import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  DateFormatPattern,
  formatDate,
  notify,
} from "@nexploretechnology/nxp-ui";

import { DataListItem } from "../../../app/components/app-data-list/types";
import useAppContext from "../../../app/hooks/useAppContext";
import { useAsync } from "../../../app/hooks/useAsync";
import {
  DeletedRecord,
  VocGetDictionary,
  VocGetKeyDatesResponse,
  VocGetPlanningResponse,
  VocKeyDateOption,
  VocPlanningRequest,
  VocUpdateKeyDatesRequest,
} from "../../../app/types";
import {
  getKeyDates,
  getPlanning,
  updateKeyDates,
  updatePlanning,
} from "../../compensation-event-services";
import PlanningTabLayout from "./PlanningTabLayout";
import moment from "moment";

interface PlanningTabContainerProps {
  entityId: string;
  eventId: string;
}

interface ReactiveContract {
  keyDateIndex: number;
  contractualDate: Date;
}

const PlanningTabContainer: React.FC<PlanningTabContainerProps> = (props) => {
  const { entityId, eventId } = props;
  const { t } = useTranslation();

  const { serviceConfig, libraries, errorHandler } = useAppContext();

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loadedDates, setLoadedDates] = React.useState<boolean>(false);
  const [memo, setMemo] = React.useState<VocGetPlanningResponse>(null);
  const [keyDates, setKeyDates] =
    React.useState<VocGetKeyDatesResponse[]>(null);

  const [planningData, setPlanningData] =
    React.useState<VocPlanningRequest>(null);
  const [planningForm, setPlanningForm] =
    React.useState<typeof planningData>(null);

  const processPlanningData = (planningData: VocGetPlanningResponse) => {
    const data = {
      ...planningData,
      constructionStatus: planningData.constructionStatus
        ? { value: planningData.constructionStatus }
        : null,
    } as any;
    setMemo(data);
    setPlanningData({
      planningOwner: data.planningOwner,
      impactedActivities: data.impactedActivities,
      planningReference: data.planningReference,
      constructionStatus: data.constructionStatus?.value,
    });
    setLoaded(true);
  };

  useAsync<VocGetPlanningResponse>(() => getPlanning(eventId, serviceConfig), {
    onSuccess: processPlanningData,
    onError: (ex) => errorHandler(ex, "getting planning"),
  });

  const handleUpdatePlanning = React.useCallback(async () => {
    try {
      processPlanningData(
        await updatePlanning(eventId, planningForm, serviceConfig)
      );
      notify.actionCompleted();
    } catch (ex) {
      errorHandler(ex, "planning update");
    }
  }, [errorHandler, eventId, planningForm, serviceConfig]);

  React.useEffect(() => {
    if (loaded && planningForm) {
      handleUpdatePlanning();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, planningForm]);

  function setPlanning(_: string, fieldLabel: string, newValue: any) {
    if (fieldLabel === t("voc.eventPage.planning.planningOwner")) {
      setPlanningData({ ...planningData, planningOwner: newValue[0] });
    }
    if (fieldLabel === t("voc.eventPage.planning.impacted")) {
      setPlanningData({ ...planningData, impactedActivities: newValue });
    }
    if (fieldLabel === t("voc.eventPage.planning.planningReference")) {
      setPlanningData({ ...planningData, planningReference: newValue });
    }
    if (fieldLabel === t("voc.eventPage.planning.constructionStatus")) {
      setPlanningData({
        ...planningData,
        constructionStatus: newValue ? newValue : null,
      });
    }
  }

  function savePlanning() {
    setPlanningForm({
      planningOwner: planningData.planningOwner
        ? planningData.planningOwner.key
        : null,
      impactedActivities: planningData.impactedActivities,
      planningReference: planningData.planningReference,
      constructionStatus: planningData.constructionStatus,
    });
  }

  const libConstrStatuses = useMemo<any[]>(
    () => [
      {
        enumValue: undefined,
        render: t("voc.common.pleaseSelect"),
      },
      ...convertConstrStatuses(libraries["VOC_CONSTR_STATUS"]),
    ],
    [libraries, t]
  );

  const libKeyDates = useMemo<VocKeyDateOption[]>(
    () => [
      {
        enumValue: undefined,
        render: t("voc.common.pleaseSelect"),
      },
      ...convertLibKeyDates(libraries["VOC_KEY_DATES"]),
    ],
    [libraries, t]
  );

  function convertLibKeyDates(dicts: VocGetDictionary[]) {
    return dicts.map((dict) => ({
      enumValue: dict.value,
      render: dict.label,
      contractualDate: dict.contractualDate,
    }));
  }

  function convertConstrStatuses(dicts: VocGetDictionary[]) {
    return dicts.map((dict) => ({
      enumValue: dict.value,
      render: dict.label,
    }));
  }

  const [keyDatesForm, setKeyDatesForm] =
    React.useState<VocUpdateKeyDatesRequest>(null);
  const [keyDatesData, setKeyDatesData] = React.useState<DataListItem[]>(null);
  const [reactiveKeyDatesData, setReactiveKeyDatesData] =
    React.useState<DataListItem[]>(null);
  const [deletedRecords, setDeletedRecords] = React.useState<string[]>(null);

  const processKeyDateData = (keyDatesData: VocGetKeyDatesResponse[]) => {
    setKeyDates(
      keyDatesData
        .map((d) => ({
          ...d,
          reference: {
            value: d.reference,
          },
        }))
        .filter((item) => !item.deleted)
    );
  };

  useAsync<VocGetKeyDatesResponse[]>(
    () => getKeyDates(eventId, serviceConfig),
    {
      onSuccess: processKeyDateData,
      onError: (ex) => errorHandler(ex, "getting key dates"),
    }
  );
  React.useEffect(() => {
    if (keyDates) {
      setKeyDatesData(convertListData(keyDates));
      setLoadedDates(true);
    }
  }, [keyDates]);

  function convertListData(items: VocGetKeyDatesResponse[]) {
    return items.map((item) => ({
      id: item.id,
      fieldDataArray: [
        { fieldData: item.reference ? item.reference.value : null },
        { fieldData: item.dateOfContract ? item.dateOfContract : null },
        { fieldData: item.forecastDate ? item.forecastDate : null },
        { fieldData: item.timeImpact ? item.timeImpact : null },
      ],
    }));
  }

  const handleUpdateKeyDates = React.useCallback(async () => {
    try {
      processKeyDateData(
        await updateKeyDates(eventId, keyDatesForm, serviceConfig)
      );
      notify.actionCompleted();
    } catch (ex) {
      errorHandler(ex, "updating key dates");
    }
  }, [errorHandler, eventId, keyDatesForm, serviceConfig]);

  React.useEffect(() => {
    if (keyDatesForm) {
      handleUpdateKeyDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyDatesForm]);

  React.useEffect(() => {
    if (keyDatesData) {
      const deletedRecords: string[] = [];
      keyDates?.forEach((memo) => {
        let deleted = true;
        keyDatesData?.forEach((kd) => {
          if (memo.id === kd.id) {
            deleted = false;
          }
        });
        if (deleted) {
          deletedRecords.push(memo.id);
        }
      });
      setDeletedRecords(deletedRecords);

      //code for updating contractual date when user selects a new reference
      const reactiveContract: ReactiveContract[] = [];

      keyDatesData?.forEach((kd: DataListItem, i: number) => {
        if (kd.fieldDataArray[0].fieldData) {
          libKeyDates?.forEach((lib: VocKeyDateOption) => {
            if (
              lib.enumValue !== undefined &&
              lib.enumValue === kd.fieldDataArray[0].fieldData
            ) {
              reactiveContract.push({
                keyDateIndex: i,
                contractualDate: lib.contractualDate,
              });
            }
          });
        }
      });

      const copyKeyDatesData: DataListItem[] = [...keyDatesData];

      reactiveContract.forEach((rc) => {
        copyKeyDatesData[rc.keyDateIndex].fieldDataArray[1].fieldData =
          rc.contractualDate;

        if (copyKeyDatesData[rc.keyDateIndex].fieldDataArray[2].fieldData) {
          const msDiff =
            moment(
              copyKeyDatesData[rc.keyDateIndex].fieldDataArray[2].fieldData
            )
              .toDate()
              .getTime() - moment(rc.contractualDate).toDate().getTime();
          const dayDiff = msDiff / 86400000;

          copyKeyDatesData[rc.keyDateIndex].fieldDataArray[3].fieldData =
            dayDiff.toFixed(0);
        }
      });

      setReactiveKeyDatesData(copyKeyDatesData);
    }
  }, [keyDatesData, libKeyDates, keyDates]);

  function findDuplicates(array) {
    const duplicates = [];
    for (let i = 0; i < array.length; i++) {
      for (let j = i + 1; j < array.length; j++) {
        if (
          array[i]["fieldDataArray"][0]["fieldData"] ===
            array[j]["fieldDataArray"][0]["fieldData"] &&
          !duplicates.includes(array[i])
        ) {
          duplicates.push(array[i]);
        }
      }
    }
    return duplicates;
  }
  function saveKeyDates() {
    const deconvertedKeyDates: Partial<VocGetKeyDatesResponse>[] =
      keyDatesData.map((kd: DataListItem) => ({
        id: kd.id?.toString(),
        reference: kd.fieldDataArray[0].fieldData,
        forecastDate: formatDate(
          kd.fieldDataArray[2].fieldData,
          DateFormatPattern.date
        ) as any,
      }));

    deconvertedKeyDates.forEach((kd: Partial<VocGetKeyDatesResponse>) => {
      if (!kd.id) {
        delete kd.id;
      }
    });

    const deletedRecordObjects: (Omit<DeletedRecord, "id"> & { id: string })[] =
      deletedRecords.map((kdId) => ({
        id: kdId,
        isDeleted: true,
      }));
    const combinedRecords = [...deconvertedKeyDates, ...deletedRecordObjects];

    setKeyDatesForm({
      records: combinedRecords,
    });
  }

  function validateKeyDates() {
    let validated = true;
    const copyKeyDatesData = [...keyDatesData];
    copyKeyDatesData.forEach((kd: DataListItem) => {
      if (kd.fieldDataArray[2].fieldData - kd.fieldDataArray[1].fieldData < 0) {
        kd.validationError = t("voc.eventPage.planning.dateValidation");
        validated = false;
      }
      if (!kd.fieldDataArray[0].fieldData && kd.fieldDataArray[2].fieldData) {
        kd.validationError = t("voc.eventPage.planning.refValidation");
        validated = false;
      }
      if (kd.fieldDataArray[0].fieldData && !kd.fieldDataArray[2].fieldData) {
        kd.validationError = t("voc.eventPage.planning.forecastValidation");
        validated = false;
      }
      if (!kd.fieldDataArray[0].fieldData && !kd.fieldDataArray[2].fieldData) {
        kd.validationError = t(
          "voc.eventPage.planning.refAndForecastValidation"
        );
        validated = false;
      }
      //reset corrected validation errors
      if (
        kd.fieldDataArray[0].fieldData &&
        kd.fieldDataArray[2].fieldData &&
        kd.fieldDataArray[2].fieldData - kd.fieldDataArray[1].fieldData > 0
      ) {
        kd.validationError = null;
      }
    });
    const duplicateKeyDatesReference = findDuplicates(keyDatesData);
    if (duplicateKeyDatesReference.length > 0) {
      const validateLast = [...keyDatesData];
      validateLast[validateLast.length - 1].validationError = t(
        "voc.eventPage.planning.duplicateReference"
      );
      validated = false;
    }
    if (!validated) {
      setKeyDatesData(copyKeyDatesData);
      setReactiveKeyDatesData(copyKeyDatesData);
    }
    return validated;
  }

  function addKeyDate() {
    if (keyDatesData.length === libKeyDates.length - 1) {
      const validateLast = [...keyDatesData];
      validateLast[validateLast.length - 1].validationError = t(
        "voc.eventPage.planning.addContractDeadlines"
      );
      setKeyDatesData(validateLast);
      return;
    }
    setKeyDatesData([
      ...keyDatesData,
      {
        id: null,
        fieldDataArray: [
          { fieldData: null },
          { fieldData: null },
          { fieldData: null },
          { fieldData: null },
        ],
      },
    ]);
  }

  //pEnum: 0 - planning, 1 - key dates
  function cancel(pEnum: number) {
    if (pEnum === 0) {
      setPlanningData({
        planningOwner: memo.planningOwner,
        impactedActivities: memo.impactedActivities,
        planningReference: memo.planningReference,
        constructionStatus: memo.constructionStatus?.value,
      });
    } else if (pEnum === 1) {
      setKeyDatesData(convertListData(keyDates));
    }
  }

  return loaded && loadedDates && libConstrStatuses && libKeyDates ? (
    <PlanningTabLayout
      entityId={entityId}
      planningData={planningData}
      setPlanning={setPlanning}
      savePlanning={savePlanning}
      keyDatesData={keyDatesData}
      reactiveKeyDatesData={reactiveKeyDatesData}
      setKeyDatesData={setKeyDatesData}
      saveKeyDates={saveKeyDates}
      validateKeyDates={validateKeyDates}
      addCallback={addKeyDate}
      cancelCallback={cancel}
      libKeyDates={libKeyDates}
      libConstrStatuses={libConstrStatuses}
    />
  ) : null;
};

export default PlanningTabContainer;
