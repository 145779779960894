// Format number by locale without rounding.
// Possible values for "minimumFractionDigits" are 0-100
// (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#minimumfractiondigits)
export const formatNumberByLocale = (value: number, locale: string) => {
  const fractionDigits = getFractionDigits(value);
  return value.toLocaleString(locale, {
    minimumFractionDigits: Math.min(fractionDigits, 100),
    useGrouping: true, // to avoid expected 1.000 to be 1000 in locale 'es'
  });
};

const getFractionDigits = (value: number) => {
  const numberAsString = value.toString();
  if (numberAsString.includes(".")) {
    return numberAsString.split(".")[1].length;
  }
  return 0;
};
