import React, { useState } from "react";

import classNames from "classnames";

import { ColumnDataType } from "../../../app/components/app-data-table";
import { VocValueRegister } from "../../../app/types";
import ValueRegisterCheckboxInput from "./CellContent/ValueRegisterCheckboxInput";
import ValueRegisterLink from "./CellContent/ValueRegisterLink";
import ValueRegisterMoneyInput from "./CellContent/ValueRegisterMoneyInput";
import ValueRegisterPercentInput from "./CellContent/ValueRegisterPercentInput";
import ValueRegisterTextInput from "./CellContent/ValueRegisterTextInput";
import {
  applyValueRegisterCalculation,
  ValueRegisterColumn,
} from "./ValueRegisterContainer";

import "./ValueRegisterRow.scss";

interface ValueRegisterInputRowProps {
  valueData: VocValueRegister;
  rowIndex: number;
  columns: ValueRegisterColumn[];
  inputIdPrefix: string;
  onValueChange: (
    newValue: number | string | boolean,
    prevValue: number | string | boolean,
    fieldName: keyof VocValueRegister,
    rowIndex: number,
    valueRegister: VocValueRegister
  ) => void;
  onInputKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement | HTMLAnchorElement>,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => void;
  onInputFocus: (
    e: React.FocusEvent<HTMLInputElement | HTMLAnchorElement>,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => void;
}

const ValueRegisterInputRow: React.FC<ValueRegisterInputRowProps> = ({
  valueData,
  rowIndex,
  columns,
  inputIdPrefix,
  onValueChange,
  onInputKeyDown,
  onInputFocus,
}) => {
  const [rowState, setRowState] = useState<
    VocValueRegister & { changed: boolean; active: boolean }
  >(() => ({ ...valueData, changed: false, active: false }));

  const handleOnValueChange = (
    newValue: number | string | boolean,
    prevValue: number | string | boolean,
    fieldName: keyof VocValueRegister,
    rowIndex: number,
    valueRegister: VocValueRegister
  ) => {
    setRowState((prevState) => {
      const newState = { ...prevState };
      newState.changed = true;
      (newState[fieldName] as number | string | boolean) = newValue;
      applyValueRegisterCalculation(newState, fieldName);
      return newState;
    });
    onValueChange(newValue, prevValue, fieldName, rowIndex, valueRegister);
  };

  const handleInputBlur = () => {
    setRowState((prevState) => ({ ...prevState, active: false }));
  };

  const handleInputFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLAnchorElement>,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => {
    setRowState((prevState) => ({ ...prevState, active: true }));
    onInputFocus(e, fieldName, rowIndex);
  };

  const getPercentInput = (col: ValueRegisterColumn) => {
    const inputProps = {
      id: `${inputIdPrefix}-${col.fieldName}-${rowIndex}`,
      disabled: false,
      fieldName: col.fieldName,
      rowIndex: rowIndex,
      value: rowState[col.fieldName] as number,
      onChange: (
        value: number | string | boolean,
        prevValue: number | string | boolean,
        fieldName: keyof VocValueRegister,
        rowIndex: number
      ) =>
        handleOnValueChange(value, prevValue, fieldName, rowIndex, valueData),
      onKeyDown: onInputKeyDown,
      onFocus: handleInputFocus,
      onBlur: handleInputBlur,
    };
    switch (col.fieldName) {
      case "recovery":
        inputProps.disabled = true;
        break;
      case "cumulativeApplied":
        if (!valueData.cumulativeAppliedNominal) {
          inputProps.disabled = true;
        }
        break;
      case "overheadProfit":
        inputProps.disabled = true;
        break;
      default:
        break;
    }
    return (
      <div key={col.fieldName} role="gridcell">
        <ValueRegisterPercentInput {...inputProps} />
      </div>
    );
  };

  const getMoneyInput = (col: ValueRegisterColumn) => {
    const inputProps = {
      id: `${inputIdPrefix}-${col.fieldName}-${rowIndex}`,
      disabled: false,
      fieldName: col.fieldName,
      rowIndex: rowIndex,
      value: rowState[col.fieldName] as number,
      onChange: (
        value: number | string | boolean,
        prevValue: number | string | boolean,
        fieldName: keyof VocValueRegister,
        rowIndex: number
      ) =>
        handleOnValueChange(value, prevValue, fieldName, rowIndex, valueData),

      onKeyDown: onInputKeyDown,
      onFocus: handleInputFocus,
      onBlur: handleInputBlur,
    };
    switch (col.fieldName) {
      case "eisA2A6":
        if (!valueData.includedInReports) {
          inputProps.disabled = true;
          inputProps.value = null;
        }
        break;
      case "eisA8A14":
        inputProps.disabled = true;
        if (!valueData.includedInReports) {
          inputProps.value = null;
        }
        break;
      case "pendingApproval":
      case "totalCost":
      case "profitLoss":
        inputProps.disabled = true;
        break;
      default:
        break;
    }
    return (
      <div key={col.fieldName} role="gridcell">
        <ValueRegisterMoneyInput {...inputProps} />
      </div>
    );
  };
  return (
    <div
      role="row"
      className={classNames(
        rowState.changed && "changed",
        rowState.active && "active"
      )}
    >
      {columns.map((col) =>
        col.readonly ? (
          <div
            key={col.fieldName}
            role="gridcell"
            className={classNames(
              "read-only",
              col.fieldName === "eventTitle" && "title"
            )}
          >
            <span>
              <ValueRegisterLink
                id={`${inputIdPrefix}-${col.fieldName}-${rowIndex}`}
                fieldName={col.fieldName}
                rowIndex={rowIndex}
                href={`./compensation-events/${valueData.compensationEventId}`}
                onKeyDown={onInputKeyDown}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
              >
                {valueData[col.fieldName]}
              </ValueRegisterLink>
            </span>
          </div>
        ) : col.columnDataType === ColumnDataType.Money ? (
          getMoneyInput(col)
        ) : col.columnDataType === ColumnDataType.Percent ? (
          getPercentInput(col)
        ) : col.columnDataType === ColumnDataType.Boolean ? (
          <div key={col.fieldName} role="gridcell">
            <ValueRegisterCheckboxInput
              id={`${inputIdPrefix}-${col.fieldName}-${rowIndex}`}
              fieldName={col.fieldName}
              rowIndex={rowIndex}
              checked={rowState[col.fieldName] as boolean}
              onChange={(value, fieldName, rowIndex) =>
                handleOnValueChange(
                  value,
                  !value,
                  fieldName,
                  rowIndex,
                  valueData
                )
              }
              onKeyDown={onInputKeyDown}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
          </div>
        ) : (
          <div key={col.fieldName} role="gridcell">
            <ValueRegisterTextInput
              id={`${inputIdPrefix}-${col.fieldName}-${rowIndex}`}
              fieldName={col.fieldName}
              rowIndex={rowIndex}
              value={rowState[col.fieldName] as string}
              onChange={(value, prevValue, fieldName, rowIndex) =>
                handleOnValueChange(
                  value,
                  prevValue,
                  fieldName,
                  rowIndex,
                  valueData
                )
              }
              onKeyDown={onInputKeyDown}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
          </div>
        )
      )}
    </div>
  );
};

export default ValueRegisterInputRow;
