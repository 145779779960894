import { formatDate } from "@nexploretechnology/nxp-ui";
import { ColumnDataType } from "../app/components/app-data-table";
import { FilterOption, FilterType } from "../app/components/app-filter";
import { convertToJsonBody } from "../app/services/convert-to-json-body";
import exportRequest from "../app/services/export-request";
import { PageRequest } from "../app/services/vocService";
import {
  ResponseWithPagination,
  VocClaim,
  VocEvent,
  VocEventCreation,
  VocEventListItem,
  VocEventShort,
  VocEventStatusRecord,
  VocEventSummaryNotification,
  VocGetKeyDatesResponse,
  VocGetNotificationsResponse,
  VocGetPlanningResponse,
  VocGetRecentMessagesResponse,
  VocGetSupportDocumentResponse,
  VocGetValuesResponse,
  VocLog,
  VocPlanningRequest,
  VocRecordStatus,
  VocSendTaskReminderResponse,
  VocSummaryRefFiles,
  VocTask,
  VocUpdateCostNotificationsRequest,
  VocUpdateKeyDatesRequest,
  VocUpdateNotificationsRequest,
  VocUpdateSubrecordsRequest,
  VocUpdateSubrecordsResponse,
  VocUpdateSupportDocument,
  VocUpdateTasks,
  VocUpdateTimeNotificationsRequest,
  VocUpdateValuesRequest,
  VocValueRegister,
  VocVO,
} from "../app/types";
import { VocAllSubContractorsDataPackage } from "../app/types/subcontractor";
import { apiRequest, ServiceConfig } from "../app/utils/backend/api-request";
import { prepareFilterOptions } from "../app/utils/backend/prepare-filter-options";
import { REGISTER_LAZY_LOAD_SIZE } from "../app/utils/const";
import { DateFormatPattern } from "../app/utils/localisation/locale-enum";
import { getUserKey } from "./pages/helpers/compensation-events.helpers";

export const searchCompensationEvents = async (
  serviceConfig: ServiceConfig,
  pageRequest: PageRequest,
  // extraParams: TableRefreshExtraParams<keyof DemoStatusCount>,
  filterOptions?: FilterOption[]
) => {
  const filters = !filterOptions ? [] : prepareFilterOptions(filterOptions);

  if (pageRequest.search) {
    // handle table search box value
    filters.push({
      column: "serial",
      type: FilterType.Contains,
      value: pageRequest.search,
    });
  }

  // pageRequest.perPage === 0 for lazy loading
  const perPage =
    pageRequest.perPage === 0 ? REGISTER_LAZY_LOAD_SIZE : pageRequest.perPage;

  return apiRequest<ResponseWithPagination<VocEventListItem[]>>(
    `/search/compensation_events`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        // total: pageRequest.perPage === 0 && pageRequest.page === "1",
        limit: perPage,
        offset: parseInt(pageRequest.page)
          ? (parseInt(pageRequest.page) - 1) * perPage
          : 0,
        sortColumn: pageRequest.sort,
        sortOrder: pageRequest.order,
        filters,
      }),
    }
  );
};

export const getEventById = (
  eventId: VocEvent["eventId"],
  serviceConfig: ServiceConfig
): Promise<VocEvent> => {
  return apiRequest<VocEvent>(`/events/${eventId}`, serviceConfig);
};
export const createEvent = (
  event: VocEventCreation,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocEvent>(`/events`, serviceConfig, {
    credentials: "same-origin",
    method: "POST",
    body: JSON.stringify(event),
  });
};

export const updateEvent = (
  eventId: VocEvent["eventId"],
  {
    commercialOwner,
    recordOwner,
    recordSupporter,
    ...event
  }: Partial<VocEvent>,
  serviceConfig: ServiceConfig
) => {
  let body: { [key: string]: unknown } = {
    ...event,
    commercialOwnerId: getUserKey(commercialOwner),
    recordOwnerId: getUserKey(recordOwner),
    recordSupporterId: getUserKey(recordSupporter),
  };

  if (event.eventType) {
    body.eventType = event.eventType;
  }
  if (event.statusWithClient) {
    body.statusWithClient = event.statusWithClient;
  }
  if (event.delayDisruptiveEffect) {
    body.delayDisruptiveEffect = event.delayDisruptiveEffect.map(
      (item: any) => item
    );
  }
  if (event.siteEvents) {
    body.siteEvents = event.siteEvents.map((item: any) => item.id);
  }
  if (event.eventSource) {
    body.eventSource = event.eventSource;
  }
  if (event.instructionOwner) {
    body.instructionOwner = event.instructionOwner;
  }
  if (event.locations) {
    body.locations = event.locations.map((item: any) => item);
  }
  if (event.eventSourceType) {
    body.eventSourceType = event.eventSourceType;
  }
  if (event.dateOfAwareness) {
    body.dateOfAwareness = formatDate(
      event.dateOfAwareness,
      DateFormatPattern.date
    ) as any;
  }
  if (event.dateOfCessation) {
    body.dateOfCessation = formatDate(
      event.dateOfCessation,
      DateFormatPattern.date
    ) as any;
  }

  // t=${new Date().getTime() to avoid json response caching after back navigation by user
  return apiRequest<VocEvent>(
    `/events/${eventId}?t=${new Date().getTime()}`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PATCH",
      body: JSON.stringify(body),
    }
  );
};

export const createEventSummaryNotification = (
  eventId: string,
  summaryNotification: VocEventSummaryNotification,
  serviceConfig: ServiceConfig
): Promise<VocEventSummaryNotification> => {
  return apiRequest<VocEventSummaryNotification>(
    `/events/${eventId}/event_notifications`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "POST",
      body: JSON.stringify(summaryNotification),
    }
  );
};

export const getSiteEventsQuery = (
  filterText: string,
  onlyUnlink: boolean,
  serviceConfig: ServiceConfig
): Promise<VocEvent[]> => {
  // api build for picker option list
  return apiRequest<VocEvent[]>(
    `/search/site_events?keyword=${filterText}&onlyUnlink=${onlyUnlink}`,
    serviceConfig
  );
};

export const getEventSupportDocuments = (
  eventId: ServiceConfig["entityId"],
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocGetSupportDocumentResponse>(
    `/events/${eventId}/support_document`,
    serviceConfig
  );
};

export const getSiteEventSupportDocuments = (
  siteEventId: string,
  serviceConfig: ServiceConfig
): Promise<VocGetSupportDocumentResponse> => {
  return apiRequest<VocGetSupportDocumentResponse>(
    `/site_events/${siteEventId}/support_document`,
    serviceConfig
  );
};

export const getRecentMessages = (
  eventId: string,
  serviceConfig: ServiceConfig
): Promise<VocGetRecentMessagesResponse> => {
  return apiRequest<VocGetRecentMessagesResponse>(
    `/events/${eventId}/message/recent`,
    serviceConfig
  );
};

export const getSubcontractor = (
  serviceConfig: ServiceConfig
): Promise<VocAllSubContractorsDataPackage> => {
  return Promise.resolve({
    results: [],
    counts: 0,
  } as VocAllSubContractorsDataPackage);
};

export const getStatusRecords = (
  eventId: string,
  serviceConfig: ServiceConfig
): Promise<VocEventStatusRecord[]> => {
  return apiRequest<{ results: VocEventStatusRecord[] }>(
    `/events/${eventId}/records`,
    serviceConfig
  ).then((data: any) => data.results);
};

export const createStatusRecord = (
  start: string,
  status: VocRecordStatus,
  remarks: string,
  eventId: string,
  serviceConfig: ServiceConfig
): Promise<VocEventStatusRecord[]> => {
  return apiRequest<VocEventStatusRecord[]>(
    `/events/${eventId}/records`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "POST",
      body: JSON.stringify({
        start,
        status,
        remarks,
      }),
    }
  );
};
export const batchUpdateStatusRecords = (
  start: string,
  finish: string,
  status: VocRecordStatus,
  remarks: string,
  eventId: string,
  serviceConfig: ServiceConfig
): Promise<VocEventStatusRecord[]> => {
  return apiRequest<VocEventStatusRecord[]>(
    `/events/${eventId}/records`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PATCH",
      body: convertToJsonBody(
        {
          start,
          finish,
          status: { value: status },
          remarks,
        },
        serviceConfig.timezoneHourAdjustment
      ),
    }
  );
};

export const getEventListShort = async (
  serviceConfig: ServiceConfig
): Promise<VocEventShort[]> => {
  return apiRequest<ResponseWithPagination<VocEventShort[]>>(
    `/search/compensation_events`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "POST",
      body: convertToJsonBody({}, serviceConfig.timezoneHourAdjustment),
    }
  ).then((data) => data?.results);
};

export const getEventTasks = (
  eventId: VocEvent["eventId"],
  type: "compEvent" | "siteEvent",
  serviceConfig: ServiceConfig
): Promise<VocTask[]> => {
  return apiRequest<VocTask[]>(
    `/${type === "compEvent" ? "events" : "site_events"}/${eventId}/tasks`,
    serviceConfig
  );
};

export const updateCompEventTasks = (
  eventId: VocEvent["eventId"],
  form: VocUpdateTasks,
  serviceConfig: ServiceConfig
): Promise<VocTask[]> => {
  return apiRequest<VocTask[]>(`/events/${eventId}/tasks`, serviceConfig, {
    credentials: "same-origin",
    method: "PATCH",
    body: JSON.stringify(form),
  });
};
export const updateSiteEventTasks = (
  siteEventId: VocEvent["eventId"],
  form: VocUpdateTasks,
  serviceConfig: ServiceConfig
): Promise<VocTask[]> => {
  return apiRequest<VocTask[]>(
    `/site_events/${siteEventId}/tasks`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PATCH",
      body: JSON.stringify(form),
    }
  );
};
export const getRefFiles = (
  eventId: VocEvent["eventId"],
  serviceConfig: ServiceConfig
): Promise<VocSummaryRefFiles> => {
  return apiRequest<VocSummaryRefFiles>(
    `/events/${eventId}/files`,
    serviceConfig
  );
};

export const editRefFiles = (
  eventId: VocEvent["eventId"],
  refFileUpdate: VocSummaryRefFiles,
  serviceConfig: ServiceConfig
): Promise<VocSummaryRefFiles> => {
  return apiRequest<VocSummaryRefFiles>(
    `/events/${eventId}/files`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PUT",
      body: JSON.stringify(refFileUpdate),
    }
  );
};

export const editSupportDocuments = (
  eventId: VocEvent["eventId"],
  supportDocUpdate: VocUpdateSupportDocument,
  serviceConfig: ServiceConfig
): Promise<VocUpdateSupportDocument> => {
  return apiRequest<VocUpdateSupportDocument>(
    `/events/${eventId}/support_document`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PATCH",
      body: JSON.stringify(supportDocUpdate),
    }
  );
};

export const getEventVO = (
  eventId: string,
  serviceConfig: ServiceConfig
): Promise<VocVO> => {
  return apiRequest<VocVO>(`/events/${eventId}/vo`, serviceConfig);
};

export const updateEventVO = (
  eventId: string,
  vo: Partial<VocVO>,
  serviceConfig: ServiceConfig
): Promise<VocVO> => {
  return apiRequest<VocVO>(`/events/${eventId}/vo`, serviceConfig, {
    credentials: "same-origin",
    method: "PATCH",
    headers: {
      accept: "application/json",
    },
    body: JSON.stringify(vo),
  });
};

export const getVOSelectList = (
  serviceConfig: ServiceConfig
): Promise<{ id: number; title: string; serial: string }[]> => {
  return apiRequest<{
    results: { id: number; title: string; serial: string }[];
  }>("/events/type/vo", serviceConfig).then((data: any) => data.results);
};

export const getClaimSelectList = (
  serviceConfig: ServiceConfig
): Promise<{ id: number; title: string; serial: string }[]> => {
  return apiRequest<{
    results: { id: number; title: string; serial: string }[];
  }>("/events/type/claim", serviceConfig).then((data: any) => data.results);
};

export const getClaims = async (
  eventId: string,
  serviceConfig: ServiceConfig
): Promise<VocClaim[]> => {
  const response = await apiRequest<{ results: { claimDetails: VocClaim }[] }>(
    `/events/${eventId}/claim`,
    serviceConfig
  );
  return response.results.map((result) => result.claimDetails);
};

export const updateClaim = (
  eventId: string,
  claim: Partial<VocClaim>,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocClaim>(`/events/${eventId}/claim`, serviceConfig, {
    credentials: "same-origin",
    method: "PATCH",
    body: convertToJsonBody(claim, serviceConfig.timezoneHourAdjustment),
  });
};

export const createClaim = (
  eventId: string,
  claim: Partial<VocClaim>,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocClaim>(`/events/${eventId}/claim`, serviceConfig, {
    credentials: "same-origin",
    method: "POST",
    body: convertToJsonBody(claim, serviceConfig.timezoneHourAdjustment),
  });
};

export const getPlanning = (eventId: string, serviceConfig: ServiceConfig) => {
  return apiRequest<VocGetPlanningResponse>(
    `/events/${eventId}/planner`,
    serviceConfig
  );
};

export const updatePlanning = (
  eventId: string,
  planningForm: VocPlanningRequest,
  serviceConfig: ServiceConfig
) => {
  return apiRequest(`/events/${eventId}/planner`, serviceConfig, {
    credentials: "same-origin",
    method: "PATCH",
    body: convertToJsonBody(
      {
        ...planningForm,
        planningOwnerId: planningForm.planningOwner,
        planningOwner: undefined,
      },
      serviceConfig.timezoneHourAdjustment
    ),
  });
};

export const getKeyDates = (eventId: string, serviceConfig: ServiceConfig) => {
  return apiRequest<VocGetKeyDatesResponse[]>(
    `/events/${eventId}/key_dates`,
    serviceConfig
  );
};

export const updateKeyDates = (
  eventId: string,
  keyDatesForm: VocUpdateKeyDatesRequest,
  serviceConfig: ServiceConfig
) => {
  return apiRequest(`/events/${eventId}/key_dates/batch`, serviceConfig, {
    credentials: "same-origin",
    method: "PATCH",
    body: convertToJsonBody(keyDatesForm, serviceConfig.timezoneHourAdjustment),
  });
};
export const sendTaskReminder = (
  taskId: string,
  serviceConfig: ServiceConfig
): Promise<VocSendTaskReminderResponse> => {
  return apiRequest<VocSendTaskReminderResponse>(
    `/tasks/${taskId}/message/send`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "POST",
    }
  );
};

// ===========================================
// event notification tab
// ===========================================

export const getNotifications = (
  eventId: string,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocGetNotificationsResponse>(
    `/events/${eventId}/notifications_v2`,
    serviceConfig
  );
};

export const editNotifications = (
  eventId: string,
  reqBody:
    | VocUpdateTimeNotificationsRequest
    | VocUpdateCostNotificationsRequest,
  serviceConfig: ServiceConfig
): Promise<VocUpdateNotificationsRequest> => {
  return apiRequest<VocUpdateNotificationsRequest>(
    `/events/${eventId}/notifications_v2`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(reqBody),
    }
  );
};

// ===========================================
// reminder button
// ===========================================

export const createNotificationReminder = (
  eventId: string,
  reqBody: VocEventSummaryNotification,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocEventSummaryNotification>(
    `/events/${eventId}/reminder_notifications`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(reqBody),
    }
  );
};

// ===========================================
// event logs tab
// ===========================================

export const getSiteEventLogs = (
  siteEventId: string,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocLog[]>(
    `/site_events/${siteEventId}/logs`,
    serviceConfig
  );
};

export const getCompensationEventLogs = (
  eventId: string,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocLog[]>(`/events/${eventId}/logs`, serviceConfig);
};

export const undoSiteEventLogs = (
  eventId: string,
  logEntryId: string,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocUpdateNotificationsRequest>(
    `/site_events/${eventId}/logs/${logEntryId}/undo`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({}),
    }
  );
};

export const undoCompensationEventLogs = (
  eventId: string,
  logEntryId: string,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocUpdateNotificationsRequest>(
    `/events/${eventId}/logs/${logEntryId}/undo`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({}),
    }
  );
};

// ===========================================
// Start event values tab calls
type SearchSnapshots = {
  limit: number;
  offset: number;
  filters: [
    {
      values: string[];
    }
  ];
};

type SnapshotsMonth = {
  month: number;
  year: number;
};

export function convertValuationsToJsonBody(
  requestBody: any,
  timezoneHourAdjustment: number
) {
  if (timezoneHourAdjustment) {
    Object.keys(requestBody).forEach((key) => {
      if (
        Object.prototype.toString.call(requestBody[key]) === "[object Date]"
      ) {
        requestBody[key] = new Date(
          requestBody[key].getTime() +
            Math.round(-timezoneHourAdjustment * 3600000)
        );
      }
    });
  }
  return JSON.stringify({ valuations: requestBody });
}

export const searchSnapshots = (
  reqBody: SearchSnapshots,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocEventSummaryNotification>(
    `/dashboard/snapshots`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(reqBody),
    }
  );
};

export const postSnapshots = (
  reqBody: SnapshotsMonth,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocEventSummaryNotification>(
    `/dashboard/snapshot/monthly`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(reqBody),
    }
  );
};

export const updateValueRegisterList = (
  valueRegisterList: Partial<VocValueRegister>[],
  serviceConfig: ServiceConfig
): Promise<VocValueRegister[]> => {
  return apiRequest<VocValueRegister[]>("/valuations", serviceConfig, {
    credentials: "same-origin",
    method: "PATCH",
    body: convertValuationsToJsonBody(
      valueRegisterList.map((item) => {
        // remove field of VocValueRegister unused in patch call
        item.eventTypeSerial = undefined;
        item.eventTitle = undefined;
        item.compensationEventSerial = undefined;
        return item;
      }),
      serviceConfig.timezoneHourAdjustment
    ),
  });
};

export const getValueRegisterList = (
  filterOptions: FilterOption[],
  serviceConfig: ServiceConfig
): Promise<VocValueRegister[]> => {
  // const filters = prepareFilterOptions(filterOptions);
  return apiRequest<VocValueRegister[]>(`/valuations`, serviceConfig);
};

export const getValueExcel = async (
  screenId: number,
  serviceConfig: ServiceConfig
) => {
  return exportRequest(
    `${serviceConfig.apiBaseUrl}/entities/${serviceConfig.entityId}/export/${screenId}`,
    {
      credentials: "same-origin",
      method: "GET",
      headers: {
        Authorization: `Bearer ${serviceConfig.token}` || "",
      },
    }
  );
};

export const getValues = (
  eventId: string,
  serviceConfig: ServiceConfig
): Promise<VocGetValuesResponse> => {
  return apiRequest<VocGetValuesResponse>(
    `/events/${eventId}/valuation`,
    serviceConfig
  );
};

export const sendValues = (
  eventId: string,
  valuesUpdate: Partial<VocUpdateValuesRequest>,
  serviceConfig: ServiceConfig
): Promise<VocGetValuesResponse> => {
  return apiRequest<VocGetValuesResponse>(
    `/events/${eventId}/valuation`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PATCH",
      body: convertToJsonBody(
        valuesUpdate,
        serviceConfig.timezoneHourAdjustment
      ),
    }
  );
};

export const editValuesSubrecords = (
  eventId: string,
  valuesSubrecordsUpdate: VocUpdateSubrecordsRequest,
  serviceConfig: ServiceConfig
): Promise<VocUpdateSubrecordsResponse> => {
  return apiRequest<VocUpdateSubrecordsResponse>(
    `/events/${eventId}/valuation/records`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PUT",
      body: convertToJsonBody(
        valuesSubrecordsUpdate,
        serviceConfig.timezoneHourAdjustment
      ),
    }
  );
};

export const decodeValueRegisterExcelImport = async (
  serviceConfig: ServiceConfig,
  req: any,
  file: File
) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("mappings", JSON.stringify(req));
  const response = await fetch(
    `${serviceConfig.apiBaseUrl}/entities/${serviceConfig.entityId}/valuations/import/decode`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${serviceConfig.token}` || "",
      },
      body: formData,
    }
  ).then((response) => {
    return response.json();
  });
  return response;
};

export const searchValueRegister = (
  serviceConfig: ServiceConfig,
  filterOptions?: FilterOption[]
) => {
  const filters = filterOptions.map((options) => {
    return {
      column: options.fieldName,
      type: options.filterType,
      value: [
        ColumnDataType.Money,
        ColumnDataType.Percent,
        ColumnDataType.Percent,
      ].includes(options.columnDataType)
        ? Number(options.filterValue)
        : options.filterValue,
    };
  });
  return apiRequest<VocValueRegister[]>(`/valuations`, serviceConfig, {
    method: "POST",
    body: JSON.stringify({
      limit: 999999999,
      offset: 0,
      filters,
    }),
  });
};
