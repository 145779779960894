import React, { useCallback, useState } from "react";

import AppButton from "../../../../app/components/app-button";
import CreateEventModalContainer from "./CreateSiteEventContainer";

import { useTranslation } from "react-i18next";
import "./CreateSiteEventButton.scss";

interface CreateSiteEventButtonProps {
  entityId: string;
}

const CreateSiteEventButton: React.FC<CreateSiteEventButtonProps> = ({
  entityId,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleModalClose = useCallback(() => {
    setShowCreateModal(false);
  }, []);
  const { t } = useTranslation();
  return (
    <>
      <AppButton onClick={() => setShowCreateModal(true)}>
        + {t("voc.createEvent.createNewEvent")}
      </AppButton>
      {showCreateModal && (
        <CreateEventModalContainer
          showCreateModal={showCreateModal}
          onModalClose={handleModalClose}
          entityId={entityId}
        />
      )}
    </>
  );
};

export default CreateSiteEventButton;
