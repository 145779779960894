import React from "react";
import { useTranslation } from "react-i18next";

import AppCollapsible from "../../../app/components/app-collapsible/AppCollapsible";
import { DataListItem } from "../../../app/components/app-data-list/types";
import AppDataListV2 from "../../../app/components/app-data-list/AppDataListV2";
import AppFieldBlock, {
  Option,
} from "../../../app/components/app-field-block/AppFieldBlock";
import useAppContext from "../../../app/hooks/useAppContext";
import { DeletedRecord, VocLibraryObject } from "../../../app/types";
import { useLibraryHandler } from "../../hooks/useLibraryHandler";
import {
  getVarianceDisplayUnit,
  LibraryTypes,
  updateVarianceDisplayUnit,
} from "../../libraries-services";

interface VarianceAndDisplayUnitProps {}

export const VarianceAndDisplayUnit: React.FC<
  VarianceAndDisplayUnitProps
> = () => {
  const libraryType = "VOC_VARIANCE" as LibraryTypes; // actually NOT library, BUT dictionary

  const { serviceConfig, errorHandler } = useAppContext();
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = React.useState(false);

  const convertVarianceRanges = (items: VocLibraryObject[]) => {
    const converted = items.map((item) => ({
      id: item.id,
      value: item.value,
      edited: false,
      sortOrder: item.sortOrder,
      fieldDataArray: [
        { fieldData: item.rangeType },
        {
          fieldData: item.fromValue,
          readonly:
            item.rangeType === "range" || item.rangeType === "more than"
              ? false
              : true,
        },
        {
          fieldData: item.toValue,
          readonly:
            item.rangeType === "range" || item.rangeType === "less than"
              ? false
              : true,
        },
      ],
    }));
    return converted;
  };

  const {
    // Variables
    fetchLibraryAndUsage,
    isLibraryAndUsageLoaded,
    libraryItem,
    displayLibraryItem,
    setDisplayLibraryItem,
    // Update Handler
    handleUpdateLibraries,
  } = useLibraryHandler(libraryType, convertVarianceRanges, 0);

  // ====================================================================
  //                            Display Unit
  // ====================================================================

  // display unit
  const [varDU, setVarDU] = React.useState<string>(null);
  const [varDUForm, setVarDUForm] = React.useState<any>(null);

  const handleGetDisplayUnit = React.useCallback(async () => {
    try {
      const response = await getVarianceDisplayUnit(serviceConfig);
      setVarDU(response?.displayUnit ? String(response?.displayUnit) : "1");
    } catch (ex) {
      errorHandler(ex, "Get Display Unit");
    }
  }, [errorHandler, serviceConfig]);

  React.useEffect(() => {
    handleGetDisplayUnit();
  }, [handleGetDisplayUnit]);

  const handleUpdateVarDU = React.useCallback(async () => {
    try {
      await updateVarianceDisplayUnit(serviceConfig, varDUForm);
      handleGetDisplayUnit();
      setIsEditing(false);
    } catch (ex) {
      errorHandler(ex, "Update Display Unit");
    }
  }, [errorHandler, handleGetDisplayUnit, serviceConfig, varDUForm]);

  React.useEffect(() => {
    if (varDUForm) {
      handleUpdateVarDU();
    }
  }, [handleUpdateVarDU, varDUForm]);

  // ================================================
  //                 panel & colDef
  // ================================================

  const panelTitle = React.useMemo(() => t("voc.library.variance"), [t]);

  const rangeOptions: Option[] = React.useMemo(
    () => [
      {
        render: t("voc.library.lessThan"),
        enumValue: "less than",
      },
      {
        render: t("voc.library.range"),
        enumValue: "range",
      },
      {
        render: t("voc.library.moreThan"),
        enumValue: "more than",
      },
    ],
    [t]
  );

  const column = React.useMemo(
    () => [
      {
        columnName: "",
        columnWidthClassName: "fifth",
        columnDataType: "basicdropdown",
        columnOptions: rangeOptions,
        editable: true,
        disableColumnSort: true,
      },
      {
        columnName: t("voc.common.from"),
        columnWidthClassName: "twofifths",
        columnDataType: "string",
        editable: true,
        stringConversionSymbol: "$",
        stringConversionDecimals: 2,
      },
      {
        columnName: t("voc.common.to"),
        columnWidthClassName: "twofifths",
        columnDataType: "string",
        editable: true,
        stringConversionSymbol: "$",
        stringConversionDecimals: 2,
      },
    ],
    [rangeOptions, t]
  );

  const displayUnitOptions = React.useMemo(
    () => [
      {
        label: t("voc.library.displayUnits"),
        value: varDU,
        type: "basicdropdown",
        options: [
          {
            enumValue: "1",
            render: t("voc.library.none"),
          },
          {
            enumValue: "1000",
            render: t("voc.library.thousands"),
          },
          {
            enumValue: "1000000",
            render: t("voc.library.millions"),
          },
        ],
        width: "remainder",
        editable: true,
        onChangeCallback: (key: any, label: string, newValue: string) => {
          setVarDU(newValue);
        },
      },
    ],
    [t, varDU]
  );

  // ================================================
  //                Action Handler
  // ================================================

  const handleSave = () => {
    const deconvertedRanges: Partial<VocLibraryObject>[] = [];
    const newDisplayLibraryItem = [...displayLibraryItem];
    let lessThanCount = 0;
    let moreThanCount = 0;
    let valid = true;
    const deletedRecords: DeletedRecord[] = [];
    libraryItem.forEach((memo: any) => {
      let deleted = true;
      newDisplayLibraryItem.forEach((dl: DataListItem) => {
        if (memo.id === dl.id) {
          deleted = false;
        }
      });
      if (deleted) {
        deletedRecords.push({
          id: memo.id,
          value: memo.value, //is this necessary??
          rangeType: memo.rangeType, //is this necessary??
          fromValue: memo.fromValue, //is this necessary??
          toValue: memo.toValue, //is this necessary??
          isDeleted: true,
        });
      }
    });
    newDisplayLibraryItem.forEach((r: DataListItem) => {
      if (r.fieldDataArray[0].fieldData === "less than") {
        if (lessThanCount === 0) {
          lessThanCount++;
        } else {
          r.validationError = "Please save no more than one Less Than range!";
          valid = false;
        }
      } else if (r.fieldDataArray[0].fieldData === "more than") {
        if (moreThanCount === 0) {
          moreThanCount++;
        } else {
          r.validationError = "Please save no more than one More Than range!";
          valid = false;
        }
      }
      if (r.edited && r.id && r.value) {
        deconvertedRanges.push({
          id: r.id,
          value: r.value,
          rangeType: r.fieldDataArray[0].fieldData,
          fromValue: r.fieldDataArray[1].fieldData,
          toValue: r.fieldDataArray[2].fieldData,
        });
      } else if (r.edited && !r.id && !r.value) {
        deconvertedRanges.push({
          rangeType: r.fieldDataArray[0].fieldData,
          fromValue: r.fieldDataArray[1].fieldData,
          toValue: r.fieldDataArray[2].fieldData,
        });
      }
    });
    if (valid) {
      const combinedRecords = [...deconvertedRanges, ...deletedRecords];
      handleUpdateLibraries(
        {
          items: combinedRecords,
        },
        () => {
          setVarDUForm({
            displayUnit: Number(varDU),
          });
        }
      );
    } else {
      setDisplayLibraryItem(newDisplayLibraryItem);
    }
  };

  const handleCancel = () => {
    // setDisplayLibraryItem(libraryItem);
    fetchLibraryAndUsage();
  };

  return (
    <AppCollapsible
      permissionCode="libraries"
      hasFields={false}
      editCallback={() => {
        setIsEditing(true);
      }}
      editingState={isEditing}
      saveCallback={handleSave}
      cancelCallback={() => {
        handleCancel();
        setIsEditing(false);
      }}
      addCallback={() => {
        setDisplayLibraryItem([
          ...displayLibraryItem,
          {
            id: null,
            value: null,
            edited: true,
            sortOrder: null,
            fieldDataArray: [
              { fieldData: "range" },
              { fieldData: null },
              { fieldData: null },
            ],
          },
        ]);
      }}
      firstDecollapseCallback={() => {
        fetchLibraryAndUsage();
      }}
      isLoading={!isLibraryAndUsageLoaded}
      isLoadOnEveryClick={true}
      headerChildren={<div className="doctype-title">{panelTitle}</div>}
      bodyChildren={
        isLibraryAndUsageLoaded ? (
          <div className="variance-divider">
            <div className="ranges">
              <AppDataListV2
                permissionCode="libraries"
                editing={isEditing}
                canDeleteLastItem={true}
                columns={column}
                oriItems={libraryItem}
                items={displayLibraryItem}
                editSetStateHook={setDisplayLibraryItem}
                setFiles={null}
                defaultSortDirection=""
                saveCustomOrderCallback={null}
                resetCustomOrderCallback={null}
                saveCustomOrderCode={null}
                initCustomSortState={false}
                isVarianceLibraryFlag={true}
              />
            </div>
            <div className="display-unit">
              <AppFieldBlock editing={isEditing} fields={displayUnitOptions} />
            </div>
          </div>
        ) : null
      }
    ></AppCollapsible>
  );
};

export default VarianceAndDisplayUnit;
