import React, { useEffect, useState } from "react";

import { UserOutlined } from "@ant-design/icons";
import {
  LAZY_LOAD_PAGE_SIZE,
  LazyLoadStatus,
  notify,
  NxpTableLazy,
  NxpTableRowDropdown,
  sorterForNumber,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Menu } from "antd";
import { ColumnProps } from "antd/lib/table";

import { DemoItem, getDemoLazyList } from "../../../demo-services";

const columns: ColumnProps<DemoItem>[] = [
  {
    title: "name",
    dataIndex: "name",
    width: 150,
    fixed: "left",
    sorter: (a, b) => sorterForString(a.name, b.name),
  },
  {
    title: "age",
    dataIndex: "age",
    width: 150,
    sorter: (a, b) => sorterForNumber(a.age, b.age),
  },
  {
    title: "address",
    dataIndex: "address",
    width: 300,
  },
  {
    fixed: "right",
    align: "right",
    title: "action",
    dataIndex: "action",
    width: 100,
    render: (value, record) => (
      <NxpTableRowDropdown
        onDropdownTriggerClick={(e) => {
          e.stopPropagation();
          console.log("actionactionactionactionaction", record);
        }}
        overlay={
          <Menu
            onClick={(e) =>
              notify.info(
                `Action item ${e.keyPath} clicked for record ${JSON.stringify(
                  record
                )}.`
              )
            }
          >
            <Menu.Item key="1" icon={<UserOutlined />}>
              1st menu item
            </Menu.Item>
            <Menu.Item key="2" icon={<UserOutlined />}>
              2nd menu item
            </Menu.Item>
            <Menu.Item key="3" icon={<UserOutlined />}>
              3rd menu item
            </Menu.Item>
          </Menu>
        }
      />
    ),
  },
];

interface TableLazyProps {}

const TableLazy: React.FC<TableLazyProps> = () => {
  const [lazyLoadStatus, setLazyLoadStatus] = useState(LazyLoadStatus.ready);
  const [demoItems, setDemoItems] = useState<DemoItem[]>([]);

  const fetchData = () => {
    if (lazyLoadStatus === LazyLoadStatus.ready) {
      setLazyLoadStatus(LazyLoadStatus.loading);
      getDemoLazyList(demoItems.length, LAZY_LOAD_PAGE_SIZE).then((data) => {
        setTimeout(() => {
          setDemoItems((prevState) => {
            setLazyLoadStatus(LazyLoadStatus.ready);
            return prevState.concat(data);
          });
        }, 500);
      });
    }
  };

  const handleLazyLoad = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
    // hook to be call only once at start
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NxpTableLazy
      lazyLoadStatus={lazyLoadStatus}
      onLazyLoad={handleLazyLoad}
      columns={columns}
      dataSource={demoItems}
      useDefaultHeight
      pagination={false}
      onRow={(record) => ({
        onClick: () => {
          console.log("rowClickrowClickrowClickrowClick", record);
        },
      })}
    />
  );
};

export default TableLazy;
