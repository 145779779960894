import i18n from "../../i18n/i18n";
import { formatNumberByLocale } from "./formatNumberByLocale";
import { parseNumberByLocale } from "./parseNumberByLocale";

export const getFormattedInputNumberControlProps = () => ({
  max: Number.MAX_SAFE_INTEGER,
  min: Number.MIN_SAFE_INTEGER,
  formatter: (value?: unknown) =>
    formatNumberByLocale(Number(value ?? 0), i18n.language),
  parser: (displayValue?: string) =>
    parseNumberByLocale(displayValue, i18n.language),
});
