import React from "react";
import { VocValueRegister } from "../../../app/types";
import { ValueRegisterColumn } from "./ValueRegisterContainer";
import ValueRegisterInputRow from "./ValueRegisterInputRow";

interface ValueRegisterInputRowListProps {
  columns: ValueRegisterColumn[];
  valueDataList: VocValueRegister[];
  inputIdPrefix: string;
  //   localeContext: LocaleContextValue;
  onValueChange: (
    newValue: number | string,
    prevValue: number | string,
    fieldName: keyof VocValueRegister,
    rowIndex: number,
    data: VocValueRegister
  ) => void;
  onInputKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement | HTMLAnchorElement>,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => void;
  onInputFocus: (
    e: React.FocusEvent<HTMLInputElement | HTMLAnchorElement>,
    fieldName: keyof VocValueRegister
  ) => void;
}

const ValueRegisterInputRowList: React.FC<ValueRegisterInputRowListProps> = ({
  columns,
  valueDataList,
  inputIdPrefix,
  onValueChange,
  onInputKeyDown,
  onInputFocus,
}) => {
  return (
    <>
      {columns?.length > 0 &&
        valueDataList.map((valueData, idx) => {
          return (
            <ValueRegisterInputRow
              key={valueData.compensationEventId}
              columns={columns}
              rowIndex={idx}
              valueData={valueData}
              inputIdPrefix={inputIdPrefix}
              onValueChange={onValueChange}
              onInputKeyDown={onInputKeyDown}
              onInputFocus={onInputFocus}
            />
          );
        })}
    </>
  );
};

export default React.memo(ValueRegisterInputRowList);
