import React from "react";

import { DatePicker, IDatePickerProps } from "office-ui-fabric-react";

import { mapDateWithWeekdaysToString } from "../../utils/mappers";
import { AppFormField } from "./";
import { AppFormFieldProps } from "./AppFormField";

import "./AppFormCalendarField.scss";
import { useTranslation } from "react-i18next";
import moment from "moment";

export type AppFormCalendarFieldProps = {
  errorMessage?: string | string[];
  value?: Date;
  disableFutureDate?: boolean;
} & Pick<
  IDatePickerProps,
  | "placeholder"
  | "label"
  | "isRequired"
  | "disableAutoFocus"
  | "onClick"
  | "disabled"
> & {
    onChange: IDatePickerProps["onSelectDate"];
  } & Omit<AppFormFieldProps, "onClick">;

const AppFormCalendarField: React.FC<AppFormCalendarFieldProps> = ({
  label,
  value,
  isRequired,
  className,
  disableAutoFocus,
  errorMessage,
  disabled,
  onChange,
  onClick,
  disableFutureDate = false,
  ...restProps
}) => {
  const { t } = useTranslation();
  return (
    <AppFormField
      {...restProps}
      className={`app-form-calender-field${className ? ` ${className}` : ""}${
        errorMessage ? ` error` : ""
      }`}
    >
      <DatePicker
        disabled={disabled}
        isRequired={isRequired}
        placeholder={t("voc.common.pleaseSelect")}
        label={label}
        value={value}
        maxDate={disableFutureDate ? moment(Date.now()).toDate() : undefined}
        onSelectDate={onChange}
        onClick={onClick}
        disableAutoFocus={disableAutoFocus}
        formatDate={(date) => mapDateWithWeekdaysToString(date)}
      />
      {errorMessage && (
        <div role="alert" className="error">
          <p>
            {Array.isArray(errorMessage)
              ? errorMessage.join(" / ")
              : errorMessage}
          </p>
        </div>
      )}
    </AppFormField>
  );
};

export default AppFormCalendarField;
