import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { head } from "lodash";
import { BaseButton, Icon, IContextualMenuProps } from "office-ui-fabric-react";

import { VocRecordStatus, VocSupportDocument } from "../../../../app/types";
import { mapDateToShortString } from "../../../../app/utils/mappers";

import "./DayCellLayout.scss";

interface DayCellLayoutProps {
  date?: Date;
  dateStatus: VocRecordStatus;
  showMonth?: boolean;
  remarks?: ReactNode[];
  relatedRemarks?: ReactNode[];
  menu: IContextualMenuProps;
  supportDocuments: VocSupportDocument[];
  eventCount: number;
}

const DayCellLayout: React.FC<DayCellLayoutProps> = ({
  date,
  dateStatus,
  showMonth,
  remarks,
  menu,
  supportDocuments,
  eventCount,
  relatedRemarks,
}) => {
  const { t } = useTranslation();
  // const isPermitted = Array.isArray(permissions)
  //   ? false
  //   : window.cpcs.permissions[permissions] &&
  //     window.cpcs.permissions[permissions] === true;\
  const isPermitted = true;

  const dateStatusFound = Object.entries(VocRecordStatus).find(
    (entry) => entry[1] === dateStatus
  );

  return date ? (
    <td
      className={
        "voc-records-tab-day-cell" +
        (dateStatus
          ? ` voc-records-tab-status voc-records-tab-status-${
              dateStatusFound === undefined
                ? "none"
                : dateStatusFound[0].toLowerCase().replace(/_/g, "-")
            }`
          : "")
      }
    >
      <div>
        <div className="event-count">
          {eventCount
            ? eventCount +
              t("voc.common.event", { count: eventCount }).toLocaleUpperCase()
            : ""}
          {relatedRemarks && (
            <div className="related-event">{relatedRemarks}</div>
          )}
        </div>
        {isPermitted ? (
          <BaseButton
            disabled={date > new Date()}
            className="cell-date"
            menuProps={date > new Date() ? undefined : menu}
          >
            {showMonth ? mapDateToShortString(date) : date.getDate()}
            {remarks && <p className="remarks">{remarks}</p>}
          </BaseButton>
        ) : (
          <BaseButton disabled={date > new Date()} className="cell-date">
            {showMonth ? mapDateToShortString(date) : date.getDate()}
          </BaseButton>
        )}

        {supportDocuments.length > 0 && (
          <span className="cell-file">
            <BaseButton
              className={`file-button${dateStatus ? "" : " default"}`}
              onMouseOver={(e) => {
                const btn = e.target as HTMLButtonElement;
                if (!btn.classList.contains("is-expanded")) {
                  btn.click();
                }
              }}
              onMouseLeave={(e) => {
                const btn = e.target as HTMLButtonElement;
                if (btn.classList.contains("is-expanded")) {
                  btn.click();
                }
              }}
              menuProps={{
                className: "voc-records-tab-cell-doc-menu",
                shouldFocusOnMount: true,
                items: supportDocuments.map((doc) => {
                  return {
                    key: String(doc.id),
                    onClick: (e) => {
                      e.stopPropagation();
                      if (doc?.documentFiles.length) {
                        window.open(head(doc?.documentFiles).url, "_blank");
                      }
                    },
                    text: `${doc.details.subject} (${
                      doc.documentFiles?.length
                        ? doc.documentFiles[0].fileName
                        : doc.link || "--"
                    })`,
                  };
                }),
              }}
            >
              <Icon iconName="PageSolid" />
              {supportDocuments
                .filter((_, idx) => idx > 0)
                .map((doc) => (
                  <span key={doc.id} className="file-pages"></span>
                ))}
            </BaseButton>
          </span>
        )}
      </div>
    </td>
  ) : (
    <td className="voc-records-tab-day-cell"></td>
  );
};

export default DayCellLayout;
