import React from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import AppButton, {
  AppButtonType,
} from "../../../../app/components/app-button";
import AppCollapsible from "../../../../app/components/app-collapsible/AppCollapsible";
import {
  VocCustomUi,
  VocCustomUiLayoutItemType,
  VocEvent,
  VocEventType,
} from "../../../../app/types";
import { LocationOption } from "../../../../app/utils/location";
import getQuestionList from "./GetQuestionList";
import { SummaryTabDropdownOptions } from "./UpperCardsContainer";

import "./UpperCardsLayout.scss";
import AccessVisible from "../../../../app/components/app-access-visible/AppAccessVisible";

// import AccessVisible from "../../../../app/components/app-access-visible/AppAccessVisible";

interface UpperCardsLayoutProps {
  form: VocEvent;
  eventType: VocEventType;
  eventVoSerial: string;
  formDropdownOptions: SummaryTabDropdownOptions;
  libLocations: LocationOption[];
  editingFieldGroups: string[];
  customUi: VocCustomUi;
  formErrors: any;
  onEdit: (groupKey: string) => void;
  onFormChange: (values: Partial<VocEvent>) => void;
  onGetSiteEvents: (filterText: string) => Promise<VocEvent[]>;
  onSave: (groupKey: string) => void;
  onCancel: (groupKey: string) => void;
}

const UpperCardsLayout: React.FC<UpperCardsLayoutProps> = ({
  form,
  eventType,
  eventVoSerial,
  formDropdownOptions,
  libLocations,
  editingFieldGroups,
  formErrors,
  customUi,
  onEdit,
  onFormChange,
  onGetSiteEvents,
  onSave,
  onCancel,
}) => {
  const { t } = useTranslation();
  const handleCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    groupKey: string
  ) => {
    if (
      !(e.target as any).parentNode.parentNode.parentNode.className.includes(
        "collapsed"
      )
    ) {
      e.stopPropagation();
    }
    onCancel(groupKey);
  };

  const handleSave = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    groupKey: string
  ) => {
    if (
      !(e.target as any).parentNode.parentNode.parentNode.className.includes(
        "collapsed"
      )
    ) {
      e.stopPropagation();
    }
    onSave(groupKey);
  };

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    groupKey: string
  ) => {
    if (
      !(e.target as any).parentNode.parentNode.parentNode.className.includes(
        "collapsed"
      )
    ) {
      e.stopPropagation();
    }
    onEdit(groupKey);
  };
  const getCollapsible = (groupKey: string) => {
    const grp = customUi.layout.find(
      (grp: { key: string }) => grp.key === groupKey
    );
    if (grp && grp.type === VocCustomUiLayoutItemType.LayoutGroup) {
      if (grp.members.find((member: { display: any }) => member.display)) {
        return (
          <AppCollapsible
            className={classNames(
              "flex-child-height",
              groupKey === "background" && "infinite-child-height"
            )}
            defaultCollapsed={groupKey !== "generalInformation"}
            key={groupKey}
            hasFields={false}
            bodyChildren={
              form &&
              getQuestionList(
                t,
                groupKey,
                editingFieldGroups.includes(groupKey),
                form,
                eventType,
                eventVoSerial,
                formDropdownOptions,
                libLocations,
                formErrors,
                customUi,
                onFormChange,
                onGetSiteEvents
              )
            }
            headerChildren={
              <>
                <div className="title">
                  {grp.customName
                    ? grp.customName
                    : t(`voc.eventPage.${groupKey}`)}
                </div>
                <div className="edit">
                  {editingFieldGroups.includes(groupKey) ? (
                    <>
                      <AppButton
                        appButtonType={AppButtonType.Link}
                        onClick={(
                          e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => handleCancel(e, groupKey)}
                      >
                        {t("voc.common.cancel")}
                      </AppButton>
                      <AppButton
                        onClick={(
                          e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => handleSave(e, groupKey)}
                      >
                        {t("voc.common.save")}
                      </AppButton>
                    </>
                  ) : (
                    <AccessVisible
                      objectCode="compensation_event"
                      actionType="edit"
                    >
                      <AppButton
                        appButtonType={AppButtonType.Link}
                        onClick={(
                          e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => handleEdit(e, groupKey)}
                      >
                        {t("voc.common.edit")}
                      </AppButton>
                    </AccessVisible>
                  )}
                </div>
              </>
            }
          />
        );
      }
      return null;
    }
  };

  return (
    <div className="voc-event-summary-upper">
      {["generalInformation", "owner", "background"]
        .filter((key) =>
          customUi.layout.find(
            (group: { key: string; display: any }) =>
              group.key === key && group.display
          )
        )
        .map((key) => getCollapsible(key))}
    </div>
  );
};

export default UpperCardsLayout;
