import React from "react";
import { useTranslation } from "react-i18next";

import AccessVisible from "../../app/components/app-access-visible/AppAccessVisible";
import AppHeader from "../../app/components/app-header";
import { VocLibraryObject } from "../../app/types";
import { LibraryTypes } from "../libraries-services";
import AddLibraryButton from "./add/AddLibraryButton";
import ImportExcelButton from "./import-excel/ImportExcelButton";
import Clauses from "./panel/Clauses";
import Description from "./panel/Description";
import EmpoweredReps from "./panel/EmpoweredReps";
import KeyDates from "./panel/KeyDates";
import LabelAndDay from "./panel/LabelAndDay";
import TargetIncome from "./panel/TargetIncome";
import VarianceAndDisplayUnit from "./panel/VarianceAndDisplayUnit";

import "./Libraries.scss";
import { cloneDeep } from "lodash";

interface LibrariesProps {}

export const Libraries: React.FC<LibrariesProps> = () => {
  const { t } = useTranslation();

  const [refetchCounts, setRefetchCounts] = React.useState({
    VOC_VO_EMPOWER_REPS: 0,
    VOC_CLAUSE_INSTR: 0,
    VOC_CLAUSE_VALUATION: 0,
    VOC_SOURCES: 0,
    VOC_SOURCE_TYPE: 0,
    VOC_CONSTR_STATUS: 0,
    VOC_DELAY_EFFECTS: 0,
    VOC_CLIENT_NOTIFICATION: 0,
    VOC_TARGET_INCOME: 0,
    VOC_ESCALATION_RECORDS: 0,
    VOC_KEY_DATES: 0,
    VOC_REC_STATUS: 0,
    VOC_CLIENT_WITH_STATUS: 0,
    VOC_SUPPORT_DOC_STATUS: 0,
    VOC_EVENT_STATUS: 0,
  });

  const handleAdd = (data: VocLibraryObject, libraryType: LibraryTypes) => {
    setRefetchCounts((prevRefetchCounts) => {
      const newRefetchCounts = {
        ...prevRefetchCounts,
        [libraryType]: prevRefetchCounts[libraryType] + 1,
      };
      return newRefetchCounts;
    });
  };

  const handleImport = (response) => {
    setRefetchCounts((prevRefetchCounts) => {
      const newRefetchCounts = cloneDeep(prevRefetchCounts);
      Object.keys(newRefetchCounts).forEach((libraryType) => {
        newRefetchCounts[libraryType]++;
      });
      return newRefetchCounts;
    });
  };

  return (
    <AccessVisible
      objectCode="libraries"
      actionType="view"
      fallback={<i>{t("app.permission.accessDenied")}</i>}
    >
      <div className="libraries">
        <div className="libraries-container">
          <AppHeader text={t("voc.library.projectLibraries")}>
            <div className="flex-wrapper">
              <AccessVisible
                objectCode="libraries"
                actionType="create"
                fallback={<></>}
              >
                <ImportExcelButton
                  entityId={""}
                  onImport={handleImport}
                ></ImportExcelButton>
                <AddLibraryButton onAdd={handleAdd} />
              </AccessVisible>
            </div>
          </AppHeader>
          <div className="spine">
            <EmpoweredReps
              libraryType="VOC_VO_EMPOWER_REPS"
              refetchCount={refetchCounts["VOC_VO_EMPOWER_REPS"]}
            />
            <Clauses
              libraryType="VOC_CLAUSE_INSTR"
              refetchCount={refetchCounts["VOC_CLAUSE_INSTR"]}
            />
            <Clauses
              libraryType="VOC_CLAUSE_VALUATION"
              refetchCount={refetchCounts["VOC_CLAUSE_VALUATION"]}
            />
            <Description
              libraryType="VOC_SOURCES"
              refetchCount={refetchCounts["VOC_SOURCES"]}
            />
            <Description
              libraryType="VOC_SOURCE_TYPE"
              refetchCount={refetchCounts["VOC_SOURCE_TYPE"]}
            />
            <Description
              libraryType="VOC_CONSTR_STATUS"
              refetchCount={refetchCounts["VOC_CONSTR_STATUS"]}
            />
            <Description
              libraryType="VOC_DELAY_EFFECTS"
              refetchCount={refetchCounts["VOC_DELAY_EFFECTS"]}
            />
            <LabelAndDay
              libraryType="VOC_CLIENT_NOTIFICATION"
              refetchCount={refetchCounts["VOC_CLIENT_NOTIFICATION"]}
            />
            <TargetIncome
              libraryType="VOC_TARGET_INCOME"
              refetchCount={refetchCounts["VOC_TARGET_INCOME"]}
            />
            <LabelAndDay
              libraryType="VOC_ESCALATION_RECORDS"
              refetchCount={refetchCounts["VOC_ESCALATION_RECORDS"]}
            />
            <KeyDates
              libraryType="VOC_KEY_DATES"
              refetchCount={refetchCounts["VOC_KEY_DATES"]}
            />
            <Description
              libraryType="VOC_REC_STATUS"
              refetchCount={refetchCounts["VOC_REC_STATUS"]}
            />
            <Description
              libraryType="VOC_CLIENT_WITH_STATUS"
              refetchCount={refetchCounts["VOC_CLIENT_WITH_STATUS"]}
            />
            <Description
              libraryType="VOC_SUPPORT_DOC_STATUS"
              refetchCount={refetchCounts["VOC_SUPPORT_DOC_STATUS"]}
            />
            <Description
              libraryType="VOC_EVENT_STATUS"
              refetchCount={refetchCounts["VOC_EVENT_STATUS"]}
            />
            <VarianceAndDisplayUnit />
          </div>
        </div>
      </div>
    </AccessVisible>
  );
};

export default Libraries;
