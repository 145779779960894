import { Dashboard, VocVarianceDisplayUnit } from "../app/types";
import { apiRequest, ServiceConfig } from "../app/utils/backend/api-request";

interface SnapshotFilter {
  values: string[]; // Date string
}

export interface SnapshotRequestBody {
  limit: number;
  offset: number;
  filters: SnapshotFilter[];
}

export const getDashboardStatistic = (serviceConfig: ServiceConfig) => {
  return apiRequest<Dashboard>(`/dashboard/statistics`, serviceConfig);
};

export const getDashboardSnapshots = (
  serviceConfig: ServiceConfig,
  reqBody: SnapshotRequestBody
) => {
  return apiRequest<Dashboard>(`/dashboard/snapshots`, serviceConfig, {
    method: "POST",
    body: JSON.stringify(reqBody),
  });
};

export interface VarianceReport {
  key: string;
  numOfEvents: number;
  reportDetails: Report[];
  totalValue: number;
  type: string;
  sortOrder?: number;
}

export interface Report {
  rangeType: string;
  numOfEvents: number;
  totalValue: number;
  fromValue: number;
  toValue: number;
}

export const getVarianceReport = (serviceConfig: ServiceConfig) => {
  return apiRequest<VarianceReport[]>(`/variance/report`, serviceConfig);
};

export const getVarianceDisplayUnit = (serviceConfig: ServiceConfig) => {
  return apiRequest<VocVarianceDisplayUnit>(
    `/libraries/VOC_VARIANCE/displayUnit`,
    serviceConfig
  );
};
