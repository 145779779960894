import * as React from "react";
import { useTranslation } from "react-i18next";

import {
  Dropdown,
  IconButton,
  IDropdownOption,
  TextField,
} from "office-ui-fabric-react";

import { useDebounceLegacy } from "../../hooks/useDebounceLegacy";
import { NumericButtonsGroup } from "./NumericButtonsGroup";

import "./AppPagination.scss";

export interface AppPaginationProps {
  currentPage: number;
  pageSize: number;
  totalRecordCount: number;
  pageSizeOptions?: IDropdownOption[];
  onPageSizeChange?: (pageSize: number) => void;
  onPageNumberChange: (pageNumber: number) => void;
}

const AppPagination: React.FC<AppPaginationProps> = ({
  currentPage,
  pageSize,
  totalRecordCount,
  pageSizeOptions,
  onPageSizeChange,
  onPageNumberChange,
}) => {
  const { t } = useTranslation();
  const [goToPage, setGoToPage] = React.useState<number | undefined>(undefined);

  const getDefaultPageSizeOptions = (totalRecordCount: number) => [
    {
      key: 0,
      text: t("voc.common.pageCount", { count: totalRecordCount }),
    },
    {
      key: 15,
      text: "15",
    },
    {
      key: 50,
      text: "50",
    },
    {
      key: 100,
      text: "100",
    },
    {
      key: 200,
      text: "200",
    },
  ];

  const handleGoToPageChange = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (!newValue || (newValue && Number(newValue))) {
      setGoToPage(Number(newValue) || undefined);
    }
    if (goToPage === undefined && !Number(newValue)) {
      setGoToPage(undefined);
    }
  };

  const debouncedGoToPage = useDebounceLegacy(goToPage, 500);

  React.useEffect(() => {
    if (onPageNumberChange && debouncedGoToPage !== undefined) {
      onPageNumberChange(debouncedGoToPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedGoToPage]);

  const clearGoToPage = () => {
    if (goToPage) setGoToPage(undefined);
  };

  React.useEffect(() => {
    if (currentPage !== goToPage) {
      setGoToPage(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handlePageSizeChange = (
    _: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    if (!onPageSizeChange) {
      return;
    }
    onPageSizeChange(option?.key as number);
  };

  const maxPageNumber =
    pageSize === 0 ? 1 : Math.ceil(totalRecordCount / pageSize);

  const handlePrevClick = () => {
    if (!onPageNumberChange || pageSize === 0 || currentPage === 1) {
      return;
    }

    clearGoToPage();
    onPageNumberChange(currentPage - 1);
  };

  const handleNextClick = () => {
    if (
      !onPageNumberChange ||
      pageSize === 0 ||
      currentPage === maxPageNumber
    ) {
      return;
    }

    clearGoToPage();
    onPageNumberChange(currentPage + 1);
  };

  const handlePageNumberChange = (pageNumber: number) => {
    if (!onPageNumberChange) {
      return;
    }

    clearGoToPage();
    onPageNumberChange(pageNumber);
  };

  return (
    <div className="pagination">
      <div className="pagination-perpage">{t("voc.common.itemsPerPage")}</div>
      <Dropdown
        selectedKey={pageSize}
        onChange={handlePageSizeChange}
        options={
          pageSizeOptions
            ? pageSizeOptions
            : getDefaultPageSizeOptions(totalRecordCount)
        }
      />
      <div className="page-control">
        <IconButton
          className="page-control-prev"
          iconProps={{ iconName: "ChevronLeft" }}
          title={
            "voc.common.previousPage"
            // i18n.renderToString("voc.common.previousPage")
          }
          ariaLabel="Previous"
          disabled={currentPage === 1 || currentPage === maxPageNumber}
          onClick={handlePrevClick}
        />
        <NumericButtonsGroup
          pageNumber={currentPage}
          totalPage={maxPageNumber}
          onPageNumberChange={handlePageNumberChange}
        />
        <div className="page-control-sep"></div>
        <IconButton
          className="page-control-next"
          iconProps={{ iconName: "ChevronRight" }}
          title={
            "voc.common.nextPage"
            // i18n.renderToString("voc.common.nextPage")
          }
          ariaLabel="Next"
          disabled={currentPage === maxPageNumber}
          onClick={handleNextClick}
        />
      </div>
      <div className="page-control-go">
        {t("common.table.pagination.goTo")}
        <TextField
          readOnly={pageSize === 0}
          value={goToPage === undefined ? "" : goToPage.toString()}
          onChange={(e, value) => {
            if (maxPageNumber > 0) {
              handleGoToPageChange(e, value);
            } else {
              handleGoToPageChange(e, "");
            }
          }}
          placeholder={t("common.table.pagination.goTo.placeHolder")}
        />
        {` ${t("common.table.pagination.goTo.of")} ${maxPageNumber}`}
      </div>
    </div>
  );
};

export default React.memo(AppPagination);
