import React from "react";

import AppNumericInput from "../app-numeric-input";
import { AppNumericInputProps } from "../app-numeric-input/AppNumericInput";
import { AppFormField } from "./";

import "./AppFormNumericField.scss";

export interface AppFormNumericFieldProps
  extends Omit<AppNumericInputProps, "onChange" | "onClick"> {
  label?: string;
  errorMessage?: string | string[];
  required?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const AppFormNumericField: React.FC<AppFormNumericFieldProps> = ({
  label,
  errorMessage,
  className,
  required,
  onClick,
  ...restProps
}) => {
  return (
    <AppFormField
      onClick={onClick}
      className={`app-numeric-field${className ? ` ${className}` : ""}`}
    >
      {label && (
        <label>
          {`${required ? `${label} ` : label}`}
          {required && <span className="required">*</span>}
        </label>
      )}
      <AppNumericInput {...restProps} />
      {errorMessage && (
        <div role="alert" className="error">
          <p>
            {Array.isArray(errorMessage)
              ? errorMessage.join(" / ")
              : errorMessage}
          </p>
        </div>
      )}
    </AppFormField>
  );
};

export default AppFormNumericField;
