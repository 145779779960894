import React, { ReactNode } from "react";

import useAppContext from "../../hooks/useAppContext";
import { Right } from "../../services/app";

interface AccessVisibleProps {
  objectCode: Right["objectCode"];
  actionType: Right["actionType"];
  fallback?: ReactNode;
  children: ReactNode;
}

const AccessVisible: React.FC<AccessVisibleProps> = ({
  objectCode,
  actionType,
  fallback,
  children,
}) => {
  const { hasRight } = useAppContext();
  if (hasRight({ objectCode, actionType })) {
    return <>{children}</>;
  }
  if (fallback === undefined) {
    return <div>Access restricted.</div>;
  }
  return <>{fallback}</>;
};

export default AccessVisible;
