type ExcludeOptions =
  | "exclude-whitespace"
  | "exclude-empty-array"
  | "exclude-empty-object";

export const hasValue = <T>(
  data: T,
  excludeOptions: ExcludeOptions[] = []
): data is Exclude<T, null | undefined> => {
  if (data === null || data === undefined || data === "") {
    return false;
  }

  if (
    excludeOptions.includes("exclude-whitespace") &&
    typeof data === "string" &&
    data.trim() === ""
  ) {
    return false;
  }

  if (
    excludeOptions.includes("exclude-empty-array") &&
    Array.isArray(data) &&
    data.length === 0
  ) {
    return false;
  }

  if (
    excludeOptions.includes("exclude-empty-object") &&
    typeof data === "object" &&
    Object.keys(data).length === 0
  ) {
    return false;
  }

  return true;
};
