import React from "react";
import { useTranslation } from "react-i18next";

import { IChoiceGroupOption, Icon } from "office-ui-fabric-react";

import AppButton, { AppButtonType } from "../../../app/components/app-button";
import AppCollapsible from "../../../app/components/app-collapsible/AppCollapsible";
import AppFileUpload from "../../../app/components/app-file-upload/AppFileUpload";
import {
  AppFormField,
  AppFormNumericField,
  AppFormRadioField,
  AppFormTextField,
} from "../../../app/components/app-form";
import { VocClaim } from "../../../app/types";
import AddClaimButton from "./AddClaim/AddClaimButton";

import "./ClaimsTab.scss";

interface ClaimsTabLayoutProps {
  eventId: string;
  claimsForm: VocClaim[];
  editingClaimIds: number[];
  formErrors: any[];
  loading: boolean;
  onEdit: (claimId: number) => void;
  onFormChange: (values: Partial<VocClaim>, claimId: number) => void;
  onSave: (claimId: number) => void;
  onCancel: (claimId: number) => void;
  onRefreshData: () => void;
}

const ClaimsTabLayout: React.FC<ClaimsTabLayoutProps> = ({
  eventId,
  claimsForm,
  editingClaimIds,
  loading,
  onEdit,
  onFormChange,
  onSave,
  onCancel,
  onRefreshData,
}) => {
  const { t } = useTranslation();
  const yesNoOptions: IChoiceGroupOption[] = [
    {
      key: "true",
      text: t("voc.common.yes"),
    },
    {
      key: "false",
      text: t("voc.common.no"),
    },
  ];

  const handleCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    claimId: number
  ) => {
    if (
      !(e.target as any).parentNode.parentNode.parentNode.className.includes(
        "collapsed"
      )
    ) {
      e.stopPropagation();
    }
    onCancel(claimId);
  };

  const handleSave = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    claimId: number
  ) => {
    if (
      !(e.target as any).parentNode.parentNode.parentNode.className.includes(
        "collapsed"
      )
    ) {
      e.stopPropagation();
    }
    onSave(claimId);
  };

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    claimId: number
  ) => {
    if (
      !(e.target as any).parentNode.parentNode.parentNode.className.includes(
        "collapsed"
      )
    ) {
      e.stopPropagation();
    }
    onEdit(claimId);
  };

  return (
    <div className="claims-tab-container">
      <AddClaimButton eventId={eventId} onRefreshData={onRefreshData} />

      <div className="spine">
        <div className="headers">
          <div className="header size-s header-claim-no">
            {t("voc.eventPage.claims.claimNumber")}
          </div>
          <div className="header size-l header-claim-heading">
            {t("voc.eventPage.claims.claimHeading")}
          </div>
          <div className="header size-m header-applied">
            {t("voc.eventPage.claims.appliedDay")}
          </div>
          <div className="header size-m header-target">
            {t("voc.eventPage.claims.targetDay")}
          </div>
          <div className="header size-m header-awarded">
            {t("voc.eventPage.claims.assessedDay")}
          </div>
          <div className="header size-m header-t-vs-a">
            {t("voc.eventPage.claims.varianceVsAward")}
          </div>
        </div>
        {claimsForm?.length ? (
          claimsForm.map((claim) => (
            <AppCollapsible
              defaultCollapsed={false}
              key={claim.id}
              hasFields={true}
              fields={[
                {
                  property: t(
                    "voc.eventPage.claims.agreementToQuantumQuantities"
                  ),
                  value: editingClaimIds.includes(claim.id) ? (
                    <AppFormRadioField
                      options={yesNoOptions}
                      selectedKey={claim.agreeQuantumQty?.toString()}
                      onChange={(
                        _: React.FormEvent,
                        option: IChoiceGroupOption
                      ) =>
                        onFormChange(
                          { agreeQuantumQty: option.key === "true" },
                          claim.id
                        )
                      }
                    />
                  ) : (
                    yesNoOptions.find(
                      (opt) => opt.key === (!!claim.agreeQuantumQty).toString()
                    )?.text
                  ),
                  fieldtype: "string",
                },
                {
                  property: t("voc.eventPage.claims.agreementToQuantumPrices"),
                  value: editingClaimIds.includes(claim.id) ? (
                    <AppFormRadioField
                      options={yesNoOptions}
                      selectedKey={claim.agreeQuantumRates?.toString()}
                      onChange={(
                        _: React.FormEvent,
                        option: IChoiceGroupOption
                      ) =>
                        onFormChange(
                          { agreeQuantumRates: option.key === "true" },
                          claim.id
                        )
                      }
                    />
                  ) : (
                    yesNoOptions.find(
                      (opt) =>
                        opt.key === (!!claim.agreeQuantumRates).toString()
                    )?.text
                  ),
                  fieldtype: "string",
                },
                {
                  property: t("voc.eventPage.claims.agreementToQuantumOhp"),
                  className: "overhead",
                  value: editingClaimIds.includes(claim.id) ? (
                    <AppFormRadioField
                      options={yesNoOptions}
                      selectedKey={claim.agreeQuantumOhnp?.toString()}
                      onChange={(
                        _: React.FormEvent,
                        option: IChoiceGroupOption
                      ) =>
                        onFormChange(
                          { agreeQuantumOhnp: option.key === "true" },
                          claim.id
                        )
                      }
                    />
                  ) : (
                    yesNoOptions.find(
                      (opt) => opt.key === (!!claim.agreeQuantumOhnp).toString()
                    )?.text
                  ),
                  fieldtype: "string",
                },
                {
                  property: "",
                  value: null,
                  fieldtype: "string",
                },
                {
                  property: t("voc.eventPage.claims.referenceFile"),
                  value: editingClaimIds.includes(claim.id) ? (
                    <AppFormField className="file-upload">
                      {claim.claimSupportDocument?.assetId ? (
                        <>
                          <p className="file-info">
                            <a
                              href={claim.claimSupportDocument.url}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              <Icon iconName="PageSolid" />
                              {claim.claimSupportDocument.fileName}
                            </a>
                            <button
                              className="remove"
                              onClick={() =>
                                onFormChange(
                                  {
                                    claimSupportDocument: null,
                                  },
                                  claim.id
                                )
                              }
                            >
                              X
                            </button>
                          </p>
                        </>
                      ) : (
                        <AppFileUpload
                          fileLimit={1}
                          label={undefined}
                          onChange={(files) => {
                            if (files.length) {
                              onFormChange(
                                {
                                  claimSupportDocument: {
                                    url: (files[0] as any).url,
                                    fileName: files[0].fileName,
                                    assetId: files[0].assetId,
                                    mimeType: files[0].mimeType,
                                  },
                                },
                                claim.id
                              );
                            }
                          }}
                        />
                      )}
                    </AppFormField>
                  ) : (
                    claim.claimSupportDocument?.assetId && (
                      <>
                        <p className="file-info">
                          <a
                            href={claim.claimSupportDocument.url}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <Icon iconName="PageSolid" />
                            {claim.claimSupportDocument.fileName}
                          </a>
                        </p>
                      </>
                    )
                  ),
                  fieldtype: "string",
                },
                {
                  property: t("voc.eventPage.summary.comment"),
                  className: "comment",
                  value: editingClaimIds.includes(claim.id) ? (
                    <AppFormTextField
                      value={claim.claimSupportDocumentComment}
                      onChange={(_, value) =>
                        onFormChange(
                          { claimSupportDocumentComment: value },
                          claim.id
                        )
                      }
                    />
                  ) : (
                    claim.claimSupportDocumentComment
                  ),
                  fieldtype: "string",
                },
              ]}
              headerChildren={
                <>
                  <div className="c-col first c-claim-no size-s">
                    {/* use eventId here to keep it consistent with summary tab */}
                    <div className="c-no">{claim.serial}</div>
                  </div>
                  <div className="c-col size-l c-claim-heading">
                    {editingClaimIds.includes(claim.id) ? (
                      <AppFormTextField
                        value={claim.title}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(_, value) =>
                          onFormChange({ title: value }, claim.id)
                        }
                      />
                    ) : (
                      claim.title
                    )}
                  </div>
                  <div className="c-col size-m c-applied">
                    {editingClaimIds.includes(claim.id) ? (
                      <AppFormNumericField
                        value={claim.appliedDays}
                        decimalPlaces={0}
                        onClick={(e) => e.stopPropagation()}
                        onValueChange={(value) => {
                          onFormChange({ appliedDays: value || 0 }, claim.id);
                        }}
                      />
                    ) : (
                      claim.appliedDays
                    )}
                  </div>
                  <div className="c-col size-m c-target">
                    {editingClaimIds.includes(claim.id) ? (
                      <AppFormNumericField
                        value={claim.targetDays}
                        decimalPlaces={0}
                        onClick={(e) => e.stopPropagation()}
                        onValueChange={(value) => {
                          onFormChange({ targetDays: value || 0 }, claim.id);
                        }}
                      />
                    ) : (
                      claim.targetDays
                    )}
                  </div>
                  <div className="c-col size-m c-awarded">
                    {editingClaimIds.includes(claim.id) ? (
                      <AppFormNumericField
                        value={claim.awardedDays}
                        decimalPlaces={0}
                        onClick={(e) => e.stopPropagation()}
                        onValueChange={(value) => {
                          onFormChange({ awardedDays: value || 0 }, claim.id);
                        }}
                      />
                    ) : (
                      claim.awardedDays
                    )}
                  </div>
                  <div className="c-col size-m">
                    {editingClaimIds.includes(claim.id) ? (
                      <AppFormNumericField
                        value={claim.awardedDays - claim.targetDays}
                        decimalPlaces={0}
                        disabled={true}
                        onClick={(e) => e.stopPropagation()}
                        onValueChange={(value) => {
                          onFormChange({ targetDays: value || 0 }, claim.id);
                        }}
                      />
                    ) : (
                      claim.awardedDays - claim.targetDays
                    )}
                  </div>
                  <div
                    className={`c-col size-l${
                      editingClaimIds.includes(claim.id) ? " editing" : ""
                    }`}
                  >
                    {editingClaimIds.includes(claim.id) ? (
                      <>
                        <AppButton
                          disabled={loading}
                          appButtonType={AppButtonType.Link}
                          onClick={(e) => handleCancel(e, claim.id)}
                        >
                          {t("voc.common.cancel")}
                        </AppButton>
                        <AppButton
                          disabled={loading}
                          onClick={(e) => handleSave(e, claim.id)}
                        >
                          {t("voc.common.save")}
                        </AppButton>
                      </>
                    ) : (
                      <AppButton
                        appButtonType={AppButtonType.Link}
                        onClick={(e) => handleEdit(e, claim.id)}
                        disabled={loading}
                      >
                        {t("voc.common.edit")}
                      </AppButton>
                    )}
                  </div>
                </>
              }
              bodyChildren={null}
            />
          ))
        ) : (
          <div className="no-record">
            {t("voc.appDataListComponent.noRecords")}
          </div>
        )}
      </div>
    </div>
  );
};

export default ClaimsTabLayout;
