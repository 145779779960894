import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import { CheckCircleFilled } from "@ant-design/icons";
import { NxpButton, NxpModal } from "@nexploretechnology/nxp-ui";
import { Typography } from "antd";

const useStyles = createUseStyles((theme) => ({
  DemoModalWithSubheading: {},
}));

interface DemoModalWithSubheadingProps {}

const DemoModalWithSubheading: React.FC<DemoModalWithSubheadingProps> = () => {
  const classes = useStyles();
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div className={classes.DemoModalWithSubheading}>
      <NxpButton onClick={() => setModalVisible(true)}>Show Modal</NxpButton>
      <NxpModal
        visible={modalVisible}
        title="Modal with subheader"
        subheadingContent={
          <>
            test subheading{" "}
            <Typography.Text type="success">
              <CheckCircleFilled />
            </Typography.Text>
          </>
        }
        onCancel={() => setModalVisible(false)}
        footer={
          <>
            <NxpButton type="default" onClick={() => setModalVisible(false)}>
              Cancel
            </NxpButton>
            <NxpButton>Save</NxpButton>
          </>
        }
      >
        <p>Content...</p>
        <p>Content...</p>
        <p>Content...</p>
        <p>Content...</p>
        <p>Content...</p>
        <p>Content...</p>
      </NxpModal>
    </div>
  );
};

export default DemoModalWithSubheading;
