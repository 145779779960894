import React from "react";

import {
  AppFormCalendarField,
  AppFormRow,
  AppFormTextField,
} from "../../../../app/components/app-form";
import { VocLibraryObject } from "../../../../app/types";

interface DeadlineProps {
  form: Partial<VocLibraryObject>;
  formErrors: any; // ValidationResult<VocLibraryObject>;
  onFormChange: (changeValues: Partial<VocLibraryObject>) => void;
}

const Deadline: React.FC<DeadlineProps> = ({
  form,
  formErrors,
  onFormChange,
}) => {
  return (
    <>
      <AppFormRow>
        <AppFormTextField
          required={true}
          label="Deadline / Key Date"
          name="label"
          value={form.label}
          errorMessage={formErrors && formErrors.label}
          onChange={(_, value) => onFormChange({ label: value })}
        />
        <AppFormCalendarField
          isRequired={true}
          label="Contractual Date"
          value={form.contractualDate}
          errorMessage={formErrors && formErrors.contractualDate}
          onChange={(date) => onFormChange({ contractualDate: date })}
        />
      </AppFormRow>
    </>
  );
};

export default Deadline;
