// import { ValidationResult } from 'cpcs-common-ui';
import React from "react";

import { Col, Row } from "antd";
import i18next, { t as translate } from "i18next";
import {
  Dropdown,
  IBasePickerSuggestionsProps,
  IChoiceGroupOption,
  Icon,
  ITag,
  TagPicker,
  TextField,
} from "office-ui-fabric-react";

import { AppFormDropdownField } from "../../../../app/components/app-form";
import AppQuestionList from "../../../../app/components/app-question-list";
import {
  AppQuestionListField,
  AppQuestionListFieldTypes,
} from "../../../../app/components/app-question-list/AppQuestionList";
import { VocCustomUi, VocEvent, VocEventType } from "../../../../app/types";
import {
  INSTRUCTED_BY_NONE,
  INSTRUCTED_BY_OTHER,
} from "../../../../app/utils/const";
import { getQuestionGroupFields } from "../../../../app/utils/custom-ui";
import { LocationOption } from "../../../../app/utils/location";
import { mapDateToString } from "../../../../app/utils/mappers";
import { convertUrl } from "../../../../app/utils/string";
import { SummaryTabDropdownOptions } from "./UpperCardsContainer";
import { getUserDisplayName } from "../../../../app/utils/helper/get-user-display-name";

const yesNoOptions: IChoiceGroupOption[] = [
  {
    key: "true",
    text: i18next.t("voc.common.yes"), //no easy way to inject localeContext here...
  },
  {
    key: "false",
    text: i18next.t("voc.common.no"), //no easy way to inject localeContext here...
  },
];

const getYesNoLabel = (yesNo?: boolean) => {
  return yesNoOptions.find((option) => option.key === yesNo?.toString())?.text;
};

const getVoAndClaimFields = (
  form: VocEvent,
  eventType: VocEventType,
  eventVoSerial: string
): AppQuestionListField[] => {
  if (eventType === VocEventType.VO) {
    return [
      {
        fieldType: AppQuestionListFieldTypes.Custom,
        fieldName: "vo",
        caption: "VO NUMBER",
        contentForRead: eventVoSerial,
      },
    ];
  }
  if (eventType === VocEventType.Claim) {
    return [
      {
        fieldType: AppQuestionListFieldTypes.Custom,
        fieldName: "claim",
        caption: "CLAIM NUMBER",
        contentForRead: form.relatedClaimsDigest
          ? form.relatedClaimsDigest
              .filter((claim) => !claim.subClaim)
              .map((claim) => claim.serial)
              .join(", ")
          : "",
      },
    ];
  }
  if (eventType === VocEventType.VO_And_Claim) {
    return [
      {
        fieldType: AppQuestionListFieldTypes.Custom,
        fieldName: "vo",
        caption: translate("voc.eventPage.summary.voNumber"),
        contentForRead: eventVoSerial,
      },
      {
        fieldType: AppQuestionListFieldTypes.Custom,
        fieldName: "claim",
        caption: translate("voc.eventPage.summary.claimNumber"),
        contentForRead: form.relatedClaimsDigest
          ? form.relatedClaimsDigest
              .filter((claim) => !claim.subClaim)
              .map((claim) => claim.serial)
              .join(", ")
          : "",
      },
    ];
  }
};

const getCollapsibleFields = (
  translate: any,
  groupKey: string,
  isEditing: boolean,
  form: VocEvent,
  eventType: VocEventType,
  eventVoSerial: string,
  formDropdownOptions: SummaryTabDropdownOptions,
  libLocations: LocationOption[],
  formErrors: any,
  customUi: VocCustomUi,
  onFormChange: (form: Partial<VocEvent & { [key: string]: any }>) => void, // { [key: string]: any } is needed for custom fields
  onGetSiteEvents: (filterText: string) => Promise<VocEvent[]>
) => {
  const getTextFromItem = (item: ITag) => item.name;
  const filterEntityUserTags = (
    filterText: string,
    tagList: ITag[]
  ): ITag[] => {
    const userOpt = filterText
      ? formDropdownOptions.entityUser.filter(
          (tag) =>
            !tagList.find((selected) => selected.key === tag.key) &&
            tag.name.toLowerCase().includes(filterText.toLowerCase())
        )
      : [];
    return userOpt;
  };
  const filterVoTags = (filterText: string, tagList: ITag[]): ITag[] => {
    return filterText
      ? formDropdownOptions.vo.filter((tag) => {
          return (
            !tagList.find((selected) => selected.key === tag.key) &&
            tag.name.toLowerCase().includes(filterText.toLowerCase())
          );
        })
      : [];
  };
  const filterClaimTags = (filterText: string, tagList: ITag[]): ITag[] => {
    return filterText
      ? formDropdownOptions.claim.filter(
          (tag) =>
            !tagList.find((selected) => selected.key === tag.key) &&
            tag.name.toLowerCase().includes(filterText.toLowerCase())
        )
      : [];
  };

  const filterSiteEvents = (
    filterText: string,
    tagList: ITag[]
  ): ITag[] | Promise<ITag[]> => {
    return filterText
      ? onGetSiteEvents(filterText).then((siteEvents) =>
          siteEvents
            .filter((item) => !tagList.find((tag) => tag.key === item.id))
            .map((item) => ({
              key: item.id,
              name: `${item.subject} (${item.serial})`,
            }))
        )
      : [];
  };

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: translate(
      "voc.appFieldBlockComponent.suggestedChoices"
    ),
    noResultsFoundText: translate("voc.appFieldBlockComponent.noResultsFound"),
  };

  const upperCardsPresetFields: AppQuestionListField[] = [
    {
      fieldType: AppQuestionListFieldTypes.TextInput,
      fieldName: "eventTitle",
      caption: translate("voc.eventPage.summary.eventTitle"),
      value: form.eventTitle,
      isRequired: true,
      errorMessage: formErrors.eventTitle,
      contentForRead: form.eventTitle,
      onChange: (_, value) => onFormChange({ eventTitle: value }),
    },
    {
      fieldType: AppQuestionListFieldTypes.Dropdown,
      fieldName: "eventType",
      caption: translate("voc.eventPage.summary.eventType"),
      options: formDropdownOptions.eventType,
      value: form.eventType,
      onChange: (_, option) => {
        onFormChange({
          eventType: option.key as VocEventType,
        });
      },
      contentForRead:
        form.eventType &&
        formDropdownOptions.eventType.length &&
        formDropdownOptions.eventType.find(
          (option) => option.key === form.eventType
        )?.text,
    },
    ...getVoAndClaimFields(form, eventType, eventVoSerial),
    {
      fieldType: AppQuestionListFieldTypes.Custom,
      fieldName: "otherVoReferences",
      caption: translate("voc.eventPage.summary.otherVOReference"),
      contentForEdit: (
        <TagPicker
          removeButtonAriaLabel={translate("voc.appFieldBlockComponent.remove")}
          onResolveSuggestions={filterVoTags}
          getTextFromItem={getTextFromItem}
          pickerSuggestionsProps={pickerSuggestionsProps}
          defaultSelectedItems={
            form.relatedVoDigest?.length
              ? form.relatedVoDigest.map((vo) => ({
                  name: vo.serial,
                  key: vo.id,
                }))
              : []
          }
          onChange={(items) =>
            onFormChange({
              otherVoReferences: items.length
                ? (items.map((item) => item.key) as string[])
                : [],
            })
          }
        />
      ),
      contentForRead: form.relatedVoDigest?.length
        ? form.relatedVoDigest.map((vo) => vo.serial).join(", ")
        : "",
    },
    {
      fieldType: AppQuestionListFieldTypes.Custom,
      fieldName: "otherClaimReferences",
      caption: translate("voc.eventPage.summary.otherClaimReference"),
      contentForEdit: (
        <TagPicker
          removeButtonAriaLabel={translate("voc.appFieldBlockComponent.remove")}
          onResolveSuggestions={filterClaimTags}
          getTextFromItem={getTextFromItem}
          pickerSuggestionsProps={pickerSuggestionsProps}
          defaultSelectedItems={
            form.relatedClaimsDigest?.length
              ? form.relatedClaimsDigest
                  .filter((claim) => claim.subClaim)
                  .map((claim) => {
                    return {
                      name: claim.serial,
                      key: claim?.eventId,
                    };
                  })
              : []
          }
          onChange={(items) =>
            onFormChange({
              otherClaimReferences: items.length
                ? (items.map((item) => item.key) as string[])
                : [],
            })
          }
        />
      ),
      contentForRead: form.relatedClaimsDigest?.length
        ? form.relatedClaimsDigest
            .filter((claim) => claim.subClaim)
            .map((claim) => claim.serial)
            .join(", ")
        : "",
    },
    {
      fieldType: AppQuestionListFieldTypes.TextInput,
      fieldName: "reference",
      caption: translate("voc.eventPage.summary.eventReference"),
      value: form.reference,
      isRequired: false,
      errorMessage: formErrors.reference,
      contentForRead: convertUrl(form.reference) && (form.reference || null),
      onChange: (_, value) => onFormChange({ reference: value }),
    },
    {
      fieldType: AppQuestionListFieldTypes.Radio,
      fieldName: "contemporaryRecordRequired",
      caption: translate(
        "voc.common.contemporaryRecordRequired"
      ).toLocaleUpperCase(),
      options: yesNoOptions,
      selectedKey: form.contemporaryRecordRequired?.toString(),
      onChange: (_: React.FormEvent, option: IChoiceGroupOption) =>
        onFormChange({
          contemporaryRecordRequired: option.key === "true",
        }),
      contentForRead: getYesNoLabel(form.contemporaryRecordRequired),
    },
    {
      fieldType: AppQuestionListFieldTypes.Dropdown,
      fieldName: "statusWithClient",
      caption: translate("voc.common.statusWithClient").toLocaleUpperCase(),
      options: formDropdownOptions.clientWithStatus,
      value: form.statusWithClient,
      onChange: (_, option) => {
        onFormChange({
          statusWithClient: option.key.toString(),
        });
      },
      contentForRead:
        form.statusWithClient &&
        formDropdownOptions.clientWithStatus.length &&
        formDropdownOptions.clientWithStatus.find(
          (option) => option.key === form.statusWithClient
        )?.text,
    },
    {
      fieldType: AppQuestionListFieldTypes.Custom,
      fieldName: "siteEvents",
      caption: translate("voc.eventPage.supporting.relatedEvent"),
      contentForEdit: (
        <TagPicker
          removeButtonAriaLabel={translate("voc.appFieldBlockComponent.remove")}
          onResolveSuggestions={filterSiteEvents}
          getTextFromItem={getTextFromItem}
          pickerSuggestionsProps={pickerSuggestionsProps}
          defaultSelectedItems={
            form.siteEvents?.length
              ? form.siteEvents.map((item) => ({
                  name: item.serial,
                  key: item.id,
                }))
              : []
          }
          onChange={(items) =>
            onFormChange({
              siteEvents: items.length
                ? items.map((item) => ({
                    id: item.key as string,
                    serial: item.name,
                    title: undefined,
                  }))
                : [],
            })
          }
        />
      ),
      contentForRead: form.siteEvents?.length
        ? form.siteEvents.map((item, index) => (
            <Row key={index}>
              <Col>
                <a
                  href={`../site-events/${item.id}`}
                >{`${item.serial} - ${item.subject}`}</a>
              </Col>
            </Row>
          ))
        : "",
    },
    {
      fieldType: AppQuestionListFieldTypes.Custom,
      fieldName: "tags",
      caption: translate("voc.siteEventDetails.tags"),
      contentForEdit: (
        <TagPicker
          inputProps={{ spellCheck: true }}
          removeButtonAriaLabel={translate("voc.appFieldBlockComponent.remove")}
          onResolveSuggestions={(
            filterText: string,
            tagList: ITag[]
          ): ITag[] => {
            if (
              tagList.find(
                (tag) =>
                  tag.key.toString().toLowerCase() === filterText.toLowerCase()
              )
            ) {
              return [];
            } else {
              return [
                {
                  key: filterText,
                  name: filterText,
                },
              ];
            }
          }}
          defaultSelectedItems={
            form.tags ? form.tags.map((tag) => ({ key: tag, name: tag })) : []
          }
          onChange={(items) =>
            onFormChange({ tags: items.map((item) => item.key.toString()) })
          }
          onInputChange={(e) => {
            return e;
          }}
        />
      ),
      contentForRead: form.tags?.length ? form.tags.join(", ") : "",
    },
    {
      fieldType: AppQuestionListFieldTypes.Custom,
      fieldName: "commercialOwner",
      caption: translate("voc.sidebar.owner").toLocaleUpperCase(),
      contentForEdit: (
        <>
          <TagPicker
            removeButtonAriaLabel={translate(
              "voc.appFieldBlockComponent.remove"
            )}
            onResolveSuggestions={filterEntityUserTags}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={pickerSuggestionsProps}
            itemLimit={1}
            defaultSelectedItems={
              form.commercialOwner
                ? [
                    {
                      name: getUserDisplayName(
                        form.commercialOwner,
                        false,
                        true
                      ),
                      key: form.commercialOwner.key,
                    },
                  ]
                : undefined
            }
            onChange={(items) =>
              onFormChange({
                commercialOwner: items.length
                  ? {
                      id: Number(items[0].key),
                      name: items[0].name,
                      key: items[0].key as string,
                    }
                  : null,
              })
            }
          />
          {formErrors && formErrors.commercialOwner && (
            <div role="alert" className="error">
              <p>
                {Array.isArray(formErrors.commercialOwner)
                  ? formErrors.commercialOwner.join(" / ")
                  : formErrors.commercialOwner}
              </p>
            </div>
          )}
        </>
      ),
      contentForRead: getUserDisplayName(form.commercialOwner, false, true),
    },
    {
      fieldType: AppQuestionListFieldTypes.Custom,
      fieldName: "recordOwner",
      caption: translate("voc.eventPage.summary.operationalRecordOwner"),
      contentForEdit: (
        <>
          <TagPicker
            removeButtonAriaLabel={translate(
              "voc.appFieldBlockComponent.remove"
            )}
            onResolveSuggestions={filterEntityUserTags}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={pickerSuggestionsProps}
            itemLimit={1}
            defaultSelectedItems={
              form.recordOwner
                ? [
                    {
                      name: getUserDisplayName(form.recordOwner, false, true),
                      key: form.recordOwner.key,
                    },
                  ]
                : undefined
            }
            onChange={(items) =>
              onFormChange({
                recordOwner: items.length
                  ? {
                      id: Number(items[0].key),
                      name: items[0].name,
                      key: items[0].key as string,
                    }
                  : null,
              })
            }
          />
          {formErrors && formErrors.recordOwner && (
            <div role="alert" className="error">
              <p>
                {Array.isArray(formErrors.recordOwner)
                  ? formErrors.recordOwner.join(" / ")
                  : formErrors.recordOwner}
              </p>
            </div>
          )}
        </>
      ),
      contentForRead: getUserDisplayName(form.recordOwner, false, true),
    },
    {
      fieldType: AppQuestionListFieldTypes.Custom,
      fieldName: "recordSupporter",
      caption: translate("voc.siteEventDetails.recordSupporter"),
      contentForEdit: (
        <>
          <TagPicker
            removeButtonAriaLabel={translate(
              "voc.appFieldBlockComponent.remove"
            )}
            onResolveSuggestions={filterEntityUserTags}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={pickerSuggestionsProps}
            itemLimit={1}
            defaultSelectedItems={
              form.recordSupporter
                ? [
                    {
                      name: getUserDisplayName(
                        form.recordSupporter,
                        false,
                        true
                      ),
                      key: form.recordSupporter.key,
                    },
                  ]
                : undefined
            }
            onChange={(items) =>
              onFormChange({
                recordSupporter: items.length
                  ? {
                      id: Number(items[0].key),
                      name: items[0].name,
                      key: items[0].key as string,
                    }
                  : null,
              })
            }
          />
          {formErrors && formErrors.recordSupporter && (
            <div role="alert" className="error">
              <p>
                {Array.isArray(formErrors.recordSupporter)
                  ? formErrors.recordSupporter.join(" / ")
                  : formErrors.recordSupporter}
              </p>
            </div>
          )}
        </>
      ),
      contentForRead: getUserDisplayName(form.recordSupporter, false, true),
    },
    {
      fieldType: AppQuestionListFieldTypes.Custom,
      fieldName: "instructionOwner",
      caption: translate("voc.eventPage.VODetails.instructionIssuedBy"),
      contentForEdit: (
        <>
          <AppFormDropdownField
            options={formDropdownOptions.instructionOwner}
            value={form.instructionOwner}
            onChange={(_, option) => {
              const instructionEmpowered =
                option.key !== INSTRUCTED_BY_OTHER &&
                option.key !== INSTRUCTED_BY_NONE;
              onFormChange({
                instructionOwner: option.key.toString(),
                instructionEmpowered,
                instructionObligated: instructionEmpowered,
              });
            }}
          />
          {form.instructionOwner === INSTRUCTED_BY_OTHER && (
            <span className="specify-other">
              {translate("voc.eventPage.VODetails.specify")}
              <TextField
                value={form.instructionOwnerOptional}
                onChange={(_, value) => {
                  onFormChange({
                    instructionOwnerOptional: value,
                  });
                }}
              />
            </span>
          )}
        </>
      ),
      contentForRead:
        form.instructionOwner === INSTRUCTED_BY_OTHER &&
        form.instructionOwnerOptional
          ? translate("voc.sidebar.other") +
            " (" +
            form.instructionOwnerOptional +
            ")"
          : form.instructionOwner &&
            formDropdownOptions.instructionOwner.length &&
            formDropdownOptions.instructionOwner.find(
              (option) => option.key === form.instructionOwner
            )?.text,
    },
    {
      fieldType: AppQuestionListFieldTypes.Radio,
      fieldName: "instructionEmpowered",
      caption: translate("voc.eventPage.VODetails.instructionEmpowered"),
      options: yesNoOptions,
      disabled: true,
      selectedKey: form.instructionEmpowered.toString(),
      onChange: (_: React.FormEvent, option: IChoiceGroupOption) =>
        onFormChange({ instructionEmpowered: option.key === "true" }),
      contentForRead: getYesNoLabel(form.instructionEmpowered),
    },
    {
      fieldType: AppQuestionListFieldTypes.TextInput,
      fieldName: "narrative",
      caption: translate("voc.eventPage.summary.remark"), //backend refers to this as 'narrative' still, even though it is rendered as 'remarks'
      multiline: true,
      errorMessage: formErrors.narrative,
      value: form.narrative,
      onChange: (_, value) => onFormChange({ narrative: value }),
      contentForRead: form.narrative,
      className: "narrative",
      fillRow: true,
    },
    {
      fieldType: AppQuestionListFieldTypes.Dropdown,
      fieldName: "eventSourceType",
      caption: translate("voc.eventPage.summary.sourceType"),
      options: formDropdownOptions.sourceType,
      value: form.eventSourceType,
      onChange: (_, option) => {
        onFormChange({
          eventSourceType: option.key.toString(),
        });
      },
      contentForRead:
        form.eventSourceType &&
        formDropdownOptions.sourceType.length &&
        formDropdownOptions.sourceType.find(
          (option) => option.key === form.eventSourceType
        )?.text,
    },
    {
      fieldType: AppQuestionListFieldTypes.Dropdown,
      fieldName: "eventSource",
      caption: translate("voc.siteEventDetails.source"),
      options: formDropdownOptions.source,
      value: form.eventSource,
      onChange: (_, option) => {
        onFormChange({
          eventSource: option.key.toString(),
        });
      },
      contentForRead:
        form.eventSource &&
        formDropdownOptions.source.length &&
        formDropdownOptions.source.find(
          (option) => option.key === form.eventSource
        )?.text,
    },
    {
      fieldType: AppQuestionListFieldTypes.Calender,
      fieldName: "dateOfAwareness",
      caption: translate("voc.eventPage.notifications.dateOfAwareness"),
      value: form.dateOfAwareness,
      onChange: (date) => {
        onFormChange({
          dateOfAwareness: date,
        });
      },
      contentForRead: (
        <>
          <Icon iconName="Calendar" /> {mapDateToString(form.dateOfAwareness)}
        </>
      ),
    },
    {
      fieldType: AppQuestionListFieldTypes.Calender,
      fieldName: "dateOfCessation",
      caption: translate("voc.eventPage.notifications.dateOfCessation"),
      value: form.dateOfCessation,
      onChange: (date) => {
        onFormChange({
          dateOfCessation: date,
        });
      },
      contentForRead: (
        <>
          <Icon iconName="Calendar" /> {mapDateToString(form.dateOfCessation)}
        </>
      ),
    },
    {
      fieldType: AppQuestionListFieldTypes.TextInput,
      fieldName: "workType",
      caption: translate("voc.siteEventDetails.workType"),
      errorMessage: formErrors.workType,
      value: form.workType,
      onChange: (_: any, value: any) => onFormChange({ workType: value }),
      contentForRead: form.workType,
    },
    {
      fieldType: AppQuestionListFieldTypes.Custom,
      fieldName: "locations",
      caption: translate("voc.siteEventDetails.location"),
      contentForEdit: (
        <Dropdown
          calloutProps={{ className: "voc-location-select" }}
          multiSelect
          selectedKeys={form.locations ? form.locations.map((loc) => loc) : []}
          onChange={(e, option) => {
            const key = option.key.toString();
            const prevArr = form.locations ? [...form.locations] : [];
            onFormChange({
              locations: option.selected
                ? [...prevArr, option.key.toString()]
                : form.locations?.filter(
                    (item) =>
                      !(
                        item === key ||
                        libLocations.find(
                          (libLoc) =>
                            libLoc.enumValue === item && libLoc.childOf === key
                        )
                      )
                  ),
            });
          }}
          options={libLocations.map((option) => ({
            key: option.enumValue,
            text: option.render,
            disabled: option.disabled ? option.disabled : false,
          }))}
        />
      ),
      contentForRead:
        form.locations && libLocations.length
          ? form.locations
              // eslint-disable-next-line array-callback-return
              .map((loc) => {
                const libLoc = libLocations?.find(
                  (option) => option.enumValue === loc
                );
                if (libLoc) {
                  return libLoc?.render;
                }
              })
              .join(", ")
          : "",
    },
    {
      fieldType: AppQuestionListFieldTypes.Dropdown,
      fieldName: "delayDisruptiveEffect",
      caption: translate("voc.library.titles.delayingAndDisruptive"),
      options: formDropdownOptions.delayEffect,
      selectedKeys: form.delayDisruptiveEffect
        ? form.delayDisruptiveEffect.map((item) => item)
        : [],
      multiSelect: true,
      onChange: (_, option) => {
        const key = option.key.toString();
        const prevArr = form.delayDisruptiveEffect
          ? [...form.delayDisruptiveEffect]
          : [];
        onFormChange({
          delayDisruptiveEffect: option.selected
            ? [...prevArr, option.key.toString()]
            : form.delayDisruptiveEffect?.filter((item) => item !== key),
        });
      },
      contentForRead:
        form.delayDisruptiveEffect && formDropdownOptions.delayEffect.length
          ? form.delayDisruptiveEffect
              .map(
                (item) =>
                  formDropdownOptions.delayEffect?.find(
                    (option) => option.key === item
                  )?.text
              )
              .join(", ")
          : "",
    },
  ];

  return (
    <AppQuestionList
      isEditing={isEditing}
      fields={getQuestionGroupFields(
        groupKey,
        customUi,
        upperCardsPresetFields,
        form,
        onFormChange
      )}
    />
  );
};

export default getCollapsibleFields;
