import { NxpFormGrid, NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import i18n from "../../../../app/i18n/i18n";
import { VocGetValuesEisReporting } from "../../../../app/types";
import { getFormattedInputNumberControlProps } from "../../../../app/utils/number/getFormattedInputNumberControlProps";
import {
  computeEisA8A14,
  computeEisStrategyIncludedInPlanning,
} from "../helpers/eisReportCalculations";

interface EisReportPanelProps {
  editing: boolean;
  fieldNames: Record<string, string>;
  includedEISorFPM: Pick<VocGetValuesEisReporting, "includedInReports">;
  onSetIsIncludedInReports: React.Dispatch<
    React.SetStateAction<Pick<VocGetValuesEisReporting, "includedInReports">>
  >;
  eisReportingFormState: Pick<
    VocGetValuesEisReporting,
    | "eisA2A6"
    | "eisA8A14"
    | "in16"
    | "eisSecuredProject"
    | "eisStrategyIncludedInPlanning"
  >;
  onSetEisReportingFormState: React.Dispatch<
    React.SetStateAction<
      Pick<
        VocGetValuesEisReporting,
        | "eisA2A6"
        | "in16"
        | "eisA8A14"
        | "eisSecuredProject"
        | "eisStrategyIncludedInPlanning"
      >
    >
  >;
  internalTarget: number;
}

const useStyles = createUseStyles((theme) => ({
  title: {
    color: theme.palette.primary,
  },
}));

const EisReportPanel: React.FC<EisReportPanelProps> = ({
  editing,
  fieldNames,
  includedEISorFPM,
  onSetIsIncludedInReports,
  eisReportingFormState,
  onSetEisReportingFormState,
  internalTarget,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const eisReportingFormItems: NxpFormGridItemProps<
    Pick<VocGetValuesEisReporting, "includedInReports">
  >[] = [
    {
      controlType: "radioGroup",
      label: fieldNames.eisReporting,
      itemFieldName: "includedInReports",
      span: 8,
      controlProps: {
        value: includedEISorFPM?.includedInReports,
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
      },
    },
  ];

  const handleFormGridStateChange = (
    fieldName: keyof VocGetValuesEisReporting,
    value: unknown
  ) => {
    onSetIsIncludedInReports((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const eisFieldsFormItems: NxpFormGridItemProps<
    Pick<
      VocGetValuesEisReporting,
      | "eisA2A6"
      | "in16"
      | "eisA8A14"
      | "eisSecuredProject"
      | "eisStrategyIncludedInPlanning"
    >
  >[] = [
    {
      label: fieldNames.eisA2A6,
      itemFieldName: "eisA2A6",
      controlType: "inputNumber",
      span: 4,
      controlProps: {
        value: eisReportingFormState?.eisA2A6 ?? undefined,
        addonBefore: editing ? "$" : undefined,
        prefix: editing ? undefined : "$",
        precision: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      label: fieldNames.eisA8A14,
      itemFieldName: "eisA8A14",
      controlType: "inputNumber",
      span: 4,
      editing: false,
      controlProps: {
        value: eisReportingFormState?.eisA8A14 ?? undefined,
        prefix: "$",
        precision: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      controlType: "select",
      controlProps: {
        allowClear: true,
        options: [
          { label: t("voc.common.pleaseSelect"), value: null },
          {
            label: t("voc.eventPage.values.in16State.securedProject"),
            value: "VALUATION_IN16_SECURED",
          },
          {
            label: t("voc.eventPage.values.in16State.strategyIncluded"),
            value: "VALUATION_IN16_INC_PLAN",
          },
          {
            label: t("voc.eventPage.values.in16State.strategyNotIncluded"),
            value: "VALUATION_IN16_NOT_PLAN",
          },
          {
            label: t("voc.eventPage.values.in16State.mixSecuredStrategy"),
            value: "VALUATION_OM16_MIXED",
          },
        ],
      },
      label: fieldNames.in16,
      itemFieldName: "in16",
      span: 8,
    },
  ];

  if (eisReportingFormState?.in16 === "VALUATION_OM16_MIXED") {
    eisFieldsFormItems.push(
      {
        label: fieldNames.eisSecuredProject,
        itemFieldName: "eisSecuredProject",
        controlType: "inputNumber",
        span: 4,
        controlProps: {
          value: eisReportingFormState?.eisSecuredProject ?? undefined,
          addonBefore: editing ? "$" : undefined,
          prefix: editing ? undefined : "$",
          precision: 2,
          locale: i18n.language,
          ...getFormattedInputNumberControlProps(),
        },
      },
      {
        label: fieldNames.eisStrategyIncludedInPlanning,
        itemFieldName: "eisStrategyIncludedInPlanning",
        controlType: "inputNumber",
        span: 4,
        editing: false,
        controlProps: {
          value:
            eisReportingFormState?.eisStrategyIncludedInPlanning ?? undefined,
          prefix: editing ? undefined : "$",
          precision: 2,
          locale: i18n.language,
          ...getFormattedInputNumberControlProps(),
        },
      }
    );
  }

  const handleEisReportingFormGridStateChange = (
    fieldName: keyof VocGetValuesEisReporting,
    value: unknown
  ) => {
    onSetEisReportingFormState((prevState) => {
      const newState = {
        ...prevState,
        [fieldName]: value,
      };

      if (fieldName === "eisA2A6") {
        newState.eisA8A14 = computeEisA8A14(newState, internalTarget);
      }

      if (fieldName === "eisSecuredProject") {
        newState.eisStrategyIncludedInPlanning =
          computeEisStrategyIncludedInPlanning(newState, internalTarget);
      }

      return newState;
    });
  };

  return (
    <>
      <h4 className={classes.title}>
        {t("voc.eventPage.values.eisReportingHeader")}
      </h4>
      {t("voc.eventPage.values.includedInEIS")}
      <NxpFormGrid
        editing={editing}
        formItems={eisReportingFormItems}
        formState={includedEISorFPM}
        onFormStateChange={handleFormGridStateChange}
      />

      {includedEISorFPM?.includedInReports && (
        <NxpFormGrid
          editing={editing}
          formItems={eisFieldsFormItems}
          formState={eisReportingFormState}
          onFormStateChange={handleEisReportingFormGridStateChange}
        ></NxpFormGrid>
      )}
    </>
  );
};

export default EisReportPanel;
