import useAppContext from "../hooks/useAppContext";
import { useConst } from "../hooks/useConst";
import { useVocContext } from "./VocContext";
import { VocService } from "./vocService";

const useVocServiceConst = (entityId: string) => {
  const vocCtx = useVocContext();
  const { serviceConfig } = useAppContext();
  return useConst(
    () => new VocService(entityId, vocCtx.entityTimezone, serviceConfig)
  );
};

export default useVocServiceConst;
