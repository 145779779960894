import classNames from "classnames";

import { formatMoneyValue, formatPercentValue } from "../../utils/string";
import { ColumnDataType, DataTableColumn } from "./AppDataTable";

import { useTranslation } from "react-i18next";
import "./AppDataTableRow.scss";

interface AppDataTableFooterProps<T> {
  columns: DataTableColumn<T>[];
  dataItem: T;
  fixFirstColumn?: boolean;
  totalRecordCount: number;
}

function AppDataTableFooter<T>(props: AppDataTableFooterProps<T>) {
  const { columns, fixFirstColumn, dataItem, totalRecordCount } = props;
  const { t } = useTranslation();

  const defaultRender = (
    dataItem: T,
    fieldName: string,
    columnDataType: ColumnDataType
  ) => {
    const content = dataItem[fieldName as keyof T];
    if (typeof content === "object" && content !== null) {
      return content.toString();
    }
    switch (columnDataType) {
      case ColumnDataType.Money:
        return formatMoneyValue(content as any, "$");
      case ColumnDataType.Number:
        return formatMoneyValue(content as any);
      case ColumnDataType.Percent:
        return formatPercentValue(content as any);
    }
    return content;
  };

  return (
    <div role="row" className="app-data-table-row footer">
      {columns.map((col, idx) => {
        return (
          <div
            key={col.key}
            role="gridcell"
            className={classNames(
              col.footerClassName,
              fixFirstColumn && idx === 0 && "fixed-first-col"
            )}
          >
            <div className="cell-content">
              {idx === 0
                ? `${t("voc.common.total")} - ${totalRecordCount} ${t(
                    "voc.common.event",
                    {
                      count: totalRecordCount,
                    }
                  )}`
                : col.onRender
                ? (col.onRender(dataItem) as string)
                : (defaultRender(
                    dataItem,
                    col.fieldName,
                    col.columnDataType
                  ) as string)}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AppDataTableFooter;
