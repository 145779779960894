import React from "react";

import {
  AppFormRow,
  AppFormTextField,
} from "../../../../app/components/app-form";
import { VocLibraryObject } from "../../../../app/types";

interface ClientRepresentativesProps {
  form: Partial<VocLibraryObject>;
  formErrors: any; // ValidationResult<VocLibraryObject>;
  onFormChange: (changeValues: Partial<VocLibraryObject>) => void;
}

const ClientRepresentatives: React.FC<ClientRepresentativesProps> = ({
  form,
  formErrors,
  onFormChange,
}) => (
  <>
    <AppFormRow>
      <AppFormTextField
        required={true}
        label="First Name"
        name="firstName"
        value={form.firstName}
        errorMessage={formErrors && formErrors.firstName}
        onChange={(_, value) => onFormChange({ firstName: value })}
      />
      <AppFormTextField
        required={true}
        label="Last Name"
        name="lastName"
        value={form.lastName}
        errorMessage={formErrors && formErrors.lastName}
        onChange={(_, value) => onFormChange({ lastName: value })}
      />
    </AppFormRow>
    <AppFormRow>
      <AppFormTextField
        label="Company"
        name="company"
        value={form.company}
        errorMessage={formErrors && formErrors.company}
        onChange={(_, value) => onFormChange({ company: value })}
      />
    </AppFormRow>
    <AppFormRow>
      <AppFormTextField
        label="Role"
        name="role"
        value={form.role}
        errorMessage={formErrors && formErrors.role}
        onChange={(_, value) => onFormChange({ role: value })}
      />
      <AppFormTextField
        label="E-mail Address"
        name="email"
        value={form.email}
        errorMessage={formErrors && formErrors.email}
        onChange={(_, value) => onFormChange({ email: value })}
      />
    </AppFormRow>
  </>
);

export default ClientRepresentatives;
