interface ValidationWarningProps {
  message: string;
}

export const ValidationWarning: React.FC<ValidationWarningProps> = ({
  message,
}) => {
  return (
    <div className="warning">
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 12.5C25 15.8152 23.683 18.9946 21.3388 21.3388C18.9946 23.683 15.8152 25 12.5 25C9.18479 25 6.00537 23.683 3.66117 21.3388C1.31696 18.9946 0 15.8152 0 12.5C0 9.18479 1.31696 6.00537 3.66117 3.66117C6.00537 1.31696 9.18479 0 12.5 0C15.8152 0 18.9946 1.31696 21.3388 3.66117C23.683 6.00537 25 9.18479 25 12.5ZM18.5219 7.58438C18.6686 7.43768 18.751 7.23871 18.751 7.03125C18.751 6.82379 18.6686 6.62482 18.5219 6.47812C18.3752 6.33143 18.1762 6.24901 17.9688 6.24901C17.7613 6.24901 17.5623 6.33143 17.4156 6.47812L12.5 11.3953L7.58438 6.47812C7.51174 6.40549 7.4255 6.34787 7.3306 6.30856C7.23569 6.26925 7.13398 6.24901 7.03125 6.24901C6.92852 6.24901 6.82681 6.26925 6.7319 6.30856C6.637 6.34787 6.55076 6.40549 6.47812 6.47812C6.40549 6.55076 6.34787 6.637 6.30856 6.7319C6.26925 6.82681 6.24901 6.92852 6.24901 7.03125C6.24901 7.13398 6.26925 7.23569 6.30856 7.3306C6.34787 7.4255 6.40549 7.51174 6.47812 7.58438L11.3953 12.5L6.47812 17.4156C6.33143 17.5623 6.24901 17.7613 6.24901 17.9688C6.24901 18.1762 6.33143 18.3752 6.47812 18.5219C6.62482 18.6686 6.82379 18.751 7.03125 18.751C7.23871 18.751 7.43768 18.6686 7.58438 18.5219L12.5 13.6047L17.4156 18.5219C17.4883 18.5945 17.5745 18.6521 17.6694 18.6914C17.7643 18.7308 17.866 18.751 17.9688 18.751C18.0715 18.751 18.1732 18.7308 18.2681 18.6914C18.363 18.6521 18.4492 18.5945 18.5219 18.5219C18.5945 18.4492 18.6521 18.363 18.6914 18.2681C18.7308 18.1732 18.751 18.0715 18.751 17.9688C18.751 17.866 18.7308 17.7643 18.6914 17.6694C18.6521 17.5745 18.5945 17.4883 18.5219 17.4156L13.6047 12.5L18.5219 7.58438Z"
          fill="white"
        />
      </svg>
      <div className="warning-text">{message}</div>
    </div>
  );
};
