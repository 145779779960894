import React from "react";
import { createUseStyles } from "react-jss";

import { NxpButton, NxpTabs, TabPane } from "@nexploretechnology/nxp-ui";

const useStyles = createUseStyles((theme) => ({
  demoTabs: {},
}));

interface DemoTabsProps {}

const DemoTabs: React.FC<DemoTabsProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.demoTabs}>
      <NxpTabs>
        <TabPane tab="test" key="123"></TabPane>
        <TabPane tab="test" key="1236"></TabPane>
        <TabPane tab="test" key="1235"></TabPane>
        <TabPane tab="test" key="1234"></TabPane>
      </NxpTabs>
      <NxpTabs tabsOnRight>
        <TabPane tab="test" key="123"></TabPane>
        <TabPane tab="test" key="1236"></TabPane>
        <TabPane tab="test" key="1235"></TabPane>
        <TabPane tab="test" key="1234"></TabPane>
      </NxpTabs>
      <NxpTabs tabBarExtraContent={<NxpButton>Extra</NxpButton>}>
        <TabPane tab="test" key="123"></TabPane>
        <TabPane tab="test" key="1236"></TabPane>
        <TabPane tab="test" key="1235"></TabPane>
        <TabPane tab="test" key="1234"></TabPane>
      </NxpTabs>
    </div>
  );
};

export default DemoTabs;
