import React, { useState } from "react";

import { notify } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../app/hooks/useAppContext";
import { useAsync } from "../../../app/hooks/useAsync";
import { VocClaim, VocEvent } from "../../../app/types";
import { getClaims, updateClaim } from "../../compensation-event-services";
import ClaimsTabLayout from "./ClaimsTabLayout";

interface ClaimsTabContainerProps {
  event: VocEvent;
  onEventRefresh: () => void;
}

const ClaimsTabContainer: React.FC<ClaimsTabContainerProps> = ({
  event,
  onEventRefresh,
}) => {
  const [form, setForm] = React.useState<VocClaim[]>([]);
  const { serviceConfig, errorHandler } = useAppContext();
  const refineClaim = (claim: VocClaim) => {
    return { ...claim, workType: undefined, seq: undefined } as VocClaim;
  };

  const [getClaimsAsyncResult, setGetClaimsRequest] = useAsync(
    () => getClaims(event.id, serviceConfig),
    {
      onSuccess: (data) => setForm(data.map(refineClaim)),
      onError: (ex) => errorHandler(ex, "getting claims"),
    },
    []
  );

  const handleRefreshData = () => {
    onEventRefresh();
    setGetClaimsRequest(() => getClaims(event.id, serviceConfig));
  };

  const [updateClaimAsyncResult, setUpdateClaimRequest] = useAsync<VocClaim>(
    undefined,
    {
      onSuccess: () => {
        notify.actionCompleted();
        handleRefreshData();
      },
      onError: (ex) => errorHandler(ex, "updating claim"),
    }
  );

  const [editingClaimIds, setEditingClaimIds] = useState<number[]>([]);

  const handleCancel = (claimId: number) => {
    setForm((prevState) =>
      prevState.map((claim) =>
        claim.id === claimId
          ? refineClaim(
              getClaimsAsyncResult.data.find((item) => item.id === claimId)
            )
          : claim
      )
    );
    setEditingClaimIds((prevState) =>
      prevState.filter((item) => item !== claimId)
    );
  };

  const handleSave = (claimId: number) => {
    const claimToSave = { ...form.find((claim) => claim.id === claimId) };
    const eventId = claimToSave.id; // this eventId here is different from the actual event the tab component refers to
    setUpdateClaimRequest(() =>
      updateClaim(
        eventId.toString(),
        {
          ...claimToSave,
          createdOn: undefined,
          updatedOn: undefined,
          eventId: undefined,
          id: undefined,
          serial: undefined,
          objectSerial: undefined,
        },
        serviceConfig
      )
    );
    setEditingClaimIds((prevState) =>
      prevState.filter((item) => item !== claimId)
    );
  };

  const handleEdit = (claimId: number) => {
    setEditingClaimIds((prevState) => [...prevState, claimId]);
  };

  const handleFormChange = (
    changeValues: Partial<VocClaim>,
    claimId: number
  ) => {
    setForm((prevState) =>
      prevState.map((claim) => {
        if (claim.id === claimId) {
          return { ...claim, ...changeValues };
        }
        return claim;
      })
    );
  };

  return (
    <ClaimsTabLayout
      eventId={event.id}
      editingClaimIds={editingClaimIds}
      claimsForm={form}
      formErrors={[]}
      loading={updateClaimAsyncResult.loading || getClaimsAsyncResult.loading}
      onEdit={handleEdit}
      onFormChange={handleFormChange}
      onSave={handleSave}
      onCancel={handleCancel}
      onRefreshData={handleRefreshData}
    />
  );
};

export default ClaimsTabContainer;
