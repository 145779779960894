import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AppButton from "../../../../app/components/app-button";
import ReminderModalContainer from "./ReminderModalContainer";

import "./ReminderButton.scss";

interface ReminderButtonProps {}

const ReminderButton: React.FC<ReminderButtonProps> = () => {
  const { t } = useTranslation();
  const [showReminderModal, setShowReminderModal] = useState(false);
  const handleModalClose = () => setShowReminderModal(false);

  return (
    <>
      <AppButton
        className="voc-event-notification-reminder"
        onClick={() => setShowReminderModal(true)}
      >
        {t("voc.common.reminder")}
      </AppButton>
      <ReminderModalContainer
        showReminderModal={showReminderModal}
        onModalClose={handleModalClose}
      />
    </>
  );
};

export default ReminderButton;
