import React, { SetStateAction, useCallback, useMemo, useState } from "react";

import {
  notify,
  NxpFormItem,
  NxpTableEditable,
  useYupValidate,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";
import { ColumnProps } from "antd/lib/table";
import * as yup from "yup";

interface Item {
  key: string;
  name: string;
  age?: number;
  address: string;
}

let originData: Item[] = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}

const getColumns = (
  editRecord: Item | undefined,
  setEditRecord: (callback: SetStateAction<Item | undefined>) => void,
  validationError: ValidationResult<Pick<Item, "name" | "age">>
): ColumnProps<Item>[] => [
  {
    title: "name",
    dataIndex: "name",
    width: 150,
    fixed: "left",
    sorter: true,
    render: (_: unknown, record: Item) => {
      const editing = editRecord?.key === record.key;
      return (
        <NxpFormItem
          editing={editing}
          controlType="input"
          error={validationError.name}
          controlProps={{
            value: editing ? editRecord?.name : record.name,
            onChange: (e) =>
              setEditRecord(
                (prevState) =>
                  prevState && { ...prevState, name: e.target.value }
              ),
          }}
        />
      );
    },
  },
  {
    title: "age",
    dataIndex: "age",
    width: 150,
    sorter: true,
    render: (_: unknown, record: Item) => {
      const editing = editRecord?.key === record.key;
      return (
        <NxpFormItem
          editing={editing}
          controlType="inputNumber"
          error={validationError.age}
          controlProps={{
            decimalPlace: 0,
            value: editing ? editRecord?.age : record.age,
            onChange: (value) =>
              setEditRecord(
                (prevState) => prevState && { ...prevState, age: value }
              ),
          }}
        />
      );
    },
  },
  {
    title: "address",
    dataIndex: "address",
    width: 300,
  },
  {
    title: "address",
    dataIndex: "address",
    width: 300,
  },
  {
    title: "address",
    dataIndex: "address",
    width: 300,
  },
  {
    title: "address",
    dataIndex: "address",
    width: 300,
  },
];

const formSchema = yup.object().shape({
  name: yup.string().nullable().required("Name required."),
  age: yup.number(),
});

interface TableEditableProps {}

const TableEditable: React.FC<TableEditableProps> = () => {
  const [editItem, setEditItem] = useState<Item>();
  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);
    const rec = originData.find((data) => data.key === editItem?.key)!;
    Object.assign(rec, { ...editItem });
    setEditItem(undefined);
    notify.actionCompleted();
    setTimeout(() => setSaveInProgress(false));
  };

  const [validationError, , clearError, saveWithValidate] = useYupValidate<
    Pick<Item, "name" | "age">
  >(editItem, formSchema, handleSaveValidated);

  const columns = useMemo(
    () => getColumns(editItem, setEditItem, validationError),
    [editItem, validationError]
  );

  const handleRowEdit = useCallback(
    (editItem: Item) => setEditItem({ ...editItem }),
    []
  );

  const handleRowSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  const handleRowCancel = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      setEditItem(undefined);
      clearError();
    },
    [clearError]
  );

  const handleRowDelete = useCallback((deleteItem: Item) => {
    setSaveInProgress(true);
    originData = originData.filter((data) => data.key !== deleteItem.key);
    setEditItem(undefined);
    setTimeout(() => setSaveInProgress(false)); // need setTimeout only because this demo use synchronous procedure for deleting
  }, []);

  return (
    <NxpTableEditable
      hideDelete={false} // same as leaving this undefined
      rowDisableDelete={(record: Item) => Number(record.key) % 2 === 0}
      rowDisableEdit={(record: Item) => Number(record.key) % 4 === 0}
      scroll={{ y: 300 }}
      editItem={editItem}
      saveInProgress={saveInProgress}
      itemCompareKey="key"
      onRowDelete={handleRowDelete}
      onRowEdit={handleRowEdit}
      onRowSave={handleRowSave}
      onRowCancel={handleRowCancel}
      columns={columns}
      dataSource={originData}
      pagination={{ pageSize: 30 }}
    />
  );
};

export default React.memo(TableEditable);
