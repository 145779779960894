import { ColumnDataType } from "../components/app-data-table";
import { VocScreenIdEnums } from "./";

interface VocCustomUiLayoutItemBase {
  name: string;
  key: string;
  customName?: string;
  members?: any; //to prevent type error
}

export interface VocCustomUiLayoutGroup extends VocCustomUiLayoutItemBase {
  type: VocCustomUiLayoutItemType.LayoutGroup;
  display: boolean;
  members: VocCustomUiLayoutItem[];
  locked: boolean;
}

export interface VocCustomUiQuestionListPresetField
  extends VocCustomUiLayoutItemBase {
  type: VocCustomUiLayoutItemType.QuestionListPresetField;
  dataType: VocCustomUiFieldDataType;
  adminPlaceHolder?: string;
  display: boolean;
  span: number;
  locked: boolean;
}

export interface VocCustomUiDataTableColumn extends VocCustomUiLayoutItemBase {
  type: VocCustomUiLayoutItemType.DataTableColumn;
  display: boolean;
  customName: string;
  isCustomField?: boolean;
  columnDataType?: ColumnDataType;
}

export interface VocCustomUiQuestionListCustomField
  extends VocCustomUiLayoutItemBase {
  type: VocCustomUiLayoutItemType.QuestionListCustomField;
  dataType: VocCustomUiFieldDataType;
  adminPlaceHolder?: string;
  display: boolean;
  span: number;
  isRequired: boolean;
  schemaId: number;
  validations: VocCustomUiSchemaValidations;
  locked: boolean;
  isSyncField: boolean;
}

export enum VocCustomUiLayoutItemType {
  LayoutGroup = "LayoutGroup",
  QuestionListPresetField = "QuestionListPresetField",
  QuestionListCustomField = "QuestionListCustomField",
  DataTableColumn = "DataTableColumn",
}

export type VocCustomUiLayoutItem =
  | VocCustomUiLayoutGroup
  | VocCustomUiQuestionListPresetField
  | VocCustomUiQuestionListCustomField
  | VocCustomUiDataTableColumn;

export interface VocCustomUi {
  layout: VocCustomUiLayoutItem[];
  screen: {
    id: VocScreenIdEnums;
    name: string;
  };
  isDefault: boolean;
  entityId: string;
  createdOn: Date;
  updatedOn: Date;
  id: number;
}

export enum VocCustomUiFieldDataType {
  Text = "TEXT",
  Value = "VALUE",
  Date = "DATE",
  YesNo = "YESNO",
  Percentage = "PERCENTAGE",
  Dropdown = "DROPDOWN",
  MultiSelect = "MULTISELECT",
  FileUpload = "FILEUPLOAD",
  ReadOnly = "READONLY",
  Formula = "CALCULATION",
}

export enum VocCustomUiFormulaOutput {
  Percentage = "PERCENTAGE",
  Value = "VALUE",
  Currency = "CURRENCY",
}

export enum VocCustomUiObjectType {
  CombinedCompensationEvent = "CombinedCompensationEvent",
  Event = "Event",
  SiteEvent = "SiteEvent",
  VariationOrder = "VariationOrder",
  Valuation = "Valuation",
}

export interface VocCustomUiFieldSchema {
  entityId: string;
  createdOn: Date;
  isRequired: boolean;
  name: string;
  updatedOn: Date;
  dataType: VocCustomUiFieldDataType;
  id: number;
  objectType: VocCustomUiObjectType;
  validations: VocCustomUiSchemaValidations;
  isSyncField: boolean;
}

export interface VocCustomUiSchemaValidations {
  limits: string[];
  formula?: string;
  output?: VocCustomUiFormulaOutput;
}
