import { convertToJsonBody } from "../app/services/convert-to-json-body";
import {
  VocCustomUi,
  VocCustomUiFieldSchema,
  VocCustomUiLayoutItem,
} from "../app/types";
import { apiRequest, ServiceConfig } from "../app/utils/backend/api-request";

export const getAllCustomizedScreens = (serviceConfig: ServiceConfig) => {
  return apiRequest<VocCustomUi[]>("/customize/screens/all", serviceConfig);
};

export const createCustomizedScreen = (
  screenId: number,
  layout: VocCustomUiLayoutItem[],
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocCustomUi>(
    `/customize/screens/${screenId}`,
    serviceConfig,
    {
      method: "POST",
      body: convertToJsonBody({ layout }, serviceConfig.timezoneHourAdjustment),
    }
  );
};

export const updateCustomizedScreen = (
  screenId: number,
  layout: VocCustomUiLayoutItem[],
  serviceConfig: ServiceConfig
) =>
  apiRequest<VocCustomUi>(`/customize/screens/${screenId}`, serviceConfig, {
    method: "PATCH",
    body: convertToJsonBody({ layout }, serviceConfig.timezoneHourAdjustment),
  });

export const createCustomFieldSchema = (
  customField: Partial<VocCustomUiFieldSchema>,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocCustomUiFieldSchema>(
    `/customize/objects`,
    serviceConfig,
    {
      method: "POST",
      body: convertToJsonBody(
        customField,
        serviceConfig.timezoneHourAdjustment
      ),
    }
  );
};

export const updateCustomFieldSchema = (
  schemaObjectId: number,
  customField: Partial<VocCustomUiFieldSchema>,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocCustomUiFieldSchema>(
    `/customize/objects/${schemaObjectId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: convertToJsonBody(
        customField,
        serviceConfig.timezoneHourAdjustment
      ),
    }
  );
};

export const getCustomFieldSchema = (
  objectType: string,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocCustomUiFieldSchema>(
    `/customize/objects/type/${objectType}`,
    serviceConfig
  );
};
