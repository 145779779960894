import React from "react";
import ImportExcelModal from "./ImportExcelModal";
import { IDropdownOption } from "office-ui-fabric-react";
import { VocMapping, VocValueRegister } from "../../../../app/types";

export interface RemarkAttachment {
  id: number;
  createdAt: Date;
  remarkId: number;
  assetId: string;
  fileName: string;
  mimeType: string;
  url?: string;
}

interface ImportExcelContainerProps {
  entityId: string;
  showImportExcelModal: boolean;
  onModalClose: () => void;
  onCancel: () => void;
}

export function lettersToNumber(letters: string) {
  return (
    letters.split("").reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0) - 1
  );
}

const ImportExcelContainer: React.FC<ImportExcelContainerProps> = (props) => {
  const { showImportExcelModal, onModalClose, onCancel } = props;
  const [stage, setStage] = React.useState<number>(0);
  const [file, setFile] = React.useState<Partial<RemarkAttachment>[]>([]);
  const [refColumn, setRefColumn] = React.useState<string>("");
  const [fieldsToMap, setFieldsToMap] = React.useState<VocMapping[]>([
    { field: undefined, column: "" },
  ]);
  const [decodedValues, setDecodedValues] =
    React.useState<Partial<VocValueRegister>[]>(null); //type later
  const [aggregateValues, setAggregateValues] =
    React.useState<Partial<VocValueRegister>>(null);

  const fieldToMapOptions: IDropdownOption[] = [
    {
      key: "submittedValue",
      text: "Internal Assessed / Substantiated (Submitted) [$]",
    },
    {
      key: "internalTarget",
      text: "Internal Target (Current Project Financial Forecast) [$]",
    },
    { key: "clientAssessed", text: "Client Assessed [$]" },
    { key: "cumulativeAppliedNominal", text: "Cumulative Value Applied [$]" },
    { key: "cumulativeValueCertified", text: "Cumulative Value Certified [$]" },
    { key: "paymentReference", text: "Payment Reference [text or #]" },
    { key: "directCost", text: "Direct Cost [$]" },
    { key: "indirectCost", text: "Indirect Cost [$]" },
    { key: "costCloseOut", text: "Cost Close Out [$]" },
    { key: "costGna", text: "Cost G&A [$]" },
    { key: "includedInReports", text: "Included in Reports [true/false]" },
    { key: "eisA2A6", text: "EIS A.2 - A.6 [$]" },
  ];

  const addFieldToMap = () => {
    setFieldsToMap([
      ...fieldsToMap,
      {
        field: undefined,
        column: "",
      },
    ]);
  };

  return (
    <ImportExcelModal
      showImportExcelModal={showImportExcelModal}
      onModalClose={onModalClose}
      stage={stage}
      file={file}
      setFile={setFile}
      setStage={setStage}
      refColumn={refColumn}
      setRefColumn={setRefColumn}
      fieldsToMap={fieldsToMap}
      setFieldsToMap={setFieldsToMap}
      addFieldToMap={addFieldToMap}
      fieldToMapOptions={fieldToMapOptions}
      aggregateValues={aggregateValues}
      setDecodedValues={setDecodedValues}
      setAggregateValues={setAggregateValues}
      decodedValues={decodedValues}
      onCancel={onCancel}
    ></ImportExcelModal>
  );
};

export default ImportExcelContainer;
