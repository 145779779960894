import { VocEventFile, VocRecordStatus } from "../../../../app/types";

export const vocSupportDocumentOptions = [
  { label: "Please Select Document Type", value: "" },
  { label: "Correspondence", value: "VO-EV-SDOC-01" },
  { label: "Labour Records", value: "VO-EV-SDOC-02" },
  { label: "Plant Records", value: "VO-EV-SDOC-03" },
  { label: "Staff Records Timesheets", value: "VO-EV-SDOC-04" },
  { label: "Daily Diaries", value: "VO-EV-SDOC-05" },
  { label: "Subcontractor ACA", value: "VO-EV-SDOC-06" },
  { label: "Subcontractor SCI", value: "VO-EV-SDOC-07" },
  { label: "Contemporary Records", value: "VO-EV-SDOC-08" },
  { label: "Other Documents", value: "VO-EV-SDOC-09" },
  { label: "Subcontractor Minerva", value: "VO-EV-SDOC-10" },
  { label: "Drawings", value: "VO-EV-SDOC-11" },
];

export const recordStatus = [
  { label: "Record Prepared", value: "VO_REC_STATUS_01" },
  { label: "Record Reviewed", value: "VO_REC_STATUS_02" },
  { label: "Record Signed Off By PD", value: "VO_REC_STATUS_03" },
  { label: "Record Submitted To Client", value: "VO_REC_STATUS_04" },
  { label: "Final Claim Submitted", value: "VO_REC_STATUS_05" },
  { label: "No Disruption Or Delay", value: "VO_REC_STATUS_09" },
  { label: "Outstanding", value: "VO_REC_STATUS_00" },
  { label: "Related Site Event", value: "VO_REC_STATUS_11" },
];

export interface GeneralSupportingFormState {
  fromDate: Date;
  toDate: Date;
  subject: string;
  comment: string;
  link: string;
  recordStatus: VocRecordStatus;
  files: VocEventFile[];
}

export interface SubcontractorAcaForm {
  documentType: string;
  reference: string;
  subject: string;
  subcontractor: string;
  amount: number;
  documentStatus: string;
  comments: string;
  link: string;
  files: VocEventFile[];
}

export interface SubcontractorSciForm {
  amount: number;
  documentStatus: string;
  documentType: string;
  fromDate: Date;
  link: string;
  reference: string;
  subcontractor: string;
  subject: string;
  toDate: Date;
  files: VocEventFile[];
}

export interface DrawingForm {
  drawingNumber: string;
  revision: string;
  title: string;
  documentDate: Date;
  location: string[];
  link: string;
  files: VocEventFile[];
  originator: string;
}

export interface CorrespondenceForm {
  subject: string;
  sender: string;
  documentDate: Date;
  recipient: string;
  comments: string;
  link: string;
  files: VocEventFile[];
}

export interface LabourRecordForm {
  fromDate: Date;
  toDate: Date;
  subject: string;
  comment: string;
  link: string;
  recordStatus: VocRecordStatus;
  files: VocEventFile[];
}

export interface PlantRecordForm {
  fromDate: Date;
  toDate: Date;
  subject: string;
  comment: string;
  link: string;
  recordStatus: VocRecordStatus;
  files: VocEventFile[];
}

export interface StaffRecordTimeSheetForm {
  fromDate: Date;
  toDate: Date;
  subject: string;
  comment: string;
  link: string;
  recordStatus: VocRecordStatus;
  files: VocEventFile[];
}

export interface DailyDiariesForm {
  fromDate: Date;
  toDate: Date;
  subject: string;
  comment: string;
  link: string;
  recordStatus: VocRecordStatus;
  files: VocEventFile[];
}

export interface ContemporaryRecordForm {
  fromDate: Date;
  toDate: Date;
  subject: string;
  comment: string;
  link: string;
  recordStatus: VocRecordStatus;
  files: VocEventFile[];
}

//Not yet implemented
export interface MinervaForm {
  fromDate: null;
  toDate: null;
  subject: "";
  subcontractor: "";
  totalAmount: "";
  valueToDate: "";
  certifiedToDate: "";
  assessment: "";
  reference: "";
  subcontractorAssessment: "";
  recordStatus: undefined;
  details: string;
}
