export interface DemoItem {
  key: number;
  name: string;
  age?: number;
  birthday: Date;
  address: string;
}

export const getDemoFormList = async () => {
  const data: DemoItem[] = [];
  for (let i = 0; i < 100; i++) {
    data.push({
      key: i,
      name: `Edrward ${i}`,
      age: 8 + i,
      birthday: new Date(1000000000000 + i * 10000000),
      address: `London Park no. ${i}`,
    });
  }
  return Promise.resolve(data);
};

export const getDemoLongList = async () => {
  const data: DemoItem[] = [];
  for (let i = 0; i < 500; i++) {
    data.push({
      key: i,
      name: `Edrward ${i}`,
      age: 8 + i,
      birthday: new Date(1000000000000 + i * 10000000),
      address: `London Park no. ${i}`,
    });
  }
  return Promise.resolve(data);
};

export const getDemoLazyList = async (offset: number, limit: number) => {
  return Promise.resolve(
    Array.from({ length: limit }, (_, idx) => {
      const i = idx + offset;
      return {
        key: i,
        name: `Edrward ${i}`,
        age: 10 * i,
        address: `London Park no. ${i}`,
        birthday: new Date(),
      };
    })
  );
};

export const updateDemo = async (demoForm: DemoItem) => {
  return Promise.resolve({
    ...demoForm,
  });
};

export interface DemoItemForm extends Pick<DemoItem, "name" | "age"> {}

export const createDemo = async (demoForm: DemoItemForm) => {
  return Promise.resolve({
    id: new Date().getTime(),
    ...demoForm,
  });
};

export const deleteDemo = async (id: number) => {
  return Promise.resolve(id);
};
