import { NxpButton } from "@nexploretechnology/nxp-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AddRoleModal from "./AddRoleModal";
import { createUseStyles } from "react-jss";

interface AddRoleButtonProps {
  onRefetch: () => Promise<void>;
}

const useStyles = createUseStyles({
  updateButton: {
    width: "100%",
    textAlign: "left",
  },
});

const AddRoleButton: React.FC<AddRoleButtonProps> = ({ onRefetch }) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpen = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <NxpButton className={classes.updateButton} onClick={handleOpen}>
        {t("voc.AccessRight.AddRole")}
      </NxpButton>
      {isModalVisible && (
        <AddRoleModal onClose={handleClose} onRefetch={onRefetch} />
      )}
    </>
  );
};

export default AddRoleButton;
