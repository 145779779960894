import React from "react";
import { useTranslation } from "react-i18next";

import {
  AppFormCalendarField,
  AppFormField,
  AppFormNumericField,
  AppFormRow,
} from "../../../../app/components/app-form";
import { VocLibraryObject } from "../../../../app/types";

interface TargetIncomeProps {
  form: Partial<VocLibraryObject>;
  formErrors: any; // ValidationResult<VocLibraryObject>;
  onFormChange: (changeValues: Partial<VocLibraryObject>) => void;
}

const TargetIncome: React.FC<TargetIncomeProps> = ({
  form,
  formErrors,
  onFormChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <AppFormRow>
        <AppFormCalendarField
          isRequired={true}
          label={t("voc.library.effectiveDateFrom")}
          value={form.fromEffectiveDate}
          errorMessage={formErrors && formErrors.fromEffectiveDate}
          onChange={(date) => onFormChange({ fromEffectiveDate: date })}
        />
        <AppFormCalendarField
          label={t("voc.library.effectiveDateTo")}
          value={form.effectiveDate}
          errorMessage={formErrors && formErrors.effectiveDate}
          onChange={(date) => onFormChange({ effectiveDate: date })}
        />
      </AppFormRow>
      <AppFormRow>
        <AppFormNumericField
          decimalPlaces={0}
          label={t("voc.library.targetValue")}
          name="comment"
          required={true}
          value={form.targetValue}
          errorMessage={formErrors && formErrors.targetValue}
          onValueChange={(value) => onFormChange({ targetValue: value })}
        />
        <AppFormField />
      </AppFormRow>
    </>
  );
};

export default TargetIncome;
