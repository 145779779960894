import React from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import "./AppDataList.scss";

import { isNull } from "lodash";
import {
  DatePicker,
  Dropdown,
  IBasePickerSuggestionsProps,
  ITag,
  TagPicker,
  Toggle,
  TooltipHost,
} from "office-ui-fabric-react";

import { convertIntoAmountFormat } from "../../utils/amount";
import { mapDateWithWeekdaysToString } from "../../utils/mappers";
import AppFileUpload from "../app-file-upload/AppFileUpload";

import AccessVisible from "../app-access-visible/AppAccessVisible";
import { Right } from "../../services/app";
import {
  calculateOHP,
  formatDate,
  formatForEnums,
  translateLeft,
  translateRight,
} from "./helper";
import {
  AppDataListColumn,
  DragState,
  SortedColumn,
  DataListItem,
} from "./types";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import moment from "moment";
import { getUserDisplayName } from "../../utils/helper/get-user-display-name";
import { InputNumber } from "antd";
import i18n from "../../i18n/i18n";

interface AppDataListPropsLayout {
  // general
  labelIfEmpty?: string;
  extenderCssClass?: string;
  totalUp?: boolean;
  autoheight?: boolean;
  //permission
  permissionCode?: Right["objectCode"];
  // translate
  translate: string;
  setTranslate: React.Dispatch<React.SetStateAction<string>>;
  translateLimit: any;
  translates?: number;
  // table
  columns: AppDataListColumn[];
  items: DataListItem[];
  // sortedItems must be empty in AppDataListV2
  sortedItems?: DataListItem[];
  // setSortedItems in AppDataList(old ver), setItems in AppDataListV2
  setItemCallbackOnClickSort?: React.Dispatch<
    React.SetStateAction<DataListItem[]>
  >;
  // table flag/funcs
  editing: boolean;
  canDeleteLastItem: boolean;
  undoCallback?: Function;
  // sorting
  isDisableSort?: boolean;
  customSort: boolean;
  setCustomSort: React.Dispatch<React.SetStateAction<boolean>>;
  sortedColumn: SortedColumn;
  setSortedColumn: React.Dispatch<React.SetStateAction<SortedColumn>>;
  sortMarker: number;
  setSortMarker: React.Dispatch<React.SetStateAction<number>>;
  sortDirectionIsUp: boolean;
  setSortDirectionIsUp: React.Dispatch<React.SetStateAction<boolean>>;
  dragging: DragState;
  setDragging: React.Dispatch<React.SetStateAction<DragState>>;
  saveCustomOrderCallback?: Function;
  resetCustomOrderCallback?: Function;
  saveCustomOrderCode?: string;
  // container funcs
  triggerSort: Function;
  handleFieldChange: Function;
  handleFilesChange: any;
  clearItem: Function;
  deleteItem: Function;
  filterEntityUserTags: any;
  disableFutureDay: boolean;
}

function reducer(itemsToReduce: DataListItem[], columnNumber: number) {
  return itemsToReduce.reduce((accumulator, item) => {
    return accumulator + Number(item.fieldDataArray[columnNumber].fieldData);
  }, 0);
}

const AppDataListLayout: React.FC<AppDataListPropsLayout> = (props) => {
  const { t } = useTranslation();
  const {
    // general
    labelIfEmpty,
    extenderCssClass,
    totalUp,
    autoheight,
    //permission
    permissionCode,
    // translate
    translate,
    setTranslate,
    translateLimit,
    translates,
    // table
    columns,
    items,
    sortedItems, // sortedItems must be empty in AppDataListV2, all sort result will be done in 'items' but not 'sortedItems'
    setItemCallbackOnClickSort,
    // table flag/funcs
    editing,
    canDeleteLastItem,
    undoCallback,
    // sorting
    isDisableSort,
    customSort,
    setCustomSort,
    sortedColumn,
    setSortedColumn,
    sortMarker,
    setSortMarker,
    sortDirectionIsUp,
    setSortDirectionIsUp,
    dragging,
    setDragging,
    saveCustomOrderCallback,
    resetCustomOrderCallback,
    saveCustomOrderCode,
    // container funcs
    triggerSort,
    handleFieldChange,
    handleFilesChange,
    clearItem,
    deleteItem,
    filterEntityUserTags,
    disableFutureDay,
  } = props;

  const getTextFromItem = (item: ITag) => item.name;

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: t("voc.appFieldBlockComponent.suggestedChoices"),
    noResultsFoundText: t("voc.appFieldBlockComponent.noResultsFound"),
  };

  const ButtonWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  return (
    <div className="item-list-container">
      {extenderCssClass && translate !== translateLimit ? (
        <div
          className="translateright"
          onClick={() => translateRight(translates, translate, setTranslate)}
        >
          <svg
            width="6"
            height="11"
            viewBox="0 0 6 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.658542 0C0.483892 0 0.316385 0.07975 0.192883 0.221704C0.069382 0.36366 0 0.556187 0 0.756947V9.32082C0 9.52156 0.069382 9.71406 0.192883 9.85606C0.316385 9.99806 0.483892 10.0778 0.658542 10.0778C0.833202 10.0778 1.0007 9.99806 1.1242 9.85606L4.8495 5.57412C4.973 5.43217 5.04238 5.23964 5.04238 5.03888C5.04238 4.83813 4.973 4.6456 4.8495 4.50364L1.1242 0.221704C1.0007 0.07975 0.833202 0 0.658542 0Z"
              fill="#8A9999"
            />
          </svg>
        </div>
      ) : null}
      <div
        className={
          "item-headers" + (extenderCssClass ? " " + extenderCssClass : "")
        }
      >
        {columns.map((column, i) => (
          <div
            key={i}
            className={
              "item-header " +
              column.columnWidthClassName +
              (i === 0 ? " first" : "") +
              (i !== 0 ? translate : "")
            }
          >
            {extenderCssClass && translate !== "" && i === 0 ? (
              <div
                className="translateleft"
                onClick={() =>
                  translateLeft(translates, translate, setTranslate)
                }
              >
                <svg
                  width="6"
                  height="11"
                  viewBox="0 0 6 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.658542 0C0.483892 0 0.316385 0.07975 0.192883 0.221704C0.069382 0.36366 0 0.556187 0 0.756947V9.32082C0 9.52156 0.069382 9.71406 0.192883 9.85606C0.316385 9.99806 0.483892 10.0778 0.658542 10.0778C0.833202 10.0778 1.0007 9.99806 1.1242 9.85606L4.8495 5.57412C4.973 5.43217 5.04238 5.23964 5.04238 5.03888C5.04238 4.83813 4.973 4.6456 4.8495 4.50364L1.1242 0.221704C1.0007 0.07975 0.833202 0 0.658542 0Z"
                    fill="#8A9999"
                  />
                </svg>
              </div>
            ) : null}
            <div
              className="item-header-text"
              onClick={() =>
                isDisableSort || column.disableColumnSort
                  ? null
                  : triggerSort(i)
              }
            >
              {isDisableSort || column.disableColumnSort ? null : (
                <div className="sort">
                  {!customSort ? (
                    sortedColumn.columnIndex !== i ? (
                      <svg
                        width="10"
                        height="15"
                        viewBox="0 0 10 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M-2.85438e-08 4.34699C-2.09738e-08 4.52018 0.0791347 4.68627 0.219993 4.80874C0.360854 4.9312 0.551896 5 0.751107 5L9.2489 5C9.44809 5 9.63911 4.9312 9.78001 4.80874C9.92092 4.68627 10 4.52017 10 4.34699C10 4.1738 9.92092 4.00771 9.78001 3.88525L5.53111 0.191258C5.39025 0.0687964 5.19921 -2.27265e-07 5 -2.18557e-07C4.8008 -2.0985e-07 4.60975 0.0687964 4.46889 0.191258L0.219993 3.88525C0.0791346 4.00771 -3.61143e-08 4.1738 -2.85438e-08 4.34699Z"
                          fill="#8A9999"
                        />
                        <path
                          d="M10 10.653C10 10.4798 9.92087 10.3137 9.78001 10.1913C9.63915 10.0688 9.4481 10 9.24889 10L0.7511 10C0.551908 10 0.360893 10.0688 0.219989 10.1913C0.0790844 10.3137 5.72185e-09 10.4798 7.78703e-09 10.653C9.85232e-09 10.8262 0.0790844 10.9923 0.219989 11.1148L4.46889 14.8087C4.60975 14.9312 4.80079 15 5 15C5.1992 15 5.39025 14.9312 5.53111 14.8087L9.78001 11.1148C9.92087 10.9923 10 10.8262 10 10.653Z"
                          fill="#8A9999"
                        />
                      </svg>
                    ) : sortedColumn.sortDirection === "asc" ? (
                      <svg
                        width="10"
                        height="15"
                        viewBox="0 0 10 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M-2.85438e-08 4.34699C-2.09738e-08 4.52018 0.0791347 4.68627 0.219993 4.80874C0.360854 4.9312 0.551896 5 0.751107 5L9.2489 5C9.44809 5 9.63911 4.9312 9.78001 4.80874C9.92092 4.68627 10 4.52017 10 4.34699C10 4.1738 9.92092 4.00771 9.78001 3.88525L5.53111 0.191258C5.39025 0.0687964 5.19921 -2.27265e-07 5 -2.18557e-07C4.8008 -2.0985e-07 4.60975 0.0687964 4.46889 0.191258L0.219993 3.88525C0.0791346 4.00771 -3.61143e-08 4.1738 -2.85438e-08 4.34699Z"
                          fill="#024A94"
                        />
                        <path
                          d="M10 10.653C10 10.4798 9.92087 10.3137 9.78001 10.1913C9.63915 10.0688 9.4481 10 9.24889 10L0.7511 10C0.551908 10 0.360893 10.0688 0.219989 10.1913C0.0790844 10.3137 5.72185e-09 10.4798 7.78703e-09 10.653C9.85232e-09 10.8262 0.0790844 10.9923 0.219989 11.1148L4.46889 14.8087C4.60975 14.9312 4.80079 15 5 15C5.1992 15 5.39025 14.9312 5.53111 14.8087L9.78001 11.1148C9.92087 10.9923 10 10.8262 10 10.653Z"
                          fill="#8A9999"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="10"
                        height="15"
                        viewBox="0 0 10 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 10.653C10 10.4798 9.92087 10.3137 9.78001 10.1913C9.63915 10.0688 9.4481 10 9.24889 10L0.7511 10C0.551908 10 0.360893 10.0688 0.219989 10.1913C0.0790844 10.3137 -2.09738e-08 10.4798 -2.85438e-08 10.653C-3.61143e-08 10.8262 0.0790844 10.9923 0.219989 11.1148L4.46889 14.8087C4.60975 14.9312 4.80079 15 5 15C5.1992 15 5.39025 14.9312 5.53111 14.8087L9.78001 11.1148C9.92087 10.9923 10 10.8262 10 10.653Z"
                          fill="#024A94"
                        />
                        <path
                          d="M4.93006e-08 4.34699C3.62258e-08 4.52018 0.0791347 4.68627 0.219993 4.80874C0.360854 4.9312 0.551896 5 0.751107 5L9.2489 5C9.44809 5 9.63911 4.9312 9.78001 4.80874C9.92092 4.68627 10 4.52018 10 4.34699C10 4.1738 9.92092 4.00771 9.78001 3.88525L5.53111 0.191259C5.39025 0.068797 5.19921 3.92529e-07 5 3.77489e-07C4.8008 3.6245e-07 4.60975 0.068797 4.46889 0.191259L0.219993 3.88525C0.0791347 4.00771 6.23763e-08 4.1738 4.93006e-08 4.34699Z"
                          fill="#8A9999"
                        />
                      </svg>
                    )
                  ) : null}
                </div>
              )}
              {column.columnName}
            </div>
          </div>
        ))}
      </div>
      <div
        className={"items" + (extenderCssClass ? " " + extenderCssClass : "")}
      >
        {customSort ? (
          <TooltipHost content={t("voc.appDataListComponent.orderForms")}>
            <div
              className="custom-sort-notice"
              onClick={() => {
                resetCustomOrderCallback(saveCustomOrderCode);
                setSortedColumn({
                  columnIndex: 0,
                  // isAsc: true,
                  sortDirection: "asc",
                });
                setCustomSort(false);
              }}
            >
              {t("voc.appDataListComponent.customOrderSaved")}
            </div>
          </TooltipHost>
        ) : null}
        {items.length === 0 ? (
          // Updated for VC-298: will take prop labelIfEmpty if defined
          <div className="item-container">
            <div className="wrapper">
              <div
                className={
                  "item none" +
                  (extenderCssClass ? " compensate-" + extenderCssClass : "")
                }
              >
                <div className="nonetext">
                  {labelIfEmpty
                    ? labelIfEmpty
                    : t("voc.appDataListComponent.noRecords")}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {sortMarker === 0 ? <div className="sort-marker"></div> : null}
        {(sortedItems ? sortedItems : items).map((item, i) => (
          <div
            // item.id must exist if it is the items from api, i is the new item index that without item id
            // TO ENHANCEL: better add tempId for new item, and avoid using id as the order's matters
            key={item.id ?? i}
            className={"item-container" + (item.hidden ? " hidden" : "")}
          >
            {sortMarker === i &&
            sortMarker !== items.length - 1 &&
            sortMarker !== 0 &&
            sortDirectionIsUp ? (
              <div className="sort-marker"></div>
            ) : null}
            <Draggable
              axis="y"
              bounds={{
                top: -68 * i,
                bottom: (items.length - i - 1) * 68 + 10,
              }}
              position={{ x: 0, y: 0 }}
              cancel=".nodrag"
              disabled={!saveCustomOrderCallback}
              onStart={(e, ui) => {
                setDragging({ drag: ui.y, item: i });
              }}
              onDrag={(e, ui) => {
                const dragged = (ui.y - dragging.drag) / 68;
                if (dragged > 0) {
                  setSortDirectionIsUp(false);
                } else {
                  setSortDirectionIsUp(true);
                }
                setSortMarker(i + Number(dragged.toFixed(0)));
              }}
              onStop={(e, ui) => {
                const dragged = (ui.y - dragging.drag) / 68;
                if (dragged) {
                  const copy = [...(sortedItems ? sortedItems : items)];
                  copy.splice(
                    i + Number(dragged.toFixed(0)),
                    0,
                    copy.splice(i, 1)[0]
                  );
                  setItemCallbackOnClickSort(copy);
                  setCustomSort(true);
                  if (saveCustomOrderCallback) {
                    saveCustomOrderCallback(copy, saveCustomOrderCode);
                  }
                }
                setDragging({ drag: ui.y, item: -1 });
                setSortMarker(null);
              }}
            >
              <div
                className={"wrapper" + (dragging.item === i ? " dragging" : "")}
              >
                {item.validationError ? (
                  <div className="validation-error">{item.validationError}</div>
                ) : null}
                <div
                  className={
                    "item" +
                    (dragging.item === i ? " dragging" : "") +
                    (autoheight ? " autoheight" : "")
                  }
                >
                  {editing &&
                  !item.readonly &&
                  !item.undeletable &&
                  item.immutableIndex !== 0 &&
                  !extenderCssClass ? (
                    <ButtonWrapper
                      condition={Boolean(permissionCode)}
                      wrapper={(children) => (
                        <AccessVisible
                          objectCode={permissionCode}
                          actionType="delete"
                          fallback={<div className="delete"></div>}
                        >
                          {children}
                        </AccessVisible>
                      )}
                      children={
                        <div className="delete">
                          <div
                            className="clickable nodrag"
                            onClick={() =>
                              deleteItem(item.immutableIndex, item)
                            }
                          >
                            {t("voc.appDataListComponent.delete")}
                          </div>
                        </div>
                      }
                    />
                  ) : null}
                  {editing &&
                  !item.readonly &&
                  !item.undeletable &&
                  item.immutableIndex === 0 &&
                  !extenderCssClass ? (
                    <>
                      {canDeleteLastItem /*|| items.length > 1*/ ? (
                        <ButtonWrapper
                          condition={Boolean(permissionCode)}
                          wrapper={(children) => (
                            <AccessVisible
                              objectCode={permissionCode}
                              actionType="delete"
                              fallback={<div className="delete"></div>}
                            >
                              {children}
                            </AccessVisible>
                          )}
                          children={
                            <div className="delete">
                              <div
                                className="clickable nodrag"
                                onClick={() =>
                                  deleteItem(item.immutableIndex, item)
                                }
                              >
                                {t("voc.appDataListComponent.delete")}
                              </div>
                            </div>
                          }
                        />
                      ) : (
                        <ButtonWrapper
                          condition={Boolean(permissionCode)}
                          wrapper={(children) => (
                            <AccessVisible
                              objectCode={permissionCode}
                              actionType="edit"
                              fallback={
                                <div className="first-column-spacer"></div>
                              }
                            >
                              {children}
                            </AccessVisible>
                          )}
                          children={
                            <div className="clear">
                              <div
                                className="clickable nodrag"
                                onClick={() => clearItem(item.immutableIndex)}
                              >
                                {t("voc.appDataListComponent.clear")}
                              </div>
                            </div>
                          }
                        />
                      )}
                    </>
                  ) : null}
                  {undoCallback ? (
                    <div className={"undo" + (autoheight ? " autoheight" : "")}>
                      {item.action === "UPDATED" ? (
                        <ButtonWrapper
                          condition={Boolean(permissionCode)}
                          wrapper={(children) => (
                            <AccessVisible
                              objectCode={permissionCode}
                              actionType="edit"
                              fallback={
                                <div className="clickable nodrag"></div>
                              }
                            >
                              {children}
                            </AccessVisible>
                          )}
                          children={
                            <div
                              className="clickable nodrag"
                              onClick={() => undoCallback(item.id)}
                            >
                              {t("voc.appDataListComponent.undo")}
                            </div>
                          }
                        />
                      ) : null}
                      {item.action !== "UPDATED" ? (
                        <div className="spacer"></div>
                      ) : null}
                    </div>
                  ) : null}
                  {editing && (item.readonly || item.undeletable) ? (
                    <div className="lock">
                      <TooltipHost content={item.readonlyNotice}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 100 100"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.625 56.25C15.625 52.9348 16.942 49.7554 19.2862 47.4112C21.6304 45.067 24.8098 43.75 28.125 43.75H71.875C75.1902 43.75 78.3696 45.067 80.7138 47.4112C83.058 49.7554 84.375 52.9348 84.375 56.25V87.5C84.375 90.8152 83.058 93.9946 80.7138 96.3388C78.3696 98.683 75.1902 100 71.875 100H28.125C24.8098 100 21.6304 98.683 19.2862 96.3388C16.942 93.9946 15.625 90.8152 15.625 87.5V56.25Z"
                            fill="black"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M28.125 25C28.125 19.1984 30.4297 13.6344 34.532 9.53204C38.6344 5.42968 44.1984 3.125 50 3.125C55.8016 3.125 61.3656 5.42968 65.468 9.53204C69.5703 13.6344 71.875 19.1984 71.875 25V43.75H65.625V25C65.625 20.856 63.9788 16.8817 61.0485 13.9515C58.1183 11.0212 54.144 9.375 50 9.375C45.856 9.375 41.8817 11.0212 38.9515 13.9515C36.0212 16.8817 34.375 20.856 34.375 25V43.75H28.125V25Z"
                            fill="black"
                          />
                        </svg>
                      </TooltipHost>
                    </div>
                  ) : null}
                  {item.fieldDataArray.map((field, i2) => (
                    <div
                      key={i2}
                      className={
                        "item-col" +
                        (i2 === 0 ? " first" : "") +
                        (i2 + 1 === item.fieldDataArray.length ? " last" : "") +
                        ((i2 === 0 && editing && !extenderCssClass) ||
                        undoCallback
                          ? " firstedit"
                          : "") +
                        " " +
                        columns[i2].columnWidthClassName +
                        " " +
                        columns[i2].columnDataType +
                        (i2 !== 0 ? translate : "") +
                        (editing && columns[i2].editable && !field.readonly
                          ? " editpadtop"
                          : "") +
                        (item.readonly || item.undeletable ? " locked" : "")
                      }
                    >
                      {editing &&
                      !item.readonly &&
                      !item.undeletable &&
                      i2 === 0 &&
                      item.immutableIndex !== 0 &&
                      extenderCssClass ? (
                        <div className="delete">
                          <div
                            className="clickable nodrag"
                            onClick={() =>
                              deleteItem(item.immutableIndex, item)
                            }
                          >
                            {t("voc.appDataListComponent.delete")}
                          </div>
                        </div>
                      ) : null}
                      {editing &&
                      !item.readonly &&
                      !item.undeletable &&
                      i2 === 0 &&
                      item.immutableIndex === 0 &&
                      canDeleteLastItem &&
                      extenderCssClass ? (
                        <div className="delete">
                          <div
                            className="clickable nodrag"
                            onClick={() =>
                              deleteItem(item.immutableIndex, item)
                            }
                          >
                            {t("voc.appDataListComponent.delete")}
                          </div>
                        </div>
                      ) : null}
                      {editing &&
                      !item.readonly &&
                      !item.undeletable &&
                      i2 === 0 &&
                      item.immutableIndex === 0 &&
                      !canDeleteLastItem &&
                      extenderCssClass ? (
                        <div className="clear">
                          <div
                            className="clickable nodrag"
                            onClick={() => clearItem(item.immutableIndex)}
                          >
                            {t("voc.appDataListComponent.clear")}
                          </div>
                        </div>
                      ) : null}

                      {columns[i2].columnDataType === "amount" ? (
                        editing && columns[i2].editable && !field.readonly ? (
                          <InputNumber
                            decimalSeparator={
                              ["de", "es"].includes(i18n.language) ? "," : "."
                            }
                            formatter={(
                              value: number | undefined,
                              { userTyping }
                            ) => {
                              if (!value) {
                                return "";
                              }

                              const number = Number(value).toLocaleString(
                                i18n.language,
                                {
                                  minimumFractionDigits: userTyping ? 0 : 2,
                                  maximumFractionDigits: 2,
                                }
                              );
                              return number;
                            }}
                            parser={(value?: string) => {
                              if (!value) {
                                return "";
                              }
                              const groupSeparator = ["de", "es"].includes(
                                i18n.language
                              )
                                ? "."
                                : ",";

                              const decimalSeparator = ["de", "es"].includes(
                                i18n.language
                              )
                                ? ","
                                : ".";

                              const numberStr = value
                                .replaceAll(groupSeparator, "")
                                .replaceAll(decimalSeparator, ".");
                              console.log("parsered", Number(numberStr));
                              return Number(numberStr);
                            }}
                            className={
                              "input-element" +
                              (extenderCssClass ? " wee" : "") +
                              (columns[i2].columnWidthClassName ===
                                "eightpercent" ||
                              columns[i2].columnWidthClassName ===
                                "sixpointfive"
                                ? " lil"
                                : "") +
                              (i2 === 0 ? " first" : "") +
                              " nodrag"
                            }
                            controls={false}
                            value={
                              !field.fieldData ? "" : Number(field.fieldData)
                            }
                            onChange={(value) => {
                              handleFieldChange(item.immutableIndex, i2, value);
                            }}
                          />
                        ) : (
                          <div
                            className={
                              "value" +
                              (columns[i2].stringConversionSymbol &&
                              field.fieldData < 0
                                ? " negative"
                                : "")
                            }
                          >
                            {(columns[i2].stringConversionSymbol &&
                            field.fieldData !== null &&
                            columns[i2].stringConversionSymbol !== "%"
                              ? columns[i2].stringConversionSymbol
                              : "") +
                              (columns[i2].stringConversionDecimals &&
                              field.fieldData !== null
                                ? convertIntoAmountFormat(
                                    field.fieldData,
                                    columns[i2].stringConversionSymbol,
                                    columns[i2].stringConversionDecimals
                                  ) +
                                  (columns[i2].stringConversionSymbol &&
                                  field.fieldData !== null &&
                                  columns[i2].stringConversionSymbol === "%"
                                    ? columns[i2].stringConversionSymbol
                                    : "")
                                : field.fieldData
                                ? field.fieldData
                                : "")}
                          </div>
                        )
                      ) : null}

                      {columns[i2].columnDataType === "string" ? (
                        editing && columns[i2].editable && !field.readonly ? (
                          <input
                            className={
                              "input-element" +
                              (extenderCssClass ? " wee" : "") +
                              (columns[i2].columnWidthClassName ===
                                "eightpercent" ||
                              columns[i2].columnWidthClassName ===
                                "sixpointfive"
                                ? " lil"
                                : "") +
                              (i2 === 0 ? " first" : "") +
                              " nodrag"
                            }
                            value={!field.fieldData ? "" : field.fieldData}
                            onChange={(e) =>
                              handleFieldChange(
                                item.immutableIndex,
                                i2,
                                e.target.value
                              )
                            }
                          ></input>
                        ) : (
                          <div
                            className={
                              "value" +
                              (columns[i2].stringConversionSymbol &&
                              field.fieldData < 0
                                ? " negative"
                                : "")
                            }
                          >
                            {(columns[i2].stringConversionSymbol &&
                            field.fieldData !== null &&
                            columns[i2].stringConversionSymbol !== "%"
                              ? columns[i2].stringConversionSymbol
                              : "") +
                              (columns[i2].stringConversionDecimals &&
                              field.fieldData !== null
                                ? convertIntoAmountFormat(
                                    field.fieldData,
                                    columns[i2].stringConversionSymbol,
                                    columns[i2].stringConversionDecimals
                                  ) +
                                  (columns[i2].stringConversionSymbol &&
                                  field.fieldData !== null &&
                                  columns[i2].stringConversionSymbol === "%"
                                    ? columns[i2].stringConversionSymbol
                                    : "")
                                : field.fieldData
                                ? field.fieldData
                                : "")}
                          </div>
                        )
                      ) : null}
                      {columns[i2].columnDataType === "basicdropdown" ? (
                        editing && columns[i2].editable && !field.readonly ? (
                          <select
                            className="nodrag"
                            value={field.fieldData}
                            onChange={(e) => {
                              handleFieldChange(
                                item.immutableIndex,
                                i2,
                                e.target.value
                              );
                            }}
                          >
                            {columns[i2].columnOptions.map(
                              (option: any, o: number) => (
                                <option key={o} value={option.enumValue}>
                                  {option.render}
                                </option>
                              )
                            )}
                          </select>
                        ) : (
                          <div className="dropdown-value">
                            {formatForEnums(
                              field.fieldData,
                              columns[i2].columnOptions
                            )}
                          </div>
                        )
                      ) : null}
                      {columns[i2].columnDataType === "multiselectdropdown" ? (
                        editing && columns[i2].editable && !field.readonly ? (
                          <Dropdown
                            calloutProps={{ className: "voc-location-select" }}
                            multiSelect
                            selectedKeys={field.fieldData}
                            onChange={(e, selected) => {
                              let newArray = field.fieldData
                                ? [...field.fieldData]
                                : [];
                              if (
                                field.fieldData &&
                                field.fieldData.includes(selected.key)
                              ) {
                                newArray.splice(
                                  field.fieldData.indexOf(selected.key),
                                  1
                                );
                              } else {
                                newArray = [...newArray, selected.key];
                              }
                              handleFieldChange(
                                item.immutableIndex,
                                i2,
                                newArray
                              );
                            }}
                            options={columns[i2].columnOptions.map(
                              (option) => ({
                                key: option.enumValue,
                                text: option.render,
                                disabled:
                                  option.childOf &&
                                  !field.fieldData.includes(option.childOf),
                              })
                            )}
                          ></Dropdown>
                        ) : (
                          <div className="dropdown-value">
                            {field.fieldData
                              ? field.fieldData.map((o: any, i: number) => (
                                  <span key={i}>
                                    {formatForEnums(
                                      o,
                                      columns[i2].columnOptions
                                    ) +
                                      (o + 1 === field.fieldData.length
                                        ? ""
                                        : ", ")}
                                  </span>
                                ))
                              : null}
                          </div>
                        )
                      ) : null}
                      {columns[i2].columnDataType === "owner" ? (
                        editing && columns[i2].editable && !field.readonly ? (
                          <TagPicker
                            className={field.fieldData ? "selected" : undefined}
                            removeButtonAriaLabel={t(
                              "voc.appFieldBlockComponent.remove"
                            )}
                            onResolveSuggestions={filterEntityUserTags}
                            getTextFromItem={getTextFromItem}
                            pickerSuggestionsProps={pickerSuggestionsProps}
                            itemLimit={columns[i2].itemLimit}
                            defaultSelectedItems={
                              field.fieldData
                                ? [
                                    {
                                      name: getUserDisplayName(
                                        field.fieldData,
                                        false,
                                        true
                                      ),
                                      key: field.fieldData.id,
                                    },
                                  ]
                                : undefined
                            }
                            onChange={(items) => {
                              handleFieldChange(
                                item.immutableIndex,
                                i2,
                                items[0] //need to change this if case of multi-owner fields is needed
                              );
                            }}
                            onInputChange={(e) => {
                              return e;
                            }}
                          />
                        ) : (
                          <div className="dropdown-value">
                            {field.fieldData
                              ? getUserDisplayName(field.fieldData, false, true)
                              : null}
                          </div>
                        )
                      ) : null}
                      {columns[i2].columnDataType === "link" ? (
                        editing && columns[i2].editable && !field.readonly ? (
                          <input
                            className={
                              "input-element" +
                              (extenderCssClass ? " wee" : "") +
                              (columns[i2].columnWidthClassName ===
                                "eightpercent" ||
                              columns[i2].columnWidthClassName ===
                                "sixpointfive"
                                ? " lil"
                                : "") +
                              (i2 === 0 ? " first" : "") +
                              " nodrag"
                            }
                            value={field.fieldData ?? ""}
                            onChange={(e) =>
                              handleFieldChange(
                                item.immutableIndex,
                                i2,
                                e.target.value
                              )
                            }
                          ></input>
                        ) : (
                          <div className="link">
                            {field.fieldData ? (
                              field.linkUrl ? (
                                <a
                                  href={field.linkUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {field.fieldData}
                                </a>
                              ) : (
                                <a
                                  className="nodrag"
                                  href={
                                    field.fieldData.indexOf("http") !== 0
                                      ? "http://" + field.fieldData
                                      : field.fieldData
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <svg
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.06622 8.26834L1.58756 10.747C0.571062 11.7635 0 13.1422 0 14.5797C0 16.0173 0.571062 17.3959 1.58756 18.4124C2.60406 19.4289 3.98272 20 5.42026 20C6.85781 20 8.23647 19.4289 9.25297 18.4124L12.5554 15.1081C13.1684 14.4951 13.6254 13.7439 13.888 12.9177C14.1506 12.0915 14.2111 11.2144 14.0646 10.3599C13.9181 9.50545 13.5687 8.69861 13.0458 8.00712C12.5229 7.31562 11.8418 6.75965 11.0596 6.38586L10.0009 7.44453C9.89341 7.55219 9.79993 7.67298 9.72269 7.80405C10.327 7.97779 10.8756 8.30625 11.3141 8.75695C11.7526 9.20766 12.0659 9.76501 12.223 10.3739C12.3801 10.9828 12.3756 11.6221 12.2099 12.2288C12.0442 12.8354 11.723 13.3882 11.2782 13.8327L7.97751 17.1351C7.29953 17.8131 6.37998 18.194 5.42117 18.194C4.46235 18.194 3.54281 17.8131 2.86483 17.1351C2.18684 16.4572 1.80595 15.5376 1.80595 14.5788C1.80595 13.62 2.18684 12.7004 2.86483 12.0225L4.29746 10.5916C4.09533 9.83417 4.01724 9.04897 4.06622 8.26654V8.26834Z"
                                      fill="#005794"
                                    />
                                    <path
                                      d="M5.86697 9.02355L6.16867 8.72185C6.61626 8.27261 7.17472 7.94975 7.78738 7.78603C7.9511 7.17337 8.27396 6.61491 8.7232 6.16732L9.02491 5.86561C8.11612 5.81452 7.20909 5.99282 6.38727 6.38411C5.98981 7.21515 5.81638 8.12387 5.86697 9.02175V9.02355Z"
                                      fill="#005794"
                                    />
                                    <path
                                      d="M7.44653 4.89003C6.83356 5.50309 6.37659 6.25424 6.114 7.08045C5.8514 7.90665 5.79082 8.7838 5.93736 9.63826C6.0839 10.4927 6.43328 11.2996 6.95616 11.991C7.47904 12.6825 8.16018 13.2385 8.94239 13.6123L10.3425 12.2104C9.72998 12.0461 9.17148 11.7235 8.72314 11.2749C8.27479 10.8264 7.9524 10.2678 7.78836 9.65516C7.62431 9.04256 7.6244 8.39757 7.7886 7.78501C7.9528 7.17245 8.27534 6.6139 8.72379 6.16549L12.0245 2.86302C12.7024 2.18504 13.622 1.80415 14.5808 1.80415C15.5396 1.80415 16.4592 2.18504 17.1371 2.86302C17.8151 3.541 18.196 4.46055 18.196 5.41936C18.196 6.37818 17.8151 7.29772 17.1371 7.9757L15.7045 9.40653C15.9068 10.1653 15.9845 10.9512 15.9357 11.7316L18.4144 9.25297C19.4309 8.23647 20.002 6.85781 20.002 5.42026C20.002 3.98272 19.4309 2.60406 18.4144 1.58756C17.3979 0.571062 16.0192 0 14.5817 0C13.1442 0 11.7655 0.571062 10.749 1.58756L7.44653 4.89003Z"
                                      fill="#005794"
                                    />
                                  </svg>
                                </a>
                              )
                            ) : null}
                          </div>
                        )
                      ) : null}
                      {columns[i2].columnDataType === "date" ? (
                        editing && columns[i2].editable && !field.readonly ? (
                          <div className="datepicker-squeezer">
                            <DatePicker
                              className="nodrag"
                              value={
                                field.fieldData
                                  ? new Date(field.fieldData)
                                  : undefined
                              }
                              formatDate={(date) => {
                                return mapDateWithWeekdaysToString(date);
                              }}
                              maxDate={
                                disableFutureDay
                                  ? moment(Date.now()).toDate()
                                  : undefined
                              }
                              onSelectDate={(date) =>
                                handleFieldChange(item.immutableIndex, i2, date)
                              }
                            ></DatePicker>
                            {columns[i2].canClearDate ? (
                              <div
                                className="clear-date nodrag"
                                onClick={() =>
                                  handleFieldChange(
                                    item.immutableIndex,
                                    i2,
                                    null
                                  )
                                }
                              >
                                X
                              </div>
                            ) : null}
                          </div>
                        ) : !columns[i2].noValueButtonCallback ||
                          field.fieldData ? (
                          formatDate(field.fieldData, true)
                        ) : (
                          <NxpButton
                            loading={columns[i2].loading}
                            disabled={isNull(item?.id)}
                            onClick={() => {
                              columns[i2].noValueButtonCallback(item);
                            }}
                          >
                            {`${t("voc.common.notify").toLocaleUpperCase()}`}
                          </NxpButton>
                        )
                      ) : null}
                      {columns[i2].columnDataType === "file" ? (
                        editing && columns[i2].editable && !field.readonly ? (
                          field.fieldData?.length > 0 ? (
                            <div className="existingdoc">
                              <a
                                className="nodrag"
                                href={field.fieldData[0].url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {field.fieldData[0].fileName}
                              </a>
                              {columns[i2].noRemove ? null : (
                                <div
                                  className="remove nodrag"
                                  onClick={() =>
                                    handleFieldChange(
                                      item.immutableIndex,
                                      i2,
                                      []
                                    )
                                  }
                                >
                                  X
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="nodrag">
                              <AppFileUpload
                                label={null}
                                fileRef={item.immutableIndex}
                                onChange={handleFilesChange}
                                fileLimit={1}
                              />
                            </div>
                          )
                        ) : field.fieldData?.[0] ? (
                          <div className="filename">
                            <a
                              className="nodrag"
                              href={field.fieldData[0].url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {field.fieldData[0].fileName}
                            </a>
                          </div>
                        ) : typeof field.fieldData === "object" ? (
                          <div className="filename">
                            <a
                              className="nodrag"
                              href={field.fieldData.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {field.fieldData.fileName}
                            </a>
                          </div>
                        ) : null
                      ) : null}
                      {columns[i2].columnDataType === "toggle" ? (
                        editing && columns[i2].editable && !field.readonly ? (
                          <Toggle
                            checked={field.fieldData}
                            onChange={() => {
                              handleFieldChange(
                                item.immutableIndex,
                                i2,
                                !field.fieldData
                              );
                            }}
                          />
                        ) : (
                          <div className="toggle-value">
                            {field.fieldData ? "Yes" : "No"}
                          </div>
                        )
                      ) : null}
                      {columns[i2].columnDataType === "customDisplay" ? ( // currently for logs node display only
                        <div>{field.fieldData}</div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </Draggable>
            {sortMarker === i &&
            sortMarker !== items.length - 1 &&
            sortMarker !== 0 &&
            !sortDirectionIsUp ? (
              <div className="sort-marker"></div>
            ) : null}
          </div>
        ))}
        {sortMarker === items.length - 1 && !sortDirectionIsUp ? (
          <div className="sort-marker"></div>
        ) : null}
        {totalUp && items.length > 0 ? (
          <div className="item-container">
            <div className="wrapper">
              <div className="total item">
                {columns.map((col, i) => (
                  <div
                    key={i}
                    className={
                      "item-col" +
                      (i === 0 ? " first" : "") +
                      (i + 1 === columns.length ? " last" : " ") +
                      " " +
                      columns[i].columnWidthClassName +
                      " " +
                      columns[i].columnDataType +
                      (i !== 0 ? translate : "")
                    }
                  >
                    {i === 0 || i === 1 || i === 2 ? (
                      i === 0 ? (
                        t("voc.appDataListComponent.total")
                      ) : (
                        ""
                      )
                    ) : i === 12 ? (
                      <div
                        className={
                          "value" + (calculateOHP(items) < 0 ? " negative" : "")
                        }
                      >
                        {(calculateOHP(items) * 100).toFixed(
                          columns[i].stringConversionDecimals
                        ) + columns[i].stringConversionSymbol}
                      </div>
                    ) : (
                      <div
                        className={
                          "value" + (reducer(items, i) < 0 ? " negative" : "")
                        }
                      >
                        {(columns[i].stringConversionSymbol !== "%"
                          ? columns[i].stringConversionSymbol
                          : "") +
                          reducer(items, i).toFixed(
                            columns[i].stringConversionDecimals
                          ) +
                          (columns[i].stringConversionSymbol === "%"
                            ? columns[i].stringConversionSymbol
                            : "")}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
        {totalUp && items.length === 0 ? (
          <div className="item-container">
            <div className="wrapper">
              <div className="total item">
                {columns.map((col, i) => (
                  <div
                    key={i}
                    className={
                      "item-col" +
                      (i === 0 ? " first" : "") +
                      (i + 1 === columns.length ? " last" : "") +
                      " " +
                      columns[i].columnWidthClassName +
                      " " +
                      columns[i].columnDataType +
                      (i !== 0 ? translate : "")
                    }
                  >
                    {i === 0 || i === 1 || i === 2 ? (
                      i === 0 ? (
                        t("voc.common.total")
                      ) : (
                        ""
                      )
                    ) : (
                      <div className="value">
                        {(columns[i].stringConversionSymbol !== "%"
                          ? columns[i].stringConversionSymbol
                          : "") +
                          Number(0).toFixed(
                            columns[i].stringConversionDecimals
                          ) +
                          (columns[i].stringConversionSymbol === "%"
                            ? columns[i].stringConversionSymbol
                            : "")}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AppDataListLayout;
