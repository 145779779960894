import { useTranslation } from "react-i18next";

import AppButton from "../../../app/components/app-button";
import {
  AppFormDropdownField,
  AppFormRow,
} from "../../../app/components/app-form";
import AppModal from "../../../app/components/app-modal";
import { AsyncResult } from "../../../app/hooks/useAsync";
import { ValidationResult } from "../../../app/hooks/useValidate";
import { VocProjectLibraryType } from "../../../app/types";
import CustomApiError from "../../../app/utils/backend/custom-api-error";
import { LibraryAddForm } from "./AddLibraryContainer";
import ClientRepresentatives from "./form-content/ClientRepresentatives";
import Deadline from "./form-content/Deadline";
import Description from "./form-content/Description";
import DescriptionWithFile from "./form-content/DescriptionWithFile";
import TargetIncome from "./form-content/TargetIncome";

import "./AddLibraryModal.scss";

interface AddLibraryModalProps {
  showAddLibraryModal: boolean;
  form: Partial<LibraryAddForm>;
  createLibraryAsyncResult: AsyncResult<LibraryAddForm>;
  validationError: ValidationResult<LibraryAddForm>;
  onFormChange: (changeValues: Partial<LibraryAddForm>) => void;
  onSave: (addAnother: boolean) => void;
  onModalClose: () => void;
}

const AddLibraryModal: React.FC<AddLibraryModalProps> = ({
  showAddLibraryModal,
  form,
  createLibraryAsyncResult,
  validationError,
  onFormChange,
  onSave,
  onModalClose,
}) => {
  const { t } = useTranslation();
  const formErrors = {
    ...validationError,
    ...(createLibraryAsyncResult.error as CustomApiError)?.serverResponseData,
  };

  const getFormContent = () => {
    switch (form.libraryType) {
      case VocProjectLibraryType.Empowered_Client_Representatives:
        return (
          <ClientRepresentatives
            form={form}
            onFormChange={onFormChange}
            formErrors={formErrors}
          />
        );
      case VocProjectLibraryType.Deadline_or_Key_Dates:
        return (
          <Deadline
            form={form}
            onFormChange={onFormChange}
            formErrors={formErrors}
          />
        );
      case VocProjectLibraryType.Target_Income:
        return (
          <TargetIncome
            form={form}
            onFormChange={onFormChange}
            formErrors={formErrors}
          />
        );
      case VocProjectLibraryType.Clauses_for_Instructions:
      case VocProjectLibraryType.Clauses_for_Valuation:
        return (
          <DescriptionWithFile
            form={form}
            onFormChange={onFormChange}
            formErrors={formErrors}
          />
        );
      case VocProjectLibraryType.Event_Source:
      case VocProjectLibraryType.Event_Source_Type:
      case VocProjectLibraryType.Construction_Status:
      case VocProjectLibraryType.Delaying_and_Disruptive_Effects:
      case VocProjectLibraryType.Record_Status:
      case VocProjectLibraryType.Status_With_Client:
      case VocProjectLibraryType.Support_Document_Status:
      case VocProjectLibraryType.Site_Event_Status:
        return (
          <Description
            form={form}
            onFormChange={onFormChange}
            formErrors={formErrors}
          />
        );
      default:
        return <div className="empty-form"></div>;
    }
  };

  return (
    <AppModal
      className="voc-add-library-modal"
      isOpen={showAddLibraryModal}
      title={t("voc.common.add")}
      onClose={onModalClose}
      loading={createLibraryAsyncResult.loading}
      footer={
        <AppButton
          className="button"
          onClick={() => onSave(false)}
          text={t("voc.common.save")}
        />
      }
      errorContent={
        !(createLibraryAsyncResult.error as CustomApiError)
          ?.serverResponseData && createLibraryAsyncResult.error?.message
      }
    >
      <AppFormRow>
        <AppFormDropdownField
          label={t("voc.library.addLibrary.libraryType")}
          required={true}
          placeholder={t("voc.common.pleaseSelect")}
          options={Object.entries(VocProjectLibraryType)
            .filter(
              (entry) =>
                entry[1] !== VocProjectLibraryType.Client_Notification &&
                entry[1] !==
                  VocProjectLibraryType.Escalation_of_Outstanding_Records
            )
            .map((entry) => ({
              text: entry[0].replace(/_/g, " "),
              key: entry[1],
            }))}
          value={form.libraryType === undefined ? null : form.libraryType}
          errorMessage={formErrors && (formErrors.libraryType as any)}
          onChange={(_, option) => {
            onFormChange({
              libraryType: option.key as VocProjectLibraryType | null,
            });
          }}
        />
      </AppFormRow>
      {getFormContent()}
      <div className="reminder">#{t("voc.library.reminder.saveBeforeAdd")}</div>
    </AppModal>
  );
};

export default AddLibraryModal;
