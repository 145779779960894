import "./AppDataTable.scss";

import React, { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import LazyLoad from "react-lazy-load";

import { FilterColumn } from "../app-filter";
import AppPagination from "../app-pagination";
import AppDataTableHeader from "./AppDataTableHeader";
import AppDataTableLoader from "./AppDataTableLoader";
import AppDataTableRowList from "./AppDataTableRowList";

export enum ColumnDataType {
  String = "String",
  Number = "Number",
  Percent = "Percent",
  Money = "Money",
  Date = "Date",
  Boolean = "Boolean",
  Select = "Select",
}

export interface SortColumn {
  fieldName: string;
  order: "ASC" | "DESC";
}

export interface QuerySettings {
  pageSize: number;
  currentPage: number;
  sortColumns: SortColumn[];
  search?: string;
}

export interface DataTableColumn<T> extends FilterColumn {
  key: string;
  headerClassName?: string;
  cellClassName?: string;
  footerClassName?: string;
  minWidth?: number;
  isResizable?: boolean;
  isSortable: boolean;
  onRender?: (dataItem: T) => ReactNode;
}

interface AppDataTableProps<T> {
  columns: DataTableColumn<T>[];
  keyColumnName: string;
  querySettings: QuerySettings;
  totalRecordCount: number;
  loading: boolean;
  dataSource?: T[];
  enableSearch?: boolean;
  enablePagination?: boolean;
  fixFirstColumn?: boolean;
  onRowClick?: (e: React.MouseEvent<HTMLDivElement>, dataItem: T) => void;
  onRowKeyGenerate?: (dataItem: T) => string;
  onDataTableQuery: (querySettings: QuerySettings) => void;
  onFooterAggregate?: (
    rowDataItem: T,
    footerDataItem: Partial<T>,
    isLastRow: boolean
  ) => void;
  onLazyLoad?: () => void;
}

function AppDataTable<T>(props: AppDataTableProps<T>) {
  const { t } = useTranslation();
  const {
    columns,
    keyColumnName,
    loading,
    dataSource,
    querySettings,
    totalRecordCount,
    enablePagination,
    enableSearch,
    fixFirstColumn,
    onRowClick,
    onRowKeyGenerate,
    onDataTableQuery,
    onFooterAggregate,
    onLazyLoad,
  } = props;

  const handlePageNumberChange = useCallback(
    (pageNumber: number) => {
      onDataTableQuery({
        ...querySettings,
        currentPage: pageNumber,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [querySettings]
  );

  const handlePageSizeChange = useCallback(
    (pageSize: number) => {
      onDataTableQuery({
        ...querySettings,
        pageSize,
        currentPage: 1,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [querySettings]
  );

  return (
    <>
      {(enableSearch || enablePagination) && (
        <div className="app-data-table-toolbar">
          <div className="toolbar-left">
            {enableSearch && (
              <></>
              // <AppDataTableSearchBox
              //   placeholder={t("common.table.searchBox.placeholder")}
              //   querySettings={querySettings}
              //   onDataTableQuery={onDataTableQuery}
              // />
              // searching box is disabled
            )}
          </div>
          {enablePagination && (
            <AppPagination
              pageSize={querySettings?.pageSize || 0}
              currentPage={querySettings?.currentPage || 1}
              totalRecordCount={totalRecordCount || 0}
              onPageNumberChange={handlePageNumberChange}
              onPageSizeChange={handlePageSizeChange}
              // i18n={localeContext}
            />
          )}
        </div>
      )}
      <div role="grid" className="app-data-table">
        <AppDataTableHeader
          columns={columns}
          querySettings={querySettings}
          fixFirstColumn={fixFirstColumn}
          onDataTableQuery={onDataTableQuery}
        />
        {!dataSource ||
        (loading &&
          (querySettings.pageSize > 0 || querySettings.currentPage === 0)) ? (
          <AppDataTableLoader />
        ) : null}
        {dataSource &&
          (dataSource.length === 0 ? (
            <div role="row" className="app-data-table-empty">
              <div role="gridcell" className="read-only">
                <span>
                  {loading
                    ? t("voc.table.loading")
                    : t("voc.table.noRecordsFound")}
                </span>
              </div>
            </div>
          ) : (
            <AppDataTableRowList
              columns={columns}
              dataSource={dataSource}
              keyColumnName={keyColumnName}
              fixFirstColumn={fixFirstColumn}
              onRowClick={onRowClick}
              onRowKeyGenerate={onRowKeyGenerate}
              onFooterAggregate={onFooterAggregate}
              totalRecordCount={totalRecordCount}
            />
          ))}
        {querySettings.pageSize === 0 &&
          !loading &&
          dataSource?.length !== undefined &&
          dataSource.length < totalRecordCount && (
            <LazyLoad
              onContentVisible={onLazyLoad}
              offset={-100}
              className="register"
            >
              <div />
            </LazyLoad>
          )}
        {querySettings.pageSize === 0 &&
          querySettings.currentPage > 1 &&
          loading && (
            <div className="app-data-table-lazy-loading">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
      </div>
    </>
  );
}

export default AppDataTable;
