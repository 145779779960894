import React from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";
import { TextField } from "office-ui-fabric-react";

import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";
import AppButton, { AppButtonType } from "../../../app/components/app-button";
import { VocCustomUiLayoutGroup } from "../../../app/types";

interface QuestionGroupHeaderProps {
  group: VocCustomUiLayoutGroup;
  allowRename: boolean;
  allowHide: boolean;
  isRenaming: boolean;
  onGroupRenameEdit: (groupKey: string) => void;
  onGroupRenameCancel: (groupKey: string) => void;
  onGroupRenameValueChange: (value: string, groupKey: string) => void;
  onGroupRenameSave: (groupKey: string) => void;
  onGroupDisplayChange: (groupKey: string, display: boolean) => void;
  onGroupLockChange: (groupKey: string, display: boolean) => void;
}

const QuestionGroupHeader: React.FC<QuestionGroupHeaderProps> = ({
  group,
  allowRename,
  allowHide,
  isRenaming,
  onGroupDisplayChange,
  onGroupLockChange,
  onGroupRenameCancel,
  onGroupRenameEdit,
  onGroupRenameValueChange,
  onGroupRenameSave,
}) => {
  const { t } = useTranslation();

  const getDisplayLockButton = (accessAllowed: boolean) => {
    if (accessAllowed) {
      return (
        <>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onGroupDisplayChange(group.key, !group.display);
            }}
            className={classNames(
              "group-display-button",
              group.display || "hide"
            )}
          />
          <button
            onClick={(e) => {
              e.stopPropagation();
              onGroupLockChange(group.key, !group.locked);
            }}
            className={classNames(
              "group-lock-button",
              group.locked && "locked"
            )}
          />
        </>
      );
    }

    return (
      <>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onGroupDisplayChange(group.key, !group.display);
          }}
          disabled={group.locked}
          className={classNames(
            "group-display-button",
            !group.display && "hide"
          )}
        />
        {group.locked && <i className="locked" />}
      </>
    );
  };

  return (
    <div>
      {isRenaming ? (
        <AccessVisible
          objectCode="customizations"
          actionType="admin"
          fallback={
            <TextField
              value={
                group.customName === undefined ? group.name : group.customName
              }
              disabled={true}
            />
          }
        >
          <TextField
            onChange={(_, value) => {
              onGroupRenameValueChange(value, group.key);
            }}
            onClick={(e) => e.stopPropagation()}
            value={
              group.customName === undefined ? group.name : group.customName
            }
          />
        </AccessVisible>
      ) : (
        group.customName || group.name
      )}

      {!isRenaming && allowHide && (
        <AccessVisible
          objectCode="customizations"
          actionType="admin"
          fallback={getDisplayLockButton(false)}
        >
          {getDisplayLockButton(true)}
        </AccessVisible>
      )}

      {/* right-side header buttons for renaming */}
      <div className="rename-wrap">
        {isRenaming ? (
          <>
            <AppButton
              appButtonType={AppButtonType.Link}
              onClick={(e) => {
                e.stopPropagation();
                onGroupRenameCancel(group.key);
              }}
            >
              {t("voc.common.cancel")}
            </AppButton>
            <AppButton
              onClick={(e) => {
                e.stopPropagation();
                onGroupRenameSave(group.key);
              }}
            >
              {t("voc.common.save")}
            </AppButton>
          </>
        ) : (
          allowRename && (
            <AppButton
              // hardcode to show rename button only for summary tab
              appButtonType={AppButtonType.Link}
              onClick={(e) => {
                e.stopPropagation();
                onGroupRenameEdit(group.key);
              }}
            >
              {t("voc.common.rename")}
            </AppButton>
          )
        )}
      </div>
    </div>
  );
};

export default QuestionGroupHeader;
