import "./AppFilterModal.scss";

import { BaseButton } from "office-ui-fabric-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AppButton, { AppButtonType } from "../app-button/AppButton";
import { ColumnDataType } from "../app-data-table";
import AppModal from "../app-modal";
import { FilterColumn, FilterOption, FilterType } from "./AppFilter";
import AppFilterFieldRow from "./AppFilterFieldRow";

interface AppFilterModalProps {
  filterOptions: FilterOption[];
  filterColumns: FilterColumn[];
  onFilterChange: (filterOptions: FilterOption[]) => void;
  showFilterModal: boolean;
  onClose: () => void;
}

const AppFilterModal: React.FC<AppFilterModalProps> = ({
  filterOptions,
  filterColumns,
  showFilterModal,
  onFilterChange,
  onClose,
}) => {
  const [formFilterOptions, setFormFilterOptions] = useState(() => {
    if (filterOptions.length) {
      return [...filterOptions].map((option) => ({ ...option }));
    } else return [{}]; // show an empty row
  });

  const [isFormValid, setIsFormValid] = useState(true);

  const findColumn = (fieldName: string): FilterColumn => {
    return filterColumns.find((column) => column.fieldName === fieldName);
  };

  const fieldDropdownOptions = [
    ...filterColumns.map((column) => ({
      key: column.fieldName,
      text: column.name,
    })),
  ];

  const handleClearFilter = () => {
    onClose();
    onFilterChange([]);
  };

  const handleSaveFilter = () => {
    let isValid = true;
    if (
      formFilterOptions.length === 1 &&
      !formFilterOptions[0].fieldName &&
      !formFilterOptions[0].filterType &&
      !formFilterOptions[0].filterValue?.trim()
    ) {
      isValid = true; // pass validation when only one blank row
    } else if (
      formFilterOptions.find(
        (option) =>
          !option.fieldName ||
          !option.filterType ||
          (!option.filterValue?.trim() &&
            option.filterType !== FilterType.IsEmpty)
      )
    ) {
      isValid = false;
    }

    if (isValid) {
      const options = formFilterOptions.filter(
        (option) =>
          option.fieldName && option.columnDataType && option.filterType
      );
      onFilterChange(options);
      onClose();
      return;
    }

    setIsFormValid(isValid);
  };

  const handleAddFilter = () => {
    setFormFilterOptions((prevState) => [...prevState, {}]);
    setIsFormValid(true);
  };

  const handleFormFilterChange = (
    originalFormFilterOption: FilterOption,
    updatedFormFilterOption?: FilterOption
  ) => {
    if (!updatedFormFilterOption) {
      // delete
      setFormFilterOptions((prev) =>
        prev.filter((item) => item !== originalFormFilterOption)
      );
      return;
    }

    if (!Object.keys(updatedFormFilterOption).length) {
      // clear
      setFormFilterOptions((prevState) => {
        prevState[prevState.indexOf(originalFormFilterOption)] =
          updatedFormFilterOption;
        return [...prevState];
      });
      return;
    }

    if (
      originalFormFilterOption.fieldName !== updatedFormFilterOption.fieldName
    ) {
      // update filter field
      if (
        originalFormFilterOption.columnDataType === ColumnDataType.Boolean ||
        originalFormFilterOption.columnDataType === ColumnDataType.Date ||
        originalFormFilterOption.columnDataType === ColumnDataType.Select
      ) {
        updatedFormFilterOption.filterValue = undefined;
      }

      if (updatedFormFilterOption.fieldName) {
        const column = findColumn(updatedFormFilterOption.fieldName);
        if (
          column.columnDataType === ColumnDataType.Boolean ||
          column.columnDataType === ColumnDataType.Date ||
          column.columnDataType === ColumnDataType.Select
        ) {
          updatedFormFilterOption.filterValue = undefined;
        }
        updatedFormFilterOption.columnDropdownOptions =
          column.columnDropdownOptions;
        updatedFormFilterOption.columnDataType = column.columnDataType;
      } else {
        updatedFormFilterOption.columnDropdownOptions = [];
        updatedFormFilterOption.columnDataType = undefined;
      }

      if (
        originalFormFilterOption.columnDataType !==
        updatedFormFilterOption.columnDataType
      ) {
        updatedFormFilterOption.filterType = undefined;
      }
    }

    setFormFilterOptions((prevState) => {
      prevState[prevState.indexOf(originalFormFilterOption)] =
        updatedFormFilterOption;
      return [...prevState];
    });
  };

  const { t } = useTranslation();

  return (
    <AppModal
      mandatoryHintReplacement={null}
      title={t("voc.filter.filter")}
      onClose={onClose}
      isOpen={showFilterModal}
      footer={
        <>
          <AppButton
            className="app-filter-form-clear-all-button"
            onClick={handleClearFilter}
            appButtonType={AppButtonType.Basic}
          >
            {t("voc.common.clearFilters").toLocaleUpperCase()}
          </AppButton>
          <AppButton
            className="app-filter-form-save-button"
            onClick={handleSaveFilter}
          >
            {t("voc.common.apply").toLocaleUpperCase()}
          </AppButton>
        </>
      }
    >
      <div className="app-filter-form">
        {formFilterOptions.map((formFilterOption, idx) => (
          // use index for key as form filter option has unique identifier
          <AppFilterFieldRow
            key={idx}
            rowIndex={idx}
            isFormValid={isFormValid}
            formFilterOption={formFilterOption}
            showClearButton={formFilterOptions.length === 1}
            fieldDropdownOptions={fieldDropdownOptions}
            onFormFilterChange={handleFormFilterChange}
          />
        ))}
        <BaseButton
          iconProps={{ iconName: "CalculatorAddition" }}
          className="add-button"
          onClick={handleAddFilter}
        >
          {t("voc.common.addFilter")}
        </BaseButton>
        {!isFormValid && (
          <p className="error-message">{t("voc.common.emptyFields")}</p>
        )}
      </div>
    </AppModal>
  );
};

export default AppFilterModal;
