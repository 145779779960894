import React from "react";
import { useTranslation } from "react-i18next";

import AppCollapsible from "../../../app/components/app-collapsible/AppCollapsible";
import AppDataListV2 from "../../../app/components/app-data-list/AppDataListV2";
import { DataListItem } from "../../../app/components/app-data-list/types";
import { DeletedRecord, VocLibraryObject } from "../../../app/types";
import { useLibraryHandler } from "../../hooks/useLibraryHandler";

interface DescriptionProps {
  libraryType:
    | "VOC_SOURCES"
    | "VOC_SOURCE_TYPE"
    | "VOC_CONSTR_STATUS"
    | "VOC_DELAY_EFFECTS"
    | "VOC_REC_STATUS"
    | "VOC_CLIENT_WITH_STATUS"
    | "VOC_SUPPORT_DOC_STATUS"
    | "VOC_EVENT_STATUS";
  refetchCount: number;
}

export const Description: React.FC<DescriptionProps> = ({
  libraryType,
  refetchCount,
}) => {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = React.useState(false);

  const convertDescription = (items: VocLibraryObject[]) => {
    const converted = items.map((item) => ({
      id: item.id,
      value: item.value,
      edited: false,
      sortOrder: item.sortOrder,
      fieldDataArray: [{ fieldData: item.label }],
    }));
    return converted;
  };

  const {
    // Variables
    fetchLibraryAndUsage,
    isLibraryAndUsageLoaded,
    libraryItem,
    displayLibraryItem,
    setDisplayLibraryItem,
    // Library Custom Sort
    isCustomSort,
    saveCustomOrder,
    resetCustomOrder,
    // Update Handler
    handleUpdateLibraries,
  } = useLibraryHandler(libraryType, convertDescription, refetchCount);

  // ================================================
  //                 panel & colDef
  // ================================================

  const panelTitle = React.useMemo(
    () =>
      libraryType === "VOC_SOURCES"
        ? t("voc.library.titles.eventSource")
        : libraryType === "VOC_SOURCE_TYPE"
        ? t("voc.library.titles.eventSourceType")
        : libraryType === "VOC_CONSTR_STATUS"
        ? t("voc.library.titles.constructionStatus")
        : libraryType === "VOC_DELAY_EFFECTS"
        ? t("voc.library.titles.delayingAndDisruptive")
        : libraryType === "VOC_REC_STATUS"
        ? t("voc.library.titles.recordStatus")
        : libraryType === "VOC_CLIENT_WITH_STATUS"
        ? t("voc.library.titles.statusWithClient")
        : libraryType === "VOC_SUPPORT_DOC_STATUS"
        ? t("voc.library.titles.supportDocumentStatus")
        : libraryType === "VOC_EVENT_STATUS"
        ? t("voc.library.titles.siteEventStatus")
        : "",
    [libraryType, t]
  );

  const column = React.useMemo(
    () => [
      {
        columnName: t("voc.eventPage.values.description"),
        columnWidthClassName: "remainder",
        columnDataType: "string",
        editable: true,
      },
    ],
    [t]
  );

  // ================================================
  //                Action Handler
  // ================================================

  const handleSave = () => {
    const scMemo = libraryItem;
    const deconvertedSCs: Partial<VocLibraryObject>[] = [];
    const deconversionTarget: DataListItem[] = displayLibraryItem;
    deconversionTarget.forEach((sc: DataListItem) => {
      if (sc.edited) {
        deconvertedSCs.push({
          id: sc.id,
          value: sc.value,
          label: sc.fieldDataArray[0].fieldData,
        });
      }
    });
    const deletedRecords: DeletedRecord[] = [];
    scMemo.forEach((memo: any) => {
      let deleted = true;
      deconversionTarget.forEach((sc) => {
        if (memo.id === sc.id) {
          deleted = false;
        }
      });
      if (deleted) {
        deletedRecords.push({
          id: memo.id,
          label: memo.label,
          value: memo.value,
        });
      }
    });
    //BACKEND REQUIRES CERTAIN FIELDS, EVEN WHEN DELETING, WHEN IT SHOULD NOT BE NECESSARY
    const deletedRecordObjects: DeletedRecord[] = deletedRecords.map((rec) => ({
      id: rec.id,
      value: rec.value,
      label: rec.label,
      isDeleted: true,
    }));
    const combinedRecords = [...deconvertedSCs, ...deletedRecordObjects];
    handleUpdateLibraries(
      {
        items: combinedRecords,
      },
      () => setIsEditing(false)
    );
  };

  const handleCancel = () => {
    // setDisplayLibraryItem(libraryItem);
    fetchLibraryAndUsage();
  };

  return (
    <AppCollapsible
      permissionCode="libraries"
      hasFields={false}
      editCallback={() => {
        setIsEditing(true);
      }}
      editingState={isEditing}
      saveCallback={handleSave}
      cancelCallback={() => {
        handleCancel();
        setIsEditing(false);
      }}
      firstDecollapseCallback={() => {
        fetchLibraryAndUsage();
      }}
      isLoading={!isLibraryAndUsageLoaded}
      isLoadOnEveryClick={true}
      headerChildren={<div className="doctype-title">{panelTitle}</div>}
      bodyChildren={
        isLibraryAndUsageLoaded ? (
          <AppDataListV2
            permissionCode="libraries"
            editing={isEditing}
            canDeleteLastItem={true}
            columns={column}
            oriItems={libraryItem}
            items={displayLibraryItem}
            editSetStateHook={setDisplayLibraryItem}
            setFiles={null}
            defaultSortDirection=""
            saveCustomOrderCallback={saveCustomOrder}
            resetCustomOrderCallback={resetCustomOrder}
            saveCustomOrderCode={libraryType}
            initCustomSortState={isCustomSort}
          />
        ) : null
      }
    ></AppCollapsible>
  );
};

export default Description;
