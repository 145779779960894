import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import {
  notify,
  NxpInputNumber,
  NxpInputPercent,
} from "@nexploretechnology/nxp-ui";

const useStyles = createUseStyles((theme) => ({
  DemoNumberInput: {},
}));

interface DemoNumberInputProps {}

const DemoNumberInput: React.FC<DemoNumberInputProps> = () => {
  const classes = useStyles();
  const [value, setValue] = useState<number>();
  return (
    <div className={classes.DemoNumberInput}>
      <NxpInputNumber
        decimalPlace={3}
        value={value}
        onChange={(n) => {
          notify.info(n?.toString() || "--");
          setValue(n);
        }}
      />
      <br />
      <br />
      <p>For percent</p>
      <NxpInputPercent
        decimalPlace={1}
        value={value}
        onChange={(n) => {
          notify.info(n?.toString() || "--");
          setValue(n);
        }}
      />
    </div>
  );
};

export default DemoNumberInput;
