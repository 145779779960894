import React from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import "./CreateFeedbackHeaderButton.scss";

interface CreateFeedbackHeaderButtonProps {
  setShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateFeedbackHeaderButton: React.FC<CreateFeedbackHeaderButtonProps> = ({
  setShowCreateModal,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <NxpButton
        onClick={() => {
          setShowCreateModal(true);
        }}
        type="primary"
        className="feedback-btn"
      >
        {t("voc.sidebar.feedback")}
      </NxpButton>
    </>
  );
};

export default CreateFeedbackHeaderButton;
