import React from "react";
import { useTranslation } from "react-i18next";

import AppCard from "../../../../app/components/app-card/AppCard";
import AppDataList from "../../../../app/components/app-data-list/AppDataList";
import { DataListItem } from "../../../../app/components/app-data-list/types";
import useAppContext from "../../../../app/hooks/useAppContext";
import { AsyncResult } from "../../../../app/hooks/useAsync";
import { VocSendTaskReminderResponse, VocTask } from "../../../../app/types";
import { sendTaskReminder } from "../../../compensation-event-services";

export interface TasksLayoutProps {
  entityId: string;
  isEditingTasks: boolean;
  setIsEditingTasks: React.Dispatch<React.SetStateAction<boolean>>;
  tasks: DataListItem[];
  todoTasks: DataListItem[];
  setTasks: React.Dispatch<React.SetStateAction<DataListItem[]>>;
  addTask: Function;
  cancel: Function;
  saveTasks: Function;
  libTaskStatuses: any[];
  taskReminder: AsyncResult<VocSendTaskReminderResponse>;
  setSendTaskReminder: (
    asyncRequest: () => Promise<VocSendTaskReminderResponse>
  ) => void;
}

const TasksLayout: React.FC<TasksLayoutProps> = (props) => {
  const {
    entityId,
    isEditingTasks,
    setIsEditingTasks,
    tasks,
    todoTasks,
    setTasks,
    addTask,
    cancel,
    saveTasks,
    libTaskStatuses,
    taskReminder,
    setSendTaskReminder,
  } = props;
  const [taskFilter, setTaskFilter] = React.useState<boolean>(false);
  const { serviceConfig } = useAppContext();
  const { t } = useTranslation();

  const handleSendTaskReminder = (taskId: VocTask["id"]) => {
    setSendTaskReminder(() =>
      sendTaskReminder(taskId as string, serviceConfig)
    );
  };
  return (
    <AppCard
      title={t("voc.eventPage.tasks.tasks")}
      canEdit={true}
      isEditing={isEditingTasks}
      editCallback={() => {
        setIsEditingTasks(true);
      }}
      saveCallback={() => {
        saveTasks();
      }}
      cancelCallback={() => {
        cancel();
      }}
      addCallback={() => {
        addTask();
      }}
      addCallbackShortcut={true}
      taskFilter={true}
      taskFilterState={taskFilter}
      setTaskFilterState={setTaskFilter}
    >
      <AppDataList
        entityId={entityId}
        columns={[
          {
            columnName: t("voc.eventPage.tasks.tasksDetails"),
            columnWidthClassName: "eighteenpercent",
            columnDataType: "string",
            editable: true,
          },
          {
            columnName: t("voc.eventPage.tasks.assignedTo"),
            columnWidthClassName: "fifteenpercent",
            columnDataType: "owner",
            itemLimit: 1,
            editable: true,
          },
          {
            columnName: t("voc.eventPage.tasks.deadline"),
            columnWidthClassName: "thirteenpercent",
            columnDataType: "date",
            editable: true,
          },
          {
            columnName: t("voc.eventPage.tasks.assignedBy"),
            columnWidthClassName: "fifteenpercent",
            columnDataType: "string",
            editable: false,
          },
          {
            columnName: t("voc.eventPage.tasks.assignedDate"),
            columnWidthClassName: "thirteenpercent",
            columnDataType: "date",
            editable: false,
          },
          {
            columnName: t("voc.eventPage.tasks.status"),
            columnWidthClassName: "fifteenpercent",
            columnDataType: "basicdropdown",
            columnOptions: libTaskStatuses,
            editable: true,
          },
          {
            columnName: t("voc.eventPage.tasks.completedDate"),
            columnWidthClassName: "thirteenpercent",
            columnDataType: "date",
            editable: false,
            loading: taskReminder?.loading,
            noValueButtonCallback: (item: VocTask) => {
              handleSendTaskReminder(item.id);
            },
          },
        ]}
        items={taskFilter ? todoTasks : tasks}
        editing={isEditingTasks}
        editSetStateHook={setTasks}
        setFiles={null}
        canDeleteLastItem={true}
      />
    </AppCard>
  );
};

export default TasksLayout;
