import React from "react";
import { createUseStyles } from "react-jss";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModalFooter,
  NxpUpload,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";
import { Col, Row, Tooltip } from "antd";
import { UploadFile } from "antd/lib/upload/interface";

import { useTranslation } from "react-i18next";
import { CPCSUser, userSalutationValues } from "../../../../services/app";
import { PresignResult } from "../../../../services/file";
import AppUserProfile from "../../AppUserProfile";

const useStyles = createUseStyles((theme) => ({
  appAccountSettingsGeneralLayout: {
    marginTop: theme.spacing(1),
  },
  profile: {
    marginLeft: theme.spacing(1),
    "& .userImage": { margin: theme.spacing(0, 3.5) },
    "& .userInfo": { marginLeft: theme.spacing(6) },
  },
  upload: {
    "& .ant-upload": {
      display: "block",
    },
    "& button": {
      marginLeft: 0,
      width: "100%",
    },
  },
  recommendedSize: {
    fontSize: theme.fontSize.small,
    margin: 0,
  },
}));

interface AppAccountSettingsGeneralLayoutProps {
  editForm: CPCSUser;
  validationError: ValidationResult<CPCSUser>;
  saveInProgress: boolean;
  onFormGridStateChange: (fieldKey: keyof CPCSUser, value: unknown) => void;
  onSave: () => void;
  onImageUpload: (file: File) => Promise<PresignResult>;
  onImageUploadDone: (file: PresignResult) => void;
  onImageUploadClear: () => void;
  onModalClose: () => void;
}

const AppAccountSettingsGeneralLayout: React.FC<
  AppAccountSettingsGeneralLayoutProps
> = ({
  editForm,
  validationError,
  onFormGridStateChange,
  saveInProgress,
  onSave,
  onImageUpload,
  onImageUploadDone,
  onImageUploadClear,
  onModalClose,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const formItems: NxpFormGridItemProps<CPCSUser>[] = [
    {
      controlType: "custom",
      label: "",
      itemFieldName: "id",
      span: 8,
      customContent: (
        <Tooltip
          overlay={
            <p className={classes.recommendedSize}>
              Recommended Size: 150x150 pixels
            </p>
          }
        >
          <NxpUpload
            maxCount={1}
            className={classes.upload}
            onUpload={async (file) => {
              const uploadResult = await onImageUpload(file);
              onImageUploadDone(uploadResult);
              return uploadResult as unknown as UploadFile;
            }}
            onChange={(e) => {
              if (!e.fileList.length) {
                onImageUploadClear();
              }
            }}
          />
        </Tooltip>
      ),
    },
    {
      controlType: "select",
      controlProps: {
        allowClear: true,
        options: [
          ...userSalutationValues.map((salutation) => ({
            label: salutation,
            value: salutation,
          })),
        ],
        onChange: (val) =>
          onFormGridStateChange("salutation", val ? val : null),
      },
      startOnNewRow: true,
      label: t("voc.accountSettings.salutation"),
      itemFieldName: "salutation",
      span: 8,
    },
    {
      controlType: "input",
      required: true,
      label: t("voc.accountSettings.firstName"),
      itemFieldName: "firstName",
      span: 8,
    },
    {
      controlType: "input",
      required: true,
      label: t("voc.accountSettings.lastName"),
      itemFieldName: "lastName",
      span: 8,
    },
    {
      controlType: "input",
      required: true,
      label: t("voc.accountSettings.displayName"),
      itemFieldName: "displayName",
      span: 8,
    },
    {
      controlType: "input",
      label: t("voc.accountSettings.jobTitle"),
      itemFieldName: "title",
      span: 8,
    },
    {
      controlType: "input",
      startOnNewRow: true,
      required: true,
      label: t("voc.accountSettings.email"),
      itemFieldName: "primaryEmail",
      span: 8,
    },
    {
      controlType: "input",
      label: t("voc.accountSettings.mobile"),
      itemFieldName: "mobileNumber",
      span: 8,
    },
    {
      controlType: "input",
      label: t("voc.accountSettings.telephone"),
      itemFieldName: "officeNumber",
      span: 8,
    },
  ];

  return (
    <>
      <Row gutter={16} className={classes.appAccountSettingsGeneralLayout}>
        <Col span={24}>
          <AppUserProfile user={editForm} className={classes.profile} />
        </Col>
        <Col span={24}>
          <NxpFormGrid
            formState={editForm}
            validationError={validationError}
            formItems={formItems}
            onFormStateChange={onFormGridStateChange}
          />
        </Col>
      </Row>
      <NxpModalFooter>
        <>
          <NxpButton
            type="default"
            onClick={onModalClose}
            disabled={saveInProgress}
          >
            {t("voc.common.cancel")}
          </NxpButton>
          <NxpButton onClick={onSave} loading={saveInProgress}>
            {t("voc.common.save")}
          </NxpButton>
        </>
      </NxpModalFooter>
    </>
  );
};

export default AppAccountSettingsGeneralLayout;
