import React from "react";

import classNames from "classnames";

import { mapDateToString, stringToDateFormat } from "../../utils/mappers";
import {
  formatBoolean,
  formatMoneyValue,
  formatPercentValue,
} from "../../utils/string";
import { DataTableColumn } from "./";
import { ColumnDataType } from "./AppDataTable";

import "./AppDataTableRow.scss";

interface AppDataTableRowProps<T> {
  columns: DataTableColumn<T>[];
  dataItem: T;
  fixFirstColumn: boolean;
  onRowClick?: (e: React.MouseEvent<HTMLDivElement>, dataItem: T) => void;
}

export const getDataTableDefaultCellContent = (
  columnDataType: ColumnDataType,
  content: any
) => {
  switch (columnDataType) {
    case ColumnDataType.Date:
      return mapDateToString(stringToDateFormat(content));
    case ColumnDataType.Boolean:
      return formatBoolean(content);
    case ColumnDataType.Percent:
      return formatPercentValue(content);
    case ColumnDataType.Money:
      return formatMoneyValue(content);
    default:
      if (typeof content === "object" && content !== null) {
        return content.toString();
      }
      return content;
  }
};

function AppDataTableRow<T>(props: AppDataTableRowProps<T>) {
  const { columns, dataItem, fixFirstColumn, onRowClick } = props;

  return (
    <div
      role="row"
      className={classNames("app-data-table-row", onRowClick && "clickable")}
      onClick={
        onRowClick === undefined ? undefined : (e) => onRowClick(e, dataItem)
      }
    >
      {columns.map((col, idx) => (
        <div
          key={col.key}
          role="gridcell"
          className={classNames(
            col.cellClassName,
            fixFirstColumn && idx === 0 && "fixed-first-col"
          )}
        >
          <div className="cell-content">
            {col.onRender
              ? col.onRender(dataItem)
              : getDataTableDefaultCellContent(
                  col.columnDataType,
                  dataItem[col.fieldName]
                )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default AppDataTableRow;
