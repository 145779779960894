import { format } from "date-fns";

import moment from "moment";
import { ColumnDataType } from "../components/app-data-table";
import i18n from "../i18n/i18n";
import { VocCustomUiFieldDataType } from "../types";
import { UserLanguagePreference } from "./localisation/locale-enum";

export const stringToDateFormat = (dataSource: Date | string) => {
  return dataSource
    ? dataSource instanceof Date
      ? dataSource
      : moment(dataSource).toDate()
    : null;
};
export function mapDateToString(date: Date | string): string {
  const pattern =
    i18n.language === UserLanguagePreference.US ? "LLL-d-y" : "d-LLL-y";
  return (
    date && (date instanceof Date ? format(date, pattern).toUpperCase() : date)
  );
}

export function mapDateTimeToString(date: Date): string {
  const pattern =
    i18n.language === UserLanguagePreference.US
      ? "LLL-d-y kk:mm:ss"
      : "d-LLL-y kk:mm:ss";
  return date && format(date, pattern).toUpperCase();
}

export function mapDateWithWeekdaysToString(date: Date): string {
  if (!date || !(date instanceof Date)) {
    return "";
  }
  const pattern =
    i18n.language === UserLanguagePreference.US ? "E LLL-d-y" : "E d-LLL-y";
  return format(date, pattern).toUpperCase();
}

export function mapDateToShortString(date: Date): string {
  const pattern =
    i18n.language === UserLanguagePreference.US ? "LLL-d" : "d-LLL";
  return date && format(date, pattern).toUpperCase();
}

export function mapDateToMonthString(date: Date): string {
  return date && format(date, "LLL y").toUpperCase();
}

export function mapCustomizationColumnDataType(
  customFieldDataType: VocCustomUiFieldDataType
): ColumnDataType {
  switch (customFieldDataType) {
    case VocCustomUiFieldDataType.Date:
      return ColumnDataType.Date;
    case VocCustomUiFieldDataType.Dropdown:
    case VocCustomUiFieldDataType.MultiSelect:
    case VocCustomUiFieldDataType.ReadOnly:
    case VocCustomUiFieldDataType.Text:
      return ColumnDataType.String;
    case VocCustomUiFieldDataType.YesNo:
      return ColumnDataType.Boolean;
    case VocCustomUiFieldDataType.Percentage:
      return ColumnDataType.Percent;
    case VocCustomUiFieldDataType.Value:
      return ColumnDataType.Number;
  }
  return ColumnDataType.String;
}

export const getUserDisplayDateMonthDayFormat = () => {
  if (i18n.language === UserLanguagePreference.US) {
    return "MMM-DD";
  } else {
    return "DD-MMM";
  }
};

export const getUserDisplayDateFormat = () => {
  return `${getUserDisplayDateMonthDayFormat()}-YYYY`;
};

export const getUserDisplayDateTimeFormat = () => {
  return `${getUserDisplayDateMonthDayFormat()}-YYYY HH:mm:ss`;
};

export const getUserDisplayDateTimeWithAmPmFormat = () => {
  return `${getUserDisplayDateMonthDayFormat()}-YYYY hh:mm a`;
};
