import { NxpFormGrid, NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";
import React from "react";
import {
  computeInternalProgressValue,
  computePhysicalProgress,
} from "../helpers/actualProgressCalculation";
import { createUseStyles } from "react-jss";
import { getFormattedInputNumberControlProps } from "../../../../app/utils/number/getFormattedInputNumberControlProps";
import { useTranslation } from "react-i18next";
import i18n from "../../../../app/i18n/i18n";

interface ActualProgressPanelProps {
  editing: boolean;
  fieldNames: Record<string, string>;
  actualValues: {
    physicalProgress: number;
    internalProgressValue: number;
  };
  onSetActualValues: React.Dispatch<
    React.SetStateAction<{
      physicalProgress: number;
      internalProgressValue: number;
    }>
  >;
  internalTarget: number;
}

const useStyles = createUseStyles((theme) => ({
  title: {
    color: theme.palette.primary,
  },
}));

const ActualProgressPanel: React.FC<ActualProgressPanelProps> = ({
  editing,
  fieldNames,
  actualValues,
  onSetActualValues,
  internalTarget, // E
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const handleFormGridStateChange = (
    fieldName: keyof {
      physicalProgress: number; // T
      internalProgressValue: number; // U
    },
    value: number
  ) => {
    onSetActualValues((prevState) => {
      const updatedState = {
        ...prevState,
        [fieldName]: value,
      };

      if (fieldName === "physicalProgress") {
        updatedState.internalProgressValue = computeInternalProgressValue(
          updatedState,
          internalTarget
        ); // U = T * E
      }

      if (fieldName === "internalProgressValue") {
        updatedState.physicalProgress = computePhysicalProgress(
          updatedState,
          internalTarget
        ); // T = U / E
      }

      return updatedState;
    });
  };

  const ActualProgressFormItems: NxpFormGridItemProps<{
    physicalProgress: number;
    internalProgressValue: number;
  }>[] = [
    {
      controlType: "inputPercent",
      label: fieldNames.physicalProgress,
      itemFieldName: "physicalProgress",
      span: 3,
      controlProps: {
        value: actualValues?.physicalProgress,
        onChange: (value) =>
          handleFormGridStateChange("physicalProgress", value),
        addonAfter: editing ? "%" : undefined,
        suffix: editing ? undefined : "%",
        decimalPlace: 2,
        precision: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      controlType: "inputNumber",
      label: fieldNames.internalProgressValue,
      itemFieldName: "internalProgressValue",
      span: 3,
      controlProps: {
        value: actualValues?.internalProgressValue,
        prefix: editing ? undefined : "$",
        addonBefore: editing ? "$" : undefined,
        precision: 2,
        decimalPlace: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
  ];

  return (
    <>
      <h4 className={classes.title}>
        {t("voc.eventPage.values.actualProgressHeader")}
      </h4>
      <NxpFormGrid
        editing={editing}
        formItems={ActualProgressFormItems}
        formState={actualValues}
        onFormStateChange={handleFormGridStateChange}
      />
    </>
  );
};

export default ActualProgressPanel;
