import React from "react";
import { createUseStyles } from "react-jss";

import { NxpSignature } from "@nexploretechnology/nxp-ui";

const useStyles = createUseStyles((theme) => ({
  DemoSignature: {},
}));

interface DemoSignatureProps {}

const DemoSignature: React.FC<DemoSignatureProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.DemoSignature}>
      <NxpSignature
        userId="1"
        userName="tester"
        onChange={(value) => console.log(value)}
      />
    </div>
  );
};

export default DemoSignature;
