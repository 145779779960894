// Copied and Customized from https://github.com/cchanxzy/react-currency-input-field

import React, {
  FC,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { AppNumericInputBaseProps } from "./AppNumericInputBaseProps";
import {
  cleanValue,
  CleanValueOptions,
  fixedDecimalValue,
  formatValue,
  getLocaleConfig,
  getSuffix,
  isNumber,
  padTrimValue,
} from "./utils";
import { escapeRegExp } from "./utils/escapeRegExp";

export const AppNumericInputBase: FC<AppNumericInputBaseProps> = forwardRef<
  HTMLInputElement,
  AppNumericInputBaseProps
>(
  (
    {
      allowDecimals = true,
      allowNegativeValue = true,
      id,
      name,
      className,
      customInput,
      // decimalsLimit,
      defaultValue,
      disabled = false,
      maxLength: userMaxLength,
      value: userValue,
      onValueChange,
      // fixedDecimalLength,
      placeholder,
      decimalScale,
      prefix,
      intlConfig,
      step,
      disableGroupSeparators = false,
      disableAbbreviations = false,
      decimalSeparator: _decimalSeparator,
      groupSeparator: _groupSeparator,
      valueUnitLabel,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      onKeyUp,
      ...props
    }: AppNumericInputBaseProps,
    ref
  ) => {
    if (
      _decimalSeparator &&
      _groupSeparator &&
      _decimalSeparator === _groupSeparator
    ) {
      throw new Error("decimalSeparator cannot be the same as groupSeparator");
    }

    if (_decimalSeparator && isNumber(_decimalSeparator)) {
      throw new Error("decimalSeparator cannot be a number");
    }

    if (_groupSeparator && isNumber(_groupSeparator)) {
      throw new Error("groupSeparator cannot be a number");
    }

    const localeConfig = useMemo(
      () => getLocaleConfig(intlConfig),
      [intlConfig]
    );
    const decimalSeparator =
      _decimalSeparator || localeConfig.decimalSeparator || "";
    const groupSeparator = _groupSeparator || localeConfig.groupSeparator || "";

    const formatValueOptions = {
      decimalSeparator,
      groupSeparator,
      disableGroupSeparators,
      intlConfig,
      prefix,
    };

    const cleanValueOptions: Partial<CleanValueOptions> = {
      decimalSeparator,
      groupSeparator,
      allowDecimals,
      // decimalsLimit: decimalsLimit || fixedDecimalLength || decimalScale || 2,
      decimalsLimit: decimalScale || 2,
      allowNegativeValue,
      disableAbbreviations,
      prefix,
    };

    const formattedStateValue =
      defaultValue !== undefined
        ? formatValue({
            ...formatValueOptions,
            decimalScale,
            value: defaultValue,
          })
        : userValue !== undefined
        ? formatValue({
            ...formatValueOptions,
            decimalScale,
            value: userValue,
          })
        : "";

    const [stateValue, setStateValue] = useState(
      formattedStateValue +
        (formattedStateValue && valueUnitLabel ? valueUnitLabel : "")
    );
    const [dirty, setDirty] = useState(false);
    const [cursor, setCursor] = useState(0);
    const refLocal = useRef<HTMLInputElement>(null);
    const inputRef = ref === undefined ? refLocal : ref;

    const processChange = (
      value: string,
      selectionStart?: number | null
    ): void => {
      setDirty(true);
      const valueOnly = cleanValue({ value, ...cleanValueOptions });

      if (valueOnly === "") {
        onValueChange && onValueChange(undefined, name);
        setStateValue("");
        return;
      }

      if (userMaxLength && valueOnly.replace(/-/g, "").length > userMaxLength) {
        return;
      }

      if (valueOnly === "-") {
        onValueChange && onValueChange(undefined, name);
        setStateValue(value);
        return;
      }

      const formattedValue = formatValue({
        value: valueOnly,
        ...formatValueOptions,
      });

      /* istanbul ignore next */
      if (selectionStart !== undefined && selectionStart !== null) {
        // Prevent cursor jumping
        const cursor =
          selectionStart + (formattedValue.length - value.length) || 1;
        setCursor(cursor);
      }

      setStateValue(formattedValue);

      onValueChange && onValueChange(valueOnly, name);
    };

    const handleOnChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      const {
        target: { value, selectionStart },
      } = event;

      processChange(value, selectionStart);

      onChange && onChange(event);
    };

    const handleOnFocus = (
      event: React.FocusEvent<HTMLInputElement>
    ): number => {
      onFocus && onFocus(event);
      if (valueUnitLabel) {
        if (
          Number(userValue) !==
          Number(cleanValue({ value: stateValue, ...cleanValueOptions }))
        ) {
          setStateValue(() =>
            formatValue({
              ...formatValueOptions,
              value: userValue,
            })
          );
        } else {
          setStateValue((prevState) => {
            return prevState.replace(
              new RegExp(`${escapeRegExp(valueUnitLabel)}$`),
              ""
            );
          });
        }
      }
      return stateValue ? stateValue.length : 0;
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
      const {
        target: { value },
      } = event;

      const valueOnly = cleanValue({ value, ...cleanValueOptions });

      if (valueOnly === "-" || !valueOnly) {
        setStateValue("");
        onBlur && onBlur(event);
        return;
      }

      const fixedDecimals = fixedDecimalValue(
        valueOnly,
        decimalSeparator,
        0 // fixedDecimalLength
      );

      // Add padding or trim value to decimalScale
      const newValue = padTrimValue(
        fixedDecimals,
        decimalSeparator,
        decimalScale // !== undefined ? decimalScale : fixedDecimalLength
      );

      onValueChange && onValueChange(newValue, name);

      const formattedValue = formatValue({
        ...formatValueOptions,
        value: newValue,
      });

      setStateValue(
        valueUnitLabel ? formattedValue + valueUnitLabel : formattedValue
      );
      setDirty(false);
      onBlur && onBlur(event);
    };

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = event;

      if (step && (key === "ArrowUp" || key === "ArrowDown")) {
        event.preventDefault();
        const currentValue =
          Number(
            userValue !== undefined
              ? userValue
              : cleanValue({ value: stateValue, ...cleanValueOptions })
          ) || 0;
        const newValue =
          key === "ArrowUp" ? currentValue + step : currentValue - step;
        processChange(String(newValue));
      }

      onKeyDown && onKeyDown(event);
    };

    const handleOnKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
      const {
        currentTarget: { selectionStart },
      } = event;
      const suffix = getSuffix(stateValue, {
        groupSeparator,
        decimalSeparator,
      });

      if (
        suffix &&
        selectionStart &&
        selectionStart > stateValue.length - suffix.length
      ) {
        if (inputRef && typeof inputRef === "object" && inputRef.current) {
          const newCursor = stateValue.length - suffix.length;
          inputRef.current.setSelectionRange(newCursor, newCursor);
        }
      }

      onKeyUp && onKeyUp(event);
    };

    /* istanbul ignore next */
    useEffect(() => {
      if (inputRef && typeof inputRef === "object" && inputRef.current) {
        inputRef.current.setSelectionRange(cursor, cursor);
      }
    }, [cursor, inputRef]);
    const formattedPropsValue =
      userValue !== undefined
        ? Number(userValue) ===
          Number(cleanValue({ value: stateValue, ...cleanValueOptions }))
          ? stateValue
          : formatValue({
              ...formatValueOptions,
              decimalScale: dirty ? undefined : decimalScale,
              value: String(userValue),
            }) + (valueUnitLabel && !dirty ? valueUnitLabel : "")
        : undefined;

    // console.log(
    //   'fffffffff' +
    //     formattedPropsValue +
    //     '---' +
    //     Number(userValue) +
    //     '---' +
    //     Number(cleanValue({ value: stateValue, ...cleanValueOptions })) +
    //     '---' +
    //     String(
    //       Number(userValue) ===
    //         Number(cleanValue({ value: stateValue, ...cleanValueOptions }))
    //     ) +
    //     '---' +
    //     stateValue
    // );

    const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
      type: "text",
      inputMode: "decimal",
      id,
      name,
      className,
      onChange: handleOnChange,
      onBlur: handleOnBlur,
      onFocus: handleOnFocus,
      onKeyDown: handleOnKeyDown,
      onKeyUp: handleOnKeyUp,
      placeholder,
      disabled,
      value:
        formattedPropsValue !== undefined && stateValue !== "-"
          ? formattedPropsValue
          : stateValue,
      ref: inputRef,
      ...props,
    };

    if (customInput) {
      const CustomInput = customInput;
      return <CustomInput {...inputProps} />;
    }

    return <input {...inputProps} />;
  }
);

AppNumericInputBase.displayName = "AppNumericInputBase";

export default AppNumericInputBase;
