import { NxpButton } from "@nexploretechnology/nxp-ui";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import EditRoleModal from "./EditRoleModal";
import { ManageRolesTableItem } from "../../../manage-role-services";
import { createUseStyles } from "react-jss";

interface EditRoleButtonProps {
  onSetRows: Dispatch<SetStateAction<ManageRolesTableItem[]>>;
  onSetInitRows: Dispatch<SetStateAction<ManageRolesTableItem[]>>;
  row: ManageRolesTableItem;
}

const useStyles = createUseStyles({
  updateButton: {
    width: "100%",
    textAlign: "left",
  },
});

const EditRoleButton: React.FC<EditRoleButtonProps> = ({
  row,
  onSetRows,
  onSetInitRows,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpen = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <NxpButton
        className={classes.updateButton}
        type="link"
        onClick={handleOpen}
      >
        {t("voc.common.edit")}
      </NxpButton>
      {isModalVisible && (
        <EditRoleModal
          row={row}
          onClose={handleClose}
          onSetRows={onSetRows}
          onSetInitRows={onSetInitRows}
        />
      )}
    </>
  );
};

export default EditRoleButton;
