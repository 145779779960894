import React, { useCallback, useEffect, useState } from "react";

import {
  notify,
  NxpButton,
  NxpDateDisplay,
  NxpFormTableColumnProps,
  NxpFullFormTable,
  NxpFullFormTableItem,
  sorterForNumber,
  sorterForString,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import { DemoItem, getDemoFormList } from "../../../demo-services";

interface FormStateItem extends DemoItem, NxpFullFormTableItem {}

const columns: NxpFormTableColumnProps<FormStateItem>[] = [
  {
    title: "ID",
    dataIndex: "key",
    width: 100,
    fixed: "left",
    sorter: (a, b) => sorterForNumber(a.key, b.key),
    render: (val) => val || "New",
  },
  {
    title: "name",
    dataIndex: "name",
    width: 150,
    sorter: (a, b) => sorterForString(a.name, b.name),
    formItemProps: {
      controlType: "input",
    },
  },
  {
    title: "age",
    dataIndex: "age",
    width: 150,
    sorter: (a, b) => sorterForNumber(a.age, b.age),
    formItemProps: {
      controlType: "inputNumber",
      controlProps: {
        decimalPlace: 0,
      },
    },
  },
  {
    title: "Updated On",
    dataIndex: "updatedOn",
    width: 300,
    render: (val) => <NxpDateDisplay date={val} />,
  },
];

const yupCheckDeleted = (yupContext: yup.TestContext) => {
  const itemState = yup.ref("itemState");
  if (
    (
      yupContext.resolve(
        itemState
      ) as unknown as NxpFullFormTableItem["itemState"]
    ).deleted
  ) {
    return true;
  }
  return false;
};

const formSchema = yup.array(
  yup.object().shape({
    name: yup.string().test("name", "Name required.", function (val) {
      return yupCheckDeleted(this) || !!val?.trim();
    }),
    age: yup.number().test("age", "Age required.", function (val) {
      return yupCheckDeleted(this) || !!val;
    }),
  })
);

interface TableFullFormProps {}

const TableFullForm: React.FC<TableFullFormProps> = () => {
  const [formState, setFormState] = useState<FormStateItem[]>([]);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const initFormState = useCallback(async () => {
    const items = (await getDemoFormList()).filter((_, idx) => idx < 5);
    setFormState(
      items.map((item) => ({
        ...item,
        itemState: {
          new: false,
          modified: false,
          deleted: false,
        },
        itemUuid: uuidv4(),
      }))
    );
  }, []);

  useEffect(() => {
    initFormState();
  }, [initFormState]);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);
    // save data................
    notify.actionCompleted();
    setTimeout(() => setSaveInProgress(false));
  };

  // useValidate<> cannot handle array, so just add any
  const [validationError, , clearError, saveWithValidate] = useYupValidate<any>(
    formState,
    formSchema,
    handleSaveValidated
  );

  const handleSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  const handleReset = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      initFormState();
      clearError();
    },
    [clearError, initFormState]
  );

  const handleRowAdd = useCallback(() => {
    setFormState((prevState) => [
      ...prevState,
      {
        name: "",
        itemState: {
          new: true,
          modified: false,
          deleted: false,
        },
        itemUuid: uuidv4(),
      } as FormStateItem,
    ]);
  }, []);

  const handleRowToggleDelete = useCallback(
    (itemUuid: string) => {
      const deleteItem = formState.find((item) => item.itemUuid === itemUuid);
      if (deleteItem) {
        if (deleteItem.itemState.new) {
          setFormState((prevState) =>
            prevState.filter((item) => item.itemUuid !== itemUuid)
          );
          // clear validation if deleted item has any
          if (
            validationError[
              formState?.findIndex((item) => item.itemUuid === itemUuid)!
            ]
          ) {
            clearError();
          }
        } else {
          setFormState((prevState) =>
            prevState.map((item) =>
              item.itemUuid === itemUuid
                ? {
                    ...item,
                    itemState: {
                      ...item.itemState,
                      deleted: !item.itemState.deleted,
                    },
                  }
                : item
            )
          );
        }
      }
    },
    [clearError, formState, validationError]
  );

  const handleFormStateChange = useCallback(
    (fieldName: keyof FormStateItem, value: unknown, itemUuid: string) => {
      setFormState((prevState) =>
        prevState.map((item) =>
          item.itemUuid === itemUuid
            ? {
                ...item,
                [fieldName]: value,
                itemState: {
                  ...item.itemState,
                  modified: true,
                },
              }
            : item
        )
      );
    },
    []
  );

  return (
    <>
      <NxpFullFormTable
        actionContent={
          <>
            <NxpButton onClick={handleReset} type="text">
              Reset
            </NxpButton>{" "}
            <NxpButton onClick={handleSave}>Save Full Form Table</NxpButton>
          </>
        }
        rowKey="itemUuid"
        formState={formState}
        columns={columns}
        validationError={validationError as any}
        hideDelete={false} // same as leaving this undefined
        rowDisableDelete={(record: FormStateItem) =>
          Number(record.key) % 2 === 0
        }
        disabled={saveInProgress}
        onFormStateChange={handleFormStateChange}
        onRowToggleDelete={handleRowToggleDelete}
        onAddClick={handleRowAdd}
      />
    </>
  );
};

export default React.memo(TableFullForm);
