import { Component, ErrorInfo, ReactNode } from "react";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { datadogLogs } from "@datadog/browser-logs";

import { NxpButton } from "@nexploretechnology/nxp-ui";
import { LogLevel } from "../../hooks/useLogger";
import AppContext, { AppContextType } from "../app-context/AppContext";

interface Props {
  title: string;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

const patchLogReference = (
  logReference: string | number | object | undefined,
  level: LogLevel,
  userId: string,
  entityId: string
) => {
  const ref =
    typeof logReference === "string" || typeof logReference === "number"
      ? { id: logReference }
      : logReference;

  return {
    ...ref,
    level,
    user: userId,
    hostname: process.env.REACT_APP_DATADOG_HOSTNAME,
    entity: entityId,
  };
};

class AppErrorBoundary extends Component<Props, State> {
  static readonly contextType = AppContext;

  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { activeEntity, activeUser } = this.context as AppContextType;
    const title = `ErrorBoundary(${this.props.title})`;
    datadogLogs.logger.error(
      `${title}: ${error?.message} ${errorInfo.componentStack}`,
      patchLogReference(title, "error", activeUser?.id, activeEntity?.id),
      error
    );
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div>
          <h3 style={{ paddingLeft: 10 }}>
            <ExclamationCircleFilled /> Something went wrong
            <br />
          </h3>
          <NxpButton onClick={() => window.location.reload()}>
            Reload Application
          </NxpButton>
        </div>
      );
    }

    return this.props.children;
  }
}

export default AppErrorBoundary;
