import React from "react";
import { useParams } from "react-router-dom";

import _ from "lodash";
import * as yup from "yup";

import useAppContext from "../../app/hooks/useAppContext";
import { useAsync } from "../../app/hooks/useAsync";
import { useValidate } from "../../app/hooks/useValidate";
import { createFeedback } from "../../app/services/app";

import CreateFeedbackModalLayout from "./CreateFeedbackModalLayout";
import {
  VocFeedback,
  VocFeedbackCreation,
  VocFeedbackType,
} from "../../app/types";

interface CreateFeedbackModalContainerProps {
  showCreateModal: boolean;
  onModalClose: () => void;
}

function getValidationSchema() {
  return yup.object().shape({
    feedbackType: yup
      .object({
        label: yup.string(),
        value: yup
          .mixed<VocFeedbackType>()
          .oneOf(Object.values(VocFeedbackType)),
      })
      .nullable()
      .required("Feedback Type required"),
    feedbackDes: yup
      .string()
      .nullable()
      .required("Feedback Description required"),
    link: yup.string().nullable(),
  });
}

const CreateFeedbackModalContainer: React.FC<
  CreateFeedbackModalContainerProps
> = ({ showCreateModal, onModalClose }) => {
  const { entityId } = useParams();
  const { errorHandler, serviceConfig } = useAppContext();
  const [formSubmit, setFormSubmit] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<VocFeedbackCreation>(() => ({
    entityId,
    feedbackType: null,
    feedbackDes: null,
    isAnonymous: false,
  }));

  const [createEventAsyncResult, setCreateEventRequest] = useAsync<VocFeedback>(
    undefined,
    {
      onSuccess: (data) => {
        if (_.get(data, "id", false)) {
          setFormSubmit(true);
          // window.location.href = vocRoute(entityId, `/Feedbacks/${data.feedbackId}`);
        }
      },
      onError(ex) {
        errorHandler(ex, "Feeback submit error");
      },
    }
  );

  const handleCreateValidated = React.useCallback(
    // (addAnother: boolean) => {
    () => {
      setCreateEventRequest(() => {
        const postObj: Partial<VocFeedbackCreation> = {
          isAnonymous: form.isAnonymous,
          inquiryType: _.get(form, "feedbackType.value"),
          description: form.feedbackDes,
          relatedFiles: form.documentFiles,
        };
        return createFeedback(postObj, serviceConfig);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  );

  const [validationError, , , saveWithValidate] = useValidate<
    Partial<VocFeedbackCreation>,
    boolean //context type, eg. addAnother
  >(form, getValidationSchema() as any, handleCreateValidated);

  const handleChange = React.useCallback(
    (changeValues: Partial<VocFeedbackCreation>) => {
      if (_.has(changeValues, "feedbackType")) {
        if (_.get(changeValues, "feedbackType.value")) {
          setForm((prevState) => ({ ...prevState, ...changeValues }));
        }
      } else {
        setForm((prevState) => ({ ...prevState, ...changeValues }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  );

  const handleSave = () => {
    saveWithValidate(false);
  };
  return (
    <>
      <CreateFeedbackModalLayout
        entityId={entityId}
        showCreateModal={showCreateModal}
        onModalClose={onModalClose}
        form={form}
        onChange={handleChange}
        onSave={handleSave}
        validationError={validationError}
        createFeedbackAsyncResult={createEventAsyncResult}
        formSubmit={formSubmit}
      />
    </>
  );
};

export default CreateFeedbackModalContainer;
