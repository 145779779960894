import { notify } from "@nexploretechnology/nxp-ui";
import { useState } from "react";
import useAppContext from "../../../../../../../app/hooks/useAppContext";
import { VocSupportDocumentEnums } from "../../../../../../../app/types";
import { createSupportDocument } from "../../../new-document-form-services";
import { GeneralSupportingFormState } from "../../../new-document-form-type";

export function useGeneralSupportingFormActions(
  onModalClose: () => void,
  onRefresh: () => void,
  documentType: VocSupportDocumentEnums | "",
  formState: GeneralSupportingFormState,
  siteEventId?: string,
  eventId?: string
) {
  const { errorHandler, serviceConfig } = useAppContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSaveValidated = async () => {
    try {
      setIsSubmitting(true);
      const idToUse = siteEventId || eventId;
      const isSiteEvent = Boolean(siteEventId);

      await createSupportDocument(
        idToUse,
        {
          documentFiles: formState.files,
          comment: formState.comment,
          details: {
            documentType,
            fromDate: formState.fromDate,
            toDate: formState.toDate,
            subject: formState.subject,
            link: formState.link === "" ? null : formState.link,
            recordStatus: formState.recordStatus,
          },
          documentType,
        },
        serviceConfig,
        isSiteEvent
      );
      onModalClose();
      notify.actionCompleted();
      onRefresh();
    } catch (error) {
      errorHandler(error, "save document");
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    handleSaveValidated,
  };
}
