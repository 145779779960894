import { t } from "i18next";
import React from "react";

import { NxpModal, NxpTabs, TabPane } from "@nexploretechnology/nxp-ui";

import { CPCSUser } from "../../../services/app";
import AppAccountSettingsGeneral from "./AppAccountSettingsGeneral/AppAccountSettingsGeneralContainer";
import AppAccountSettingsSecurity from "./AppAccountSettingsSecurity";

interface AppAccountSettingsModalProps {
  user: CPCSUser;
  modalVisible: boolean;
  onModalClose: () => void;
}

const AppAccountSettingsModal: React.FC<AppAccountSettingsModalProps> = ({
  user,
  modalVisible,
  onModalClose,
}) => {
  return (
    <NxpModal
      title={t("voc.common.accountSettings")}
      visible={modalVisible}
      onCancel={onModalClose}
      centered
    >
      <NxpTabs
        defaultActiveKey="general"
        tabsOnRight
        tabBarExtraContent={<></>}
      >
        <TabPane key="general" tab={t("voc.common.general")}>
          <AppAccountSettingsGeneral user={user} onModalClose={onModalClose} />
        </TabPane>
        <TabPane key="security" tab={t("voc.common.security")}>
          <AppAccountSettingsSecurity onModalClose={onModalClose} />
        </TabPane>
      </NxpTabs>
    </NxpModal>
  );
};

export default AppAccountSettingsModal;
