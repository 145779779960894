import { ITextFieldProps, TextField } from "office-ui-fabric-react";
import React from "react";

import AppFormField, { AppFormFieldProps } from "./AppFormField";

import "./AppFormTextField.scss";

export type AppFormTextFieldProps = {
  errorMessage?: string | string[];
} & Pick<
  ITextFieldProps,
  | "value"
  | "onChange"
  | "label"
  | "name"
  | "placeholder"
  | "required"
  | "disabled"
  | "multiline"
> &
  AppFormFieldProps;

const AppFormTextField: React.FC<AppFormTextFieldProps> = ({
  label,
  name,
  value,
  required,
  errorMessage,
  onChange,
  placeholder,
  disabled,
  multiline,
  ...restProps
}) => {
  return (
    <AppFormField {...restProps}>
      <TextField
        multiline={multiline}
        disabled={disabled}
        className="app-form-text-field"
        label={label}
        required={required}
        name={name}
        value={value}
        placeholder={placeholder}
        errorMessage={
          Array.isArray(errorMessage) ? errorMessage.join(" / ") : errorMessage
        }
        onChange={onChange}
      />
    </AppFormField>
  );
};

export default AppFormTextField;
