import React, { ReactNode } from "react";

import {
  AppFormCalendarField,
  AppFormDropdownField,
  AppFormNumericField,
  AppFormRadioField,
  AppFormSingleFileField,
  AppFormTextField,
} from "../app-form";
import { AppFormCalendarFieldProps } from "../app-form/AppFormCalendarField";
import { AppFormDropdownFieldProps } from "../app-form/AppFormDropdownField";
import { AppFormNumericFieldProps } from "../app-form/AppFormNumericField";
import { AppFormRadioFieldProps } from "../app-form/AppFormRadioField";
import { AppFormSingleFieldProps } from "../app-form/AppFormSingleFileField";
import { AppFormTextFieldProps } from "../app-form/AppFormTextField";

import "./AppQuestionList.scss";

export enum AppQuestionListFieldTypes {
  Calender,
  Custom,
  Dropdown,
  NumericInput,
  Radio,
  SingleFileInput,
  TextInput,
}

interface BaseField {
  fieldName: string;
  caption: ReactNode;
  contentForRead: ReactNode;
  isRequired?: boolean;
  fillRow?: boolean;
  customProps?: Partial<React.HTMLAttributes<HTMLDivElement>>;
}

interface CustomField extends BaseField {
  contentForRead: ReactNode;
  contentForEdit?: ReactNode;
  fieldType: AppQuestionListFieldTypes.Custom;
  className?: string;
}

interface CalenderField
  extends BaseField,
    Omit<AppFormCalendarFieldProps, "label"> {
  fieldType: AppQuestionListFieldTypes.Calender;
}

interface DropdownField
  extends BaseField,
    Omit<AppFormDropdownFieldProps, "label"> {
  fieldType: AppQuestionListFieldTypes.Dropdown;
}

interface NumericField
  extends BaseField,
    Omit<AppFormNumericFieldProps, "label"> {
  fieldType: AppQuestionListFieldTypes.NumericInput;
}

interface RadioField extends BaseField, Omit<AppFormRadioFieldProps, "label"> {
  fieldType: AppQuestionListFieldTypes.Radio;
}

interface SingleFileInputField
  extends BaseField,
    Omit<AppFormSingleFieldProps, "label"> {
  fieldType: AppQuestionListFieldTypes.SingleFileInput;
}

interface TextInputField
  extends BaseField,
    Omit<AppFormTextFieldProps, "label"> {
  fieldType: AppQuestionListFieldTypes.TextInput;
}

export type AppQuestionListField =
  | CustomField
  | CalenderField
  | DropdownField
  | NumericField
  | RadioField
  | SingleFileInputField
  | TextInputField;

interface AppQuestionListProps {
  isEditing?: boolean;
  fields: AppQuestionListField[];
}

const getEditField = (field: AppQuestionListField) => {
  switch (field.fieldType) {
    case AppQuestionListFieldTypes.Calender:
      return <AppFormCalendarField {...field} />;
    case AppQuestionListFieldTypes.Custom:
      return field.contentForEdit || field.contentForRead;
    case AppQuestionListFieldTypes.Dropdown:
      return <AppFormDropdownField {...field} />;
    case AppQuestionListFieldTypes.NumericInput:
      return <AppFormNumericField {...field} />;
    case AppQuestionListFieldTypes.Radio:
      return <AppFormRadioField {...field} />;
    case AppQuestionListFieldTypes.SingleFileInput:
      return <AppFormSingleFileField {...field} />;
    case AppQuestionListFieldTypes.TextInput:
      return <AppFormTextField {...field} />;
  }
};

const AppQuestionList: React.FC<AppQuestionListProps> = ({
  fields,
  isEditing,
}) => {
  let rightCheck = true;
  return (
    <div className="voc-app-question-list" style={{ width: "100%" }}>
      {fields.map((field) => {
        if (!field.fillRow || !rightCheck) {
          rightCheck = !rightCheck;
        }
        return (
          <div
            key={field.fieldName}
            className={`list-item${
              field.className ? ` ${field.className}` : ""
            }${field.fillRow ? " fill-row" : ""}`}
            {...field.customProps}
          >
            <div
              className={
                "field" + (rightCheck && !field.fillRow ? " right" : "")
              }
            >
              <div className="field-caption">
                {field.caption}
                {field.isRequired && <span className="required">*</span>}
              </div>
              <div className="field-content">
                {isEditing ? getEditField(field) : field.contentForRead}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(AppQuestionList);
