import { apiRequest, ServiceConfig } from "../utils/backend/api-request";

export enum LangLabel {
  EN = "en",
  ES = "es",
  DE = "de",
  ZH_HK = "zh-HK",
}

export type DictionaryTypeName =
  | "LOCATION"
  | "COST_CODES"
  | "VOC_EVENT_TASK_STATUS"
  | "VOC_CLIENT_NAME";

export interface DictionaryType {
  id: string;
  name: DictionaryTypeName;
  description: string;
  dataType: DictionaryDataType;
  application: string;
  createdDate: Date;
  lastUpdatedDate: Date;
  createdById: string;
  slug: string;
  nature: string;
  isDeleted: false;
}

export interface Dictionary {
  id: string;
  name: DictionaryTypeName;
  entityType: string;
  entityId: string;
  members: DictionaryItem[];
  dictionaryTypeId: string;
  dictionaryType?: DictionaryType;
}

export interface DictionaryItem {
  key: string;
  label: string;
  value: string | number;
  members?: DictionaryItem[];
  isEngaged: boolean;
  itemDataId?: string;
  level: number;
  id?: string;
  translations: {
    fieldName: string;
    dictionaryItemId: string;
    langKey: LangLabel;
    value: string;
  }[];
}

export enum DictionaryNature {
  APPLICATION = "Application",
  ENTITY_APPLICATION = "Entity-Application",
  ENTITY = "Entity",
  UNKNOWN = "Unknown",
}

export enum DictionaryDataType {
  TEXT = "Text",
  NUMBER = "Number",
  CONTACT = "Contact",
  GEOLOCATION = "Geolocation",
  BLOB = "BLOB",
}

export interface CreateDictionaryType {
  name: string;
  slug: string;
  description?: string;
  nature: DictionaryNature;
  application: string;
  dataType: string;
}

export const getDictionaryTypes = async (serviceConfig: ServiceConfig) => {
  const data = await apiRequest<{ data: DictionaryType[] }>(
    `/dictionary-types`,
    serviceConfig
  );
  return data.data;
};

export const getDictionaryList = (serviceConfig: ServiceConfig) =>
  apiRequest<Dictionary[]>("/dictionary", serviceConfig);

export const findDictionaryItemByKey = (
  dictItemKey: string,
  dictionaryItems: DictionaryItem[]
): DictionaryItem | undefined => {
  const item = dictionaryItems.find((item) => item.key === dictItemKey);
  if (item !== undefined) {
    return item;
  }

  let matchChildItem: DictionaryItem;

  dictionaryItems.some((dict) => {
    if (dict.members?.length > 0) {
      matchChildItem = findDictionaryItemByKey(dictItemKey, dict.members);
    }
    return matchChildItem !== undefined;
  });

  return matchChildItem;
};

export const getDictionaryItemsAsIndentedSelectOptions = (
  dictionaryItems: DictionaryItem[]
) => {
  const options: {
    text: string;
    key: string;
  }[] = [];
  function unpackChildren(
    dict: DictionaryItem,
    childOf: string,
    childN: number
  ) {
    const spacer = " ".repeat(childN);
    const option = {
      key: dict.key,
      text: spacer + dict.label,
    };
    options.push(option);

    dict.members?.forEach((c: any) => {
      unpackChildren(c, dict.key, childN + 1);
    });
  }

  dictionaryItems.forEach((d) => {
    unpackChildren(d, null, 0);
  });
  return options;
};
