import { Chart as ChartJS, registerables } from "chart.js";
import { Dropdown, Toggle } from "office-ui-fabric-react";
import React from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import i18n from "../../../../app/i18n/i18n";
import { ChartData } from "../../../../app/types";

ChartJS.register(...registerables);

interface DashboardChartLayoutProps {
  chartData: ChartData;
  monthLabels: string[];
  dateRangeOptions: string[];
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
}

const DashboardChartLayout: React.FC<DashboardChartLayoutProps> = ({
  chartData,
  monthLabels,
  dateRangeOptions,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const [graphVOs, setGraphVOs] = React.useState<boolean>(true);
  const [graphCs, setGraphCs] = React.useState<boolean>(true);
  const [graphVOCs, setGraphVOCs] = React.useState<boolean>(true);
  const { t } = useTranslation();

  const getDataset = (setType: string, property: string) => {
    return chartData[setType].map((vo: any) => (vo ? vo[property] : null));
  };

  const voInternalValueSet = getDataset("VOs", "internalValue");
  const voInternalTargetSet = getDataset("VOs", "internalTarget");
  const voClientAssessedSet = getDataset("VOs", "clientAssessed");
  const voProgressSet = getDataset("VOs", "internalProgressValue");
  const voCumAppliedSet = getDataset("VOs", "cumulativeAppliedNominal");
  const voCumCertifiedSet = getDataset("VOs", "cumulativeValueCertified");
  const voSetArray = [
    voInternalValueSet,
    voInternalTargetSet,
    voClientAssessedSet,
    voProgressSet,
    voCumAppliedSet,
    voCumCertifiedSet,
  ];

  const claimInternalValueSet = getDataset("Cs", "internalValue");
  const claimInternalTargetSet = getDataset("Cs", "internalTarget");
  const claimClientAssessedSet = getDataset("Cs", "clientAssessed");
  const claimProgressSet = getDataset("Cs", "internalProgressValue");
  const claimCumAppliedSet = getDataset("Cs", "cumulativeAppliedNominal");
  const claimCumCertifiedSet = getDataset("Cs", "cumulativeValueCertified");
  const claimSetArray = [
    claimInternalValueSet,
    claimInternalTargetSet,
    claimClientAssessedSet,
    claimProgressSet,
    claimCumAppliedSet,
    claimCumCertifiedSet,
  ];

  const voAndClaimInternalValueSet = getDataset("VOCs", "internalValue");
  const voAndClaimInternalTargetSet = getDataset("VOCs", "internalTarget");
  const voAndClaimClientAssessedSet = getDataset("VOCs", "clientAssessed");
  const voAndClaimProgressSet = getDataset("VOCs", "internalProgressValue");
  const voAndClaimCumAppliedSet = getDataset(
    "VOCs",
    "cumulativeAppliedNominal"
  );
  const voAndClaimCumCertifiedSet = getDataset(
    "VOCs",
    "cumulativeValueCertified"
  );

  const voAndClaimSetArray = [
    voAndClaimInternalValueSet,
    voAndClaimInternalTargetSet,
    voAndClaimClientAssessedSet,
    voAndClaimProgressSet,
    voAndClaimCumAppliedSet,
    voAndClaimCumCertifiedSet,
  ];

  const addDatasets = (
    setType1: string,
    setType2: string,
    property: string
  ) => {
    return chartData[setType1].map((set: any, i: number) =>
      set || chartData?.[setType2]?.[i]
        ? (set?.[property] ?? 0) + (chartData?.[setType2]?.[i]?.[property] ?? 0)
        : null
    );
  };

  const voPlusCInternalValueSet = addDatasets("VOs", "Cs", "internalValue");
  const voPlusCInternalTargetSet = addDatasets("VOs", "Cs", "internalTarget");
  const voPlusCClientAssessedSet = addDatasets("VOs", "Cs", "clientAssessed");
  const voPlusCProgressSet = addDatasets("VOs", "Cs", "internalProgressValue");
  const voPlusCCumAppliedSet = addDatasets(
    "VOs",
    "Cs",
    "cumulativeAppliedNominal"
  );
  const voPlusCCumCertifiedSet = addDatasets(
    "VOs",
    "Cs",
    "cumulativeValueCertified"
  );
  const voPlusCSetArray = [
    voPlusCInternalValueSet,
    voPlusCInternalTargetSet,
    voPlusCClientAssessedSet,
    voPlusCProgressSet,
    voPlusCCumAppliedSet,
    voPlusCCumCertifiedSet,
  ];

  const voPlusVOCInternalValueSet = addDatasets("VOs", "VOCs", "internalValue");
  const voPlusVOCInternalTargetSet = addDatasets(
    "VOs",
    "VOCs",
    "internalTarget"
  );
  const voPlusVOCClientAssessedSet = addDatasets(
    "VOs",
    "VOCs",
    "clientAssessed"
  );
  const voPlusVOCProgressSet = addDatasets(
    "VOs",
    "VOCs",
    "internalProgressValue"
  );
  const voPlusVOCCumAppliedSet = addDatasets(
    "VOs",
    "VOCs",
    "cumulativeAppliedNominal"
  );
  const voPlusVOCCumCertifiedSet = addDatasets(
    "VOs",
    "VOCs",
    "cumulativeValueCertified"
  );
  const voPlusVOCSetArray = [
    voPlusVOCInternalValueSet,
    voPlusVOCInternalTargetSet,
    voPlusVOCClientAssessedSet,
    voPlusVOCProgressSet,
    voPlusVOCCumAppliedSet,
    voPlusVOCCumCertifiedSet,
  ];

  const cPlusVOCInternalValueSet = addDatasets("Cs", "VOCs", "internalValue");
  const cPlusVOCInternalTargetSet = addDatasets("Cs", "VOCs", "internalTarget");
  const cPlusVOCClientAssessedSet = addDatasets("Cs", "VOCs", "clientAssessed");
  const cPlusVOCProgressSet = addDatasets(
    "Cs",
    "VOCs",
    "internalProgressValue"
  );
  const cPlusVOCCumAppliedSet = addDatasets(
    "Cs",
    "VOCs",
    "cumulativeAppliedNominal"
  );
  const cPlusVOCCumCertifiedSet = addDatasets(
    "Cs",
    "VOCs",
    "cumulativeValueCertified"
  );

  const cPlusVOCSetArray = [
    cPlusVOCInternalValueSet,
    cPlusVOCInternalTargetSet,
    cPlusVOCClientAssessedSet,
    cPlusVOCProgressSet,
    cPlusVOCCumAppliedSet,
    cPlusVOCCumCertifiedSet,
  ];

  const addAllDatasets = (property: string) => {
    return chartData.VOs.map((vo: any, i: number) =>
      vo || chartData?.Cs?.[i] || chartData?.VOCs?.[i]
        ? (vo?.[property] ?? 0) +
          (chartData.Cs?.[i]?.[property] ?? 0) +
          (chartData.VOCs?.[i]?.[property] ?? 0)
        : null
    );
  };

  const allInternalValueSet = addAllDatasets("internalValue");
  const allInternalTargetSet = addAllDatasets("internalTarget");
  const allClientAssessedSet = addAllDatasets("clientAssessed");
  const allProgressSet = addAllDatasets("internalProgressValue");
  const allCumAppliedSet = addAllDatasets("cumulativeAppliedNominal");
  const allCumCertifiedSet = addAllDatasets("cumulativeValueCertified");
  const allSetArray = [
    allInternalValueSet,
    allInternalTargetSet,
    allClientAssessedSet,
    allProgressSet,
    allCumAppliedSet,
    allCumCertifiedSet,
  ];

  const packageDatasets = (setType: string, datasets: number[][]) => {
    return [
      {
        label: setType + t("voc.dashboard.internalValues"),
        data: datasets[0],
        fill: false,
        borderColor: "#A6CEE3",
        tension: 0.1,
      },
      {
        label: setType + t("voc.dashboard.internalTargets"),
        data: datasets[1],
        fill: false,
        borderColor: "#1F78B4",
        tension: 0.1,
      },
      {
        label: setType + t("voc.dashboard.clientAssessed"),
        data: datasets[2],
        fill: false,
        borderColor: "#1C7530",
        tension: 0.1,
      },
      {
        label: setType + t("voc.dashboard.progress"),
        data: datasets[3],
        fill: false,
        borderColor: "#FFC107",
        tension: 0.1,
      },
      {
        label: setType + t("voc.dashboard.cumulativeValueApplied"),
        data: datasets[4],
        fill: false,
        borderColor: "#B38705",
        tension: 0.1,
      },
      {
        label: setType + t("voc.dashboard.cumulativeValueCertified"),
        data: datasets[5],
        fill: false,
        borderColor: "#ADD3B6",
        tension: 0.1,
      },
    ];
  };

  const voDatasets = packageDatasets("VOs", voSetArray);
  const claimDatasets = packageDatasets(
    t("voc.reports.dashboard.claims"),
    claimSetArray
  );
  const voAndClaimDatasets = packageDatasets(
    t("voc.dashboard.voWithClaims"),
    voAndClaimSetArray
  );
  const voPlusCDatasets = packageDatasets(
    t("voc.dashboard.allVosAllClaims"),
    voPlusCSetArray
  );
  const voPlusVOCDatasets = packageDatasets(
    t("voc.dashboard.allVosPlusAllVosWithClaims"),
    voPlusVOCSetArray
  );
  const cPlusVOCDatasets = packageDatasets(
    t("voc.dashboard.allClaimsPlusAllVosWithClaims"),
    cPlusVOCSetArray
  );
  const allDatasets = packageDatasets(
    t("voc.dashboard.valuesRegister"),
    allSetArray
  );

  const getToggledSets = React.useCallback(() => {
    if (graphVOs && graphCs && graphVOCs) {
      return allDatasets;
    } else if (graphVOs && graphCs && !graphVOCs) {
      return voPlusCDatasets;
    } else if (graphVOs && !graphCs && graphVOCs) {
      return voPlusVOCDatasets;
    } else if (!graphVOs && graphCs && graphVOCs) {
      return cPlusVOCDatasets;
    } else if (graphVOs && !graphCs && !graphVOCs) {
      return voDatasets;
    } else if (!graphVOs && graphCs && !graphVOCs) {
      return claimDatasets;
    } else if (!graphVOs && !graphCs && graphVOCs) {
      return voAndClaimDatasets;
    } else if (!graphVOs && !graphCs && !graphVOCs) {
      return [];
    }
  }, [
    graphVOs,
    graphCs,
    graphVOCs,
    allDatasets,
    voPlusCDatasets,
    voPlusVOCDatasets,
    cPlusVOCDatasets,
    voDatasets,
    claimDatasets,
    voAndClaimDatasets,
  ]);

  //TODO: Move to utils, wait other branch to be merged avoiding conflicts
  const formatNumberByLocale = (value: number, locale: string) => {
    const fractionDigits = getFractionDigits(value);
    return value.toLocaleString(locale, {
      minimumFractionDigits: Math.min(fractionDigits, 100),
      useGrouping: true,
    });
  };

  const getFractionDigits = (value: number) => {
    const numberAsString = value.toString();
    if (numberAsString.includes(".")) {
      return numberAsString.split(".")[1].length;
    }
    return 0;
  };

  return (
    <div className="dashboard-chart">
      <div className="chart">
        <Line
          data={{
            labels: monthLabels,
            datasets: getToggledSets(),
          }}
          options={{
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || "";
                    if (label) {
                      label += ": ";
                    }
                    if (context.raw !== null) {
                      label += formatNumberByLocale(
                        Number(context.raw),
                        i18n.language
                      );
                    }
                    return label;
                  },
                },
              },
            },
            scales: {
              y: {
                ticks: {
                  callback: function (value) {
                    return formatNumberByLocale(Number(value), i18n.language);
                  },
                },
              },
            },
          }}
        />
      </div>
      <div className="chart-control">
        <Dropdown
          label={t("voc.dashboard.startDate")}
          selectedKey={startDate}
          options={dateRangeOptions.map((r) => ({
            key: r,
            text: r,
          }))}
          onChange={(e, item) => {
            setStartDate(String(item.key));
          }}
        />
        <Dropdown
          label={t("voc.dashboard.endDate")}
          selectedKey={endDate}
          options={dateRangeOptions.map((r) => ({
            key: r,
            text: r,
          }))}
          onChange={(e, item) => {
            setEndDate(String(item.key));
          }}
        />
        <Toggle
          label={t("voc.dashboard.vos")}
          checked={graphVOs}
          onChange={() => setGraphVOs(!graphVOs)}
        ></Toggle>
        <Toggle
          label={t("voc.dashboard.claims")}
          checked={graphCs}
          onChange={() => setGraphCs(!graphCs)}
        ></Toggle>
        <Toggle
          label={t("voc.dashboard.voAndClaims")}
          checked={graphVOCs}
          onChange={() => setGraphVOCs(!graphVOCs)}
        ></Toggle>
      </div>
    </div>
  );
};

export default DashboardChartLayout;
