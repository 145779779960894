import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { NxpSpin } from "@nexploretechnology/nxp-ui";
import moment from "moment";
import useAppContext from "../../../app/hooks/useAppContext";
import { useAsync } from "../../../app/hooks/useAsync";
import {
  VocEvent,
  VocEventStatusRecord,
  VocGetSupportDocumentResponse,
  VocRecordStatus,
  VocSiteEvent,
  VocSupportDocument,
} from "../../../app/types";
import { DateFormatPattern } from "../../../app/utils/localisation/locale-enum";
import { stringToDateFormat } from "../../../app/utils/mappers";
import {
  getEventSupportDocuments,
  getSiteEventSupportDocuments,
} from "../../compensation-event-services";
import RecordsTabLayout, { BatchUpdateEvent } from "./RecordsTabLayout";

export interface DateRecord {
  date: Date;
  statusType?: VocRecordStatus;
  siteEventId?: string;
  siteEventSerial?: string;
  siteEventTitle?: string;
  compensationEventId?: string;
  compensationEventSerial?: string;
  compensationEventTitle?: string;
}

interface RecordsTabContainerProps {
  event: VocEvent | VocSiteEvent;
  onEventRefresh: () => void;
  entityId: string;
  eventId: string;
  siteEventId?: string;
  contemporaryRecordRequired?: boolean;
}

const RecordsTabContainer: React.FC<RecordsTabContainerProps> = (props) => {
  const {
    event,
    onEventRefresh,
    entityId,
    eventId,
    siteEventId,
    contemporaryRecordRequired = true,
  } = props;
  const { serviceConfig } = useAppContext();

  // comment out and refresh the whole event instead, this makes sure supporting documents are refreshed too
  // const [, setGetStatusRecordRequest] = useVocServiceAsync<
  //   VocEventStatusRecord[]
  // >(event.entityId, undefined, {
  //   onSuccess: data => {
  //     event.records = data;
  //   },
  // });
  const getDaysArray = (start, end) => {
    let arr: any[] = [],
      dt;
    for (
      arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const dateRecords = useMemo(() => {
    if (!siteEventId) {
      const relatedSiteEvent: any[][] = event.siteEvents.map((event) => {
        const daylist = getDaysArray(
          new Date(
            moment(event?.dateOfAwareness).format(DateFormatPattern.date)
          ),
          new Date(moment().format(DateFormatPattern.date))
        );
        return daylist.map((v) => ({
          date: new Date(v.toISOString().slice(0, 10)),
          siteEventSerial: event.serial,
          siteEventSubject: event.subject,
          siteEventId: event.id,
          compensationEventId: event.eventId,
          compensationEventSerial: event.compensationEventSerial,
          compensationEventTitle: event.compensationEventTitle,
          statusType: "VO_REC_STATUS_11" as VocRecordStatus,
        }));
      });
      const records: DateRecord[] = event.records.map((record: any) => ({
        date: stringToDateFormat(record.recordDate),
        statusType: record.status as VocRecordStatus,
        siteEventId: record.siteEventId,
        siteEventSerial: record.siteEventSerial,
        siteEventTitle: record.siteEventTitle,
        compensationEventId: record.eventId,
        compensationEventSerial: record.compensationEventSerial,
        compensationEventTitle: record.compensationEventTitle,
      }));
      return [...records, ...relatedSiteEvent].flatMap((item) => item);
    } else {
      const records: DateRecord[] = event.recordList.map(
        (record: VocEventStatusRecord) => ({
          date: stringToDateFormat(record.recordDate),
          statusType: record.status as VocRecordStatus,
          siteEventId: record.siteEventId,
          siteEventSerial: record.siteEventSerial,
          siteEventTitle: record.siteEventTitle,
        })
      );
      return records;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.records, event.recordList]);

  useEffect(() => {
    // refresh event data on mount to ensure records and supporting docs are update-to-date
    onEventRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ///////////////
  //RECORDS LISTS
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const [allSupportDocuments, setAllSupportDocuments] = React.useState<
    VocSupportDocument[]
  >([]);

  const [, setGetSupportDocuments] = useAsync<VocGetSupportDocumentResponse>(
    () => {
      if (!siteEventId) {
        return getEventSupportDocuments(eventId, serviceConfig);
      } else {
        return getSiteEventSupportDocuments(siteEventId, serviceConfig);
      }
    },
    {
      onSuccess: (data) => {
        setAllSupportDocuments([
          ...data.labour,
          ...data.plant,
          ...data.timesheet,
          ...data.siteDiaries,
          ...data.contemporary,
        ]);
        setLoaded(true);
        if (data) onEventRefresh();
      },
    }
  );

  const handleStatusRecordsRefresh = () => {
    onEventRefresh();
    if (!siteEventId) {
      setGetSupportDocuments(() =>
        getEventSupportDocuments(eventId, serviceConfig)
      );
    } else {
      setGetSupportDocuments(() =>
        getSiteEventSupportDocuments(siteEventId, serviceConfig)
      );
    }
  };

  const { siteEventId: seId, eventId: compensationId } = useParams();
  const events: BatchUpdateEvent[] = event.siteEvents
    ? event.siteEvents.map((e: any) => ({ ...e, eventType: "siteEvent" }))
    : [];
  if (compensationId) {
    events.push({
      id: compensationId as string,
      serial: event.serial,
      title: event.eventTitle,
      eventType: "compEvent",
    });
  } else {
    events.push({
      id: seId as string,
      serial: event.serial,
      title: event.subject,
      eventType: "siteEvent",
    });
  }
  return loaded ? (
    <RecordsTabLayout
      eventId={eventId}
      entityId={entityId}
      onStatusRecordsRefresh={handleStatusRecordsRefresh}
      dateRecords={dateRecords}
      dateOfAwareness={event.dateOfAwareness}
      dateOfCessation={event.dateOfCessation}
      eventSupportDocuments={allSupportDocuments}
      siteEventId={siteEventId}
      events={events}
      contemporaryRecordRequired={contemporaryRecordRequired}
    />
  ) : (
    <NxpSpin
      spinning={true}
      style={{ display: "flex", justifyContent: "center", margin: 60 }}
    ></NxpSpin>
  );
};

export default React.memo(RecordsTabContainer);
