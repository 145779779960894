import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { VocSupportDocument } from "../../../../app/types";
import ContemporaryRecordsPanel from "../../supporting-tab/supporting-panels/General/ContemporaryRecordPanel.tsx/ContemporaryRecordPanel";
import DailyDiariesPanel from "../../supporting-tab/supporting-panels/General/DailyDiariesPanel/DailyDiariesPanel";
import LabourRecordPanel from "../../supporting-tab/supporting-panels/General/LabourRecordPanel/LabourRecordPanel";
import PlantRecordPanel from "../../supporting-tab/supporting-panels/General/PlantRecordPanel/PlantRecordPanel";
import StaffRecordPanel from "../../supporting-tab/supporting-panels/General/StaffRecordPanel/StaffRecordPanel";

const useStyles = createUseStyles({
  recordList: {
    "& .ant-collapse": {
      margin: "20px",
      border: "1px solid #f0f0f0",
      borderRadius: "2px",
    },
  },
});

interface RecordsListsProps {
  onRefresh: () => void;
  labourRecords: VocSupportDocument[];
  plantRecords: VocSupportDocument[];
  timesheets: VocSupportDocument[];
  dailyDiaries: VocSupportDocument[];
  contemporaryRecords: VocSupportDocument[];
}

const RecordsLists: React.FC<RecordsListsProps> = ({
  onRefresh,
  labourRecords,
  plantRecords,
  timesheets,
  dailyDiaries,
  contemporaryRecords,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={classes.recordList}>
      <LabourRecordPanel
        labourRecords={labourRecords}
        onRefresh={onRefresh}
        onSetIsLoading={setIsLoading}
        isLoading={isLoading}
      />
      <PlantRecordPanel
        plantRecords={plantRecords}
        onRefresh={onRefresh}
        onSetIsLoading={setIsLoading}
        isLoading={isLoading}
      />
      <StaffRecordPanel
        timesheets={timesheets}
        onRefresh={onRefresh}
        onSetIsLoading={setIsLoading}
        isLoading={isLoading}
      />
      <DailyDiariesPanel
        dailyDiaries={dailyDiaries}
        onRefresh={onRefresh}
        onSetIsLoading={setIsLoading}
        isLoading={isLoading}
      />
      <ContemporaryRecordsPanel
        contemporaryRecords={contemporaryRecords}
        onRefresh={onRefresh}
        onSetIsLoading={setIsLoading}
        isLoading={isLoading}
      />
    </div>
  );
};

export default RecordsLists;
