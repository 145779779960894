import React, { useCallback, useEffect } from "react";

import useAppContext from "../../../app/hooks/useAppContext";
import { Dashboard } from "../../../app/types";
import { getDashboardStatistic } from "../../reports-services";
import DashboardLayout from "./DashboardLayout";
import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";
import { useTranslation } from "react-i18next";

interface DashboardContainerProps {}

const DashboardContainer: React.FC<DashboardContainerProps> = () => {
  const { serviceConfig } = useAppContext();
  const { t } = useTranslation();
  const [dashboardData, setDashboardData] = React.useState<Dashboard>(null);

  const handleGetDashboardStatistic = useCallback(async () => {
    const response = await getDashboardStatistic(serviceConfig);
    setDashboardData(response);
  }, [serviceConfig]);

  useEffect(() => {
    handleGetDashboardStatistic();
  }, [handleGetDashboardStatistic]);

  return dashboardData ? (
    <AccessVisible
      objectCode="dashboard"
      actionType="reader"
      fallback={<i>{t("app.permission.accessDenied")}</i>}
    >
      <DashboardLayout dashboardData={dashboardData} />
    </AccessVisible>
  ) : null;
};

export default DashboardContainer;
