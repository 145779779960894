import React, { useState } from "react";

import classNames from "classnames";

import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";
import AppButton, { AppButtonType } from "../../../app/components/app-button";
import AppCollapsible from "../../../app/components/app-collapsible/AppCollapsible";
import AppQuestionList from "../../../app/components/app-question-list";
import {
  AppQuestionListField,
  AppQuestionListFieldTypes,
} from "../../../app/components/app-question-list/AppQuestionList";
import {
  VocCustomUi,
  VocCustomUiLayoutGroup,
  VocCustomUiLayoutItemType,
  VocCustomUiQuestionListCustomField,
  VocCustomUiQuestionListPresetField,
} from "../../../app/types";
import { SelectedUiReducerAction } from "../../selected-ui-reducer";
import QuestionGroupCaption from "./QuestionGroupCaption";
import QuestionGroupFieldSchemaContainer from "./QuestionGroupFieldSchemaContainer";
import QuestionGroupHeader from "./QuestionGroupHeader";

import { useTranslation } from "react-i18next";
import { getDefaultAdminPlaceholder } from "../../pages/customization-home/getDefaultAdminPlaceholder.helpers";
import "./QuestionGroups.scss";

interface QuestionGroupsProps {
  selectedUi: VocCustomUi;
  screenData: VocCustomUi[];
  allowRename: boolean;
  allowHideAndReorder: boolean;
  allowCustomField: boolean;
  onSelectedUiDispatch: (action: SelectedUiReducerAction) => void;
  onSelectedUiSave: () => void;
  onCancel: () => void;
  onScreenDataRefresh: () => void;
}

const QuestionGroups: React.FC<QuestionGroupsProps> = ({
  selectedUi,
  screenData,
  allowRename,
  allowHideAndReorder,
  allowCustomField,
  onSelectedUiDispatch,
  onSelectedUiSave, //only use for saving after rename,
  onCancel, //only use for cancelling after rename,
  onScreenDataRefresh,
}) => {
  const { t } = useTranslation();
  const handleFieldDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    groupKey: string,
    fieldKey: string
  ) => {
    e.dataTransfer.setData("groupKey", groupKey);
    e.dataTransfer.setData("fieldKey", fieldKey);
    if (!e.currentTarget.className.includes(" dragging")) {
      e.currentTarget.className += " dragging";
    }
  };

  const handleFieldDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.className = e.currentTarget.className.replace(
      / dragging/g,
      ""
    );
  };

  const handleFieldDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!e.currentTarget.className.includes(" drag-over")) {
      e.currentTarget.className += " drag-over";
    }
  };

  const handleFieldDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.className = e.currentTarget.className.replace(
      / drag-over/g,
      ""
    );
  };

  const handleFieldDrop = (
    e: React.DragEvent<HTMLDivElement>,
    toGroupKey: string,
    toFieldKey?: string
  ) => {
    const fromGroupKey = e.dataTransfer.getData("groupKey");
    const fromFieldKey = e.dataTransfer.getData("fieldKey");
    e.currentTarget.className = e.currentTarget.className.replace(
      / drag-over/g,
      ""
    );
    if (fromGroupKey !== toGroupKey || fromFieldKey !== toFieldKey) {
      onSelectedUiDispatch({
        type: "layoutGroupMemberReorder",
        groupKey: fromGroupKey,
        fieldKey: fromFieldKey,
        toGroupKey,
        toFieldKey,
      });
    }
  };

  const [schemaModalConfig, setSchemaModalConfig] = useState<{
    groupKey: string;
    editCustomFieldKey?: string; // empty for create, otherwise update
  }>();

  const handleGroupDisplayChange = (groupKey: string, display: boolean) => {
    onSelectedUiDispatch({
      type: "layoutGroupChange",
      groupKey: groupKey,
      changeValues: {
        display,
      },
    });
  };

  const handleGroupLockChange = (groupKey: string, locked: boolean) => {
    onSelectedUiDispatch({
      type: "layoutGroupChange",
      groupKey: groupKey,
      changeValues: {
        locked,
      },
    });
  };

  const [renamingGroups, setRenamingGroups] = useState<string[]>([]);

  const handleGroupRenameEdit = (groupKey: string) => {
    setRenamingGroups((prevState) => [...prevState, groupKey]);
  };

  const handleGroupRenameCancel = (groupKey: string) => {
    onCancel();
    setRenamingGroups((prevState) =>
      prevState.filter((item) => item !== groupKey)
    );
  };

  const handleGroupRenameValueChange = (value: string, groupKey: string) => {
    onSelectedUiDispatch({
      type: "layoutGroupRename",
      groupKey: groupKey,
      customName: value,
    });
  };

  const handleGroupRenameSave = (groupKey: string) => {
    onSelectedUiSave();
    setRenamingGroups((prevState) =>
      prevState.filter((item) => item !== groupKey)
    );
  };

  return (
    <div className="voc-customization-question-groups">
      {selectedUi.layout.map((group: VocCustomUiLayoutGroup) => {
        return (
          <AppCollapsible
            className={classNames(
              "flex-child-height",
              group.display && "display"
            )}
            defaultCollapsed={false}
            key={group.key}
            hasFields={false}
            headerChildren={
              <QuestionGroupHeader
                group={group}
                allowRename={allowRename}
                isRenaming={renamingGroups.includes(group.key)}
                allowHide={allowHideAndReorder}
                onGroupDisplayChange={handleGroupDisplayChange}
                onGroupLockChange={handleGroupLockChange}
                onGroupRenameCancel={handleGroupRenameCancel}
                onGroupRenameEdit={handleGroupRenameEdit}
                onGroupRenameSave={handleGroupRenameSave}
                onGroupRenameValueChange={handleGroupRenameValueChange}
              />
            }
            bodyChildren={
              <AppQuestionList
                fields={[
                  ...(group.members.map(
                    (
                      field:
                        | VocCustomUiQuestionListPresetField
                        | VocCustomUiQuestionListCustomField
                    ) => ({
                      fieldType: AppQuestionListFieldTypes.Custom,
                      customProps:
                        !allowHideAndReorder ||
                        renamingGroups.includes(group.key)
                          ? undefined
                          : {
                              draggable: true,
                              onDrop: (e: React.DragEvent<HTMLDivElement>) =>
                                handleFieldDrop(e, group.key, field.key),
                              onDragStart: (
                                e: React.DragEvent<HTMLDivElement>
                              ) =>
                                handleFieldDragStart(e, group.key, field.key),
                              onDragEnd: (e: React.DragEvent<HTMLDivElement>) =>
                                handleFieldDragEnd(e),
                              onDragLeave: handleFieldDragLeave,
                              onDragOver: handleFieldDragOver,
                            },
                      className: classNames(
                        field.display && "display",
                        (renamingGroups.includes(group.key) ||
                          !allowHideAndReorder) &&
                          "no-reorder"
                      ),
                      fieldName: field.key,
                      caption: (
                        <QuestionGroupCaption
                          caption={
                            field.customName === undefined
                              ? field.name
                              : field.customName
                          }
                          isRequired={false}
                          display={field.display}
                          locked={field.locked}
                          isRenaming={renamingGroups.includes(group.key)}
                          allowHide={allowHideAndReorder}
                          layoutItemType={field.type}
                          onRenameChange={(value) => {
                            onSelectedUiDispatch({
                              type: "layoutGroupMemberRename",
                              groupKey: group.key,
                              fieldKey: field.key,
                              customName: value,
                            });
                          }}
                          onLockChange={() => {
                            onSelectedUiDispatch({
                              type: "layoutGroupMemberChange",
                              groupKey: group.key,
                              fieldKey: field.key,
                              changeValues: {
                                locked: !field.locked,
                              },
                            });
                          }}
                          onDisplayChange={() =>
                            onSelectedUiDispatch({
                              type: "layoutGroupMemberChange",
                              groupKey: group.key,
                              fieldKey: field.key,
                              changeValues: {
                                display: !field.display,
                              },
                            })
                          }
                        />
                      ),
                      contentForRead:
                        field.type ===
                        VocCustomUiLayoutItemType.QuestionListCustomField ? (
                          <AccessVisible
                            objectCode="customizations"
                            actionType="admin"
                          >
                            <>
                              {getDefaultAdminPlaceholder(field, t)}
                              {renamingGroups.includes(group.key) || (
                                <AppButton
                                  className="edit-button"
                                  appButtonType={AppButtonType.Link}
                                  onClick={() =>
                                    setSchemaModalConfig({
                                      groupKey: group.key,
                                      editCustomFieldKey: field.key,
                                    })
                                  }
                                >
                                  <i className="edit" />
                                  {t("voc.common.edit")}
                                </AppButton>
                              )}
                            </>
                          </AccessVisible>
                        ) : (
                          getDefaultAdminPlaceholder(field, t)
                        ),
                    })
                  ) as AppQuestionListField[]),
                ].concat(
                  // append +ADD button
                  allowCustomField && !renamingGroups.includes(group.key)
                    ? [
                        {
                          // for drop placeholder at the end of the list
                          fieldType: AppQuestionListFieldTypes.Custom,
                          customProps: {
                            onDrop: (e: React.DragEvent<HTMLDivElement>) =>
                              handleFieldDrop(e, group.key, undefined),
                            onDragLeave: handleFieldDragLeave,
                            onDragOver: handleFieldDragOver,
                          },
                          className: "group-add-field display",
                          fieldName: null,
                          caption: (
                            <AppButton
                              appButtonType={AppButtonType.Link}
                              onClick={() =>
                                setSchemaModalConfig({ groupKey: group.key })
                              }
                            >
                              <i className="add" />
                              {t("voc.common.add")}
                            </AppButton>
                          ),
                          contentForRead: null,
                        },
                      ]
                    : []
                )}
              />
            }
          />
        );
      })}
      {!!schemaModalConfig && (
        <QuestionGroupFieldSchemaContainer
          selectedUi={selectedUi}
          screenData={screenData}
          schemaModalConfig={schemaModalConfig}
          onModalClose={() => setSchemaModalConfig(undefined)}
          onScreenDataRefresh={onScreenDataRefresh}
        />
      )}
    </div>
  );
};

export default QuestionGroups;
