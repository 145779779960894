import React from "react";
import { useTranslation } from "react-i18next";

import { ValidationResult } from "@nexploretechnology/nxp-ui";
import { IDropdownOption } from "office-ui-fabric-react";

import AppButton, { AppButtonType } from "../../../app/components/app-button";
import AppCollapsible from "../../../app/components/app-collapsible/AppCollapsible";
import { VocCustomUi, VocCustomUiLayoutItem, VocVO } from "../../../app/types";
import getQuestionList from "./getQuestionList";

import "./VODetailsTabLayout.scss";

interface VODetailsTabLayoutProps {
  voForm: VocVO;
  applicableClausesOptions: IDropdownOption[];
  valuationClauseOptions: IDropdownOption[];
  valuationMethodOptions: IDropdownOption[];
  instructionOwnerOptions: IDropdownOption[];
  editingFieldGroups: string[];
  customUi: VocCustomUi;
  formErrors: ValidationResult<VocVO>;
  onEdit: (groupKey: string) => void;
  onFormChange: (values: Partial<VocVO>) => void;
  onSave: (groupKey: string) => void;
  onCancel: (groupKey: string) => void;
}

const VODetailsTabLayout: React.FC<VODetailsTabLayoutProps> = ({
  voForm,
  applicableClausesOptions,
  valuationClauseOptions,
  valuationMethodOptions,
  instructionOwnerOptions,
  editingFieldGroups,
  customUi,
  formErrors,
  onEdit,
  onFormChange,
  onSave,
  onCancel,
}) => {
  const { t } = useTranslation();
  if (!voForm) {
    voForm = {
      id: null,
      eventId: null,
      createdOn: null,
      updatedOn: null,
    };
  }

  const handleCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    groupKey: string
  ) => {
    if (
      !(e.target as any).parentNode.parentNode.parentNode.className.includes(
        "collapsed"
      )
    ) {
      e.stopPropagation();
    }
    onCancel(groupKey);
  };

  const handleSave = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    groupKey: string
  ) => {
    if (
      !(e.target as any).parentNode.parentNode.parentNode.className.includes(
        "collapsed"
      )
    ) {
      e.stopPropagation();
    }
    onSave(groupKey);
  };

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    groupKey: string
  ) => {
    if (
      !(e.target as any).parentNode.parentNode.parentNode.className.includes(
        "collapsed"
      )
    ) {
      e.stopPropagation();
    }
    onEdit(groupKey);
  };

  const getCollapsible = (group: VocCustomUiLayoutItem) => {
    if (group !== undefined) {
      return (
        <AppCollapsible
          className="flex-child-height"
          defaultCollapsed={false}
          key={group.key}
          hasFields={false}
          bodyChildren={
            voForm &&
            getQuestionList(
              t,
              group.key,
              editingFieldGroups.includes(group.key),
              voForm,
              applicableClausesOptions,
              valuationClauseOptions,
              valuationMethodOptions,
              instructionOwnerOptions,
              customUi,
              formErrors,
              onFormChange
            )
          }
          headerChildren={
            <>
              <div className="title">
                {group.customName
                  ? group.customName
                  : t(`voc.eventPage.${group.key}`)}
              </div>
              <div className="edit">
                {editingFieldGroups.includes(group.key) ? (
                  <>
                    <AppButton
                      appButtonType={AppButtonType.Link}
                      onClick={(e) => handleCancel(e, group.key)}
                    >
                      {t("voc.common.cancel")}
                    </AppButton>
                    <AppButton onClick={(e) => handleSave(e, group.key)}>
                      {t("voc.common.save")}
                    </AppButton>
                  </>
                ) : (
                  // <AccessVisible permissions={'compensation event:edit'}>
                  <AppButton
                    appButtonType={AppButtonType.Link}
                    onClick={(e) => handleEdit(e, group.key)}
                  >
                    {t("voc.common.edit")}
                  </AppButton>
                  // </AccessVisible>
                )}
              </div>
            </>
          }
        />
      );
    }
  };

  return (
    <div className="vo-details-tab-container">
      <div className="spine">
        {["voGeneral", "voVariationDiscussion"]
          .map((key) =>
            customUi.layout.find((group) => group.key === key && group.display)
          )
          .map((group) => getCollapsible(group))}
      </div>
    </div>
  );
};

export default VODetailsTabLayout;
