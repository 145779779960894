import React, { useEffect, useState } from "react";
import { VocValueRegister } from "../../../app/types";
import {
  formatMoneyValue,
  formatPercentValue,
} from "../../../app/utils/string";
import { ValueRegisterColumn } from "./ValueRegisterContainer";

import { useTranslation } from "react-i18next";
import "./ValueRegisterRow.scss";

const getColumnContent = (
  fieldName: keyof VocValueRegister,
  totals: Partial<VocValueRegister>
) => {
  const totalCost =
    (totals.directCost || 0) +
    (totals.indirectCost || 0) +
    (totals.costCloseOut || 0) +
    (totals.costGna || 0);
  const profitLoss = (totals.internalTarget || 0) - totalCost;
  switch (fieldName) {
    case "compensationEventId":
    case "compensationEventSerial":
    case "eventTypeSerial":
    case "eventTitle":
      return null;
    case "recovery":
      return (
        <div role="gridcell" className="read-only">
          <span>
            {totals.submittedValue && Number.isFinite(totals.internalTarget)
              ? formatPercentValue(
                  Number(totals.internalTarget / totals.submittedValue)
                )
              : null}
          </span>
        </div>
      );
    case "cumulativeApplied":
      return (
        <div role="gridcell" className="read-only">
          <span>
            {totals.internalTarget &&
            Number.isFinite(totals.cumulativeAppliedNominal)
              ? formatPercentValue(
                  Number(
                    totals.cumulativeAppliedNominal / totals.internalTarget
                  )
                )
              : null}
          </span>
        </div>
      );
    case "pendingApproval":
      return (
        <div role="gridcell" className="read-only">
          <span>
            {Number.isFinite(totals.internalTarget) &&
              Number.isFinite(totals.clientAssessed) &&
              formatMoneyValue(totals.internalTarget - totals.clientAssessed)}
          </span>
        </div>
      );
    case "totalCost":
      return (
        <div role="gridcell" className="read-only">
          <span>{formatMoneyValue(totalCost)}</span>
        </div>
      );
    case "profitLoss":
      return (
        <div role="gridcell" className="read-only">
          <span>{formatMoneyValue(profitLoss)}</span>
        </div>
      );
    case "overheadProfit":
      return (
        <div role="gridcell" className="read-only">
          <span>
            {Number.isFinite(totals.costGna) &&
              formatPercentValue(
                (totals.costGna + profitLoss) / (totalCost - totals.costGna)
              )}
          </span>
        </div>
      );

    case "paymentReference":
    case "includedInReports":
      return <div role="gridcell" className="read-only"></div>;
    default:
      return (
        <div role="gridcell" className="read-only">
          <span>
            {Number.isFinite(totals[fieldName])
              ? formatMoneyValue(
                  Number((totals[fieldName] as number).toFixed(2))
                )
              : null}
          </span>
        </div>
      );
  }
};

interface ValueRegisterFooterRowProps {
  valueRegisterList: VocValueRegister[];
  columns: ValueRegisterColumn[];
  totalsUpdateArgs: {
    newValue: number | string | boolean;
    prevValue: number | string | boolean;
    fieldName: keyof VocValueRegister;
    valueRegister: VocValueRegister;
  };
}

const sumTotalFields: (keyof VocValueRegister)[] = [
  "submittedValue",
  "internalTarget",
  "clientAssessed",
  "cumulativeAppliedNominal",
  "cumulativeValueCertified",
  "directCost",
  "indirectCost",
  "costCloseOut",
  "costGna",
  "eisA2A6",
  "eisA8A14",
];

const ValueRegisterFooterRow: React.FC<ValueRegisterFooterRowProps> = ({
  valueRegisterList,
  columns,
  totalsUpdateArgs,
}) => {
  const { t } = useTranslation();
  const [totals, setTotals] = useState<Partial<VocValueRegister>>(() => {
    const initState: Partial<VocValueRegister> = {};
    valueRegisterList.forEach((data) => {
      sumTotalFields.forEach((field) => {
        if (!initState[field]) {
          (initState[field] as number) = 0;
        }
        (initState[field] as number) += data[field] as number;
      });
    });
    return initState;
  });

  const updateTotals = (
    newValue: number | string | boolean,
    prevValue: number | string | boolean,
    fieldName: keyof VocValueRegister,
    valueRegister: VocValueRegister
  ) => {
    if (fieldName === "recovery") {
      if (valueRegister.submittedValue) {
        setTotals((prevState) => {
          const internalTargetChanged =
            valueRegister.submittedValue *
            ((newValue as number) - (prevValue as number));
          return {
            ...prevState,
            internalTarget: internalTargetChanged + prevState.internalTarget,
            eisA8A14: valueRegister.includedInReports
              ? (prevState.eisA8A14 || 0) + internalTargetChanged
              : null,
          };
        });
      }
    } else if (fieldName === "internalTarget") {
      setTotals((prevState) => {
        const internalTargetChanged =
          (newValue as number) - (prevValue as number);
        return {
          ...prevState,
          internalTarget: internalTargetChanged + prevState.internalTarget,
          eisA8A14: valueRegister.includedInReports
            ? (prevState.eisA8A14 || 0) + internalTargetChanged
            : prevState.eisA8A14,
        };
      });
    } else if (fieldName === "cumulativeApplied") {
      if (valueRegister.internalTarget) {
        setTotals((prevState) => {
          const cumulativeAppliedNominal =
            valueRegister.internalTarget *
              ((newValue as number) - (prevValue as number)) +
            prevState.cumulativeAppliedNominal;
          return {
            ...prevState,
            cumulativeAppliedNominal,
          };
        });
      }
    } else if (fieldName === "includedInReports") {
      if (newValue === false) {
        setTotals((prevState) => {
          return {
            ...prevState,
            eisA2A6: (prevState.eisA2A6 || 0) - (valueRegister.eisA2A6 || 0),
            eisA8A14: (prevState.eisA8A14 || 0) - (valueRegister.eisA8A14 || 0),
          };
        });
      } else {
        setTotals((prevState) => {
          return {
            ...prevState,
            eisA8A14:
              (prevState.eisA8A14 || 0) + (valueRegister.internalTarget || 0),
          };
        });
      }
    } else if (fieldName === "eisA2A6") {
      setTotals((prevState) => {
        return {
          ...prevState,
          eisA2A6:
            (prevState.eisA2A6 || 0) +
            (newValue as number) -
            (prevValue as number),
          eisA8A14:
            (prevState.eisA8A14 || 0) +
            (prevValue as number) -
            (newValue as number),
        };
      });
    } else if (Number.isFinite(newValue) || Number.isFinite(prevValue)) {
      setTotals((prevState) => ({
        ...prevState,
        [fieldName]:
          (prevState[fieldName] as number) +
          ((newValue as number) - (prevValue as number)),
      }));
    }
  };

  useEffect(() => {
    if (totalsUpdateArgs) {
      updateTotals(
        totalsUpdateArgs.newValue,
        totalsUpdateArgs.prevValue,
        totalsUpdateArgs.fieldName,
        totalsUpdateArgs.valueRegister
      );
    }
  }, [totalsUpdateArgs]);

  return (
    <div role="row" className="voc-value-register-footer">
      <div role="gridcell" className="read-only">
        <span>
          {`${t("voc.common.total")} - ${valueRegisterList.length} ${t(
            "voc.common.event",
            { count: valueRegisterList.length }
          )}`}
        </span>
      </div>
      <div role="gridcell" className="read-only"></div>
      <div role="gridcell" className="read-only title"></div>
      {columns.map((col) => (
        <React.Fragment key={col.fieldName}>
          {getColumnContent(col.fieldName, totals)}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ValueRegisterFooterRow;
