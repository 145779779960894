import i18n from "../i18n/i18n";
import { hasValue } from "./has-value";

//For Value register input money
export const getNumberFromFormatValue = (
  value: string | null,
  lang: string
) => {
  if (!value) {
    return null;
  }

  switch (lang) {
    case "de":
    case "es":
      return formatEUNumberString(value);
    case "en":
    case "zh-hk":
    default:
      return formatUSNumberString(value);
  }
};

export function formatUSNumberString(value: string) {
  const inputNumVal = value.replace(/[^0-9.-]/g, "");
  if (inputNumVal && inputNumVal.trim()) {
    const num = Number(inputNumVal);
    if (Number.isFinite(num)) {
      return num;
    }
  }

  return null;
}

export function formatEUNumberString(value: string) {
  const inputNumVal = value.replace(/[^0-9,-]/g, "");
  if (inputNumVal && inputNumVal.trim()) {
    const num = Number(inputNumVal.replace(/,/g, "."));
    if (Number.isFinite(num)) {
      return num;
    }
  }

  return null;
}

//Todo: change to template string
export function formatMoneyValue(val: number, prefix: string = "$") {
  if (!Number.isFinite(val)) {
    return "";
  } else {
    const valFixed = val.toFixed(2);
    const sVal = formatNumberWithComma(
      Number(valFixed.split(".")[0]).toString(),
      prefix
    );
    if (["de", "es"].includes(i18n.language)) {
      return (
        (val < 0 ? sVal.replace("-", `-${prefix}`) : prefix + sVal) +
        "," +
        valFixed.split(".")[1]
      );
    } else {
      return (
        (val < 0 ? sVal.replace("-", `-${prefix}`) : prefix + sVal) +
        "." +
        valFixed.split(".")[1]
      );
    }
  }
}

//Todo: change to template string
export function formatPercentValue(val: number, suffix: string = "%") {
  if (!Number.isFinite(val)) {
    return "";
  } else {
    const convertVal = val * 100;
    const valFixed = convertVal.toFixed(2);
    const sVal = formatNumberWithComma(
      Number(valFixed.split(".")[0]).toString(),
      ""
    );
    if (["de", "es"].includes(i18n.language)) {
      return sVal + "," + valFixed.split(".")[1] + suffix;
    } else {
      return sVal + "." + valFixed.split(".")[1] + suffix;
    }
  }
}

//Todo: change to template string
export function formatNumberWithComma(val: string, prefix: string) {
  // format number 1000000 to 1,234,567
  const negative = val.startsWith("-") || val.startsWith(prefix + "-");

  if (["de", "es"].includes(i18n.language)) {
    return (
      (negative ? "-" : "") +
      val.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    );
  } else {
    return (
      (negative ? "-" : "") +
      val.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  }
}

export function isNumericString(s: string) {
  return /^\d*\.?\d*$/.test(s);
}

export function isIntegerString(s: string) {
  return /^-?\d+$/.test(s);
}

export function escapeRegex(s: string) {
  // eslint-disable-next-line no-useless-escape
  return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}

export function formatIdField(id: number, prefix: string) {
  return id ? `${prefix}-${id.toString().padStart(5, "0")}` : "";
}

export function reviveJsonDateTime(key: any, value: any): any {
  if (typeof value === "string") {
    const d = mapJsonDateTime(value);
    if (d) {
      return d;
    }
  }
  return value;
}

export function reviveJsonDateTimeWithTimezoneAdjustment(
  timezoneHourAdjustment: number
): any {
  const reviveJsonDateTime = (key: any, value: any): any => {
    if (typeof value === "string") {
      const d = mapJsonDateTime(value);
      if (d) {
        return new Date(
          d.getTime() + Math.round(timezoneHourAdjustment * 3600000)
        );
      }
    }
    return value;
  };

  return reviveJsonDateTime;
}

export function formatBoolean(value?: boolean) {
  return value === true ? "Yes" : value === false ? "No" : "";
}

export function mapJsonDateTime(value: string) {
  if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(value)) {
    return new Date(value);
  }
  return undefined;
}

export function buildQueryString(
  params: {
    [key: string]: string | object;
  },
  startWith: string = "?"
): string {
  let query = "";

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) {
      const convertValue = encodeURIComponent(
        typeof value === "object" ? JSON.stringify(value) : value
      );
      const paramsString = `${key}=${convertValue}`;
      query += `${query !== "" ? "&" : ""}${paramsString}`;
    }
  });

  return query && startWith + query; // return empty string if query is empty
}

export const convertUrl = (link: string | Record<string, string>) => {
  if (typeof link !== "string") {
    return hasValue(link?.url) ? link.url : "";
  }

  if (link && link.toLowerCase) {
    const linkRefined = link.toLowerCase();
    if (
      linkRefined.startsWith("http://") ||
      linkRefined.startsWith("https://")
    ) {
      return link;
    }

    // regex mtach for domain - https://stackoverflow.com/questions/10306690/what-is-a-regular-expression-which-will-match-a-valid-domain-name-without-a-subd
    if (
      linkRefined
        .replace(/^www\./, "")
        .match(
          /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})(\/|$)/
        )
    ) {
      return `http://${link}`;
    }
  }
  return "";
};
