import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AppButton from "../../../../app/components/app-button";
import AddClaim from "./";

import "./AddClaimButton.scss";

interface AddClaimButtonProps {
  eventId: string;
  onRefreshData: () => void;
}

const AddClaimButton: React.FC<AddClaimButtonProps> = ({
  onRefreshData,
  eventId,
}) => {
  const [showAddClaimModal, setShowAddClaimModal] = useState(false);
  const handleModalClose = () => setShowAddClaimModal(false);
  const { t } = useTranslation();
  return (
    <>
      <AppButton
        className="voc-event-claim-add"
        onClick={() => setShowAddClaimModal(true)}
      >
        {t("voc.common.addClaim")}
      </AppButton>
      {showAddClaimModal && (
        <AddClaim
          eventId={eventId}
          onRefreshData={onRefreshData}
          showAddClaimModal={showAddClaimModal}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default AddClaimButton;
