import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Nxp404 } from "@nexploretechnology/nxp-ui";

import { accessControlRoutes } from "../../../access-control/access-control-routes";
import { compensationEventRoutes } from "../../../compensation-events/compensation-event-routes";
import { customizationRoutes } from "../../../customization/customization-routes";
import { demoRoutes } from "../../../demo/demo-routes";
import { feedbackRoutes } from "../../../feedback/feedback-routes";
import { librariesRoutes } from "../../../libraries/libraries-routes";
import { reportsRoutes } from "../../../reports/reports-routes";
import { siteEventRoutes } from "../../../site-events/site-event-routes";
import TranslationCheck from "../../pages/translation-check/TranslationCheck";
import AppProtectedPage from "./AppProtectedPage";

interface AppRouterProps {}

const AppRouter: React.FC<AppRouterProps> = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate to="/entities/7ab454f6-9b35-4181-96f0-14f9feee1993/events" />
          }
        />
        {customizationRoutes}
        {compensationEventRoutes}
        {siteEventRoutes}
        {demoRoutes}
        {librariesRoutes}
        {reportsRoutes}
        {accessControlRoutes}
        {feedbackRoutes}
        <Route
          path="/translation-check"
          element={<AppProtectedPage component={<TranslationCheck />} />}
        />
        <Route
          path="/*"
          element={<AppProtectedPage component={<Nxp404 />} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
