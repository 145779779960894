import { parseAbbrValue } from "./parseAbbrValue";
import { removeSeparators } from "./removeSeparators";
import { removeInvalidChars } from "./removeInvalidChars";
import { escapeRegExp } from "./escapeRegExp";
import { removeDirtyAbbreviation } from "./removeDirtyAbbreviation";

export interface CleanValueOptions {
  value: string;
  decimalSeparator?: string;
  groupSeparator?: string;
  allowDecimals?: boolean;
  decimalsLimit?: number;
  allowNegativeValue?: boolean;
  disableAbbreviations?: boolean;
  prefix?: string;
}

/**
 * Remove prefix, separators and extra decimals from value
 */
export const cleanValue = ({
  value,
  groupSeparator = ",",
  decimalSeparator = ".",
  allowDecimals = true,
  decimalsLimit = 2,
  allowNegativeValue = true,
  disableAbbreviations = false,
  prefix = "",
}: CleanValueOptions): string => {
  if (value === "-") {
    return value;
  }

  const abbreviations = disableAbbreviations ? [] : ["k", "m", "b"];
  const isNegative = new RegExp(
    `^\\d?-${prefix ? `${escapeRegExp(prefix)}?` : ""}\\d`
  ).test(value);

  const [prefixWithValue, preValue] =
    RegExp(`(\\d+)-?${escapeRegExp(prefix)}`).exec(value) || [];
  const withoutPrefix = prefix
    ? value.replace(prefixWithValue, "").concat(preValue)
    : value;
  const withoutSeparators = removeSeparators(withoutPrefix, groupSeparator);
  const withoutDirtyAbbreviations = removeDirtyAbbreviation(withoutSeparators, [
    ...abbreviations,
  ]);

  const withoutInvalidChars = removeInvalidChars(withoutDirtyAbbreviations, [
    groupSeparator,
    decimalSeparator,
    ...abbreviations,
  ]);

  let valueOnly = withoutInvalidChars;

  if (!disableAbbreviations) {
    // disallow letter without number
    if (
      abbreviations.some(
        (letter) => letter === withoutInvalidChars.toLowerCase()
      )
    ) {
      return "";
    }
    const parsed = parseAbbrValue(withoutInvalidChars, decimalSeparator);
    if (parsed) {
      valueOnly = String(parsed);
    }
  }

  const includeNegative = isNegative && allowNegativeValue ? "-" : "";

  if (decimalSeparator && valueOnly.includes(decimalSeparator)) {
    const [int, decimals] = withoutInvalidChars.split(decimalSeparator);
    const trimmedDecimals =
      decimalsLimit && decimals ? decimals.slice(0, decimalsLimit) : decimals;
    const includeDecimals = allowDecimals
      ? `${decimalSeparator}${trimmedDecimals}`
      : "";

    return `${includeNegative}${int}${includeDecimals}`;
  }

  return `${includeNegative}${valueOnly}`;
};
