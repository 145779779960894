import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { isNull } from "lodash";
import {
  BaseButton,
  IPivotItemProps,
  Pivot,
  PivotItem,
  PivotLinkFormat,
} from "office-ui-fabric-react";

import AppHeader from "../../../app/components/app-header";
import useAppContext from "../../../app/hooks/useAppContext";
import {
  VocCustomUiLayoutItemType,
  VocEvent,
  VocEventType,
  VocScreenNameEnums,
} from "../../../app/types";
import {
  getCompensationEventLogs,
  undoCompensationEventLogs,
} from "../../compensation-event-services";
import ChatsTabContainer from "../../components/chatsTab/ChatsTabContainer";
import ClaimsTabContainer from "../../components/claims-tab/ClaimsTabContainer";
import LogsTabContainer from "../../components/logs-tab/LogsTabContainer";
import NotificationsTabPage from "../../components/notifications-tab/NotificationsTabPage";
import PlanningTabContainer from "../../components/planning-tab/PlanningTabContainer";
import RecordsTab from "../../components/records-tab";
import SummaryTabContainer from "../../components/summary-tab/SummaryTabContainer";
import SupportingTab from "../../components/supporting-tab/SupportingTab";
import ValuesTab from "../../components/value-tab-beta/ValuesTab";
import VODetailsTabContainer from "../../components/vo-details-tab/VODetailsTabContainer";

interface EventDetailsLayoutProps {
  entityId: string;
  eventId: string;
  backHref?: string;
  event?: VocEvent;
  loading: boolean;
  error?: Error;
  onEventRefresh: () => void;
  nextEvent: string;
  prevEvent: string;
  preAndNextEventHandler: (eventId: string) => Promise<void>;
}
const EventDetailsLayout: React.FC<EventDetailsLayoutProps> = ({
  entityId,
  eventId,
  backHref,
  event,
  onEventRefresh,
  preAndNextEventHandler,
  nextEvent,
  prevEvent,
}) => {
  const { getCustomizedScreen, customizedScreens: screenData } =
    useAppContext();
  const checkVoTabDisplay = () => {
    const voScreen = getCustomizedScreen(
      VocScreenNameEnums.COMPENSATION_EVENT_VO_DETAIL_SCREEN
    );
    if (!voScreen) {
      return false;
    }

    if (
      !voScreen.layout.find(
        (item) =>
          item.type === VocCustomUiLayoutItemType.LayoutGroup && item.display
      )
    ) {
      return false;
    }

    return true;
  };
  const [lastHeader, setLastHeader] = React.useState<
    { props: IPivotItemProps } | undefined
  >(undefined);

  const navigate = useNavigate();
  const [quickNavIndent, setQuickNavIndent] = React.useState<boolean>(true);
  React.useEffect(() => {
    if (lastHeader) {
      if (
        [
          t("voc.eventPage.tabSummary"),
          t("voc.eventPage.tabNotifications"),
          t("voc.eventPage.tabRecords"),
          t("voc.eventPage.tabSupporting"),
          t("voc.eventPage.tabSubcontractors"),
          t("voc.eventPage.tabClaims"),
        ].includes(lastHeader.props.headerText)
      ) {
        setQuickNavIndent(true);
      } else if (
        [
          t("voc.eventPage.tabVODetails"),
          t("voc.eventPage.tabValues"),
          t("voc.eventPage.tabPlanning"),
          t("voc.eventPage.tabChats"),
          t("voc.eventPage.tabLogs"),
        ].includes(lastHeader.props.headerText)
      ) {
        setQuickNavIndent(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastHeader]);
  const { t } = useTranslation();

  return (
    <div className="voc-event-page">
      <AppHeader
        text={event?.eventTitle ?? t("voc.common.ce")}
        subtext={event?.serial}
        backHref={backHref}
      >
        <div
          className={"quick-nav-flexbox" + (quickNavIndent ? " indent" : "")}
        >
          {prevEvent && !isNull(prevEvent) ? (
            <BaseButton
              className="header-back-button"
              onClick={() =>
                preAndNextEventHandler(prevEvent).finally(() =>
                  navigate(`./../../compensation-events/${prevEvent}`)
                )
              }
            >
              <span className="arrow">◀</span>{" "}
              {t("voc.siteEventDetails.prevSiteEvent")}
            </BaseButton>
          ) : null}
          {nextEvent && !isNull(nextEvent) ? (
            <BaseButton
              className="header-back-button"
              onClick={() =>
                preAndNextEventHandler(nextEvent).finally(() =>
                  navigate(`./../../compensation-events/${nextEvent}`)
                )
              }
            >
              {t("voc.siteEventDetails.nextSiteEvent")}{" "}
              <span className="arrow">▶</span>
            </BaseButton>
          ) : null}
        </div>
      </AppHeader>
      <Pivot
        linkFormat={PivotLinkFormat.tabs}
        className={
          lastHeader?.props?.headerText === "Chats"
            ? "chats-tabs-control"
            : "tabs-control"
        }
        onLinkClick={setLastHeader}
      >
        <PivotItem headerText={t("voc.eventPage.tabSummary")}>
          {event && (
            <SummaryTabContainer
              onEventRefresh={onEventRefresh}
              event={event}
              customUi={getCustomizedScreen(
                VocScreenNameEnums.COMPENSATION_EVENT_SUMMARY_SCREEN
              )}
            />
          )}
        </PivotItem>
        <PivotItem headerText={t("voc.eventPage.tabNotifications")}>
          <NotificationsTabPage eventId={eventId} />
        </PivotItem>
        <PivotItem headerText={t("voc.eventPage.tabRecords")}>
          {event && (
            <RecordsTab
              onEventRefresh={onEventRefresh}
              event={event}
              entityId={entityId}
              eventId={eventId}
              contemporaryRecordRequired={event.contemporaryRecordRequired}
            />
          )}
        </PivotItem>
        {(event?.eventType === VocEventType.VO ||
          event?.eventType === VocEventType.VO_And_Claim) &&
          screenData &&
          checkVoTabDisplay() && (
            <PivotItem headerText={t("voc.eventPage.tabVODetails")}>
              <VODetailsTabContainer
                eventId={eventId}
                onEventRefresh={onEventRefresh}
                customUi={getCustomizedScreen(
                  VocScreenNameEnums.COMPENSATION_EVENT_VO_DETAIL_SCREEN
                )}
              />
            </PivotItem>
          )}
        {(event?.eventType === VocEventType.Claim ||
          event?.eventType === VocEventType.VO_And_Claim) && (
          <PivotItem headerText={t("voc.eventPage.tabClaims")}>
            <ClaimsTabContainer event={event} onEventRefresh={onEventRefresh} />
          </PivotItem>
        )}
        <PivotItem headerText={t("voc.eventPage.tabValues")}>
          <ValuesTab />
        </PivotItem>
        <PivotItem headerText={t("voc.eventPage.tabPlanning")}>
          <PlanningTabContainer entityId={entityId} eventId={eventId} />
        </PivotItem>
        <PivotItem headerText={t("voc.eventPage.tabSupporting")}>
          <SupportingTab eventDetail={event} />
        </PivotItem>
        <PivotItem headerText={t("voc.eventPage.tabChats")}>
          <ChatsTabContainer></ChatsTabContainer>
        </PivotItem>
        <PivotItem headerText={t("voc.eventPage.tabLogs")}>
          <LogsTabContainer
            permissionCode="compensation_event"
            id={eventId}
            onFetch={getCompensationEventLogs}
            onUndo={undoCompensationEventLogs}
            onEventRefresh={onEventRefresh}
          />
        </PivotItem>
      </Pivot>
    </div>
  );
};
export default EventDetailsLayout;
