import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { QuerySettings } from "../../../app/components/app-data-table";
import { FilterOption } from "../../../app/components/app-filter";
import useAppContext from "../../../app/hooks/useAppContext";
import { useAsync } from "../../../app/hooks/useAsync";
import { getExcel } from "../../../app/services/app";
import { VocEventListItem, VocScreenNameEnums } from "../../../app/types";
import { REGISTER_LAZY_LOAD_SIZE } from "../../../app/utils/const";
import { shallowEqual } from "../../../app/utils/object";
import { searchCompensationEvents } from "../../compensation-event-services";
import EventListLayout from "./EventListLayout";

const EventListContainer: React.FC = () => {
  const { activeEntity, serviceConfig, errorHandler, getCustomizedScreen } =
    useAppContext();

  const navigate = useNavigate();

  const [listingState, setListingState] = useState<{
    itemList?: VocEventListItem[];
    filterOptions?: FilterOption[];
    querySettings?: QuerySettings;
  }>({
    filterOptions: [],
    querySettings: {
      pageSize: 0, // pageSize = 0 for lazy loading
      currentPage: 1,
      search: "",
      sortColumns: [],
    },
  });

  const [eventListAsyncResult, setEventListRequest] = useAsync<{
    results: VocEventListItem[];
    pagination: { countAll: number };
    totals?: VocEventListItem;
  }>(undefined, {
    onSuccess: (data) => {
      setListingState((prevState) => {
        if (
          prevState.querySettings.currentPage > 1 &&
          prevState.querySettings.pageSize === 0
        ) {
          return {
            ...prevState,
            itemList: [...prevState.itemList, ...data.results],
          };
        } else {
          setTotalValues(data.totals);
          return { ...prevState, itemList: [...data.results] };
        }
      });
    },
    onError: (ex) => {
      errorHandler(ex, "getting compensation events");
    },
  });

  const fetchSiteEventList = useCallback(
    (querySettings: QuerySettings, filterOptions: FilterOption[]) => {
      setEventListRequest(() =>
        searchCompensationEvents(
          serviceConfig,
          {
            perPage: querySettings.pageSize,
            page: querySettings.currentPage.toString(),
            sort: querySettings.sortColumns.length
              ? querySettings.sortColumns[0].fieldName
              : undefined,
            order: querySettings.sortColumns.length
              ? querySettings.sortColumns[0].order
              : undefined,
            search: querySettings.search,
          },
          filterOptions
        )
      );
    },
    [serviceConfig, setEventListRequest]
  );

  const setListingStateAndFetch = useCallback(
    (callback: (prevState: typeof listingState) => typeof listingState) => {
      setListingState((prevState) => {
        const nextState = callback(prevState);
        fetchSiteEventList(nextState.querySettings, nextState.filterOptions);
        return nextState;
      });
    },
    [fetchSiteEventList]
  );

  useLayoutEffect(() => {
    if (
      listingState.itemList === undefined &&
      !eventListAsyncResult.loading &&
      activeEntity?.id
    ) {
      if (window.history.state) {
        setListingStateAndFetch((prevState) => {
          const nextState = { ...prevState };
          nextState.itemList = [];
          if (window.history.state.querySettings) {
            nextState.querySettings = {
              ...window.history.state.querySettings,
            };
          }
          if (window.history.state.filterOptions) {
            nextState.filterOptions = [...window.history.state.filterOptions];
          }
          return nextState;
        });
      } else {
        setListingStateAndFetch((prevState) => ({
          ...prevState,
          itemList: [],
        }));
      }
    }
  }, [
    activeEntity?.id,
    eventListAsyncResult.loading,
    listingState.itemList,
    setListingStateAndFetch,
  ]);

  const [totalValues, setTotalValues] = useState<VocEventListItem>();

  useEffect(() => {
    window.history.replaceState(
      {
        querySettings: listingState.querySettings,
        filterOptions: listingState.filterOptions,
      },
      document.title
    );
  }, [listingState.filterOptions, listingState.querySettings]);

  const handleDataTableQuery = useCallback(
    (querySettings: QuerySettings) => {
      // avoid unnecessary rendering
      if (!shallowEqual(listingState.querySettings, querySettings)) {
        setListingStateAndFetch((prevState) => ({
          ...prevState,
          querySettings,
        }));
      }
    },
    [listingState.querySettings, setListingStateAndFetch]
  );

  const handleRowClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, dataItem: VocEventListItem) => {
      navigate(`./${dataItem.id}`);
    },
    [navigate]
  );

  const handleFilterChange = useCallback(
    (filterOptions: FilterOption[]) => {
      setListingStateAndFetch((prevState) => ({ ...prevState, filterOptions })); // [...newFilterOptions].map((option) => ({ ...option })));
    },
    [setListingStateAndFetch]
  );

  const handleDataTableFooterAggregate = useCallback(
    (
      rowDataItem: VocEventListItem,
      footerDataItem: VocEventListItem,
      isLastRow: boolean
    ) => {
      if (
        isLastRow &&
        listingState.querySettings.pageSize === 0 &&
        totalValues
      ) {
        footerDataItem.daysOfOutstandingRecords =
          totalValues.daysOfOutstandingRecords;

        footerDataItem.internalValue = totalValues.internalValue;

        footerDataItem.clientAssessed = totalValues.clientAssessed;

        footerDataItem.pendingApproval = totalValues.pendingApproval;

        footerDataItem.internalProgressValue =
          totalValues.internalProgressValue;

        footerDataItem.cumulativeValueCertified =
          totalValues.cumulativeValueCertified;

        footerDataItem.physicalProgress = totalValues.physicalProgress;

        footerDataItem.internalTarget = totalValues.internalTarget;

        footerDataItem.unapprovedAmount = totalValues.unapprovedAmount;

        footerDataItem.targetDays = totalValues.targetDays;

        footerDataItem.recovery = totalValues.recovery;
      }
      if (listingState.querySettings.pageSize > 0) {
        if (Number.isFinite(rowDataItem.daysOfOutstandingRecords)) {
          footerDataItem.daysOfOutstandingRecords =
            (footerDataItem.daysOfOutstandingRecords || 0) +
            rowDataItem.daysOfOutstandingRecords;
        }

        if (Number.isFinite(rowDataItem.internalValue)) {
          footerDataItem.internalValue =
            (footerDataItem.internalValue || 0) + rowDataItem.internalValue;
        }

        if (Number.isFinite(rowDataItem.clientAssessed)) {
          footerDataItem.clientAssessed =
            (footerDataItem.clientAssessed || 0) + rowDataItem.clientAssessed;
        }

        if (Number.isFinite(rowDataItem.pendingApproval)) {
          footerDataItem.pendingApproval =
            (footerDataItem.pendingApproval || 0) + rowDataItem.pendingApproval;
        }

        if (Number.isFinite(rowDataItem.internalProgressValue)) {
          footerDataItem.internalProgressValue =
            (footerDataItem.internalProgressValue || 0) +
            rowDataItem.internalProgressValue;
        }

        if (Number.isFinite(rowDataItem.cumulativeValueCertified)) {
          footerDataItem.cumulativeValueCertified =
            (footerDataItem.cumulativeValueCertified || 0) +
            rowDataItem.cumulativeValueCertified;
        }

        if (Number.isFinite(rowDataItem.internalTarget)) {
          footerDataItem.internalTarget =
            (footerDataItem.internalTarget || 0) + rowDataItem.internalTarget;
        }

        if (Number.isFinite(rowDataItem.unapprovedAmount)) {
          footerDataItem.unapprovedAmount =
            (footerDataItem.unapprovedAmount || 0) +
            rowDataItem.unapprovedAmount;
        }

        if (Number.isFinite(rowDataItem.targetDays)) {
          footerDataItem.targetDays =
            (footerDataItem.targetDays || 0) + rowDataItem.targetDays;
        }

        if (isLastRow) {
          footerDataItem.recovery = footerDataItem.internalValue
            ? (footerDataItem.internalTarget || 0) /
              footerDataItem.internalValue
            : null;
        }
      }
    },
    [listingState.querySettings.pageSize, totalValues]
  );

  const handleLazyLoad = () => {
    setListingStateAndFetch((prevState) => {
      if (
        eventListAsyncResult?.data?.pagination.countAll >
        REGISTER_LAZY_LOAD_SIZE * prevState.querySettings.currentPage
      ) {
        return {
          ...prevState,
          querySettings: {
            ...prevState.querySettings,
            currentPage: prevState.querySettings.currentPage + 1,
          },
        };
      } else {
        return prevState;
      }
    });
  };

  const customUi = getCustomizedScreen(
    VocScreenNameEnums.COMPENSATION_EVENT_REGISTER_SCREEN
  );

  const handleExportExcel = useCallback(() => {
    getExcel(customUi.screen.id, serviceConfig);
  }, [customUi.screen.id, serviceConfig]);

  return (
    customUi !== undefined && (
      <div className="event-list">
        <EventListLayout
          customUi={customUi}
          entityId={activeEntity.id}
          querySettings={listingState.querySettings}
          totalRecordCount={eventListAsyncResult?.data?.pagination.countAll}
          loading={eventListAsyncResult?.loading}
          dataSource={listingState.itemList}
          filterOptions={listingState.filterOptions}
          onDataTableQuery={handleDataTableQuery}
          onDataTableRowClick={handleRowClick}
          onDataTableFooterAggregate={handleDataTableFooterAggregate}
          onFilterChange={handleFilterChange}
          onExportExcel={handleExportExcel}
          onLazyLoad={handleLazyLoad}
        />
      </div>
    )
  );
};

export default EventListContainer;
