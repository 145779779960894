import React from "react";

import "./AppFormField.scss";

export type AppFormFieldProps = {
  width?: number | string;
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "onChange" | "value" | "label" | "name"
>;

const AppFormField: React.FC<AppFormFieldProps> = ({
  width,
  style,
  className,
  ...restProps
}) => {
  // remove unused fields to suppress react warning, will work on a better solution
  const trimRestProps: { [key: string]: any } = { ...restProps };
  delete trimRestProps.fieldType;
  delete trimRestProps.fieldName;
  delete trimRestProps.contentForRead;
  delete trimRestProps.caption;
  delete trimRestProps.isRequired;
  delete trimRestProps.fillRow;
  return (
    <div
      style={{ width, ...style }}
      className={`app-form-field${className ? ` ${className}` : ""}`}
      {...trimRestProps}
    />
  );
};

export default AppFormField;
