import { TFunction } from "i18next";
import { Option } from "../../../../../app/components/app-field-block/AppFieldBlock";
import { DictionaryItem } from "../../../../../app/services/dictionary";
import {
  VocDocumentStatusType,
  VocEvent,
  VocRecordStatus,
  VocSiteEvent,
  VocSupportDocNotification,
  VocSupportDocument,
} from "../../../../../app/types";

export const relatedEventDisplay = (
  compEventSerial: string,
  compEventTitle: string,
  siteEventSerial: string,
  siteEventTitle: string
) => {
  const formatEvent = (serial: string, title: string) => {
    const combined = `${serial} ${title}`;
    return combined.length > 15 ? `${combined.substring(0, 15)}...` : combined;
  };

  return siteEventSerial
    ? formatEvent(siteEventSerial, siteEventTitle)
    : formatEvent(compEventSerial, compEventTitle);
};

export const getSiteRecordStatuses = (
  t: TFunction<"translation", undefined, "translation">
): Option[] => [
  {
    render: t("voc.common.pleaseSelect"),
    enumValue: "",
  },
  {
    render: t("voc.eventPage.supporting.recStatus.prepared"),
    enumValue: VocRecordStatus.Record_Prepared,
  },
  {
    render: t("voc.eventPage.supporting.recStatus.reviewed"),
    enumValue: VocRecordStatus.Record_Reviewed,
  },
  {
    render: t("voc.eventPage.supporting.recStatus.signedOff"),
    enumValue: VocRecordStatus.Record_Signed_Off_By_PD,
  },
  {
    render: t("voc.eventPage.supporting.recStatus.submittedToClient"),
    enumValue: VocRecordStatus.Record_Submitted_To_Client,
  },
  {
    render: t("voc.eventPage.supporting.recStatus.finalClaimSubmitted"),
    enumValue: VocRecordStatus.Final_Claim_Submitted,
  },
  {
    render: t("voc.eventPage.supporting.recStatus.noDisruption"),
    enumValue: VocRecordStatus.No_Disruption_Or_Delay,
  },
  {
    render: t("voc.eventPage.supporting.recStatus.outstanding"),
    enumValue: VocRecordStatus.Outstanding,
  },

  {
    render: t("voc.eventPage.supporting.recStatus.relatedSiteEvent"),
    enumValue: VocRecordStatus.Related_Site_Event,
  },
];

export const getSiteDocStatuses = (
  t: TFunction<"translation", undefined, "translation">
) => [
  {
    render: t("voc.eventPage.supporting.siteDocStatus.draft"),
    enumValue: VocDocumentStatusType.Draft,
  },
  {
    render: t("voc.eventPage.supporting.siteDocStatus.submitted"),
    enumValue: VocDocumentStatusType.Submitted,
  },
  {
    render: t("voc.eventPage.supporting.siteDocStatus.approved"),
    enumValue: VocDocumentStatusType.Approved,
  },
  {
    render: t("voc.eventPage.supporting.siteDocStatus.rejected"),
    enumValue: VocDocumentStatusType.Rejected,
  },
];

const deriveDocumentType = (type, stage) => {
  // Example logic to derive document type
  return `${type}-${stage}`;
};

const getNotificationSubject = (
  item: VocSupportDocNotification,
  t: TFunction<"translation", undefined, "translation">
) => {
  return `${t(`voc.eventPage.supporting.${item.stage}`)} (${t(
    `voc.eventPage.supporting.${item.type}`
  )})`;
};

export function getNotificationReceiverNameByKey(
  receiver: string,
  members: DictionaryItem[],
  language: string
) {
  if (!members || !receiver || !language) {
    return "";
  }

  const client = members.find((client) => client.key === receiver);

  if (!client) {
    return receiver;
  }

  const translation = client.translations.find(
    (lang) => lang.langKey === language
  );

  return translation?.value || receiver;
}

export const convertAttachments = (attachments) =>
  attachments.map((att) => ({
    id: att.id,
    fileName: att.fileName,
    mimeType: att.mimeType,
    url: att.url,
    deleted: att.deleted,
    createdOn: att.createdOn,
    updatedOn: att.updatedOn,
  }));

export const convertNotificationToCorrespondence = (
  notification: VocSupportDocNotification,
  sender: string,
  recipient: string,
  t: TFunction<"translation", undefined, "translation">,
  eventDetail: VocEvent | VocSiteEvent
) => ({
  id: notification.id,
  createdOn: notification.createdOn,
  updatedOn: notification.updatedOn,
  comment: notification.comment,
  documentType: deriveDocumentType(notification.type, notification.stage),
  details: {
    sender: sender,
    recipient: recipient,
    subject: getNotificationSubject(notification, t),
    documentDate: notification.requiredDate || notification.actualDate,
    documentType: deriveDocumentType(notification.type, notification.stage),
  },
  eventId: notification.eventId,
  entityId: notification.entityId,
  documentFiles: convertAttachments(notification.attachments),
  deleted: notification.deleted,
  deletedOn: notification.deletedOn,
  isNotification: true,
  compensationEventSerial: eventDetail.serial,
  compensationEventTitle: eventDetail.eventTitle,
  siteEventSerial: eventDetail.serial,
  siteEventTitle: eventDetail.siteEvents.subject,
});
export const isRowDisplayOnly = (
  record: VocSupportDocument,
  eventId: string | undefined
) => {
  return eventId && record.siteEventSerial && record.siteEventTitle;
};

export const getTooltipMessage = (
  record: VocSupportDocument,
  eventId: string,
  t: TFunction<"translation", undefined, "translation">
) => {
  if (record.isNotification) {
    return (
      t("voc.eventPage.supporting.readonlyNotification") ||
      "You can edit this document in Notifications Tab."
    );
  }
  if (record.isEventReferenceFiles) {
    return t("voc.eventPage.supporting.readonlySummary");
  }

  if (isRowDisplayOnly(record, eventId)) {
    return `${t("voc.eventPage.supporting.siteDocStatus.editInSiteEvent")} ${
      record.siteEventSerial
    }`;
  }
  return "";
};

export const convertEventReferenceFilesToOtherDocument = (
  eventReferenceFiles: VocSupportDocument[],
  eventDetail: VocEvent | VocSiteEvent,
  isSiteEvent: boolean
) => {
  return eventReferenceFiles?.map((file) => ({
    id: file.id,
    createdOn: file.createdOn,
    updatedOn: file.updatedOn,
    documentType: null,
    comment: file.comment,
    details: {
      toDate: file.toDate,
      subject: file.subject,
      fromDate: file.fromDate,
      documentType: null,
      link: file.link,
    },
    subject: file.subject,
    eventId: null,
    siteEventId: null,
    subcontractorId: null,
    deleted: false,
    deletedOn: null,
    documentFiles: isSiteEvent ? [file.documentFile] : file.documentFile,
    //in site event, documentFile will be return object,
    //but in compensation event, documentFiles will be return array
    compensationEventSerial: eventDetail.serial,
    compensationEventTitle: eventDetail.eventTitle,
    siteEventSerial: eventDetail.serial,
    siteEventTitle: eventDetail.subject,
    isEventReferenceFiles: true,
  }));
};
