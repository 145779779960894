import {
  DictionaryEntry,
  UserInfo,
  VocClaim,
  VocEventRelatedClaim,
  VocEventRelatedVO,
  VocEventType,
  VocRecordStatus,
} from "./";
import { VocSupportDocument } from "./support";

export interface VocEvent {
  eventId: number | string;
  id: string;
  eventType: VocEventType;
  eventTitle?: string;
  awarded?: boolean;
  reference?: string;
  contemporaryRecordRequired?: boolean;
  commercialOwner?: UserInfo;
  recordOwner?: UserInfo;
  recordSupporter?: UserInfo;
  daysOfOutstandingRecords?: number;
  delayDisruptiveEffect: string[];
  dateOfAwareness?: Date;
  dateOfCessation?: Date;
  statusWithClient?: string;
  siteEvents?: BatchUpdateEvent[];
  eventSource?: string;
  eventSourceType?: string;
  customization?: Record<string, any>;
  instructionOwner?: string;
  instructionOwnerOptional?: string;
  instructionEmpowered?: boolean;
  locations?: string[];
  otherClaimReferences: string[]; // just for patch operation
  relatedClaimsDigest: VocEventRelatedClaim[];
  relatedVoDigest: VocEventRelatedVO[];
  otherVoReferences: string[]; // just for patch operation
  narrative?: string;
  claimDetails?: VocClaim;
  entityId: string;
  createdOn: Date;
  updatedOn: Date;
  records: VocEventStatusRecord[];
  recordList?: VocEventStatusRecord[];
  supportDocuments: VocSupportDocument[];
  workType?: string;
  serial?: string;
  tags?: string[];
  next?: string;
  last?: string;
  subject?: string;
}

export interface BatchUpdateEvent {
  id: string;
  serial: string;
  title: string;
  eventType?: string;
  subject?: string;
}
export interface VocEventShort {
  id: number;
  eventTitle: string;
  serial?: string;
}

export interface VocEventSummaryNotification {
  sendToAll: boolean;
  sendToCoOnly: boolean;
  sendToRoAndRsOnly: boolean;
  sendToOthers: string[];
  message: string;
}

export interface VocEventFile {
  assetId: string;
  fileName: string;
  mimeType: string;
  url: string;
  id?: number;
}

export interface VocEventFileWrapper {
  id?: number;
  file: VocEventFile;
}

export interface VocEventStatusRecord {
  recordDate: Date;
  status: VocRecordStatus;
  remarks: string;
  siteEventId?: string;
  siteEventSerial?: string;
  siteEventTitle?: string;
}

export type VocEventCreation = Pick<
  VocEvent,
  | "eventTitle"
  | "awarded"
  | "reference"
  | "contemporaryRecordRequired"
  | "narrative"
> & {
  eventType: string;
  eventSource: string;
  eventSourceType: string;
};

export type VocEventListItem = {
  eventTypeSerial: string;
  claimSerial?: string;
  eventReference?: string;
  clientVoRef?: string;
  advanceInstruction?: DictionaryEntry;

  internalValue?: number;
  clientAssessed?: number;
  pendingApproval?: number;
  freeColumn?: string;

  physicalProgress?: number;
  internalProgressValue?: number;
  cumulativeValueCertified?: number;
  recovery?: number;
  internalTarget?: number;
  unapprovedAmount?: number;
  actionDescription?: string;
  actionBy?: UserInfo;
  actionDueDate?: Date;
  eotReference?: string;
  targetDays?: number;
  agreeQuantumQty?: boolean;
  includedInReports?: boolean;
  statusWithClient?: string;
  eventType?: string;
  eventSource?: string;
  eventSourceType?: string;
} & Pick<
  VocEvent,
  | "id"
  | "serial"
  | "eventTitle"
  | "awarded"
  | "reference"
  | "contemporaryRecordRequired"
  | "commercialOwner"
  | "recordOwner"
  | "recordSupporter"
  | "daysOfOutstandingRecords"
  | "dateOfAwareness"
  | "dateOfCessation"
  | "entityId"
  | "createdOn"
  | "updatedOn"
  | "customization"
>;

export enum VocDocumentStatusType {
  Draft = "SUPPORT_DOC_STATUS_01",
  Submitted = "SUPPORT_DOC_STATUS_02",
  Approved = "SUPPORT_DOC_STATUS_03",
  Rejected = "SUPPORT_DOC_STATUS_04",
}

export interface VocRecordBatchUpdateData {
  fromDate: Date;
  toDate: Date;
  status: VocRecordStatus;
  serials: string[];
}

export interface DeletedRecord {
  id: number | string;
  isDeleted?: boolean;
  label?: string;
  value?: string;
  firstName?: string;
  lastName?: string;
  fromEffectiveDate?: Date;
  effectiveDate?: Date;
  contractualDate?: Date;
  rangeType?: string;
  fromValue?: number;
  toValue?: number;
}

export interface VocGetRecentMessagesResponse {
  eventOwnersMessage: VocReminder;
}

export interface VocReminder {
  source: string;
  dispatchedOn: Date;
  receipt: string;
  entityId: string;
  message: VocMessage;
  updatedOn: Date;
  receivers: UserInfo[];
  createdOn: Date;
  template: string;
  sourceId: number;
  id: number;
}

export interface VocMessage {
  commercialOwner: string;
  entity: string;
  id: number;
  eventType: string;
  message: string;
  recordOwner: string;
  recordSupporter: string;
  sender: string;
  serial: string;
  title: string;
  notificationType: string;
  requiredDate: Date;
}
