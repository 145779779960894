import React from "react";
import { createUseStyles } from "react-jss";

import { UserOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import clsx from "clsx";

import { CPCSUser } from "../../services/app";

const useStyles = createUseStyles((theme) => ({
  appUserProfile: {
    margin: theme.spacing(1, 2),
  },
  userName: {
    fontSize: theme.fontSize.h2,
    margin: 0,
  },
  userInfo: {
    margin: theme.spacing(1, 0, 0, 3),
    "& > p": {
      margin: 0,
    },
  },
  userPhone: {
    marginRight: theme.spacing(2),
  },
  userImage: {
    margin: "0 auto",
    borderRadius: "50%",
    border: `1px solid ${theme.palette.border}`,
    color: theme.palette.border,
    width: 150,
    height: 150,
    textAlign: "center",
    fontSize: 100,
    overflow: "hidden",
    verticalAlign: "middle",
    "& > .anticon.anticon-user": {
      verticalAlign: 0,
    },
    "& > img": { verticalAlign: 0, maxWidth: 150, maxHeight: 150, width: 150 },
  },
}));

interface AppUserProfileProps {
  user: CPCSUser;
  className?: string;
}

const AppUserProfile: React.FC<AppUserProfileProps> = ({ user, className }) => {
  const classes = useStyles();

  return (
    <Row className={clsx(classes.appUserProfile, className)}>
      <Col>
        <div className={clsx(classes.userImage, "userImage")}>
          {!user.avatar?.url ? (
            <UserOutlined />
          ) : (
            <img src={user.avatar.url} alt="My Avatar" />
          )}
        </div>
      </Col>
      <Col className={clsx(classes.userInfo, "userInfo")}>
        <p className={clsx(classes.userName, "userName")}>
          {user.displayName
            ? user.displayName
            : `${user.firstName} ${user.lastName}`}
        </p>
        {user.title && <p>{user.title}</p>}
        <p>{user.primaryEmail}</p>
        <p>
          <span className={clsx(classes.userPhone, "userPhone")}>
            M: {user.mobileNumber}
          </span>
          <span>T: {user.officeNumber}</span>
        </p>
        <p>Office: </p>
      </Col>
    </Row>
  );
};

export default AppUserProfile;
