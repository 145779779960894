import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../app/i18n/i18n";
import { Report, VarianceReport } from "../../reports-services";
import "./VarianceReport.scss";

interface VarianceReportLayoutProps {
  reportData: VarianceReport[];
  varDU: number;
}

function numberToLetters(num: number) {
  let letters = "";
  while (num >= 0) {
    letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[num % 26] + letters;
    num = Math.floor(num / 26) - 1;
  }
  return letters;
}

const VarianceReportLayout: React.FC<VarianceReportLayoutProps> = (props) => {
  const { reportData, varDU } = props;
  const { t } = useTranslation();

  const reportTitles = [
    t("voc.reports.variance.voInternal"),
    t("voc.reports.variance.voTarget"),
    t("voc.reports.variance.voPaid"),
    t("voc.reports.variance.cInternal"),
    t("voc.reports.variance.cTarget"),
    t("voc.reports.variance.cPaid"),
    t("voc.reports.variance.voAndCInternal"),
    t("voc.reports.variance.voAndCTarget"),
    t("voc.reports.variance.voAndCPaid"),
  ];

  const formatNumber = (value: number) => {
    return ["de", "es"].includes(i18n.language)
      ? value
          .toFixed(2)
          .replace(".", ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      : value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="variance-report-container">
      <h1 className="variance-report-title">
        {t("voc.reports.variance.varianceReport")}
      </h1>
      <div className="reports-flexbox">
        {reportData.map((r: VarianceReport, i: number) => (
          <div className="report" key={i}>
            <h3 className="report-title">{reportTitles[i]}</h3>
            <div className="report-headings">
              <div className="heading-small">
                {t("voc.reports.variance.ref")}
              </div>
              <div className="heading-large">
                {t("voc.reports.variance.value")}
              </div>
              <div className="heading-large">
                {t("voc.reports.variance.varianceRange")}
              </div>
              <div className="heading-small">
                {t("voc.reports.variance.number")}
              </div>
            </div>
            {r.reportDetails.map((d: Report, i2: number) => (
              <div
                className={"report-row" + (i2 % 2 === 1 ? " odd" : "")}
                key={i2}
              >
                <div className="col-small">{numberToLetters(i2)}</div>
                <div className="col-large">
                  $
                  {d.totalValue
                    ? formatNumber(Number(d.totalValue) / varDU)
                    : "0"}
                </div>
                <div className="col-large">
                  {d.rangeType === "less than" ? "< " : ""}
                  {d.rangeType === "more than" ? "> " : ""}
                  {d.fromValue
                    ? "$" + formatNumber(Number(d.fromValue) / varDU)
                    : ""}
                  {d.rangeType === "range" ? " - " : ""}
                  {d.toValue
                    ? "$" + formatNumber(Number(d.toValue) / varDU)
                    : ""}
                  {varDU === 1000 ? "k" : ""}
                  {varDU === 1000000 ? "m" : ""}
                </div>
                <div className="col-small">{d.numOfEvents}</div>
              </div>
            ))}
            <div className="report-row total">
              <div className="col-small">{t("voc.common.total")}</div>
              <div className="col-large">
                {r.totalValue
                  ? formatNumber(Number(r.totalValue) / varDU)
                  : "0"}
              </div>
              <div className="col-large"></div>
              <div className="col-small">{r.numOfEvents}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VarianceReportLayout;
