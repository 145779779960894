import React, { useState } from "react";
import { cloneDeep } from "lodash";
import {
  DateFormatPattern,
  NxpSpin,
  formatDate,
  notify,
} from "@nexploretechnology/nxp-ui";
import useAppContext from "../../../app/hooks/useAppContext";
import {
  VocNotification,
  VocNotifications,
  VocUpdateNotificationsRequest,
} from "../../../app/types";
import { editNotifications } from "../../compensation-event-services";
import { EditingPanel, GeneralInfo } from "./NotificationsTabPage";
import NotificationsTabPanelContent from "./NotificationsTabPanelContent";
import moment from "moment";

interface NotificationsTabPanelProps {
  eventId: string;
  title: string;
  notificationType: EditingPanel;
  generalInfo: GeneralInfo;
  fetchNotifications: Function;
  editingPanel: EditingPanel;
  setEditingPanel: React.Dispatch<React.SetStateAction<EditingPanel>>;
  notifications: VocNotifications;
  displayNotifications: VocNotifications;
  setDisplayNotifications: React.Dispatch<
    React.SetStateAction<VocNotifications>
  >;
  isFollowUpNotRequired: boolean;
  onSetIsFollowUpNotRequired: React.Dispatch<React.SetStateAction<boolean>>;
}
const NotificationsTabPanel: React.FC<NotificationsTabPanelProps> = ({
  eventId,
  title,
  notificationType,
  generalInfo,
  fetchNotifications,
  editingPanel,
  setEditingPanel,
  notifications,
  displayNotifications,
  setDisplayNotifications,
  isFollowUpNotRequired,
  onSetIsFollowUpNotRequired,
}) => {
  const { serviceConfig, errorHandler } = useAppContext();

  // =========================================================
  //                     Update Notification
  // =========================================================

  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const [form, setForm] = React.useState<VocUpdateNotificationsRequest>({
    followUpRequired: true,
    notifications: [],
  });

  const handleEditNotifications = React.useCallback(async () => {
    setSaveLoading(true);
    try {
      if (notificationType === "TIME") {
        const reqBody = {
          followUpRequired: form.followUpRequired,
          timeNotifications: form.notifications,
        };
        await editNotifications(eventId, reqBody, serviceConfig);
      } else if (notificationType === "COST") {
        const reqBody = {
          followUpRequired: form.followUpRequired,
          costNotifications: form.notifications,
        };
        await editNotifications(eventId, reqBody, serviceConfig);
      }
      notify.actionCompleted();
      setEditingPanel("");
      fetchNotifications();
    } catch (ex) {
      errorHandler(ex, "Edit Notifications");
    } finally {
      setSaveLoading(false);
    }
  }, [
    notificationType,
    setEditingPanel,
    fetchNotifications,
    form,
    eventId,
    serviceConfig,
    errorHandler,
  ]);

  React.useEffect(() => {
    if (form.notifications.length > 0) {
      handleEditNotifications();
    }
  }, [form, handleEditNotifications]);

  const handleSave = () => {
    if (!generalInfo.isDivideTimeCost && notificationType === "COST") return;

    const newNotifications = [];

    // ==========================================================
    //                            FIRST
    // ==========================================================

    if (
      displayNotifications?.firstNotification?.actualDate ||
      displayNotifications?.firstNotification?.comment ||
      displayNotifications?.firstNotification?.attachments?.length > 0
    ) {
      const notificationPush: VocNotification = {
        id: displayNotifications.firstNotification?.id ?? undefined,
        actualDate: displayNotifications.firstNotification.actualDate
          ? (formatDate(
              displayNotifications.firstNotification.actualDate,
              DateFormatPattern.date
            ) as any)
          : null,
        requiredDate: displayNotifications.firstNotification.requiredDate
          ? (formatDate(
              moment(
                displayNotifications.firstNotification.requiredDate
              ).toDate(),
              DateFormatPattern.date
            ) as any)
          : null,
        comment: displayNotifications.firstNotification.comment,
        type: notificationType,
        stage: "FIRST",
        attachments: displayNotifications.firstNotification.attachments ?? [],
      };
      newNotifications.push(notificationPush);
    }

    // ==========================================================
    //                           FOLLOW UP
    // ==========================================================

    const oriFollowUpIds = cloneDeep(notifications.followUpNotifications);
    const deletedFollowUps = oriFollowUpIds.filter(
      (item) =>
        !displayNotifications.followUpNotifications.some(
          ({ id: idB }) => item.id === idB
        )
    );
    deletedFollowUps.forEach((deletedFollowUp) => {
      deletedFollowUp.deleted = true;
    });
    // concate
    const newFollowUpNotifications = cloneDeep(deletedFollowUps).concat(
      cloneDeep(displayNotifications.followUpNotifications)
    );
    // push followups
    newFollowUpNotifications.forEach((notification, i) => {
      if (
        // [0]requiredDate, [1]actualDate, [2]attachments, [3]comment
        notification.fieldDataArray[1].fieldData ||
        notification.fieldDataArray[2].fieldData ||
        notification.fieldDataArray[3].fieldData
      ) {
        const notificationPush: VocNotification = {
          id: notification?.id ?? undefined,
          requiredDate: notification.fieldDataArray[0].fieldData
            ? (formatDate(
                moment(notification.fieldDataArray[0].fieldData).toDate(),
                DateFormatPattern.date
              ) as any)
            : null,
          actualDate: notification.fieldDataArray[1].fieldData
            ? (formatDate(
                notification.fieldDataArray[1].fieldData,
                DateFormatPattern.date
              ) as any)
            : null,
          attachments: notification.fieldDataArray[2].fieldData ?? [],
          comment: notification.fieldDataArray[3].fieldData,
          type: notificationType,
          stage: "FOLLOW-UP",
          deleted: notification?.deleted ?? false,
        };
        newNotifications.push(notificationPush);
      }
    });

    // ==========================================================
    //                         FINAL
    // ==========================================================

    if (
      displayNotifications?.finalNotification?.actualDate ||
      displayNotifications?.finalNotification?.comment ||
      displayNotifications?.finalNotification?.attachments?.length > 0
    ) {
      const notificationPush: VocNotification = {
        id: displayNotifications.finalNotification?.id ?? undefined,
        actualDate: displayNotifications.finalNotification.actualDate
          ? (formatDate(
              displayNotifications.finalNotification.actualDate,
              DateFormatPattern.date
            ) as any)
          : null,
        requiredDate: displayNotifications.finalNotification.requiredDate
          ? (formatDate(
              moment(
                displayNotifications.finalNotification.requiredDate
              ).toDate(),
              DateFormatPattern.date
            ) as any)
          : null,
        comment: displayNotifications.finalNotification.comment,
        type: notificationType,
        stage: "FINAL",
        attachments: displayNotifications.finalNotification.attachments ?? [],
      };
      newNotifications.push(notificationPush);
    }

    // ==========================================================

    setForm({
      followUpRequired: !isFollowUpNotRequired,
      notifications: newNotifications,
    });
  };

  const handleCancel = () => {
    setDisplayNotifications(cloneDeep(notifications));
    setEditingPanel("");
  };

  return (
    <NxpSpin spinning={saveLoading}>
      <NotificationsTabPanelContent
        notificationType={notificationType}
        title={title}
        isEditable={editingPanel === "" || editingPanel === notificationType}
        isEditing={editingPanel === notificationType}
        setIsEditingNotifications={setEditingPanel}
        saveCallback={handleSave}
        cancelCallback={handleCancel}
        displayNotifications={displayNotifications}
        setDisplayNotifications={setDisplayNotifications}
        isFollowUpNotRequired={isFollowUpNotRequired}
        onSetIsFollowUpNotRequired={onSetIsFollowUpNotRequired}
      ></NotificationsTabPanelContent>
    </NxpSpin>
  );
};

export default NotificationsTabPanel;
