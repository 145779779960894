import React from "react";

import { Chart as ChartJS, registerables } from "chart.js";

import useAppContext from "../../../../app/hooks/useAppContext";
import { ChartData, MonthlySnapshot } from "../../../../app/types";
import {
  getDashboardSnapshots,
  SnapshotRequestBody,
} from "../../../reports-services";
import DashboardChartLayout from "./DashboardChartLayout";

interface MonthContainer {
  month: string;
  data: MonthlySnapshot[];
}

ChartJS.register(...registerables);

const DashboardChartContainer = () => {
  const { serviceConfig, errorHandler } = useAppContext();

  const [monthLabels, setMonthLabels] = React.useState<string[]>(null);
  const [chartData, setChartData] = React.useState<ChartData>(null);

  const now = new Date();
  const [endDate, setEndDate] = React.useState<string>(
    now.getFullYear() + "-" + (now.getMonth() + 1)
  );
  const [startDate, setStartDate] = React.useState<string>(
    now.getFullYear() - 1 + "-" + (now.getMonth() + 1)
  );

  const dateRangeOptions: string[] = [];
  for (let i = 0; i < 5; i++) {
    const year = now.getFullYear() - i;
    if (i === 0) {
      for (let i2 = now.getMonth() + 1; i2 > 0; i2--) {
        const month = i2;
        const string = year + "-" + month;
        dateRangeOptions.push(string);
      }
    } else {
      for (let i2 = 12; i2 > 0; i2--) {
        const month = i2;
        const string = year + "-" + month;
        dateRangeOptions.push(string);
      }
    }
  }

  const handleGetDashboardSnapshots = React.useCallback(async () => {
    try {
      const reqBody: SnapshotRequestBody = {
        limit: 0,
        offset: 0,
        filters: [
          {
            values: [startDate, endDate],
          },
        ],
      };
      const response = await getDashboardSnapshots(serviceConfig, reqBody);
      const months: MonthContainer[] = [];
      for (const month in response) {
        months.push({ month: month, data: response[month] });
      }
      //need to re-sort into array because "for... in" does not guarantee the right order
      months.sort((a, b) => {
        const aStrArray = a.month.split("-");
        const bStrArray = b.month.split("-");
        if (
          Number(aStrArray[0] + aStrArray[1]) <
          Number(bStrArray[0] + bStrArray[1])
        ) {
          return -1;
        } else {
          return 1;
        }
      });
      setMonthLabels(months.map((m: MonthContainer) => m.month));
      const VOs: MonthlySnapshot[] = [];
      const Cs: MonthlySnapshot[] = [];
      const VOCs: MonthlySnapshot[] = [];
      months.forEach((m) => {
        VOs.push(m.data.filter((set) => set.eventType === "VO_EV_TYPE_VO")[0]);
        Cs.push(
          m.data.filter((set) => set.eventType === "VO_EV_TYPE_CLAIM")[0]
        );
        VOCs.push(
          m.data.filter((set) => set.eventType === "VO_EV_TYPE_VO_AND_CLAIM")[0]
        );
      });
      setChartData({
        VOs: VOs,
        Cs: Cs,
        VOCs: VOCs,
      } as any);
    } catch (ex) {
      errorHandler(ex, "Get Dashboard Snapshots");
    }
  }, [startDate, endDate, serviceConfig, errorHandler]);

  React.useEffect(() => {
    handleGetDashboardSnapshots();
  }, [handleGetDashboardSnapshots]);

  return chartData ? (
    <DashboardChartLayout
      chartData={chartData}
      monthLabels={monthLabels}
      dateRangeOptions={dateRangeOptions}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
    />
  ) : null;
};

export default DashboardChartContainer;
