import { notify, NxpButton } from "@nexploretechnology/nxp-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAppContext from "../../../../../app/hooks/useAppContext";
import {
  VocGetUpdateValuesSubrecord,
  VocGetValuesToClient,
  VocGetValuesToDate,
} from "../../../../../app/types";
import { sendValues } from "../../../../compensation-event-services";

interface CopyClientAssessedProps {
  editForm: VocGetUpdateValuesSubrecord;
  dataSource: VocGetUpdateValuesSubrecord[];
  toClientFormState: VocGetValuesToClient;
  onSetCustomValues: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  onSetToClientFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesToClient>
  >;
  onSetCostCodes: React.Dispatch<
    React.SetStateAction<{
      costCodes: string[];
    }>
  >;
  onSetPaymentWithClientFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesToDate>
  >;
}

const CopyCostAndRevenueButton: React.FC<CopyClientAssessedProps> = ({
  editForm,
  dataSource,
  toClientFormState,
  onSetCustomValues,
  onSetToClientFormState,
  onSetCostCodes,
  onSetPaymentWithClientFormState,
}) => {
  const { t } = useTranslation();

  const { eventId } = useParams<{ eventId: string }>();

  const { serviceConfig, errorHandler } = useAppContext();

  const handleCopyClientAssessed = async () => {
    try {
      const clientAssessed = dataSource.reduce(
        (acc, cur) => acc + cur.clientAssessed,
        0
      );

      const cumulativeAppliedNominal = dataSource.reduce(
        (acc, cur) => acc + cur.paymentApplication,
        0
      );

      const cumulativeValueCertified = dataSource.reduce(
        (acc, cur) => acc + cur.clientCertified,
        0
      );

      const costCodes = Array.from(
        new Set(
          dataSource.map((code) => code.costCode).filter((code) => code !== "")
        )
      );

      const valuesForm = {
        costCodes: costCodes,
        clientAssessed: clientAssessed,
        internalVariance: toClientFormState.internalValue - clientAssessed,
        targetVariance: toClientFormState.internalTarget - clientAssessed,
        cumulativeApplied:
          cumulativeAppliedNominal / toClientFormState.internalTarget, //V = W / E
        cumulativeAppliedNominal: cumulativeAppliedNominal, //W = E * V
        cumulativeValueCertified: cumulativeValueCertified, //X
        cumulativeValueVariance:
          cumulativeAppliedNominal - cumulativeValueCertified, //Y = W - X
      };

      const updatedValues = await sendValues(
        eventId,
        valuesForm,
        serviceConfig
      );
      onSetCustomValues(updatedValues.customization);
      onSetToClientFormState(updatedValues.toClient);
      onSetCostCodes({ costCodes: costCodes });
      onSetPaymentWithClientFormState(updatedValues.valuesToDate);
      notify.actionCompleted();
    } catch (error) {
      errorHandler(error, "copy client assessed values and payment");
    }
  };

  return (
    <NxpButton
      type="default"
      disabled={!!editForm}
      onClick={handleCopyClientAssessed}
    >
      {t("voc.eventPage.values.copyClientAssessedAndPayment")}
    </NxpButton>
  );
};

export default CopyCostAndRevenueButton;
