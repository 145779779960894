import { notify } from "@nexploretechnology/nxp-ui";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnDataType } from "../../../app/components/app-data-table";
import { FilterColumn, FilterOption } from "../../../app/components/app-filter";
import useAppContext from "../../../app/hooks/useAppContext";
import { useAsync } from "../../../app/hooks/useAsync";
import { VocScreenNameEnums, VocValueRegister } from "../../../app/types";
import {
  getValueExcel,
  getValueRegisterList,
  postSnapshots,
  searchSnapshots,
  searchValueRegister,
  updateValueRegisterList,
} from "../../compensation-event-services";
import ValueRegisterLayout from "./ValueRegisterLayout";

export interface ValueRegisterColumn extends FilterColumn {
  fieldName: keyof VocValueRegister;
  readonly: boolean;
}

export interface SortParam {
  column: keyof VocValueRegister;
  order: "asc" | "desc";
}

// for auto calculating related fields
export const applyValueRegisterCalculation = (
  valueRegister: VocValueRegister,
  fieldName: keyof VocValueRegister
) => {
  // set recovery / internal target
  if (fieldName === "submittedValue") {
    if (valueRegister.submittedValue) {
      valueRegister.recovery =
        (valueRegister.internalTarget ? valueRegister.internalTarget : 0) /
        valueRegister.submittedValue;
    } else {
      valueRegister.recovery = null;
    }
  }
  if (fieldName === "recovery") {
    if (Number.isFinite(valueRegister.recovery)) {
      valueRegister.internalTarget =
        valueRegister.recovery * valueRegister.submittedValue;
    } else {
      valueRegister.internalTarget = null;
    }
  }
  if (fieldName === "internalTarget") {
    if (
      valueRegister.submittedValue &&
      Number.isFinite(valueRegister.internalTarget)
    ) {
      valueRegister.recovery =
        valueRegister.internalTarget / valueRegister.submittedValue;
    } else {
      valueRegister.recovery = null;
    }

    if (valueRegister.internalTarget) {
      valueRegister.cumulativeApplied =
        (valueRegister.cumulativeAppliedNominal
          ? valueRegister.cumulativeAppliedNominal
          : 0) / valueRegister.internalTarget;
    } else {
      valueRegister.cumulativeApplied = null;
    }
  }

  // set pendingApproval
  if (
    fieldName === "clientAssessed" ||
    fieldName === "internalTarget" ||
    fieldName === "submittedValue" ||
    fieldName === "recovery"
  ) {
    valueRegister.pendingApproval =
      (valueRegister.internalTarget || 0) - (valueRegister.clientAssessed || 0);
  }

  // set cumulativeApplied / cumulativeValueCertified
  if (fieldName === "cumulativeAppliedNominal") {
    if (valueRegister.internalTarget) {
      valueRegister.cumulativeApplied =
        (valueRegister.cumulativeAppliedNominal
          ? valueRegister.cumulativeAppliedNominal
          : 0) / valueRegister.internalTarget;
    } else {
      valueRegister.cumulativeApplied = null;
    }
  }
  if (fieldName === "cumulativeApplied") {
    if (Number.isFinite(valueRegister.cumulativeApplied)) {
      valueRegister.cumulativeAppliedNominal =
        valueRegister.cumulativeApplied * valueRegister.internalTarget;
    } else {
      valueRegister.cumulativeAppliedNominal = null;
    }
  }
  // if (fieldName === 'cumulativeValueCertified') {
  //   if (
  //     valueRegister.cumulativeAppliedNominal &&
  //     Number.isFinite(valueRegister.cumulativeValueCertified)
  //   ) {
  //     valueRegister.cumulativeApplied =
  //       valueRegister.cumulativeValueCertified /
  //       valueRegister.cumulativeAppliedNominal;
  //   } else {
  //     valueRegister.cumulativeApplied = null;
  //   }
  // }

  // set eisA2A6 / eisA8A14
  if (fieldName === "eisA2A6") {
    valueRegister.eisA8A14 =
      (valueRegister.internalTarget || 0) - (valueRegister.eisA2A6 || 0);
  }
  if (fieldName === "includedInReports" && valueRegister.includedInReports) {
    valueRegister.eisA2A6 = null;
    valueRegister.eisA8A14 = valueRegister.internalTarget || 0;
  } else {
    // kept eisA2A6 and eisA8A14 for calculating total in footer row
    //// if (!valueRegister.includedInReports)
    //// valueRegister.eisA2A6 = null;
    //// valueRegister.eisA8A14 = null;
  }
  if (fieldName === "internalTarget" || fieldName === "recovery") {
    valueRegister.eisA8A14 =
      (valueRegister.internalTarget || 0) - (valueRegister.eisA2A6 || 0);
  }

  // set total cost
  if (
    fieldName === "directCost" ||
    fieldName === "indirectCost" ||
    fieldName === "costCloseOut" ||
    fieldName === "costGna"
  ) {
    valueRegister.totalCost = valueRegister.directCost || 0;
    valueRegister.totalCost += valueRegister.indirectCost || 0;
    valueRegister.totalCost += valueRegister.costGna || 0;
    valueRegister.totalCost += valueRegister.costCloseOut || 0;
  }

  // set profit / loss
  valueRegister.profitLoss =
    (valueRegister.internalTarget || 0) - (valueRegister.totalCost || 0);

  // set overheadprofit
  if (
    Number.isFinite(valueRegister.costGna) &&
    Number.isFinite(valueRegister.totalCost) &&
    valueRegister.totalCost - valueRegister.costGna !== 0
  ) {
    valueRegister.overheadProfit =
      (valueRegister.costGna + valueRegister.profitLoss) /
      (valueRegister.totalCost - valueRegister.costGna);
  } else {
    valueRegister.overheadProfit = null;
  }
};

interface ValuesRegisterContainerProps {}

const ValueRegisterContainer: React.FC<ValuesRegisterContainerProps> = () => {
  const { t } = useTranslation();
  const { errorHandler, serviceConfig, getCustomizedScreen } = useAppContext();

  const [sortParam, setSortParam] = useState<SortParam>();
  const [changeTracker, setChangeTracker] = useState<number[]>([]);
  // save columns as state to support re-ordering in ui
  const [columns, setColumns] = useState<ValueRegisterColumn[]>(() => [
    {
      fieldName: "compensationEventSerial",
      name: t("voc.valueRegisters.compensationEventSerial"),
      columnDataType: ColumnDataType.String,
      readonly: true,
    },
    {
      fieldName: "eventTypeSerial",
      name: t("voc.valueRegisters.eventTypeSerial"),
      columnDataType: ColumnDataType.String,
      readonly: true,
    },
    {
      fieldName: "eventTitle",
      name: t("voc.valueRegisters.eventTitle"),
      columnDataType: ColumnDataType.String,
      readonly: true,
    },
    {
      fieldName: "submittedValue",
      name: t("voc.valueRegisters.submittedValue"),
      columnDataType: ColumnDataType.Money,
      readonly: false,
    },
    {
      fieldName: "recovery",
      name: t("voc.valueRegisters.recovery"),
      columnDataType: ColumnDataType.Percent,
      readonly: false,
    },
    {
      fieldName: "internalTarget",
      name: t("voc.valueRegisters.internalTarget"),
      columnDataType: ColumnDataType.Money,
      readonly: false,
    },
    {
      fieldName: "clientAssessed",
      name: t("voc.valueRegisters.clientAssessed"),
      columnDataType: ColumnDataType.Money,
      readonly: false,
    },
    {
      fieldName: "pendingApproval",
      name: t("voc.valueRegisters.pendingApproval"),
      columnDataType: ColumnDataType.Money,
      readonly: false,
    },
    {
      fieldName: "cumulativeApplied",
      name: t("voc.valueRegisters.cumulativeApplied"),
      columnDataType: ColumnDataType.Percent,
      readonly: false,
    },
    {
      fieldName: "cumulativeAppliedNominal",
      name: t("voc.valueRegisters.cumulativeAppliedNominal"),
      columnDataType: ColumnDataType.Money,
      readonly: false,
    },
    {
      fieldName: "cumulativeValueCertified",
      name: t("voc.valueRegisters.cumulativeValueCertified"),
      columnDataType: ColumnDataType.Money,
      readonly: false,
    },
    {
      fieldName: "paymentReference",
      name: t("voc.valueRegisters.paymentReference"),
      columnDataType: ColumnDataType.String,
      readonly: false,
    },
    {
      fieldName: "directCost",
      name: t("voc.valueRegisters.directCost"),
      columnDataType: ColumnDataType.Money,
      readonly: false,
    },
    {
      fieldName: "indirectCost",
      name: t("voc.valueRegisters.indirectCost"),
      columnDataType: ColumnDataType.Money,
      readonly: false,
    },
    {
      fieldName: "costCloseOut",
      name: t("voc.valueRegisters.costCloseOut"),
      columnDataType: ColumnDataType.Money,
      readonly: false,
    },
    {
      fieldName: "costGna",
      name: t("voc.valueRegisters.costGna"),
      columnDataType: ColumnDataType.Money,
      readonly: false,
    },
    {
      fieldName: "totalCost",
      name: t("voc.valueRegisters.totalCost"),
      columnDataType: ColumnDataType.Money,
      readonly: false,
    },
    {
      fieldName: "profitLoss",
      name: t("voc.valueRegisters.profitLoss"),
      columnDataType: ColumnDataType.Money,
      readonly: false,
    },
    {
      fieldName: "overheadProfit",
      name: t("voc.valueRegisters.overheadProfit"),
      columnDataType: ColumnDataType.Percent,
      readonly: false,
    },
    {
      fieldName: "includedInReports",
      name: t("voc.valueRegisters.includedInReports"),
      columnDataType: ColumnDataType.Boolean,
      readonly: false,
    },
    {
      fieldName: "eisA2A6",
      name: t("voc.valueRegisters.eisA2A6"),
      columnDataType: ColumnDataType.Money,
      readonly: false,
    },
    {
      fieldName: "eisA8A14",
      name: t("voc.valueRegisters.eisA8A14"),
      columnDataType: ColumnDataType.Money,
      readonly: false,
    },
  ]);
  const [valueRegisterList, setValueRegisterList] =
    useState<VocValueRegister[]>();
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [isFutureMonth, setIsFutureMonth] = useState<boolean>();

  const [, setGetValueRegisterRequest] = useAsync<VocValueRegister[]>(
    () => searchValueRegister(serviceConfig, filterOptions),
    {
      // not using the hook's AyncResult because of the need to handle sorting data
      onSuccess: (data) => {
        setValueRegisterList(data);
      },
    }
  );

  const unloadWarningAddedRef = useRef(false);

  const clearUnloadWarning = () => {
    window.onbeforeunload = undefined;
    unloadWarningAddedRef.current = false;
  };

  const handleCancel = useCallback(() => {
    setValueRegisterList(undefined); // unmount the list to clear it's state
    setChangeTracker([]);
    setGetValueRegisterRequest(() =>
      getValueRegisterList(filterOptions, serviceConfig)
    );
    clearUnloadWarning();
  }, [setGetValueRegisterRequest, filterOptions, serviceConfig]);

  const customUi = getCustomizedScreen(
    VocScreenNameEnums.VALUE_REGISTER_SCREEN
  );

  const handleExportExcel = useCallback(() => {
    getValueExcel(customUi.screen.id, serviceConfig);
  }, [customUi.screen.id, serviceConfig]);

  const [patchValueRegisterAsyncResult, setPatchValueRegisterRequest] =
    useAsync<VocValueRegister[]>(undefined, {
      onSuccess: () => {
        notify.actionCompleted(); //     'voc.valuesRegister.batchPaymentSuccessful'
        handleCancel();
      },
      onError: (ex) => errorHandler(ex, "value register update"),
    });

  useEffect(
    () =>
      setValueRegisterList((prevState) => {
        if (prevState && prevState.length) {
          return [
            // eslint-disable-next-line array-callback-return
            ...prevState.sort((a, b) => {
              if (!sortParam) {
                return b.compensationEventId - a.compensationEventId;
              }

              if (sortParam.order === "asc") {
                if (
                  columns.find(
                    (column) => column.fieldName === sortParam.column
                  ).columnDataType === ColumnDataType.String
                ) {
                  return a[sortParam.column] > b[sortParam.column] ? 1 : -1;
                } else {
                  return (
                    (a[sortParam.column] as number) -
                    (b[sortParam.column] as number)
                  );
                }
              }

              if (sortParam.order === "desc") {
                if (
                  columns.find(
                    (column) => column.fieldName === sortParam.column
                  ).columnDataType === ColumnDataType.String
                ) {
                  return b[sortParam.column] > a[sortParam.column] ? 1 : -1;
                } else {
                  return (
                    (b[sortParam.column] as number) -
                    (a[sortParam.column] as number)
                  );
                }
              }
            }),
          ];
        }
        // no sorting for empty list
        return prevState;
      }),
    [columns, sortParam]
  );

  const handleValueChange = useCallback(
    (
      newValue: number | string,
      _: number | string,
      fieldName: keyof VocValueRegister,
      rowIndex: number
    ) => {
      if (!unloadWarningAddedRef.current) {
        const message = t("voc.valuesRegister.navigateAway");
        const confirmOnPageExit = function (e: BeforeUnloadEvent) {
          // If we haven't been passed the event get the window.event
          e = e || window.event;

          // For IE6-8 and Firefox prior to version 4
          if (e) {
            e.returnValue = message;
          }

          // For Chrome, Safari, IE8+ and Opera 12+
          return message;
        };
        window.onbeforeunload = confirmOnPageExit;
        unloadWarningAddedRef.current = true;
      }

      setValueRegisterList((prevState) => {
        (prevState[rowIndex][fieldName] as string | number) = newValue;

        setChangeTracker((prevTracker) => {
          if (!prevTracker.includes(prevState[rowIndex].compensationEventId)) {
            return [...prevTracker, prevState[rowIndex].compensationEventId];
          }
          return [...prevTracker];
        });

        applyValueRegisterCalculation(prevState[rowIndex], fieldName);

        return prevState; // mutate array deliberately to avoid rendering row list
      });
    },
    [t]
  );

  const handleSave = useCallback(() => {
    const updateItems = valueRegisterList.filter((item) =>
      changeTracker.includes(item.compensationEventId)
    );
    setPatchValueRegisterRequest(() =>
      updateValueRegisterList(updateItems, serviceConfig)
    );
    clearUnloadWarning();
  }, [
    changeTracker,
    serviceConfig,
    setPatchValueRegisterRequest,
    valueRegisterList,
  ]);

  const handleSortChange = useCallback((sortParam: SortParam) => {
    setSortParam(sortParam);
  }, []);

  const handleFilterChange = useCallback(
    (filterOptions: FilterOption[]) => {
      setFilterOptions([...filterOptions].map((option) => ({ ...option })));
      setValueRegisterList(undefined);
      setGetValueRegisterRequest(() =>
        searchValueRegister(serviceConfig, filterOptions)
      );
    },
    [serviceConfig, setGetValueRegisterRequest]
  );

  const handleHeaderDrop = useCallback(
    (
      e: React.DragEvent<HTMLDivElement>,
      toFieldName: keyof VocValueRegister
    ) => {
      const fromFieldName = e.dataTransfer.getData(
        "column"
      ) as keyof VocValueRegister;

      setColumns((prevState) => {
        const fromCol = prevState.find(
          (column) => column.fieldName === fromFieldName
        );
        const toCol = prevState.find(
          (column) => column.fieldName === toFieldName
        );
        const fromIndex = prevState.indexOf(fromCol);
        const toIndex = prevState.indexOf(toCol);
        if (fromIndex === toIndex) {
          return prevState;
        }
        if (fromIndex > toIndex) {
          prevState.splice(fromIndex, 1);
          prevState.splice(toIndex, 0, fromCol);
        } else {
          prevState.splice(fromIndex, 1);
          prevState.splice(toIndex - 1, 0, fromCol);
        }
        return [...prevState];
      });

      e.currentTarget.className = e.currentTarget.className.replace(
        / drag-over/g,
        ""
      );
    },
    []
  );

  ///////////////////////
  //RECORD SUMMARY VALUES
  const [recordSummaryModalOn, setRecordSummaryModalOn] =
    React.useState<boolean>(false);
  const [snapshotExistsWarning, setSnapshotExistsWarning] =
    React.useState<boolean>(false);

  const handlePostSnapshot = (month: number, year: number) => {
    //month parameter is 0-indexed but request is not
    const dateString =
      String(year) +
      "-" +
      (String(month).length === 1
        ? "0" + String(month + 1)
        : String(month + 1));

    searchSnapshots(
      {
        limit: 0,
        offset: 0,
        filters: [
          {
            values: [dateString, dateString],
          },
        ],
      },
      serviceConfig
    ).then((response) => {
      if (response[dateString] && response[dateString].length === 0) {
        return postSnapshots(
          { month: month + 1, year: year },
          serviceConfig
        ).then(() => {
          notify.actionCompleted();
          setRecordSummaryModalOn(false);
          setSnapshotExistsWarning(false);
        });
      } else {
        if (snapshotExistsWarning) {
          setSnapshotExistsWarning(false);
          return postSnapshots(
            { month: month + 1, year: year },
            serviceConfig
          ).then(() => {
            notify.actionCompleted();
            setRecordSummaryModalOn(false);
          });
        } else {
          setSnapshotExistsWarning(true);
        }
      }
    });
  };

  return (
    <ValueRegisterLayout
      changeTracker={changeTracker}
      columns={columns}
      valueRegisterList={valueRegisterList}
      sortParam={sortParam}
      patchValueRegisterAsyncResult={patchValueRegisterAsyncResult}
      filterOptions={filterOptions}
      onValueChange={handleValueChange}
      onHeaderDrop={handleHeaderDrop}
      onSortChange={handleSortChange}
      onFilterChange={handleFilterChange}
      onSave={handleSave}
      onCancel={handleCancel}
      onExportExcel={handleExportExcel}
      recordSummaryModalOn={recordSummaryModalOn}
      setRecordSummaryModalOn={setRecordSummaryModalOn}
      snapshotExistsWarning={snapshotExistsWarning}
      setSnapshotExistsWarning={setSnapshotExistsWarning}
      handlePostSnapshot={handlePostSnapshot}
      setIsFutureMonth={setIsFutureMonth}
      isFutureMonth={isFutureMonth}
    />
  );
};

export default ValueRegisterContainer;
