import { t } from "i18next";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import {
  notify,
  NxpButton,
  NxpPanel,
  NxpTableEditable,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";

import { createUseStyles } from "react-jss";
import useAppContext from "../../../../app/hooks/useAppContext";
import {
  VocGetUpdateValuesSubrecord,
  VocGetValuesBuildUp,
  VocGetValuesEisReporting,
  VocGetValuesResponse,
  VocGetValuesToClient,
  VocGetValuesToDate,
  VocValuesSubrecord,
} from "../../../../app/types";
import CopyAllButton from "./actions/CopyAllButton";
import CopyClientAssessedButton from "./actions/CopyClientAssessedButton";
import CopyCostAndRevenueButton from "./actions/CopyCostAndRevenueButton";
import SummaryComponent from "./SummaryComponent";
import useValueBreakdownColumns from "./useGetBreakdownColumns";
import {
  deleteValuesSubRecords,
  updateValuesSubRecords,
} from "./value-breakdown-services";

interface ValuesBreakDownProps {
  records: VocGetUpdateValuesSubrecord[];
  toClientFormState: VocGetValuesToClient;
  actualValues: {
    physicalProgress: number;
    internalProgressValue: number;
  };
  eisReportingFormState: Pick<
    VocGetValuesEisReporting,
    | "eisA2A6"
    | "in16"
    | "eisA8A14"
    | "eisSecuredProject"
    | "eisStrategyIncludedInPlanning"
  >;
  paymentWithClientFormState: VocGetValuesToDate;
  onSetCustomValues: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  onSetBuildUpFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesBuildUp>
  >;
  onSetToClientFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesToClient>
  >;
  onSetPaymentWithClientFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesToDate>
  >;
  onSetActualValues: React.Dispatch<
    React.SetStateAction<{
      physicalProgress: number;
      internalProgressValue: number;
    }>
  >;
  onSetIncomingDataPackage: React.Dispatch<
    React.SetStateAction<VocGetValuesResponse>
  >;
  onSetEisReportingFormState: React.Dispatch<
    React.SetStateAction<
      Pick<
        VocGetValuesEisReporting,
        | "eisA2A6"
        | "eisA8A14"
        | "in16"
        | "eisSecuredProject"
        | "eisStrategyIncludedInPlanning"
      >
    >
  >;
  onSetCostCodes: React.Dispatch<
    React.SetStateAction<{
      costCodes: string[];
    }>
  >;
}

const useStyles = createUseStyles({
  buttonContainer: {
    textAlign: "right",
  },
});

const ValuesBreakDown: React.FC<ValuesBreakDownProps> = ({
  records,
  toClientFormState,
  eisReportingFormState,
  actualValues,
  paymentWithClientFormState,
  onSetBuildUpFormState,
  onSetToClientFormState,
  onSetPaymentWithClientFormState,
  onSetEisReportingFormState,
  onSetActualValues,
  onSetIncomingDataPackage,
  onSetCostCodes,
  onSetCustomValues,
}) => {
  const classes = useStyles();

  const { eventId } = useParams<{ eventId: string }>();
  const { serviceConfig, errorHandler } = useAppContext();

  const [saveInProgress, setSaveInProgress] = useState(false);
  const [editForm, setEditForm] = useState<VocGetUpdateValuesSubrecord>();
  const [dataSource, setDataSource] =
    useState<VocGetUpdateValuesSubrecord[]>(records);

  const handleNewRecord = () => {
    const newRecord: VocGetUpdateValuesSubrecord = {
      id: null,
      description: "",
      valuationMethod: "",
      costCode: "",
      directCostPrevious: 0,
      indirectCostPrevious: 0,
      directCostPlanned: 0,
      indirectCostPlanned: 0,
      closeOut: 0,
      gna: 0,
      totalCost: 0,
      revenue: 0,
      profitLoss: 0,
      ohnp: 0,
      paymentApplication: 0,
      clientAssessed: 0,
      clientCertified: 0,
    };
    setEditForm(newRecord);
    setDataSource((prevDataSource) => [newRecord, ...prevDataSource]);
  };

  const handleRowDelete = useCallback(
    async (deleteItem: VocGetUpdateValuesSubrecord) => {
      try {
        await deleteValuesSubRecords(
          eventId,
          { id: deleteItem.id, isDeleted: true },
          serviceConfig
        );
        setDataSource(dataSource.filter((data) => data.id !== deleteItem.id));
        setEditForm(undefined);
        onSetIncomingDataPackage((prevState) => {
          prevState.records = dataSource.filter(
            (data) => data.id !== deleteItem.id
          );
          return { ...prevState };
        });
        notify.actionCompleted();
      } catch (error) {
        errorHandler(error, "delete record.");
      }
    },
    [dataSource, errorHandler, eventId, onSetIncomingDataPackage, serviceConfig]
  );

  const handleRowEdit = (record: VocGetUpdateValuesSubrecord) => {
    setEditForm(record);
  };

  const formSchema = yup.object().shape({
    description: yup.string().nullable().required("Description required."),
  });

  const handleSaveValidated = useCallback(async () => {
    setSaveInProgress(true);
    const payload = {
      id: editForm.id,
      description: editForm.description,
      valuationMethod: editForm.valuationMethod,
      costCode: editForm.costCode,
      directCostPrevious: editForm.directCostPrevious,
      indirectCostPrevious: editForm.indirectCostPrevious,
      directCostPlanned: editForm.directCostPlanned,
      indirectCostPlanned: editForm.indirectCostPlanned,
      closeOut: editForm.closeOut,
      gna: editForm.gna,
      totalCost: editForm.totalCost,
      revenue: editForm.revenue,
      profitLoss: editForm.profitLoss,
      ohnp: editForm.ohnp,
      paymentApplication: editForm.paymentApplication,
      clientAssessed: editForm.clientAssessed,
      clientCertified: editForm.clientCertified,
    };
    try {
      const newValuesSubRecords = await updateValuesSubRecords(
        eventId,
        payload,
        serviceConfig
      );
      setDataSource(newValuesSubRecords.results);
      onSetIncomingDataPackage((prevState) => {
        prevState.records = newValuesSubRecords.results;
        return { ...prevState };
      });
      notify.actionCompleted();
      setEditForm(undefined);
    } catch (error) {
      errorHandler(error, "Failed to save changes.");
      setDataSource(records);
    } finally {
      setSaveInProgress(false);
    }
  }, [
    editForm,
    eventId,
    serviceConfig,
    onSetIncomingDataPackage,
    errorHandler,
    records,
  ]);

  const [validationRecordError, , clearError, saveWithValidate] =
    useYupValidate<Pick<VocValuesSubrecord, "description">>(
      editForm,
      formSchema,
      handleSaveValidated
    );

  const handleModalSave = () => {
    saveWithValidate(undefined);
  };

  const handleRowCancel = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      setEditForm(undefined);
      setDataSource((prev) => prev.filter((data) => data.id !== null));
      clearError();
    },
    [clearError]
  );

  const columns = useValueBreakdownColumns(
    editForm,
    setEditForm,
    validationRecordError
  );

  return (
    <NxpPanel titleContent={<>{t("voc.eventPage.values.breakdown")}</>}>
      <NxpTableEditable
        hideDelete={false}
        scroll={{ y: 400 }}
        useDefaultHeight
        editItem={editForm}
        saveInProgress={saveInProgress}
        itemCompareKey="id"
        onRowDelete={handleRowDelete}
        onRowEdit={handleRowEdit}
        onRowSave={handleModalSave}
        onRowCancel={handleRowCancel}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        title={() => (
          <div className={classes.buttonContainer}>
            <NxpButton
              type="link"
              className="add control"
              onClick={handleNewRecord}
              disabled={!!editForm}
            >
              {t("voc.common.add").toLocaleUpperCase()}
            </NxpButton>
          </div>
        )}
        summary={() => <SummaryComponent dataSource={dataSource} />}
        footer={() => (
          <>
            <CopyAllButton
              dataSource={dataSource}
              editForm={editForm}
              toClientFormState={toClientFormState}
              actualValues={actualValues}
              eisReportingFormState={eisReportingFormState}
              onSetCustomValues={onSetCustomValues}
              onSetToClientFormState={onSetToClientFormState}
              onSetBuildUpFormState={onSetBuildUpFormState}
              onSetEisReportingFormState={onSetEisReportingFormState}
              onSetCostCodes={onSetCostCodes}
              onSetPaymentWithClientFormState={onSetPaymentWithClientFormState}
              onSetActualValues={onSetActualValues}
            />
            <CopyCostAndRevenueButton
              toClientFormState={toClientFormState}
              editForm={editForm}
              dataSource={dataSource}
              eisReportingFormState={eisReportingFormState}
              paymentWithClientFormState={paymentWithClientFormState}
              actualValues={actualValues}
              onSetCustomValues={onSetCustomValues}
              onSetToClientFormState={onSetToClientFormState}
              onSetPaymentWithClientFormState={onSetPaymentWithClientFormState}
              onSetBuildUpFormState={onSetBuildUpFormState}
              onSetCostCodes={onSetCostCodes}
              onSetEisReportingFormState={onSetEisReportingFormState}
            />
            <CopyClientAssessedButton
              toClientFormState={toClientFormState}
              editForm={editForm}
              dataSource={dataSource}
              onSetCustomValues={onSetCustomValues}
              onSetCostCodes={onSetCostCodes}
              onSetToClientFormState={onSetToClientFormState}
              onSetPaymentWithClientFormState={onSetPaymentWithClientFormState}
            />
          </>
        )}
      />
    </NxpPanel>
  );
};

export default ValuesBreakDown;
