import { escapeRegExp } from "./escapeRegExp";

/**
 * Remove abbreviation in middle of text
 */
export const removeDirtyAbbreviation = (
  value: string,
  abbreviationChars: readonly string[]
): string => {
  const chars = escapeRegExp(abbreviationChars.join(""));
  const regAbbreviationAtStart = new RegExp(`^[${chars}](.+)`, "gi");
  const regAbbreviationInMiddle = new RegExp(`[${chars}]([\\d|\\.]+)`, "gi");
  const regAbbreviationAfterDot = new RegExp(`(\\..*)[${chars}]`, "gi");
  return value
    .replace(regAbbreviationAtStart, "$1")
    .replace(regAbbreviationInMiddle, "$1")
    .replace(regAbbreviationAfterDot, "$1");
};
