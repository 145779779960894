//RENAMED FROM: "VocEventSupportDocumentType"
export enum VocSupportDocumentEnums {
  Correspondence = "VO-EV-SDOC-01",
  Labour_Records = "VO-EV-SDOC-02",
  Plant_Records = "VO-EV-SDOC-03",
  Staff_Records_Timesheets = "VO-EV-SDOC-04",
  Daily_Diaries = "VO-EV-SDOC-05",
  Subcontractor_ACA = "VO-EV-SDOC-06",
  Subcontractor_SCI = "VO-EV-SDOC-07",
  Subcontractor_Minerva = "VO-EV-SDOC-10",
  Contemporary_Records = "VO-EV-SDOC-08",
  Other_Documents = "VO-EV-SDOC-09",
  Drawings = "VO-EV-SDOC-11",
}

//RENAMED FROM: "VocRecordStatus"
export enum VocRecordStatus {
  Record_Prepared = "VO_REC_STATUS_01",
  Record_Reviewed = "VO_REC_STATUS_02",
  Record_Signed_Off_By_PD = "VO_REC_STATUS_03",
  Record_Submitted_To_Client = "VO_REC_STATUS_04",
  Final_Claim_Submitted = "VO_REC_STATUS_05",
  No_Disruption_Or_Delay = "VO_REC_STATUS_09",
  Outstanding = "VO_REC_STATUS_00",
  Related_Site_Event = "VO_REC_STATUS_11",
}

//RENAMED FROM "VocDocumentStatusType"
export enum VocDocumentStatusEnums {
  Draft = "SUPPORT_DOC_STATUS_01",
  Submitted = "SUPPORT_DOC_STATUS_02",
  Approved = "SUPPORT_DOC_STATUS_03",
  Rejected = "SUPPORT_DOC_STATUS_04",
}

export enum VocEventType {
  VO = "VO_EV_TYPE_VO",
  Claim = "VO_EV_TYPE_CLAIM",
  VO_And_Claim = "VO_EV_TYPE_VO_AND_CLAIM",
}
export enum VocFeedbackType {
  Enhancement = "VO_FB_TYPE_ENHANCEMENT",
  MissingInformation = "VO_FB_TYPE_MISSING_IMFORMATION",
  Bug_And_Error = "VO_FB_TYPE_BUG_AND_ERROR",
  Question = "VO_FB_TYPE_QUESTION",
  Other = "VO_FB_TYPE_OTHER",
}
export enum VocEventValuationMethod {
  BQ_Rate = "VO_EVAL_METHOD_01",
  Star_Rate = "VO_EVAL_METHOD_02",
  Pro_Rata = "VO_EVAL_METHOD_03",
  Cost_Plus = "VO_EVAL_METHOD_04",
  Lump_Sum_Offer = "VO_EVAL_METHOD_05",
  Other = "VO_EVAL_METHOD_99",
}

export enum VocProjectLibraryType {
  Empowered_Client_Representatives = "VOC_VO_EMPOWER_REPS",
  Clauses_for_Instructions = "VOC_CLAUSE_INSTR",
  Clauses_for_Valuation = "VOC_CLAUSE_VALUATION",
  Event_Source = "VOC_SOURCES",
  Event_Source_Type = "VOC_SOURCE_TYPE",
  Construction_Status = "VOC_CONSTR_STATUS",
  Delaying_and_Disruptive_Effects = "VOC_DELAY_EFFECTS",
  Client_Notification = "VOC_CLIENT_NOTIFICATION",
  Target_Income = "VOC_TARGET_INCOME",
  Escalation_of_Outstanding_Records = "VOC_ESCALATION_RECORDS",
  Deadline_or_Key_Dates = "VOC_KEY_DATES",
  Record_Status = "VOC_REC_STATUS",
  Status_With_Client = "VOC_CLIENT_WITH_STATUS",
  Support_Document_Status = "VOC_SUPPORT_DOC_STATUS",
  Site_Event_Status = "VOC_EVENT_STATUS",
}

export enum VocIN16Enums {
  Secured_Project = "VALUATION_IN16_SECURED",
  Strategy_included_in_Planning = "VALUATION_IN16_INC_PLAN",
  Strategy_not_included_in_Planning = "VALUATION_IN16_NOT_PLAN",
  Mix_of_Secured_Project_and_Strategy_included_in_Planning = "VALUATION_OM16_MIXED",
}

export enum VocConstructionStatusEnums {
  In_Progress = "VOC_CONSTR_STATUS_01",
  Prior_Execution = "VOC_CONSTR_STATUS_02",
  Executed = "VOC_CONSTR_STATUS_03",
}

// may not work any more in new backend, use name enum below
/**
 * @deprecated use `VocScreenNameEnums` instead
 */
export enum VocScreenIdEnums {
  COMPENSATION_EVENT_REGISTER_SCREEN = 1,
  COMPENSATION_EVENT_SUMMARY_SCREEN = 2,
  COMPENSATION_EVENT_NOTIFICATION_SCREEN = 3,
  COMPENSATION_EVENT_RECORD_SCREEN = 4,
  COMPENSATION_EVENT_VO_DETAIL_SCREEN = 5,
  COMPENSATION_EVENT_CLAIM_DETAIL_SCREEN = 6,
  COMPENSATION_EVENT_VALUES_SCREEN = 7,
  SITE_EVENT_REGISTER_SCREEN = 8,
  SITE_EVENT_SUMMARY_SCREEN = 9,
  VALUE_REGISTER_SCREEN = 10,
}

export enum VocScreenNameEnums {
  COMPENSATION_EVENT_REGISTER_SCREEN = "compensation_event_register",
  COMPENSATION_EVENT_SUMMARY_SCREEN = "compensation_event_summary",
  COMPENSATION_EVENT_NOTIFICATION_SCREEN = "compensation_event_notification",
  COMPENSATION_EVENT_RECORD_SCREEN = "compensation_event_record",
  COMPENSATION_EVENT_VO_DETAIL_SCREEN = "compensation_event_vo_detail",
  COMPENSATION_EVENT_CLAIM_DETAIL_SCREEN = "compensation_event_claim_detail",
  COMPENSATION_EVENT_VALUES_SCREEN = "compensation_event_value",
  SITE_EVENT_REGISTER_SCREEN = "site_event_register",
  SITE_EVENT_SUMMARY_SCREEN = "site_event_summary",
  VALUE_REGISTER_SCREEN = "value_register",
}
