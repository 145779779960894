import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { NxpButton, NxpPanel, notify } from "@nexploretechnology/nxp-ui";
import { Divider } from "antd";
import Checkbox from "antd/lib/checkbox";
import {
  ApplicationPermissionSetData,
  EntityRole,
  EntityRoleAccessRight,
  addRoleRights,
  deleteRoleRights,
} from "../../access-right-services";
import useAppContext from "../../../app/hooks/useAppContext";

interface AccessRightPanelProps {
  selectedRole?: EntityRole;
  editing: boolean;
  isSubmitting: boolean;
  userRights: EntityRoleAccessRight[];
  applicationPermissionSetData: ApplicationPermissionSetData[];
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = createUseStyles((theme) => ({
  managePanel: {
    flex: [1, 1, "auto"],
  },
  selectAllCheckbox: {
    paddingRight: theme.spacing(4),
  },
  row: {
    display: "flex",
    padding: theme.spacing(0, 3),
  },
  accessRight: {
    flex: [1, 1, "auto"],
    fontWeight: theme.fontWeight.bold,
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const AccessRightPanel: React.FC<AccessRightPanelProps> = ({
  selectedRole,
  editing,
  isSubmitting,
  userRights,
  applicationPermissionSetData,
  setEditing,
  setRefresh,
  setIsSubmitting,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const appContext = useAppContext();
  const { serviceConfig, errorHandler } = appContext;
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [userRightsMap, setUserRightsMap] = useState<
    Map<string, ApplicationPermissionSetData[]>
  >(new Map<string, ApplicationPermissionSetData[]>());

  const toPermissionString = (objectCode: string, action: string) => {
    return `@${objectCode}:${action}`;
  };

  const toI18nKey = (resource: string, action: string) => {
    return `voc.AccessRight.${resource}${action}`;
  };

  const handleCheck = (data: ApplicationPermissionSetData) => {
    const permission = toPermissionString(data.objectCode, data.action);
    const newSelectedRows = selectedRows.includes(permission)
      ? selectedRows.filter((s) => s !== permission)
      : [...selectedRows, permission];
    setSelectedRows(newSelectedRows);
  };

  const resetData = useCallback(() => {
    setEditing(false);
    const userRight = userRights?.find(
      (right) => right.id === selectedRole?.id
    );
    setSelectedRows(
      userRight
        ? userRight.permissions.map((p) => {
            return toPermissionString(p.objectCode, p.action);
          })
        : []
    );
  }, [selectedRole, userRights, setEditing]);

  useEffect(() => {
    const m = new Map<string, ApplicationPermissionSetData[]>();
    applicationPermissionSetData.forEach((data) => {
      const arr = m.get(data.objectCode);
      if (!arr) {
        m.set(data.objectCode, [data]);
      } else {
        m.set(data.objectCode, [...arr, data]);
      }
    });
    setUserRightsMap(m);
  }, [applicationPermissionSetData]);

  useEffect(() => {
    resetData();
  }, [selectedRole, resetData]);

  const handleClickCancel = () => {
    resetData();
  };

  const userRight = userRights?.find((right) => right.id === selectedRole?.id);

  const initSelectedRows = userRight?.permissions?.map((p) => {
    return toPermissionString(p.objectCode, p.action);
  });

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const allRights = [];
      userRightsMap.forEach((userRight) => {
        allRights.push(...userRight);
      });
      const diff = [];
      allRights.forEach((right) => {
        const permissionName = toPermissionString(
          right.objectCode,
          right.action
        );
        if (
          selectedRows.includes(permissionName) &&
          !initSelectedRows.includes(permissionName)
        ) {
          diff.push({
            name: permissionName,
            type: true,
            id: right.id,
          });
        } else if (
          !selectedRows.includes(permissionName) &&
          initSelectedRows.includes(permissionName)
        ) {
          diff.push({
            name: permissionName,
            type: false,
            id: right.id,
          });
        }
      });
      const apiPromise = diff.map((right) => {
        if (right.type) {
          return addRoleRights(serviceConfig, selectedRole.id, right.id);
        } else {
          return deleteRoleRights(serviceConfig, selectedRole.id, right.id);
        }
      });
      await Promise.all(apiPromise);
      setRefresh(true);
    } catch (err) {
      errorHandler(err, "update access right");
    } finally {
      notify.actionCompleted();
      setEditing(false);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className={classes.managePanel}>
        {Array.from(userRightsMap.entries()).map(([key, value]) => (
          <NxpPanel
            key={`panel-${key}`}
            titleContent={t(`voc.AccessRight.${key}`)}
            extra={
              <div
                className={classes.selectAllCheckbox}
                onClick={(e) => e.stopPropagation()}
              ></div>
            }
          >
            {value.map((row, idx) => {
              return (
                <div key={`panel-${key}-row-${row.id}`}>
                  <div className={classes.row}>
                    <span className={classes.accessRight}>
                      {t(toI18nKey(row.objectCode, row.action))}
                    </span>
                    <Checkbox
                      disabled={!editing}
                      checked={selectedRows.includes(
                        toPermissionString(row.objectCode, row.action)
                      )}
                      onChange={() => handleCheck(row)}
                    />
                  </div>
                  {idx !== value.length - 1 && <Divider />}
                </div>
              );
            })}
          </NxpPanel>
        ))}
        {editing && (
          <div className={classes.buttonGroup}>
            <NxpButton
              type="default"
              onClick={handleClickCancel}
              loading={isSubmitting}
            >
              {t("common.button.cancel")}
            </NxpButton>
            <NxpButton onClick={handleSubmit} loading={isSubmitting}>
              {t("common.confirm")}
            </NxpButton>
          </div>
        )}
      </div>
    </>
  );
};

export default AccessRightPanel;
