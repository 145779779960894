import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AppButton from "../../../../../app/components/app-button";
import NewSupportingRecordModal from "./NewSupportingRecordModal";

interface NewSupportingRecordButtonProps {
  onRefresh: () => void;
}

const NewSupportingRecordButton: React.FC<NewSupportingRecordButtonProps> = ({
  onRefresh,
}) => {
  const { t } = useTranslation();

  const [showNewDocumentModal, setShowNewDocumentModal] = useState(false);

  const handleModalClose = () => setShowNewDocumentModal(false);

  return (
    <>
      <AppButton
        className="voc-event-supporting-new"
        onClick={() => setShowNewDocumentModal(true)}
      >
        {t("voc.eventPage.records.addRecords")}
      </AppButton>

      {showNewDocumentModal && (
        <NewSupportingRecordModal
          onRefresh={onRefresh}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default NewSupportingRecordButton;
