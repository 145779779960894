import { Route } from "react-router-dom";

import AppProtectedPage from "../app/components/app-router/AppProtectedPage";
import AccessRightPageContainer from "./pages/access-right/AccessRightPageContainer";
import ManageRolePage from "./pages/manage-role/ManageRolePage";

export const accessControlRoutes = [
  <Route
    key="access-right"
    path="/entities/:entityId/admin/access"
    element={<AppProtectedPage component={<AccessRightPageContainer />} />}
  />,
  <Route
    key="manage-role"
    path="/entities/:entityId/admin/roles"
    element={
      <AppProtectedPage requireEntityReady component={<ManageRolePage />} />
    }
  />,
];
