import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { QuerySettings } from "../../../app/components/app-data-table";
import { FilterOption } from "../../../app/components/app-filter";
import useAppContext from "../../../app/hooks/useAppContext";
import { useAsync } from "../../../app/hooks/useAsync";
import { getExcel } from "../../../app/services/app";
import { VocScreenNameEnums, VocSiteEventListItem } from "../../../app/types";
import { REGISTER_LAZY_LOAD_SIZE } from "../../../app/utils/const";
import { shallowEqual } from "../../../app/utils/object";
import { searchSiteEvents } from "../../site-event-services";
import SiteEventListLayout from "./SiteEventListLayout";

const SiteEventListContainer: React.FC = () => {
  const { activeEntity, serviceConfig, errorHandler, getCustomizedScreen } =
    useAppContext();
  const navigate = useNavigate();

  const [listingState, setListingState] = useState<{
    itemList?: VocSiteEventListItem[];
    filterOptions?: FilterOption[];
    querySettings?: QuerySettings;
  }>({
    filterOptions: [],
    querySettings: {
      pageSize: 0, // pageSize = 0 for lazy loading
      currentPage: 1,
      search: "",
      sortColumns: [],
    },
  });

  const [eventListAsyncResult, setEventListRequest] = useAsync<{
    results: VocSiteEventListItem[];
    pagination: { countAll: number };
  }>(undefined, {
    onSuccess: (data) => {
      setListingState((prevState) => {
        if (
          prevState.querySettings.currentPage > 1 &&
          prevState.querySettings.pageSize === 0
        ) {
          return {
            ...prevState,
            itemList: [...prevState.itemList, ...data.results],
          };
        } else {
          return { ...prevState, itemList: [...data.results] };
        }
      });
    },
    onError: (ex) => errorHandler(ex, "getting site events"),
  });

  const fetchSiteEventList = useCallback(
    (querySettings: QuerySettings, filterOptions: FilterOption[]) => {
      setEventListRequest(() =>
        searchSiteEvents(
          serviceConfig,
          {
            perPage: querySettings.pageSize,
            page: querySettings.currentPage.toString(),
            sort: querySettings.sortColumns.length
              ? querySettings.sortColumns[0].fieldName
              : undefined,
            order: querySettings.sortColumns.length
              ? querySettings.sortColumns[0].order
              : undefined,
            search: querySettings.search,
          },
          filterOptions
        )
      );
    },
    [serviceConfig, setEventListRequest]
  );

  const setListingStateAndFetch = useCallback(
    (callback: (prevState: typeof listingState) => typeof listingState) => {
      setListingState((prevState) => {
        const nextState = callback(prevState);
        fetchSiteEventList(nextState.querySettings, nextState.filterOptions);
        return nextState;
      });
    },
    [fetchSiteEventList]
  );

  useLayoutEffect(() => {
    if (
      listingState.itemList === undefined &&
      !eventListAsyncResult.loading &&
      activeEntity?.id
    ) {
      if (window.history.state) {
        setListingStateAndFetch((prevState) => {
          const nextState = { ...prevState };
          nextState.itemList = [];
          if (window.history.state.querySettings) {
            nextState.querySettings = {
              ...window.history.state.querySettings,
            };
          }
          if (window.history.state.filterOptions) {
            nextState.filterOptions = [...window.history.state.filterOptions];
          }
          return nextState;
        });
      } else {
        setListingStateAndFetch((prevState) => ({
          ...prevState,
          itemList: [],
        }));
      }
    }
  }, [
    activeEntity?.id,
    eventListAsyncResult.loading,
    fetchSiteEventList,
    listingState.filterOptions,
    listingState.itemList,
    listingState.querySettings,
    serviceConfig,
    setListingStateAndFetch,
  ]);

  useEffect(() => {
    window.history.replaceState(
      {
        querySettings: listingState.querySettings,
        filterOptions: listingState.filterOptions,
      },
      document.title
    );
  }, [listingState.filterOptions, listingState.querySettings]);

  const handleDataTableQuery = useCallback(
    (querySettings: QuerySettings) => {
      // avoid unnecessary rendering
      if (!shallowEqual(listingState.querySettings, querySettings)) {
        setListingStateAndFetch((prevState) => ({
          ...prevState,
          querySettings,
        }));
      }
    },
    [listingState.querySettings, setListingStateAndFetch]
  );

  const handleRowClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, dataItem: VocSiteEventListItem) => {
      navigate(`./${dataItem.id}`);
    },
    [navigate]
  );

  const handleFilterChange = useCallback(
    (filterOptions: FilterOption[]) => {
      setListingStateAndFetch((prevState) => ({ ...prevState, filterOptions })); // [...newFilterOptions].map((option) => ({ ...option })));
    },
    [setListingStateAndFetch]
  );

  const handleDataTableFooterAggregate = useCallback(() =>
    // rowDataItem: VocSiteEventListItem,
    // footerDataItem: VocSiteEventListItem,
    // isLastRow: boolean
    {
      // if (Number.isFinite(rowDataItem.daysOfOutstandingRecords)) {
      //   footerDataItem.daysOfOutstandingRecords =
      //     (footerDataItem.daysOfOutstandingRecords || 0) +
      //     rowDataItem.daysOfOutstandingRecords;
      // }
    }, []);

  const handleLazyLoad = useCallback(() => {
    setListingStateAndFetch((prevState) => {
      if (
        eventListAsyncResult?.data?.pagination.countAll >
        REGISTER_LAZY_LOAD_SIZE * prevState.querySettings.currentPage
      ) {
        return {
          ...prevState,
          querySettings: {
            ...prevState.querySettings,
            currentPage: prevState.querySettings.currentPage + 1,
          },
        };
      } else {
        return prevState;
      }
    });
  }, [eventListAsyncResult?.data, setListingStateAndFetch]);

  const customUi = getCustomizedScreen(
    VocScreenNameEnums.SITE_EVENT_REGISTER_SCREEN
  );

  const handleExportExcel = useCallback(() => {
    getExcel(customUi.screen.id, serviceConfig);
  }, [customUi.screen.id, serviceConfig]);

  return (
    customUi !== undefined && (
      <div className="site-event">
        <SiteEventListLayout
          customUi={customUi}
          entityId={activeEntity?.id}
          querySettings={listingState.querySettings}
          totalRecordCount={eventListAsyncResult?.data?.pagination.countAll}
          loading={eventListAsyncResult?.loading}
          dataSource={listingState.itemList}
          filterOptions={listingState.filterOptions}
          onDataTableQuery={handleDataTableQuery}
          onDataTableRowClick={handleRowClick}
          onDataTableFooterAggregate={handleDataTableFooterAggregate}
          onFilterChange={handleFilterChange}
          onExportExcel={handleExportExcel}
          onLazyLoad={handleLazyLoad}
        />
      </div>
    )
  );
};

export default SiteEventListContainer;
