import {
  NxpFormItem,
  sorterForNumber,
  sorterForString,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";
import { ColumnProps } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useAppContext from "../../../../app/hooks/useAppContext";
import i18n from "../../../../app/i18n/i18n";
import { VocGetUpdateValuesSubrecord } from "../../../../app/types";
import { getFormattedInputNumberControlProps } from "../../../../app/utils/number/getFormattedInputNumberControlProps";
import {
  calculateOhnp,
  calculateProfitLoss,
  calculateTotalCost,
  parseNumber,
} from "./helpers/value-breakdown-helper";
import { valuationMethodOptions } from "./value-breakdown.type";

const useValueBreakdownColumns = (
  editForm: VocGetUpdateValuesSubrecord,
  onSetEditForm: (
    value: React.SetStateAction<VocGetUpdateValuesSubrecord | undefined>
  ) => void,
  validationError: ValidationResult<VocGetUpdateValuesSubrecord>
) => {
  const { t } = useTranslation();

  const { getDictionary } = useAppContext();

  const handleNumberChange = useCallback(
    (fieldName: string, newValue: number) => {
      const parsedValue = parseNumber(newValue);
      const updatedForm = {
        ...editForm,
        [fieldName]: parsedValue,
      };
      const newTotalCost = calculateTotalCost(updatedForm);
      const newProfitLoss = calculateProfitLoss(updatedForm, newTotalCost);
      const newOhnp = calculateOhnp(updatedForm, newProfitLoss, newTotalCost);

      onSetEditForm({
        ...updatedForm,
        totalCost: newTotalCost,
        profitLoss: newProfitLoss,
        ohnp: newOhnp,
      });
    },
    [editForm, onSetEditForm]
  );

  const useGetColumns = useCallback(
    (): ColumnProps<VocGetUpdateValuesSubrecord>[] => [
      {
        title: t("voc.eventPage.values.description"),
        dataIndex: "description",
        width: 150,
        fixed: "left",
        defaultSortOrder: "descend",
        sorter: (a, b) => sorterForString(a.description, b.description),
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;
          return (
            <NxpFormItem
              editing={editing}
              controlType="input"
              error={validationError.description}
              controlProps={{
                value: editing ? editForm?.description : record.description,
                onChange: (e) =>
                  onSetEditForm(
                    (prevState) =>
                      prevState && { ...prevState, description: e.target.value }
                  ),
              }}
            />
          );
        },
      },
      {
        title: t("voc.eventPage.VODetails.methodOfValuation"),
        dataIndex: "valuationMethod",
        width: 150,
        sorter: (a, b) =>
          sorterForString(
            valuationMethodOptions.find(
              (otp) => otp.enumValue === a.valuationMethod
            ).render,
            valuationMethodOptions.find(
              (otp) => otp.enumValue === b.valuationMethod
            ).render
          ),
        render(_: unknown, record: VocGetUpdateValuesSubrecord) {
          const editing = editForm?.id === record.id;
          return (
            <NxpFormItem
              editing={editing}
              controlType="select"
              controlProps={{
                value:
                  (editing
                    ? editForm?.valuationMethod
                    : record.valuationMethod) || undefined,
                options: valuationMethodOptions.map((otp) => ({
                  value: otp?.enumValue,
                  label: otp?.render,
                })),
                onChange: (val) =>
                  onSetEditForm(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        valuationMethod: val.toString(),
                      }
                  ),
              }}
            />
          );
        },
      },
      {
        title: "COST CODE",
        dataIndex: "costCode",
        width: 150,
        sorter: (a, b) => sorterForString(a.costCode, b.costCode),
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;
          return (
            <NxpFormItem
              editing={editing}
              controlType="select"
              controlProps={{
                value:
                  (editing ? editForm?.costCode : record.costCode) || undefined,
                options: getDictionary("COST_CODES")?.members.map((otp) => ({
                  value: otp?.key,
                  label: otp?.label,
                })),
                onChange: (val) =>
                  onSetEditForm(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        costCode: val.toString(),
                      }
                  ),
              }}
            />
          );
        },
      },
      {
        title: t("voc.eventPage.values.directCostPrev"),
        dataIndex: "directCostPrevious",
        width: 150,
        sorter: (a, b) =>
          sorterForNumber(a.directCostPrevious, b.directCostPrevious),
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;
          if (!editing) {
            return (
              <>
                $
                {record.directCostPrevious?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            );
          }
          return (
            <NxpFormItem
              editing={editing}
              controlType="inputNumber"
              controlProps={{
                ...getFormattedInputNumberControlProps(),
                min: "0",
                decimalPlace: 2,
                prefix: "$",
                value: editing
                  ? editForm?.directCostPrevious
                  : record?.directCostPrevious,
                onChange: (value) =>
                  handleNumberChange("directCostPrevious", value),
              }}
            />
          );
        },
      },
      {
        title: t("voc.eventPage.values.indirectCostPrev"),
        dataIndex: "indirectCostPrevious",
        width: 150,
        sorter: (a, b) =>
          sorterForNumber(a.indirectCostPrevious, b.indirectCostPrevious),
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;
          if (!editing) {
            return (
              <>
                $
                {record.indirectCostPrevious?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            );
          }
          return (
            <NxpFormItem
              editing={editing}
              controlType="inputNumber"
              controlProps={{
                ...getFormattedInputNumberControlProps(),
                min: "0",
                decimalPlace: 2,
                prefix: "$",
                value: editing
                  ? editForm?.indirectCostPrevious
                  : record.indirectCostPrevious,
                onChange: (value) =>
                  handleNumberChange("indirectCostPrevious", value),
              }}
            />
          );
        },
      },
      {
        title: t("voc.eventPage.values.plannedCostDirect"),
        dataIndex: "directCostPlanned",
        width: 150,
        sorter: (a, b) =>
          sorterForNumber(a.directCostPlanned, b.directCostPlanned),
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;
          if (!editing) {
            return (
              <>
                $
                {record.directCostPlanned?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            );
          }
          return (
            <NxpFormItem
              editing={editing}
              controlType="inputNumber"
              controlProps={{
                ...getFormattedInputNumberControlProps(),
                min: "0",
                decimalPlace: 2,
                prefix: "$",
                value: editing
                  ? editForm?.directCostPlanned
                  : record.directCostPlanned,
                onChange: (value) =>
                  handleNumberChange("directCostPlanned", value),
              }}
            />
          );
        },
      },
      {
        title: t("voc.eventPage.values.plannedCostIndirect"),
        dataIndex: "indirectCostPlanned",
        width: 150,
        sorter: (a, b) =>
          sorterForNumber(a.indirectCostPlanned, b.indirectCostPlanned),
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;
          if (!editing) {
            return (
              <>
                $
                {record.indirectCostPlanned?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            );
          }
          return (
            <NxpFormItem
              editing={editing}
              controlType="inputNumber"
              controlProps={{
                ...getFormattedInputNumberControlProps(),
                min: "0",
                decimalPlace: 0,
                prefix: "$",
                value: editing
                  ? editForm?.indirectCostPlanned
                  : record.indirectCostPlanned,
                onChange: (value) =>
                  handleNumberChange("indirectCostPlanned", value),
              }}
            />
          );
        },
      },
      {
        title: t("voc.eventPage.values.costCloseOutRecord"),
        dataIndex: "closeOut",
        width: 150,
        sorter: (a, b) => sorterForNumber(a.closeOut, b.closeOut),
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;
          if (!editing) {
            return (
              <>
                $
                {record.closeOut?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            );
          }
          return (
            <NxpFormItem
              editing={editing}
              controlType="inputNumber"
              controlProps={{
                ...getFormattedInputNumberControlProps(),
                min: "0",
                decimalPlace: 0,
                prefix: "$",
                value: editing ? editForm?.closeOut : record.closeOut,
                onChange: (value) => handleNumberChange("closeOut", value),
              }}
            />
          );
        },
      },
      {
        title: t("voc.eventPage.values.costGnARecord"),
        dataIndex: "gna",
        width: 150,
        sorter: (a, b) => sorterForNumber(a.gna, b.gna),
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;
          if (!editing) {
            return (
              <>
                $
                {record.gna?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            );
          }
          return (
            <NxpFormItem
              editing={editing}
              controlType="inputNumber"
              controlProps={{
                ...getFormattedInputNumberControlProps(),
                min: "0",
                decimalPlace: 2,
                prefix: "$",
                value: editing ? editForm?.gna : record.gna,
                onChange: (value) => handleNumberChange("gna", value),
              }}
            />
          );
        },
      },
      {
        title: t("voc.eventPage.values.totalCostRecord"),
        dataIndex: "totalCostRecord",
        width: 150,
        sorter: (a, b) => sorterForNumber(a.totalCost, b.totalCost),
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;
          if (!editing) {
            return (
              <>
                $
                {record.totalCost?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            );
          }
          //Sum (1,2,3,4,5,6)
          return (
            <NxpFormItem
              editing={editing}
              controlType="inputNumber"
              controlProps={{
                ...getFormattedInputNumberControlProps(),
                min: "0",
                decimalPlace: 2,
                prefix: "$",
                disabled: true,
                value: editForm.totalCost,
              }}
            />
          );
        },
      },
      {
        title: t("voc.eventPage.values.revenueInternalTarget"),
        dataIndex: "revenue",
        sorter: (a, b) => sorterForNumber(a.revenue, b.revenue),
        width: 150,
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;
          if (!editing) {
            return (
              <>
                $
                {record.revenue?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            );
          }
          return (
            <NxpFormItem
              editing={editing}
              controlType="inputNumber"
              controlProps={{
                ...getFormattedInputNumberControlProps(),
                min: "0",
                decimalPlace: 2,
                prefix: "$",
                value: editing ? editForm?.revenue : record.revenue,
                onChange: (value) => handleNumberChange("revenue", value),
              }}
            />
          );
        },
      },
      {
        title: t("voc.eventPage.values.profitLossRecord"),
        dataIndex: "profitLoss",
        width: 150,
        sorter: (a, b) => sorterForNumber(a.profitLoss, b.profitLoss),
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;
          if (!editing) {
            return (
              <>
                $
                {record.profitLoss?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            );
          }
          if (editing) {
            return (
              <NxpFormItem
                editing={editing}
                controlType="inputNumber"
                controlProps={{
                  disabled: true,
                  prefix: "$",
                  ...getFormattedInputNumberControlProps(),
                  decimalPlace: 2,
                  value: editForm.profitLoss,
                  onChange: (value) => handleNumberChange("profitLoss", value),
                }}
              />
            );
          }
        },
      },
      {
        title: t("voc.eventPage.values.ohnpRecord"),
        dataIndex: "ohnp",
        width: 150,
        sorter: (a, b) => sorterForNumber(a.ohnp, b.ohnp),
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;

          if (!editing) {
            return (
              <>
                {record.ohnp?.toLocaleString(i18n.language, {
                  style: "percent",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            );
          }
          if (editing) {
            return (
              <NxpFormItem
                editing={editing}
                controlType="inputPercent"
                controlProps={{
                  disabled: true,
                  ...getFormattedInputNumberControlProps(),
                  decimalPlace: 2,
                  value: editForm.ohnp,
                  addonAfter: "%",
                }}
              />
            );
          }
        },
      },
      {
        title: t("voc.eventPage.values.paymentApplicationsRecord"),
        dataIndex: "paymentApplication",
        width: 150,
        sorter: (a, b) =>
          sorterForNumber(a.paymentApplication, b.paymentApplication),
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;
          if (!editing) {
            return (
              <>
                $
                {record.paymentApplication?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            );
          }
          return (
            <NxpFormItem
              editing={editing}
              controlType="inputNumber"
              controlProps={{
                ...getFormattedInputNumberControlProps(),
                min: "0",
                decimalPlace: 0,
                prefix: "$",
                value: editing
                  ? editForm?.paymentApplication
                  : record.paymentApplication,
                onChange: (value) =>
                  onSetEditForm(
                    (prevState) =>
                      prevState && { ...prevState, paymentApplication: value }
                  ),
              }}
            />
          );
        },
      },
      {
        title: t("voc.eventPage.values.clientAssessedRecord"),
        dataIndex: "clientAssessed",
        sorter: (a, b) => sorterForNumber(a.clientAssessed, b.clientAssessed),
        width: 150,
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;
          if (!editing) {
            return (
              <>
                $
                {record.clientAssessed?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            );
          }
          return (
            <NxpFormItem
              editing={editing}
              controlType="inputNumber"
              controlProps={{
                ...getFormattedInputNumberControlProps(),
                min: "0",
                decimalPlace: 0,
                prefix: "$",
                value: editing
                  ? editForm?.clientAssessed
                  : record.clientAssessed,
                onChange: (value) =>
                  onSetEditForm(
                    (prevState) =>
                      prevState && { ...prevState, clientAssessed: value }
                  ),
              }}
            />
          );
        },
      },
      {
        title: t("voc.eventPage.values.clientCertifiedRecord"),
        dataIndex: "clientCertified",
        width: 150,
        sorter: (a, b) => sorterForNumber(a.clientCertified, b.clientCertified),
        render: (_: unknown, record: VocGetUpdateValuesSubrecord) => {
          const editing = editForm?.id === record.id;
          if (!editing) {
            return (
              <>
                $
                {record.clientCertified?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            );
          }
          return (
            <NxpFormItem
              editing={editing}
              controlType="inputNumber"
              controlProps={{
                ...getFormattedInputNumberControlProps(),
                min: "0",
                decimalPlace: 0,
                prefix: "$",
                value: editing
                  ? editForm?.clientCertified
                  : record.clientCertified,
                onChange: (value) =>
                  onSetEditForm(
                    (prevState) =>
                      prevState && { ...prevState, clientCertified: value }
                  ),
              }}
            />
          );
        },
      },
    ],
    [
      t,
      editForm,
      validationError.description,
      onSetEditForm,
      getDictionary,
      handleNumberChange,
    ]
  );
  return useGetColumns();
};

export default useValueBreakdownColumns;
