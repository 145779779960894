import React from "react";
import AppButton from "../../../../app/components/app-button/AppButton";
import ImportExcelContainer from "./ImportExcelContainer";
import { useTranslation } from "react-i18next";

interface ImportExcelButtonProps {
  entityId: string;
  onCancel: () => void;
}

const ImportExcelButton: React.FC<ImportExcelButtonProps> = (props) => {
  const { entityId, onCancel } = props;
  const [showImportExcelModal, setShowImportExcelModal] = React.useState(false);
  const handleModalClose = () => setShowImportExcelModal(false);
  const { t } = useTranslation();
  return (
    <>
      <AppButton
        className="voc-value-register-import-excel"
        onClick={() => setShowImportExcelModal(true)}
      >
        {t("voc.value.register.importExcelButton")}
      </AppButton>
      {showImportExcelModal && (
        <ImportExcelContainer
          onCancel={onCancel}
          entityId={entityId}
          showImportExcelModal={showImportExcelModal}
          onModalClose={handleModalClose}
        ></ImportExcelContainer>
      )}
    </>
  );
};

export default ImportExcelButton;
