import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import _ from "lodash";
import { IDropdownOption } from "office-ui-fabric-react";

import { QuerySettings } from "../../app/components/app-data-table";
import { FilterOption } from "../../app/components/app-filter";
import useAppContext from "../../app/hooks/useAppContext";
import { useAsync } from "../../app/hooks/useAsync";
import { getExcel, getFeedback } from "../../app/services/app";
import { VocFeedbackListItem } from "../../app/types";
import { shallowEqual } from "../../app/utils/object";
import { getNavigationType } from "../utils/routes";
import FeedbackListLayout from "./FeedbackListLayout";
import AccessVisible from "../../app/components/app-access-visible/AppAccessVisible";
import { useTranslation } from "react-i18next";

interface FeedbackListContainerProps {}

const FeedbackListContainer: React.FC<FeedbackListContainerProps> = () => {
  const { entityId } = useParams();
  const { libraries, serviceConfig, errorHandler } = useAppContext();
  const { t } = useTranslation();

  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);

  const [, setFilterColumnDropdownOptions] = useState<{
    statusWithClient: IDropdownOption[];
    eventSource: IDropdownOption[];
  }>();
  useEffect(() => {
    setFilterColumnDropdownOptions((prevState) => ({
      ...prevState,
      statusWithClient: libraries["VOC_CLIENT_WITH_STATUS"].map((item) => ({
        text: item.name,
        key: item.code,
      })),
    }));
    setFilterColumnDropdownOptions((prevState) => ({
      ...prevState,
      eventSource: libraries["VOC_SOURCES"].map((item) => ({
        text: item.label,
        key: item.value,
      })),
    }));
  }, [libraries]);

  const [dataTableQuerySettings, setDataTableQuerySettings] =
    useState<QuerySettings>();

  useLayoutEffect(() => {
    let dataTableQuerySettings: QuerySettings = {
      pageSize: 0,
      currentPage: 1,
      search: "",
      sortColumns: [],
    }; // default pagination params
    let filterOptions: FilterOption[] = [];
    if (getNavigationType() === "reload") {
      window.history.replaceState(undefined, document.title);
    } else {
      if (window.history.state) {
        if (window.history.state.dataTableQuerySettings) {
          dataTableQuerySettings = {
            ...window.history.state.dataTableQuerySettings,
          };
        }
        if (window.history.state.filterOptions) {
          filterOptions = [...window.history.state.filterOptions];
        }
      }
    }
    setFilterOptions(filterOptions);
    setDataTableQuerySettings(dataTableQuerySettings);
  }, [entityId]);

  const [feedbackListAsyncResult, setFeedbackListRequest] =
    useAsync<VocFeedbackListItem[]>();

  // fetch data base on a change in table query
  useEffect(() => {
    if (dataTableQuerySettings && filterOptions) {
      window.history.replaceState(
        {
          dataTableQuerySettings,
          filterOptions,
        },
        document.title
      );
      setFeedbackListRequest(
        () =>
          new Promise((resolve) =>
            getFeedback(
              serviceConfig,
              {
                perPage: dataTableQuerySettings.pageSize,
                page: dataTableQuerySettings.currentPage.toString(),
                sort: dataTableQuerySettings.sortColumns.length
                  ? dataTableQuerySettings.sortColumns[0].fieldName
                  : undefined,
                order: dataTableQuerySettings.sortColumns.length
                  ? dataTableQuerySettings.sortColumns[0].order
                  : undefined,
              },
              filterOptions
            )
              .then((feedbacks) => {
                const results: VocFeedbackListItem[] = _.get(
                  feedbacks,
                  "results",
                  feedbacks
                );
                resolve(
                  results.map<VocFeedbackListItem>((feedback) => {
                    return {
                      feedbackType: {
                        label: undefined,
                        value: _.get(feedback, "inquiryType"),
                      },
                      feedbackId: _.get(feedback, "id"),
                      recordDate: _.get(feedback, "createdOn"),
                      link: _.get(
                        _.head(_.get(feedback, "relatedFiles")),
                        "url",
                        null
                      ),
                      fileName: _.get(
                        _.head(_.get(feedback, "relatedFiles")),
                        "fileName",
                        null
                      ),
                      entityCode: _.get(feedback, "entityCode")
                        ? _.get(feedback, "entityCode")
                        : "N/A",
                      raisedBy: _.get(feedback, "isAnonymous")
                        ? "Anonymous"
                        : _.get(feedback, "author.name"),
                      feedbackDes: _.get(feedback, "description"),
                      status: _.get(feedback, "isRead", false),
                    };
                  })
                );
              })
              .catch((error) => {
                errorHandler(error, "Fetch get feedback error ");
              })
          )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTableQuerySettings, filterOptions]);

  const handleDataTableQuery = useCallback((querySettings: QuerySettings) => {
    setDataTableQuerySettings((prevState) => {
      if (shallowEqual(prevState, querySettings)) {
        // avoid unnecessary rendering
        return prevState;
      }
      return { ...querySettings };
    });
  }, []);

  const handleFilterChange = useCallback((filterOptions: FilterOption[]) => {
    setFilterOptions([...filterOptions].map((option) => ({ ...option })));
  }, []);

  const handleExportExcel = useCallback(async () => {
    return await getExcel(1, serviceConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AccessVisible
      objectCode="feedbacks"
      actionType="reader"
      fallback={<i>{t("app.permission.accessDenied")}</i>}
    >
      <FeedbackListLayout
        querySettings={dataTableQuerySettings}
        dataSource={feedbackListAsyncResult?.data}
        filterOptions={filterOptions}
        onFilterChange={handleFilterChange}
        onExportExcel={handleExportExcel}
        onDataTableQuery={handleDataTableQuery}
      />
    </AccessVisible>
  );
};

export default FeedbackListContainer;
