import { Route } from "react-router-dom";

import AppProtectedPage from "../app/components/app-router/AppProtectedPage";
import SiteEventDetailsContainer from "./pages/site-event-details/SiteEventDetailsContainer";
import SiteEventListContainer from "./pages/site-event-list/SiteEventListContainer";

export const siteEventRoutes = [
  <Route
    key="site-event-list"
    path="/entities/:entityId/site-events"
    element={
      <AppProtectedPage
        requireEntityReady
        component={<SiteEventListContainer />}
        accessRight={{ objectCode: "event", actionType: "view" }}
      />
    }
  />,
  <Route
    key="site-event-details"
    path="/entities/:entityId/site-events/:siteEventId"
    element={
      <AppProtectedPage
        requireEntityReady
        component={<SiteEventDetailsContainer />}
        accessRight={{ objectCode: "event", actionType: "view" }}
      />
    }
  />,
];
