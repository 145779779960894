import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";
import AppDataTable, {
  ColumnDataType,
  DataTableColumn,
  QuerySettings,
  useDataTableWrapperResized,
} from "../../../app/components/app-data-table";
import { getDataTableDefaultCellContent } from "../../../app/components/app-data-table/AppDataTableRow";
import AppExportExcelButton from "../../../app/components/app-export-excel-button";
import AppFilter, { FilterOption } from "../../../app/components/app-filter";
import AppHeader from "../../../app/components/app-header";
import useAppContext from "../../../app/hooks/useAppContext";
import {
  VocCustomUi,
  VocCustomUiLayoutItemType,
  VocEventListItem,
  VocEventType,
} from "../../../app/types";
import { hasValue } from "../../../app/utils/has-value";
import CreateEventHeaderButton from "./create-event/CreateEventHeaderButton";

import AppIconLink from "../../../app/components/app-icon-link";
import { getUserDisplayName } from "../../../app/services/app";
import { compensationEventDefaultColumns } from "../../../app/utils/compensation-event-default-columns";
import { convertUrl } from "../../../app/utils/string";
import "./EventListLayout.scss";

interface EventListLayoutProps {
  entityId: string;
  querySettings: QuerySettings;
  totalRecordCount: number;
  loading: boolean;
  dataSource: VocEventListItem[];
  filterOptions: FilterOption[];
  customUi: VocCustomUi;
  onDataTableQuery: (querySettings: QuerySettings) => void;
  onDataTableRowClick: (
    e: React.MouseEvent<HTMLDivElement>,
    dataItem: VocEventListItem
  ) => void;

  onFilterChange: (filterOptions: FilterOption[]) => void;
  onDataTableFooterAggregate: (
    rowDataItem: VocEventListItem,
    footerDataItem: VocEventListItem,
    isLastRow: boolean
  ) => void;
  onLazyLoad: () => void;

  onExportExcel: () => void;
}

const EventListLayout: React.FC<EventListLayoutProps> = ({
  entityId,
  querySettings,
  totalRecordCount,
  loading,
  dataSource,
  filterOptions,
  customUi,
  onDataTableQuery,
  onDataTableRowClick,
  onFilterChange,
  onDataTableFooterAggregate,
  onLazyLoad,
  onExportExcel,
}) => {
  const { t } = useTranslation();
  const { libraries, getLibraryItemByKeyValue } = useAppContext();

  const getHeaderClassName = (
    filterOptions: FilterOption[],
    fileName: String
  ) =>
    filterOptions.find((item) => item.fieldName === fileName) &&
    "header-filtered";

  const compensationEventColumnOnRenderMap = {
    eventTypeSerial: (item: VocEventListItem) =>
      hasValue(item.eventTypeSerial) ? item.eventTypeSerial : item.claimSerial,
    eventReference: (item: VocEventListItem) => {
      const url = hasValue(item.eventReference, ["exclude-empty-array"])
        ? convertUrl(
            Array.isArray(item.eventReference)
              ? item.eventReference[0]
              : item.eventReference
          )
        : undefined;
      if (url) {
        return (
          <AppIconLink href={url} targetBlank={true} stopPropagation={true} />
        );
      }
      return item.eventReference?.toString() || null;
    },
    eventType: (item: VocEventListItem) =>
      item.eventType && t("voc.common.eventType." + item.eventType),
    eventSource: (item: VocEventListItem) =>
      getLibraryItemByKeyValue("VOC_SOURCES", item.eventSource)?.label ??
      item.eventSource,
    eventSourceType: (item: VocEventListItem) =>
      getLibraryItemByKeyValue("VOC_SOURCE_TYPE", item.eventSourceType)
        ?.label ?? item.eventSourceType,
    statusWithClient: (item: VocEventListItem) =>
      getLibraryItemByKeyValue("VOC_CLIENT_WITH_STATUS", item.statusWithClient)
        ?.label ?? item.statusWithClient,
    recovery: (item) => {
      const convertRecovery = Number(item.recovery) * 100;
      return <>{convertRecovery.toFixed(2)} % </>;
    },
    commercialOwner: (item: VocEventListItem) => {
      return getUserDisplayName(item.commercialOwner);
    },
    recordOwner: (item: VocEventListItem) =>
      getUserDisplayName(item.recordOwner),
    recordSupporter: (item: VocEventListItem) =>
      getUserDisplayName(item.recordSupporter),
  };

  const compensationEventColumnDropdownOptionsMap = {
    eventType: [
      ...Object.entries(VocEventType).map((entry) => ({
        key: entry[1] as string,
        text: entry[0].replace(/_/g, " "),
      })),
    ],
    eventSourceType: libraries["VOC_SOURCE_TYPE"]?.map((item) => ({
      text: item.label,
      key: item.value,
    })),
    statusWithClient: libraries["VOC_CLIENT_WITH_STATUS"].map((item) => ({
      text: item.label,
      key: item.value,
    })),
  };

  const columns = useMemo<DataTableColumn<VocEventListItem>[]>(
    () =>
      compensationEventDefaultColumns.map((col) => {
        col.name = t(col.name);
        col.headerClassName = getHeaderClassName(filterOptions, col.fieldName);
        col.onRender = compensationEventColumnOnRenderMap[col.key];
        col.columnDropdownOptions =
          compensationEventColumnDropdownOptionsMap[col.key];
        return col;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const wrapperRef = useDataTableWrapperResized(168);

  const columnCustomized: DataTableColumn<VocEventListItem>[] = useMemo(
    () =>
      customUi.layout
        .filter(
          (customCol) =>
            customCol.type === VocCustomUiLayoutItemType.DataTableColumn &&
            customCol.display &&
            (customCol.isCustomField ||
              columns.find((col) => col.key === customCol.key))
        )
        .map((customCol) => {
          if (
            customCol.type === VocCustomUiLayoutItemType.DataTableColumn &&
            customCol.isCustomField
          ) {
            return {
              key: customCol.key,
              name: customCol.name,
              fieldName: customCol.key,
              minWidth: 100,
              isResizable: true,
              isSortable: true,
              columnDataType: customCol.columnDataType || ColumnDataType.String,
              headerClassName:
                filterOptions.find(
                  (item) => item.fieldName === customCol.key
                ) && "header-filtered",
              onRender: (item: VocEventListItem) =>
                hasValue(item.customization) &&
                getDataTableDefaultCellContent(
                  customCol.columnDataType,
                  item.customization[customCol.key]
                ),
            };
          }
          const col = columns.find((col) => col.key === customCol.key);
          // if (customCol.type === VocCustomUiLayoutItemType.DataTableColumn) {
          //   col.name = customCol.customName;
          // }
          if (customCol.key === "eventTitle") {
            col.headerClassName = "title";
            col.cellClassName = col.headerClassName;
            col.footerClassName = col.headerClassName;
          }
          return col;
        }),
    [columns, customUi.layout, filterOptions]
  );

  const filterColumns = useMemo(
    () => columnCustomized.filter((col) => col.key !== "eventReference"),
    [columnCustomized]
  );

  return (
    <>
      <AppHeader text={t("voc.common.eventRegistration")}>
        <AppFilter
          filterOptions={filterOptions}
          onFilterChange={onFilterChange}
          filterColumns={filterColumns}
        />
        <AppExportExcelButton onExportExcel={onExportExcel} />
        <AccessVisible objectCode="compensation_event" actionType="create">
          <CreateEventHeaderButton entityId={entityId} />
        </AccessVisible>
      </AppHeader>
      <div className="voc-ce-register" ref={wrapperRef}>
        <AppDataTable
          columns={columnCustomized}
          keyColumnName="id"
          dataSource={dataSource}
          querySettings={querySettings}
          loading={loading}
          totalRecordCount={totalRecordCount}
          enableSearch={true}
          enablePagination={true}
          fixFirstColumn={true}
          onDataTableQuery={onDataTableQuery}
          onRowClick={onDataTableRowClick}
          onFooterAggregate={onDataTableFooterAggregate}
          onLazyLoad={onLazyLoad}
        />
      </div>
    </>
  );
};

export default EventListLayout;
