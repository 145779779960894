import { ThemeProvider } from "react-jss";

import "../i18n/i18n";

import { datadogLogs } from "@datadog/browser-logs";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { ConfigProvider as AntDesignConfigProvider } from "antd";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";

import AppContextProvider from "./app-context/AppContextProvider";
import AppErrorBoundary from "./app-error-boundary/AppErrorBoundary";
import AppRouter from "./app-router";
import { appTheme } from "./appTheme";
import keycloakInstance from "./keycloak";

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENTTOKEN,
  site: process.env.REACT_APP_DATADOG_SITE,
  forwardErrorsToLogs: false,
  sampleRate: Number(process.env.REACT_APP_DATADOG_SAMPLERATE) || 0,
});

function App() {
  initializeIcons();
  return (
    <AppErrorBoundary title="App">
      <ReactKeycloakProvider
        authClient={keycloakInstance}
        // onEvent={eventLogger}
        // onTokens={tokenLogger}
        initOptions={{
          onLoad: "login-required",
          flow: "implicit",
        }}
      >
        <ThemeProvider theme={appTheme}>
          <AntDesignConfigProvider
            theme={{
              token: {
                colorPrimary: appTheme.palette.primary,
                colorLink: appTheme.palette.textLink,
                colorSuccess: appTheme.palette.success,
                colorWarning: appTheme.palette.warning,
                colorError: appTheme.palette.danger,
                colorHighlight: appTheme.palette.warning,
                fontSize: appTheme.fontSize.main,
                colorTextHeading: appTheme.palette.text,
                colorText: appTheme.palette.text,
                colorTextSecondary: "rgba(0, 0, 0, 0.45)",
                colorTextDisabled: "rgba(0, 0, 0, 0.25)",
                colorBgContainerDisabled: appTheme.palette.dimLight,
                borderRadius: appTheme.borderRadius.main,
                colorBorder: appTheme.palette.border,
                boxShadow:
                  "0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)", // major shadow for layers
                // cannot find relevant setting after upgrade
                // "@badge-font-size-sm": "11px",
                // "@badge-color": appTheme.palette.primary,
              },
            }}
          >
            <AppContextProvider>
              <AppRouter />
            </AppContextProvider>
          </AntDesignConfigProvider>
        </ThemeProvider>
      </ReactKeycloakProvider>
    </AppErrorBoundary>
  );
}

export default App;
