import { UploadOutlined } from "@ant-design/icons";
import {
  notify,
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModalFooter,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { Button, Upload } from "antd";
import { isBefore } from "date-fns";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import useAppContext from "../../../../../app/hooks/useAppContext";
import { VocSupportDocumentEnums } from "../../../../../app/types";
import { getUserDisplayDateFormat } from "../../../../../app/utils/mappers";
import { createSupportDocument } from "../new-document-form-services";
import { CorrespondenceForm } from "../new-document-form-type";
import { useFileHandler } from "./hooks/useFileHandler";

interface NewCorrespondenceFormProps {
  onModalClose: () => void;
  onRefresh: () => void;
  documentType: VocSupportDocumentEnums | "";
}

const NewCorrespondenceForm: React.FC<NewCorrespondenceFormProps> = ({
  onModalClose,
  onRefresh,
  documentType,
}) => {
  const { siteEventId, eventId } = useParams();

  const { errorHandler, serviceConfig } = useAppContext();

  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formState, setFormState] = useState<CorrespondenceForm>({
    subject: "",
    sender: "",
    documentDate: undefined,
    recipient: "",
    comments: "",
    link: "",
    files: [],
  });

  const { handleFileChange, handleRemoveFile } =
    useFileHandler<CorrespondenceForm>(setFormState);

  const formSchema = yup.object().shape({
    subject: yup
      .string()
      .required(t("voc.eventPage.newDocument.subjectRequired")),
    sender: yup.string().required(t("voc.eventPage.newDocument.fromRequired")),
    recipient: yup.string().required(t("voc.eventPage.newDocument.toRequired")),
    documentDate: yup.date().required(t("voc.eventPage.summary.documentDate")),
    comments: yup.string().notRequired(),
    link: yup.string().url("Enter a valid URL").notRequired(),
  });

  const formItems: NxpFormGridItemProps<CorrespondenceForm>[] = [
    {
      controlType: "input",
      span: 24,
      label: t("voc.common.subject"),
      itemFieldName: "subject",
      required: true,
      controlProps: {
        value: formState?.subject,
      },
    },
    {
      controlType: "input",
      span: 12,
      label: t("voc.common.from"),
      itemFieldName: "sender",
      required: true,
      controlProps: {
        value: formState?.sender,
      },
    },
    {
      controlType: "input",
      span: 12,
      label: t("voc.common.to"),
      itemFieldName: "recipient",
      required: true,
      controlProps: {
        value: formState?.recipient,
      },
    },
    {
      controlType: "datePicker",
      span: 24,
      label: t("voc.eventPage.summary.documentDate"),
      itemFieldName: "documentDate",
      required: true,
      controlProps: {
        format: getUserDisplayDateFormat(),
        value: formState?.documentDate,
        disabledDate: (current) =>
          current && isBefore(new Date(), current.toDate()),
      },
    },
    {
      controlType: "textarea",
      span: 24,
      label: t("voc.common.comment"),
      itemFieldName: "comments",
      controlProps: {
        value: formState?.comments,
        rows: 1,
      },
    },
    {
      controlType: "custom",
      span: 12,
      label: "File",
      itemFieldName: "files",
      customContent: (
        <Upload
          beforeUpload={(file) => {
            handleFileChange(file);
            return false; // Prevent automatic upload
          }}
          onRemove={handleRemoveFile}
          fileList={formState?.files.map((file) => ({
            uid: file.assetId,
            name: file.fileName,
            status: "done",
            url: file.url,
          }))}
        >
          {formState?.files.length < 1 && (
            <Button icon={<UploadOutlined />}>
              {t("voc.common.clickToUpload")}
            </Button>
          )}
        </Upload>
      ),
    },
    {
      controlType: "input",
      span: 12,
      label: t("voc.common.link"),
      itemFieldName: "link",
      controlProps: {
        placeholder: "https://",
        value: formState?.link,
      },
    },
  ];
  const handleSaveValidated = async () => {
    try {
      setIsSubmitting(true);
      const idToUse = siteEventId || eventId;
      const isSiteEvent = Boolean(siteEventId);

      await createSupportDocument(
        idToUse,
        {
          documentFiles: formState.files,
          comment: formState?.comments,
          details: {
            documentType: documentType,
            link: formState?.link === "" ? null : formState?.link,
            subject: formState?.subject,
            documentDate: formState?.documentDate,
            sender: formState?.sender,
            recipient: formState?.recipient,
          },
          documentType: documentType,
        },
        serviceConfig,
        isSiteEvent
      );
      onModalClose();
      notify.actionCompleted();
      onRefresh();
    } catch (error) {
      errorHandler(error, "save document");
    } finally {
      setIsSubmitting(false);
    }
  };
  const [validationError, , , saveWithValidate] =
    useYupValidate<CorrespondenceForm>(
      formState,
      formSchema,
      handleSaveValidated
    );

  const handleFormGridStateChange = (
    fieldName: keyof CorrespondenceForm,
    value: unknown
  ) => {
    setFormState((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleValidateSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  return (
    <>
      <NxpFormGrid
        formItems={formItems}
        formState={formState}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
      <NxpModalFooter>
        <NxpButton
          disabled={isSubmitting}
          type="default"
          onClick={onModalClose}
        >
          {t("voc.common.cancel")}
        </NxpButton>
        <NxpButton
          disabled={isSubmitting}
          onClick={handleValidateSave}
          type="primary"
        >
          {t("voc.common.save")}
        </NxpButton>
      </NxpModalFooter>
    </>
  );
};

export default NewCorrespondenceForm;
