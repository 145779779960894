//@ts-ignore
import * as fs from "file-saver";

export default async function exportRequest(
  url: string,
  options?: RequestInit
) {
  const requestSettings: RequestInit = {
    credentials: "same-origin",
    headers: {
      accept: "application/json",
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
    },
    ...options,
  };

  return executeExportRequest(url, requestSettings);
}

async function executeExportRequest(
  url: string,
  requestSettings: RequestInit
): Promise<any> {
  return fetch(url, requestSettings)
    .then((respsonse) => {
      if (respsonse.ok) {
        return respsonse.blob().then((resp) => {
          return { blob: resp, headers: respsonse.headers };
        });
      } else {
        // Error handling (TBC)
        return Promise.reject();
      }
    })
    .then((result) => {
      const headerContent = result.headers.get("Content-Disposition") || "";
      const filename =
        headerContent.split("filename=")[1] || "exported_excel.xlsx";
      fs.saveAs(result.blob, filename);

      // const xlsURL = window.URL.createObjectURL(result.blob);
      // const tempLink = document.createElement('a');
      // tempLink.href = xlsURL;
      // tempLink.target = '_blank';
      // tempLink.setAttribute('download', filename);
      // tempLink.click();
    });
}
