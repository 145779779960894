import React from "react";
import { useTranslation } from "react-i18next";

import { cloneDeep } from "lodash";

import AppCollapsible from "../../../app/components/app-collapsible/AppCollapsible";
import AppDataListV2 from "../../../app/components/app-data-list/AppDataListV2";
import { DataListItem } from "../../../app/components/app-data-list/types";
import {
  DeletedRecord,
  VocLibDocIndex,
  VocLibraryObject,
} from "../../../app/types";
import { useLibraryHandler } from "../../hooks/useLibraryHandler";
import { DateFormatPattern, formatDate } from "@nexploretechnology/nxp-ui";

interface TargetIncomeProps {
  libraryType: "VOC_TARGET_INCOME";
  refetchCount: number;
}

export const TargetIncome: React.FC<TargetIncomeProps> = ({
  libraryType,
  refetchCount,
}) => {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = React.useState(false);

  const convertTargetIncomes = (items: VocLibraryObject[]) => {
    const converted = items.map((item) => ({
      id: item.id,
      value: item.value,
      edited: false,
      sortOrder: item.sortOrder,
      fieldDataArray: [
        { fieldData: item.fromEffectiveDate },
        { fieldData: item.effectiveDate },
        { fieldData: item.targetValue }, //double check with Kent
      ],
    }));
    return converted;
  };

  const {
    // Variables
    fetchLibraryAndUsage,
    isLibraryAndUsageLoaded,
    libraryItem,
    displayLibraryItem,
    setDisplayLibraryItem,
    // Library Custom Sort
    isCustomSort,
    saveCustomOrder,
    resetCustomOrder,
    // Update Handler
    handleUpdateLibraries,
  } = useLibraryHandler(libraryType, convertTargetIncomes, refetchCount);

  // ================================================
  //                 panel & colDef
  // ================================================

  const panelTitle = React.useMemo(
    () => t("voc.library.titles.targetIncome"),
    [t]
  );

  const column = React.useMemo(
    () => [
      {
        columnName: t("voc.library.effectiveDateFrom"),
        columnWidthClassName: "fourth",
        columnDataType: "date",
        editable: true,
      },
      {
        columnName: t("voc.library.effectiveDateTo"),
        columnWidthClassName: "fourth",
        columnDataType: "date",
        editable: true,
        canClearDate: true,
      },
      {
        columnName: t("voc.library.targetValue"),
        columnWidthClassName: "half",
        columnDataType: "string",
        editable: true,
        stringConversionSymbol: "$",
        stringConversionDecimals: 2,
      },
    ],
    [t]
  );

  // ================================================
  //                Action Handler
  // ================================================

  const handleSave = () => {
    const deconvertedTargetIncomes: Partial<VocLibraryObject>[] = [];
    let validationError: VocLibDocIndex[] = [];

    // ================== validation ==================

    // check edited start date is ealier than end date
    displayLibraryItem.forEach((ti: DataListItem, i: number) => {
      if (ti.edited) {
        if (ti.fieldDataArray[0].fieldData >= ti.fieldDataArray[1].fieldData) {
          libraryItem.forEach((item: any, i2: number) => {
            if (i2 === i) {
              validationError = [...validationError, { docIndex: i2 }];
            }
          });
        }
      }
    });

    // return and show error msg if error exists
    if (validationError.length > 0) {
      setDisplayLibraryItem((prevDisplayLibraryItem) => {
        let result = cloneDeep(prevDisplayLibraryItem);
        validationError.forEach((valErr) => {
          let invalidated: DataListItem = null;
          invalidated = result[valErr.docIndex];
          invalidated.validationError = t(
            "voc.eventPage.supporting.recDateValidation"
          );
          const newHookState = [...result];
          newHookState[valErr.docIndex] = invalidated;
          result = [...newHookState];
        });
        return result;
      });
      return;
    }

    // ================== if validation pass ==================

    displayLibraryItem.forEach((ti: DataListItem, i: number) => {
      if (ti.edited) {
        if (ti.fieldDataArray[0].fieldData < ti.fieldDataArray[1].fieldData) {
          if (ti.validationError) {
            const newTi = { ...ti };
            newTi.validationError = null;
            const hook = [...displayLibraryItem];
            hook.splice(i, 1, newTi);
            setDisplayLibraryItem(hook);
          }
          deconvertedTargetIncomes.push({
            id: ti.id,
            value: ti.value,
            fromEffectiveDate: formatDate(
              ti.fieldDataArray[0].fieldData,
              DateFormatPattern.date
            ) as any,
            effectiveDate: formatDate(
              ti.fieldDataArray[1].fieldData,
              DateFormatPattern.date
            ) as any,
            targetValue: ti.fieldDataArray[2].fieldData,
          });
        }
      }
    });
    const deletedRecords: DeletedRecord[] = [];
    libraryItem.forEach((item: any) => {
      let deleted = true;
      displayLibraryItem.forEach((i: DataListItem) => {
        if (item.id === i.id) {
          deleted = false;
        }
      });
      if (deleted) {
        deletedRecords.push({
          id: item.id,
          fromEffectiveDate: item.fromEffectiveDate,
          effectiveDate: item.effectiveDate,
          label: item.label,
          value: item.value,
        });
      }
    });
    //BACKEND REQUIRES CERTAIN FIELDS, EVEN WHEN DELETING, WHEN IT SHOULD NOT BE NECESSARY
    const deletedRecordObjects: DeletedRecord[] = deletedRecords.map((rec) => ({
      id: rec.id,
      value: rec.value,
      label: rec.label,
      fromEffectiveDate: formatDate(
        rec.fromEffectiveDate,
        DateFormatPattern.date
      ) as any,
      effectiveDate: formatDate(
        rec.effectiveDate,
        DateFormatPattern.date
      ) as any,
      isDeleted: true,
    }));
    const combinedRecords = [
      ...deconvertedTargetIncomes,
      ...deletedRecordObjects,
    ];
    handleUpdateLibraries(
      {
        items: combinedRecords,
      },
      () => setIsEditing(false)
    );
  };

  const handleCancel = () => {
    // setDisplayLibraryItem(libraryItem);
    fetchLibraryAndUsage();
  };

  return (
    <AppCollapsible
      permissionCode="libraries"
      hasFields={false}
      editCallback={() => {
        setIsEditing(true);
      }}
      editingState={isEditing}
      saveCallback={handleSave}
      cancelCallback={() => {
        handleCancel();
        setIsEditing(false);
      }}
      firstDecollapseCallback={() => {
        fetchLibraryAndUsage();
      }}
      isLoading={!isLibraryAndUsageLoaded}
      isLoadOnEveryClick={true}
      headerChildren={<div className="doctype-title">{panelTitle}</div>}
      bodyChildren={
        isLibraryAndUsageLoaded ? (
          <AppDataListV2
            permissionCode="libraries"
            editing={isEditing}
            canDeleteLastItem={true}
            columns={column}
            oriItems={libraryItem}
            items={displayLibraryItem}
            editSetStateHook={setDisplayLibraryItem}
            setFiles={null}
            defaultSortDirection=""
            saveCustomOrderCallback={saveCustomOrder}
            resetCustomOrderCallback={resetCustomOrder}
            saveCustomOrderCode={libraryType}
            initCustomSortState={isCustomSort}
          />
        ) : null
      }
    ></AppCollapsible>
  );
};

export default TargetIncome;
