import { notify, NxpButtonDeleteConfirm } from "@nexploretechnology/nxp-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useAppContext from "../../../../app/hooks/useAppContext";
import {
  deleteRole,
  ManageRolesTableItem,
} from "../../../manage-role-services";
import { createUseStyles } from "react-jss";

interface DeleteRoleButtonProps {
  row: ManageRolesTableItem;
  onRefetch: () => Promise<void>;
}

const useStyles = createUseStyles({
  updateButton: {
    width: "100%",
    textAlign: "left",
  },
});

const DeleteRoleButton: React.FC<DeleteRoleButtonProps> = ({
  row,
  onRefetch,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { serviceConfig, errorHandler } = useAppContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRowDelete = async () => {
    try {
      const deletedRole = await deleteRole(serviceConfig, row.id);
      console.log(deletedRole);
      notify.actionCompleted();
    } catch (err) {
      errorHandler(err, t("voc.ManageRole.deleteRow"));
    } finally {
      setIsSubmitting(false);
      onRefetch();
    }
  };

  return (
    <NxpButtonDeleteConfirm
      className={classes.updateButton}
      type="link"
      loading={isSubmitting}
      onConfirm={handleRowDelete}
    >
      {t("voc.ManageRole.DeleteRole")}
    </NxpButtonDeleteConfirm>
  );
};

export default DeleteRoleButton;
