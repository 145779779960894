import React from "react";
import { useTranslation } from "react-i18next";

import { DataListItem } from "../../../app/components/app-data-list/types";

import "./LogsTab.scss";
import { Right } from "../../../app/services/app";
import AppDataList from "../../../app/components/app-data-list/AppDataList";

interface LogsTabLayoutProps {
  logs: DataListItem[];
  undoCallback: Function;
  permissionCode: Right["objectCode"];
}

const LogsTabLayout: React.FC<LogsTabLayoutProps> = (props) => {
  const { t } = useTranslation();
  const { logs, undoCallback, permissionCode } = props;

  const logsColumns = [
    {
      columnName: t("voc.eventPage.logs.actionType"),
      columnWidthClassName: "tenth autoheight",
      columnDataType: "string",
      editable: false,
    },
    {
      columnName: t("voc.eventPage.logs.actionBy"),
      columnWidthClassName: "tenth autoheight",
      columnDataType: "string",
      editable: false,
    },
    {
      columnName: t("voc.eventPage.logs.actionOn"),
      columnWidthClassName: "tenth autoheight",
      columnDataType: "date",
      editable: false,
    },
    {
      columnName: t("voc.eventPage.logs.dataChanged"),
      columnWidthClassName: "seventypercent autoheight breakword",
      columnDataType: "customDisplay",
      editable: false, // must be false
    },
  ];

  return (
    <div className="logs-container">
      <AppDataList
        permissionCode={permissionCode}
        editing={false}
        canDeleteLastItem={false}
        columns={logsColumns}
        items={logs}
        editSetStateHook={null}
        setFiles={null}
        undoCallback={undoCallback}
        defaultSortColumn={2}
        defaultSortDirection="desc"
        autoheight={true}
      />
    </div>
  );
};

export default LogsTabLayout;
