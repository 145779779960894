import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import { notify } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../app/hooks/useAppContext";
import { useAsync } from "../../../app/hooks/useAsync";
import useVocServiceAsync from "../../../app/services/useVocServiceAsync";
import {
  VocCustomUi,
  VocCustomUiDataTableColumn,
  VocCustomUiLayoutItem,
  VocCustomUiLayoutItemType,
  VocScreenNameEnums,
} from "../../../app/types";
import { mapCustomizationColumnDataType } from "../../../app/utils/mappers";
import {
  getAllCustomizedScreens,
  updateCustomizedScreen,
} from "../../customization-services";
import {
  selectedUiReducer,
  SelectedUiReducerAction,
} from "../../selected-ui-reducer";
import CeCustomization from "../ce-customization/CeCustomization";
import SiteEventCustomization from "../se-customization/SiteEventCustomization";
import CustomizationHomeLayout from "./CustomizationHomeLayout";

export enum CustomizationDetailsSection {
  Compensation_Event = "compensation-event",
  Site_Event = "site-event",
}

const CustomizationContainer: React.FC = () => {
  const { serviceConfig, onAppContextCacheItemUpdate, errorHandler } =
    useAppContext();

  useVocServiceAsync(serviceConfig.entityId); // needed for fixing obscure circular dependency issue
  const [screenDataAsyncResult, setScreenDataRequest] = useAsync<VocCustomUi[]>(
    () => getAllCustomizedScreens(serviceConfig),
    {
      onSuccess(data) {
        onAppContextCacheItemUpdate("customizedScreens", data);
      },
      onError: (ex) => errorHandler(ex, "getting screens"),
    }
  );

  const handleScreenDataRefresh = () => {
    setScreenDataRequest(() => getAllCustomizedScreens(serviceConfig));
  };

  const [screenData, setScreenData] = useState<VocCustomUi[]>();

  const [selectedUi, dispatch] = useReducer(selectedUiReducer, undefined);

  useEffect(() => {
    setScreenData(screenDataAsyncResult.data);
    if (selectedUi) {
      // select again when screen data refresh
      dispatch({
        type: "select",
        screenName: selectedUi.screen.name,
        screenData: screenDataAsyncResult.data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenDataAsyncResult.data]);

  const { t } = useTranslation();

  const handleSaveError = (_: Error) => {
    notify.error(t("voc.admin.customization.customizationContainer.fail"));

    handleScreenDataRefresh();
  };
  const [, setCustomizeScreenRequest] = useAsync<VocCustomUi>(undefined, {
    onSuccess: (data) => {
      if (data.id) {
        notify.actionCompleted(); //     "voc.admin.customization.customizationContainer.success"

        // apply saved ui to screen data
        setScreenData((prevState) => {
          const nextState = prevState.map((item) =>
            item.screen.name === selectedUi.screen.name
              ? { ...selectedUi }
              : item
          );
          onAppContextCacheItemUpdate("customizedScreens", nextState);
          return nextState;
        });
      } else {
        handleSaveError(new Error("Incorrect response format."));
      }
    },
    onError: (error) => {
      handleSaveError(error);
    },
  });

  const handleSelectedUiSave = () => {
    setCustomizeScreenRequest(() => {
      // check needed only in old version
      // if (selectedUi.isDefault) {
      //   return createCustomizedScreen(
      //     selectedUi.screen.id,
      //     selectedUi.layout,
      //     serviceConfig
      //   );
      // }
      return updateCustomizedScreen(
        selectedUi.screen.id,
        selectedUi.layout,
        serviceConfig
      );
    });
  };

  const addCustomFieldToRegisterColumns = (
    registerColumns: VocCustomUiDataTableColumn[],
    customFieldLayouts: VocCustomUiLayoutItem[]
  ) => {
    customFieldLayouts.forEach((layout) => {
      let customFields: VocCustomUiLayoutItem[] = [];
      if (layout.type === VocCustomUiLayoutItemType.LayoutGroup) {
        customFields = layout.members.filter(
          (field) =>
            field.type === VocCustomUiLayoutItemType.QuestionListCustomField
        );
      }

      customFields.forEach((field) => {
        if (!registerColumns.find((col) => col.key === field.key)) {
          registerColumns.push({
            name: field.name,
            key: field.key,
            customName: undefined,
            type: VocCustomUiLayoutItemType.DataTableColumn,
            display: false,
            isCustomField: true,
            columnDataType:
              field.type === VocCustomUiLayoutItemType.QuestionListCustomField
                ? mapCustomizationColumnDataType(field.dataType)
                : undefined,
          });
        }
      });
    });
  };

  const prepareCustomFieldRegisterColumns = (screenData: VocCustomUi[]) => {
    let registerColumns = screenData.find(
      (item) =>
        item.screen.name ===
        VocScreenNameEnums.COMPENSATION_EVENT_REGISTER_SCREEN
    ).layout as VocCustomUiDataTableColumn[];
    let customFieldLayout = screenData.find(
      (item) =>
        item.screen.name ===
        VocScreenNameEnums.COMPENSATION_EVENT_SUMMARY_SCREEN
    ).layout;
    addCustomFieldToRegisterColumns(registerColumns, customFieldLayout);

    registerColumns = screenData.find(
      (item) =>
        item.screen.name ===
        VocScreenNameEnums.COMPENSATION_EVENT_REGISTER_SCREEN
    ).layout as VocCustomUiDataTableColumn[];
    customFieldLayout = screenData.find(
      (item) =>
        item.screen.name ===
        VocScreenNameEnums.COMPENSATION_EVENT_VO_DETAIL_SCREEN
    ).layout;
    addCustomFieldToRegisterColumns(registerColumns, customFieldLayout);

    registerColumns = screenData.find(
      (item) =>
        item.screen.name === VocScreenNameEnums.SITE_EVENT_REGISTER_SCREEN
    ).layout as VocCustomUiDataTableColumn[];
    customFieldLayout = screenData.find(
      (item) =>
        item.screen.name === VocScreenNameEnums.SITE_EVENT_SUMMARY_SCREEN
    ).layout;
    addCustomFieldToRegisterColumns(registerColumns, customFieldLayout);
  };

  const handleSelectedUiChange = (screenName: string) => {
    prepareCustomFieldRegisterColumns(screenData);
    dispatch({
      type: "select",
      screenName: screenName,
      screenData,
    });
  };

  const handleSelectedUiDispatch = (value: SelectedUiReducerAction) => {
    dispatch(value);
    // call handleSave below as the original save button for ce summary and vo details is removed and saving is triggered automatically for below dispatch, such flow also make it necessary to mutate the state
    if (
      value.type === "layoutGroupChange" ||
      value.type === "layoutGroupMemberReorder" ||
      value.type === "layoutGroupMemberChange"
    ) {
      setTimeout(handleSelectedUiSave, 0);
    }
  };

  const navigate = useNavigate();

  const handleActionSectionChange = (section?: CustomizationDetailsSection) => {
    if (section === undefined) {
      navigate("./..");
    } else {
      navigate(`./${section}`);
    }
  };

  const { activeSection } = useParams();

  const getActiveSection = () => {
    switch (activeSection) {
      case CustomizationDetailsSection.Compensation_Event:
        return (
          <CeCustomization
            entityId={serviceConfig.entityId}
            selectedUi={selectedUi}
            screenData={screenData}
            onSelectedUiSave={handleSelectedUiSave}
            onSelectedUiChange={handleSelectedUiChange}
            onSelectedUiDispatch={handleSelectedUiDispatch}
            onScreenDataRefresh={handleScreenDataRefresh}
            onActionSectionChange={handleActionSectionChange}
          />
        );
      case CustomizationDetailsSection.Site_Event:
        return (
          <SiteEventCustomization
            entityId={serviceConfig.entityId}
            selectedUi={selectedUi}
            screenData={screenData}
            onSelectedUiSave={handleSelectedUiSave}
            onSelectedUiChange={handleSelectedUiChange}
            onSelectedUiDispatch={handleSelectedUiDispatch}
            onScreenDataRefresh={handleScreenDataRefresh}
            onActionSectionChange={handleActionSectionChange}
          />
        );
      default:
        return (
          <CustomizationHomeLayout
            onActiveSectionChange={handleActionSectionChange}
          />
        );
    }
  };

  return screenData ? <div>{getActiveSection()}</div> : null;
};

export default CustomizationContainer;
