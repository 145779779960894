import { NxpAppTheme } from "@nexploretechnology/nxp-ui";

declare global {
  namespace Jss {
    export interface Theme extends AppTheme {}
  }
}

export interface AppTheme extends NxpAppTheme {
  // extra theme properties here, eg:
  // sidebarColor: {
  //   adminUser: string;
  //   adminDataStructure: string;
  // };
}

export const appTheme: AppTheme = require("../../theme.config");
