import React, { useState } from "react";

import {
  DateFormatPattern,
  formatDate,
  notify,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../app/hooks/useAppContext";
import { useValidate } from "../../../app/hooks/useValidate";
import { VocLibraryObject, VocProjectLibraryType } from "../../../app/types";
import { createLibraryItem } from "../../libraries-services";
import AddLibraryModal from "./AddLibraryModal";

export interface LibraryAddForm extends VocLibraryObject {
  libraryType?: VocProjectLibraryType;
}

function getLibrarySchema(libraryType: VocProjectLibraryType) {
  const sharedRules = {
    libraryType: yup
      .mixed<VocProjectLibraryType>()
      .oneOf(Object.values(VocProjectLibraryType))
      .required("Type required."),
  };
  switch (libraryType) {
    case VocProjectLibraryType.Empowered_Client_Representatives:
      return yup.object().shape({
        ...sharedRules,
        firstName: yup.string().nullable().required("First Name required."),
        lastName: yup.string().nullable().required("Last Name required."),
        company: yup.string().nullable(),
        role: yup.string().nullable(),
        email: yup.string().nullable(),
      });

    case VocProjectLibraryType.Clauses_for_Instructions:
    case VocProjectLibraryType.Clauses_for_Valuation:
    case VocProjectLibraryType.Record_Status:
    case VocProjectLibraryType.Status_With_Client:
    case VocProjectLibraryType.Support_Document_Status:
    case VocProjectLibraryType.Site_Event_Status:
    case VocProjectLibraryType.Event_Source:
    case VocProjectLibraryType.Event_Source_Type:
    case VocProjectLibraryType.Construction_Status:
    case VocProjectLibraryType.Delaying_and_Disruptive_Effects:
      return yup.object().shape({
        ...sharedRules,
        label: yup.string().nullable().required("Description required."),
      });
    case VocProjectLibraryType.Deadline_or_Key_Dates:
      return yup.object().shape({
        ...sharedRules,
        label: yup
          .string()
          .nullable()
          .required("Deadline / Key Date required."),
        contractualDate: yup
          .date()
          .nullable()
          .required("Contractual Date required."),
      });
    default:
      // VocProjectLibraryType.Target_Income:
      return yup.object().shape({
        ...sharedRules,
        fromEffectiveDate: yup
          .date()
          .nullable()
          .required("Effective Date (From) required."),
        targetValue: yup.number().nullable().required("Target Value required."),
      });
  }
}

interface AddLibraryButtonProps {
  showAddLibraryModal: boolean;
  onAdd: Function;
  onModalClose: () => void;
}

const emptyLibrary: Partial<LibraryAddForm> = {
  files: [],
  label: null,
  value: null,
  days: null,
  company: null,
  email: null,
  firstName: null,
  lastName: null,
  role: null,
  dateFrom: null,
  dateTo: null,
  date: null,
  targetValue: null,
  fromEffectiveDate: null,
  effectiveDate: null,
  contractualDate: null,
};

const AddLibraryContainer: React.FC<AddLibraryButtonProps> = ({
  showAddLibraryModal,
  onAdd,
  onModalClose,
}) => {
  const { serviceConfig, errorHandler } = useAppContext();
  const [form, setForm] = React.useState<Partial<LibraryAddForm>>(
    () => emptyLibrary
  );

  const [createLibraryResult, setCreateLibraryResult] = useState<any>({
    error: { libraryType: "" },
    loading: false,
  });

  const handleSaveValidated = async () => {
    const postData: Partial<VocLibraryObject> = {
      ...{ ...form, libraryType: undefined },
    };

    if (!postData.files?.length) {
      postData.files = undefined;
    }

    const postItemConvertList: (keyof typeof postData)[] = [
      "contractualDate",
      "effectiveDate",
      "fromEffectiveDate",
      "representDate",
    ];

    Object.keys(postData).forEach((key: keyof VocLibraryObject) => {
      if (postData[key] === null) {
        (postData[key] as any) = undefined;
      }
      if (postItemConvertList.includes(key)) {
        (postData[key] as any) = formatDate(
          postData[key] as Date,
          DateFormatPattern.date
        );
      }
    });

    try {
      const response = await createLibraryItem(
        serviceConfig,
        form.libraryType,
        postData
      );
      notify.actionCompleted();
      onModalClose();
      onAdd(response, form.libraryType);
      setCreateLibraryResult(response);
    } catch (ex) {
      errorHandler(ex, "Create Library Item");
    }
  };

  const [validationError, , clearError, saveWithValidate] = useValidate<
    Partial<LibraryAddForm>
  >(form, getLibrarySchema(form.libraryType) as any, handleSaveValidated);

  const handleValidation = () => {
    saveWithValidate(undefined);
  };

  const handleFormChange = (changeValues: Partial<LibraryAddForm>) => {
    if (changeValues.libraryType) {
      setForm({
        ...emptyLibrary,
        libraryType: changeValues.libraryType,
      });
      clearError();
      return;
    }

    setForm((prevState) => ({ ...prevState, ...changeValues }));
  };

  return (
    <AddLibraryModal
      form={form}
      showAddLibraryModal={showAddLibraryModal}
      onModalClose={onModalClose}
      createLibraryAsyncResult={createLibraryResult}
      validationError={validationError}
      onFormChange={handleFormChange}
      onSave={handleValidation}
    />
  );
};

export default AddLibraryContainer;
