// export in commonjs to support usage in both craco.config.js and appTheme.ts
module.exports = {
  DEFAULT_LANGUAGE_KEY: "en",

  DEFAULT_DECIMAL_PLACE: 2,

  // transition duration in seconds, need to x1000 when used as millisecond arguments in functions like setTimeout
  TRANSITION_DURATION_MAIN: 0.25,
  TRANSITION_DURATION_FAST: 0.15,
  TRANSITION_DURATION_FASTER: 0.1,

  DEFAULT_LISTING_COLUMN_WIDTH: 100,
  MIN_LISTING_COLUMN_WIDTH: 20,

  BACK_HREF_BY_JS: "BACK_HREF_BY_JS",
  FORMAT_SHORT_DATE_LUXON: "d-LLL-yyyy",
  REGISTER_LAZY_LOAD_SIZE: 50,

  EVENT_STATUS_CLOSED: "VOC_EVENT_STATUS_03",

  INSTRUCTED_BY_OTHER: "VO_INSTR_OWNER_99",
  INSTRUCTED_BY_NONE: "VO_INSTR_OWNER_999",
};
