import { AsyncHandlers, useAsync, useAsyncLegacy } from "../hooks/useAsync";
import useVocServiceConst from "./useVocServiceConst";
import { VocService } from "./vocService";

// please note that using this hook twice in the same component will cause 2 separate instances of VocService to be initiated
// try using useVocServiceConst in combination with multiple useAsync if you prefer having a single VocService instance for your component
const useVocServiceAsync = <T>(
  entityId: string,
  initialRequest?: (serviceConst: VocService) => Promise<T>,
  handlers?: AsyncHandlers<T>,
  defaultResultData?: T
) => {
  const vocService = useVocServiceConst(entityId);
  const [asyncResult, setAsyncRequest] = useAsync<T>(
    initialRequest ? () => initialRequest(vocService) : undefined,
    handlers,
    defaultResultData
  );
  const setVocServiceRequest = (
    serviceCall: (serviceConst: VocService) => Promise<T>
  ) => {
    setAsyncRequest(() => serviceCall(vocService));
  };
  return [asyncResult, setVocServiceRequest] as const;
};

export default useVocServiceAsync;

// below hook exists to support the use of useAsyncLegacy - an discontinued version of async hook
// new feature should stick with the current version of useVocServiceAsync above
export const useVocServiceAsyncLegacy = <T>(
  entityId: string,
  initialRequest?: (serviceConst: VocService) => Promise<T>,
  handlers?: AsyncHandlers<T>,
  defaultResultData?: T
) => {
  const vocService = useVocServiceConst(entityId);
  const [asyncResult, setAsyncRequest] = useAsyncLegacy<T>(
    initialRequest ? () => initialRequest(vocService) : undefined,
    handlers,
    defaultResultData
  );
  const setVocServiceRequest = (
    serviceCall: (serviceConst: VocService) => Promise<T>
  ) => {
    setAsyncRequest(() => serviceCall(vocService));
  };
  return [asyncResult, setVocServiceRequest] as const;
};
