import React from "react";
import { createUseStyles } from "react-jss";

import { NxpHeader } from "@nexploretechnology/nxp-ui";

const useStyles = createUseStyles((theme) => ({
  demoHeader: {},
}));

interface DemoHeaderProps {}

const DemoHeader: React.FC<DemoHeaderProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.demoHeader}>
      <NxpHeader titleContent="Demo Header" />
    </div>
  );
};

export default DemoHeader;
