import { AppContextCacheLibraries } from "../app/components/app-context/AppContext";
import {
  VocGetDictionary,
  VocGetDictionaryResponse,
  VocGetLibraryUsage,
  VocLibraryObject,
  VocVarianceDisplayUnit,
} from "../app/types";
import { apiRequest, ServiceConfig } from "../app/utils/backend/api-request";

// libraries modules ONLY, not dictionaries
export const libraryTypes: LibraryTypes[] = [
  "VOC_VO_EMPOWER_REPS",
  "VOC_CLAUSE_INSTR",
  "VOC_CLAUSE_VALUATION",
  "VOC_SOURCES",
  "VOC_SOURCE_TYPE",
  "VOC_CONSTR_STATUS",
  "VOC_DELAY_EFFECTS",
  "VOC_CLIENT_NOTIFICATION",
  "VOC_TARGET_INCOME",
  "VOC_ESCALATION_RECORDS",
  "VOC_KEY_DATES",
  "VOC_REC_STATUS",
  "VOC_CLIENT_WITH_STATUS",
  "VOC_SUPPORT_DOC_STATUS",
  "VOC_EVENT_STATUS",
];

// libraries modules ONLY, not dictionaries
export type LibraryTypes =
  | "VOC_VO_EMPOWER_REPS"
  | "VOC_CLAUSE_INSTR"
  | "VOC_CLAUSE_VALUATION"
  | "VOC_SOURCES"
  | "VOC_SOURCE_TYPE"
  | "VOC_CONSTR_STATUS"
  | "VOC_DELAY_EFFECTS"
  | "VOC_CLIENT_NOTIFICATION"
  | "VOC_TARGET_INCOME"
  | "VOC_ESCALATION_RECORDS"
  | "VOC_KEY_DATES"
  | "VOC_REC_STATUS"
  | "VOC_CLIENT_WITH_STATUS"
  | "VOC_SUPPORT_DOC_STATUS"
  | "VOC_EVENT_STATUS";

// dictionaries + libraries
export type LibrarySource =
  | "LOCATION"
  | "VOC_VARIANCE"
  | "VOC_EVENT_TASK_STATUS"
  | "VOC_VO_EMPOWER_REPS"
  | "VOC_CLAUSE_INSTR"
  | "VOC_CLAUSE_VALUATION"
  | "VOC_SOURCES"
  | "VOC_SOURCE_TYPE"
  | "VOC_CONSTR_STATUS"
  | "VOC_DELAY_EFFECTS"
  | "VOC_CLIENT_NOTIFICATION"
  | "VOC_TARGET_INCOME"
  | "VOC_ESCALATION_RECORDS"
  | "VOC_KEY_DATES"
  | "VOC_REC_STATUS"
  | "VOC_CLIENT_WITH_STATUS"
  | "VOC_SUPPORT_DOC_STATUS"
  | "VOC_EVENT_STATUS";

interface SortOrderItem {
  id: string;
  sortOrder: number;
}

interface SortOrderRequestBody {
  orders: SortOrderItem[];
}

export const getLibrary = (
  libraryType: LibrarySource,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocGetDictionaryResponse>(
    `/libraries/${libraryType}`,
    serviceConfig
  ).then((res) => {
    // TODO: remove when api sorted
    res.dictionaries?.sort((a, b) => {
      if (a.sortOrder < b.sortOrder) {
        return -1;
      }
      if (a.sortOrder > b.sortOrder) {
        return 1;
      }
      return 0;
    });
    return res;
  });
};

export const getLibraries = async (serviceConfig: ServiceConfig) => {
  return apiRequest<Record<LibrarySource, VocGetDictionary[]>>(
    `/libraries`,
    serviceConfig
  ).then((res) => {
    // assign empty [] to libraries obj if it is not exist in api return
    libraryTypes.forEach((libraryType: LibrarySource) => {
      if (!res[libraryType]) {
        res[libraryType] = [];
      }
    });
    // assign empty [] to undefined objects in api return
    return Object.entries(res).reduce(
      (acc: AppContextCacheLibraries, [key, value]) => {
        acc[key as LibrarySource] = Array.isArray(value) ? value : [];
        return acc;
      },
      {}
    );
  });
};

export const getLibraryUsage = (
  serviceConfig: ServiceConfig,
  librarySource: LibrarySource
) => {
  return apiRequest<VocGetLibraryUsage[]>(
    `/libraries/${librarySource}/usage`,
    serviceConfig
  );
};

export const createLibraryItem = (
  serviceConfig: ServiceConfig,
  librarySource: LibrarySource,
  reqBody: any
) => {
  return apiRequest<VocLibraryObject>(
    `/libraries/${librarySource}`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(reqBody),
    }
  );
};

export const updateLibraryItem = (
  serviceConfig: ServiceConfig,
  librarySource: LibrarySource,
  reqBody: any
) => {
  return apiRequest<VocLibraryObject>(
    `/libraries/${librarySource}/batch`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(reqBody),
    }
  );
};

export const getVarianceDisplayUnit = (serviceConfig: ServiceConfig) => {
  return apiRequest<VocVarianceDisplayUnit>(
    `/libraries/VOC_VARIANCE/displayUnit`,
    serviceConfig
  );
};

export const updateVarianceDisplayUnit = (
  serviceConfig: ServiceConfig,
  reqBody: any
) => {
  return apiRequest<VocVarianceDisplayUnit>(
    `/libraries/VOC_VARIANCE/displayUnit/batch`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(reqBody),
    }
  );
};

export const updateLibrarySort = (
  serviceConfig: ServiceConfig,
  librarySource: LibrarySource,
  reqBody: SortOrderRequestBody
) => {
  return apiRequest<VocLibraryObject[]>(
    `/libraries/${librarySource}/sort`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(reqBody),
    }
  );
};
