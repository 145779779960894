import { UploadOutlined } from "@ant-design/icons";
import { NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";
import { Button, Upload } from "antd";
import { isBefore } from "date-fns";
import { useTranslation } from "react-i18next";
import { getUserDisplayDateFormat } from "../../../../../../../app/utils/mappers";
import { GeneralSupportingFormState } from "../../../new-document-form-type";
import { useFileHandler } from "../../hooks/useFileHandler";

export function useGeneralSupportingFormItems({ formState, setFormState }) {
  const { t } = useTranslation();

  const { handleFileChange, handleRemoveFile } =
    useFileHandler<GeneralSupportingFormState>(setFormState);

  const formItems: NxpFormGridItemProps<GeneralSupportingFormState>[] = [
    {
      controlType: "datePicker",
      span: 12,
      label: t("voc.common.from"),
      itemFieldName: "fromDate",
      required: true,
      controlProps: {
        format: getUserDisplayDateFormat(),
        value: formState.fromDate,
        disabledDate: (current) =>
          current && isBefore(new Date(), current.toDate()),
      },
    },
    {
      controlType: "datePicker",
      span: 12,
      label: t("voc.common.to"),
      itemFieldName: "toDate",
      required: true,
      controlProps: {
        format: getUserDisplayDateFormat(),
        value: formState.toDate,
        disabledDate: (current) =>
          current && isBefore(new Date(), current.toDate()),
      },
    },
    {
      controlType: "input",
      span: 12,
      label: t("voc.common.subject"),
      itemFieldName: "subject",
      required: true,
      controlProps: {
        value: formState.subject,
      },
    },
    {
      controlType: "input",
      span: 12,
      label: t("voc.common.comment"),
      itemFieldName: "comment",
      controlProps: {
        value: formState.comment,
      },
    },
    {
      controlType: "custom",
      span: 12,
      label: "File",
      itemFieldName: "files",
      customContent: (
        <Upload
          beforeUpload={(file) => {
            handleFileChange(file);
            return false; // Prevent automatic upload
          }}
          onRemove={handleRemoveFile}
          fileList={formState.files.map((file) => ({
            uid: file.assetId,
            name: file.fileName,
            status: "done",
            url: file.url,
          }))}
        >
          {formState.files.length < 1 && (
            <Button icon={<UploadOutlined />}>
              {t("voc.common.clickToUpload")}
            </Button>
          )}
        </Upload>
      ),
    },
    {
      controlType: "input",
      span: 12,
      label: t("voc.common.link"),
      itemFieldName: "link",
      controlProps: {
        value: formState.link,
        placeholder: "https://",
      },
    },
  ];

  return formItems;
}
