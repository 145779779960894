import React from "react";
import { useTranslation } from "react-i18next";

import { cloneDeep } from "lodash";

import AppCard from "../../../app/components/app-card/AppCard";
import AppFieldBlock from "../../../app/components/app-field-block/AppFieldBlock";
import useAppContext from "../../../app/hooks/useAppContext";
import { VocNextNotifications, VocNotifications } from "../../../app/types";
import { getNotifications } from "../../../compensation-events/compensation-event-services";
import NotificationsTabPanel from "./NotificationsTabPanel";
import ReminderButton from "./reminder/ReminderButton";
import { ValidationWarning } from "./validation/ValidationWarning";

import "./NotificationsTabPage.scss";
import { getLibrary } from "../../../libraries/libraries-services";
import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";

export type EditingPanel = "TIME" | "COST" | "";

export interface GeneralInfo {
  dateOfAwareness?: Date;
  dateOfCessation?: Date;
  isDivideTimeCost: boolean;
}

const defaultGeneralInfo: GeneralInfo = {
  dateOfAwareness: null,
  dateOfCessation: null,
  isDivideTimeCost: false,
};

interface NotificationsTabPageProps {
  eventId: string;
}

const NotificationsTabPage: React.FC<NotificationsTabPageProps> = (props) => {
  const { eventId } = props;
  const { t } = useTranslation();
  const { serviceConfig, errorHandler } = useAppContext();

  const [isNotificationLoaded, setIsNotificationLoaded] =
    React.useState<boolean>(false);
  const [isLibraryLoaded, setIsLibraryLoaded] = React.useState<boolean>(false);
  const [generalInfo, setGeneralInfo] =
    React.useState<GeneralInfo>(defaultGeneralInfo);

  // =========================================================
  //                Next Notifications Days
  // =========================================================

  const [timeNotificationsIntervals, setTimeNotificationsIntervals] =
    React.useState<VocNextNotifications>({
      firstInterval: null,
      followUpInterval: null,
      finalInterval: null,
    });

  const [costNotificationsIntervals, setCostNotificationsIntervals] =
    React.useState<VocNextNotifications>({
      firstInterval: null,
      followUpInterval: null,
      finalInterval: null,
    });

  const getLibraryClientNotifications = React.useCallback(async () => {
    setIsLibraryLoaded(false);
    try {
      const response = await getLibrary(
        "VOC_CLIENT_NOTIFICATION",
        serviceConfig
      );
      // time
      const firstIntervalTime = response.dictionaries.find(
        (dic: { value: string }) => dic.value === "FIRST_NOTIFY_TIME"
      ).days;
      const followUpIntervalTime = response.dictionaries.find(
        (dic: { value: string }) => dic.value === "FOLLOWUP_NOTIFY_TIME"
      ).days;
      const finalIntervalTime = response.dictionaries.find(
        (dic: { value: string }) => dic.value === "FINAL_SUBMIT_TIME"
      ).days;
      setTimeNotificationsIntervals({
        firstInterval: firstIntervalTime,
        followUpInterval: followUpIntervalTime,
        finalInterval: finalIntervalTime,
      });
      // cost
      const firstIntervalCost = response.dictionaries.find(
        (dic: { value: string }) => dic.value === "FIRST_NOTIFY_COST"
      ).days;
      const followUpIntervalCost = response.dictionaries.find(
        (dic: { value: string }) => dic.value === "FOLLOWUP_NOTIFY_COST"
      ).days;
      const finalIntervalCost = response.dictionaries.find(
        (dic: { value: string }) => dic.value === "FINAL_SUBMIT_COST"
      ).days;
      setCostNotificationsIntervals({
        firstInterval: firstIntervalCost,
        followUpInterval: followUpIntervalCost,
        finalInterval: finalIntervalCost,
      });
      setIsLibraryLoaded(true);
    } catch (ex) {
      errorHandler(ex, "Get Library Client Notifications");
    }
  }, [errorHandler, serviceConfig]);

  React.useEffect(() => {
    getLibraryClientNotifications();
  }, [getLibraryClientNotifications]);

  // =========================================================
  //                     Fetch Notification
  // =========================================================

  const [timeNotifications, setTimeNotifications] =
    React.useState<VocNotifications>();
  const [displayTimeNotifications, setDisplayTimeNotifications] =
    React.useState<VocNotifications>();
  const [isTimeFollowUpNotRequired, setIsTimeFollowUpNotRequired] =
    React.useState<boolean>();

  const [costNotifications, setCostNotifications] =
    React.useState<VocNotifications>();
  const [displayCostNotifications, setDisplayCostNotifications] =
    React.useState<VocNotifications>();
  const [isCostFollowUpNotRequired, setIsCostFollowUpNotRequired] =
    React.useState<boolean>();

  const handleNotificationStage = React.useCallback((notifications: any[]) => {
    const first = notifications.find(
      (notification: { stage: string }) => notification.stage === "FIRST"
    );
    const followUps = notifications.filter(
      (notification: { stage: string }) => notification.stage === "FOLLOW-UP"
    );
    const convertedFollowUps = followUps.map((followUp) => ({
      id: followUp.id,
      fieldDataArray: [
        { fieldData: followUp?.requiredDate },
        { fieldData: followUp?.actualDate },
        { fieldData: followUp?.attachments },
        { fieldData: followUp?.comment },
      ],
    }));
    const final = notifications.find(
      (notification: { stage: string }) => notification.stage === "FINAL"
    );
    return {
      first,
      convertedFollowUps,
      final,
    };
  }, []);

  const fetchNotifications = React.useCallback(async () => {
    // setIsNotificationLoaded(false);
    try {
      const response = await getNotifications(eventId, serviceConfig);
      setGeneralInfo({
        dateOfAwareness: response?.dateOfAwareness,
        dateOfCessation: response?.dateOfCessation,
        isDivideTimeCost: response?.isDivideTimeCost,
      });
      // for checkbox display
      setIsTimeFollowUpNotRequired(!Boolean(response?.timeFollowUpRequired));
      setIsCostFollowUpNotRequired(!Boolean(response?.costFollowUpRequired));
      // time
      const {
        first: timeFirst,
        convertedFollowUps: convertedTimeFollowUps,
        final: timeFinal,
      } = handleNotificationStage(response.timeNotifications);
      const newTimeNotifications = {
        firstNotification: timeFirst,
        followUpNotifications: convertedTimeFollowUps,
        finalNotification: timeFinal,
      };
      setTimeNotifications(cloneDeep(newTimeNotifications));
      setDisplayTimeNotifications(cloneDeep(newTimeNotifications));
      // cost
      const {
        first: costFirst,
        convertedFollowUps: costFollowUps,
        final: costFinal,
      } = handleNotificationStage(response.costNotifications);
      const newCostNotifications = {
        firstNotification: costFirst,
        followUpNotifications: costFollowUps,
        finalNotification: costFinal,
      };
      setCostNotifications(cloneDeep(newCostNotifications));
      setDisplayCostNotifications(cloneDeep(newCostNotifications));
      setIsNotificationLoaded(true);
    } catch (ex) {
      errorHandler(ex, "Fetch Notifications");
    }
  }, [errorHandler, eventId, handleNotificationStage, serviceConfig]);

  React.useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const [editingPanel, setEditingPanel] = React.useState<EditingPanel>("");

  const scheduleFields = React.useMemo(
    () => [
      {
        label: t("voc.eventPage.notifications.dateOfAwareness"),
        value: generalInfo.dateOfAwareness,
        type: "date",
        width: "fifth",
        onChangeCallback: null,
        editable: false,
      },
      {
        label: t("voc.eventPage.notifications.dateOfCessation"),
        value: generalInfo.dateOfCessation,
        type: "date",
        width: "fifth",
        onChangeCallback: null,
        editable: false,
      },
    ],
    [generalInfo.dateOfAwareness, generalInfo.dateOfCessation, t]
  );

  return (
    <AccessVisible
      objectCode="compensation_event"
      actionType="view"
      fallback={<i>{t("app.permission.accessDenied")}</i>}
    >
      <div className="notifications-tab-container">
        <ReminderButton />
        {isNotificationLoaded && isLibraryLoaded && (
          <>
            {!generalInfo.dateOfAwareness ? (
              <ValidationWarning
                message={t(
                  "voc.eventPage.notifications.dateOfAwarenessWarning"
                )}
              />
            ) : null}
            {!timeNotificationsIntervals.firstInterval &&
            !costNotificationsIntervals.firstInterval ? (
              <ValidationWarning
                message={t("voc.eventPage.notifications.firstTimeWarning")}
              />
            ) : null}
            {!timeNotificationsIntervals.followUpInterval &&
            !costNotificationsIntervals.followUpInterval ? (
              <ValidationWarning
                message={t("voc.eventPage.notifications.followUpTimeWarning")}
              />
            ) : null}
            {!timeNotificationsIntervals.finalInterval &&
            !costNotificationsIntervals.finalInterval ? (
              <ValidationWarning
                message={t("voc.eventPage.notifications.finalTimeWarning")}
              />
            ) : null}
          </>
        )}
        <AppCard title={t("voc.eventPage.records.schedule")} canEdit={false}>
          <AppFieldBlock editing={false} fields={scheduleFields} />
        </AppCard>
        {isNotificationLoaded && isLibraryLoaded && (
          <>
            <NotificationsTabPanel
              eventId={eventId}
              notificationType="TIME"
              title={
                generalInfo.isDivideTimeCost
                  ? t("voc.eventPage.notifications.time")
                  : t("voc.eventPage.notifications.timeAndCost")
              }
              generalInfo={generalInfo}
              fetchNotifications={fetchNotifications}
              editingPanel={editingPanel}
              setEditingPanel={setEditingPanel}
              notifications={timeNotifications}
              displayNotifications={displayTimeNotifications}
              setDisplayNotifications={setDisplayTimeNotifications}
              isFollowUpNotRequired={isTimeFollowUpNotRequired}
              onSetIsFollowUpNotRequired={setIsTimeFollowUpNotRequired}
            />
            {generalInfo.isDivideTimeCost && (
              <NotificationsTabPanel
                eventId={eventId}
                notificationType="COST"
                title={t("voc.eventPage.notifications.cost")}
                generalInfo={generalInfo}
                fetchNotifications={fetchNotifications}
                editingPanel={editingPanel}
                setEditingPanel={setEditingPanel}
                notifications={costNotifications}
                displayNotifications={displayCostNotifications}
                setDisplayNotifications={setDisplayCostNotifications}
                isFollowUpNotRequired={isCostFollowUpNotRequired}
                onSetIsFollowUpNotRequired={setIsCostFollowUpNotRequired}
              />
            )}
          </>
        )}
      </div>
    </AccessVisible>
  );
};

export default NotificationsTabPage;
