import { Table } from "antd";
import React from "react";
import { VocGetUpdateValuesSubrecord } from "../../../../app/types";
import { numberFormat } from "./helpers/value-breakdown-helper";

interface SummaryComponentProps {
  dataSource: VocGetUpdateValuesSubrecord[];
}

const SummaryComponent: React.FC<SummaryComponentProps> = ({ dataSource }) => {
  if (dataSource.length > 0) {
    return (
      <Table.Summary fixed="bottom">
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            {`$${numberFormat(
              dataSource.reduce((acc, cur) => acc + cur.directCostPrevious, 0)
            )}`}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            {`$${numberFormat(
              dataSource.reduce((acc, cur) => acc + cur.indirectCostPrevious, 0)
            )}`}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            {`$${numberFormat(
              dataSource.reduce((acc, cur) => acc + cur.directCostPlanned, 0)
            )}`}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            {`$${numberFormat(
              dataSource.reduce((acc, cur) => acc + cur.indirectCostPlanned, 0)
            )}`}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}>
            {`$${numberFormat(
              dataSource.reduce((acc, cur) => acc + cur.closeOut, 0)
            )}`}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7}>
            {`$${numberFormat(
              dataSource.reduce((acc, cur) => acc + cur.gna, 0)
            )}`}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8}>
            {`$${numberFormat(
              dataSource.reduce((acc, cur) => acc + cur.totalCost, 0)
            )}`}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9}>
            {`$${numberFormat(
              dataSource.reduce((acc, cur) => acc + cur.revenue, 0)
            )}`}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {`$${numberFormat(
              dataSource.reduce((acc, cur) => acc + cur.profitLoss, 0)
            )}`}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            {dataSource.length > 0
              ? `${(
                  (dataSource.reduce(
                    (acc, cur) => acc + cur.profitLoss + cur.gna,
                    0
                  ) /
                    dataSource.reduce(
                      (acc, cur) => acc + cur.totalCost - cur.gna,
                      0
                    )) *
                  100
                ).toFixed(2)}%`
              : "0.00%"}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}>
            {`$${numberFormat(
              dataSource.reduce((acc, cur) => acc + cur.paymentApplication, 0)
            )}`}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={13}>
            {`$${numberFormat(
              dataSource.reduce((acc, cur) => acc + cur.clientAssessed, 0)
            )}`}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={14}>
            {`$${numberFormat(
              dataSource.reduce((acc, cur) => acc + cur.clientCertified, 0)
            )}`}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={15}></Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  }
  return null;
};

export default SummaryComponent;
