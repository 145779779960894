import { VocSupportDocument } from "../../../../app/types";
import {
  ServiceConfig,
  apiRequest,
} from "../../../../app/utils/backend/api-request";
import { mapDateToString } from "../../../../app/utils/mappers";

export const createSupportDocument = (
  id: string,
  supportDocument: Partial<VocSupportDocument>,
  serviceConfig: ServiceConfig,
  isSiteEvent: boolean
) => {
  const endpoint = isSiteEvent
    ? `/site_events/${id}/support_document`
    : `/events/${id}/support_document`;

  const supportDocumentWithConvertedDates = {
    ...supportDocument,
    details: {
      ...supportDocument.details,
      documentDate: mapDateToString(supportDocument.details.documentDate),
      fromDate: mapDateToString(supportDocument.details.fromDate),
      toDate: mapDateToString(supportDocument.details.toDate),
    },
  };

  return apiRequest<VocSupportDocument>(endpoint, serviceConfig, {
    credentials: "same-origin",
    method: "POST",
    body: JSON.stringify(supportDocumentWithConvertedDates),
  });
};

// export const createSupportDocumentInRecord = (
//   eventId: string,
//   supportDocument: Partial<VocSupportDocument>,
//   serviceConfig: ServiceConfig
// ) => {
//   const supportDocument_: { [key: string]: any } = {
//     ...supportDocFormat(supportDocument),
//   };
//   Object.keys(supportDocument_).forEach((key) => {
//     if (supportDocument_[key] === null) {
//       // remove null field for other document type to meet api requirement
//       supportDocument_[key] = undefined;
//     }
//   });
//   if (supportDocument_.subcontractor) {
//     supportDocument_.subcontractorId = supportDocument_.subcontractor.id;
//     supportDocument_.subcontractor = undefined;
//   }
//   return apiRequest<VocSupportDocument>(
//     `/events/${eventId}/support_document`,
//     serviceConfig,
//     {
//       credentials: "same-origin",
//       method: "POST",
//       body: JSON.stringify(supportDocument_),
//     }
//   );
// };
