import React, { useCallback } from "react";

import { VocEventFile } from "../../types";
import AppFileUpload from "../app-file-upload/AppFileUpload";
import { AppFormField } from "./";
import { AppFormFieldProps } from "./AppFormField";

import "./AppFormMultiFileField.scss";

export type AppFormMultiFileFieldProps = {
  files: VocEventFile[];
  fileLimit?: number;

  label?: string;
  onFileChange: (files: VocEventFile[]) => void;
  onRemove: () => void;
  errorMessage?: string | string[];
} & AppFormFieldProps;

const AppFormMultiFileField: React.FC<AppFormMultiFileFieldProps> = ({
  files,
  label = "File(s)",
  fileLimit = 100,
  errorMessage,
  onFileChange,
  onRemove,
}) => {
  const handleFileChange = useCallback(
    (files: VocEventFile[]) => {
      const uploadedFiles = files.map((file) => ({
        url: (file as any).url,
        fileName: file.fileName,
        assetId: file.assetId,
        mimeType: file.mimeType,
      }));
      onFileChange(uploadedFiles);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <AppFormField className="voc-form-file-field">
        <AppFileUpload
          fileLimit={fileLimit}
          label={label}
          onChange={handleFileChange}
        />
        {errorMessage && (
          <div role="alert" className="error">
            <p>
              {Array.isArray(errorMessage)
                ? errorMessage.join(" / ")
                : errorMessage}
            </p>
          </div>
        )}
      </AppFormField>
    </>
  );
};

export default AppFormMultiFileField;
