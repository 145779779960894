import { useCallback, useLayoutEffect, useState } from "react";

export interface AsyncResult<T = any> {
  loading: boolean;
  data?: T;
  error?: Error;
}

export interface AsyncHandlers<T> {
  onLoad?: () => void;
  onSuccess?: (data: T) => void;
  onError?: (ex: Error) => void;
}

export function useAsync<T = any>(
  initialRequest?: () => Promise<T>, // use it only when you want to trigger the async operation on load
  handlers?: AsyncHandlers<T>,
  defaultResultData?: T
) {
  const [asyncResult, setAsyncResult] = useState<AsyncResult<T>>({
    loading: false,
    data: defaultResultData,
    error: undefined,
  });
  const setAsyncRequest = useCallback(
    (asyncRequest: (() => Promise<T>) | undefined) => {
      if (asyncRequest !== undefined) {
        const promise = asyncRequest();
        if (promise) {
          setAsyncResult((prevState) => ({
            loading: true,
            data: prevState.data,
            error: undefined,
          }));

          if (handlers?.onLoad) {
            handlers.onLoad();
          }
          promise
            .then((data) => {
              if (handlers?.onSuccess) {
                handlers.onSuccess(data);
              }
              setAsyncResult({
                loading: false,
                data,
                error: undefined,
              });
            })
            .catch((error) => {
              if (handlers?.onError) {
                handlers.onError(error);
              }
              setAsyncResult({
                loading: false,
                data: defaultResultData,
                error,
              });
            });
        } else {
          setAsyncResult({
            loading: false,
            data: defaultResultData,
            error: undefined,
          });
        }
      } else {
        setAsyncResult({
          loading: false,
          data: defaultResultData,
          error: undefined,
        });
      }
    },
    // defaultResultData and setAsyncResult are not supposed to change, thus not included as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handlers]
  );

  useLayoutEffect(() => {
    if (initialRequest) {
      setAsyncRequest(initialRequest);
    }
    // initialRequest is not intended to be updated, so it's not included as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [asyncResult, setAsyncRequest] as const;
}

// below hook exists to support the use of useAsyncLegacy - an discontinued version of async hook
// new feature should stick with the current version of useAsync above
export function useAsyncLegacy<T = any>(
  initialRequest?: () => Promise<T>, // use it only when you want to trigger the async operation on load
  handlers?: AsyncHandlers<T>,
  defaultResultData?: T
) {
  const [asyncResult, setAsyncResult] = useState<AsyncResult<T>>({
    loading: false,
    data: defaultResultData,
    error: undefined,
  });
  const setAsyncRequest = useCallback(
    (asyncRequest: (() => Promise<T>) | undefined) => {
      if (asyncRequest !== undefined) {
        const promise = asyncRequest();
        if (promise) {
          setAsyncResult((prevState) => ({
            loading: true,
            data: prevState.data,
            error: undefined,
          }));

          if (handlers?.onLoad) {
            handlers.onLoad();
          }
          promise
            .then((data) => {
              if (handlers?.onSuccess) {
                handlers.onSuccess(data);
              }
              setAsyncResult({
                loading: false,
                data,
                error: undefined,
              });
            })
            .catch((error) => {
              if (handlers?.onError) {
                handlers.onError(error);
              }
              setAsyncResult({
                loading: false,
                data: defaultResultData,
                error,
              });
            });
        } else {
          setAsyncResult({
            loading: false,
            data: defaultResultData,
            error: undefined,
          });
        }
      } else {
        setAsyncResult({
          loading: false,
          data: defaultResultData,
          error: undefined,
        });
      }
    },
    // defaultResultData and setAsyncResult are not supposed to change, thus not included as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useLayoutEffect(() => {
    if (initialRequest) {
      setAsyncRequest(initialRequest);
    }
    // initialRequest is not intended to be updated, so it's not included as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [asyncResult, setAsyncRequest] as const;
}
