import { notify, NxpButton } from "@nexploretechnology/nxp-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAppContext from "../../../../../app/hooks/useAppContext";
import {
  VocGetUpdateValuesSubrecord,
  VocGetValuesBuildUp,
  VocGetValuesEisReporting,
  VocGetValuesToClient,
  VocGetValuesToDate,
} from "../../../../../app/types";
import { sendValues } from "../../../../compensation-event-services";

interface CopyAllButtonProps {
  dataSource: VocGetUpdateValuesSubrecord[];
  editForm: VocGetUpdateValuesSubrecord;
  toClientFormState: VocGetValuesToClient;
  actualValues: {
    physicalProgress: number;
    internalProgressValue: number;
  };
  eisReportingFormState: Pick<
    VocGetValuesEisReporting,
    | "eisA2A6"
    | "in16"
    | "eisA8A14"
    | "eisSecuredProject"
    | "eisStrategyIncludedInPlanning"
  >;
  onSetCustomValues: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  onSetBuildUpFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesBuildUp>
  >;
  onSetToClientFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesToClient>
  >;
  onSetPaymentWithClientFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesToDate>
  >;
  onSetActualValues: React.Dispatch<
    React.SetStateAction<{
      physicalProgress: number;
      internalProgressValue: number;
    }>
  >;
  onSetCostCodes: React.Dispatch<
    React.SetStateAction<{
      costCodes: string[];
    }>
  >;
  onSetEisReportingFormState: React.Dispatch<
    React.SetStateAction<
      Pick<
        VocGetValuesEisReporting,
        | "eisA2A6"
        | "eisA8A14"
        | "in16"
        | "eisSecuredProject"
        | "eisStrategyIncludedInPlanning"
      >
    >
  >;
}

const CopyAllButton: React.FC<CopyAllButtonProps> = ({
  dataSource,
  editForm,
  toClientFormState,
  actualValues,
  eisReportingFormState,
  onSetCustomValues,
  onSetEisReportingFormState,
  onSetCostCodes,
  onSetBuildUpFormState,
  onSetToClientFormState,
  onSetPaymentWithClientFormState,
  onSetActualValues,
}) => {
  const { t } = useTranslation();

  const { eventId } = useParams<{ eventId: string }>();

  const { serviceConfig, errorHandler } = useAppContext();

  const handleCopyAll = async () => {
    try {
      // Calculate values
      const clientAssessed = dataSource.reduce(
        (acc, cur) => acc + cur.clientAssessed,
        0
      );
      const internalTarget = dataSource.reduce(
        (acc, cur) => acc + cur.revenue,
        0
      );

      const internalValue = toClientFormState.internalValue; // C

      const recovery = internalTarget / internalValue; // E / C

      const directCost =
        dataSource.reduce((acc, cur) => acc + cur.directCostPrevious, 0) +
        dataSource.reduce((acc, cur) => acc + cur.directCostPlanned, 0);
      const indirectCost =
        dataSource.reduce((acc, cur) => acc + cur.indirectCostPrevious, 0) +
        dataSource.reduce((acc, cur) => acc + cur.indirectCostPlanned, 0);
      const costCloseOut = dataSource.reduce(
        (acc, cur) => acc + cur.closeOut,
        0
      );
      const costGna = dataSource.reduce((acc, cur) => acc + cur.gna, 0);
      const totalCost = dataSource.reduce((acc, cur) => acc + cur.totalCost, 0);
      const cumulativeValueCertified = dataSource.reduce(
        (acc, cur) => acc + cur.clientCertified,
        0
      );
      const cumulativeAppliedNominal = dataSource.reduce(
        (acc, cur) => acc + cur.paymentApplication,
        0
      );
      const costCodes = Array.from(
        new Set(
          dataSource.map((code) => code.costCode).filter((code) => code !== "")
        )
      );

      const profitLoss = internalTarget - totalCost;
      const overheadProfit =
        (profitLoss + costGna) / (directCost + indirectCost + costCloseOut); // O = (N + L) / (I + J + K)

      const valuesForm = {
        internalTarget: internalTarget,
        recovery: recovery,
        clientAssessed: clientAssessed,
        internalVariance: toClientFormState.internalValue - clientAssessed,
        targetVariance: internalTarget - clientAssessed,
        directCost: directCost,
        indirectCost: indirectCost,
        costCloseOut: costCloseOut,
        costGna: costGna,
        totalCost: totalCost,
        profitLoss: profitLoss,
        overheadProfit: overheadProfit,
        internalProgressValue: actualValues.physicalProgress * internalTarget,
        costCodes: costCodes,
        cumulativeApplied: cumulativeAppliedNominal / internalTarget,
        cumulativeAppliedNominal: cumulativeAppliedNominal,
        cumulativeValueCertified: cumulativeValueCertified,
        cumulativeValueVariance:
          cumulativeAppliedNominal - cumulativeValueCertified,
        eisA8A14: internalTarget - eisReportingFormState.eisA2A6,
        eisSecuredProject: eisReportingFormState.eisSecuredProject,
        eisStrategyIncludedInPlanning:
          internalTarget - eisReportingFormState.eisSecuredProject,
      };
      const updatedValues = await sendValues(
        eventId,
        valuesForm,
        serviceConfig
      );
      onSetCustomValues(updatedValues.customization);

      onSetToClientFormState(updatedValues.toClient);

      onSetBuildUpFormState(updatedValues.buildUp);

      onSetPaymentWithClientFormState(updatedValues.valuesToDate);

      onSetActualValues({
        physicalProgress: actualValues.physicalProgress,
        internalProgressValue: actualValues.internalProgressValue,
      });

      onSetCostCodes({ costCodes: costCodes });

      onSetEisReportingFormState(updatedValues.eisReporting);
      notify.actionCompleted();
    } catch (error) {
      errorHandler(error, "copy all values");
    }
  };

  return (
    <NxpButton disabled={!!editForm} onClick={handleCopyAll}>
      {t("voc.eventPage.values.copyAll")}
    </NxpButton>
  );
};

export default CopyAllButton;
