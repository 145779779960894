import { useCallback, useContext, useMemo } from "react";

import { datadogLogs } from "@datadog/browser-logs";

import AppContext from "../components/app-context";

export type LogLevel = "log" | "info" | "debug" | "warn" | "error";

export const useLogger = () => {
  const { activeUser, activeEntity } = useContext(AppContext);

  const patchLogReference = useCallback(
    (logReference: string | number | object | undefined, level: LogLevel) => {
      const ref =
        typeof logReference === "string" || typeof logReference === "number"
          ? { id: logReference }
          : logReference;

      return {
        ...ref,
        level,
        user: activeUser?.id,
        hostname: process.env.REACT_APP_DATADOG_HOSTNAME,
        entity: activeEntity?.id,
      };
    },
    [activeEntity?.id, activeUser?.id]
  );

  const logger = useMemo(
    () => ({
      log: (message: string, logReference?: string | number | object) => {
        datadogLogs.logger.log(message, patchLogReference(logReference, "log"));
      },
      info: (
        message: string,
        logReference?: string | number | object,
        error?: Error
      ) => {
        datadogLogs.logger.info(
          message,
          patchLogReference(logReference, "info"),
          error
        );
      },
      debug: (
        message: string,
        logReference?: string | number | object,
        error?: Error
      ) => {
        datadogLogs.logger.debug(
          message,
          patchLogReference(logReference, "debug"),
          error
        );
      },
      warn: (
        message: string,
        logReference?: string | number | object,
        error?: Error
      ) => {
        datadogLogs.logger.warn(
          message,
          patchLogReference(logReference, "warn"),
          error
        );
      },
      error: (
        message: string,
        logReference?: string | number | object,
        error?: Error
      ) => {
        datadogLogs.logger.error(
          message,
          patchLogReference(logReference, "error"),
          error
        );
      },
    }),
    [patchLogReference]
  );

  return logger;
};
