import { sum } from "lodash";
import i18n from "../../../../../app/i18n/i18n";
import { VocGetUpdateValuesSubrecord } from "../../../../../app/types";

export const parseNumber = (value) => {
  const num = parseFloat(value);
  return isNaN(num) ? 0 : num;
};

export const calculateTotalCost = (
  form: VocGetUpdateValuesSubrecord,
  newValueOverrides = {}
) => {
  const combinedValues = { ...form, ...newValueOverrides };
  return sum([
    parseNumber(combinedValues.directCostPlanned),
    parseNumber(combinedValues.indirectCostPlanned),
    parseNumber(combinedValues.directCostPrevious),
    parseNumber(combinedValues.indirectCostPrevious),
    parseNumber(combinedValues.closeOut),
    parseNumber(combinedValues.gna),
  ]);
};

export const calculateProfitLoss = (
  form: VocGetUpdateValuesSubrecord,
  totalCost: number
) => {
  const revenue = parseNumber(form.revenue);
  const profitLoss = revenue - totalCost;
  return parseFloat(profitLoss.toFixed(2));
};

export const calculateOhnp = (
  form: VocGetUpdateValuesSubrecord,
  profitLoss: number,
  totalCost: number
) => {
  const totalCostMinusGna = parseNumber(form.totalCost) - parseNumber(form.gna);
  if (totalCostMinusGna === 0) return 0;

  return (profitLoss + form.gna) / (totalCost - form.gna);
};

export const numberFormat = (n) => {
  const parts = n.toFixed(2).toString().split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  const sum = ["de", "es"].includes(i18n.language)
    ? numberPart.replace(thousands, ".") +
      (decimalPart ? "," + decimalPart : "")
    : numberPart.replace(thousands, ",") +
      (decimalPart ? "." + decimalPart : "");
  return sum;
};
