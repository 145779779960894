// https://stackoverflow.com/questions/4762254/javascript-window-location-does-not-set-referer-in-the-request-header
export function redirectWithReferrer(url: string) {
  const a = document.createElement("a");
  if (a.click) {
    // HTML5 browsers and IE support click() on <a>, early FF does not.
    a.setAttribute("href", url);
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
  } else {
    // Early FF can, however, use this usual method
    // where IE cannot with secure links.
    window.location.href = url;
  }
}

//https://stackoverflow.com/questions/5004978/check-if-page-gets-reloaded-or-refreshed-in-javascript/55087265#55087265
export function getNavigationType() {
  let result:
    | "navigate"
    | "reload"
    | "back_forward"
    | "prerender"
    | "reserved"
    | undefined;

  let p: string;

  if (
    window.performance &&
    performance.navigation.type === performance.navigation.TYPE_RELOAD
  ) {
    result = "reload";
  } else {
    const windowPref = window.performance as any;

    if (windowPref) {
      if (windowPref.navigation === 255) {
        result = "reserved";
      }
    }

    if (windowPref.getEntriesByType("navigation")) {
      p = windowPref.getEntriesByType("navigation")[0].type;

      if (
        p === "navigate" ||
        p === "reload" ||
        p === "back_forward" ||
        p === "prerender"
      ) {
        result = p;
      }
    }
  }
  return result;
}
