import React from "react";
import { useTranslation } from "react-i18next";

import { DateFormatPattern, formatDate } from "@nexploretechnology/nxp-ui";
import { cloneDeep } from "lodash";
import AppCollapsible from "../../../app/components/app-collapsible/AppCollapsible";
import AppDataListV2 from "../../../app/components/app-data-list/AppDataListV2";
import { DataListItem } from "../../../app/components/app-data-list/types";
import { DeletedRecord, VocLibraryObject } from "../../../app/types";
import {
  INSTRUCTED_BY_NONE,
  INSTRUCTED_BY_OTHER,
} from "../../../app/utils/const";
import { useLibraryHandler } from "../../hooks/useLibraryHandler";

interface EmpoweredRepsProps {
  libraryType: "VOC_VO_EMPOWER_REPS";
  refetchCount: number;
}

export const EmpoweredReps: React.FC<EmpoweredRepsProps> = ({
  libraryType,
  refetchCount,
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = React.useState(false);

  const convertEmpoweredClientReps = (items: VocLibraryObject[]) => {
    const mutables = items.filter((item) => !item.immutable);
    const converted = mutables.map((item) => {
      const isDefaultRow =
        item.value === "VO_INSTR_OWNER_99" ||
        item.value === "VO_INSTR_OWNER_999";
      return {
        id: item.id,
        edited: false,
        value: item.value,
        sortOrder: item.sortOrder,
        fieldDataArray: [
          { fieldData: item.firstName, readonly: isDefaultRow },
          { fieldData: item.lastName, readonly: isDefaultRow },
          { fieldData: item.company, readonly: isDefaultRow },
          { fieldData: item.role, readonly: isDefaultRow },
          { fieldData: item.email, readonly: isDefaultRow },
          { fieldData: item.representDate, readonly: isDefaultRow },
          { fieldData: item.limitations, readonly: isDefaultRow },
          {
            fieldData: item.appointmentFile ? [item.appointmentFile] : [],
            readonly: isDefaultRow,
          },
          { fieldData: item.appointmentReference, readonly: isDefaultRow },
          {
            fieldData: item.disabled !== null ? item.disabled : false,
            // readonly: isDefaultRow, // turn on
          },
        ],
        undeletable: isDefaultRow,
      };
    });
    return converted;
  };

  const {
    // Variables
    fetchLibraryAndUsage,
    isLibraryAndUsageLoaded,
    libraryItem,
    displayLibraryItem,
    setDisplayLibraryItem,
    // Library Custom Sort
    isCustomSort,
    saveCustomOrder,
    resetCustomOrder,
    // Update Handler
    handleUpdateLibraries,
  } = useLibraryHandler(libraryType, convertEmpoweredClientReps, refetchCount);

  // ================================================
  //                 setFiles
  // ================================================

  const handleCustomSetFiles = React.useCallback(
    (file: any, fileRef: any) => {
      setDisplayLibraryItem((prevDisplayLibraryItem) => {
        const newDisplayLibraryItem = cloneDeep(prevDisplayLibraryItem);
        if (newDisplayLibraryItem?.[fileRef]?.fieldDataArray?.[7]) {
          newDisplayLibraryItem[fileRef].fieldDataArray[7].fieldData = file;
          newDisplayLibraryItem[fileRef].edited = true;
        }
        return newDisplayLibraryItem;
      });
    },
    [setDisplayLibraryItem]
  );

  // ================================================
  //                 panel & colDef
  // ================================================

  const panelTitle = React.useMemo(
    () => t("voc.library.titles.empoweredClientReps"),
    [t]
  );

  const column = React.useMemo(
    () => [
      {
        columnName: t("voc.library.firstName"),
        columnWidthClassName: "fifteenpercent",
        columnDataType: "string",
        editable: true,
      },
      {
        columnName: t("voc.library.lastName"),
        columnWidthClassName: "twelvepercent",
        columnDataType: "string",
        editable: true,
      },
      {
        columnName: t("voc.library.company"),
        columnWidthClassName: "twelvepercent",
        columnDataType: "string",
        editable: true,
      },
      {
        columnName: t("voc.library.role"),
        columnWidthClassName: "twelvepercent",
        columnDataType: "string",
        editable: true,
      },
      {
        columnName: t("voc.library.email"),
        columnWidthClassName: "twelvepercent",
        columnDataType: "string",
        editable: true,
      },
      {
        columnName: t("voc.library.representFromAppointmentDate"),
        columnWidthClassName: "sevenpercent",
        columnDataType: "date",
        editable: true,
      },
      {
        columnName: t("voc.library.clausesLimitations"),
        columnWidthClassName: "sevenpercent",
        columnDataType: "string",
        editable: true,
      },
      {
        columnName: t("voc.library.appointmentLetter"),
        columnWidthClassName: "sevenpercent",
        columnDataType: "file",
        editable: true,
      },
      {
        columnName: t("voc.library.appointmentReference"),
        columnWidthClassName: "sevenpercent",
        columnDataType: "link",
        editable: true,
      },
      {
        columnName: t("voc.library.disabled"),
        columnWidthClassName: "sevenpercent",
        columnDataType: "toggle",
        editable: true,
      },
    ],
    [t]
  );

  // ================================================
  //                Action Handler
  // ================================================

  const handleSave = () => {
    const deconvertedReps: Partial<VocLibraryObject>[] = [];
    displayLibraryItem.forEach((rep: DataListItem, i: number) => {
      if (rep.edited) {
        deconvertedReps.push({
          id: rep.id,
          value: rep.value,
          firstName: rep.fieldDataArray[0].fieldData,
          lastName: rep.fieldDataArray[1].fieldData,
          company: rep.fieldDataArray[2].fieldData,
          role: rep.fieldDataArray[3].fieldData,
          email: rep.fieldDataArray[4].fieldData,
          representDate: rep.fieldDataArray[5].fieldData
            ? (formatDate(
                rep.fieldDataArray[5].fieldData,
                DateFormatPattern.date
              ) as any)
            : null, // comment convert date to string for VC-1700 dackend change
          limitations: rep.fieldDataArray[6].fieldData,
          appointmentFile:
            rep.fieldDataArray[7].fieldData?.length > 0
              ? {
                  assetId: rep.fieldDataArray[7].fieldData[0].assetId,
                  fileName: rep.fieldDataArray[7].fieldData[0].fileName,
                  mimeType: rep.fieldDataArray[7].fieldData[0].mimeType,
                  url: rep.fieldDataArray[7].fieldData[0].url,
                }
              : null,
          appointmentReference: rep.fieldDataArray[8].fieldData,
          disabled: rep.fieldDataArray[9].fieldData,
        });
      }
    });
    const deletedRecords: DeletedRecord[] = [];
    libraryItem.forEach((memo: any) => {
      let deleted = true;
      displayLibraryItem.forEach((rep) => {
        if (memo.id === rep.id) {
          deleted = false;
        }
      });
      if (
        deleted &&
        memo.value !== INSTRUCTED_BY_OTHER &&
        memo.value !== INSTRUCTED_BY_NONE
      ) {
        deletedRecords.push({
          id: memo.id,
          value: memo.value,
          firstName: memo.firstName,
          lastName: memo.lastName,
        });
      }
    });
    //BACKEND REQUIRES CERTAIN FIELDS, EVEN WHEN DELETING, WHEN IT SHOULD NOT BE NECESSARY
    const deletedRecordObjects: DeletedRecord[] = deletedRecords.map((rec) => ({
      id: rec.id,
      value: rec.value,
      firstName: rec.firstName,
      lastName: rec.lastName,
      isDeleted: true,
    }));
    const combinedRecords = [...deconvertedReps, ...deletedRecordObjects];
    handleUpdateLibraries(
      {
        items: combinedRecords,
      },
      () => setIsEditing(false)
    );
  };

  const handleCancel = () => {
    // setDisplayLibraryItem(libraryItem);
    fetchLibraryAndUsage();
  };

  return (
    <AppCollapsible
      permissionCode="libraries"
      hasFields={false}
      editCallback={() => {
        setIsEditing(true);
      }}
      editingState={isEditing}
      saveCallback={handleSave}
      cancelCallback={() => {
        handleCancel();
        setIsEditing(false);
      }}
      firstDecollapseCallback={() => {
        fetchLibraryAndUsage();
      }}
      isLoading={!isLibraryAndUsageLoaded}
      isLoadOnEveryClick={true}
      headerChildren={<div className="doctype-title">{panelTitle}</div>}
      bodyChildren={
        isLibraryAndUsageLoaded ? (
          <AppDataListV2
            permissionCode="libraries"
            editing={isEditing}
            canDeleteLastItem={true}
            columns={column}
            oriItems={libraryItem}
            items={displayLibraryItem}
            editSetStateHook={setDisplayLibraryItem}
            handleCustomSetFiles={handleCustomSetFiles}
            // setFiles={setFiles} // we use handleCustomSetFiles
            defaultSortDirection=""
            saveCustomOrderCallback={saveCustomOrder}
            resetCustomOrderCallback={resetCustomOrder}
            saveCustomOrderCode={libraryType}
            initCustomSortState={isCustomSort}
          />
        ) : null
      }
    ></AppCollapsible>
  );
};

export default EmpoweredReps;
