import { DictionaryItem } from "../services/dictionary";

export interface LocationOption {
  render: string;
  level: number;
  enumValue: string;
  disabled?: boolean;
  childOf?: string;
}
export function convertLocationOptions(dicts: DictionaryItem[]) {
  const options: LocationOption[] = [];
  function unpackChildren(
    dict: DictionaryItem,
    childOf: string,
    childN: number
  ) {
    const spacer = " ".repeat(childN);
    const option = {
      enumValue: dict.key,
      level: childN,
      render: spacer + dict.label,
      disabled: true,
      childOf,
    };
    options.push(option);

    dict.members?.forEach((c: any) => {
      unpackChildren(c, dict.key, childN + 1);
    });
  }

  dicts.forEach((d) => {
    unpackChildren(d, null, 0);
  });
  return options;
}

export function convertToTreeSelectOptions(items: DictionaryItem[]): any[] {
  // A recursive function to format the nested structure for treeSelect
  const formatForTreeSelect = (node: DictionaryItem): any => ({
    title: node.label,
    value: node.value,
    children:
      node.members && node.members.length > 0
        ? node.members.map(formatForTreeSelect)
        : undefined,
  });

  return items.map(formatForTreeSelect);
}
