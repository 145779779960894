import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AppButton from "../../../../app/components/app-button";
import NotifyModalContainer from "./NotifyModalContainer";

import "./NotifyButton.scss";

interface NotifyButtonProps {}

const NotifyButton: React.FC<NotifyButtonProps> = (props) => {
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const handleModalClose = () => setShowNotifyModal(false);
  const { t } = useTranslation();
  return (
    <>
      <AppButton
        className="voc-event-summary-notify"
        onClick={() => setShowNotifyModal(true)}
      >
        {t("voc.eventPage.summary.notifications")}
      </AppButton>
      <NotifyModalContainer
        showNotifyModal={showNotifyModal}
        onModalClose={handleModalClose}
      />
    </>
  );
};

export default NotifyButton;
