// import AppButton from '../../../components/app-button';
// import CustomApiError from '../../../services/customApiError';
// import { AsyncResult } from '../../../utils/hooks';
// import { VocValueRegister } from '../../../types';
// import { AppFormRow, AppFormTextField } from '../../../components/app-form';
import "./BatchPaymentReferenceModal.scss";

// import AppModal from '../../../components/app-modal';
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AppButton from "../../../../app/components/app-button";
import {
  AppFormRow,
  AppFormTextField,
} from "../../../../app/components/app-form";
import AppModal from "../../../../app/components/app-modal";
import { AsyncResult } from "../../../../app/hooks/useAsync";
import { VocValueRegister } from "../../../../app/types";
import CustomApiError from "../../../../app/utils/backend/custom-api-error";

interface BatchPaymentReferenceModalProps {
  showModal: boolean;
  recordCount: number;
  updateValuationsAsyncResult: AsyncResult<VocValueRegister[]>;
  onSave: (paymentReference: string) => void;
  onClose: () => void;
}

const BatchPaymentReferenceModal: React.FC<BatchPaymentReferenceModalProps> = ({
  showModal,
  recordCount,
  updateValuationsAsyncResult,
  onSave,
  onClose,
}) => {
  const [paymentReference, setPaymentReference] = useState<string>();
  const { t } = useTranslation();
  const handleSaveClick = () => {
    if (!paymentReference) {
      setPaymentReference("");
    }
    onSave(paymentReference);
  };

  return (
    <AppModal
      title={t("voc.common.batchUpdate")}
      isOpen={showModal}
      onClose={onClose}
      className="voc-value-register-event-modal"
      loading={updateValuationsAsyncResult.loading}
      footer={
        <AppButton
          disabled={updateValuationsAsyncResult.loading}
          className="button"
          onClick={handleSaveClick}
          text={t("voc.common.save")}
        />
      }
      errorContent={
        !(updateValuationsAsyncResult.error as CustomApiError)
          ?.serverResponseData && updateValuationsAsyncResult.error?.message
      }
    >
      <AppFormRow>
        <AppFormTextField
          required={true}
          label={`Payment Reference (${recordCount} record${
            recordCount > 1 ? "s" : ""
          } affected)`}
          name="paymentReference"
          value={paymentReference}
          errorMessage={
            paymentReference === "" ? "Payment Reference required." : undefined
          }
          onChange={(_, value) => setPaymentReference(value)}
        />
      </AppFormRow>
    </AppModal>
  );
};

export default BatchPaymentReferenceModal;
