import React, { useCallback, useState } from "react";
import * as yup from "yup";

import { useParams } from "react-router-dom";

import { UploadOutlined } from "@ant-design/icons";
import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModalFooter,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { Button, TreeSelect, Upload } from "antd";
import { isBefore } from "date-fns";
import { useTranslation } from "react-i18next";
import useAppContext from "../../../../../app/hooks/useAppContext";
import { VocSupportDocumentEnums } from "../../../../../app/types";
import { convertToTreeSelectOptions } from "../../../../../app/utils/location";
import { getUserDisplayDateFormat } from "../../../../../app/utils/mappers";
import { createSupportDocument } from "../new-document-form-services";
import { DrawingForm } from "../new-document-form-type";
import { useFileHandler } from "./hooks/useFileHandler";

interface NewAcaFormProps {
  onModalClose: () => void;
  onRefresh: () => void;
  documentType: VocSupportDocumentEnums | "";
}

const NewDrawingForm: React.FC<NewAcaFormProps> = ({
  onModalClose,
  onRefresh,
  documentType,
}) => {
  const { t } = useTranslation();

  const { eventId, siteEventId } = useParams();

  const { getDictionary, serviceConfig, errorHandler } = useAppContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formState, setFormState] = useState<DrawingForm>({
    drawingNumber: "",
    revision: "",
    title: "",
    documentDate: undefined,
    location: [],
    link: "",
    files: [],
    originator: "",
  });

  const { handleFileChange, handleRemoveFile } =
    useFileHandler<DrawingForm>(setFormState);

  const formSchema = yup.object().shape({
    drawingNumber: yup.string().required("Drawing Number is required"),
    revision: yup.string().required("Revision is required"),
    title: yup.string().required("Title is required"),
    documentDate: yup.date().required("Document Date is required"),
    location: yup.array().required("Location Date is required"),
    link: yup.string(),
    originator: yup.string(),
  });

  const formItems: NxpFormGridItemProps<DrawingForm>[] = [
    {
      controlType: "input",
      span: 12,
      label: "Drawing Number",
      itemFieldName: "drawingNumber",
      required: true,
      controlProps: {
        value: formState?.drawingNumber,
      },
    },
    {
      controlType: "input",
      span: 12,
      label: "Revision",
      itemFieldName: "revision",
      required: true,
      controlProps: {
        value: formState?.revision,
      },
    },
    {
      controlType: "input",
      span: 12,
      label: "Title",
      itemFieldName: "title",
      required: true,
      controlProps: {
        value: formState?.title,
      },
    },
    {
      controlType: "datePicker",
      span: 12,
      label: "Date",
      itemFieldName: "documentDate",
      required: true,
      controlProps: {
        format: getUserDisplayDateFormat(),
        value: formState?.documentDate,
        disabledDate: (current) =>
          current && isBefore(new Date(), current.toDate()),
      },
    },
    {
      controlType: "input",
      span: 12,
      label: "originator",
      itemFieldName: "originator",
      controlProps: {
        value: formState?.originator,
      },
    },
    {
      controlType: "custom",
      label: "locations",
      itemFieldName: "location",
      span: 12,
      customContent: (
        <TreeSelect
          allowClear
          showSearch
          multiple
          style={{ width: "100%" }}
          treeData={convertToTreeSelectOptions(
            getDictionary("LOCATION")?.members
          )}
          value={formState?.location}
          onChange={(value) => {
            handleFormGridStateChange("location", value);
          }}
        ></TreeSelect>
      ),
    },
    {
      controlType: "custom",
      span: 12,
      label: "File",
      itemFieldName: "files",
      customContent: (
        <Upload
          beforeUpload={(file) => {
            handleFileChange(file);
            return false; // Prevent automatic upload
          }}
          onRemove={handleRemoveFile}
          fileList={formState?.files.map((file) => ({
            uid: file.assetId,
            name: file.fileName,
            status: "done",
            url: file.url,
          }))}
        >
          {formState?.files.length < 1 && (
            <Button icon={<UploadOutlined />}>
              {t("voc.common.clickToUpload")}
            </Button>
          )}
        </Upload>
      ),
    },
    {
      controlType: "input",
      span: 12,
      label: "link",
      itemFieldName: "link",
      controlProps: {
        value: formState?.link,
      },
    },
  ];

  const handleSaveValidated = async () => {
    try {
      setIsSubmitting(true);
      const idToUse = siteEventId || eventId;
      const isSiteEvent = Boolean(siteEventId);

      await createSupportDocument(
        idToUse,
        {
          documentFiles: formState?.files,
          details: {
            title: formState?.title,
            documentType: documentType,
            documentDate: formState?.documentDate,
            link: formState?.link === "" ? null : formState?.link,
            drawingNumber: formState?.drawingNumber,
            revision: formState?.revision,
            location: formState?.location,
            originator: formState?.originator,
          },
          documentType: documentType,
        },
        serviceConfig,
        isSiteEvent
      );
      onModalClose();
      onRefresh();
    } catch (error) {
      errorHandler(error, "save document");
    } finally {
      setIsSubmitting(false);
    }
  };
  const [validationError, , , saveWithValidate] = useYupValidate<DrawingForm>(
    formState,
    formSchema,
    handleSaveValidated
  );

  const handleFormGridStateChange = (
    fieldName: keyof DrawingForm,
    value: unknown
  ) => {
    setFormState((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleValidateSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  return (
    <>
      <NxpFormGrid
        formItems={formItems}
        formState={formState}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
      <NxpModalFooter>
        <NxpButton
          disabled={isSubmitting}
          type="default"
          onClick={onModalClose}
        >
          {t("voc.common.cancel")}
        </NxpButton>
        <NxpButton
          disabled={isSubmitting}
          onClick={handleValidateSave}
          type="primary"
        >
          {t("voc.common.save")}
        </NxpButton>
      </NxpModalFooter>
    </>
  );
};

export default NewDrawingForm;
