import React from "react";

import AppButton from "../../../app/components/app-button";
import ImportExcelContainer from "./ImportExcelContainer";
import { useTranslation } from "react-i18next";

interface ImportExcelButtonProps {
  entityId: string;
  onImport: Function;
  disabled?: boolean;
}

const ImportExcelButton: React.FC<ImportExcelButtonProps> = (props) => {
  const { entityId, onImport, disabled } = props;
  const { t } = useTranslation();

  const [showImportExcelModal, setShowImportExcelModal] = React.useState(false);
  const handleModalClose = () => setShowImportExcelModal(false);

  return (
    <>
      <AppButton
        className="voc-admin-library-import-excel"
        onClick={() => setShowImportExcelModal(true)}
        disabled={disabled}
      >
        {t("voc.library.importExcel.importFromExcel")}
      </AppButton>
      {showImportExcelModal && (
        <ImportExcelContainer
          entityId={entityId}
          showImportExcelModal={showImportExcelModal}
          onModalClose={handleModalClose}
          onImport={onImport}
        ></ImportExcelContainer>
      )}
    </>
  );
};

export default ImportExcelButton;
