import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import { NxpDatePicker } from "@nexploretechnology/nxp-ui";

const useStyles = createUseStyles((theme) => ({
  DemoDatePicker: {},
}));

interface DemoDatePickerProps {}

const DemoDatePicker: React.FC<DemoDatePickerProps> = () => {
  const classes = useStyles();

  const [date, setDate] = useState<Date>();

  return (
    <div className={classes.DemoDatePicker}>
      <NxpDatePicker value={date} onChange={setDate} />
    </div>
  );
};

export default DemoDatePicker;
