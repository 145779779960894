import React from "react";

import { DataTableColumn } from "./";
import AppDataTableFooter from "./AppDataTableFooter";
import AppDataTableRow from "./AppDataTableRow";

interface AppDataTableRowListProps<T> {
  columns: DataTableColumn<T>[];
  dataSource?: T[];
  keyColumnName: string;
  fixFirstColumn?: boolean;
  totalRecordCount: number;
  onRowClick?: (e: React.MouseEvent<HTMLDivElement>, dataItem: T) => void;
  onRowKeyGenerate?: (dataItem: T) => string;
  onFooterAggregate?: (
    rowDataItem: T,
    footerDataItem: Partial<T>,
    isLastRow: boolean
  ) => void;
}

function AppDataTableRowList<T>(props: AppDataTableRowListProps<T>) {
  const {
    columns,
    dataSource,
    keyColumnName,
    fixFirstColumn,
    totalRecordCount,
    onRowClick,
    onRowKeyGenerate,
    onFooterAggregate,
  } = props;

  const footerDataItem: Partial<T> = {};

  return (
    <>
      {columns?.length > 0 &&
        dataSource?.map((dataItem, idx) => {
          if (onFooterAggregate) {
            onFooterAggregate(
              dataItem,
              footerDataItem,
              idx === dataSource.length - 1
            );
          }
          return (
            <AppDataTableRow
              key={
                keyColumnName
                  ? String(dataItem[keyColumnName as keyof T])
                  : onRowKeyGenerate && onRowKeyGenerate(dataItem)
              }
              columns={columns}
              dataItem={dataItem}
              fixFirstColumn={fixFirstColumn === true}
              onRowClick={onRowClick}
            />
          );
        })}
      <AppDataTableFooter
        columns={columns}
        dataItem={footerDataItem as T}
        fixFirstColumn={fixFirstColumn}
        totalRecordCount={totalRecordCount}
      />
    </>
  );
}

export default AppDataTableRowList;
