import { TFunction } from "i18next";
import {
  VocCustomUiFieldDataType,
  VocCustomUiQuestionListCustomField,
  VocCustomUiQuestionListPresetField,
} from "../../../app/types";

export const getDefaultAdminPlaceholder = (
  field:
    | VocCustomUiQuestionListPresetField
    | VocCustomUiQuestionListCustomField,
  t: TFunction<"translation", undefined, "translation">
) => {
  if (field.adminPlaceHolder) {
    return field.adminPlaceHolder;
  }

  switch (field.dataType) {
    case VocCustomUiFieldDataType.Date:
      return t("voc.customization.date");
    case VocCustomUiFieldDataType.Dropdown:
      return t("voc.customization.dropDown");
    case VocCustomUiFieldDataType.Percentage:
      return t("voc.customization.percentage");
    case VocCustomUiFieldDataType.Value:
      return t("voc.customization.numeric");
    case VocCustomUiFieldDataType.YesNo:
      return t("voc.customization.yesNo");
    case VocCustomUiFieldDataType.Text:
      return t("voc.customization.text");
    case VocCustomUiFieldDataType.FileUpload:
      return t("voc.customization.fileUpload");
    case VocCustomUiFieldDataType.MultiSelect:
      return t("voc.customization.multiSelection");
    case VocCustomUiFieldDataType.ReadOnly:
      return t("voc.customization.readonly");
    case VocCustomUiFieldDataType.Formula:
      return t("voc.customization.formula");
    default:
      return t("voc.customization.customInput");
  }
};
