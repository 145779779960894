import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";

import {
  DateFormatPattern,
  formatDate,
  notify,
} from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../app/hooks/useAppContext";
import { useAsync } from "../../../app/hooks/useAsync";
import { VocSiteEvent, VocSiteEventPatchRequest } from "../../../app/types";
import { BACK_HREF_BY_JS } from "../../../app/utils/const";
import {
  getSiteEvent,
  Recovery,
  updateSiteEvent,
} from "../../site-event-services";
import SiteEventDetailsLayout from "./SiteEventDetailsLayout";
import { stringToDateFormat } from "../../../app/utils/mappers";

interface SiteEventDetailsContainerProps {}

const SiteEventDetailsContainer: React.FC<
  SiteEventDetailsContainerProps
> = () => {
  const { entityId, siteEventId } = useParams();
  const { serviceConfig, errorHandler } = useAppContext();

  const [siteEventData, setSiteEventData] = React.useState<VocSiteEvent>(null);

  const [nextSiteEvent, setNextSiteEvent] = React.useState<string>(null);
  const [prevSiteEvent, setPrevSiteEvent] = React.useState<string>(null);

  const siteEventStringToDateFormat = useCallback(
    (recentEvent: VocSiteEvent) => ({
      ...recentEvent,
      dateOfAwareness:
        recentEvent.dateOfAwareness &&
        stringToDateFormat(recentEvent.dateOfAwareness),
      dateOfCessation:
        recentEvent.dateOfCessation &&
        stringToDateFormat(recentEvent.dateOfCessation),
    }),
    []
  );
  const [, setGetSiteEventRequest] = useAsync<VocSiteEvent>(
    () =>
      getSiteEvent(siteEventId, serviceConfig).then(
        siteEventStringToDateFormat
      ),
    {
      onSuccess: (data) => {
        if (nextSiteEvent !== data.next) {
          setNextSiteEvent(data.next);
        }
        if (prevSiteEvent !== data.last) {
          setPrevSiteEvent(data.last);
        }
        setSiteEventData(data);
      },
      onError(ex) {
        errorHandler(ex, "fetching event detail error");
      },
    }
  );
  const [, setUpdateSiteEventRecovery] = useAsync<Recovery>(null, {
    onSuccess(data) {
      notify.success("Site Event Recovery update success");
    },
    onError(ex) {
      errorHandler(ex, "Site Event Recovery update error");
    },
  });

  const [form, setForm] = React.useState<VocSiteEventPatchRequest>(null);

  const handleUpdateSiteEvent = React.useCallback(() => {
    //hooking into setGetValuesRequest will trigger a 2nd get, is this necessary?
    setGetSiteEventRequest(() =>
      updateSiteEvent(siteEventId, form, serviceConfig).then(
        siteEventStringToDateFormat
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  React.useEffect(() => {
    if (form) {
      if (form.dateOfAwareness) {
        form.dateOfAwareness = formatDate(
          form.dateOfAwareness,
          DateFormatPattern.date
        ) as any;
      }
      if (form.dateOfCessation) {
        form.dateOfCessation = formatDate(
          form.dateOfCessation,
          DateFormatPattern.date
        ) as any;
      }
      handleUpdateSiteEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const preAndNextEventHandler = async (eventId: string) =>
    await getSiteEvent(eventId, serviceConfig).then((data) => {
      if (nextSiteEvent !== data.next) {
        setNextSiteEvent(data.next);
      }
      if (prevSiteEvent !== data.last) {
        setPrevSiteEvent(data.last);
      }
      setSiteEventData(data);
    });

  const handleEventRefresh = () => {
    setGetSiteEventRequest(() =>
      getSiteEvent(siteEventId, serviceConfig).then(siteEventStringToDateFormat)
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return siteEventData ? (
    <SiteEventDetailsLayout
      key={siteEventId}
      backHref={
        document.referrer === `./site-events`
          ? BACK_HREF_BY_JS
          : `./../../site-events`
      }
      entityId={entityId}
      siteEventId={siteEventId}
      siteEventData={siteEventData}
      setForm={setForm}
      handleEventRefresh={handleEventRefresh}
      nextSiteEvent={nextSiteEvent}
      prevSiteEvent={prevSiteEvent}
      preAndNextEventHandler={preAndNextEventHandler}
      setUpdateSiteEventRecovery={setUpdateSiteEventRecovery}
    />
  ) : null;
};

export default SiteEventDetailsContainer;
