// validationSchemas.ts
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

export const useGetGeneralSupportingFormSchema = () => {
  const { t } = useTranslation();
  const formSchema = useMemo(() => {
    return yup.object().shape({
      fromDate: yup
        .date()
        .required(t("voc.eventPage.newDocument.fromRequired")),
      toDate: yup
        .date()
        .required(t("voc.eventPage.newDocument.toRequired"))
        .min(
          yup.ref("fromDate"),
          t("ToDate must be greater than or equal to FromDate")
        ),
      subject: yup
        .string()
        .required(t("voc.eventPage.newDocument.subjectRequired")),
      comment: yup.string(),
      link: yup.string().url(t("Enter a valid URL")).nullable(),
    });
  }, [t]);

  return formSchema;
};
