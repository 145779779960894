import { VocGetValuesToDate } from "../../../../app/types";

export const computeCumulativeAppliedNominal = (
  state: VocGetValuesToDate,
  internalTarget: number
) => {
  const { cumulativeApplied } = state;
  //W = E * V
  if (cumulativeApplied || internalTarget) {
    return parseFloat(
      ((internalTarget ?? 0) * (cumulativeApplied ?? 0)).toFixed(2)
    );
  }
};

export const computeCumulativeApplied = (
  state: VocGetValuesToDate,
  internalTarget: number
) => {
  //V = W / E
  const { cumulativeAppliedNominal } = state;

  if (cumulativeAppliedNominal || internalTarget) {
    return parseFloat(
      ((cumulativeAppliedNominal ?? 0) / (internalTarget ?? 0)).toFixed(2)
    );
  }
};

export const computeCumulativeValueVariance = (state: VocGetValuesToDate) => {
  const { cumulativeAppliedNominal, cumulativeValueCertified } = state;
  //Y = W - X
  if (cumulativeAppliedNominal || cumulativeValueCertified) {
    return (cumulativeAppliedNominal ?? 0) - (cumulativeValueCertified ?? 0);
  }
};
