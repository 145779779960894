import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  NxpButton,
  NxpHeader,
  NxpMainSplitter,
  NxpSpin,
} from "@nexploretechnology/nxp-ui";
import { Tree } from "antd";
import { DataNode } from "antd/lib/tree";
import AccessRightPanel from "./AccessRightPanel";
import {
  ApplicationPermissionSetData,
  EntityRole,
  EntityRoleAccessRight,
} from "../../access-right-services";
import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";

interface AccessRightPageLayoutProps {
  selectedRole?: EntityRole;
  entityRolesTree: DataNode[];
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  userRights: EntityRoleAccessRight[];
  applicationPermissionSetData: ApplicationPermissionSetData[];
  onRoleSelect: (key: string) => void;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = createUseStyles((theme) => ({
  action: {
    display: "flex",
  },
  main: {
    margin: theme.spacing(2),
  },
  treePanel: {
    margin: theme.spacing(4, 2),
  },
}));

const AccessRightPageLayout: React.FC<AccessRightPageLayoutProps> = ({
  selectedRole,
  entityRolesTree,
  isSubmitting,
  setIsSubmitting,
  userRights,
  applicationPermissionSetData,
  onRoleSelect,
  setRefresh,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [editing, setEditing] = useState<boolean>(false);

  const handleRoleSelect = (selectedKeys: React.Key[]) => {
    onRoleSelect(selectedKeys[0] as string);
  };

  return (
    <>
      <NxpSpin spinning={!selectedRole}>
        <NxpMainSplitter>
          <div className={classes.treePanel}>
            {selectedRole && (
              <Tree
                selectedKeys={selectedRole ? [selectedRole.code] : undefined}
                defaultExpandAll={true}
                treeData={entityRolesTree}
                onSelect={handleRoleSelect}
              />
            )}
          </div>
          <div className={classes.main}>
            <NxpHeader
              titleContent={t("app.layout.Access")}
              actionContent={
                <div className={classes.action}>
                  {/* {!editing && hasRight("@access/permission:assign") && ( */}
                  {!editing && (
                    <AccessVisible
                      objectCode="access"
                      actionType="edit"
                      fallback={
                        <i>{t("voc.AccessRight.permission.editDenied")}</i>
                      }
                    >
                      <NxpButton onClick={() => setEditing(true)}>
                        {t("voc.common.edit")}
                      </NxpButton>
                    </AccessVisible>
                  )}
                </div>
              }
            />

            <AccessRightPanel
              selectedRole={selectedRole}
              editing={editing}
              isSubmitting={isSubmitting}
              userRights={userRights}
              applicationPermissionSetData={applicationPermissionSetData}
              setEditing={setEditing}
              setRefresh={setRefresh}
              setIsSubmitting={setIsSubmitting}
            />
          </div>
        </NxpMainSplitter>
      </NxpSpin>
    </>
  );
};

export default AccessRightPageLayout;
