import React from "react";
import { useTranslation } from "react-i18next";

import { ITag } from "office-ui-fabric-react";

import useAppContext from "../../hooks/useAppContext";
import { VocEventFile } from "../../types";

import { Right } from "../../services/app";
import {
  defaultSortedColumn,
  defaultTranslateLimit,
  handleFieldChangeHelper,
  handleSortHelper,
} from "./helper";
import {
  AppDataListColumn,
  DataListItem,
  SortDirection,
  SortedColumn,
} from "./types";
import AppDataListLayout from "./AppDataListLayout";
import { getUserDisplayName } from "../../utils/helper/get-user-display-name";

interface AppDataListProps {
  columns: AppDataListColumn[];
  items: DataListItem[];
  editing: boolean;
  editSetStateHook: any;
  setFiles?: React.Dispatch<React.SetStateAction<VocEventFile[][]>>; // either this
  handleCustomSetFiles?: Function; // or this
  canDeleteLastItem: boolean;
  entityId?: string; //only necessary for pulling certain dropdown options
  extenderCssClass?: string;
  translates?: number;
  totalUp?: boolean;
  labelIfEmpty?: string;
  //values
  validateNumbersOnly?: boolean;
  valuesSubrecordsCalculations?: boolean;
  //library
  saveCustomOrderCallback?: Function;
  resetCustomOrderCallback?: Function;
  saveCustomOrderCode?: string;
  initCustomSortState?: boolean;
  isVarianceLibraryFlag?: boolean;
  //logs
  undoCallback?: Function;
  isDisableSort?: boolean;
  defaultSortColumn?: number;
  defaultSortDirection?: SortDirection;
  autoheight?: boolean;
  deleteRecordDoc?: (doc_id: DataListItem["id"]) => Promise<any>;
  //permission
  permissionCode?: Right["objectCode"];
  disableFutureDay?: boolean;
}

interface DragState {
  drag: number;
  item: number;
}

const AppDataList: React.FC<AppDataListProps> = (props) => {
  const {
    entityId,
    columns,
    items,
    editing,
    editSetStateHook,
    setFiles, // either this
    handleCustomSetFiles, // or this
    canDeleteLastItem,
    extenderCssClass,
    translates,
    totalUp,
    labelIfEmpty, // added for VC-298
    validateNumbersOnly,
    valuesSubrecordsCalculations,
    saveCustomOrderCallback,
    resetCustomOrderCallback,
    saveCustomOrderCode,
    initCustomSortState,
    undoCallback,
    isDisableSort,
    defaultSortColumn,
    defaultSortDirection,
    autoheight,
    isVarianceLibraryFlag,
    deleteRecordDoc = undefined,
    permissionCode,
    disableFutureDay = false,
  } = props;
  const { t } = useTranslation();
  const { entityUsers } = useAppContext();
  const [translate, setTranslate] = React.useState<string>("");
  const translateLimit = React.useMemo(
    () => defaultTranslateLimit(translates),
    [translates]
  );

  const [sortedItems, setSortedItems] = React.useState<DataListItem[]>(null);
  const [sortedColumn, setSortedColumn] = React.useState<SortedColumn>(
    defaultSortedColumn(isDisableSort, defaultSortColumn, defaultSortDirection)
  );
  const [customSort, setCustomSort] =
    React.useState<boolean>(initCustomSortState);

  const [dragging, setDragging] = React.useState<DragState>({
    drag: 0,
    item: -1,
  });
  const [sortMarker, setSortMarker] = React.useState<number>(null);
  const [sortDirectionIsUp, setSortDirectionIsUp] =
    React.useState<boolean>(null);

  const [usersDropdownOptions, setUsersDropdownOptions] =
    React.useState<ITag[]>(null);

  const filterEntityUserTags = (
    filterText: string,
    tagList: ITag[]
  ): ITag[] => {
    if (!usersDropdownOptions) {
      return null;
    }
    return filterText
      ? usersDropdownOptions.filter((tag: ITag) => {
          return (
            !tagList.find((selected) => selected.key === tag.key) &&
            tag.name.toLowerCase().includes(filterText.toLowerCase())
          );
        })
      : [];
  };

  React.useEffect(() => {
    if (
      entityId &&
      columns.filter((c) => c.columnDataType === "owner").length > 0
    ) {
      setUsersDropdownOptions(
        entityUsers.map((user) => ({
          name: getUserDisplayName(user, false, true),
          key: user.id,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (items) {
      setSortedItems(null);
    }
  }, [items]);

  const handleFilesChange = React.useCallback(
    (file: any, fileRef: any) => {
      if (Boolean(handleCustomSetFiles)) {
        handleCustomSetFiles(file, fileRef);
      } else if (setFiles) {
        setFiles((prevState: any) => {
          const newFiles = [...prevState];
          newFiles[fileRef] = file;
          return newFiles;
        });
      } else {
        console.log("--- ERROR: no set files funcs");
      }
    },
    [handleCustomSetFiles, setFiles]
  );

  function handleFieldChange(
    itemIndex: number,
    fieldIndex: number,
    newValue: any
  ) {
    const newItems = [...items];
    handleFieldChangeHelper(
      itemIndex,
      fieldIndex,
      newValue,
      newItems,
      isVarianceLibraryFlag,
      validateNumbersOnly,
      valuesSubrecordsCalculations,
      t
    );
    editSetStateHook(newItems);
  }

  const handleSort = React.useCallback(() => {
    if (!isDisableSort) {
      const sorted: DataListItem[] = handleSortHelper(
        items,
        columns,
        sortedColumn
      );
      setSortedItems(sorted);
    }
  }, [columns, isDisableSort, items, sortedColumn]);

  function handleCustomSort() {
    const sorted: DataListItem[] = [...items].sort((a, b) =>
      a.sortOrder > b.sortOrder ? 1 : a.sortOrder < b.sortOrder ? -1 : 0
    );
    setSortedItems(sorted);
  }

  function triggerSort(columnIndex: number) {
    if (!isDisableSort) {
      if (columnIndex === sortedColumn.columnIndex) {
        setSortedColumn((prevState) => {
          return {
            columnIndex: columnIndex,
            // isAsc: !prevState.isAsc,
            sortDirection: prevState.sortDirection === "asc" ? "desc" : "asc",
          };
        });
      } else {
        setSortedColumn({
          columnIndex: columnIndex,
          // isAsc: true,
          sortDirection: "asc",
        });
      }
    } else {
      console.log("---sort is disabled");
    }
  }

  // call custom sort again when items changes
  React.useEffect(() => {
    if (customSort) {
      handleCustomSort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  React.useEffect(() => {
    if (customSort) {
      handleCustomSort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!customSort) {
      handleSort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumn, customSort]);

  function deleteItem(itemIndex: number, deleteRec: DataListItem) {
    const newItems = [...items];
    newItems.splice(itemIndex, 1);
    if (deleteRecordDoc) {
      deleteRecordDoc(deleteRec?.id).then((data: any) => {
        editSetStateHook(newItems);
      });
    } else {
      editSetStateHook(newItems);
    }
  }

  function clearItem(itemIndex: number) {
    columns.forEach((column, i) => {
      if (column.editable) {
        if (column.columnDataType !== "file") {
          handleFieldChange(itemIndex, i, null);
        } else {
          handleFieldChange(itemIndex, i, []);
        }
      }
    });
  }

  items.forEach((item: DataListItem, index: number) => {
    item.immutableIndex = index;
  });

  return (
    <AppDataListLayout
      // general
      labelIfEmpty={labelIfEmpty}
      extenderCssClass={extenderCssClass}
      totalUp={totalUp}
      autoheight={autoheight}
      //permission
      permissionCode={permissionCode}
      // translate
      translate={translate}
      setTranslate={setTranslate}
      translateLimit={translateLimit}
      translates={translates}
      // table
      columns={columns}
      items={items}
      sortedItems={sortedItems}
      setItemCallbackOnClickSort={setSortedItems}
      // table flag/funcs
      editing={editing}
      canDeleteLastItem={canDeleteLastItem}
      undoCallback={undoCallback}
      // sorting
      isDisableSort={isDisableSort}
      customSort={customSort}
      setCustomSort={setCustomSort}
      sortedColumn={sortedColumn}
      setSortedColumn={setSortedColumn}
      sortMarker={sortMarker}
      setSortMarker={setSortMarker}
      sortDirectionIsUp={sortDirectionIsUp}
      setSortDirectionIsUp={setSortDirectionIsUp}
      dragging={dragging}
      setDragging={setDragging}
      saveCustomOrderCallback={saveCustomOrderCallback}
      resetCustomOrderCallback={resetCustomOrderCallback}
      saveCustomOrderCode={saveCustomOrderCode}
      // container func
      triggerSort={triggerSort}
      handleFieldChange={handleFieldChange}
      handleFilesChange={handleFilesChange}
      clearItem={clearItem}
      deleteItem={deleteItem}
      disableFutureDay={disableFutureDay}
      filterEntityUserTags={filterEntityUserTags}
    />
  );
};

export default AppDataList;
