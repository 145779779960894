import { Route } from "react-router-dom";

import AppProtectedPage from "../app/components/app-router/AppProtectedPage";
import CustomizationContainer from "./pages/customization-home/CustomizationContainer";

export const customizationRoutes = [
  <Route
    key="customization-section"
    path="/entities/:entityId/admin/customization/:activeSection"
    element={
      <AppProtectedPage
        component={<CustomizationContainer />}
        accessRight={{ objectCode: "customizations", actionType: "view" }}
      />
    }
  />,
  <Route
    key="customization"
    path="/entities/:entityId/admin/customization"
    element={
      <AppProtectedPage
        component={<CustomizationContainer />}
        accessRight={{ objectCode: "customizations", actionType: "view" }}
      />
    }
  />,
];
