import { DeleteOutlined, LockFilled } from "@ant-design/icons";
import {
  formatDate,
  notify,
  NxpButton,
  NxpButtonDeleteConfirm,
  NxpDatePicker,
  NxpPanel,
  NxpTable,
  NxpTableColumnProps,
  NxpTooltip,
  sorterForDate,
  sorterForNumber,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Input, InputNumber } from "antd";
import { isAfter, isBefore } from "date-fns";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import { convertNumber } from "../../../../../app/components/app-numeric-input/utils/convert-value-helper";
import useAppContext from "../../../../../app/hooks/useAppContext";
import i18n from "../../../../../app/i18n/i18n";
import {
  VocSupportDocument,
  VocSupportDocumentEnums,
} from "../../../../../app/types";
import { getUserDisplayDateFormat } from "../../../../../app/utils/mappers";
import { formatNumberByLocale } from "../../../../../app/utils/number/formatNumberByLocale";
import { parseNumberByLocale } from "../../../../../app/utils/number/parseNumberByLocale";
import {
  deleteSupportDocumentsById,
  editEventSupportDocuments,
  editSiteEventSupportDocuments,
} from "../document-form-services";
import { isRowDisplayOnly } from "../helpers/supporting-helper";

interface SciPanelProps {
  scis: VocSupportDocument[];
  onRefresh: () => void;
  onSetIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

const useStyles = createUseStyles({
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
});

const SciPanel: React.FC<SciPanelProps> = ({
  scis,
  onRefresh,
  onSetIsLoading,
  isLoading,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const { errorHandler, serviceConfig } = useAppContext();

  const { eventId, siteEventId } = useParams();
  const [formState, setFormState] = useState(scis);

  const handleClickEdit = () => {
    setFormState([...scis]);
    setEditing(true);
  };

  const handleClickCancel = () => {
    setEditing(false);
    setFormState([]);
  };

  const deleteDocument = useCallback(
    async (record: VocSupportDocument) => {
      const idToUse = siteEventId || eventId;
      const eventType = siteEventId ? "site_events" : "events";
      await deleteSupportDocumentsById(
        idToUse,
        record.id,
        eventType,
        serviceConfig
      );
      setEditing(false);
      setFormState([]);
      notify.actionCompleted();
      onRefresh();
    },
    [eventId, onRefresh, serviceConfig, siteEventId]
  );

  const handleSave = async () => {
    if (!formState) return;

    onSetIsLoading(true);
    const updatedSciDocs = formState.map((item) => ({
      comment: item.comment,
      details: { ...item.details },
      documentFiles: item.documentFiles.map((file) => ({
        assetId: file.assetId,
        fileName: file.fileName,
        mimeType: file.mimeType,
        url: file.url,
      })),
      documentType: item.documentType,
      id: item.id,
    }));

    const isSiteEvent = Boolean(siteEventId);
    try {
      if (isSiteEvent) {
        await editSiteEventSupportDocuments(siteEventId, serviceConfig, {
          documentType: VocSupportDocumentEnums.Subcontractor_SCI,
          documents: updatedSciDocs,
        });
      } else {
        await editEventSupportDocuments(eventId, serviceConfig, {
          documentType: VocSupportDocumentEnums.Subcontractor_SCI,
          documents: updatedSciDocs,
        });
      }
      setEditing(false);
      setFormState([]);
      notify.actionCompleted();
      onRefresh();
    } catch (error) {
      errorHandler(error, "save Sci documents");
    } finally {
      onSetIsLoading(false);
    }
  };

  const handleInputChange = useCallback(
    (id: string | number, field: string, value: unknown) => {
      const newRecords = formState.map((item) => {
        if (item.id === id) {
          if (field in item.details) {
            return {
              ...item,
              details: {
                ...item.details,
                [field]: value,
              },
            };
          } else {
            return {
              ...item,
              [field]: value,
            };
          }
        }
        return item;
      });
      setFormState(newRecords);
    },
    [formState]
  );

  const columns: NxpTableColumnProps<VocSupportDocument>[] = useMemo(
    () => [
      {
        title: t("voc.eventPage.supporting.sciRef"),
        dataIndex: ["details", "reference"],
        key: "reference",
        sorter: (a, b) =>
          sorterForString(a.details.reference, b.details.reference),
        render: (text, record) =>
          editing && !isRowDisplayOnly(record, eventId) ? (
            <Input
              value={record.details.reference}
              onChange={(e) =>
                handleInputChange(record.id, "reference", e.target.value)
              }
            />
          ) : (
            text
          ),
      },
      {
        title: t("voc.eventPage.supporting.subcontractor"),
        dataIndex: ["details", "subcontractor"],
        key: "subcontractor",
        sorter: (a, b) =>
          sorterForString(a.details.subcontractor, b.details.subcontractor),
        render: (text, record) =>
          editing && !isRowDisplayOnly(record, eventId) ? (
            <Input
              value={record.details.subcontractor}
              onChange={(e) =>
                handleInputChange(record.id, "subcontractor", e.target.value)
              }
            />
          ) : (
            text
          ),
      },
      {
        title: t("voc.eventPage.summary.subject"),
        dataIndex: ["details", "subject"],
        key: "subject",
        sorter: (a, b) => sorterForString(a.details.subject, b.details.subject),
        render: (text, record) =>
          editing && !isRowDisplayOnly(record, eventId) ? (
            <Input
              value={record.details.subject}
              onChange={(e) =>
                handleInputChange(record.id, "subject", e.target.value)
              }
            />
          ) : (
            text
          ),
      },
      {
        title: t("voc.eventPage.supporting.dateFrom"),
        dataIndex: ["details", "fromDate"],
        key: "fromDate",
        width: "10%",
        sorter: (a, b) => sorterForDate(a.details.fromDate, b.details.fromDate),
        render: (date, record) =>
          editing && !isRowDisplayOnly(record, eventId) ? (
            <NxpDatePicker
              format={getUserDisplayDateFormat()}
              value={record.details.fromDate}
              disabledDate={(current) =>
                (current &&
                  (isBefore(new Date(), current.toDate()) ||
                    isAfter(current.toDate(), new Date()))) ||
                isAfter(current.toDate(), record.details.toDate)
              }
              onChange={(value) => {
                handleInputChange(record.id, "fromDate", value);
              }}
            />
          ) : (
            formatDate(new Date(date), getUserDisplayDateFormat())
          ),
      },
      {
        title: t("voc.eventPage.supporting.dateTo"),
        dataIndex: ["details", "toDate"],
        key: "toDate",
        width: "10%",
        sorter: (a, b) => sorterForDate(a.details.toDate, b.details.toDate),
        render: (date, record) =>
          editing && !isRowDisplayOnly(record, eventId) ? (
            <NxpDatePicker
              format={getUserDisplayDateFormat()}
              value={record.details.toDate}
              disabledDate={(current) =>
                current &&
                (isBefore(current.toDate(), record.details.fromDate) ||
                  isAfter(current.toDate(), new Date()))
              }
              onChange={(value) =>
                handleInputChange(record.id, "toDate", value)
              }
            />
          ) : (
            formatDate(new Date(date), getUserDisplayDateFormat())
          ),
      },
      {
        title: t("voc.common.amount"),
        dataIndex: ["details", "amount"],
        key: "amount",
        sorter: (a, b) =>
          sorterForNumber(Number(a.details.amount), Number(b.details.amount)),
        render: (amount, record) =>
          editing && !isRowDisplayOnly(record, eventId) ? (
            <InputNumber
              value={amount}
              onChange={(value) =>
                handleInputChange(record.id, "amount", value)
              }
              style={{ width: "100%" }}
              formatter={(value?: unknown) =>
                formatNumberByLocale(Number(value ?? 0), i18n.language)
              }
              parser={(displayValue?: string) =>
                parseNumberByLocale(displayValue, i18n.language)
              }
            />
          ) : (
            convertNumber(amount)
          ),
      },
    ],
    [t, editing, handleInputChange, eventId]
  );

  if (editing) {
    columns.unshift({
      title: t("voc.appDataListComponent.delete"),
      key: "delete",
      width: "3%",
      render: (text, record) => {
        if (isRowDisplayOnly(record, eventId)) {
          return (
            <NxpTooltip
              title={`${
                t("voc.eventPage.supporting.siteDocStatus.editInSiteEvent") +
                record.siteEventSerial
              }`}
            >
              <LockFilled />
            </NxpTooltip>
          );
        }
        return (
          <NxpButtonDeleteConfirm
            type="link"
            danger
            icon={<DeleteOutlined />}
            onConfirm={() => deleteDocument(record)}
          />
        );
      },
    });
  }

  return (
    <NxpPanel
      titleContent={`${t("voc.eventPage.supporting.subcontractsSCI")} (${
        scis?.length
      })`}
      defaultCollapsed
    >
      <div className={classes.buttonsContainer}>
        {!editing && (
          <NxpButton onClick={handleClickEdit}>
            {t("voc.common.edit")}
          </NxpButton>
        )}
        {editing && (
          <>
            <NxpButton type="default" onClick={handleClickCancel}>
              {t("voc.common.cancel")}
            </NxpButton>
            <NxpButton onClick={handleSave}>{t("voc.common.save")}</NxpButton>
          </>
        )}
      </div>
      <NxpTable
        loading={isLoading}
        columns={columns}
        dataSource={editing ? formState : scis}
        rowKey="id"
      />
    </NxpPanel>
  );
};

export default SciPanel;
