import { apiRequest, ServiceConfig } from "../utils/backend/api-request";
import CustomApiError, {
  CustomApiErrorServerResponseData,
} from "../utils/backend/custom-api-error";
import { CPCSUser } from "./app";

export interface FileData {
  blob: string;
  createdAt: string;
  entityId: string;
  id: string;
  imageHeight: number;
  imageWidth: number;
  name: string;
  originalBlob: string;
  originalUrl: string;
  size: number;
  thumbnailBlob: string;
  thumbnailUrl: string;
  type: string;
  url: string;
  createdOn: Date;
  deletedOn: Date;
  updatedOn: Date;
  uploadedBy: CPCSUser;
}

export interface PresignResult {
  assetId: string;
  url: string;
  method: string;
  headers: any[];
}

export interface PresignRequest {
  filename: string;
  contentLength: string;
  contentType: string;
}

export interface UploadResult {
  assetId: string;
  url: string;
}

export const getMimeTypeByFileName = (filename: string) => {
  const ext = filename.split(".").pop();
  switch (ext) {
    case "msg":
      return "application/vnd.ms-outlook";
  }
};

export const upload = async (
  file: File,
  serviceConfig: ServiceConfig,
  customUploadPath?: string
) => {
  const formData = new FormData();
  if (file.name.endsWith(".msg")) {
    const mailFile = new File([file], file.name, {
      type: getMimeTypeByFileName(file.name),
    });
    formData.append("file", mailFile);
  } else {
    formData.append("file", file);
  }
  const response = await fetch(
    `${serviceConfig.apiBaseUrl}/entities/${serviceConfig.entityId}${
      customUploadPath ? customUploadPath : "/file/upload"
    }`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${serviceConfig.token}` || "",
      },
      body: formData,
    }
  );
  if (!response.ok) {
    try {
      throw Error(
        `File upload error - ${JSON.stringify(await response.json())}`
      );
    } catch {
      // nothing to do
      throw Error("File upload error.");
    }
  } else {
    const respText = await response.text();
    const result = JSON.parse(respText);
    return result as unknown as UploadResult;
  }
};

export const uploadAvatar = async (
  file: File,
  serviceConfig: ServiceConfig
): Promise<PresignResult> => {
  const formData = new FormData();
  formData.append("file", file);

  const uploadData = {
    headers: {
      Authorization: `Bearer ${serviceConfig.token}` || "",
    },
    method: "POST",
    body: formData,
  };

  const apiPath = `${serviceConfig.entityServiceApiBaseUrl}/file/avatar-upload`;

  const response = await fetch(apiPath, {
    ...uploadData,
  });

  if (!response.ok) {
    let errorJson: CustomApiErrorServerResponseData | undefined = undefined;
    try {
      errorJson = await response.json();
    } catch {
      // nothing to do
    }
    throw new CustomApiError(
      apiPath,
      uploadData,
      response.status,
      response.statusText,
      errorJson,
      undefined,
      0,
      undefined,
      true // stop retry for defined api server error
    );
  } else {
    const respText = await response.text();
    const result = JSON.parse(respText);
    return {
      ...result,
    } as unknown as PresignResult;
  }
};

/**
 * @deprecated use `uploadFile` instead
 */
export const sendFile = async (presignResult: PresignResult, file: File) => {
  const uploadResponse = await fetch(presignResult.url, {
    method: presignResult.method,
    headers: {
      ...presignResult.headers.reduce((headers, current) => {
        return { ...headers, [current.name]: current.value };
      }),
    },
    body: file,
  });
  if (!uploadResponse.ok) throw new Error("Upload Failed");
  return {
    blob: file,
    // createdAt: string;
    // entityId: string;
    id: presignResult.assetId,
    // imageHeight: number;
    // imageWidth: number;
    name: file.name,
    originalBlob: file,
    originalUrl: presignResult.url,
    // size: number;
    thumbnailBlob: file,
    thumbnailUrl: presignResult.url,
    type: file.type,
    url: presignResult.url,
    // createdOn: Date;
    // deletedOn: Date;
    // updatedOn: Date;
    // uploadedBy: CPCSUser;
  } as unknown as FileData;
};

export interface SignResponse {
  assets: { assetName: string; url: string }[];
}

export const sign = async (
  options: { assetNames: string[] },
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<SignResponse>(`/sign`, serviceConfig, {
    body: JSON.stringify({
      assets: options.assetNames.map((name) => ({ asset_name: name })),
    }),
  });
};

export const getFile = async (
  projectId: string,
  fileId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<File>(
    `/projects/${projectId}/files/${fileId}`,
    serviceConfig
  );
};
