import * as React from "react";

import { Button, IconButton } from "office-ui-fabric-react";

import "./AppPagination.scss";
import { useTranslation } from "react-i18next";

interface NumericButtonsGroupProps {
  pageNumber: number;
  totalPage: number;
  onPageNumberChange: (pageNumber: number) => void;
}

const NumericButtonsGroup_: React.FC<NumericButtonsGroupProps> = ({
  pageNumber,
  totalPage,
  onPageNumberChange,
}) => {
  const { t } = useTranslation();
  if (totalPage > 0) {
    let itemShift = pageNumber - 4; // case when there is a More icon button on both side
    if (pageNumber < 6 || totalPage <= 9) {
      itemShift = 1; // case when there is a More icon button on right only or no More icon button at all
    } else if (totalPage - pageNumber < 5) {
      itemShift = totalPage - 8; // case when there is a More icon button on left only
    }
    return (
      <>
        {Array(totalPage < 9 ? totalPage : 9)
          .fill(0)
          .map((_, i) => {
            const itemPage = i + itemShift;
            return i === 0 && pageNumber > 5 && totalPage > 9 ? ( // add the left More icon button
              <React.Fragment key={i}>
                <div className="page-control-sep"></div>
                <IconButton
                  className="page-control-prev"
                  iconProps={{ iconName: "More" }}
                  title={t("voc.common.previousPage")}
                  ariaLabel="Previous"
                  onClick={() =>
                    onPageNumberChange(itemPage - 3 >= 1 ? itemPage - 3 : 1)
                  }
                />
              </React.Fragment>
            ) : i === 8 && totalPage - pageNumber > 4 && totalPage > 9 ? ( // add the right More icon button
              <React.Fragment key={i}>
                <div className="page-control-sep"></div>
                <IconButton
                  className="page-control-more"
                  iconProps={{ iconName: "More" }}
                  title={t("voc.common.nextPage")}
                  ariaLabel="Next"
                  onClick={() =>
                    onPageNumberChange(
                      itemPage + 3 <= totalPage ? itemPage + 3 : totalPage
                    )
                  }
                />
              </React.Fragment>
            ) : (
              // add the number buttons
              <React.Fragment key={i}>
                {(i === 0 || (i === 1 && pageNumber > 5 && totalPage > 9)) && ( // add seperator to the first number button
                  <div className="page-control-sep"></div>
                )}
                <Button
                  className={
                    "page-control-num" +
                    (itemPage === pageNumber ? " active" : "")
                  }
                  title={`Page ${itemPage}`}
                  ariaLabel="Previous"
                  key={itemPage}
                  onClick={() => onPageNumberChange(itemPage)}
                >
                  {itemPage}
                </Button>
              </React.Fragment>
            );
          })}
      </>
    );
  }
  return null;
};

export const NumericButtonsGroup = React.memo(NumericButtonsGroup_);
