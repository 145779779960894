import React from "react";
import { useTranslation } from "react-i18next";

import { Dashboard } from "../../../app/types";
import DashboardChartContainer from "./chart/DashboardChartContainer";

import i18n from "../../../app/i18n/i18n";
import "./DashboardLayout.scss";
import { formatNumberByLocale } from "../../../app/utils/number/formatNumberByLocale";

interface DashboardLayoutProps {
  dashboardData: Dashboard;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ dashboardData }) => {
  const { t } = useTranslation();

  const formatNumber = (value: number) => {
    return formatNumberByLocale(value, i18n.language);
  };

  const convertToNumber = (value) => {
    if (value && !Number.isNaN(value)) {
      return Number(value);
    }
    return 0;
  };

  return (
    <div className="dashboard-container">
      <h1 className="title">{t("voc.common.dashboard")}</h1>
      <div className="dashboard-top">
        <div className="siteevent count">
          <div className="row total">
            <span className="label">
              <strong>{t("voc.reports.dashboard.numSiteEvents")}</strong>
            </span>
            <span className="value">
              {formatNumber(
                convertToNumber(
                  dashboardData.events.recoverable.unrecoverable
                ) +
                  convertToNumber(
                    dashboardData.events.recoverable.recoverable
                  ) +
                  convertToNumber(dashboardData.events.recoverable.undecided)
              )}
            </span>
          </div>
          <div className="row">
            <span className="label">
              {t("voc.reports.dashboard.nonRecoverable")}
            </span>
            <span className="value">
              {formatNumber(
                convertToNumber(dashboardData.events.recoverable.unrecoverable)
              )}
            </span>
          </div>
          <div className="row">
            <span className="label">
              {t("voc.reports.dashboard.recoverable")}
            </span>
            <span className="value">
              {formatNumber(
                convertToNumber(dashboardData.events.recoverable.recoverable)
              )}
            </span>
          </div>
          <div className="row">
            <span className="label">
              {t("voc.reports.dashboard.toBeDecided")}
            </span>
            <span className="value">
              {formatNumber(
                convertToNumber(dashboardData.events.recoverable.undecided)
              )}
            </span>
          </div>
        </div>
        <div className="compevent count">
          <div className="row total">
            <span className="label">
              <strong>{t("voc.reports.dashboard.numCompEvents")}</strong>
            </span>
            <span className="value">
              {formatNumber(
                convertToNumber(dashboardData?.compensationEvents?.types?.vo) +
                  convertToNumber(
                    dashboardData?.compensationEvents?.types?.claim
                  ) +
                  convertToNumber(
                    dashboardData?.compensationEvents?.types?.voAndClaim
                  )
              )}
            </span>
          </div>
          <div className="row">
            <span className="label">{t("voc.reports.dashboard.vo")}</span>
            <span className="value">
              {formatNumber(
                convertToNumber(dashboardData.compensationEvents?.types?.vo)
              )}
            </span>
          </div>
          <div className="row">
            <span className="label">{t("voc.reports.dashboard.claims")}</span>
            <span className="value">
              {formatNumber(
                convertToNumber(dashboardData.compensationEvents?.types?.claim)
              )}
            </span>
          </div>
          <div className="row">
            <span className="label">
              {t("voc.reports.dashboard.voAndClaim")}
            </span>
            <span className="value">
              {formatNumber(
                convertToNumber(
                  dashboardData.compensationEvents?.types?.voAndClaim
                )
              )}
            </span>
          </div>
        </div>
      </div>
      <DashboardChartContainer />
      <div className="dashboard-bottom">
        <div className="bot record-statuses">
          <div className="statrow headers">
            <div className="header status">
              <strong>{t("voc.reports.dashboard.recStatus")}</strong>
            </div>
            <div className="header sevent">{t("voc.common.siteEvent")}</div>
            <div className="header cevent">
              {t("voc.reports.dashboard.compEvent")}
            </div>
          </div>
          <div className="statrow outstanding">
            <div className="status">
              {t("voc.eventPage.supporting.recStatus.outstanding")}
            </div>
            <div className="sevent">
              {formatNumber(
                convertToNumber(dashboardData?.events?.status?.outstanding)
              )}
            </div>
            <div className="cevent">
              {formatNumber(
                convertToNumber(
                  dashboardData?.compensationEvents?.status?.outstanding
                )
              )}
            </div>
          </div>
          <div className="statrow prepared">
            <div className="status">
              {t("voc.reports.dashboard.recsPrepared")}
            </div>
            <div className="sevent">
              {formatNumber(
                convertToNumber(dashboardData?.events?.status?.prepared)
              )}
            </div>
            <div className="cevent">
              {formatNumber(
                convertToNumber(
                  dashboardData?.compensationEvents?.status?.prepared
                )
              )}
            </div>
          </div>
          <div className="statrow reviewed">
            <div className="status">
              {t("voc.reports.dashboard.recsReviewed")}
            </div>
            <div className="sevent">
              {formatNumber(
                convertToNumber(dashboardData?.events?.status?.reviewed)
              )}
            </div>
            <div className="cevent">
              {formatNumber(
                convertToNumber(
                  dashboardData?.compensationEvents?.status?.reviewed
                )
              )}
            </div>
          </div>
          <div className="statrow submitted">
            <div className="status">
              {t("voc.reports.dashboard.recsSubmitted")}
            </div>
            <div className="sevent">
              {formatNumber(
                convertToNumber(dashboardData?.events?.status?.submitted)
              )}
            </div>
            <div className="cevent">
              {formatNumber(
                convertToNumber(
                  dashboardData?.compensationEvents?.status?.submitted
                )
              )}
            </div>
          </div>
          <div className="statrow nodelay">
            <div className="status">
              {t("voc.eventPage.supporting.recStatus.noDisruption")}
            </div>
            <div className="sevent">
              {formatNumber(
                convertToNumber(dashboardData?.events?.status?.noAction)
              )}
            </div>
            <div className="cevent">
              {formatNumber(
                convertToNumber(
                  dashboardData?.compensationEvents?.status?.noAction
                )
              )}
            </div>
          </div>
        </div>
        <div className="bot notifications">
          <div className="row">
            <span className="label">
              {t("voc.reports.dashboard.upcomingClientNotifications")}
            </span>{" "}
            <span className="value">
              {formatNumber(
                convertToNumber(
                  dashboardData?.compensationEvents?.notifications?.upcoming
                )
              )}
            </span>
          </div>
          <div className="row">
            <span className="label">
              {t("voc.reports.dashboard.overdueClientNotifications")}
            </span>{" "}
            <span className="value">
              {formatNumber(
                convertToNumber(
                  dashboardData?.compensationEvents?.notifications?.overdue
                )
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
