import React from "react";

import useAppContext from "../../../app/hooks/useAppContext";
import useVocServiceAsync from "../../../app/services/useVocServiceAsync";
import { VarianceReport } from "../../../app/types";
import { getVarianceDisplayUnit } from "../../../libraries/libraries-services";
import { getVarianceReport } from "../../reports-services";
import VarianceReportLayout from "./VarianceReportLayout";

interface VarianceReportContainerProps {}

const VarianceReportContainer: React.FC<VarianceReportContainerProps> = (
  props
) => {
  const { serviceConfig } = useAppContext();

  const [reportData, setReportData] = React.useState<VarianceReport[]>(null);

  /////////////////////
  //GET VARIANCE REPORT
  const [,] = useVocServiceAsync<VarianceReport[]>(
    serviceConfig.entityId,
    () => {
      return getVarianceReport(serviceConfig);
    },
    {
      onSuccess: (data) => {
        const dataCopy = data;
        dataCopy.forEach((r: any) => {
          r.sortOrder = 0;
          if (r.key === "internalVariance") {
            r.sortOrder = r.sortOrder + 0;
          } else if (r.key === "targetVariance") {
            r.sortOrder = r.sortOrder + 1;
          } else if (r.key === "cumulativeValueVariance") {
            r.sortOrder = r.sortOrder + 2;
          }
          if (r.type === "VO_EV_TYPE_VO") {
            r.sortOrder = r.sortOrder + 0;
          } else if (r.type === "VO_EV_TYPE_CLAIM") {
            r.sortOrder = r.sortOrder + 10;
          } else if (r.type === "VO_EV_TYPE_VO_AND_CLAIM") {
            r.sortOrder = r.sortOrder + 20;
          }
        });
        dataCopy.sort((a, b) =>
          a.sortOrder > b.sortOrder ? 1 : a.sortOrder < b.sortOrder ? -1 : 0
        );
        setReportData(dataCopy);
      },
    }
  );

  ////////////////////////////
  //GET VARIANCE DISPLAY UNITS
  const [varDU, setVarDU] = React.useState<number>(null);
  const [,] = useVocServiceAsync<any>(
    serviceConfig.entityId,
    () => {
      return getVarianceDisplayUnit(serviceConfig);
    },
    {
      onSuccess: (data) => {
        setVarDU(data.displayUnit);
      },
    }
  );

  return reportData && varDU ? (
    <VarianceReportLayout reportData={reportData} varDU={varDU} />
  ) : null;
};

export default VarianceReportContainer;
