import React, { useState } from "react";

import AppListBuilderBase from "../../../app/components/app-list-builder";
import { AppListBuilderSelection } from "../../../app/components/app-list-builder/AppListBuilderBase";
import { VocCustomUi, VocCustomUiLayoutItem } from "../../../app/types";
import { SelectedUiReducerAction } from "../../selected-ui-reducer";

import { useTranslation } from "react-i18next";
import "./DataTable.scss";

interface DataTableProps {
  selectedUi: VocCustomUi;
  onSelectedUiDispatch: (action: SelectedUiReducerAction) => void;
  onSelectedUiSave: () => void;
  onCancel: () => void;
}

const DataTable: React.FC<DataTableProps> = ({
  selectedUi,
  onSelectedUiDispatch,
  onSelectedUiSave,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [listBuilderSelection, setListBuilderSelection] =
    useState<AppListBuilderSelection>();
  const handleMoveToLeft = (
    layoutItem: VocCustomUiLayoutItem,
    _: number,
    toIndex?: number
  ) => {
    const layoutItemList = [...selectedUi.layout];
    const toItem =
      toIndex === undefined
        ? undefined
        : layoutItemList.filter((item) => !item.display)[toIndex];

    // remove from list
    layoutItemList.splice(layoutItemList.indexOf(layoutItem), 1);

    // add back to list
    if (toItem) {
      layoutItemList.splice(layoutItemList.indexOf(toItem), 0, {
        ...layoutItem,
        display: false,
      });
    } else {
      layoutItemList.push({ ...layoutItem, display: false });
    }
    onSelectedUiDispatch({
      type: "dataTableChange",
      layoutItemList,
    });
  };

  const handleMoveToRight = (
    layoutItem: VocCustomUiLayoutItem,
    _: number,
    toIndex?: number
  ) => {
    const layoutItemList = [...selectedUi.layout];
    const toItem =
      toIndex === undefined
        ? undefined
        : layoutItemList.filter((item) => item.display)[toIndex];

    // remove from list
    layoutItemList.splice(layoutItemList.indexOf(layoutItem), 1);

    // add back to list
    if (toItem) {
      layoutItemList.splice(layoutItemList.indexOf(toItem), 0, {
        ...layoutItem,
        display: true,
      });
    } else {
      layoutItemList.push({ ...layoutItem, display: true });
    }
    onSelectedUiDispatch({
      type: "dataTableChange",
      layoutItemList,
    });
    setListBuilderSelection({
      isLeftItem: false,
      itemIndex:
        toIndex === undefined
          ? layoutItemList.filter((item) => item.display).length - 1
          : toIndex,
    });
  };

  const handleReorder = (
    layoutItem: VocCustomUiLayoutItem,
    fromIndex: number,
    toIndex?: number
  ) => {
    const layoutItemList = [...selectedUi.layout];
    const toItem =
      toIndex === undefined
        ? undefined
        : layoutItemList.filter((item) => item.display)[toIndex];

    // remove from list
    layoutItemList.splice(layoutItemList.indexOf(layoutItem), 1);

    // add back to list
    if (toItem) {
      layoutItemList.splice(layoutItemList.indexOf(toItem), 0, layoutItem);
    } else {
      layoutItemList.push(layoutItem);
    }

    onSelectedUiDispatch({
      type: "dataTableChange",
      layoutItemList,
    });

    setListBuilderSelection({
      isLeftItem: false,
      itemIndex:
        toIndex === undefined || toItem === undefined
          ? layoutItemList.filter((item) => item.display).length - 1 // move to end
          : fromIndex < toIndex
          ? toIndex - 1 // move down
          : fromIndex === toIndex + 1
          ? fromIndex - 1 //move up 1 position
          : layoutItemList.indexOf(toItem) - 1, //drag up more than 1 position
    });
  };

  return (
    <div className="voc-ce-customization-data-table">
      <AppListBuilderBase<VocCustomUiLayoutItem>
        leftHeader={t("voc.common.description")}
        rightHeader={t("voc.customization.displayInEventRegister")}
        listBuilderSelection={listBuilderSelection}
        leftItems={selectedUi.layout.filter((item) => !item.display)}
        rightItems={selectedUi.layout.filter((item) => item.display)}
        onMoveToLeft={handleMoveToLeft}
        onMoveToRight={handleMoveToRight}
        onReorder={handleReorder}
        onItemPropsRender={(item) => ({ key: item.key, label: item.name })}
        onSortAscending={(a, b) => (a.name > b.name ? 1 : -1)}
        onSortDescending={(a, b) => (a.name > b.name ? -1 : 1)}
        onSave={onSelectedUiSave}
        onCancel={onCancel}
      />
    </div>
  );
};

export default DataTable;
