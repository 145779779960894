import { NxpButton, NxpPanel, NxpTable } from "@nexploretechnology/nxp-ui";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import {
  VocSupportDocument,
  VocSupportDocumentEnums,
} from "../../../../../../app/types";
import { useGeneralSupportingActions } from "../hooks/useGeneralSupportingActions";
import { useGeneralSupportingColumns } from "../hooks/useGeneralSupportingColumns";

interface PlantRecordPanelProps {
  plantRecords: VocSupportDocument[];
  onRefresh: () => void;
  onSetIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

const useStyles = createUseStyles({
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
});

const PlantRecordPanel: React.FC<PlantRecordPanelProps> = ({
  plantRecords,
  onRefresh,
  onSetIsLoading,
  isLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { eventId, siteEventId } = useParams();

  const {
    handleInputChange,
    handleFileChange,
    handleRemoveFile,
    handleSave,
    deleteDocument,
    handleClickCancel,
    handleClickEdit,
    editing,
    formState,
  } = useGeneralSupportingActions({
    records: plantRecords,
    eventId,
    siteEventId,
    documentType: VocSupportDocumentEnums.Plant_Records,
    onRefresh,
    onSetIsLoading,
  });

  const columns = useGeneralSupportingColumns({
    editing,
    eventId,
    handleInputChange,
    handleFileChange,
    handleRemoveFile,
    deleteDocument,
  });

  return (
    <NxpPanel
      titleContent={`${t(
        "voc.eventPage.supporting.siteRecords.plantRecords"
      )} (${plantRecords?.length})`}
      defaultCollapsed
    >
      <div className={classes.buttonsContainer}>
        {!editing && (
          <NxpButton loading={isLoading} onClick={handleClickEdit}>
            {t("voc.common.edit")}
          </NxpButton>
        )}
        {editing && (
          <>
            <NxpButton
              loading={isLoading}
              type="default"
              onClick={handleClickCancel}
            >
              {t("voc.common.cancel")}
            </NxpButton>
            <NxpButton loading={isLoading} onClick={handleSave}>
              {t("voc.common.save")}
            </NxpButton>
          </>
        )}
      </div>
      <NxpTable
        loading={isLoading}
        columns={columns}
        dataSource={editing && formState ? formState : plantRecords}
        rowKey="id"
      />
    </NxpPanel>
  );
};

export default PlantRecordPanel;
