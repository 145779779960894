import React from "react";

import { ValidationResult } from "@nexploretechnology/nxp-ui";
import {
  IChoiceGroupOption,
  Icon,
  IDropdownOption,
  TextField,
} from "office-ui-fabric-react";

import AppFileUpload from "../../../app/components/app-file-upload/AppFileUpload";
import {
  AppFormDropdownField,
  AppFormField,
} from "../../../app/components/app-form";
import AppQuestionList from "../../../app/components/app-question-list";
import {
  AppQuestionListField,
  AppQuestionListFieldTypes,
} from "../../../app/components/app-question-list/AppQuestionList";
import { VocCustomUi, VocVO } from "../../../app/types";
import { INSTRUCTED_BY_OTHER } from "../../../app/utils/const";
import { getQuestionGroupFields } from "../../../app/utils/custom-ui";

const yesNoOptions: IChoiceGroupOption[] = [
  {
    key: "true",
    text: "yes", //no easy way to inject localeContext here...
  },
  {
    key: "false",
    text: "no", //no easy way to inject localeContext here...
  },
];

const getYesNoLabel = (yesNo?: boolean) => {
  return yesNoOptions.find((option) => option.key === yesNo?.toString())?.text;
};

const getInstructionObligatedRelatedFields = (
  t: any,
  voForm: VocVO,
  formErrors: ValidationResult<VocVO>,
  onFormChange: (form: Partial<VocVO>) => void
): AppQuestionListField[] => {
  const fields: AppQuestionListField[] = [];
  if (!voForm.instructionObligated) {
    fields.push({
      fieldType: AppQuestionListFieldTypes.Radio,
      fieldName: "pdInformalOk",
      caption: t("voc.eventPage.VODetails.pdApprovedInformal"),
      errorMessage: formErrors.pdInformalOk,
      options: yesNoOptions,
      selectedKey: voForm.pdInformalOk?.toString(),
      onChange: (_: React.FormEvent, option: IChoiceGroupOption) =>
        onFormChange({ pdInformalOk: option.key === "true" }),
      contentForRead: getYesNoLabel(voForm.pdInformalOk),
    });
  }

  if (!voForm.instructionObligated && !voForm.pdInformalOk) {
    fields.push({
      fieldType: AppQuestionListFieldTypes.TextInput,
      fieldName: "rejectReference",
      caption: t("voc.eventPage.VODetails.rejectFormalInstruction"),
      errorMessage: formErrors.rejectReference,
      value: voForm.rejectReference,
      onChange: (_: any, value: any) =>
        onFormChange({ rejectReference: value }),
      contentForRead: voForm.rejectReference,
    });
  }

  return fields;
};

const getCollapsibleFields = (
  t: any,
  groupKey: string,
  isEditing: boolean,
  voForm: VocVO,
  applicableClausesOptions: IDropdownOption[],
  valuationClauseOptions: IDropdownOption[],
  valuationMethodOptions: IDropdownOption[],
  instructionOwnerOptions: IDropdownOption[],
  customUi: VocCustomUi,
  formErrors: ValidationResult<VocVO>,
  onFormChange: (form: Partial<VocVO>) => void
) => {
  const voDetailsPresetFields: any[] = [
    {
      fieldType: AppQuestionListFieldTypes.Custom,
      fieldName: "serial",
      caption: t("voc.eventPage.VODetails.VONumberInternal"),
      contentForRead: voForm.serial,
    },
    {
      fieldType: AppQuestionListFieldTypes.TextInput,
      fieldName: "advanceInstruction",
      caption: t("voc.eventPage.VODetails.advanceInstructionReference"),
      errorMessage: formErrors.advanceInstruction,
      value: voForm.advanceInstruction,
      onChange: (_: any, value: any) =>
        onFormChange({ advanceInstruction: value }),
      contentForRead: voForm.advanceInstruction,
    },
    {
      fieldType: AppQuestionListFieldTypes.TextInput,
      fieldName: "clauseReferenced",
      caption: t("voc.eventPage.VODetails.clausesReferenced"),
      errorMessage: formErrors.clauseReferenced,
      value: voForm.clauseReferenced,
      onChange: (_: any, value: any) =>
        onFormChange({ clauseReferenced: value }),
      contentForRead: voForm.clauseReferenced,
    },
    {
      fieldType: AppQuestionListFieldTypes.Custom,
      fieldName: "instructionOwner",
      caption: t("voc.eventPage.VODetails.instructionIssuedBy"),
      contentForEdit: (
        <>
          <AppFormDropdownField
            options={instructionOwnerOptions}
            value={voForm.instructionOwner?.value}
            onChange={(
              _: any,
              option: { key: { toString: () => any }; text: any }
            ) => {
              onFormChange({
                instructionOwner: {
                  value: option.key.toString(),
                  label: option.text,
                },
              });
            }}
          />
          {voForm.instructionOwner?.value === INSTRUCTED_BY_OTHER && (
            <span className="specify-other">
              {t("voc.eventPage.VODetails.specify")}
              <TextField
                value={voForm.instructionOwnerOptional}
                onChange={(_, value) => {
                  onFormChange({
                    instructionOwnerOptional: value,
                  });
                }}
              />
            </span>
          )}
        </>
      ),
      contentForRead:
        voForm.instructionOwner?.value === INSTRUCTED_BY_OTHER &&
        voForm.instructionOwnerOptional
          ? `Other (${voForm.instructionOwnerOptional})`
          : voForm.instructionOwner &&
            voForm.instructionOwner.value &&
            instructionOwnerOptions.length &&
            instructionOwnerOptions.find(
              (option) => option.key === voForm.instructionOwner.value
            )?.text,
    },
    {
      fieldType: AppQuestionListFieldTypes.Radio,
      fieldName: "instructionEmpowered",
      caption: t("voc.eventPage.VODetails.instructionEmpowered"),
      disabled: true,
      options: yesNoOptions,
      selectedKey: voForm.instructionEmpowered.toString(),
      onChange: (_: React.FormEvent, option: IChoiceGroupOption) =>
        onFormChange({ instructionEmpowered: option.key === "true" }),
      contentForRead: getYesNoLabel(voForm.instructionEmpowered),
    },
    {
      fieldType: AppQuestionListFieldTypes.Radio,
      fieldName: "instructionObligated",
      caption: t("voc.eventPage.VODetails.obligatedToProceed"),
      errorMessage: formErrors.instructionObligated,
      options: yesNoOptions,
      selectedKey: voForm.instructionObligated?.toString(),
      onChange: (_: React.FormEvent, option: IChoiceGroupOption) =>
        onFormChange({ instructionObligated: option.key === "true" }),
      contentForRead: getYesNoLabel(voForm.instructionObligated),
    },
    ...getInstructionObligatedRelatedFields(
      t,
      voForm,
      formErrors,
      onFormChange
    ),
    {
      fieldType: AppQuestionListFieldTypes.TextInput,
      fieldName: "formalInstruction",
      caption: t("voc.eventPage.VODetails.formalInstructionRef"),
      errorMessage: formErrors.formalInstruction,
      value: voForm.formalInstruction,
      onChange: (_: any, value: any) =>
        onFormChange({ formalInstruction: value }),
      contentForRead: voForm.formalInstruction,
    },
    {
      fieldType: AppQuestionListFieldTypes.Dropdown,
      fieldName: "applicableClauses",
      caption: t("voc.eventPage.VODetails.clausesReferencedInstruction"),
      options: applicableClausesOptions,
      selectedKeys: voForm.applicableClauses?.map(
        (item: { value: any }) => item.value
      ),
      multiSelect: true,
      onChange: (
        _: any,
        option: { key: { toString: () => any }; selected: any; text: any }
      ) => {
        const key = option.key.toString();
        const prevArr = voForm.applicableClauses
          ? [...voForm.applicableClauses]
          : [];
        onFormChange({
          applicableClauses: option.selected
            ? [...prevArr, { value: key, label: option.text }]
            : voForm.applicableClauses.filter(
                (item: { value: any }) => item.value !== key
              ),
        });
      },
      contentForRead:
        voForm.applicableClauses && applicableClausesOptions.length
          ? voForm.applicableClauses
              .map(
                (dict: { value: string | number }) =>
                  applicableClausesOptions.find(
                    (option) => option.key === dict.value
                  )?.text
              )
              .join(", ")
          : "",
    },
    {
      fieldType: AppQuestionListFieldTypes.Dropdown,
      fieldName: "applicableValuation",
      caption: t("voc.eventPage.VODetails.clausesReferencedValuation"),
      options: valuationClauseOptions,
      selectedKeys: voForm.applicableValuation?.map(
        (item: { value: any }) => item.value
      ),
      multiSelect: true,
      onChange: (
        _: any,
        option: { key: { toString: () => any }; selected: any; text: any }
      ) => {
        const key = option.key.toString();
        const prevArr = voForm.applicableValuation
          ? [...voForm.applicableValuation]
          : [];
        onFormChange({
          applicableValuation: option.selected
            ? [...prevArr, { value: key, label: option.text }]
            : voForm.applicableValuation.filter(
                (item: { value: any }) => item.value !== key
              ),
        });
      },
      contentForRead:
        voForm.applicableValuation && valuationClauseOptions.length
          ? voForm.applicableValuation
              .map(
                (dict: { value: string | number }) =>
                  valuationClauseOptions.find(
                    (option) => option.key === dict.value
                  )?.text
              )
              .join(", ")
          : "",
    },
    {
      fieldType: AppQuestionListFieldTypes.Dropdown,
      fieldName: "valuationMethod",
      caption: t("voc.eventPage.VODetails.methodOfValuation"),
      options: valuationMethodOptions,
      //valuationMethod is value string, not {value, label} object
      selectedKeys: voForm.valuationMethod?.map((item: any) => item),
      multiSelect: true,
      onChange: (
        _: any,
        option: { key: { toString: () => any }; selected: any }
      ) => {
        const key = option.key.toString();
        const prevArr = voForm.valuationMethod
          ? [...voForm.valuationMethod]
          : [];
        onFormChange({
          valuationMethod: option.selected
            ? [...prevArr, key]
            : //valuationMethod is value string, not {value, label} object
              voForm.valuationMethod.filter((item: any) => item !== key),
        });
      },
      contentForRead:
        voForm.valuationMethod && valuationMethodOptions.length
          ? //valuationMethod is value string, not {value, label} object
            voForm.valuationMethod
              .map(
                (dict: string | number) =>
                  valuationMethodOptions.find((option) => option.key === dict)
                    ?.text
              )
              .join(", ")
          : null,
    },
    {
      fieldType: AppQuestionListFieldTypes.TextInput,
      fieldName: "clientVoRef",
      caption: t("voc.eventPage.VODetails.clientEngineerVONo"),
      errorMessage: formErrors.clientVoRef,
      value: voForm.clientVoRef,
      onChange: (_: any, value: any) => onFormChange({ clientVoRef: value }),
      contentForRead: voForm.clientVoRef,
    },
    {
      fieldType: AppQuestionListFieldTypes.Radio,
      fieldName: "coveredByFormal",
      caption: t("voc.eventPage.VODetails.agreedInPrinciple"),
      errorMessage: formErrors.coveredByFormal,
      options: yesNoOptions,
      selectedKey: voForm.coveredByFormal?.toString(),
      onChange: (_: React.FormEvent, option: IChoiceGroupOption) =>
        onFormChange({ coveredByFormal: option.key === "true" }),
      contentForRead: getYesNoLabel(voForm.coveredByFormal),
    },
    {
      fieldType: AppQuestionListFieldTypes.Radio,
      fieldName: "sowChangeOk",
      caption: t("voc.eventPage.VODetails.agreementToSOW"),
      errorMessage: formErrors.sowChangeOk,
      options: yesNoOptions,
      selectedKey: voForm.sowChangeOk?.toString(),
      onChange: (_: React.FormEvent, option: IChoiceGroupOption) =>
        onFormChange({ sowChangeOk: option.key === "true" }),

      contentForRead: getYesNoLabel(voForm.sowChangeOk),
    },
    {
      fieldType: AppQuestionListFieldTypes.Radio,
      fieldName: "quantumQtyOk",
      caption: t("voc.eventPage.claims.agreementToQuantumQuantities"),
      errorMessage: formErrors.quantumQtyOk,
      options: yesNoOptions,
      selectedKey: voForm.quantumQtyOk?.toString(),
      onChange: (_: React.FormEvent, option: IChoiceGroupOption) =>
        onFormChange({ quantumQtyOk: option.key === "true" }),
      contentForRead: getYesNoLabel(voForm.quantumQtyOk),
    },
    {
      fieldType: AppQuestionListFieldTypes.Radio,
      fieldName: "quantumRatesOk",
      caption: t("voc.eventPage.claims.agreementToQuantumPrices"),
      errorMessage: formErrors.quantumRatesOk,
      options: yesNoOptions,
      selectedKey: voForm.quantumRatesOk?.toString(),
      onChange: (_: any, option: IChoiceGroupOption) =>
        onFormChange({ quantumRatesOk: option.key === "true" }),

      contentForRead: getYesNoLabel(voForm.quantumRatesOk),
    },
    {
      fieldType: AppQuestionListFieldTypes.Radio,
      fieldName: "quantumOhnpOk",
      caption: t("voc.eventPage.claims.agreementToQuantumOhp"),
      errorMessage: formErrors.quantumOhnpOk,
      options: yesNoOptions,
      selectedKey: voForm.quantumOhnpOk?.toString(),
      onChange: (_: React.FormEvent, option: IChoiceGroupOption) =>
        onFormChange({ quantumOhnpOk: option.key === "true" }),
      contentForRead: getYesNoLabel(voForm.quantumOhnpOk),
    },
    {
      fieldType: AppQuestionListFieldTypes.Custom,
      fieldName: "referenceFile",
      caption: t("voc.eventPage.VODetails.refFile"),
      contentForEdit: (
        <AppFormField className="file-upload">
          {voForm.referenceFile?.assetId ? (
            <>
              <p className="file-info">
                <a
                  href={voForm.referenceFile.url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Icon iconName="PageSolid" />
                  {voForm.referenceFile.fileName}
                </a>
                <button
                  className="remove"
                  onClick={() =>
                    onFormChange({
                      referenceFile: null,
                    })
                  }
                >
                  X
                </button>
              </p>
            </>
          ) : (
            <AppFileUpload
              label={null}
              onChange={(files: string | any[]) => {
                if (files.length) {
                  onFormChange({
                    referenceFile: {
                      url: (files[0] as any).url,
                      fileName: files[0].fileName,
                      assetId: files[0].assetId,
                      mimeType: files[0].mimeType,
                    },
                  });
                }
              }}
              fileLimit={1}
            />
          )}
        </AppFormField>
      ),
      contentForRead: voForm.referenceFile?.assetId && (
        <>
          <p className="file-info">
            <a
              href={voForm.referenceFile.url}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icon iconName="PageSolid" />
              {voForm.referenceFile.fileName}
            </a>
          </p>
        </>
      ),
    },
    {
      fieldType: AppQuestionListFieldTypes.TextInput,
      fieldName: "varianceComment",
      caption: t("voc.eventPage.summary.comment"),
      errorMessage: formErrors.varianceComment,
      value: voForm.varianceComment,
      onChange: (_: any, value: any) =>
        onFormChange({ varianceComment: value }),
      contentForRead: voForm.varianceComment,
      fillRow: true,
    },
  ];

  return (
    <AppQuestionList
      isEditing={isEditing}
      fields={getQuestionGroupFields(
        groupKey,
        customUi,
        voDetailsPresetFields,
        voForm,
        onFormChange
      )}
    />
  );
};

export default getCollapsibleFields;
