import { t } from "i18next";
import { VocValuesSubrecord } from "../../../../app/types";

export type ValueBreakdownRecord = VocValuesSubrecord & {
  ohnpRecord: number;
  profitLossRecord: number;
  totalCostRecord: number;
  paymentApplication: number;
};
const VocEventValuationMethod = {
  BQ_Rate: "VO_EVAL_METHOD_01",
  Star_Rate: "VO_EVAL_METHOD_02",
  Pro_Rata: "VO_EVAL_METHOD_03",
  Cost_Plus: "VO_EVAL_METHOD_04",
  Lump_Sum_Offer: "VO_EVAL_METHOD_05",
  Other: "VO_EVAL_METHOD_99",
};

export const valuationMethodOptions = [
  {
    render: t("voc.common.pleaseSelect"),
    enumValue: null,
  },
  {
    render: "BQ Rate",
    enumValue: VocEventValuationMethod.BQ_Rate,
  },
  {
    render: "Star Rate",
    enumValue: VocEventValuationMethod.Star_Rate,
  },
  {
    render: "Pro Rata",
    enumValue: VocEventValuationMethod.Pro_Rata,
  },
  {
    render: "Cost Plus",
    enumValue: VocEventValuationMethod.Cost_Plus,
  },
  {
    render: "Lump Sum Offer",
    enumValue: VocEventValuationMethod.Lump_Sum_Offer,
  },
  {
    render: "Other",
    enumValue: VocEventValuationMethod.Other,
  },
];

export interface EditValuesSubrecord {
  id: string | number;
  description: string;
  valuationMethod: string;
  costCode: string;
  directCostPrevious: number;
  indirectCostPrevious: number;
  directCostPlanned: number;
  indirectCostPlanned: number;
  closeOut: number;
  gna: number;
  totalCost: number;
  revenue: number;
  profitLoss: number;
  ohnp: number;
  paymentApplication: number;
  clientAssessed: number;
  clientCertified: number;
}
