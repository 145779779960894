import React from "react";

import { Icon } from "office-ui-fabric-react";

import "./AppMessageBar.scss";

export enum AppMessageBarType {
  Warning = "Warning",
  Error = "Error",
  Success = "Success",
  Info = "Info",
}

type AppMessageBarProps = {
  messageBarType: AppMessageBarType;
  children?: React.ReactNode;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const getIcon = (messageBarType: AppMessageBarType) => {
  switch (messageBarType) {
    case AppMessageBarType.Info:
      return <Icon iconName="InfoSolid" />;
    case AppMessageBarType.Success:
      return <Icon iconName="CompletedSolid" />;
    case AppMessageBarType.Error:
      return <Icon iconName="StatusErrorFull" />;
    case AppMessageBarType.Warning:
      return <Icon iconName="WarningSolid" />;
  }
};

const AppMessageBar: React.FC<AppMessageBarProps> = ({
  messageBarType,
  children,
  className,
}) => {
  return (
    <div
      className={`app-message-bar ${messageBarType.toLowerCase()}${
        className ? ` ${className}` : ""
      }`}
    >
      {getIcon(messageBarType)}
      {children}
    </div>
  );
};

export default AppMessageBar;
