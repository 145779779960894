import "./AppHeader.scss";

import { BaseButton } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { BACK_HREF_BY_JS } from "../../utils/const";

interface AppHeaderProps {
  localeResourceId?: string;
  text?: string;
  subtext?: string;
  backHref?: string;
  children?: React.ReactNode;
}

const AppHeader: React.FC<React.PropsWithChildren<AppHeaderProps>> = ({
  localeResourceId,
  text,
  subtext,
  backHref,
  children,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="app-header-container">
      <h1 className="header-title">
        {backHref && (
          <BaseButton
            className="header-back-button"
            onClick={() => {
              if (backHref !== BACK_HREF_BY_JS) {
                navigate(backHref);
              } else {
                navigate(-1);
                // history.back();
              }
            }}
          >
            <span className="arrow">◀</span> {t("voc.common.back")}
          </BaseButton>
        )}
        <span className="header-title-content">
          <span className="header-title-title">
            {localeResourceId !== undefined ? t(localeResourceId) : text}
          </span>{" "}
          {subtext && <span className="header-title-subtitle">{subtext}</span>}
        </span>
      </h1>
      <div className="header-sidearea">{children}</div>
    </div>
  );
};

export default AppHeader;
