import { apiRequest, ServiceConfig } from "../app/utils/backend/api-request";

export interface EntityRoleAccessRight {
  id: string;
  permissions: AccessPermission[];
}

export interface AccessPermission {
  application: string;
  objectCode: string;
  action: string;
}

export interface ApplicationPermissionSetData {
  id: string;
  applicationCode: string;
  objectCode: string;
  action: string;
}

export interface EntityRole {
  id: string;
  rootEntityId: string | null;
  operationEntityId: string | null;
  code: string;
  name: string;
  description: string;
  isDisabled: boolean;
  isAdmin: boolean;
  isVirtual: boolean;
  isCopyFromEntityType: boolean;
  rootEntity: Entity | null;
  operationEntity: OperationEntity | null;
  userEntities?: UserEntity[];
}

export interface Entity {
  id: string;
  name: string;
  entityCode: string; // added for CPCS-976
  description: string;
  members?: Entity[];
  tenantId?: string | null;
  type?: "Root" | "Operation";
}

export interface OperationEntity extends Entity {
  level: string;
  rootEntityId?: string;
  parentOperationEntityId?: string;
  timezone: string;
  parentOperationEntity?: OperationEntity;
  rootEntity?: Entity;
}

export interface UserEntity {
  id: string;
  userId: string;
  entityType: string;
  entityId: string;
  entityRoleId: string;
  entityRole?: EntityRole;
  user?: User;
}

export type UserStatus = "US_FULLTIME" | "US_PARTTIME";

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  primaryEmail: string;
  isSuspended: boolean;
  userEntities?: UserEntity[];

  salutation?: string | null;
  displayName: string;
  title?: string | null;
  mobileNumber?: string | null;
  officeNumber?: string | null;
  comments?: string | null;
  isPrimaryContact?: boolean;
  startDate?: string | null;
  endDate?: string | null;
  status?: UserStatus | null;
  avatar?: {
    filename?: string;
    assetId?: string;
    url?: string;
  } | null;
}

export interface AddUserEntity {
  entityRoleIds: string[];
}

export type AddUser = {
  firstName: string;
  lastName: string;
  primaryEmail: string;
  isADUser?: boolean;
  entityRoleIds?: string[];
} & Partial<AddUserEntity>;

export interface ADUser {
  id: string;
  displayName: string;
  givenName: string;
  surname: string;
  mail: string;
}

export const getEntityRoles = async (serviceConfig: ServiceConfig) => {
  return apiRequest<EntityRole[]>(`/entity-roles`, serviceConfig);
};

export const getRoleAccessRight = async (
  serviceConfig: ServiceConfig,
  roleId: string
) => {
  const res = await apiRequest<EntityRoleAccessRight[]>(
    `/entity-roles/${roleId}/rights`,
    serviceConfig
  );
  return res;
};

export const getAllAccessRight = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<ApplicationPermissionSetData[]>(
    `${serviceConfig.apiBaseUrl}/rights`,
    serviceConfig
  );
};

export const deleteRoleRights = async (
  serviceConfig: ServiceConfig,
  entityRoleId: string,
  rightId: string
) => {
  return await apiRequest<ApplicationPermissionSetData>(
    `/entity-roles/${entityRoleId}/rights/${rightId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export const addRoleRights = async (
  serviceConfig: ServiceConfig,
  entityRoleId: string,
  rightId: string
) => {
  return await apiRequest<EntityRoleAccessRight>(
    `/entity-roles/${entityRoleId}/rights/${rightId}`,
    serviceConfig,
    {
      method: "POST",
    }
  );
};
