import { apiRequest, ServiceConfig } from "../utils/backend/api-request";
import { CPCSUser } from "./app";

export interface UserPreference {
  id: string;
  userId: string;
  application: string;
  parameter: string;
  value: string;
}
export type AddUserPreference = Omit<UserPreference, "id" | "userId">;

export const getMyUserPreference = async (serviceConfig: ServiceConfig) => {
  return apiRequest<UserPreference[]>(
    `${serviceConfig.userDirectoryServiceApiBaseUrl}/user-preference/me`,
    serviceConfig
  );
};

export const createMyUserPreference = async (
  serviceConfig: ServiceConfig,
  userPreference: AddUserPreference
) => {
  return apiRequest<UserPreference>(
    `${serviceConfig.userDirectoryServiceApiBaseUrl}/user-preference/me`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(userPreference),
    }
  );
};

export const patchMyUserPreference = async (
  serviceConfig: ServiceConfig,
  userPreference: AddUserPreference
) => {
  return apiRequest<any>(
    `${serviceConfig.userDirectoryServiceApiBaseUrl}/user-preference/me`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(userPreference),
    }
  );
};

export const updateMyProfile = async (
  user: CPCSUser,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<CPCSUser>(
    `${serviceConfig.userDirectoryServiceApiBaseUrl}/user/me`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        firstName: user.firstName,
        lastName: user.lastName,
        displayName: user.displayName,
        primaryEmail: user.primaryEmail,
        mobileNumber: user.mobileNumber,
        title: user.title,
        salutation: user.salutation,
        officeNumber: user.officeNumber,
        avatar: user.avatar?.assetId ? user.avatar : null,
      }),
    }
  );
};
