import { NxpFormGrid, NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";
import React from "react";
import { VocGetValuesBuildUp } from "../../../../app/types";
import {
  calculateOverheadProfit,
  calculateProfitLoss,
  calculateTotalCost,
} from "../helpers/buildUpCalculations";
import { createUseStyles } from "react-jss";
import { getFormattedInputNumberControlProps } from "../../../../app/utils/number/getFormattedInputNumberControlProps";
import { useTranslation } from "react-i18next";
import i18n from "../../../../app/i18n/i18n";

interface BuildUpPanelProps {
  editing: boolean;
  fieldNames: Record<string, string>;
  buildUp: VocGetValuesBuildUp;
  internalTarget: number;
  onSetBuildUpFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesBuildUp>
  >;
}

const useStyles = createUseStyles((theme) => ({
  title: {
    color: theme.palette.primary,
  },
}));

const BuildUpPanel: React.FC<BuildUpPanelProps> = ({
  editing,
  fieldNames,
  buildUp,
  internalTarget,
  onSetBuildUpFormState,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const handleFormGridStateChange = (
    fieldName: keyof VocGetValuesBuildUp,
    value: unknown
  ) => {
    onSetBuildUpFormState((prevState) => {
      const newState = {
        ...prevState,
        [fieldName]: value,
      };

      if (
        fieldName === "directCost" ||
        fieldName === "indirectCost" ||
        fieldName === "costCloseOut" ||
        fieldName === "costGna"
      ) {
        //M = I + J + K + L
        newState.totalCost = calculateTotalCost(newState);
        //N = E - M
        newState.profitLoss = calculateProfitLoss(newState, internalTarget);

        // O = (N + L) / (I + J + K)
        // Recalculate overhead profit
        newState.overheadProfit = calculateOverheadProfit(newState);
      }

      return newState;
    });
  };

  const buildUpFormItems: NxpFormGridItemProps<VocGetValuesBuildUp>[] = [
    {
      controlType: "inputNumber",
      label: fieldNames.directCost,
      itemFieldName: "directCost",
      span: 3,
      controlProps: {
        value: buildUp?.directCost,
        addonBefore: editing ? "$" : undefined,
        prefix: editing ? undefined : "$",
        precision: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      controlType: "inputNumber",
      label: fieldNames.indirectCost,
      itemFieldName: "indirectCost",
      span: 3,
      controlProps: {
        value: buildUp?.indirectCost,
        addonBefore: editing ? "$" : undefined,
        prefix: editing ? undefined : "$",
        precision: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      controlType: "inputNumber",
      label: fieldNames.costCloseout,
      itemFieldName: "costCloseOut",
      span: 3,
      controlProps: {
        value: buildUp?.costCloseOut,
        addonBefore: editing ? "$" : undefined,
        prefix: editing ? undefined : "$",
        precision: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      controlType: "inputNumber",
      label: fieldNames.costGnA,
      itemFieldName: "costGna",
      span: 3,
      controlProps: {
        value: buildUp?.costGna,
        addonBefore: editing ? "$" : undefined,
        prefix: editing ? undefined : "$",
        precision: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      controlType: "inputNumber",
      label: fieldNames.totalCost,
      itemFieldName: "totalCost",
      span: 3,
      editing: false,
      controlProps: {
        value: buildUp?.totalCost,
        prefix: "$",
        precision: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      controlType: "inputNumber",
      label: fieldNames.profitLoss,
      itemFieldName: "profitLoss",
      span: 3,
      editing: false,
      controlProps: {
        value: buildUp?.profitLoss,
        prefix: "$",
        precision: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      controlType: "inputPercent",
      label: fieldNames.ohp,
      itemFieldName: "overheadProfit",
      span: 3,
      editing: false,
      controlProps: {
        value: buildUp?.overheadProfit,
        suffix: "%",
        precision: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
  ];

  return (
    <>
      <h4 className={classes.title}>
        {t("voc.eventPage.values.buildUpHeader")}
      </h4>
      <NxpFormGrid
        editing={editing}
        formItems={buildUpFormItems}
        formState={buildUp}
        onFormStateChange={handleFormGridStateChange}
      />
    </>
  );
};

export default BuildUpPanel;
