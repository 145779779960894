import React from "react";
import { useTranslation } from "react-i18next";

import AppCollapsible from "../../../app/components/app-collapsible/AppCollapsible";
import AppDataListV2 from "../../../app/components/app-data-list/AppDataListV2";
import { DataListItem } from "../../../app/components/app-data-list/types";
import { VocLibDocIndex, VocLibraryObject } from "../../../app/types";
import { useLibraryHandler } from "../../hooks/useLibraryHandler";
import { cloneDeep } from "lodash";

interface LabelAndDayProps {
  libraryType: "VOC_CLIENT_NOTIFICATION" | "VOC_ESCALATION_RECORDS";
  refetchCount: number;
}

export const LabelAndDay: React.FC<LabelAndDayProps> = ({
  libraryType,
  refetchCount,
}) => {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = React.useState(false);

  const convertLabelAndDay = (items: VocLibraryObject[]) => {
    const converted = items.map((item) => ({
      id: item.id,
      edited: false,
      value: item.value,
      undeletable: true,
      sortOrder: item.sortOrder,
      fieldDataArray: [{ fieldData: item.label }, { fieldData: item.days }],
    }));
    return converted;
  };

  const {
    // Variables
    fetchLibraryAndUsage,
    isLibraryAndUsageLoaded,
    libraryItem,
    displayLibraryItem,
    setDisplayLibraryItem,
    // Update Handler
    handleUpdateLibraries,
  } = useLibraryHandler(libraryType, convertLabelAndDay, refetchCount);

  // ================================================
  //                 panel & colDef
  // ================================================

  const panelTitle = React.useMemo(
    () =>
      libraryType === "VOC_CLIENT_NOTIFICATION"
        ? t("voc.library.titles.clientNotification")
        : libraryType === "VOC_ESCALATION_RECORDS"
        ? t("voc.library.titles.escalation")
        : "",
    [libraryType, t]
  );

  const column = React.useMemo(
    () => [
      {
        columnName: t("voc.library.name"),
        columnWidthClassName: "eightythreepercent",
        columnDataType: "string",
        editable: false,
      },
      {
        columnName: t("voc.library.days"),
        columnWidthClassName: "fifteenpercent",
        columnDataType: "string",
        sortNumerically: true,
        editable: true,
      },
    ],
    [t]
  );

  // ================================================
  //                Action Handler
  // ================================================

  const handleSave = () => {
    let validationError: VocLibDocIndex[] = [];

    // ================== validation ==================

    // check edited start date is ealier than end date
    displayLibraryItem.forEach((ti: DataListItem, i: number) => {
      if (ti.edited) {
        if (
          ti.fieldDataArray[1].fieldData && // exist
          isNaN(ti.fieldDataArray[1].fieldData) // but it is not a number
        ) {
          libraryItem.forEach((item: any, i2: number) => {
            if (i2 === i) {
              validationError = [...validationError, { docIndex: i2 }];
            }
          });
        }
      }
    });

    // return and show error msg if error exists
    if (validationError.length > 0) {
      setDisplayLibraryItem((prevDisplayLibraryItem) => {
        let result = cloneDeep(prevDisplayLibraryItem);
        validationError.forEach((valErr) => {
          let invalidated: DataListItem = null;
          invalidated = result[valErr.docIndex];
          invalidated.validationError = t(
            "voc.library.validation.numberRequired"
          );
          const newHookState = [...result];
          newHookState[valErr.docIndex] = invalidated;
          result = [...newHookState];
        });
        return result;
      });
      return;
    }

    // ================== if validation pass ==================

    const deconvertedTCs: Partial<VocLibraryObject>[] = [];
    const deconversionTarget: DataListItem[] = displayLibraryItem;
    deconversionTarget.forEach((tc: DataListItem) => {
      if (tc.edited) {
        deconvertedTCs.push({
          id: tc.id,
          value: tc.value,
          label: tc.fieldDataArray[0].fieldData,
          days: Number(tc.fieldDataArray[1].fieldData).toFixed(0),
        });
      }
    });
    handleUpdateLibraries(
      {
        items: deconvertedTCs,
      },
      () => setIsEditing(false)
    );
  };

  const handleCancel = () => {
    // setDisplayLibraryItem(libraryItem);
    fetchLibraryAndUsage();
  };

  return (
    <AppCollapsible
      permissionCode="libraries"
      hasFields={false}
      editCallback={() => {
        setIsEditing(true);
      }}
      editingState={isEditing}
      saveCallback={handleSave}
      cancelCallback={() => {
        handleCancel();
        setIsEditing(false);
      }}
      firstDecollapseCallback={() => {
        fetchLibraryAndUsage();
      }}
      isLoading={!isLibraryAndUsageLoaded}
      isLoadOnEveryClick={true}
      headerChildren={<div className="doctype-title">{panelTitle}</div>}
      bodyChildren={
        isLibraryAndUsageLoaded ? (
          <AppDataListV2
            permissionCode="libraries"
            editing={isEditing}
            canDeleteLastItem={true}
            columns={column}
            oriItems={libraryItem}
            items={displayLibraryItem}
            editSetStateHook={setDisplayLibraryItem}
            setFiles={null}
            defaultSortDirection=""
            saveCustomOrderCallback={null}
            resetCustomOrderCallback={null}
            saveCustomOrderCode={null}
            initCustomSortState={false}
          />
        ) : null
      }
    ></AppCollapsible>
  );
};

export default LabelAndDay;
