import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AppButton from "../../../../app/components/app-button";
import { VocEvent } from "../../../../app/types";
import NewSupportingDocumentModal from "./NewSupportingDocumentModal";

interface NewDocumentButtonProps {
  siteEventId?: string;
  eventId?: VocEvent["id"];
  recordSummaryDocumentTypesOnly?: boolean;
  buttonCaption?: string;
  onRefresh: () => void;
}

const NewSupportingDocumentButton: React.FC<NewDocumentButtonProps> = ({
  onRefresh,
}) => {
  const { t } = useTranslation();

  const [showNewDocumentModal, setShowNewDocumentModal] = useState(false);

  const handleModalClose = () => setShowNewDocumentModal(false);

  return (
    <>
      <AppButton
        className="voc-event-supporting-new"
        onClick={() => setShowNewDocumentModal(true)}
      >
        {t("voc.common.newDocument")}
      </AppButton>

      {showNewDocumentModal && (
        <NewSupportingDocumentModal
          onRefresh={onRefresh}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default NewSupportingDocumentButton;
