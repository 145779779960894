import React from "react";
import { createUseStyles } from "react-jss";

import { NxpAlert } from "@nexploretechnology/nxp-ui";

const useStyles = createUseStyles((theme) => ({
  DemoAlerts: {},
}));

interface DemoAlertsProps {}

const DemoAlerts: React.FC<DemoAlertsProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.DemoAlerts}>
      <NxpAlert message="Demo 123" />
      <NxpAlert message="Demo abc" type="warning" />
      <NxpAlert
        message={
          <>
            <p>Demo 123</p> <p>Demo abc</p>
          </>
        }
        type="info"
      />
      <NxpAlert message="Done" type="success" />
    </div>
  );
};

export default DemoAlerts;
