import React from "react";

import "./AppButton.scss";

export enum AppButtonType {
  Primary = "Primary",
  Basic = "Basic",
  Link = "Link",
  Danger = "Danger",
}

type AppButtonProps = {
  text?: string;
  appButtonType?: AppButtonType;
  children?: React.ReactNode;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const AppButton: React.FC<AppButtonProps> = ({
  text, // for non-string content, use children
  appButtonType = AppButtonType.Primary,
  children,
  className,
  ...restProps
}) => {
  return (
    <button
      className={`app-button ${appButtonType.toLowerCase()}${
        className ? ` ${className}` : ""
      }`}
      {...restProps}
    >
      {children ? children : text}
    </button>
  );
};

export default AppButton;
