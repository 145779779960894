import { NxpSelectUserOption } from "@nexploretechnology/nxp-ui";
import { EntityUser } from "../services/app";
import { getUserDisplayName } from "../utils/helper/get-user-display-name";
import useAppContext from "./useAppContext";
import _ from "lodash";

export interface UserInfo {
  id: string;
  displayName: string;
  email: string;
  isSuspended: boolean;
}

export const useSelectUserOptions = () => {
  const { entityUsers } = useAppContext();

  const getUserOptionsForTag = (
    selectedUserOptions: EntityUser[]
  ): (NxpSelectUserOption & { user?: EntityUser; tag: string })[] => {
    const deduplicatedSelectedUsers = _.uniqBy(selectedUserOptions, "id");

    return deduplicatedSelectedUsers
      .filter((user) => user.isSuspended)
      .map((user) => {
        return {
          id: user.id,
          name: getUserDisplayName(user, true, true),
          tag: getUserDisplayName(user, false, true),
        };
      })
      .concat(
        entityUsers.map((user) => ({
          id: user.id,
          name: getUserDisplayName(user, true, true),
          tag: getUserDisplayName(user),
        }))
      );
  };

  return {
    getUserOptionsForTag,
  };
};
