import React, { ReactNode } from "react";

import {
  notify,
  NxpAuthSpinner,
  NxpLayoutGlobal,
} from "@nexploretechnology/nxp-ui";
import { useKeycloak } from "@react-keycloak/web";

import useAppContext from "../../hooks/useAppContext";
import { Right } from "../../services/app";
import AccessVisible from "../app-access-visible/AppAccessVisible";
import AppErrorBoundary from "../app-error-boundary/AppErrorBoundary";
import AppLayout from "../app-layout";
import useRouteAppContextUpdate from "./useRouteAppContextUpdate";

interface AppProtectedPageProps {
  requireEntityReady?: boolean;
  component: ReactNode;
  accessRight?: Right;
}

const AppProtectedPage: React.FC<AppProtectedPageProps> = ({
  requireEntityReady,
  component,
  accessRight,
}) => {
  const { keycloak } = useKeycloak();
  const appContext = useAppContext();
  useRouteAppContextUpdate();

  if (
    appContext.activeEntity &&
    appContext.entityUsers &&
    (accessRight?.objectCode === "event" ||
      accessRight?.objectCode === "compensation_event") &&
    !appContext.hasRight({
      objectCode: "compensation_event",
      actionType: "view",
    }) &&
    !appContext.hasRight({ objectCode: "event", actionType: "view" })
  ) {
    return (
      <AppLayout>
        <div>Access restricted.</div>
      </AppLayout>
    );
  }

  if (
    accessRight?.objectCode === "compensation_event" &&
    appContext.activeEntity &&
    appContext.entityUsers &&
    !appContext.hasRight({
      objectCode: "compensation_event",
      actionType: "view",
    }) &&
    appContext.hasRight({ objectCode: "event", actionType: "view" })
  ) {
    window.location.href = `/entities/${appContext.activeEntity.id}/site-events`;
  }

  if (
    keycloak?.authenticated &&
    (!requireEntityReady ||
      (appContext.activeEntity &&
        appContext.entityUsers &&
        ((accessRight?.objectCode !== "event" &&
          accessRight?.objectCode !== "compensation_event") ||
          appContext.customizedScreens.length > 0) &&
        Object.keys(appContext.libraries).length > 0 &&
        appContext.dictionaries.length > 0))
  ) {
    return (
      <AppLayout>
        <AppErrorBoundary title="Route">
          {accessRight ? (
            <AccessVisible
              objectCode={accessRight.objectCode}
              actionType={accessRight.actionType}
            >
              {component}
            </AccessVisible>
          ) : (
            component
          )}
        </AppErrorBoundary>
      </AppLayout>
    );
  }

  setTimeout(() => {
    if (!keycloak?.authenticated) {
      notify.error(
        "Cannot connect to authentication server. Please try refreshing your browser or contact support."
      );
    }
  }, 15000);
  return (
    <NxpLayoutGlobal>
      <NxpAuthSpinner />
    </NxpLayoutGlobal>
  );
};

export default AppProtectedPage;
