import React, { useEffect } from "react";

import AppButton, { AppButtonType } from "../../../app/components/app-button";
import AppButtonSelector from "../../../app/components/app-button-selector";
import AppHeader from "../../../app/components/app-header";
import { VocCustomUi, VocScreenNameEnums } from "../../../app/types";
import DataTable from "../../components/data-table/DataTable";
import QuestionGroups from "../../components/question-group/QuestionGroups";
import { SelectedUiReducerAction } from "../../selected-ui-reducer";
import { CustomizationDetailsSection } from "../customization-home/CustomizationContainer";

import { useTranslation } from "react-i18next";
import { siteEventDefaultColumns } from "../../../app/utils/site-event-default-columns";
import "../../Customization.scss";

interface SiteEventCustomizationProps {
  entityId: string;
  selectedUi: VocCustomUi;
  screenData: VocCustomUi[];
  onSelectedUiChange: (screenName: string) => void;
  onSelectedUiDispatch: (action: SelectedUiReducerAction) => void;
  onSelectedUiSave: () => void;
  onScreenDataRefresh: () => void;
  onActionSectionChange: (section?: CustomizationDetailsSection) => void;
}

const SiteEventCustomization: React.FC<SiteEventCustomizationProps> = ({
  entityId,
  selectedUi,
  screenData,
  onSelectedUiChange,
  onSelectedUiDispatch,
  onSelectedUiSave,
  onScreenDataRefresh,
  onActionSectionChange,
}) => {
  useEffect(() => {
    // select register as default when screen data is ready
    onSelectedUiChange("site_event_register");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    onSelectedUiChange(selectedUi.screen.name);
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (selectedUi) {
      selectedUi.layout.forEach((item) => {
        const defaultItem = siteEventDefaultColumns.find(
          (col) => col.key === item.key
        );
        if (defaultItem) {
          item.name = t(defaultItem.name);
        }
      });
    }
  }, [selectedUi, t]);
  return selectedUi ? (
    <>
      <AppHeader
        localeResourceId={"voc.customization.siteEventCustomization"}
      />
      <div className="voc-customization">
        <AppButtonSelector
          items={[
            {
              label: t("voc.customization.siteEventRegister"),
              value: "site_event_register",
            },
            {
              label: t("voc.customization.siteEventSummary"),
              value: "site_event_summary",
            },
          ]}
          value={selectedUi.screen.name}
          onChange={onSelectedUiChange}
        />
        <AppButton
          appButtonType={AppButtonType.Basic}
          className="back-button"
          onClick={() => onActionSectionChange(undefined)}
        >
          {t("common.button.back")}
        </AppButton>
        {selectedUi.screen.name ===
          VocScreenNameEnums.SITE_EVENT_REGISTER_SCREEN && (
          <DataTable
            selectedUi={selectedUi}
            onSelectedUiDispatch={onSelectedUiDispatch}
            onSelectedUiSave={onSelectedUiSave}
            onCancel={handleCancel}
          />
        )}
        {selectedUi.screen.name ===
          VocScreenNameEnums.SITE_EVENT_SUMMARY_SCREEN && (
          <QuestionGroups
            allowHideAndReorder={true}
            allowRename={true}
            allowCustomField={true}
            selectedUi={selectedUi}
            screenData={screenData}
            onSelectedUiDispatch={onSelectedUiDispatch}
            onSelectedUiSave={onSelectedUiSave}
            onCancel={handleCancel}
            onScreenDataRefresh={onScreenDataRefresh}
          />
        )}
      </div>
    </>
  ) : null;
};

export default SiteEventCustomization;
