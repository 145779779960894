import React from "react";
import { useTranslation } from "react-i18next";

import exportExcelSvg from "../../images/export-excel-icon.svg";
import AppButton from "../app-button";

import "./AppExportExcelButton.scss";

interface AppExportExcelButtonProps {
  onExportExcel: () => void;
}

function AppExportExcelButton(props: AppExportExcelButtonProps) {
  const { t } = useTranslation();

  return (
    <AppButton className="voc-export-btn" onClick={props.onExportExcel}>
      <img src={exportExcelSvg} className="export-icon" alt="" />
      {t("voc.common.exportExcel")}
    </AppButton>
  );
}

export default AppExportExcelButton;
