import { cloneDeep } from "lodash";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "antd";
import AppCard from "../../../app/components/app-card/AppCard";
import AppDataList from "../../../app/components/app-data-list/AppDataList";
import AppFieldBlock from "../../../app/components/app-field-block/AppFieldBlock";
import { VocEventFile, VocNotifications } from "../../../app/types";
import { EditingPanel } from "./NotificationsTabPage";

interface NotificationTabPanelContentProps {
  title: string;
  notificationType: EditingPanel;
  isEditing: boolean;
  setIsEditingNotifications: React.Dispatch<React.SetStateAction<EditingPanel>>;
  saveCallback: Function;
  cancelCallback: Function;
  isEditable: boolean;
  displayNotifications: VocNotifications;
  setDisplayNotifications: React.Dispatch<
    React.SetStateAction<VocNotifications>
  >;
  isFollowUpNotRequired: boolean;
  onSetIsFollowUpNotRequired: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationTabPanelContent: React.FC<
  NotificationTabPanelContentProps
> = (props) => {
  const {
    notificationType,
    title,
    isEditing,
    setIsEditingNotifications,
    saveCallback,
    cancelCallback,
    isEditable,
    displayNotifications,
    setDisplayNotifications,
    isFollowUpNotRequired,
    onSetIsFollowUpNotRequired,
  } = props;
  const { t } = useTranslation();

  const setDate = useCallback(
    (key: any, fieldLabel: string, newValue: Date) => {
      if (
        fieldLabel ===
        `${t(
          "voc.eventPage.notifications.actualFirstNotification"
        )}_${notificationType}`
      ) {
        setDisplayNotifications((prevDisplayNotifications) => {
          return {
            ...prevDisplayNotifications,
            firstNotification: {
              ...prevDisplayNotifications?.firstNotification,
              actualDate: newValue,
            },
          };
        });
      }
      if (
        fieldLabel ===
        `${t(
          "voc.eventPage.notifications.actualFinalNotification"
        )}_${notificationType}`
      ) {
        setDisplayNotifications((prevDisplayNotifications) => {
          return {
            ...prevDisplayNotifications,
            finalNotification: {
              ...prevDisplayNotifications?.finalNotification,
              actualDate: newValue,
            },
          };
        });
      }
    },
    [notificationType, setDisplayNotifications, t]
  );

  const setString = useCallback(
    (key: any, fieldLabel: string, newValue: string) => {
      if (
        fieldLabel ===
        `${t("voc.eventPage.notifications.comment")}_${notificationType}_FIRST`
      ) {
        setDisplayNotifications((prevDisplayNotifications) => {
          return {
            ...prevDisplayNotifications,
            firstNotification: {
              ...prevDisplayNotifications?.firstNotification,
              comment: newValue,
            },
          };
        });
      }
      if (
        fieldLabel ===
        `${t("voc.eventPage.notifications.comment")}_${notificationType}_FINAL`
      ) {
        setDisplayNotifications((prevDisplayNotifications) => {
          return {
            ...prevDisplayNotifications,
            finalNotification: {
              ...prevDisplayNotifications?.finalNotification,
              comment: newValue,
            },
          };
        });
      }
    },
    [notificationType, setDisplayNotifications, t]
  );

  const setFirstFinalFile = React.useCallback(
    (key: any, fieldLabel: string, newFile: VocEventFile[]) => {
      if (
        fieldLabel ===
        `${t(
          "voc.eventPage.notifications.referenceFirstNotification"
        )}_${notificationType}`
      ) {
        setDisplayNotifications((prevDisplayNotifications) => {
          return {
            ...prevDisplayNotifications,
            firstNotification: {
              ...prevDisplayNotifications?.firstNotification,
              attachments: newFile,
            },
          };
        });
      }
      if (
        fieldLabel ===
        `${t(
          "voc.eventPage.notifications.referenceFinalNotification"
        )}_${notificationType}`
      ) {
        setDisplayNotifications((prevDisplayNotifications) => {
          return {
            ...prevDisplayNotifications,
            finalNotification: {
              ...prevDisplayNotifications?.finalNotification,
              attachments: newFile,
            },
          };
        });
      }
    },
    [notificationType, setDisplayNotifications, t]
  );

  const handleSetFollowUpFiles = useCallback(
    (file: any, fileRef: any) => {
      setDisplayNotifications((prevDisplayNotifications: any) => {
        const newDisplayNotifications = cloneDeep(prevDisplayNotifications);
        if (
          newDisplayNotifications?.followUpNotifications?.[fileRef]
            ?.fieldDataArray?.[2]
        ) {
          newDisplayNotifications.followUpNotifications[
            fileRef
          ].fieldDataArray[2].fieldData = file;
        }
        return newDisplayNotifications;
      });
    },
    [setDisplayNotifications]
  );

  const handleSetFollowUpNotifications = useCallback(
    (items: any) => {
      setDisplayNotifications((prevDisplayNotifications: any) => {
        return {
          ...prevDisplayNotifications,
          followUpNotifications: items,
        };
      });
    },
    [setDisplayNotifications]
  );

  const firstNotificationFields = React.useMemo(
    () => [
      {
        label: t("voc.eventPage.notifications.requiredFirstNotification"),
        identifier: "_" + notificationType,
        value: displayNotifications?.firstNotification?.requiredDate,
        type: "date",
        width: "fifth",
        onChangeCallback: null,
        editable: false,
      },
      {
        label: t("voc.eventPage.notifications.actualFirstNotification"),
        identifier: "_" + notificationType,
        value: displayNotifications?.firstNotification?.actualDate,
        type: "date",
        width: "fifth",
        onChangeCallback: setDate,
        editable: true,
      },
      {
        label: t("voc.eventPage.notifications.referenceFirstNotification"),
        identifier: "_" + notificationType,
        value: displayNotifications?.firstNotification?.attachments,
        type: "file",
        width: "fifth",
        onChangeCallback: setFirstFinalFile,
        editable: true,
      },
      {
        label: t("voc.eventPage.notifications.comment"),
        identifier: "_" + notificationType + "_FIRST",
        value: displayNotifications?.firstNotification?.comment,
        type: "string",
        width: "twofifths",
        onChangeCallback: setString,
        editable: true,
      },
    ],
    [
      displayNotifications?.firstNotification?.actualDate,
      displayNotifications?.firstNotification?.attachments,
      displayNotifications?.firstNotification?.comment,
      displayNotifications?.firstNotification?.requiredDate,
      notificationType,
      setDate,
      setFirstFinalFile,
      setString,
      t,
    ]
  );

  const followUpNotificationFields = React.useMemo(
    () => [
      {
        columnName: t("voc.eventPage.notifications.requiredDate"),
        columnWidthClassName: "fifth",
        columnDataType: "date",
        editable: false,
      },
      {
        columnName: t("voc.eventPage.notifications.actualDate"),
        columnWidthClassName: "fifth",
        columnDataType: "date",
        editable: true,
      },
      {
        columnName: t("voc.eventPage.notifications.reference"),
        columnWidthClassName: "fifth",
        columnDataType: "file",
        editable: true,
      },
      {
        columnName: t("voc.eventPage.notifications.comment"),
        columnWidthClassName: "twofifths",
        columnDataType: "string",
        editable: true,
      },
    ],
    [t]
  );

  const finalNotificationFields = React.useMemo(
    () => [
      {
        label: t("voc.eventPage.notifications.requiredFinalNotification"),
        identifier: "_" + notificationType,
        value: displayNotifications?.finalNotification?.requiredDate,
        type: "date",
        width: "fifth",
        onChangeCallback: null,
        editable: false,
      },
      {
        label: t("voc.eventPage.notifications.actualFinalNotification"),
        identifier: "_" + notificationType,
        value: displayNotifications?.finalNotification?.actualDate,
        type: "date",
        width: "fifth",
        onChangeCallback: setDate,
        editable: true,
      },
      {
        label: t("voc.eventPage.notifications.referenceFinalNotification"),
        identifier: "_" + notificationType,
        value: displayNotifications?.finalNotification?.attachments,
        type: "file",
        width: "fifth",
        onChangeCallback: setFirstFinalFile,
        editable: true,
      },
      {
        label: t("voc.eventPage.notifications.comment"),
        value: displayNotifications?.finalNotification?.comment,
        identifier: "_" + notificationType + "_FINAL",
        type: "string",
        width: "twofifths",
        onChangeCallback: setString,
        editable: true,
      },
    ],
    [
      displayNotifications?.finalNotification?.actualDate,
      displayNotifications?.finalNotification?.attachments,
      displayNotifications?.finalNotification?.comment,
      displayNotifications?.finalNotification?.requiredDate,
      notificationType,
      setDate,
      setFirstFinalFile,
      setString,
      t,
    ]
  );

  return (
    <AppCard
      title={title}
      canEdit={isEditable}
      isEditing={isEditing}
      editCallback={() => setIsEditingNotifications(notificationType)}
      cancelCallback={cancelCallback}
      saveCallback={saveCallback}
    >
      {displayNotifications?.firstNotification?.requiredDate ? (
        <>
          <div className="header subheader">
            <div className="title">
              {t("voc.eventPage.notifications.firstNotificationHeader")}
            </div>
          </div>
          <AppFieldBlock editing={isEditing} fields={firstNotificationFields} />
        </>
      ) : null}
      {displayNotifications?.followUpNotifications?.length > 0 ? (
        <>
          <div className="divider"></div>
          <div className="header subheader">
            <div className="title">
              {t("voc.eventPage.notifications.followUpNotificationHeader")}
            </div>
          </div>
          <AppDataList
            permissionCode="compensation_event"
            defaultSortDirection={"asc"} // required date asc
            editing={isEditing}
            canDeleteLastItem={false}
            columns={followUpNotificationFields}
            items={displayNotifications?.followUpNotifications}
            editSetStateHook={handleSetFollowUpNotifications}
            handleCustomSetFiles={handleSetFollowUpFiles}
            // setFiles={setFiles} // we use handleCustomSetFiles
          ></AppDataList>
          {isEditing && (
            <div className="follow-up-required-not-checkbox">
              <Checkbox
                onChange={(e) => onSetIsFollowUpNotRequired(e.target.checked)}
                checked={isFollowUpNotRequired}
              >
                {t("voc.eventPage.notifications.followUpNotRequired")}
              </Checkbox>
            </div>
          )}
        </>
      ) : null}

      {displayNotifications?.finalNotification?.requiredDate ? (
        <>
          <div className="header subheader">
            <div className="title">
              {t("voc.eventPage.notifications.finalNotificationHeader")}
            </div>
          </div>
          <AppFieldBlock editing={isEditing} fields={finalNotificationFields} />
        </>
      ) : null}
    </AppCard>
  );
};

export default NotificationTabPanelContent;
