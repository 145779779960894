import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AppButton, {
  AppButtonType,
} from "../../../../app/components/app-button";
import { BatchUpdateEvent } from "../RecordsTabLayout";
import BatchUpdate from ".";

import "./BatchUpdateButton.scss";

interface BatchUpdateButtonProps {
  onStatusRecordsRefresh: () => void;
  events: BatchUpdateEvent[];
}

const BatchUpdateButton: React.FC<BatchUpdateButtonProps> = ({
  onStatusRecordsRefresh,
  events,
}) => {
  const { t } = useTranslation();
  const [showBatchUpdateModal, setShowBatchUpdateModal] = useState(false);
  const handleBatchUpdateClick = () => {
    setShowBatchUpdateModal(true);
  };
  const handleModalClose = () => {
    setShowBatchUpdateModal(false);
  };
  return (
    <>
      <AppButton
        appButtonType={AppButtonType.Link}
        className="voc-records-tab-batch-update"
        text={t("voc.eventPage.records.batchUpdate")}
        onClick={handleBatchUpdateClick}
      />
      {showBatchUpdateModal && (
        <BatchUpdate
          onStatusRecordsRefresh={onStatusRecordsRefresh}
          showBatchUpdateModal={showBatchUpdateModal}
          onModalClose={handleModalClose}
          events={events}
        />
      )}
    </>
  );
};

export default BatchUpdateButton;
