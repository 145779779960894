import { useMemo } from "react";

import { useKeycloak } from "@react-keycloak/web";

import { ServiceConfig } from "../utils/backend/api-request";
import useRouteParams from "./useRouteParams";

const useServiceConfig = (): ServiceConfig => {
  const { keycloak } = useKeycloak();
  const routeParams = useRouteParams();

  const serviceConfig = useMemo(
    () => ({
      entityId: routeParams.entityId,
      token: keycloak?.token,
      apiBaseUrl: process.env.REACT_APP_API_BASE_URL || "",
      userDirectoryServiceApiBaseUrl:
        process.env.REACT_APP_QC_CORE_SERVICE_API_BASE_URL || "",
      entityServiceApiBaseUrl:
        process.env.REACT_APP_QC_CORE_SERVICE_API_BASE_URL || "",
      fileServiceApiBaseUrl:
        process.env.REACT_APP_QC_CORE_SERVICE_API_BASE_URL || "",
      qcAppBaseUrl: process.env.REACT_APP_QC_CORE_APP_BASE_URL || "",
      timezoneHourAdjustment: 0,
    }),
    [routeParams.entityId, keycloak?.token]
  );

  return serviceConfig;
};

export default useServiceConfig;
