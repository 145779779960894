import React, { useState } from "react";

import {
  notify,
  NxpButton,
  NxpFormGrid,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import { DemoUserForm, demoUserFormItems } from "./demoUserForm";

const formSchema = yup.object({
  firstName: yup.string().nullable().required("First Name required."),
  lastName: yup.string(),
  birthday: yup.date().required("Birthday required."),
});

interface FormGridModalProps {
  visible: boolean;
  onCancel: () => void;
}

const FormGridModal: React.FC<FormGridModalProps> = ({ visible, onCancel }) => {
  const [userForm, setUserForm] = useState<DemoUserForm>({
    firstName: "",
    lastName: "Chan",
    age: 18,
    gender: "m",
    skills: [],
    isDead: false,
    kids: [],
    birthday: new Date(),
  });

  const handleSaveValidated = async () => {
    notify.actionCompleted();
  };

  const [validationError, , , saveWithValidate] = useYupValidate<
    Pick<DemoUserForm, "firstName" | "lastName" | "birthday">
  >(userForm, formSchema, handleSaveValidated);

  const handleSave = () => {
    return (
      Object.values(saveWithValidate(undefined)).filter(
        (val) => val !== undefined
      ).length === 0
    );
  };

  const handleFormGridStateChange = (
    fieldName: keyof DemoUserForm,
    value: unknown
  ) => {
    console.log("ccccccccccccccc", fieldName, value);
    if (
      fieldName === "age" &&
      Number.isFinite(value) &&
      (value as number) > 100
    ) {
      setUserForm((prevState) => ({
        ...prevState,
        age: 100,
      }));
      return;
    }

    if (fieldName === "firstName" && value === "peter") {
      setUserForm((prevState) => ({
        ...prevState,
        firstName: value,
        lastName: "Hung",
      }));
      return;
    }

    setUserForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    if (handleSave()) {
      onCancel();
    }
  };

  return (
    <NxpModal
      title="User Form"
      visible={visible}
      onCancel={onCancel}
      footer={
        <>
          <NxpButton type="default" onClick={onCancel}>
            Cancel
          </NxpButton>
          <NxpButton onClick={handleModalSave}>Save</NxpButton>
        </>
      }
      width="medium"
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={demoUserFormItems}
        formState={userForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default FormGridModal;
