import {
  DeleteOutlined,
  LinkOutlined,
  LockFilled,
  UploadOutlined,
} from "@ant-design/icons";
import {
  DateFormatPattern,
  formatDate,
  NxpButtonDeleteConfirm,
  NxpDatePicker,
  NxpTableColumnProps,
  NxpTooltip,
  sorterForDate,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Button, Input, Select, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import { isAfter, isBefore } from "date-fns";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { VocSupportDocument } from "../../../../../../app/types";
import { getUserDisplayDateFormat } from "../../../../../../app/utils/mappers";
import { recordStatus } from "../../../new-document/new-document-form-type";
import {
  getSiteRecordStatuses,
  isRowDisplayOnly,
  relatedEventDisplay,
} from "../../helpers/supporting-helper";

export const useGeneralSupportingColumns = ({
  editing,
  eventId,
  handleInputChange,
  handleFileChange,
  handleRemoveFile,
  deleteDocument,
}) => {
  const { t } = useTranslation();
  const getStatusRender = useCallback(
    (status: string) => {
      const statusOption = getSiteRecordStatuses(t).find(
        (option) => option.enumValue === status
      );
      return statusOption ? statusOption.render : null;
    },
    [t]
  );
  const columns: NxpTableColumnProps<VocSupportDocument>[] = useMemo(
    () => [
      {
        title: t("voc.eventPage.summary.subject"),
        dataIndex: ["details", "subject"],
        key: "subject",
        width: "8%",
        sorter: (a, b) => sorterForString(a.details.subject, b.details.subject),
        render: (text, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <Input
                type="text"
                value={record.details.subject}
                onChange={(e) =>
                  handleInputChange(record.id, "subject", e.target.value)
                }
              />
            );
          } else {
            return text;
          }
        },
      },
      {
        title: t("voc.eventPage.supporting.dateFrom"),
        dataIndex: ["details", "fromDate"],
        key: "fromDate",
        width: "8%",
        sorter: (a, b) => sorterForDate(a.details.fromDate, b.details.fromDate),
        render: (date, record) =>
          editing && !isRowDisplayOnly(record, eventId) ? (
            <NxpDatePicker
              format={getUserDisplayDateFormat()}
              value={record.details.fromDate}
              disabledDate={(current) =>
                (current &&
                  (isBefore(new Date(), current.toDate()) ||
                    isAfter(current.toDate(), new Date()))) ||
                isAfter(current.toDate(), record.details.toDate)
              }
              onChange={(value) => {
                handleInputChange(record.id, "fromDate", value);
              }}
            />
          ) : (
            formatDate(new Date(date), getUserDisplayDateFormat())
          ),
      },
      {
        title: t("voc.eventPage.supporting.dateTo"),
        dataIndex: ["details", "toDate"],
        key: "toDate",
        width: "8%",
        sorter: (a, b) => sorterForDate(a.details.toDate, b.details.toDate),
        render: (date, record) =>
          editing && !isRowDisplayOnly(record, eventId) ? (
            <NxpDatePicker
              format={getUserDisplayDateFormat()}
              value={record.details.toDate}
              disabledDate={(current) =>
                current &&
                (isBefore(current.toDate(), record.details.fromDate) ||
                  isAfter(current.toDate(), new Date()))
              }
              onChange={(value) =>
                handleInputChange(record.id, "toDate", value)
              }
            />
          ) : (
            formatDate(new Date(date), getUserDisplayDateFormat())
          ),
      },
      {
        title: t("voc.eventPage.supporting.relatedEvent"),
        dataIndex: ["details", "relatedEvent"],
        key: "relatedEvent",
        width: "8%",
        sorter: (a, b) =>
          sorterForString(
            relatedEventDisplay(
              a.compensationEventSerial,
              a.compensationEventTitle,
              a.siteEventSerial,
              a.siteEventTitle
            ),
            relatedEventDisplay(
              b.compensationEventSerial,
              b.compensationEventTitle,
              b.siteEventSerial,
              b.siteEventTitle
            )
          ),
        render: (text, record) => {
          return (
            <Button
              type="link"
              onClick={
                !record.siteEventId
                  ? () =>
                      window.open(`./../compensation-events/${record.eventId}`)
                  : () => window.open(`./../site-events/${record.siteEventId}`)
              }
            >
              {relatedEventDisplay(
                record.compensationEventSerial,
                record.compensationEventTitle,
                record.siteEventSerial,
                record.siteEventTitle
              )}
            </Button>
          );
        },
      },
      {
        title: t("voc.eventPage.summary.comment"),
        dataIndex: "comment",
        key: "comment",
        width: "8%",
        sorter: (a, b) => sorterForString(a.comment, b.comment),
        render: (text, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <TextArea
                value={record.comment}
                onChange={(e) =>
                  handleInputChange(record.id, "comment", e.target.value)
                }
              />
            );
          } else {
            return text;
          }
        },
      },
      {
        title: t("voc.eventPage.supporting.file"),
        dataIndex: "file",
        key: "file",
        width: "5%",
        render: (file, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            switch (record.documentFiles?.length) {
              case 0:
                return (
                  <Upload
                    showUploadList={false}
                    beforeUpload={(file) => {
                      handleFileChange(file, record);
                      return false;
                    }}
                  >
                    <Button icon={<UploadOutlined />} />
                  </Upload>
                );
              case 1:
                return (
                  <Upload
                    fileList={record.documentFiles.map((file) => ({
                      uid: file.assetId,
                      name: file.fileName,
                      status: "done",
                      url: file.url,
                    }))}
                    onRemove={() => handleRemoveFile(record)}
                  >
                    {record?.documentFiles.length < 1 && (
                      <Button icon={<UploadOutlined />} />
                    )}
                  </Upload>
                );
              default:
                return null;
            }
          } else {
            return record.documentFiles && record.documentFiles.length > 0 ? (
              <a
                href={record.documentFiles[0].url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {record.documentFiles[0]?.fileName}
              </a>
            ) : null;
          }
        },
      },
      {
        title: t("voc.eventPage.supporting.link"),
        dataIndex: ["details", "link"],
        key: "link",
        width: "8%",
        render: (link, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <Input
                type="text"
                value={record.details.link}
                onChange={(e) =>
                  handleInputChange(record.id, "link", e.target.value)
                }
              />
            );
          } else {
            const getClickableLink = () => {
              return record.details.link.startsWith("http://") ||
                record.details.link.startsWith("https://")
                ? record.details.link
                : `https://${link}`;
            };
            return record.details.link ? (
              <NxpTooltip title={record.details.link}>
                <a
                  href={getClickableLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkOutlined />
                </a>
              </NxpTooltip>
            ) : null;
          }
        },
      },
      {
        title: t("voc.eventPage.supporting.recordStatus"),
        dataIndex: "recordStatus",
        key: "recordStatus",
        width: "8%",
        sorter: (a, b) =>
          sorterForString(a.details.recordStatus, b.details.recordStatus),
        render: (status, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <Select
                options={recordStatus.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                value={record.details.recordStatus}
                placeholder={t("voc.common.pleaseSelect")}
                style={{ width: "100%" }}
                allowClear
                onChange={(value) =>
                  handleInputChange(record.id, "recordStatus", value)
                }
              />
            );
          } else {
            return getStatusRender(record.details.recordStatus);
          }
        },
        placeholder: t("voc.common.pleaseSelect"),
      },
      {
        title: t("voc.common.updatedOn"),
        dataIndex: "updatedOn",
        key: "updatedOn",
        width: "10%",
        sorter: (a, b) => sorterForDate(a.updatedOn, b.updatedOn),
        extraFormatOptions: {
          type: "date",
          format: DateFormatPattern.date,
        },
        render: (date) =>
          formatDate(new Date(date), getUserDisplayDateFormat()),
      },
    ],
    [
      editing,
      eventId,
      getStatusRender,
      handleFileChange,
      handleInputChange,
      handleRemoveFile,
      t,
    ]
  );

  if (editing) {
    columns.unshift({
      title: t("voc.appDataListComponent.delete"),
      key: "delete",
      width: "3%",
      render: (text, record) => {
        if (isRowDisplayOnly(record, eventId)) {
          return (
            <NxpTooltip
              title={`${
                t("voc.eventPage.supporting.siteDocStatus.editInSiteEvent") +
                record.siteEventSerial
              }`}
            >
              <LockFilled />
            </NxpTooltip>
          );
        }
        return (
          <NxpButtonDeleteConfirm
            type="link"
            danger
            icon={<DeleteOutlined />}
            onConfirm={() => deleteDocument(record)}
          />
        );
      },
    });
  }

  return columns;
};
