import { useRef } from "react";
import { useParams } from "react-router-dom";

import { useKeycloak } from "@react-keycloak/web";
import i18n from "i18next";

import { getAllCustomizedScreens } from "../../../customization/customization-services";
import { getLibraries } from "../../../libraries/libraries-services";
import useAppContext from "../../hooks/useAppContext";
import {
  getCPCSUsers,
  getEntityInfo,
  getEntityUsers,
  getMe,
  getMyRights,
} from "../../services/app";
import { getDictionaryList } from "../../services/dictionary";
import {
  createMyUserPreference,
  getMyUserPreference,
} from "../../services/user-preference-services";

// for setting cache data in app context based on route param
const useRouteAppContextUpdate = () => {
  const { keycloak } = useKeycloak();

  const {
    activeEntity,
    activeUser,
    serviceConfig,
    onAppContextCacheItemUpdate,
  } = useAppContext();
  const loadingEntityIdRef = useRef("");

  const loadingLanguage = useRef(false);

  const { entityId } = useParams<"entityId">();

  if (!serviceConfig.token) return;
  if (entityId && keycloak) {
    if (activeUser && !loadingLanguage.current) {
      loadingLanguage.current = true;
      getMyUserPreference(serviceConfig).then((userPreference) => {
        const languagePreference = userPreference.find(
          (u) => u.application === "entity" && u.parameter === "language"
        );
        if (languagePreference) {
          i18n.changeLanguage(languagePreference.value);
        } else {
          createMyUserPreference(serviceConfig, {
            application: "entity",
            parameter: "language",
            value: "en",
          }).then((newUserPreference) => {
            i18n.changeLanguage(newUserPreference.value);
          });
        }
      });
    }

    if (
      activeEntity?.id !== entityId &&
      loadingEntityIdRef.current !== entityId
    ) {
      loadingEntityIdRef.current = entityId;

      getEntityInfo(serviceConfig).then((entity) => {
        onAppContextCacheItemUpdate("activeEntity", entity);
      });

      getMe(serviceConfig).then((user) => {
        onAppContextCacheItemUpdate("activeUser", user);
      });

      // getUserRightList(serviceConfig).then((userRightList) => {
      //   onAppContextCacheItemUpdate("userRightList", userRightList);
      // });

      getCPCSUsers(serviceConfig).then((users) => {
        onAppContextCacheItemUpdate("users", users);
      });

      getEntityUsers(serviceConfig).then((users) => {
        onAppContextCacheItemUpdate("entityUsers", users);
      });

      getLibraries(serviceConfig).then((libs) => {
        onAppContextCacheItemUpdate("libraries", libs);
      });

      getDictionaryList(serviceConfig).then((dicts) => {
        onAppContextCacheItemUpdate("dictionaries", dicts);
      });

      getAllCustomizedScreens(serviceConfig).then((customizedScreens) => {
        onAppContextCacheItemUpdate("customizedScreens", customizedScreens);
      });

      getMyRights(serviceConfig).then((myRightList) => {
        onAppContextCacheItemUpdate("myRightList", myRightList);
      });
    }
  }
};

export default useRouteAppContextUpdate;
