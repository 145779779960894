import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import AppButton, { AppButtonType } from "../app-button";

import "./AppListBuilderBase.scss";

export interface AppListBuilderListItemProps {
  key: string;
  label: ReactNode;
}

export interface AppListBuilderSelection {
  isLeftItem: boolean;
  itemIndex: number;
}
interface AppListBuilderBaseProps<T> {
  leftHeader: ReactNode;
  leftItems: T[];
  rightHeader: ReactNode;
  rightItems: T[];
  listBuilderSelection?: AppListBuilderSelection;
  onMoveToRight: (item: T, fromIndex: number, toIndex?: number) => void;
  onMoveToLeft: (item: T, fromIndex: number, toIndex?: number) => void;
  onReorder: (item: T, fromIndex: number, toIndex?: number) => void;
  onItemPropsRender: (item: T) => AppListBuilderListItemProps;
  onSortAscending: (a: T, b: T) => number;
  onSortDescending: (a: T, b: T) => number;
  onSave: () => void;
  onCancel: () => void;
}

function AppListBuilderBase<T>(props: AppListBuilderBaseProps<T>) {
  const {
    leftHeader,
    leftItems,
    rightHeader,
    rightItems,
    listBuilderSelection,
    onMoveToRight,
    onMoveToLeft,
    onReorder,
    onSortAscending,
    onSortDescending,
    onItemPropsRender,
    onSave,
    onCancel,
  } = props;

  const { t } = useTranslation();

  // for adding scroll bottom behavior after add click
  const rightListRef = useRef<HTMLUListElement>();

  const [sorting, setSorting] = useState<{
    type: "default" | "a-z" | "z-a";
    sortedList: T[];
  }>({
    type: "default",
    sortedList: leftItems,
  });

  const [itemSelection, setItemSelection] = useState<{
    isLeftItem: boolean;
    itemIndex: number;
  }>();

  useEffect(() => {
    setItemSelection(listBuilderSelection);
  }, [listBuilderSelection]);

  useEffect(() => {
    switch (sorting.type) {
      case "default":
        setSorting((prevState) => ({
          ...prevState,
          sortedList: leftItems,
        }));
        break;
      case "a-z":
        setSorting((prevState) => ({
          ...prevState,
          sortedList: [...leftItems].sort(onSortAscending),
        }));
        break;
      case "z-a":
        setSorting((prevState) => ({
          ...prevState,
          sortedList: [...leftItems].sort(onSortDescending),
        }));
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting.type, leftItems]);

  const handleSortClick = () => {
    if (itemSelection?.isLeftItem) {
      setItemSelection(undefined);
    }
    switch (sorting.type) {
      case "default":
        setSorting((prevState) => ({
          ...prevState,
          type: "a-z",
        }));
        break;
      case "a-z":
        setSorting((prevState) => ({
          ...prevState,
          type: "z-a",
        }));
        break;
      case "z-a":
        setSorting((prevState) => ({
          ...prevState,
          type: "default",
        }));
        break;
    }
  };

  const handleAddClick = () => {
    setItemSelection(undefined);
    onMoveToRight(leftItems[itemSelection.itemIndex], itemSelection.itemIndex);
    // run in next tick to ensure reaching bottom
    setTimeout(() =>
      rightListRef.current.scrollTo(0, rightListRef.current.scrollHeight)
    );
  };

  const handleRemoveClick = () => {
    setItemSelection(undefined);
    onMoveToLeft(rightItems[itemSelection.itemIndex], itemSelection.itemIndex);
  };

  const handleRemoveDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const isFromLeft = e.dataTransfer.getData("isFromLeft") === true.toString();
    if (!isFromLeft) {
      setItemSelection(undefined);
      onMoveToLeft(
        rightItems[itemSelection.itemIndex],
        itemSelection.itemIndex
      );
      e.currentTarget.className = e.currentTarget.className.replace(
        / drag-over/g,
        ""
      );
    }
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLDivElement | HTMLLIElement>
  ) => {
    e.preventDefault();
    if (!e.currentTarget.className.includes(" drag-over")) {
      e.currentTarget.className += " drag-over";
    }
  };

  const handleDragLeave = (
    e: React.DragEvent<HTMLDivElement | HTMLLIElement>
  ) => {
    e.currentTarget.className = e.currentTarget.className.replace(
      / drag-over/g,
      ""
    );
  };

  const handleItemDragStart = (
    e: React.DragEvent<HTMLLIElement>,
    fromIndex: number,
    isFromLeft: boolean
  ) => {
    if (isFromLeft && sorting.type !== "default") {
      // reset to index of original array instead of sorted one
      const findFromIndex = leftItems.indexOf(sorting.sortedList[fromIndex]);
      e.dataTransfer.setData("fromIndex", findFromIndex.toString());
    } else {
      e.dataTransfer.setData("fromIndex", fromIndex.toString());
    }

    e.dataTransfer.setData("isFromLeft", isFromLeft.toString());
  };

  const handleItemDrop = (
    e: React.DragEvent<HTMLLIElement>,
    toIndex: number
  ) => {
    const fromIndex = Number(e.dataTransfer.getData("fromIndex"));
    const isFromLeft = e.dataTransfer.getData("isFromLeft") === true.toString();
    e.currentTarget.className = e.currentTarget.className.replace(
      / drag-over/g,
      ""
    );

    if (isFromLeft) {
      onMoveToRight(leftItems[fromIndex], fromIndex, toIndex);
    } else {
      onReorder(rightItems[fromIndex], fromIndex, toIndex);
    }
  };

  const handleCancel = () => {
    setItemSelection(undefined);
    onCancel();
  };

  return (
    <div className="app-list-builder-base">
      <div
        className="builder-list-wrapper"
        onDrop={handleRemoveDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <h3>
          {leftHeader}
          <AppButton
            onClick={handleSortClick}
            appButtonType={AppButtonType.Link}
          >
            {t("voc.common.sort")}:{" "}
            {sorting.type === "default"
              ? t("voc.common.default")
              : sorting.type}
          </AppButton>
        </h3>
        <ul>
          {sorting.sortedList.map((item, idx) => {
            const itemProps = onItemPropsRender(item);
            return (
              <li
                draggable={true}
                onDragStart={(e: React.DragEvent<HTMLLIElement>) =>
                  handleItemDragStart(e, idx, true)
                }
                className={
                  itemSelection?.isLeftItem === true &&
                  itemSelection?.itemIndex === idx
                    ? "selected"
                    : undefined
                }
                onMouseDown={() =>
                  setItemSelection({ isLeftItem: true, itemIndex: idx })
                }
                key={itemProps.key}
              >
                {itemProps.label}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="builder-buttons">
        <AppButton
          onClick={handleAddClick}
          disabled={itemSelection?.isLeftItem !== true}
        >
          + {t("voc.common.add")}
        </AppButton>
        <AppButton
          onClick={handleRemoveClick}
          disabled={itemSelection?.isLeftItem !== false}
        >
          {t("voc.common.remove")}
        </AppButton>
      </div>
      <div className="builder-list-wrapper">
        <h3>{rightHeader}</h3>
        <ul className="with-list-buttons" ref={rightListRef}>
          {rightItems.map((item, idx) => {
            const itemProps = onItemPropsRender(item);
            return (
              <li
                draggable={true}
                onDrop={(e: React.DragEvent<HTMLLIElement>) =>
                  handleItemDrop(e, idx)
                }
                onDragStart={(e: React.DragEvent<HTMLLIElement>) =>
                  handleItemDragStart(e, idx, false)
                }
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                className={
                  itemSelection?.isLeftItem === false &&
                  itemSelection?.itemIndex === idx
                    ? "selected"
                    : undefined
                }
                onMouseDown={() => {
                  setItemSelection({ isLeftItem: false, itemIndex: idx });
                }}
                key={itemProps.key}
              >
                {itemProps.label}
              </li>
            );
          })}
          <li
            className="drop-placeholder"
            onDrop={(e: React.DragEvent<HTMLLIElement>) =>
              handleItemDrop(e, undefined)
            }
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
          ></li>
        </ul>
        <div className="list-buttons">
          <AppButton
            onClick={() =>
              onReorder(
                rightItems[itemSelection.itemIndex],
                itemSelection.itemIndex,
                itemSelection.itemIndex - 1
              )
            }
            disabled={
              itemSelection?.isLeftItem !== false ||
              itemSelection.itemIndex === 0
            }
          >
            ▲
          </AppButton>
          <AppButton
            onClick={() =>
              onReorder(
                rightItems[itemSelection.itemIndex],
                itemSelection.itemIndex,
                itemSelection.itemIndex + 2
              )
            }
            disabled={
              itemSelection?.isLeftItem !== false ||
              itemSelection.itemIndex === rightItems.length - 1
            }
          >
            ▼
          </AppButton>
          <div className="right">
            <AppButton
              onClick={handleCancel}
              appButtonType={AppButtonType.Basic}
            >
              {t("voc.common.reset")}
            </AppButton>
            <AppButton onClick={onSave}>{t("voc.common.save")}</AppButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppListBuilderBase;
