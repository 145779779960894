import { VocGetValuesEisReporting } from "../../../../app/types";

export const computeEisA8A14 = (
  state: Pick<
    VocGetValuesEisReporting,
    | "eisA2A6"
    | "in16"
    | "eisA8A14"
    | "eisSecuredProject"
    | "eisStrategyIncludedInPlanning"
  >,
  internalTarget: number
) => {
  const { eisA2A6 } = state;
  if (eisA2A6 || internalTarget) {
    return parseFloat(((internalTarget ?? 0) - (eisA2A6 ?? 0)).toFixed(2));
  }
  return undefined;
};

export const computeEisStrategyIncludedInPlanning = (
  state: Pick<
    VocGetValuesEisReporting,
    | "eisA2A6"
    | "in16"
    | "eisA8A14"
    | "eisSecuredProject"
    | "eisStrategyIncludedInPlanning"
  >,
  internalTarget: number
) => {
  const { eisSecuredProject } = state;
  if (eisSecuredProject || internalTarget) {
    return parseFloat(
      ((internalTarget ?? 0) - (eisSecuredProject ?? 0)).toFixed(2)
    );
  }
  return undefined;
};
