import React from "react";
import { notify } from "@nexploretechnology/nxp-ui";
import useVocServiceAsync from "../../../../app/services/useVocServiceAsync";
import { VocValueRegister } from "../../../../app/types";
import BatchPaymentReferenceModal from "./BatchPaymentReferenceModal";
import useAppContext from "../../../../app/hooks/useAppContext";
import { updateValueRegisterList } from "../../../compensation-event-services";

interface BatchPaymentReferenceContainerProps {
  showModal: boolean;
  valueRegiserList: VocValueRegister[];
  onRefresh: () => void;
  onModalClose: () => void;
}

const BatchPaymentReferenceContainer: React.FC<
  BatchPaymentReferenceContainerProps
> = ({ showModal, valueRegiserList, onRefresh, onModalClose }) => {
  const { serviceConfig } = useAppContext();

  const [updateValuationsAsyncResult, setUpdateValuaionsRequest] =
    useVocServiceAsync<VocValueRegister[]>(serviceConfig.entityId, undefined, {
      onSuccess: () => {
        notify.actionCompleted(); // 'voc.valuesRegister.batchPaymentSuccessful'
        onRefresh();
        onModalClose();
      },
    });

  const handleSave = (paymentReference: string) => {
    valueRegiserList.forEach(
      (item) => (item.paymentReference = paymentReference)
    );
    setUpdateValuaionsRequest(() =>
      updateValueRegisterList(valueRegiserList, serviceConfig)
    );
  };
  return (
    <BatchPaymentReferenceModal
      showModal={showModal}
      recordCount={valueRegiserList.length}
      updateValuationsAsyncResult={updateValuationsAsyncResult}
      onSave={handleSave}
      onClose={onModalClose}
    />
  );
};

export default BatchPaymentReferenceContainer;
