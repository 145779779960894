import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { notify } from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../app/hooks/useAppContext";
import { useValidate } from "../../../../app/hooks/useValidate";
import { getUserDisplayName } from "../../../../app/services/app";
import { VocSiteEventPostRequest } from "../../../../app/types";
import {
  INSTRUCTED_BY_NONE,
  INSTRUCTED_BY_OTHER,
} from "../../../../app/utils/const";
import {
  convertLocationOptions,
  LocationOption,
} from "../../../../app/utils/location";
import { createSiteEvent } from "../../../site-event-services";
import CreateSiteEventModal from "./CreateSiteEventModal";
import { mapDateToString } from "../../../../app/utils/mappers";

interface CreateSiteEventContainerProps {
  showCreateModal: boolean;
  entityId: string;
  onModalClose: () => void;
}

function getValidationSchema() {
  return yup.object().shape({
    subject: yup.string().nullable().required("Subject required."),
    remarks: yup.string().nullable().required("Remarks required."),
    eventSource: yup.string().nullable().required("Source required."),
    reference: yup.string().nullable(),
    dateOfAwareness: yup
      .date()
      .nullable()
      .required("Date of Awareness required."),
    instructedBy: yup
      .string()
      .nullable()
      .required("Instruction Issued By required."),
    instructedByOther: yup.string().nullable(),
  }) as unknown as yup.Schema<Partial<VocSiteEventPostRequest>>;
}

const CreateSiteEventContainer: React.FC<CreateSiteEventContainerProps> = ({
  showCreateModal,
  onModalClose,
  entityId,
}) => {
  const { serviceConfig, libraries, errorHandler, getDictionary } =
    useAppContext();

  const navigate = useNavigate();
  const [form, setForm] = React.useState<Partial<VocSiteEventPostRequest>>(
    () => {
      let today = new Date();
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      return {
        subject: null,
        remarks: null,
        eventSource: null,
        reference: null,
        locations: [],
        instructedBy: null,
        instructionEmpowered: null,
        files: [],
        dateOfAwareness: mapDateToString(today),
      };
    }
  );

  const [locationOptions, setLocationOptions] = React.useState<
    LocationOption[]
  >(() => convertLocationOptions(getDictionary("LOCATION")?.members || []));

  const updateLocationOptions = (locations: string[]) => {
    setLocationOptions((prevState) => {
      const libLocCopy = [...prevState];
      prevState.forEach((libLoc) => {
        libLocCopy.forEach((copy) => {
          if (!copy.childOf) {
            copy.disabled = false;
          }
        });
        if (locations.includes(libLoc.enumValue)) {
          libLocCopy.forEach((copy) => {
            if (
              copy.enumValue === libLoc.enumValue ||
              copy.childOf === libLoc.enumValue ||
              !copy.childOf
            ) {
              copy.disabled = false;
            }
          });
        } else {
          libLocCopy.forEach((copy) => {
            if (copy.childOf === libLoc.enumValue) {
              copy.disabled = true;
            }
          });
        }
      });
      return libLocCopy;
    });
  };

  useEffect(() => {
    if (form?.locations) {
      updateLocationOptions([...form.locations]);
    }
  }, [form]);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleCreateValidated = React.useCallback(async () => {
    try {
      setSaveInProgress(true);
      const siteEvent = await createSiteEvent(form, serviceConfig);
      notify.actionCompleted();
      navigate(`./${siteEvent.id}`);
    } catch (ex) {
      errorHandler(ex, `site event creation`);
    } finally {
      setSaveInProgress(false);
    }
  }, [errorHandler, form, navigate, serviceConfig]);

  const [validationError, , , saveWithValidate] = useValidate<
    Partial<VocSiteEventPostRequest>,
    boolean //context type, eg. addAnother
  >(form, getValidationSchema(), handleCreateValidated);

  const handleFormChange = React.useCallback(
    (changeValues: VocSiteEventPostRequest) => {
      const changeValuesCopy = { ...changeValues };
      if (
        changeValuesCopy.instructedBy === INSTRUCTED_BY_OTHER ||
        changeValuesCopy.instructedBy === INSTRUCTED_BY_NONE
      ) {
        changeValuesCopy.instructionEmpowered = false;
      } else if (
        changeValuesCopy.instructedBy &&
        changeValuesCopy.instructedBy !== INSTRUCTED_BY_OTHER &&
        changeValuesCopy.instructedBy !== INSTRUCTED_BY_NONE
      ) {
        changeValuesCopy.instructionEmpowered = true;
      }
      setForm((prevState) => ({ ...prevState, ...changeValuesCopy }));
    },
    []
  );

  const handleFormSave = () => {
    saveWithValidate(false);
  };

  return (
    <CreateSiteEventModal
      entityId={entityId}
      showCreateModal={showCreateModal}
      onModalClose={onModalClose}
      locationOptions={locationOptions}
      empowerRepsDropdownOptions={
        libraries["VOC_VO_EMPOWER_REPS"]
          ?.filter((o) => !o.disabled)
          .map((o) => {
            const user = o as unknown as Record<string, string>;
            return {
              key: o.value,
              text:
                user.firstName === undefined || user.lastName === undefined
                  ? o.value
                  : getUserDisplayName(user),
            };
          }) || []
      }
      form={form}
      onFormChange={handleFormChange}
      onFormSave={handleFormSave}
      validationError={validationError}
      saveInProgress={saveInProgress}
    />
  );
};

export default CreateSiteEventContainer;
