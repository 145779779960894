import * as React from "react";

import { DefaultButton } from "office-ui-fabric-react";

import { Icon, ModalIcon } from "./Icon";

export interface BackButtonProps {
  onClick: () => void;
  backLabel?: string;
  fontSize?: number;
}

export const BackButton: React.FC<BackButtonProps> = ({
  onClick,
  backLabel,
}) => {
  // const i18n = useI18n();

  return (
    <DefaultButton
      id="back"
      className="back-button base-button"
      onClick={onClick}
    >
      <Icon
        iconName={ModalIcon.back}
        style={{ fontSize: 16, marginRight: 5 }}
      />
      {
        backLabel || "common.button.back" // i18n.renderToString("common.button.back")
      }
    </DefaultButton>
  );
};
