import {
  VocCustomUi,
  VocCustomUiLayoutItem,
  VocCustomUiLayoutItemType,
} from "../app/types";

export type SelectedUiReducerAction =
  | {
      type: "select";
      screenName: string;
      screenData: VocCustomUi[];
    }
  | {
      type: "dataTableChange";
      layoutItemList: VocCustomUiLayoutItem[];
    }
  | {
      type: "layoutGroupRename";
      groupKey: string;
      customName: string;
    }
  | {
      type: "layoutGroupChange";
      groupKey: string;
      changeValues: Partial<{
        display: boolean;
        locked: boolean;
      }>;
    }
  | {
      type: "layoutGroupMemberRename";
      groupKey: string;
      fieldKey: string;
      customName: string;
    }
  | {
      type: "layoutGroupMemberChange";
      groupKey: string;
      fieldKey: string;
      changeValues: Partial<{
        display: boolean;
        locked: boolean;
        span: number;
        customName: string;
      }>;
    }
  | {
      type: "layoutGroupMemberReorder";
      groupKey: string;
      fieldKey: string;
      toGroupKey: string;
      toFieldKey: string;
    };

export const selectedUiReducer = (
  state: VocCustomUi,
  action: SelectedUiReducerAction
): VocCustomUi => {
  switch (action.type) {
    case "select": {
      const customUi = action.screenData.find(
        (item) => item.screen.name === action.screenName
      );
      if (customUi) {
        return {
          ...customUi,
          layout: [...customUi.layout.map((item) => ({ ...item }))], // spread to make sure original object not mutating
        };
      }
      return undefined;
    }
    case "dataTableChange": {
      const newState = { ...state };
      newState.layout = [...action.layoutItemList];
      return newState;
    }
    case "layoutGroupRename": {
      const newState = { ...state };
      newState.layout = newState.layout.map((group) => {
        if (
          group.key === action.groupKey &&
          group.type === VocCustomUiLayoutItemType.LayoutGroup
        ) {
          return {
            ...group,
            customName: action.customName,
          };
        }
        return group;
      });
      return newState;
    }
    case "layoutGroupChange": {
      const newState = { ...state };
      newState.layout = newState.layout.map((group) => {
        if (
          group.key === action.groupKey &&
          group.type === VocCustomUiLayoutItemType.LayoutGroup
        ) {
          group.display =
            action.changeValues.display === undefined
              ? group.display
              : action.changeValues.display;
          group.locked =
            action.changeValues.locked === undefined
              ? group.locked
              : action.changeValues.locked;

          return {
            ...group,
          };
        }
        return group;
      });
      return newState;
    }
    case "layoutGroupMemberRename": {
      const newState = { ...state };
      const group = newState.layout.find((grp) => grp.key === action.groupKey);
      if (
        group.type === VocCustomUiLayoutItemType.LayoutGroup &&
        group?.members
      ) {
        group.members = group.members.map((item) => {
          if (item.key === action.fieldKey) {
            if (
              item.type === VocCustomUiLayoutItemType.QuestionListCustomField
            ) {
              return {
                ...item,
                name: action.customName,
                key: action.customName,
              };
            }
            return {
              ...item,
              customName: action.customName,
            };
          }
          return item;
        });
      }
      return newState;
    }
    case "layoutGroupMemberChange": {
      const newState = { ...state };
      const group = newState.layout.find((grp) => grp.key === action.groupKey);
      if (
        group.type === VocCustomUiLayoutItemType.LayoutGroup &&
        group?.members
      ) {
        group.members = group.members.map((item) => {
          if (item.key === action.fieldKey) {
            return {
              ...item,
              ...action.changeValues,
            };
          }
          return item;
        });
      }
      return newState;
    }
    case "layoutGroupMemberReorder": {
      const newState = { ...state };
      const fromGroup = newState.layout.find(
        (grp) => grp.key === action.groupKey
      );

      // type guard
      if (fromGroup.type === VocCustomUiLayoutItemType.LayoutGroup) {
        const removeIndex = fromGroup.members.indexOf(
          fromGroup.members.find((member) => member.key === action.fieldKey)
        );

        if (removeIndex > -1) {
          const removed = fromGroup.members.splice(removeIndex, 1);

          const toGroup = newState.layout.find(
            (grp) => grp.key === action.toGroupKey
          );

          if (toGroup.type === VocCustomUiLayoutItemType.LayoutGroup) {
            if (action.toFieldKey === undefined) {
              // case when item is dragged to the end
              toGroup.members.push(...removed);
            } else {
              const insertIndex = toGroup.members.indexOf(
                toGroup.members.find(
                  (member) => member.key === action.toFieldKey
                )
              );
              if (toGroup === fromGroup && insertIndex >= removeIndex) {
                // case when the item is dragged downward in the same group
                toGroup.members.splice(insertIndex + 1, 0, ...removed);
              } else {
                toGroup.members.splice(insertIndex, 0, ...removed);
              }
            }
          }
        }
      }
      return newState;
    }
  }
};
