import React from "react";
import { useTranslation } from "react-i18next";

// import { ValidationResult } from 'cpcs-common-ui';
import {
  Checkbox,
  IBasePickerSuggestionsProps,
  ITag,
  TagPicker,
} from "office-ui-fabric-react";

import AppButton from "../../../../app/components/app-button";
import {
  AppFormField,
  AppFormRow,
  AppFormTextField,
} from "../../../../app/components/app-form";
import AppModal from "../../../../app/components/app-modal";
import { AsyncResult } from "../../../../app/hooks/useAsync";
import { VocEventSummaryNotification } from "../../../../app/types";
import CustomApiError from "../../../../app/utils/backend/custom-api-error";

import "./NotifyModalLayout.scss";

interface NotifyModalProps {
  showNotifyModal: boolean;
  form: VocEventSummaryNotification;
  entityUserOptions: ITag[];
  createSummaryNotificationAsyncResult: AsyncResult<VocEventSummaryNotification>;
  validationError: any;
  onFormChange: (changeValues: Partial<VocEventSummaryNotification>) => void;
  onSave: () => void;
  onModalClose: () => void;
}

const NotifyModal: React.FC<NotifyModalProps> = ({
  showNotifyModal,
  form,
  entityUserOptions,
  createSummaryNotificationAsyncResult,
  validationError,
  onFormChange,
  onSave,
  onModalClose,
}) => {
  const { t } = useTranslation();
  const formErrors = {
    ...validationError,
    ...(createSummaryNotificationAsyncResult.error as CustomApiError)
      ?.serverResponseData,
  };

  const getTextFromItem = (item: ITag) => item.name;

  const filterEntityUserTags = (
    filterText: string,
    tagList: ITag[]
  ): ITag[] => {
    return filterText
      ? entityUserOptions.filter(
          (tag) =>
            !tagList.find((selected) => selected.key === tag.key) &&
            tag.name.toLowerCase().includes(filterText.toLowerCase())
        )
      : [];
  };

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: t("voc.appFieldBlockComponent.suggestedChoices"),
    noResultsFoundText: t("voc.appFieldBlockComponent.noResultsFound"),
  };

  const noReceiverError =
    (formErrors as any)?.details === t("voc.eventPage.summary.noReceiver") && //this is a condition... and with typos X_X can I localeContext.renderToString it?? voc.eventPage.summary.noReceiver
    t("voc.eventPage.summary.assignRolesFirst");

  const errorContent =
    !(createSummaryNotificationAsyncResult.error as CustomApiError)
      ?.serverResponseData &&
    createSummaryNotificationAsyncResult.error?.message;

  const enableSave =
    form?.sendToAll ||
    form?.sendToCoOnly ||
    form?.sendToRoAndRsOnly ||
    form?.sendToOthers?.length > 0;
  return (
    <AppModal
      className="voc-event-summary-modal"
      isOpen={showNotifyModal}
      title={t("voc.eventPage.summary.notifications")}
      onClose={onModalClose}
      loading={createSummaryNotificationAsyncResult.loading}
      footer={
        <AppButton
          className="button"
          onClick={onSave}
          text={
            createSummaryNotificationAsyncResult.loading
              ? t("voc.common.sending")
              : t("voc.common.send")
          }
          disabled={!enableSave || createSummaryNotificationAsyncResult.loading}
        />
      }
      errorContent={noReceiverError || errorContent}
      mandatoryHintReplacement={null}
    >
      <AppFormRow>
        <AppFormField className="recipients">
          <label>{t("voc.eventPage.summary.recipients")}</label>
          <Checkbox
            label={t("voc.eventPage.summary.notifyAll")}
            onChange={(_, checked) =>
              onFormChange({
                sendToAll: checked,
                sendToCoOnly: !checked,
                sendToRoAndRsOnly: !checked,
              })
            }
          />
          <Checkbox
            label={t("voc.eventPage.summary.notifyCommercialOwner")}
            disabled={form.sendToAll}
            checked={form.sendToAll ? true : form.sendToCoOnly}
            onChange={(_, checked) =>
              onFormChange({
                sendToCoOnly: checked,
              })
            }
          />
          <Checkbox
            label={t("voc.eventPage.summary.notifyROAndRS")}
            disabled={form.sendToAll}
            checked={form.sendToAll ? true : form.sendToRoAndRsOnly}
            onChange={(_, checked) =>
              onFormChange({
                sendToRoAndRsOnly: checked,
              })
            }
          />
          <Checkbox
            label={t("voc.eventPage.summary.notifyDesignatedUsers")}
            onChange={(_, checked) =>
              onFormChange({
                sendToOthers: checked ? [] : undefined,
              })
            }
          />
          <TagPicker
            disabled={!form.sendToOthers}
            className={form.sendToOthers ? "selected" : undefined}
            removeButtonAriaLabel={t("voc.appFieldBlockComponent.remove")}
            onResolveSuggestions={filterEntityUserTags}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={pickerSuggestionsProps}
            onChange={(items) =>
              onFormChange({
                sendToOthers: items.map((item) => String(item.key)),
              })
            }
          />
          {formErrors && formErrors.sendToOthers && (
            <div role="alert" className="error">
              <p>
                {Array.isArray(formErrors.sendToOthers)
                  ? formErrors.sendToOthers.join(" / ")
                  : formErrors.sendToOthers}
              </p>
            </div>
          )}
        </AppFormField>
      </AppFormRow>
      <AppFormRow>
        <AppFormTextField
          label={t("voc.eventPage.summary.message")}
          name="message"
          multiline={true}
          value={form.message}
          onChange={(_: any, value: any) => onFormChange({ message: value })}
        />
      </AppFormRow>
    </AppModal>
  );
};

export default NotifyModal;
