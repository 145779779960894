import { FileOutlined, LinkOutlined } from "@ant-design/icons";
import {
  NxpPanel,
  NxpTable,
  NxpTableColumnProps,
  sorterForDate,
  sorterForNumber,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { convertNumber } from "../../../../../app/components/app-numeric-input/utils/convert-value-helper";
import { VocSupportDocument } from "../../../../../app/types";
import { getUserDisplayDateFormat } from "../../../../../app/utils/mappers";

interface MinervaPanelProps {}

const MinervaPanel: React.FC<MinervaPanelProps> = () => {
  const { t } = useTranslation();

  const columns: NxpTableColumnProps<VocSupportDocument>[] = useMemo(
    () => [
      {
        title: t("voc.eventPage.supporting.sciRef"),
        dataIndex: ["details", "reference"],
        key: "reference",
        width: "10%",
        sorter: (a, b) =>
          sorterForString(a.details.reference, b.details.reference),
      },
      {
        title: t("voc.eventPage.supporting.subcontractor"),
        dataIndex: ["details", "subcontractor"],
        key: "subcontractor",
        width: "10%",
        sorter: (a, b) =>
          sorterForString(a.details.subcontractor, b.details.subcontractor),
      },
      {
        title: t("voc.common.subject"),
        dataIndex: ["details", "subject"],
        key: "subject",
        width: "10%",
        sorter: (a, b) => sorterForString(a.details.subject, b.details.subject),
      },
      {
        title: t("voc.eventPage.supporting.dateFrom"),
        dataIndex: ["details", "fromDate"],
        key: "fromDate",
        width: "10%",
        sorter: (a, b) => sorterForDate(a.details.fromDate, b.details.fromDate),
        extraFormatOptions: {
          type: "date",
          format: getUserDisplayDateFormat(),
        },
      },
      {
        title: t("voc.eventPage.supporting.dateTo"),
        dataIndex: ["details", "toDate"],
        key: "toDate",
        width: "10%",
        sorter: (a, b) => sorterForDate(a.details.toDate, b.details.toDate),
        extraFormatOptions: {
          type: "date",
          format: getUserDisplayDateFormat(),
        },
      },
      {
        title: t("voc.common.total"),
        dataIndex: ["details", "total"],
        key: "total",
        width: "10%",
        sorter: (a, b) =>
          sorterForNumber(Number(a.details.amount), Number(b.details.amount)),
        render: (amount, record) => {
          return `$${convertNumber(amount)}`;
        },
      },
      {
        title: t("voc.eventPage.supporting.file"),
        dataIndex: "file",
        key: "file",
        width: "5%",
        render: (file, record) => {
          return <FileOutlined />;
        },
      },
      {
        title: t("voc.eventPage.supporting.link"),
        dataIndex: ["details", "link"],
        key: "link",
        width: "5%",
        render: (link, record) => {
          return <LinkOutlined />;
        },
      },
    ],
    [t]
  );

  const dummyData = [
    {
      id: "1",
      details: {
        reference: "Test",
        subcontractor: "Hing Lee Engineering",
        subject: "Lorem ipsum",
        fromDate: new Date(),
        toDate: new Date(),
        total: 10000,
        link: "link",
      },
      file: "Document.pdf",
    },
  ];

  return (
    <NxpPanel
      titleContent={`${t("voc.eventPage.supporting.subcontractsMinerva")}`}
      defaultCollapsed
    >
      <NxpTable columns={columns} dataSource={dummyData} rowKey="id" />
    </NxpPanel>
  );
};

export default MinervaPanel;
