import React from "react";
import { useTranslation } from "react-i18next";

import { Toggle } from "office-ui-fabric-react";

import "./AppCard.scss";
import AccessVisible from "../app-access-visible/AppAccessVisible";
import { useParams } from "react-router-dom";

interface AppCardProps {
  title: string;
  canEdit: boolean;
  isEditing?: boolean;
  editCallback?: Function;
  saveCallback?: Function;
  cancelCallback?: Function;
  addCallback?: Function;
  addCallbackShortcut?: boolean;
  stickyHeader?: boolean;
  taskFilter?: boolean; //only used for tasks component
  taskFilterState?: boolean; //only used for tasks component
  setTaskFilterState?: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}

const AppCard: React.FC<AppCardProps> = (props) => {
  const {
    title,
    canEdit,
    isEditing,
    editCallback,
    saveCallback,
    cancelCallback,
    addCallback,
    addCallbackShortcut,
    stickyHeader,
    taskFilter,
    taskFilterState,
    setTaskFilterState,
    children,
  } = props;
  const { t } = useTranslation();
  const { eventId } = useParams();
  return (
    <div className="app-card">
      <div className={"header" + (stickyHeader ? " sticky" : "")}>
        <div className="title">{title}</div>
        <div className="controls">
          <AccessVisible
            objectCode={eventId ? "compensation_event" : "event"}
            actionType="edit"
          >
            {taskFilter && !isEditing ? (
              <>
                <Toggle
                  checked={taskFilterState}
                  onChange={() => {
                    setTaskFilterState(!taskFilterState);
                  }}
                />
                <span className="toggle-text">
                  {t("voc.common.hideCompleted")}
                </span>
              </>
            ) : null}
            {canEdit && !isEditing ? (
              <div className="edit control" onClick={() => editCallback()}>
                {t("voc.common.edit").toLocaleUpperCase()}
              </div>
            ) : null}
            {canEdit && !isEditing && addCallbackShortcut ? (
              <div
                className="edit control"
                onClick={() => {
                  addCallback();
                  editCallback();
                }}
              >
                {t("voc.common.add").toLocaleUpperCase()}
              </div>
            ) : null}
            {canEdit && isEditing ? (
              <>
                <div
                  className="left cancel control"
                  onClick={() => cancelCallback()}
                >
                  {t("voc.common.cancel").toLocaleUpperCase()}
                </div>
                <div className="save control" onClick={() => saveCallback()}>
                  {t("voc.common.save").toLocaleUpperCase()}
                </div>
                {addCallback ? (
                  <div className="add control" onClick={() => addCallback()}>
                    {t("voc.common.add").toLocaleUpperCase()}
                  </div>
                ) : null}
              </>
            ) : null}
          </AccessVisible>
        </div>
      </div>
      {children}
    </div>
  );
};

export default AppCard;
