import React from "react";
import { useTranslation } from "react-i18next";

import AppCollapsible from "../../../app/components/app-collapsible/AppCollapsible";
import { DataListItem } from "../../../app/components/app-data-list/types";
import AppDataListV2 from "../../../app/components/app-data-list/AppDataListV2";
import { DeletedRecord, VocLibraryObject } from "../../../app/types";
import { useLibraryHandler } from "../../hooks/useLibraryHandler";
import { DateFormatPattern, formatDate } from "@nexploretechnology/nxp-ui";

interface KeyDatesProps {
  libraryType: "VOC_KEY_DATES";
  refetchCount: number;
}

export const KeyDates: React.FC<KeyDatesProps> = ({
  libraryType,
  refetchCount,
}) => {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = React.useState(false);

  const convertDeadlines = (items: VocLibraryObject[]) => {
    const converted = items.map((item) => ({
      id: item.id,
      value: item.value,
      edited: false,
      sortOrder: item.sortOrder,
      fieldDataArray: [
        { fieldData: item.label },
        { fieldData: item.contractualDate },
      ],
    }));
    return converted;
  };

  const {
    // Variables
    fetchLibraryAndUsage,
    isLibraryAndUsageLoaded,
    libraryItem,
    displayLibraryItem,
    setDisplayLibraryItem,
    // Library Custom Sort
    isCustomSort,
    saveCustomOrder,
    resetCustomOrder,
    // Update Handler
    handleUpdateLibraries,
  } = useLibraryHandler(libraryType, convertDeadlines, refetchCount);

  // ================================================
  //                 panel & colDef
  // ================================================

  const panelTitle = React.useMemo(
    () => t("voc.library.titles.deadlines"),
    [t]
  );

  const column = React.useMemo(
    () => [
      {
        columnName: t("voc.library.deadlineKeyDate"),
        columnWidthClassName: "remainder",
        columnDataType: "string",
        editable: true,
      },
      {
        columnName: t("voc.library.contractualDate"),
        columnWidthClassName: "fourth",
        columnDataType: "date",
        editable: true,
      },
    ],
    [t]
  );

  // ================================================
  //                Action Handler
  // ================================================

  const handleSave = () => {
    const deconvertedDeadlines: Partial<VocLibraryObject>[] = [];
    displayLibraryItem.forEach((dl: DataListItem) => {
      if (dl.edited) {
        deconvertedDeadlines.push({
          id: dl.id,
          value: dl.value,
          label: dl.fieldDataArray[0].fieldData,
          contractualDate: formatDate(
            dl.fieldDataArray[1].fieldData,
            DateFormatPattern.date
          ) as any,
        });
      }
    });
    const deletedRecords: DeletedRecord[] = [];
    libraryItem.forEach((memo: any) => {
      let deleted = true;
      displayLibraryItem.forEach((dl: DataListItem) => {
        if (memo.id === dl.id) {
          deleted = false;
        }
      });
      if (deleted) {
        deletedRecords.push({
          id: memo.id,
          contractualDate: memo.contractualDate
            ? (formatDate(memo.contractualDate, DateFormatPattern.date) as any)
            : null, //is this necessary?
          label: memo.label, //is this necessary?
          value: memo.value, //is this necessary?
          isDeleted: true,
        });
      }
    });
    const combinedRecords = [...deconvertedDeadlines, ...deletedRecords];
    handleUpdateLibraries(
      {
        items: combinedRecords,
      },
      () => setIsEditing(false)
    );
  };

  const handleCancel = () => {
    // setDisplayLibraryItem(libraryItem);
    fetchLibraryAndUsage();
  };

  return (
    <AppCollapsible
      permissionCode="libraries"
      hasFields={false}
      editCallback={() => {
        setIsEditing(true);
      }}
      editingState={isEditing}
      saveCallback={handleSave}
      cancelCallback={() => {
        handleCancel();
        setIsEditing(false);
      }}
      firstDecollapseCallback={() => {
        fetchLibraryAndUsage();
      }}
      isLoading={!isLibraryAndUsageLoaded}
      isLoadOnEveryClick={true}
      headerChildren={<div className="doctype-title">{panelTitle}</div>}
      bodyChildren={
        isLibraryAndUsageLoaded ? (
          <AppDataListV2
            permissionCode="libraries"
            editing={isEditing}
            canDeleteLastItem={true}
            columns={column}
            oriItems={libraryItem}
            items={displayLibraryItem}
            editSetStateHook={setDisplayLibraryItem}
            setFiles={null}
            defaultSortDirection=""
            saveCustomOrderCallback={saveCustomOrder}
            resetCustomOrderCallback={resetCustomOrder}
            saveCustomOrderCode={libraryType}
            initCustomSortState={isCustomSort}
          />
        ) : null
      }
    ></AppCollapsible>
  );
};

export default KeyDates;
