import React from "react";

import { notify, useYupValidate } from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../app/hooks/useAppContext";
import { useAsync } from "../../../../app/hooks/useAsync";
import { VocClaim } from "../../../../app/types";
import { createClaim } from "../../../compensation-event-services";
import AddClaimModal from "./AddClaimModal";

const formClaimSchema = yup.object({
  title: yup.string().nullable(),
  // .required('Title required.'),  // VC-303: title field is optional
  appliedDays: yup.number().nullable(),
  // .required('Applied Days required.'), // VC-303: title field is optional
  targetDays: yup.number().nullable(),
  // .required('Target Days required.'), // VC-303: title field is optional
  awardedDays: yup.number().nullable(),
  // .required('Awarded Days required.'), // VC-303: title field is optional
});
// function getClaimSchema() {
//   return yup.object().shape({
//     title: yup.string().nullable(),
//     // .required('Title required.'),  // VC-303: title field is optional
//     appliedDays: yup.number().nullable(),
//     // .required('Applied Days required.'), // VC-303: title field is optional
//     targetDays: yup.number().nullable(),
//     // .required('Target Days required.'), // VC-303: title field is optional
//     awardedDays: yup.number().nullable(),
//     // .required('Awarded Days required.'), // VC-303: title field is optional
//   });
// }

interface AddClaimContainerProps {
  eventId: string;
  showAddClaimModal: boolean;
  onRefreshData: () => void;
  onModalClose: () => void;
}

const AddClaimContainer: React.FC<AddClaimContainerProps> = ({
  eventId,
  showAddClaimModal,
  onRefreshData,
  onModalClose,
}) => {
  const [form, setForm] = React.useState<Partial<VocClaim>>(() => ({
    agreeQuantumOhnp: false,
    agreeQuantumQty: false,
    agreeQuantumRates: false,
  }));

  const { serviceConfig, errorHandler } = useAppContext();

  const [createClaimAsyncResult, setCreateClaimRequest] = useAsync<VocClaim>(
    undefined,
    {
      onSuccess: () => {
        notify.actionCompleted(); //     'voc.eventPage.notifications.requiredFinalNotification'
        onModalClose();
        onRefreshData();
      },
      onError: (ex) => errorHandler(ex, "claim creation"),
    }
  );

  const handleSaveValidated = () => {
    setCreateClaimRequest(() => createClaim(eventId, form, serviceConfig));
  };

  const [validationError, , , saveWithValidate] = useYupValidate<
    Partial<VocClaim>
  >(form, formClaimSchema as any, handleSaveValidated);

  const handleValidation = () => {
    saveWithValidate(undefined);
  };

  const handleFormChange = (changeValues: Partial<VocClaim>) => {
    setForm((prevState) => ({ ...prevState, ...changeValues }));
  };

  return (
    <AddClaimModal
      entityId={serviceConfig.entityId}
      showAddClaimModal={showAddClaimModal}
      form={form}
      createClaimAsyncResult={createClaimAsyncResult}
      validationError={validationError}
      onFormChange={handleFormChange}
      onModalClose={onModalClose}
      onSave={handleValidation}
    />
  );
};

export default AddClaimContainer;
