import React, { useCallback, useState } from "react";

import { LibrarySource } from "../../../libraries/libraries-services";
import { Right } from "../../services/app";
import {
  DictionaryTypeName,
  findDictionaryItemByKey,
} from "../../services/dictionary";
import { VocScreenNameEnums } from "../../types";
import AppContext, { AppContextCache } from "./AppContext";

interface AppContextProviderProps {
  initAppContextCache?: AppContextCache;
  children: React.ReactNode;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  initAppContextCache,
  children,
}) => {
  const [appContextCache, setAppContextCache] = useState<AppContextCache>(
    initAppContextCache || {
      users: [],
      entityUsers: [],
      libraries: {},
      dictionaries: [],
      customizedScreens: [],
      myRightList: [],
    }
  );

  const handleAppContextCacheItemUpdate = useCallback(
    <K extends keyof AppContextCache>(
      cacheItem: K,
      itemValue: AppContextCache[K]
    ) => {
      setAppContextCache((prevState) => ({
        ...prevState,
        [cacheItem]: itemValue,
      }));
    },
    []
  );

  const getDictionary = useCallback(
    (dictionaryName: DictionaryTypeName) =>
      appContextCache.dictionaries.find((d) => d.name === dictionaryName),
    [appContextCache.dictionaries]
  );

  const getDictionaryItemByKey = useCallback(
    (dictionaryName: DictionaryTypeName, itemKey: string) => {
      return findDictionaryItemByKey(
        itemKey,
        getDictionary(dictionaryName)?.members || []
      );
    },
    [getDictionary]
  );

  const getLibraryItemByKeyValue = useCallback(
    (source: LibrarySource, keyValue: string) => {
      return appContextCache.libraries[source]?.find(
        (item) => item.value === keyValue
      );
    },
    [appContextCache.libraries]
  );

  const getCustomizedScreen = useCallback(
    (screenName: VocScreenNameEnums) =>
      appContextCache.customizedScreens?.find(
        (s) => s.screen.name === screenName
      ),
    [appContextCache.customizedScreens]
  );

  const rightsTypes = appContextCache.myRightList.map((item) => {
    return { objectCode: item.objectCode, actionType: item.action };
  });

  const hasRight = useCallback(
    (hasRight: Right | "demo-view") => {
      if (hasRight === "demo-view") {
        return true;
      } else {
        return rightsTypes.some(
          (right) =>
            right.actionType === hasRight.actionType &&
            right.objectCode === hasRight.objectCode
        );
      }
    },
    [rightsTypes]
  );

  return (
    <AppContext.Provider
      value={{
        ...appContextCache,
        onAppContextCacheUpdate: setAppContextCache,
        onAppContextCacheItemUpdate: handleAppContextCacheItemUpdate,
        getCustomizedScreen,
        getDictionary,
        getDictionaryItemByKey,
        getLibraryItemByKeyValue,
        hasRight,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
