import "./AppModal.scss";

import { Icon } from "office-ui-fabric-react";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Modal, ModalProps } from "./Modal";

export type AppModalProps = {
  footer?: ReactNode;
  mandatoryHintReplacement?: ReactNode; // pass null or your own content if you want to replace the mandatory hint
  errorContent?: ReactNode;
  header?: boolean;
  children?: ReactNode;
} & Pick<
  ModalProps,
  | "isOpen"
  | "loading"
  | "title"
  | "subTitle"
  | "onClose"
  | "children"
  | "className"
>;

const AppModal: React.FC<AppModalProps> = ({
  title,
  subTitle,
  mandatoryHintReplacement,
  errorContent,
  footer,
  children,
  className,
  onClose,
  header = true,
  ...restProps
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      {...restProps}
      renderAction={() => footer}
      className={`voc-app-modal${className ? ` ${className}` : ""}`}
      title={undefined}
      subTitle={undefined}
    >
      <header className={`${header ? "" : "hide"}`}>
        {title && typeof title === "string" ? (
          <h1>
            {title}
            {subTitle && <span className="subtitle">{subTitle}</span>}
          </h1>
        ) : (
          title
        )}
        {mandatoryHintReplacement === undefined ? (
          <p className="mandatory-hint">
            <span>*</span> {t("voc.common.mandatoryField")}
          </p>
        ) : (
          mandatoryHintReplacement
        )}
      </header>
      <div className="modal-content">
        {children}
        {errorContent && typeof errorContent === "string" ? (
          <div className="error-content">
            <p>{errorContent}</p>
          </div>
        ) : (
          <div className="error-content">{errorContent}</div>
        )}
      </div>
      {/* put close button at the end to avoid auto-focusing */}
      <div className={`close-button-wrap ${header ? "" : "hide"}`}>
        <button onClick={onClose} tabIndex={1}>
          <Icon iconName="Cancel" />
        </button>
      </div>
    </Modal>
  );
};

export default AppModal;
