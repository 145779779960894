import { TFunction } from "i18next";
import { IDropdownOption } from "office-ui-fabric-react";

import { ColumnDataType } from "../app-data-table";
import { FilterType } from "./AppFilter";

export const getSettingsOptions = (
  columnDataType: ColumnDataType,
  emptyOption: IDropdownOption,
  t: TFunction<"translation", undefined, "translation">
): IDropdownOption[] => {
  let options: IDropdownOption[];
  switch (columnDataType) {
    case undefined:
      options = [
        {
          key: FilterType.Contains,
          text: t("voc.filter.contains"),
        },
        {
          key: FilterType.Equal,
          text: t("voc.filter.equal"),
        },
      ];
      break;
    case ColumnDataType.Select:
      options = [
        {
          key: FilterType.Equal,
          text: t("voc.filter.equal"),
        },
        {
          key: FilterType.NotEqual,
          text: t("voc.filter.notEqual"),
        },
      ];
      break;
    case ColumnDataType.Boolean:
      options = [
        {
          key: FilterType.Equal,
          text: t("voc.filter.equal"),
        },
        {
          key: FilterType.IsEmpty,
          text: t("voc.filter.isEmpty"),
        },
      ];
      break;
    case ColumnDataType.Number:
    case ColumnDataType.Percent:
    case ColumnDataType.Money:
    case ColumnDataType.Date:
      options = [
        {
          key: FilterType.Equal,
          text: t("voc.filter.equal"),
        },
        {
          key: FilterType.GreaterThanOrEqual,
          text: t("voc.filter.greaterThanOrEqual"),
        },
        {
          key: FilterType.LessThanOrEqual,
          text: t("voc.filter.lessThanOrEqual"),
        },
      ];
      break;
    case ColumnDataType.String:
      options = [
        {
          key: FilterType.Contains,
          text: t("voc.filter.contains"),
        },
        {
          key: FilterType.Equal,
          text: t("voc.filter.equal"),
        },
        {
          key: FilterType.NotEqual,
          text: t("voc.filter.notEqual"),
        },
      ];
      break;
  }
  options.unshift(emptyOption);
  return options;
};
