import React from "react";

import { isNull } from "lodash";

import { DataListItem } from "../../../app/components/app-data-list/types";
import useAppContext from "../../../app/hooks/useAppContext";
import {
  DeletedRecord,
  VocCustomUi,
  VocEvent,
  VocEventFile,
  VocSummaryRefFile,
  VocSummaryRefFiles,
} from "../../../app/types";
import { editRefFiles, getRefFiles } from "../../compensation-event-services";
import SummaryTabLayout from "./SummaryTabLayout";
import { useAsync } from "../../../app/hooks/useAsync";
import { notify } from "@nexploretechnology/nxp-ui";
import { mapDateToString } from "../../../app/utils/mappers";

interface SummaryTabContainerProps {
  event: VocEvent;
  customUi: VocCustomUi;
  onEventRefresh: () => void;
}

const SummaryTabContainer: React.FC<SummaryTabContainerProps> = ({
  event,
  customUi,
  onEventRefresh,
}) => {
  const { serviceConfig, errorHandler } = useAppContext();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [isEditingReference, setIsEditingReference] =
    React.useState<boolean>(false);
  const [refFileData, setRefFileData] = React.useState<DataListItem[]>(null);
  const [refFileDataMemo, setRefFileDataMemo] =
    React.useState<DataListItem[]>(null);
  const [refFiles, setRefFiles] = React.useState<VocEventFile[][]>([[]]);

  const [, setGetRefFiles] = useAsync<VocSummaryRefFiles>(
    () => getRefFiles(event.id, serviceConfig),
    {
      onSuccess: (data) => {
        setRefFileData(convertListData(data.results));
        setRefFileDataMemo(convertListData(data.results));
      },
    }
  );

  const [, setUpdateRefFiles] = useAsync<VocSummaryRefFiles>(null, {
    onSuccess: () => {
      setGetRefFiles(() => {
        return getRefFiles(event.id, serviceConfig);
      }); //better to get again and memoize (for cancel) once confirmed data sent?
      //or refactor to update memo?
      notify.success("Update Event Success !");
    },
    onError(ex) {
      errorHandler(ex, "Update Event Failure !");
    },
  });

  React.useEffect(() => {
    if (refFileData && !loaded) {
      setRefFiles(
        refFileData.map((rf) => {
          return rf.fieldDataArray[2].fieldData;
        })
      );
      setLoaded(true);
    }
    // eslint-disable-next-line
  }, [refFileData]);

  const [refFileForm, setRefFileForm] = React.useState<VocSummaryRefFiles>({
    records: [],
  });

  const handleEditRefFiles = React.useCallback(() => {
    setUpdateRefFiles(() => {
      return editRefFiles(event.id, refFileForm, serviceConfig);
    });
    // eslint-disable-next-line
  }, [refFileForm]);

  React.useEffect(() => {
    if (refFileForm.records.length > 0) {
      handleEditRefFiles();
    }
    // eslint-disable-next-line
  }, [refFileForm]);

  const [deletedRecords, setDeletedRecords] = React.useState<string[]>(null);

  React.useEffect(() => {
    if (refFileData && refFileDataMemo) {
      const deletedRecords: string[] = [];
      refFileDataMemo.forEach((memo) => {
        let deleted = true;
        refFileData.forEach((rf) => {
          if (memo.id === rf.id) {
            deleted = false;
          }
        });
        if (deleted) {
          deletedRecords.push(memo.id as string);
        }
      });
      setDeletedRecords(deletedRecords);
    }
  }, [refFileData, refFileDataMemo]);

  function saveRefFiles() {
    const refFileDataCopy = [...refFileData];
    let validated = true;
    refFileDataCopy.forEach((rf: DataListItem) => {
      if (!rf.fieldDataArray[0].fieldData || !rf.fieldDataArray[1].fieldData) {
        validated = false;
      }
      // if (!rf.fieldDataArray[0].fieldData && !rf.fieldDataArray[1].fieldData) {
      //   rf.validationError = localeContext.renderToString(
      //     'voc.eventPage.validationError.provideSubjectAndDocumentDate'
      //   );
      // }
      // if (!rf.fieldDataArray[1].fieldData && rf.fieldDataArray[0].fieldData) {
      //   rf.validationError = localeContext.renderToString(
      //     'voc.eventPage.summary.refFileValidation'
      //   );
      // }
      // if (!rf.fieldDataArray[0].fieldData && rf.fieldDataArray[1].fieldData) {
      //   rf.validationError = localeContext.renderToString(
      //     'voc.eventPage.validationError.provideSubject'
      //   );
      // }
    });
    if (!validated) {
      setRefFileData(refFileDataCopy);
    } else {
      refFileDataCopy.forEach((rf: DataListItem) => {
        rf.validationError = null;
      });
      const deconvertedRefFiles: VocSummaryRefFile[] = refFileData.map(
        (rf, i) => ({
          id: isNull(rf.id) ? undefined : rf.id,
          description: rf.fieldDataArray[0].fieldData,
          documentDate: mapDateToString(
            rf.fieldDataArray[1].fieldData
          ) as string,
          file: refFiles[i],
          link: rf.fieldDataArray[3].fieldData,
          comment: rf.fieldDataArray[4].fieldData,
        })
      );
      const deletedRecordObjects: DeletedRecord[] = deletedRecords.map(
        (recid) => ({
          id: recid,
          description: "", // added this to bypass the record integrity check
          documentDate: mapDateToString(new Date()), // added this to bypass the record integrity check
          isDeleted: true,
        })
      );
      const combinedRecords = [...deconvertedRefFiles, ...deletedRecordObjects];

      setRefFileForm({
        records: combinedRecords,
      });
      setIsEditingReference(false);
    }
  }

  function convertListData(items: any[]) {
    const minusDeleted = items.filter((item) => !item.deleted);
    return minusDeleted.map((item) => ({
      id: item.id,
      fieldDataArray: [
        { fieldData: item.description },
        { fieldData: item.documentDate },
        { fieldData: item.file },
        { fieldData: item.link },
        { fieldData: item.comment },
      ],
    }));
  }

  function cancelRefFile() {
    setRefFileData(refFileDataMemo);
  }

  function addRefFile() {
    const newItems = [...refFileData];
    newItems.push({
      id: null,
      fieldDataArray: [
        { fieldData: null },
        { fieldData: null },
        { fieldData: [] },
        { fieldData: null },
        { fieldData: null },
      ],
    });
    setRefFileData(newItems);
    const newFiles = [...refFiles];
    newFiles.push([]);
    setRefFiles(newFiles);
  }

  return loaded ? (
    <SummaryTabLayout
      event={event}
      customUi={customUi}
      refFileData={refFileData}
      setRefFileData={setRefFileData}
      setRefFiles={setRefFiles}
      saveRefFiles={saveRefFiles}
      cancelRefFile={cancelRefFile}
      addRefFile={addRefFile}
      onEventRefresh={onEventRefresh}
      isEditingReference={isEditingReference}
      setIsEditingReference={setIsEditingReference}
    />
  ) : null;
};

export default SummaryTabContainer;
