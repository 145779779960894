import React from "react";
import { useTranslation } from "react-i18next";

import { notify } from "@nexploretechnology/nxp-ui";
import { TFunction } from "i18next";
// @ts-ignore: fix check-ui command not seeing @types/math-expression-evaluator
import mathEvaluator from "math-expression-evaluator";
import * as yup from "yup";

import useAppContext from "../../../app/hooks/useAppContext";
import { useAsync } from "../../../app/hooks/useAsync";
import { useValidate } from "../../../app/hooks/useValidate";
import {
  VocCustomUi,
  VocCustomUiFieldDataType,
  VocCustomUiFieldSchema,
  VocCustomUiFormulaOutput,
  VocCustomUiLayoutItem,
  VocCustomUiLayoutItemType,
  VocCustomUiObjectType,
  VocScreenNameEnums,
} from "../../../app/types";
import { hasValue } from "../../../app/utils/has-value";
import {
  createCustomFieldSchema,
  updateCustomFieldSchema,
  updateCustomizedScreen,
} from "../../customization-services";
import QuestionGroupFieldSchemaModal from "./QuestionGroupFieldSchemaModal";

function getValidationSchema(
  t: TFunction,
  selectedUi: VocCustomUi,
  isCreating: boolean,
  valuesFormulaFields?: VocCustomUiLayoutItem[]
) {
  return yup.object().shape({
    name: yup
      .string()
      .nullable()
      .test("nameDuplicated", "Name duplicated", function (val) {
        if (isCreating && val?.length > 0) {
          const match = selectedUi.layout.find((layout) => {
            return (
              layout.members?.find(
                (member: VocCustomUiLayoutItem) =>
                  member.key.toLowerCase() === val.toLowerCase()
              ) !== undefined
            );
          });
          return match === undefined;
        }
        return true;
      })
      .required("Name required."),
    dataType: yup
      .mixed<VocCustomUiFieldDataType>()
      .oneOf(
        Object.values(VocCustomUiFieldDataType),
        t("voc.admin.customization.questionGroup.dataTypeRequired")
      )
      .test(
        "dropdownEmpty",
        t("voc.admin.customization.questionGroup.dropdownEmpty"),
        function (val) {
          if (val === VocCustomUiFieldDataType.Dropdown) {
            const ref = this.resolve(yup.ref("validations")) as {
              limits: string[];
            };
            return (
              ref.limits.filter((item) => (item ? item.trim() : "") !== "")
                .length > 1
            );
          }
          return true;
        }
      )
      .test(
        "dropdownDuplicated",
        t("voc.admin.customization.questionGroup.dropdownDuplicated"),
        function (val) {
          if (val === VocCustomUiFieldDataType.Dropdown) {
            const ref = this.resolve(yup.ref("validations")) as {
              limits: string[];
            };
            return !ref.limits.find(
              // allow duplicate of empty string but not with value
              (item) =>
                ref.limits.filter(
                  (duplicate) =>
                    duplicate.trim() !== "" && duplicate.trim() === item.trim()
                ).length > 1
            );
          }
          return true;
        }
      )
      .test(
        "formulaBracketUnclosed",
        t("voc.admin.customization.questionGroup.formulaBracketUnclosed"),
        function (val) {
          if (val === VocCustomUiFieldDataType.Formula && valuesFormulaFields) {
            const ref = this.resolve(
              yup.ref("validations")
            ) as VocCustomUiFieldSchema["validations"];

            return (
              (ref.formula.match(/\(/g)?.length || 0) ===
              (ref.formula.match(/\)/g)?.length || 0)
            );
          }
          return true;
        }
      )
      .test(
        "formulaInvalid",
        t("voc.admin.customization.questionGroup.formulaInvalid"),
        function (val) {
          if (val === VocCustomUiFieldDataType.Formula && valuesFormulaFields) {
            const ref = this.resolve(
              yup.ref("validations")
            ) as VocCustomUiFieldSchema["validations"];
            let formula = ref.formula;
            valuesFormulaFields.forEach((field) => {
              const regex = new RegExp(
                `{{${`${field.key}::${field.customName || field.name}`
                  .replace(/\[/g, "(")
                  .replace(/\]/g, ")")}}}`.replace(
                  // eslint-disable-next-line no-useless-escape
                  /[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g,
                  "\\$&"
                ),
                "g"
              );
              formula = formula.replace(regex, "1");
            });

            try {
              mathEvaluator.eval(formula);
            } catch (ex) {
              console.log(ex);
              return false;
            }
          }
          return true;
        }
      ),
    validations: yup
      .object({
        limits: yup.array(yup.string()),
      })
      .nullable()
      .required(
        t("voc.admin.customization.questionGroup.optionsIncorrectFormat")
      ),
  });
}

interface QuestionGroupFieldSchemaContainerProps {
  schemaModalConfig: {
    groupKey: string;
    editCustomFieldKey?: string; // undefined for schema creation, otherwise update existing
  };
  selectedUi: VocCustomUi;
  screenData: VocCustomUi[];
  onModalClose: () => void;
  onScreenDataRefresh: () => void;
}

const QuestionGroupFieldSchemaContainer: React.FC<
  QuestionGroupFieldSchemaContainerProps
> = ({
  schemaModalConfig,
  selectedUi,
  screenData,
  onModalClose,
  onScreenDataRefresh,
}) => {
  const isCreating = !(schemaModalConfig?.editCustomFieldKey?.length > 0);
  const { serviceConfig, errorHandler } = useAppContext();
  const { t } = useTranslation();

  const getEditField = () => {
    const grp = selectedUi.layout.find(
      (item) => item.key === schemaModalConfig.groupKey
    );
    if (
      grp.type === VocCustomUiLayoutItemType.LayoutGroup // type guard
    ) {
      const field = grp.members.find(
        (item) => item.key === schemaModalConfig.editCustomFieldKey
      );
      if (
        field.type === VocCustomUiLayoutItemType.QuestionListCustomField // type guard
      ) {
        return field;
      }
    }
  };

  const mapFormulaFieldKey = (formulaFieldKey: string) => {
    return formulaFieldKey === "internalEstimateSubstantiated"
      ? "internalSubstantiated"
      : formulaFieldKey === "internalVariance"
      ? "varianceIVCA"
      : formulaFieldKey === "targetVariance"
      ? "varianceTCA"
      : formulaFieldKey === "costCloseOut"
      ? "costCloseout"
      : formulaFieldKey === "costGna"
      ? "costGnA"
      : formulaFieldKey === "overheadProfit"
      ? "ohp"
      : formulaFieldKey === "cumulativeApplied"
      ? "cumPApplied"
      : formulaFieldKey === "cumulativeAppliedNominal"
      ? "cumVApplied"
      : formulaFieldKey === "cumulativeValueCertified"
      ? "cumVCertified"
      : formulaFieldKey === "cumulativeValueVariance"
      ? "varianceAC"
      : formulaFieldKey;
  };

  const [form, setForm] = React.useState<Partial<VocCustomUiFieldSchema>>(
    () => {
      if (schemaModalConfig.editCustomFieldKey) {
        // edit existing
        const field = getEditField();
        let rebuildFormula: string = undefined;
        if (field.dataType === VocCustomUiFieldDataType.Formula) {
          rebuildFormula = field.validations.formula;
          const formulaFields = field.validations.formula
            .match(/\{\{(.+?)\}\}/g)
            .map((match) => match.replace(/\{\{|\}\}/g, "").trim());
          formulaFields.forEach((formulaFieldKey) => {
            selectedUi.layout.forEach((layout) => {
              if (layout.type === VocCustomUiLayoutItemType.LayoutGroup) {
                const mappedKey = mapFormulaFieldKey(formulaFieldKey);
                const member = layout.members.find(
                  (member) => member.key === mappedKey
                );
                if (member) {
                  rebuildFormula = rebuildFormula.replace(
                    `{{${formulaFieldKey}}}`,
                    `{{${formulaFieldKey}::${member.name
                      .replace(/\[/g, "(")
                      .replace(/\]/g, ")")}}}`
                  );
                }
              }
            });
          });
        }

        return {
          name: field.key,
          dataType: field.dataType,
          validations: { ...field.validations, formula: rebuildFormula },
          isRequired: field.isRequired,
          isSyncField: field.isSyncField,
        };
      }
      return {
        name: null,
        dataType: null,
        validations: { limits: [] },
        isRequired: false,
        isSyncField: false,
      };
    }
  );

  const [, setProcessCustomUiRequest] = useAsync<
    [VocCustomUi, VocCustomUi[] | undefined, VocCustomUi | undefined]
  >(undefined, {
    onSuccess: (data) => {
      if (data[0].id) {
        notify.actionCompleted(); //   message: `Field ${
        //     schemaModalConfig.editCustomFieldKey ? "updated" : "added"
        //   } successfully.`,
        onScreenDataRefresh();
        onModalClose();
      }
    },
  });

  const processCustomUiForSynchronizedSummary = (
    fieldSchema: VocCustomUiFieldSchema
  ) => {
    if (fieldSchema.isSyncField) {
      // for sync field the json data in related summary ui also need to update
      const relatedSummaryUi = screenData.find(
        (ui) =>
          ui.screen.name ===
          (selectedUi.screen.name ===
          VocScreenNameEnums.COMPENSATION_EVENT_SUMMARY_SCREEN
            ? VocScreenNameEnums.SITE_EVENT_SUMMARY_SCREEN
            : VocScreenNameEnums.COMPENSATION_EVENT_SUMMARY_SCREEN)
      );

      if (schemaModalConfig.editCustomFieldKey) {
        // schema edit: find and update sync field in related screen
        // eslint-disable-next-line array-callback-return
        const syncField = relatedSummaryUi.layout.reduce((acc, group) => {
          if (group.type === VocCustomUiLayoutItemType.LayoutGroup) {
            const fieldMatched = group.members.find(
              (member) => member.key === schemaModalConfig.editCustomFieldKey
            );
            if (fieldMatched) {
              return fieldMatched;
            }
            return acc;
          }
        }, null);
        if (syncField) {
          syncField.key = fieldSchema.name;
          syncField.name = fieldSchema.name;
        }
      } else {
        // add schema: append custom field to generalInformation group in related screen
        const generalInfoGroup = relatedSummaryUi.layout.find(
          (group) =>
            group.key === "generalInformation" &&
            group.type === VocCustomUiLayoutItemType.LayoutGroup
        );
        if (generalInfoGroup.type === VocCustomUiLayoutItemType.LayoutGroup) {
          generalInfoGroup.members.push({
            name: fieldSchema.name,
            key: fieldSchema.name,
            type: VocCustomUiLayoutItemType.QuestionListCustomField,
            dataType: fieldSchema.dataType,
            display: true,
            span: 1,
            isRequired: false,
            schemaId: fieldSchema.id,
            validations: fieldSchema.validations,
            locked: false,
            isSyncField: fieldSchema.isSyncField,
          });
        }
      }
      return updateCustomizedScreen(
        relatedSummaryUi.screen.id,
        relatedSummaryUi.layout,
        serviceConfig
      );
    }
  };

  const getProcessCustomUiForRegisterColumnsApiCall = (
    fieldSchema: VocCustomUiFieldSchema,
    screenName: VocScreenNameEnums
  ) => {
    const registerUi = screenData.find((ui) => ui.screen.name === screenName);
    if (!registerUi.isDefault) {
      for (const col of registerUi.layout) {
        if (
          col.type === VocCustomUiLayoutItemType.DataTableColumn &&
          col.isCustomField &&
          col.key === schemaModalConfig.editCustomFieldKey &&
          col.key !== fieldSchema.name // ensure schema name is updated before calling updateCustomizedScreen
        ) {
          col.key = fieldSchema.name;
          col.name = fieldSchema.name;
          return updateCustomizedScreen(
            registerUi.screen.id,
            registerUi.layout,
            serviceConfig
          );
        }
      }
    }
  };

  const processCustomUiForRegisterColumns = async (
    fieldSchema: VocCustomUiFieldSchema
  ) => {
    const apiCalls: Promise<VocCustomUi>[] = [];
    if (schemaModalConfig.editCustomFieldKey) {
      // for edit mode, update the relevant json entries in register column below
      if (
        selectedUi.screen.name ===
          VocScreenNameEnums.COMPENSATION_EVENT_SUMMARY_SCREEN ||
        selectedUi.screen.name ===
          VocScreenNameEnums.COMPENSATION_EVENT_VO_DETAIL_SCREEN
      ) {
        apiCalls.push(
          getProcessCustomUiForRegisterColumnsApiCall(
            fieldSchema,
            VocScreenNameEnums.COMPENSATION_EVENT_REGISTER_SCREEN
          )
        );
        if (fieldSchema.isSyncField) {
          apiCalls.push(
            getProcessCustomUiForRegisterColumnsApiCall(
              fieldSchema,
              VocScreenNameEnums.SITE_EVENT_REGISTER_SCREEN
            )
          );
        }
      } else if (
        selectedUi.screen.name === VocScreenNameEnums.SITE_EVENT_SUMMARY_SCREEN
      ) {
        apiCalls.push(
          getProcessCustomUiForRegisterColumnsApiCall(
            fieldSchema,
            VocScreenNameEnums.SITE_EVENT_REGISTER_SCREEN
          )
        );
        if (fieldSchema.isSyncField) {
          apiCalls.push(
            getProcessCustomUiForRegisterColumnsApiCall(
              fieldSchema,
              VocScreenNameEnums.COMPENSATION_EVENT_REGISTER_SCREEN
            )
          );
        }
      }

      return Promise.all([...apiCalls]);
    }
  };

  const processCustomUiForSelectedUi = (
    fieldSchema: VocCustomUiFieldSchema
  ) => {
    if (schemaModalConfig.editCustomFieldKey) {
      // update existing
      selectedUi.layout.forEach((item) => {
        if (
          item.key === schemaModalConfig.groupKey &&
          item.type === VocCustomUiLayoutItemType.LayoutGroup
        ) {
          item.members = item.members.map((item) => {
            if (
              item.key === schemaModalConfig.editCustomFieldKey &&
              item.type === VocCustomUiLayoutItemType.QuestionListCustomField
            ) {
              return {
                type: VocCustomUiLayoutItemType.QuestionListCustomField,
                name: fieldSchema.name,
                isRequired: fieldSchema.isRequired,
                validations: fieldSchema.validations,
                schemaId: fieldSchema.id,
                span: 1,
                display: true,
                dataType: fieldSchema.dataType,
                key: fieldSchema.name,
                locked: false,
                isSyncField: fieldSchema.isSyncField,
              };
            }
            return item;
          });
        }
      });
    } else {
      // add to group
      selectedUi.layout.forEach((item) => {
        if (
          item.key === schemaModalConfig.groupKey &&
          item.type === VocCustomUiLayoutItemType.LayoutGroup
        ) {
          item.members = [
            ...item.members,
            {
              type: VocCustomUiLayoutItemType.QuestionListCustomField,
              name: fieldSchema.name,
              isRequired: fieldSchema.isRequired,
              validations: fieldSchema.validations,
              schemaId: fieldSchema.id,
              span: 1,
              display: true,
              dataType: fieldSchema.dataType,
              key: fieldSchema.name,
              locked: false,
              isSyncField: fieldSchema.isSyncField,
            },
          ];
        }
      });
    }

    // check needed only in old version
    // if (selectedUi.isDefault) {
    //   return createCustomizedScreen(
    //     selectedUi.screen.id,
    //     selectedUi.layout,
    //     serviceConfig
    //   );
    // }
    return updateCustomizedScreen(
      selectedUi.screen.id,
      selectedUi.layout,
      serviceConfig
    );
  };

  const [fieldSchemaAsyncResult, setFieldSchemaRequest] =
    useAsync<VocCustomUiFieldSchema>(undefined, {
      onSuccess: (data) => {
        if (data.id) {
          data.isSyncField = form.isSyncField; // set isSyncField manually because it's not available from the api response at the moment
          setProcessCustomUiRequest(() => {
            return Promise.all([
              processCustomUiForSelectedUi(data),
              processCustomUiForRegisterColumns(data),
              processCustomUiForSynchronizedSummary(data),
            ]);
          });
        }
      },
      onError: (ex) => errorHandler(ex, "field schema operation"),
    });

  const getValuesFormulaFields = () =>
    selectedUi.screen.name ===
    VocScreenNameEnums.COMPENSATION_EVENT_VALUES_SCREEN
      ? // eslint-disable-next-line array-callback-return
        selectedUi.layout.reduce((aggr, item) => {
          if (
            item.key === schemaModalConfig.groupKey &&
            item.type === VocCustomUiLayoutItemType.LayoutGroup
          ) {
            return item.members.map((m) => ({
              ...m,
              key:
                m.key === "internalSubstantiated"
                  ? "internalEstimateSubstantiated"
                  : m.key === "varianceIVCA"
                  ? "internalVariance"
                  : m.key === "varianceTCA"
                  ? "targetVariance"
                  : m.key === "costCloseout"
                  ? "costCloseOut"
                  : m.key === "costGnA"
                  ? "costGna"
                  : m.key === "ohp"
                  ? "overheadProfit"
                  : m.key === "cumPApplied"
                  ? "cumulativeApplied"
                  : m.key === "cumVApplied"
                  ? "cumulativeAppliedNominal"
                  : m.key === "cumVCertified"
                  ? "cumulativeValueCertified"
                  : m.key === "varianceAC"
                  ? "cumulativeValueVariance"
                  : m.key,
            }));
          }
        }, [])
      : undefined;

  const prepareFieldSchemaForSubmit = (
    form: Partial<VocCustomUiFieldSchema>
  ) => {
    const objectType =
      selectedUi.screen.name ===
      VocScreenNameEnums.COMPENSATION_EVENT_SUMMARY_SCREEN
        ? VocCustomUiObjectType.Event
        : selectedUi.screen.name ===
          VocScreenNameEnums.COMPENSATION_EVENT_VO_DETAIL_SCREEN
        ? VocCustomUiObjectType.VariationOrder
        : selectedUi.screen.name ===
          VocScreenNameEnums.SITE_EVENT_SUMMARY_SCREEN
        ? VocCustomUiObjectType.SiteEvent
        : selectedUi.screen.name ===
          VocScreenNameEnums.COMPENSATION_EVENT_VALUES_SCREEN
        ? VocCustomUiObjectType.Valuation
        : undefined;
    const schema = { ...form, validations: { ...form.validations } };
    schema.validations.limits =
      schema.dataType === VocCustomUiFieldDataType.Dropdown
        ? schema.validations.limits.filter((item) => item && item.trim() !== "")
        : [];
    if (schema.dataType === VocCustomUiFieldDataType.Formula) {
      schema.validations.formula = schema.validations.formula
        .replace(/::.+?\}\}/g, "}}") // keep only field key
        .replace(/[+\-*/()]/g, " $& ") // add space between symbol
        .replace(/\{\{/g, " $&") // add start space for field
        .replace(/\}\}/g, "$& ") // add end space for field
        .replace(/\s+/g, " "); // remove extra space
    } else {
      schema.validations.formula = undefined;
      schema.validations.output = undefined;
    }
    if (isCreating) {
      return { ...schema, objectType };
    }
    return {
      ...schema,
      objectType: undefined,
      isSyncField: undefined,
      isRequired: undefined,
      dataType: undefined,
    };
  };

  const handleSaveValidated = () => {
    setFieldSchemaRequest(() => {
      if (schemaModalConfig.editCustomFieldKey) {
        // for edit
        return updateCustomFieldSchema(
          getEditField().schemaId,
          prepareFieldSchemaForSubmit(form),
          serviceConfig
        );
      }
      return createCustomFieldSchema(
        prepareFieldSchemaForSubmit(form),
        serviceConfig
      );
    });
  };

  const [validationError, , clearError, saveWithValidate] =
    useValidate<VocCustomUiFieldSchema>(
      form,
      getValidationSchema(
        t,
        selectedUi,
        isCreating,
        getValuesFormulaFields()
      ) as any,
      handleSaveValidated
    );

  const handleOnFormSave = () => {
    saveWithValidate(undefined);
  };

  const handleFormChange = (changeValues: Partial<VocCustomUiFieldSchema>) => {
    if (changeValues.dataType) {
      clearError();
    }

    if (
      changeValues.dataType === VocCustomUiFieldDataType.Formula &&
      !form.validations.output
    ) {
      Object.assign(changeValues, {
        validations: {
          ...form.validations,
          formula: "",
          output: VocCustomUiFormulaOutput.Value,
        },
      });
    } else if (
      changeValues.dataType === VocCustomUiFieldDataType.Dropdown &&
      !form.validations.limits
    ) {
      Object.assign(changeValues, {
        validations: { ...form.validations, limits: [] },
      });
    }
    setForm((prevState) => ({
      ...prevState,
      ...changeValues,
    }));
  };

  return (
    <QuestionGroupFieldSchemaModal
      valuesFormulaFields={getValuesFormulaFields()}
      showFieldSchemaModal={
        hasValue(schemaModalConfig) && hasValue(schemaModalConfig.groupKey)
      }
      isCreating={isCreating}
      form={form}
      validationError={validationError}
      fieldSchemaAsyncResult={fieldSchemaAsyncResult}
      onModalClose={onModalClose}
      onFormChange={handleFormChange}
      onFormSave={handleOnFormSave}
    />
  );
};

export default QuestionGroupFieldSchemaContainer;
