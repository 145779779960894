import React from "react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import { Mention, MentionsInput } from "react-mentions";

import { SendOutlined } from "@ant-design/icons";
import { notify } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../app/hooks/useAppContext";
import { getUserDisplayName } from "../../../app/services/app";
import { useVocContext } from "../../../app/services/VocContext";
import {
  VocChatChannel,
  VocChatMention,
  VocChatMessage,
  VocPostChatRequest,
} from "../../../app/types";
import { mapDateTimeToString } from "../../../app/utils/mappers";

import "./ChatsTab.scss";

interface ChatsTabLayoutProps {
  channels: VocChatChannel[];
  selectedChannel: string;
  changeChannel: Function;
  messages: VocChatMessage[];
  posters: string[];
  users: VocChatMention[];
  mentions: VocChatMention[];
  setMentions: React.Dispatch<React.SetStateAction<VocChatMention[]>>;
  setForm: React.Dispatch<React.SetStateAction<VocPostChatRequest>>;
  setMessagePosted: React.Dispatch<React.SetStateAction<boolean>>;
  messagePosting: boolean;
}

const ChatsTabLayout: React.FC<ChatsTabLayoutProps> = (props) => {
  const {
    channels,
    selectedChannel,
    changeChannel,
    messages,
    posters,
    users,
    mentions,
    setMentions,
    setForm,
    setMessagePosted,
    messagePosting,
  } = props;
  const [message, setMessage] = React.useState<string>("");
  const { activeUser } = useAppContext();
  const vocCtx = useVocContext();
  const { t } = useTranslation();

  function handleEditMessage(event: any) {
    const mentionsCopy = [...mentions];
    mentions.forEach((m, i) => {
      if (event.target.value.indexOf(m.display) === -1) {
        mentionsCopy.splice(i, 1);
      }
    });
    setMentions(mentionsCopy);
    setMessage(event.target.value);
  }

  const sendChat = React.useCallback(() => {
    if (message === "") {
      notify.warning("Please type a message");
      return;
    } else {
      setMessagePosted(true);
      setForm({
        // author: activeUser.id, //window.cpcs.user.id,
        message: message,
        mentions: mentions.map((m) => m.id),
      });
      setMessage("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, mentions]);

  function handleKeyPress(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === "Enter" && !event.shiftKey) {
      sendChat();
      event.preventDefault();
    }
  }

  const messagesRef = React.useRef(null);

  React.useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollIntoView();
    }
  }, [messages]);

  const focusInput = () => {
    const input = document.getElementById("input-message");
    setTimeout(() => {
      input.focus();
    }, 1500);
  };
  return (
    <div className="chats-tab-container">
      <div className="channels">
        {channels.map((c, i) => {
          if (c.eventType !== "divider" && c.id !== undefined) {
            return (
              <div
                key={i}
                className={
                  "channel" +
                  (selectedChannel === String(c.id) ||
                  selectedChannel === c.eventType
                    ? " selected"
                    : "")
                }
                onClick={() => {
                  changeChannel(c.eventType, c.id);
                }}
              >
                {c.serial} : {c.title}
              </div>
            );
          }
          // else if(c.eventType === "divider")
          else {
            if (c.eventType === "divider") {
              return (
                <div key={"divider"} className="divider">
                  {t("voc.chat.relatedEvents")}
                </div>
              );
            } else {
              return <></>;
            }
          }
        })}
      </div>

      <div className="messages">
        {messages.map((msg, i) => (
          <div
            key={i}
            className={
              "message" +
              (msg.author.id.toString() === activeUser.id
                ? " user"
                : " not-user")
            }
          >
            <div className="profile-wrapper">
              <div
                className={
                  "profile " +
                  "color" +
                  posters
                    .indexOf(msg.author.id as string)
                    .toString()
                    .split("")
                    .pop()
                }
              >
                {getUserDisplayName(msg.author).slice(0, 1)}
              </div>
            </div>
            <div className="bubble">
              <div className="meta">
                <div
                  className={`author color${posters.indexOf(
                    msg.author.id as string
                  )}`}
                >
                  {getUserDisplayName(msg.author)}
                </div>
                <div className="timestamp">
                  {mapDateTimeToString(msg.createdOn)} {vocCtx.entityTimezone}
                </div>
              </div>
              <div className="text">
                {msg.message.split("\n").map((s, i: number) => {
                  if (s.indexOf("@[") > -1) {
                    const ss = s.split("@[");
                    return ss.map((substring, i2: number) => {
                      if (i2 === 0) {
                        return <span key={i2}>{substring}</span>;
                      } else {
                        return (
                          <span key={i2}>
                            <span className="highlighted">
                              {substring.split("]")[0]}
                            </span>
                            {substring.split("]")[1]}
                            {i2 === ss.length - 1 ? <br /> : null}
                          </span>
                        );
                      }
                    });
                  }
                  return (
                    <span key={i}>
                      {s}
                      <br />
                    </span>
                  );
                })}
                <br />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="messages-bottom" ref={messagesRef}></div>
      <div className="new-message-wrapper">
        <form
          name="myform"
          className="new-message"
          onSubmit={(e) => {
            e.preventDefault();
            sendChat();
            focusInput();
          }}
        >
          <MentionsInput
            id="input-message"
            className="message-input"
            value={message}
            onChange={handleEditMessage}
            allowSuggestionsAboveCursor={true}
            onKeyPress={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
              handleKeyPress(e);
              focusInput();
            }}
            disabled={messagePosting === true}
          >
            <Mention
              className="mention"
              trigger="@"
              data={users}
              onAdd={(id: string, display: string) => {
                setMentions([...mentions, { id: id, display: display }]);
              }}
              displayTransform={(id: any, display: string) => `@${display}`}
              markup={"@[__display__]"}
            />
          </MentionsInput>
          {messagePosting === true ? (
            <button className="sending-button">
              <SendOutlined className="sending-button-icon" />
            </button>
          ) : (
            <button
              type="submit"
              className="send-button"
              disabled={message.length < 1}
            >
              {t("voc.eventPage.chats.send")}
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default ChatsTabLayout;
