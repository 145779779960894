import {
  NxpButton,
  NxpFormGrid,
  NxpLabel,
  NxpModalFooter,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { Divider, Select } from "antd";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { VocSupportDocumentEnums } from "../../../../../../app/types";
import {
  GeneralSupportingFormState,
  PlantRecordForm,
  recordStatus,
} from "../../new-document-form-type";
import { useGeneralSupportingFormActions } from "./hooks/useGeneralSupportingFormActions";
import { useGeneralSupportingFormItems } from "./hooks/useGeneralSupportingFormItems";
import { useGetGeneralSupportingFormSchema } from "./hooks/useGeneralSupportingFormSchema";

interface NewPlantRecordProps {
  onModalClose: () => void;
  onRefresh: () => void;
  documentType: VocSupportDocumentEnums | "";
}

const NewPlantRecordForm: React.FC<NewPlantRecordProps> = ({
  onModalClose,
  onRefresh,
  documentType,
}) => {
  const { t } = useTranslation();

  const { siteEventId, eventId } = useParams();

  const [formState, setFormState] = useState<PlantRecordForm>({
    fromDate: undefined,
    toDate: undefined,
    subject: "",
    comment: "",
    link: null,
    files: [],
    recordStatus: undefined,
  });

  const { isSubmitting, handleSaveValidated } = useGeneralSupportingFormActions(
    onModalClose,
    onRefresh,
    documentType,
    formState,
    siteEventId,
    eventId
  );

  const formItems = useGeneralSupportingFormItems({
    formState,
    setFormState,
  });

  const formSchema = useGetGeneralSupportingFormSchema();

  const [validationError, , , saveWithValidate] =
    useYupValidate<PlantRecordForm>(formState, formSchema, handleSaveValidated);

  const handleFormGridStateChange = (
    fieldName: keyof GeneralSupportingFormState,
    value: unknown
  ) => {
    setFormState((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleValidateSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  return (
    <>
      <NxpFormGrid
        formItems={formItems}
        formState={formState}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
      <Divider />
      <p>
        <span style={{ display: "block" }}>
          {t("voc.eventPage.newDocument.statusMessage")}
        </span>
        <NxpLabel>{t("voc.eventPage.newDocument.updateRecordStatus")}</NxpLabel>
      </p>
      <Select
        options={recordStatus.map((item) => ({
          label: item.label,
          value: item.value,
        }))}
        placeholder={t("voc.common.pleaseSelect")}
        style={{ width: "100%" }}
        allowClear
        onChange={(value) => handleFormGridStateChange("recordStatus", value)}
      />
      <NxpModalFooter>
        <NxpButton
          disabled={isSubmitting}
          type="default"
          onClick={onModalClose}
        >
          {t("voc.common.cancel")}
        </NxpButton>
        <NxpButton
          disabled={isSubmitting}
          onClick={handleValidateSave}
          type="primary"
        >
          {t("voc.common.save")}
        </NxpButton>
      </NxpModalFooter>
    </>
  );
};

export default NewPlantRecordForm;
