import React, { ReactNode, useState } from "react";

import "./AppButtonSelector.scss";

interface AppButtonSelectorProps<ValueType> {
  defaultValue?: ValueType;
  value?: ValueType;
  items: {
    value: ValueType;
    label: ReactNode;
    itemKey?: string | number; //label.toString will be used if itemKey is undefined
  }[];
  onChange?: (value: ValueType) => void;
}

function AppButtonSelector<ValueType>(
  props: AppButtonSelectorProps<ValueType>
) {
  const { defaultValue, value, items, onChange } = props;
  const [selectedValue, setSelectedValue] = useState<ValueType>(defaultValue);

  const handleChange = (newValue: ValueType) => {
    if (value === undefined) {
      setSelectedValue(newValue);
    }
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <ul className="app-form-button-selector">
      {items.map((item) => (
        <li
          key={
            item.itemKey === undefined ? item.label.toString() : item.itemKey
          }
        >
          <button
            className={
              value === item.value || selectedValue === item.value
                ? "selected"
                : undefined
            }
            onClick={() => handleChange(item.value)}
          >
            {item.label}
          </button>
        </li>
      ))}
    </ul>
  );
}

export default AppButtonSelector;
