import { UploadOutlined } from "@ant-design/icons";
import {
  notify,
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModalFooter,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { Button, Upload } from "antd";
import { isBefore } from "date-fns";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import useAppContext from "../../../../../app/hooks/useAppContext";
import i18n from "../../../../../app/i18n/i18n";
import { VocSupportDocumentEnums } from "../../../../../app/types";
import { getUserDisplayDateFormat } from "../../../../../app/utils/mappers";
import { getFormattedInputNumberControlProps } from "../../../../../app/utils/number/getFormattedInputNumberControlProps";
import { getSiteDocStatuses } from "../../supporting-panels/helpers/supporting-helper";
import { createSupportDocument } from "../new-document-form-services";
import {
  SubcontractorAcaForm,
  SubcontractorSciForm,
} from "../new-document-form-type";
import { useFileHandler } from "./hooks/useFileHandler";

interface NewSciFormProps {
  onModalClose: () => void;
  onRefresh: () => void;
  documentType: VocSupportDocumentEnums | "";
}

const NewSciForm: React.FC<NewSciFormProps> = ({
  onModalClose,
  onRefresh,
  documentType,
}) => {
  const { siteEventId, eventId } = useParams();

  const { errorHandler, serviceConfig } = useAppContext();

  const { t } = useTranslation();

  const [formState, setFormState] = useState<SubcontractorSciForm>({
    amount: undefined,
    documentStatus: undefined,
    documentType: undefined,
    fromDate: undefined,
    link: undefined,
    reference: undefined,
    subcontractor: undefined,
    subject: undefined,
    toDate: undefined,
    files: [],
  });

  const { handleFileChange, handleRemoveFile } =
    useFileHandler<SubcontractorSciForm>(setFormState);

  const formSchema = yup.object().shape({
    amount: yup
      .number()
      .required(t("voc.eventPage.newDocument.subcontractAmountRequired"))
      .positive("Amount must be positive"),
    documentStatus: yup
      .string()
      .required(t("voc.eventPage.newDocument.statusRequired")),
    fromDate: yup.date().required(t("voc.eventPage.newDocument.fromRequired")),
    link: yup.string().url("Enter a valid URL").notRequired(),
    reference: yup
      .string()
      .required(t("voc.eventPage.newDocument.referenceRequired")),
    subcontractor: yup
      .string()
      .required(t("voc.eventPage.newDocument.subcontractorRequired")),
    subject: yup
      .string()
      .required(t("voc.eventPage.newDocument.subjectRequired")),
    toDate: yup.date().required(t("voc.eventPage.newDocument.toRequired")),
  });

  const formItems: NxpFormGridItemProps<SubcontractorSciForm>[] = [
    {
      controlType: "input",
      span: 12,
      label: t("voc.common.reference"),
      itemFieldName: "reference",
      required: true,
      controlProps: {
        value: formState?.reference,
      },
    },
    {
      controlType: "input",
      span: 12,
      label: t("voc.common.subject"),
      itemFieldName: "subject",
      required: true,
      controlProps: {
        value: formState?.subject,
      },
    },
    {
      controlType: "datePicker",
      span: 12,
      label: t("voc.common.from"),
      itemFieldName: "fromDate",
      required: true,
      controlProps: {
        format: getUserDisplayDateFormat(),
        value: formState?.fromDate,
        disabledDate: (current) =>
          current && isBefore(new Date(), current.toDate()),
      },
    },
    {
      controlType: "datePicker",
      span: 12,
      label: t("voc.common.to"),
      itemFieldName: "toDate",
      required: true,
      controlProps: {
        format: getUserDisplayDateFormat(),
        value: formState?.toDate,
        disabledDate: (current) =>
          current && isBefore(new Date(), current.toDate()),
      },
    },
    {
      controlType: "input",
      span: 12,
      label: t("voc.common.subcontractor"),
      itemFieldName: "subcontractor",
      required: true,
      controlProps: {
        value: formState?.subcontractor,
      },
    },
    {
      controlType: "inputNumber",
      span: 12,
      label: t("voc.common.sciAmount"),
      itemFieldName: "amount",
      required: true,
      controlProps: {
        addonBefore: "$",
        value: formState?.amount,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      controlType: "select",
      span: 12,
      label: t("voc.common.status"),
      itemFieldName: "documentStatus",
      required: true,
      controlProps: {
        value: formState?.documentStatus,
        options:
          getSiteDocStatuses(t).map((status) => ({
            label: status.render,
            value: status.enumValue,
          })) || [],
      },
    },
    {
      controlType: "custom",
      span: 12,
      label: "File",
      itemFieldName: "files",
      customContent: (
        <Upload
          beforeUpload={(file) => {
            handleFileChange(file);
            return false; // Prevent automatic upload
          }}
          onRemove={handleRemoveFile}
          fileList={formState?.files.map((file) => ({
            uid: file.assetId,
            name: file.fileName,
            status: "done",
            url: file.url,
          }))}
        >
          {formState?.files.length < 1 && (
            <Button icon={<UploadOutlined />}>
              {t("voc.common.clickToUpload")}
            </Button>
          )}
        </Upload>
      ),
    },
    {
      controlType: "input",
      span: 12,
      label: t("voc.eventPage.supporting.link"),
      itemFieldName: "link",
      controlProps: {
        value: formState?.link,
        placeholder: "https://",
      },
    },
  ];

  const handleSaveValidated = async () => {
    try {
      const idToUse = siteEventId || eventId;
      const isSiteEvent = Boolean(siteEventId);

      await createSupportDocument(
        idToUse,
        {
          documentFiles: formState.files,
          details: {
            amount: formState.amount,
            docStatus: formState?.documentStatus,
            documentType: documentType,
            fromDate: formState?.fromDate,
            link: formState?.link === "" ? null : formState?.link,
            reference: formState?.reference,
            subcontractor: formState?.subcontractor,
            subject: formState?.subject,
            toDate: formState?.toDate,
          },
          documentType: documentType,
        },
        serviceConfig,
        isSiteEvent
      );
      onModalClose();
      notify.actionCompleted();
      onRefresh();
    } catch (error) {
      errorHandler(error, "save document");
    }
  };
  const [validationError, , , saveWithValidate] =
    useYupValidate<SubcontractorAcaForm>(
      formState,
      formSchema,
      handleSaveValidated
    );

  const handleFormGridStateChange = (
    fieldName: keyof SubcontractorSciForm,
    value: unknown
  ) => {
    setFormState((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleValidateSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  return (
    <>
      <NxpFormGrid
        formItems={formItems}
        formState={formState}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
      <NxpModalFooter>
        <NxpButton type="default" onClick={onModalClose}>
          {t("voc.common.cancel")}
        </NxpButton>
        <NxpButton onClick={handleValidateSave} type="primary">
          {t("voc.common.save")}
        </NxpButton>
      </NxpModalFooter>
    </>
  );
};

export default NewSciForm;
