import React from "react";
import AppNumericInputBase from "./AppNumericInputBase";

export interface AppNumericInputProps {
  value: number;
  inputType?: "number" | "money" | "percent";
  allowNegativeValue?: boolean;
  groupSeparator?: string;
  prefix?: string;
  decimalPlaces?: number;

  name?: string;
  disabled?: boolean;
  className?: string;
  onValueChange?: (value: number, name: string) => void;
  onClick?: () => void;
}

const AppNumericInput: React.FC<AppNumericInputProps> = ({
  value,
  inputType = "number",
  allowNegativeValue = true,
  groupSeparator = ",",
  prefix,
  decimalPlaces = 2,
  name,
  disabled,
  className,
  onValueChange,
}) => {
  const handleValueChange = (value: string, name: string) => {
    const numValue = Number.isFinite(Number(value)) ? Number(value) : undefined;
    if (onValueChange) {
      onValueChange(
        inputType === "percent"
          ? Number((numValue / 100).toFixed(decimalPlaces + 2))
          : numValue,
        name
      );
    }
  };

  return (
    <AppNumericInputBase
      className={className}
      value={
        Number.isFinite(value)
          ? inputType === "percent"
            ? (value * 100).toFixed(decimalPlaces)
            : value.toFixed(decimalPlaces)
          : undefined
      }
      disabled={disabled}
      disableGroupSeparators={!groupSeparator}
      allowNegativeValue={allowNegativeValue}
      groupSeparator={groupSeparator}
      prefix={inputType === "money" && !prefix ? "$" : prefix}
      valueUnitLabel={inputType === "percent" ? "%" : undefined}
      decimalScale={decimalPlaces}
      onValueChange={handleValueChange}
      name={name}
    />
  );
};

export default AppNumericInput;
