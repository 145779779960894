import { notify, NxpPanelEditable, NxpSpin } from "@nexploretechnology/nxp-ui";
import { Divider } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import useAppContext from "../../../app/hooks/useAppContext";
import {
  VocCustomUi,
  VocCustomUiQuestionListCustomField,
  VocGetValuesBuildUp,
  VocGetValuesEisReporting,
  VocGetValuesResponse,
  VocGetValuesToClient,
  VocGetValuesToDate,
  VocScreenNameEnums,
} from "../../../app/types";
import { getValues, sendValues } from "../../compensation-event-services";
import { prepareCustomData } from "./helpers/prepareCustomData";
import { prepareValuesForm } from "./helpers/prepareValuesForm";
import { processFields } from "./helpers/processFields";
import ValuesBreakDown from "./value-breakdown/ValuesBreakDown";
import ActualProgressPanel from "./value-panels/ActualProgressPanel";
import BuildUpPanel from "./value-panels/BuildUpPanel";
import CostCodesPanel from "./value-panels/CostCodesPanel";
import CustomFields from "./value-panels/CustomFields";
import EisReportPanel from "./value-panels/EisReportPanel";
import PaymentWithClient from "./value-panels/PaymentWithClient";
import ToClientPanel from "./value-panels/ToClientPanel";
import "./ValuesTab.scss";

const useStyles = createUseStyles((theme) => ({
  title: {
    color: theme.palette.primary,
  },
  disclaimer: {
    marginBottom: theme.spacing(2),
  },
}));

//TODO: Change the folder name to value-tab once the value-beta is stable version
const ValuesTab: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { eventId } = useParams<{ eventId: string }>();
  const { getCustomizedScreen, serviceConfig, errorHandler } = useAppContext();

  // State Variables
  const [fieldNames, setFieldNames] = useState<Record<string, string>>({});
  const [customFieldsLoaded, setCustomFieldsLoaded] = useState<boolean>(false);
  const [customFields, setCustomFields] = useState<
    VocCustomUiQuestionListCustomField[]
  >([]);
  const [customValues, setCustomValues] = useState<Record<string, any> | null>(
    null
  );

  const [toClientFormState, setToClientFormState] =
    useState<VocGetValuesToClient | null>(null);

  const [buildUpFormState, setBuildUpFormState] =
    useState<VocGetValuesBuildUp | null>(null);

  const [costCodes, setCostCodes] = useState<{ costCodes: string[] }>();

  const [isIncludedInReports, setIsIncludedInReports] =
    useState<Pick<VocGetValuesEisReporting, "includedInReports">>();

  const [eisReportingFormState, setEisReportingFormState] = useState<Pick<
    VocGetValuesEisReporting,
    | "eisA2A6"
    | "in16"
    | "eisA8A14"
    | "eisSecuredProject"
    | "eisStrategyIncludedInPlanning"
  > | null>(null);

  const [actualValues, setActualValues] = useState<{
    physicalProgress: number;
    internalProgressValue: number;
  } | null>(null);

  const [paymentWithClientFormState, setPaymentWithClientFormState] =
    useState<VocGetValuesToDate | null>(null);

  const [incomingDataPackage, setIncomingDataPackage] =
    useState<VocGetValuesResponse | null>(null);

  // Fetch Customization Data
  useEffect(() => {
    const fetchCustomization = () => {
      try {
        const customizedScreen: VocCustomUi = getCustomizedScreen(
          VocScreenNameEnums.COMPENSATION_EVENT_VALUES_SCREEN
        );

        const { fieldLookup, customs } = processFields(customizedScreen, t);
        setFieldNames(fieldLookup);
        setCustomFields(customs);
        setCustomFieldsLoaded(true);
      } catch (err) {
        errorHandler(err, "fetch customization");
      }
    };
    fetchCustomization();
  }, [getCustomizedScreen, errorHandler, t]);

  // Fetch Values Data
  const fetchValues = useCallback(async () => {
    try {
      const response = await getValues(eventId, serviceConfig);
      setIncomingDataPackage(response);
      setToClientFormState(response.toClient);
      setBuildUpFormState(response.buildUp);
      setCostCodes({ costCodes: response.costCodeList });
      setIsIncludedInReports({
        includedInReports: response.eisReporting.includedInReports,
      });
      setEisReportingFormState(response.eisReporting);
      setActualValues({
        physicalProgress: response.eisReporting.physicalProgress,
        internalProgressValue: response.eisReporting.internalProgressValue,
      });
      setPaymentWithClientFormState(response.valuesToDate);
    } catch (err) {
      errorHandler(err, "fetch values");
    }
  }, [eventId, serviceConfig, errorHandler]);

  useEffect(() => {
    fetchValues();
  }, [fetchValues]);

  // Compute Custom Values
  const computedCustomValues = useMemo(() => {
    if (
      customFieldsLoaded &&
      incomingDataPackage &&
      incomingDataPackage.customization
    ) {
      const cvs: Record<string, any> = {};
      customFields.forEach((f) => {
        if (incomingDataPackage.customization[f.key] !== undefined) {
          cvs[f.key] = incomingDataPackage.customization[f.key];
        }
      });
      return cvs;
    }
    return null;
  }, [customFieldsLoaded, incomingDataPackage, customFields]);

  useEffect(() => {
    if (computedCustomValues) {
      setCustomValues(computedCustomValues);
    }
  }, [computedCustomValues]);

  // Handle Cancel
  const handleCancel = () => {
    if (incomingDataPackage) {
      setToClientFormState(incomingDataPackage.toClient);
      setBuildUpFormState(incomingDataPackage.buildUp);
      setCostCodes({ costCodes: incomingDataPackage.costCodeList });
      setIsIncludedInReports({
        includedInReports: incomingDataPackage.eisReporting.includedInReports,
      });
      setEisReportingFormState(incomingDataPackage.eisReporting);
      setActualValues({
        physicalProgress: incomingDataPackage.eisReporting.physicalProgress,
        internalProgressValue:
          incomingDataPackage.eisReporting.internalProgressValue,
      });
      setPaymentWithClientFormState(incomingDataPackage.valuesToDate);
      setCustomValues(computedCustomValues);
    }
  };

  // Handle Save
  const handleSave = async (setEditing: (editing: boolean) => void) => {
    try {
      const customFullSend = prepareCustomData(customFields, customValues);
      const valuesForm = prepareValuesForm(
        customFullSend,
        toClientFormState,
        buildUpFormState,
        paymentWithClientFormState,
        eisReportingFormState,
        isIncludedInReports,
        actualValues,
        costCodes
      );
      await sendValues(eventId, valuesForm, serviceConfig);
      notify.actionCompleted();
      setEditing(false);
    } catch (err) {
      errorHandler(err, "save values");
    } finally {
      fetchValues();
    }
  };

  return (
    <div className="values-tab-container">
      <div className="disclaimer">{t("voc.eventPage.values.disclaimer")}</div>
      <NxpPanelEditable
        titleContent={
          t("voc.eventPage.tabValues") +
          " " +
          t("voc.eventPage.values.at100PercentCompletionHeader")
        }
        onPanelSave={handleSave}
        onPanelCancel={handleCancel}
      >
        {(editing) => (
          <NxpSpin spinning={!incomingDataPackage}>
            <ToClientPanel
              fieldNames={fieldNames}
              editing={editing}
              toClientFormState={toClientFormState}
              onSetToClientFormState={setToClientFormState}
              onSetBuildUpFormState={setBuildUpFormState}
              onSetActualValues={setActualValues}
              onSetEisReportingFormState={setEisReportingFormState}
              onSetPaymentWithClientFormState={setPaymentWithClientFormState}
            />
            <Divider />
            <BuildUpPanel
              editing={editing}
              fieldNames={fieldNames}
              buildUp={buildUpFormState}
              internalTarget={toClientFormState?.internalTarget}
              onSetBuildUpFormState={setBuildUpFormState}
            />
            {customFieldsLoaded &&
              customValues &&
              customFields &&
              customFields.length > 0 && (
                <>
                  <Divider />
                  <CustomFields
                    customFields={customFields}
                    customValues={customValues}
                    onSetCustomValues={setCustomValues}
                    isEditingValues={editing}
                  />
                </>
              )}
            <Divider />
            {!editing && !isIncludedInReports ? (
              <>
                <h4 className={classes.title}>
                  {t("voc.eventPage.values.includedInEIS")}
                </h4>
                {t("voc.eventPage.values.includedInEIS")}
              </>
            ) : (
              <EisReportPanel
                editing={editing}
                fieldNames={fieldNames}
                includedEISorFPM={isIncludedInReports}
                onSetIsIncludedInReports={setIsIncludedInReports}
                eisReportingFormState={eisReportingFormState}
                onSetEisReportingFormState={setEisReportingFormState}
                internalTarget={toClientFormState?.internalTarget}
              />
            )}
            <Divider />
            {incomingDataPackage && (
              <CostCodesPanel
                editing={editing}
                fieldNames={fieldNames}
                incomingDataPackage={incomingDataPackage}
                costCodes={costCodes}
                onSetCostCodes={setCostCodes}
              />
            )}
            <Divider />
            <ActualProgressPanel
              editing={editing}
              fieldNames={fieldNames}
              actualValues={actualValues}
              onSetActualValues={setActualValues}
              internalTarget={toClientFormState?.internalTarget}
            />
            <Divider />
            <PaymentWithClient
              editing={editing}
              fieldNames={fieldNames}
              paymentWithClientFormState={paymentWithClientFormState}
              onSetPaymentWithClientFormState={setPaymentWithClientFormState}
              internalTarget={toClientFormState?.internalTarget}
            />
          </NxpSpin>
        )}
      </NxpPanelEditable>
      {incomingDataPackage && (
        <ValuesBreakDown
          records={incomingDataPackage.records}
          toClientFormState={toClientFormState}
          actualValues={actualValues}
          eisReportingFormState={eisReportingFormState}
          paymentWithClientFormState={paymentWithClientFormState}
          onSetCustomValues={setCustomValues}
          onSetToClientFormState={setToClientFormState}
          onSetBuildUpFormState={setBuildUpFormState}
          onSetEisReportingFormState={setEisReportingFormState}
          onSetPaymentWithClientFormState={setPaymentWithClientFormState}
          onSetCostCodes={setCostCodes}
          onSetActualValues={setActualValues}
          onSetIncomingDataPackage={setIncomingDataPackage}
        />
      )}
    </div>
  );
};

export default ValuesTab;
