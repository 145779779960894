import { convertToJsonBody } from "../../../../app/services/convert-to-json-body";
import {
  VocSupportDocument,
  VocUpdateSupportDocument,
} from "../../../../app/types";
import {
  apiRequest,
  ServiceConfig,
} from "../../../../app/utils/backend/api-request";
import { mapDateToString } from "../../../../app/utils/mappers";

export const editEventSupportDocuments = (
  eventId: string,
  serviceConfig: ServiceConfig,
  supportDocUpdate: VocUpdateSupportDocument
): Promise<VocUpdateSupportDocument> => {
  const supportDocumentWithConvertedDates = supportDocUpdate.documents.map(
    (doc) => {
      const updatedDetails = {
        ...doc.details,
        documentDate: mapDateToString(doc.details.documentDate),
        fromDate: mapDateToString(doc.details.fromDate),
        toDate: mapDateToString(doc.details.toDate),
      };

      return {
        ...doc,
        details: updatedDetails,
      };
    }
  );

  const updatedSupportDocuments = {
    ...supportDocUpdate,
    documents: supportDocumentWithConvertedDates,
  };

  return apiRequest<VocUpdateSupportDocument>(
    `/events/${eventId}/support_document`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PATCH",
      body: convertToJsonBody(
        updatedSupportDocuments,
        serviceConfig.timezoneHourAdjustment
      ),
    }
  );
};

export const editSiteEventSupportDocuments = (
  siteEventId: string,
  serviceConfig: ServiceConfig,
  supportDocUpdate: VocUpdateSupportDocument
): Promise<VocUpdateSupportDocument> => {
  const supportDocumentWithConvertedDates = supportDocUpdate.documents.map(
    (doc) => {
      const updatedDetails = {
        ...doc.details,
        documentDate: mapDateToString(doc.details.documentDate),
        fromDate: mapDateToString(doc.details.fromDate),
        toDate: mapDateToString(doc.details.toDate),
      };

      return {
        ...doc,
        details: updatedDetails,
      };
    }
  );

  const updatedSupportDocuments = {
    ...supportDocUpdate,
    documents: supportDocumentWithConvertedDates,
  };

  return apiRequest<VocUpdateSupportDocument>(
    `/site_events/${siteEventId}/support_document`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PATCH",
      body: JSON.stringify(updatedSupportDocuments),
    }
  );
};

export const deleteSupportDocumentsById = (
  eventId: string,
  docId: string | number,
  type: "site_events" | "events",
  serviceConfig: ServiceConfig
): Promise<VocSupportDocument[]> => {
  return apiRequest<VocSupportDocument[]>(
    `/${type}/${eventId}/support_document/${docId}`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "DELETE",
    }
  );
};
