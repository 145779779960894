import React, { useEffect, useState } from "react";

import { UserOutlined } from "@ant-design/icons";
import {
  DateFormatPattern,
  notify,
  NxpDateDisplay,
  NxpTable,
  NxpTableRowDropdown,
  sorterForDate,
  sorterForNumber,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Menu } from "antd";
import { ColumnProps } from "antd/lib/table";

import useAppContext from "../../app/hooks/useAppContext";
import { DemoItem, getDemoLongList } from "../demo-services";

const columns: ColumnProps<DemoItem>[] = [
  {
    title: "name",
    dataIndex: "name",
    width: 150,
    fixed: "left",
    sorter: (a, b) => sorterForString(a.name, b.name),
  },
  {
    title: "birthday",
    dataIndex: "birthday",
    width: 150,
    sorter: (a, b) => sorterForDate(a.birthday, b.birthday),
    render: (val) => (
      <NxpDateDisplay date={val} format={DateFormatPattern.date} />
    ),
  },
  {
    title: "age",
    dataIndex: "age",
    width: 150,
    sorter: (a, b) => sorterForNumber(a.age, b.age),
  },
  {
    title: "address",
    dataIndex: "address",
    width: 300,
  },
  {
    title: "address2",
    dataIndex: "address",
    width: 300,
  },
  {
    title: "address3",
    dataIndex: "address",
    width: 300,
  },
  {
    title: "address4",
    dataIndex: "address",
    width: 300,
  },
  {
    title: "address5",
    dataIndex: "address",
    width: 300,
  },
  {
    fixed: "right",
    align: "right",
    title: "action",
    dataIndex: "action",
    width: 100,
    render: (_, record) => (
      <NxpTableRowDropdown
        onDropdownTriggerClick={(e) => {
          e.stopPropagation();
        }}
        overlay={
          <Menu
            onClick={(e) =>
              notify.info(
                `Action item ${e.keyPath} clicked for record ${JSON.stringify(
                  record
                )}.`
              )
            }
          >
            <Menu.Item key="1" icon={<UserOutlined />}>
              1st menu item
            </Menu.Item>
            <Menu.Item key="2" icon={<UserOutlined />}>
              2nd menu item
            </Menu.Item>
            <Menu.Item key="3" icon={<UserOutlined />}>
              3rd menu item
            </Menu.Item>
          </Menu>
        }
      />
    ),
  },
];

interface TableListingProps {}

const TableListing: React.FC<TableListingProps> = () => {
  const appContext = useAppContext();
  const [demoItems, setDemoItems] = useState<DemoItem[]>();

  useEffect(() => {
    getDemoLongList().then((data) => setDemoItems(data));
  }, [appContext.serviceConfig]);

  return (
    <NxpTable
      useDefaultHeight
      pagination={{ pageSize: 10 }}
      columns={columns} //any for fixing antd type error
      dataSource={demoItems}
      onRow={(record) => ({
        onClick: () => {
          console.log("rowClickrowClickrowClickrowClick", record);
        },
      })}
    />
  );
};

export default React.memo(TableListing);
