import React from "react";

import classNames from "classnames";

import { FilterOption } from "../../../app/components/app-filter";
import { VocValueRegister } from "../../../app/types";
// import { useTranslation } from "react-i18next";
import { SortParam, ValueRegisterColumn } from "./ValueRegisterContainer";

import "./ValueRegisterRow.scss";

interface ValueRegisterHeaderRowProps {
  columns: ValueRegisterColumn[];
  filterOptions: FilterOption[];
  sortParam: SortParam;
  onHeaderDrop: (
    e: React.DragEvent<HTMLDivElement>,
    toCol: keyof VocValueRegister
  ) => void;
  onSortChange: (sortParam: SortParam) => void;
}

const ValueRegisterHeaderRow: React.FC<ValueRegisterHeaderRowProps> = ({
  columns,
  onHeaderDrop,
  onSortChange,
  filterOptions,
  sortParam,
}) => {
  const handleHeaderDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    col: keyof VocValueRegister
  ) => {
    e.dataTransfer.setData("column", col);
  };

  // const { t } = useTranslation();
  const handleHeaderDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!e.currentTarget.className.includes(" drag-over")) {
      e.currentTarget.className += " drag-over";
    }
  };

  const handleHeaderDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.className = e.currentTarget.className.replace(
      / drag-over/g,
      ""
    );
  };

  const getSortParam = (fieldName: keyof VocValueRegister): SortParam => {
    if (!sortParam || sortParam.column !== fieldName) {
      return { column: fieldName, order: "asc" };
    }
    if (sortParam.order === "asc") {
      return { column: fieldName, order: "desc" };
    }
    if (sortParam.order === "desc") {
      return undefined;
    }
  };

  const getSortClassName = (fieldName: keyof VocValueRegister): string => {
    if (sortParam?.column === fieldName) {
      return `sort sort-${sortParam.order}`;
    } else {
      return "sort";
    }
  };
  return (
    <div role="row" className="header">
      {columns.map((col) => {
        const dragDropProps = !col.readonly
          ? {
              draggable: true,
              onDrop: (e: React.DragEvent<HTMLDivElement>) =>
                onHeaderDrop(e, col.fieldName),
              onDragStart: (e: React.DragEvent<HTMLDivElement>) =>
                handleHeaderDragStart(e, col.fieldName),
              onDragLeave: handleHeaderDragLeave,
              onDragOver: handleHeaderDragOver,
            }
          : undefined;
        return (
          <div
            key={col.fieldName}
            role="columnheader"
            {...dragDropProps}
            onClick={() => onSortChange(getSortParam(col.fieldName))}
            className={classNames(
              getSortClassName(col.fieldName),
              filterOptions.find(
                (option) => option.fieldName === col.fieldName
              ) && "filtered",
              col.fieldName === "eventTitle" && "title"
            )}
          >
            {/* <span>{t(`voc.valueRegister.${col.fieldName}`) || col.name}</span> */}
            <span>{col.name}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ValueRegisterHeaderRow;
