import React from "react";

import { LibrarySource } from "../../../libraries/libraries-services";
import {
  CPCSUser,
  Entity,
  EntityUser,
  Right,
  RoleList,
} from "../../services/app";
import {
  Dictionary,
  DictionaryItem,
  DictionaryTypeName,
} from "../../services/dictionary";
import { VocCustomUi, VocGetDictionary } from "../../types";

export type AppContextCacheLibraries = Partial<
  Record<LibrarySource, VocGetDictionary[]>
>;

export interface AppContextCache {
  users: CPCSUser[];
  entityUsers: EntityUser[];
  activeEntity?: Entity;
  activeUser?: CPCSUser;
  libraries: AppContextCacheLibraries;
  dictionaries: Dictionary[];
  customizedScreens: VocCustomUi[];
  myRightList: RoleList[];
}

export type AppContextType = AppContextCache & {
  onAppContextCacheUpdate: React.Dispatch<
    React.SetStateAction<AppContextCache>
  >;
  onAppContextCacheItemUpdate: <K extends keyof AppContextCache>(
    stateItem: K,
    itemValue: AppContextCache[K]
  ) => void;
  getCustomizedScreen: (screenName: string) => VocCustomUi | undefined;
  getDictionary: (dictionaryName: DictionaryTypeName) => Dictionary | undefined;
  getLibraryItemByKeyValue: (
    source: LibrarySource,
    keyValue: string
  ) => VocGetDictionary;
  getDictionaryItemByKey: (
    dictionaryName: DictionaryTypeName,
    itemKey: string
  ) => DictionaryItem;
  hasRight: (rightType: Right | "demo-view") => boolean;
};

const AppContext = React.createContext<AppContextType>({} as AppContextType);

export default AppContext;
