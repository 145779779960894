import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpAlert, NxpModal } from "@nexploretechnology/nxp-ui";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";
import AppButton, { AppButtonType } from "../../../app/components/app-button";
import AppExportExcelButton from "../../../app/components/app-export-excel-button/AppExportExcelButton";
import AppFilter, { FilterOption } from "../../../app/components/app-filter";
import AppHeader from "../../../app/components/app-header";
import useAppContext from "../../../app/hooks/useAppContext";
import { AsyncResult } from "../../../app/hooks/useAsync";
import { VocValueRegister } from "../../../app/types";
import BatchPaymentReferenceButton from "./BatchPaymentReference/BatchPaymentReferenceButton";
import ImportExcelButton from "./ImportExcel/ImportExcelButton";
import { SortParam, ValueRegisterColumn } from "./ValueRegisterContainer";
import ValueRegisterFooterRow from "./ValueRegisterFooterRow";
import ValueRegisterHeaderRow from "./ValueRegisterHeaderRow";
import ValueRegisterInputRowList from "./ValueRegisterInputRowList";
import ValueRegisterLoader from "./ValueRegisterLoader";

import "./ValueRegisterLayout.scss";

interface ValueRegisterLayoutProps {
  columns: ValueRegisterColumn[];
  valueRegisterList: VocValueRegister[];
  changeTracker: number[];
  sortParam: SortParam;
  patchValueRegisterAsyncResult: AsyncResult;
  filterOptions: FilterOption[];
  onValueChange: (
    newValue: number | string | boolean,
    prevValue: number | string | boolean,
    fieldName: keyof VocValueRegister,
    rowIndex: number,
    data: VocValueRegister
  ) => void;
  onHeaderDrop: (
    e: React.DragEvent<HTMLDivElement>,
    toCol: keyof VocValueRegister
  ) => void;
  onSortChange: (sortParam: SortParam) => void;
  onFilterChange: (filterOptions: FilterOption[]) => void;
  onCancel: () => void;
  onSave: () => void;
  onExportExcel: () => void;
  recordSummaryModalOn: boolean;
  setRecordSummaryModalOn: React.Dispatch<React.SetStateAction<boolean>>;
  snapshotExistsWarning: boolean;
  setSnapshotExistsWarning: React.Dispatch<React.SetStateAction<boolean>>;
  handlePostSnapshot: (month: number, year: number) => void;
  setIsFutureMonth: React.Dispatch<React.SetStateAction<boolean>>;
  isFutureMonth: boolean;
}

const ValueRegisterLayout: React.FC<ValueRegisterLayoutProps> = ({
  columns,
  valueRegisterList,
  changeTracker,
  sortParam,
  patchValueRegisterAsyncResult,
  filterOptions,
  onValueChange,
  onHeaderDrop,
  onSortChange,
  onFilterChange,
  onCancel,
  onSave,
  onExportExcel,
  recordSummaryModalOn,
  setRecordSummaryModalOn,
  snapshotExistsWarning,
  setSnapshotExistsWarning,
  handlePostSnapshot,
  setIsFutureMonth,
  isFutureMonth,
}) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const { t } = useTranslation();
  const { serviceConfig } = useAppContext();
  const setWrapperAndGridDimension = () => {
    wrapperRef.current.style.height = `${window.innerHeight - 280}px`;
    (
      wrapperRef.current.childNodes[0] as HTMLElement
    ).style.width = `${wrapperRef.current.scrollWidth}px`;
  };

  useEffect(() => {
    setWrapperAndGridDimension();
    window.addEventListener("resize", setWrapperAndGridDimension);
    // window.addEventListener('scroll', setWrapperHeight);
    return () => {
      window.removeEventListener("resize", setWrapperAndGridDimension);
    };
  }, []);

  // for keeping scroll top position after cancel and save operation
  const scrollTopRef = useRef(0);
  useEffect(() => {
    if (valueRegisterList && valueRegisterList.length > 0) {
      wrapperRef.current.scrollTop = scrollTopRef.current;
    }
  }, [valueRegisterList]);

  const inputIdPrefix = "voc-value-register";

  const focusInput = (fieldName: keyof VocValueRegister, rowIndex: number) => {
    const input = document.getElementById(
      `${inputIdPrefix}-${fieldName}-${rowIndex}`
    );
    if (input) {
      input.focus();
    }
  };

  const moveUp = useCallback(
    (fieldName: keyof VocValueRegister, rowIndex: number) => {
      if (rowIndex > 0) {
        focusInput(fieldName, rowIndex - 1);
      }
    },
    []
  );

  const moveDown = useCallback(
    (fieldName: keyof VocValueRegister, rowIndex: number) => {
      focusInput(fieldName, rowIndex + 1);
    },
    []
  );

  const handleInputFocus = useCallback(
    (
      e: React.FocusEvent<HTMLInputElement | HTMLAnchorElement>,
      fieldName: keyof VocValueRegister
    ) => {
      const wrapper = wrapperRef.current; //e.currentTarget.parentNode.parentNode.parentNode.parentNode as Element;

      // scroll left to avoid input being overshadowed by the fixed columns on left
      if (
        columns.indexOf(
          columns.find((column) => column.fieldName === fieldName)
        ) === 3
      ) {
        wrapper.scrollLeft = 0;
      } else if (e.currentTarget.offsetLeft - wrapper.scrollLeft < 380) {
        wrapper.scrollLeft -= 200;
      }

      if (
        e.currentTarget.parentElement.parentElement.offsetTop -
          wrapper.scrollTop <
        60
      ) {
        // scroll up to avoid input being overshadowed by the fixed header row
        wrapper.scrollTop -= e.currentTarget.parentElement.offsetHeight + 10;
      } else if (
        e.currentTarget.parentElement.parentElement.offsetTop + 300 >
          window.innerHeight &&
        e.currentTarget.parentElement.parentElement.offsetTop -
          wrapper.scrollTop +
          275 >
          window.innerHeight
      ) {
        // scroll down to avoid input being overshadowed by the fixed footer row
        wrapper.scrollTop += e.currentTarget.parentElement.offsetHeight + 15;
      }
    },
    [columns]
  );

  const handleInputKeyDown = useCallback(
    (
      e: React.KeyboardEvent<HTMLInputElement | HTMLAnchorElement>,
      fieldName: keyof VocValueRegister,
      rowIndex: number
    ) => {
      switch (e.code) {
        // case 37: // left
        //   moveLeft(e.currentTarget, 1);
        //   break;
        case "ArrowUp": // up
          moveUp(fieldName, rowIndex);
          break;
        // case 39: // right
        case "NumpadEnter": // enter
        case "Enter":
        case "ArrowDown": // down
          moveDown(fieldName, rowIndex);
          break;
        case "escape": // escape
          e.currentTarget.parentElement.focus();
      }
    },
    [moveDown, moveUp]
  );

  // state for updating footer row to keep totals in sync
  const [totalUpdateArgs, setTotalUpdateArgs] = useState<{
    newValue: number | string | boolean;
    prevValue: number | string | boolean;
    fieldName: keyof VocValueRegister;
    valueRegister: VocValueRegister;
  }>();

  useEffect(() => {
    if (totalUpdateArgs) {
      // clear used totalUpdateArgs
      setTotalUpdateArgs(undefined);
    }
  }, [totalUpdateArgs]);

  const handleValueChange = useCallback(
    (
      newValue: number | string | boolean,
      prevValue: number | string | boolean,
      fieldName: keyof VocValueRegister,
      rowIndex: number,
      data: VocValueRegister
    ) => {
      setTotalUpdateArgs({
        newValue,
        prevValue,
        fieldName,
        valueRegister: data,
      });
      onValueChange(newValue, prevValue, fieldName, rowIndex, data);
    },
    [onValueChange]
  );

  const handleCancel = () => {
    scrollTopRef.current = wrapperRef.current.scrollTop;
    onCancel();
  };

  // {/* temporary removed because of difficulties in mirgrating cpcs-common-ui */}
  const handleSave = () => {
    scrollTopRef.current = wrapperRef.current.scrollTop;
    onSave();
  };

  ///////////////////////
  //RECORD SUMMARY VALUES
  const [selectedDate, setSelectedDate] = React.useState<Date>();

  // {/* temporary removed because of difficulties in mirgrating cpcs-common-ui */}
  const onSelectDate = React.useCallback(
    (date: Date): void => {
      setSnapshotExistsWarning(false);
      setSelectedDate(date);
    },
    [setSnapshotExistsWarning]
  );

  return (
    <div>
      <AppHeader localeResourceId={t("voc.valuesRegister.valuesRegister")}>
        <div className="voc-value-register-buttons">
          {changeTracker.length ? (
            <>
              {patchValueRegisterAsyncResult.error && (
                <p className="error">
                  {patchValueRegisterAsyncResult.error.message}
                </p>
              )}
              <AppButton
                onClick={handleCancel}
                appButtonType={AppButtonType.Basic}
                disabled={patchValueRegisterAsyncResult.loading}
              >
                {t("common.button.cancel")}
              </AppButton>

              <AppButton
                onClick={handleSave}
                disabled={patchValueRegisterAsyncResult.loading}
              >
                {t("voc.common.save")}
              </AppButton>
            </>
          ) : (
            <>
              <AppFilter
                filterOptions={filterOptions}
                onFilterChange={onFilterChange}
                filterColumns={columns}
              />
              <ImportExcelButton
                entityId={serviceConfig.entityId}
                onCancel={handleCancel}
              />

              <AppExportExcelButton onExportExcel={onExportExcel} />
              <AppButton
                onClick={() => {
                  setRecordSummaryModalOn(true);
                }}
              >
                {t("voc.value.layout.recordSummaryValues")}
              </AppButton>
              <AccessVisible objectCode="compensation_event" actionType="edit">
                <BatchPaymentReferenceButton
                  valueRegisterList={valueRegisterList}
                  onRefresh={handleCancel}
                />
              </AccessVisible>
            </>
          )}
        </div>
      </AppHeader>

      <div className="voc-value-event-register-container">
        <div className="disclaimer-register">
          {t("voc.eventPage.values.disclaimer")}
        </div>

        <div className="voc-value-register" ref={wrapperRef}>
          <div role="grid">
            <ValueRegisterHeaderRow
              columns={columns}
              filterOptions={filterOptions}
              sortParam={sortParam}
              onHeaderDrop={onHeaderDrop}
              onSortChange={onSortChange}
            />
            {!valueRegisterList ? (
              <ValueRegisterLoader />
            ) : !valueRegisterList.length ? (
              <div role="row" className="voc-value-register-empty">
                <div role="gridcell" className="read-only">
                  <span>{t("voc.value.layout.noRecordFound")}</span>
                </div>
              </div>
            ) : (
              <>
                <ValueRegisterInputRowList
                  // localeContext={localeContext}
                  columns={columns}
                  valueDataList={valueRegisterList}
                  inputIdPrefix={inputIdPrefix}
                  onValueChange={handleValueChange}
                  onInputFocus={handleInputFocus}
                  onInputKeyDown={handleInputKeyDown}
                />
                <ValueRegisterFooterRow
                  totalsUpdateArgs={totalUpdateArgs}
                  columns={columns}
                  valueRegisterList={valueRegisterList}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <NxpModal
        open={recordSummaryModalOn}
        title={t("voc.value.layout.recordSummaryValues")}
        onCancel={() => {
          setRecordSummaryModalOn(false);
        }}
        // mandatoryHintReplacement={null}
        footer={
          <>
            {snapshotExistsWarning ? (
              <NxpAlert
                type="warning"
                message={t("voc.value.layout.warning.snapshot")}
              />
            ) : null}
            <AppButton
              disabled={!selectedDate || isFutureMonth}
              className="button"
              onClick={() => {
                handlePostSnapshot(
                  selectedDate.getMonth(),
                  selectedDate.getFullYear()
                );
              }}
              text={
                (snapshotExistsWarning
                  ? t("voc.value.layout.overwrite")
                  : t("voc.value.layout.record")) +
                t("voc.value.layout.summaryForMonth")
              }
            />
          </>
        }
      >
        <DatePicker
          value={dayjs(selectedDate)}
          allowClear={false}
          onChange={(date, dateString: string) => {
            const currentDate = new Date();
            let currentMonth = currentDate.getMonth();
            let currentYear = currentDate.getFullYear();
            if (
              (currentYear <= date.year() && currentMonth < date.month()) ||
              (currentYear < date.year() && currentMonth > date.month())
            ) {
              setIsFutureMonth(true);
            } else {
              setIsFutureMonth(false);
            }
            onSelectDate(new Date(dateString));
          }}
          picker="month"
        />
      </NxpModal>
    </div>
  );
};

export default React.memo(ValueRegisterLayout);
