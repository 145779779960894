import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { notify } from "@nexploretechnology/nxp-ui";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react";

import AppButton from "../../../../app/components/app-button/AppButton";
import ValueExcelUpload from "../../../../app/components/app-file-upload/ValueExcelUpload";
import AppModal from "../../../../app/components/app-modal/AppModal";
import useAppContext from "../../../../app/hooks/useAppContext";
import { useAsync } from "../../../../app/hooks/useAsync";
import { VocMapping, VocValueRegister } from "../../../../app/types";
import { updateValueRegisterList } from "../../../compensation-event-services";
import { RemarkAttachment } from "./ImportExcelContainer";

import "./importExcelModal.scss";

interface ImportExcelModalProps {
  showImportExcelModal: boolean;
  stage: number;
  file: Partial<RemarkAttachment>[];
  refColumn: string;
  fieldsToMap: VocMapping[];
  fieldToMapOptions: IDropdownOption[];
  aggregateValues: any;
  decodedValues: Partial<VocValueRegister>[];
  setFile: React.Dispatch<React.SetStateAction<Partial<RemarkAttachment>[]>>;
  setStage: React.Dispatch<React.SetStateAction<number>>;
  setRefColumn: React.Dispatch<React.SetStateAction<string>>;
  setFieldsToMap: React.Dispatch<React.SetStateAction<VocMapping[]>>;
  addFieldToMap: () => void;
  setDecodedValues: React.Dispatch<
    React.SetStateAction<Partial<VocValueRegister>[]>
  >;
  setAggregateValues: React.Dispatch<
    React.SetStateAction<Partial<VocValueRegister>>
  >;
  onModalClose: () => void;
  onCancel: () => void;
}

const ImportExcelModal: React.FC<ImportExcelModalProps> = (props) => {
  const {
    showImportExcelModal,
    stage,
    file,
    fieldsToMap,
    fieldToMapOptions,
    aggregateValues,
    decodedValues,
    refColumn,
    setFieldsToMap,
    setFile,
    setStage,
    setRefColumn,
    setDecodedValues,
    setAggregateValues,
    addFieldToMap,
    onModalClose,
    onCancel,
  } = props;

  const [extension, setExtension] = React.useState<string>(null);
  const [csvFile, setCsvFile] = useState<File>();
  const { errorHandler, serviceConfig } = useAppContext();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (csvFile) {
      setExtension(csvFile?.type);
    }
  }, [csvFile, csvFile?.type, file]);

  function checkValidColumnInput(input: string) {
    let validInput = true;
    for (let i = 0; i < input.length; i++) {
      if (!input[i].match(/[a-z]/i)) {
        validInput = false;
      }
    }
    return validInput;
  }

  const [, setPatchValueRegisterRequest] = useAsync<VocValueRegister[]>(
    undefined,
    {
      onSuccess: () => {
        notify.actionCompleted();
        onCancel();
      },
      onError: (ex) =>
        errorHandler(ex, t("voc.value.importExcelModal.error.updateRegister")),
    }
  );

  return (
    <AppModal
      className="import-excel-modal--value-register"
      title={t("voc.value.importExcelModal.title")}
      isOpen={showImportExcelModal}
      onClose={onModalClose}
      mandatoryHintReplacement={<div></div>}
      footer={
        <>
          <div
            className={`${
              stage === 0 ? "value-excel-upload-stage0" : "value-excel-upload"
            }`}
          >
            <ValueExcelUpload
              setFile={setCsvFile}
              label={null}
              onChange={(f) => {
                setFile(f);
              }}
              stage={stage}
              refColumn={refColumn}
              fieldsToMap={fieldsToMap}
              setDecodedValues={setDecodedValues}
              setAggregateValues={setAggregateValues}
              //need to add uploading state (while file is still uploading) handler
            ></ValueExcelUpload>
          </div>

          <div className="wizard-control">
            <AppButton
              className="wiz-button wizard-next"
              text={stage !== 2 ? t("voc.common.next") : t("common.confirm")}
              onClick={() => {
                if (stage === 2) {
                  setPatchValueRegisterRequest(() => {
                    return updateValueRegisterList(
                      decodedValues,
                      serviceConfig
                    );
                  });
                  onModalClose();
                }
                setStage(stage + 1);
              }}
              disabled={
                (stage === 0 && extension !== "text/csv") ||
                (stage === 2 && !aggregateValues)
              }
            />
            {stage > 0 ? (
              <AppButton
                className="wiz-button wizard-back"
                text={t("common.button.back")}
                onClick={() => {
                  setStage(stage - 1);
                }}
              />
            ) : null}
          </div>
        </>
      }
    >
      <div className="stages">
        <div
          className={
            "stage stage-one" +
            (stage === 0 ? " active" : "") +
            (stage > 0 ? " done" : "")
          }
        >
          <div className="icon">
            <span>1</span>
          </div>
          <span className="label">
            {t("voc.value.register.importExcelModal.selectFile")}
          </span>
        </div>

        <div className="dash" />
        <div
          className={
            "stage stage-two" +
            (stage === 1 ? " active" : "") +
            (stage > 1 ? " done" : "")
          }
        >
          <div className="icon">
            <span>2</span>
          </div>
          <span className="label">
            ({t("voc.value.register.importExcelModal.dataMapping")})
          </span>
        </div>

        <div className="dash" />
        <div className={"stage stage-three" + (stage === 2 ? " active" : "")}>
          <div className="icon">
            <span>3</span>
          </div>
          <span className="label">
            {t("voc.value.importExcelModal.button.confirmation")}
          </span>
        </div>
      </div>

      {/*start stage 0 */}
      {stage === 0 ? (
        <div className="stage-one-body stage-body">
          <span className="label">IMPORT CSV FILE</span>
          {extension && extension !== "text/csv" ? (
            <span className="file-format-warning">
              {t("voc.value.register.importExcelModal.alert.fileformat")}
            </span>
          ) : null}
          {/* {extension && extension === "text/csv" ? ( */}
          <span className="empty-column-warning">
            {t("voc.value.importExcelModal.alert.warming.fileformat")}
          </span>
          {/* ) : null} */}
          {csvFile ? (
            <div className="uploaded-file">
              <>
                <h3>{csvFile.name}</h3>
              </>
              <span
                className="remove"
                onClick={() => {
                  setCsvFile(undefined);
                }}
              >
                X
              </span>
            </div>
          ) : null}
        </div>
      ) : null}
      {/*end stage 0 */}

      {/* start stage 1 */}
      {stage === 1 ? (
        <div className="stage-two-body stage-body">
          <span className="title">
            {t("voc.value.importExcelModal.Reference")}
          </span>
          <div className="to-map-fields">
            <div className="to-map-field headers">
              <div className="field">
                <span className="label">
                  {t("voc.value.importExcelModal.FIELD")}
                </span>
              </div>
              <div className="value">
                <span className="label">
                  EXCEL {t("voc.value.importExcelModal.referenceColumn")}
                </span>
              </div>
            </div>
            <div className="to-map-field">
              <div className="field">
                {t("voc.value.importExcelModal.compensationEvent.SerialNo")}
              </div>
              <div className="value">
                <input
                  value={refColumn}
                  onChange={(e) => {
                    if (checkValidColumnInput(e.target.value)) {
                      setRefColumn(e.target.value);
                    }
                  }}
                ></input>
              </div>
            </div>
          </div>
          <span className="title">
            {t("voc.value.importExcelModal.uploadValues")}
          </span>
          <div className="to-map-fields">
            <div className="to-map-field headers">
              <div className="field">
                <span className="label">
                  {t("voc.value.importExcelModal.Field")}
                </span>
              </div>
              <div className="value">
                <span className="label">
                  EXCEL {t("voc.value.importExcelModal.referenceColumn")}
                </span>
              </div>
            </div>
            {fieldsToMap.map((f, i) => (
              <div key={i} className="to-map-field">
                <div className="field">
                  <Dropdown
                    options={fieldToMapOptions}
                    onChange={(e, item) => {
                      const newFieldObject = {
                        field: String(item.key),
                        column: fieldsToMap[i].column,
                      };
                      const fieldsToMapCopy = fieldsToMap;
                      fieldsToMapCopy[i] = newFieldObject;
                      setFieldsToMap([...fieldsToMapCopy]);
                    }}
                  ></Dropdown>
                </div>
                <div className="value">
                  <input
                    value={f.column}
                    onChange={(e) => {
                      if (checkValidColumnInput(e.target.value)) {
                        const newFieldObject = {
                          field: fieldsToMap[i].field,
                          column: e.target.value,
                        };
                        const fieldsToMapCopy = fieldsToMap;
                        fieldsToMapCopy[i] = newFieldObject;
                        setFieldsToMap([...fieldsToMapCopy]);
                      }
                    }}
                  ></input>
                </div>
              </div>
            ))}
            <div className="add-field-button" onClick={addFieldToMap}>
              + {t("voc.value.importExcelModal.addField")}
            </div>
          </div>
        </div>
      ) : null}
      {/* end stage 1 */}

      {/* start stage 2 */}
      {stage === 2 ? (
        <div className="stage-three-body stage-body">
          {aggregateValues ? (
            <div className="aggregate-values">
              <div className="upload-time-notice">
                {t("voc.value.importExcelModal.uploadTimeNotice")}
              </div>
              <span className="title">
                {t("voc.value.importExcelModal.uploadValues")}
              </span>
              <span className="label">
                {t("voc.value.importExcelModal.aggregate")}
              </span>
              {fieldToMapOptions.map((f: any) => {
                if (
                  f.key !== "includedInReports" &&
                  f.key !== "paymentReference"
                ) {
                  return (
                    <div className="aggregate-field" key={f.key}>
                      <span className="field-name">{f.text}</span>
                      <span className="aggregate-value">
                        {aggregateValues[f.key]
                          ? "$" +
                            aggregateValues[f.key]
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : null}
                      </span>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          ) : null}
        </div>
      ) : null}
    </AppModal>
  );
};

export default ImportExcelModal;
