import React from "react";
import { createUseStyles } from "react-jss";
import { NavLink } from "react-router-dom";

import { NxpHeader } from "@nexploretechnology/nxp-ui";
import { Breadcrumb } from "antd";

const useStyles = createUseStyles((theme) => ({
  demoHeader: {},
}));

interface DemoHeaderBreadcrumbProps {}

const DemoHeaderBreadcrumb: React.FC<DemoHeaderBreadcrumbProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.demoHeader}>
      <NxpHeader
        titleContent={
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="/test">Demo</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="/test/123">Application Center</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>An Application</Breadcrumb.Item>
          </Breadcrumb>
        }
      />
    </div>
  );
};

export default DemoHeaderBreadcrumb;
