import { notify, NxpButton } from "@nexploretechnology/nxp-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAppContext from "../../../../../app/hooks/useAppContext";
import {
  VocGetUpdateValuesSubrecord,
  VocGetValuesBuildUp,
  VocGetValuesEisReporting,
  VocGetValuesToClient,
  VocGetValuesToDate,
} from "../../../../../app/types";
import { sendValues } from "../../../../compensation-event-services";

interface CopyCostAndRevenueButtonProps {
  editForm: VocGetUpdateValuesSubrecord;
  dataSource: VocGetUpdateValuesSubrecord[];
  toClientFormState: VocGetValuesToClient;
  eisReportingFormState: Pick<
    VocGetValuesEisReporting,
    | "eisA2A6"
    | "in16"
    | "eisA8A14"
    | "eisSecuredProject"
    | "eisStrategyIncludedInPlanning"
  >;
  actualValues: {
    physicalProgress: number;
    internalProgressValue: number;
  };
  paymentWithClientFormState: VocGetValuesToDate;
  onSetCustomValues: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  onSetToClientFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesToClient>
  >;
  onSetBuildUpFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesBuildUp>
  >;
  onSetPaymentWithClientFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesToDate>
  >;
  onSetCostCodes: React.Dispatch<
    React.SetStateAction<{
      costCodes: string[];
    }>
  >;
  onSetEisReportingFormState: React.Dispatch<
    React.SetStateAction<
      Pick<
        VocGetValuesEisReporting,
        | "eisA2A6"
        | "eisA8A14"
        | "in16"
        | "eisSecuredProject"
        | "eisStrategyIncludedInPlanning"
      >
    >
  >;
}

const CopyCostAndRevenueButton: React.FC<CopyCostAndRevenueButtonProps> = ({
  editForm,
  dataSource,
  toClientFormState,
  eisReportingFormState,
  actualValues,
  paymentWithClientFormState,
  onSetCustomValues,
  onSetToClientFormState,
  onSetPaymentWithClientFormState,
  onSetBuildUpFormState,
  onSetCostCodes,
  onSetEisReportingFormState,
}) => {
  const { t } = useTranslation();

  const { eventId } = useParams<{ eventId: string }>();

  const { serviceConfig, errorHandler } = useAppContext();

  const handleCopyCostAndRevenue = async () => {
    try {
      const internalTarget = dataSource.reduce(
        (acc, cur) => acc + cur.revenue,
        0
      );
      const directCost =
        dataSource.reduce((acc, cur) => acc + cur.directCostPrevious, 0) +
        dataSource.reduce((acc, cur) => acc + cur.directCostPlanned, 0);

      const indirectCost =
        dataSource.reduce((acc, cur) => acc + cur.indirectCostPrevious, 0) +
        dataSource.reduce((acc, cur) => acc + cur.indirectCostPlanned, 0);
      const costCloseOut = dataSource.reduce(
        (acc, cur) => acc + cur.closeOut,
        0
      );
      const costGna = dataSource.reduce((acc, cur) => acc + cur.gna, 0);
      const totalCost = dataSource.reduce((acc, cur) => acc + cur.totalCost, 0);
      const costCodes = Array.from(
        new Set(
          dataSource.map((code) => code.costCode).filter((code) => code !== "")
        )
      );

      const profitLoss = internalTarget - totalCost;
      const overheadProfit =
        (profitLoss + costGna) / (directCost + indirectCost + costCloseOut); // O = (N + L) / (I + J + K)
      const cumulativeAppliedNominal =
        internalTarget * paymentWithClientFormState.cumulativeApplied; //W = E * V
      const internalValue = toClientFormState.internalValue; // C
      const recovery = internalTarget / internalValue; // E / C

      const valuesForm = {
        internalTarget: internalTarget,
        recovery: recovery,
        targetVariance: internalTarget - toClientFormState.clientAssessed,
        directCost: directCost,
        indirectCost: indirectCost,
        costCloseOut: costCloseOut,
        costGna: costGna,
        totalCost: totalCost,
        profitLoss: profitLoss,
        overheadProfit: overheadProfit,
        costCodes: costCodes,
        internalProgressValue: actualValues.physicalProgress * internalTarget,
        cumulativeAppliedNominal: cumulativeAppliedNominal, //w = E * V
        cumulativeValueVariance:
          cumulativeAppliedNominal -
          paymentWithClientFormState.cumulativeValueCertified, //Y = W - X
        eisA8A14: internalTarget - eisReportingFormState.eisA2A6,
        eisSecuredProject: eisReportingFormState.eisSecuredProject,
        eisStrategyIncludedInPlanning:
          internalTarget - eisReportingFormState.eisSecuredProject,
      };
      const updatedValues = await sendValues(
        eventId,
        valuesForm,
        serviceConfig
      );
      onSetCustomValues(updatedValues.customization);

      onSetToClientFormState(updatedValues.toClient);

      onSetPaymentWithClientFormState(updatedValues.valuesToDate);

      onSetCostCodes({ costCodes: costCodes });

      onSetBuildUpFormState(updatedValues.buildUp);

      onSetEisReportingFormState(updatedValues.eisReporting);

      notify.actionCompleted();
    } catch (error) {
      errorHandler(error, "copy cost and revenue");
    }
  };

  return (
    <NxpButton
      type="default"
      disabled={!!editForm}
      onClick={handleCopyCostAndRevenue}
    >
      {t("voc.eventPage.values.copyCostAndRevenue")}
    </NxpButton>
  );
};

export default CopyCostAndRevenueButton;
