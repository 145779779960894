import { NxpLabel, NxpModal } from "@nexploretechnology/nxp-ui";
import { Form, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { VocSupportDocumentEnums } from "../../../../../app/types";
import { vocSupportDocumentOptions } from "../../../supporting-tab/new-document/new-document-form-type";
import NewContemporaryRecordForm from "../../../supporting-tab/new-document/new-document-forms/general/NewContemporaryRecordForm";
import NewDailyDiariesForm from "../../../supporting-tab/new-document/new-document-forms/general/NewDailyDiariesForm";
import NewLabourRecordForm from "../../../supporting-tab/new-document/new-document-forms/general/NewLabourRecordForm";
import NewPlantRecordForm from "../../../supporting-tab/new-document/new-document-forms/general/NewPlantRecordForm";
import NewStaffRecordTimeSheetForm from "../../../supporting-tab/new-document/new-document-forms/general/NewStaffRecordTimeSheetForm";
import "../../../supporting-tab/new-document/NewDocumentButton.scss";

interface NewSupportingRecordModalProps {
  onModalClose: () => void;
  onRefresh: () => void;
}

const NewSupportingRecordModal: React.FC<NewSupportingRecordModalProps> = ({
  onModalClose,
  onRefresh,
}) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    documentType: "",
  });

  const handleChange = (value) => {
    setFormState({ documentType: value });
  };

  const filteredOptions = vocSupportDocumentOptions.filter((type) =>
    [
      "VO-EV-SDOC-02",
      "VO-EV-SDOC-03",
      "VO-EV-SDOC-04",
      "VO-EV-SDOC-05",
      "VO-EV-SDOC-08",
    ].includes(type.value)
  );

  return (
    <NxpModal
      open={true}
      title={t("voc.common.newDocument")}
      onCancel={onModalClose}
    >
      <Form layout="vertical">
        <Form.Item
          label={<NxpLabel>{t("voc.common.documentType")}</NxpLabel>}
          required
        >
          <Select
            value={formState.documentType}
            showSearch
            allowClear
            onChange={handleChange}
            options={filteredOptions.map((type) => ({
              label: type.label,
              value: type.value,
            }))}
            filterOption={(input, option) =>
              option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>
      </Form>

      {formState.documentType === VocSupportDocumentEnums.Labour_Records && (
        <NewLabourRecordForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}
      {formState.documentType === VocSupportDocumentEnums.Plant_Records && (
        <NewPlantRecordForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}
      {formState.documentType ===
        VocSupportDocumentEnums.Staff_Records_Timesheets && (
        <NewStaffRecordTimeSheetForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}
      {formState.documentType === VocSupportDocumentEnums.Daily_Diaries && (
        <NewDailyDiariesForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}
      {formState.documentType ===
        VocSupportDocumentEnums.Contemporary_Records && (
        <NewContemporaryRecordForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}
    </NxpModal>
  );
};

export default NewSupportingRecordModal;
