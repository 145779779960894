// frontend env -- must be sync with backend env
export enum EnvDisplayFormat {
  AU = "au",
  US = "us",
  EU = "eu",
}

// in DB -- must be sync with i18n language code,
//          coz we mainly pass UserLanguagePreference to locale
export enum UserLanguagePreference {
  ZH_HK = "zh-HK", // 'au'
  EN = "en", // 'au'
  US = "en-US", // 'us'
  DE = "de", // 'eu'
  ES = "es", // 'eu'
}

// Event Manager -- many part using moment.js, so we should not use nxp-common DateFormatPattern
//otherwise, we may encounter unknown issue
export enum DateFormatPattern {
  date = "YYYY-MM-DD",
  dateWithWeekday = "ddd YYYY-MM-DD",
  dateWithTime = "YYYY-MM-DD HH:mm:ss",
  time = "HH:mm",
  timeToSecond = "HH:mm:ss",
}
