import React from "react";

import { Dropdown, IDropdownProps } from "office-ui-fabric-react";

import { AppFormField } from "./";
import { AppFormFieldProps } from "./AppFormField";

import "./AppFormDropdownField.scss";
import { useTranslation } from "react-i18next";

export type AppFormDropdownFieldProps = {
  errorMessage?: string | string[];
  value?: string;
} & Pick<
  IDropdownProps,
  | "placeholder"
  | "onChange"
  | "label"
  | "options"
  | "required"
  | "disabled"
  | "multiSelect"
  | "selectedKeys"
  | "calloutProps"
> &
  AppFormFieldProps;

const AppFormDropdownField: React.FC<AppFormDropdownFieldProps> = ({
  label,
  required,
  placeholder,
  options,
  multiSelect,
  value,
  selectedKeys,
  calloutProps,
  errorMessage,
  disabled,
  onChange,
  ...restProps
}) => {
  const { t } = useTranslation();
  return (
    <AppFormField {...restProps}>
      <Dropdown
        className="app-form-dropdown-field"
        label={label}
        required={required}
        placeholder={placeholder ? placeholder : t("voc.common.pleaseSelect")}
        options={options}
        selectedKey={multiSelect ? undefined : value}
        selectedKeys={multiSelect ? selectedKeys : undefined}
        calloutProps={calloutProps}
        multiSelect={multiSelect}
        disabled={disabled}
        errorMessage={
          Array.isArray(errorMessage) ? errorMessage.join(" / ") : errorMessage
        }
        onChange={onChange}
      />
    </AppFormField>
  );
};

export default AppFormDropdownField;
