import { useContext, useMemo } from "react";

import AppContext from "../components/app-context";
import useErrorHandler from "./useErrorHandler";
import useRouteParams from "./useRouteParams";
import useServiceConfig from "./useServiceConfig";

const useAppContext = () => {
  const appContext = useContext(AppContext);
  const serviceConfig = useServiceConfig();
  const routeParams = useRouteParams();
  const errorHandler = useErrorHandler();

  return useMemo(
    () => ({
      ...appContext,
      serviceConfig,
      routeParams,
      errorHandler,
    }),
    [appContext, serviceConfig, errorHandler, routeParams]
  );
};

export default useAppContext;
