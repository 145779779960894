import { NxpFormGrid, NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import i18n from "../../../../app/i18n/i18n";
import { VocGetValuesToDate } from "../../../../app/types";
import { getFormattedInputNumberControlProps } from "../../../../app/utils/number/getFormattedInputNumberControlProps";
import {
  computeCumulativeApplied,
  computeCumulativeAppliedNominal,
  computeCumulativeValueVariance,
} from "../helpers/paymentWithClient";

interface PaymentWithClientProps {
  editing: boolean;
  fieldNames: Record<string, string>;
  paymentWithClientFormState: VocGetValuesToDate;
  onSetPaymentWithClientFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesToDate>
  >;
  internalTarget: number;
}

const useStyles = createUseStyles((theme) => ({
  title: {
    color: theme.palette.primary,
  },
}));

const PaymentWithClient: React.FC<PaymentWithClientProps> = ({
  paymentWithClientFormState,
  editing,
  fieldNames,
  onSetPaymentWithClientFormState,
  internalTarget,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const handleFormGridStateChange = (
    fieldName: keyof VocGetValuesToDate,
    value: unknown
  ) => {
    onSetPaymentWithClientFormState((prevState) => {
      const newState = { ...prevState, [fieldName]: value };

      //V First Column -> E & Y
      if (fieldName === "cumulativeApplied") {
        //W = E * V
        newState.cumulativeAppliedNominal = computeCumulativeAppliedNominal(
          newState,
          internalTarget
        );

        //Y = W - X
        newState.cumulativeValueVariance =
          computeCumulativeValueVariance(newState);
      }

      //W & X Second Column and Third Column
      if (
        fieldName === "cumulativeAppliedNominal" ||
        fieldName === "cumulativeValueCertified"
      ) {
        //V = W / E
        newState.cumulativeApplied = computeCumulativeApplied(
          newState,
          internalTarget
        );
        newState.cumulativeValueVariance =
          computeCumulativeValueVariance(newState);
      }

      return newState;
    });
  };

  const paymentWithClientFormItems: NxpFormGridItemProps<VocGetValuesToDate>[] =
    [
      {
        //V
        controlType: "inputPercent",
        label: fieldNames.cumPApplied,
        itemFieldName: "cumulativeApplied",
        span: 3,
        controlProps: {
          value: paymentWithClientFormState?.cumulativeApplied,
          precision: 2,
          addonAfter: editing ? "%" : undefined,
          suffix: editing ? undefined : "%",
          locale: i18n.language,
          ...getFormattedInputNumberControlProps(),
        },
      },
      {
        //W
        controlType: "inputNumber",
        label: fieldNames.cumVApplied,
        itemFieldName: "cumulativeAppliedNominal",
        span: 3,
        controlProps: {
          value: paymentWithClientFormState?.cumulativeAppliedNominal,
          precision: 2,
          addonBefore: editing ? "$" : undefined,
          prefix: editing ? undefined : "$",
          locale: i18n.language,
          ...getFormattedInputNumberControlProps(),
        },
      },
      {
        //X
        controlType: "inputNumber",
        label: fieldNames.cumVCertified,
        itemFieldName: "cumulativeValueCertified",
        span: 3,
        controlProps: {
          value: paymentWithClientFormState?.cumulativeValueCertified,
          precision: 2,
          addonBefore: "$",
          prefix: editing ? undefined : "$",
          locale: i18n.language,
          ...getFormattedInputNumberControlProps(),
        },
      },
      {
        //Y
        controlType: "inputNumber",
        label: fieldNames.varianceAC,
        itemFieldName: "cumulativeValueVariance",
        span: 3,
        editing: false,
        controlProps: {
          value: paymentWithClientFormState?.cumulativeValueVariance,
          precision: 2,
          addonBefore: "$",
          prefix: editing ? undefined : "$",
          locale: i18n.language,
          ...getFormattedInputNumberControlProps(),
        },
      },
      {
        controlType: "input",
        label: fieldNames.paymentReference,
        itemFieldName: "paymentReference",
        span: 3,
        controlProps: {
          value: paymentWithClientFormState?.paymentReference,
        },
      },
    ];

  return (
    <>
      <h4 className={classes.title}>
        {t("voc.eventPage.values.paymentWithClientHeader")}
      </h4>
      <NxpFormGrid
        editing={editing}
        formItems={paymentWithClientFormItems}
        onFormStateChange={handleFormGridStateChange}
      />
    </>
  );
};

export default PaymentWithClient;
