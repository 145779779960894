import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import { NxpTimePicker } from "@nexploretechnology/nxp-ui";

const useStyles = createUseStyles((theme) => ({
  DemoTimePicker: {},
}));

interface DemoTimePickerProps {}

const DemoTimePicker: React.FC<DemoTimePickerProps> = () => {
  const classes = useStyles();
  const [date, setDate] = useState<Date>();

  return (
    <div className={classes.DemoTimePicker}>
      <NxpTimePicker value={date} onChange={setDate} />
    </div>
  );
};

export default DemoTimePicker;
