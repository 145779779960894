import { Route } from "react-router-dom";

import AppProtectedPage from "../app/components/app-router/AppProtectedPage";
import Demo from "./pages/demo";
import DemoLeftDrawer from "./pages/demo-left-drawer/DemoLeftDrawer";
import DemoSplitter from "./pages/demo-splitter/DemoSplitter";

export const demoRoutes = [
  <Route
    key="demo"
    path="/entities/:entityId/demo"
    element={<AppProtectedPage component={<Demo />} />}
  />,
  <Route
    key="demo-left-drawer"
    path="/entities/:entityId/demo/left-drawer"
    element={<AppProtectedPage component={<DemoLeftDrawer />} />}
  />,
  <Route
    key="demo-splitter"
    path="entities/:entityId/demo/splitter"
    element={<AppProtectedPage component={<DemoSplitter />} />}
  />,
];
