import React from "react";

import "./AppFormRow.scss";

type AppFormRowProps = {
  evenStretch?: boolean;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const AppFormRow: React.FC<AppFormRowProps> = ({
  evenStretch = true,
  className,
  ...restProps
}) => {
  return (
    <div
      className={`app-form-row${evenStretch ? ` even-stretch` : ""}${
        className ? ` ${className}` : ""
      }`}
      {...restProps}
    />
  );
};

export default AppFormRow;
