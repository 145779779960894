import { DataTableColumn, QuerySettings, SortColumn } from "./";

import "./AppDataTableRow.scss";

interface AppDataTableHeaderProps<T> {
  columns: DataTableColumn<T>[];
  querySettings: QuerySettings;
  fixFirstColumn?: boolean;
  onDataTableQuery: (querySettings: QuerySettings) => void;
}
function AppDataTableHeader<T>(props: Readonly<AppDataTableHeaderProps<T>>) {
  const { columns, querySettings, fixFirstColumn, onDataTableQuery } = props;

  const getSortColumns = (fieldName: string): SortColumn[] => {
    // allow sorting by only one column for the time being
    if (
      querySettings.sortColumns.length === 0 ||
      querySettings.sortColumns[0].fieldName !== fieldName
    ) {
      return [{ fieldName, order: "ASC" }];
    }
    if (querySettings.sortColumns[0].order === "ASC") {
      return [{ fieldName, order: "DESC" }];
    }
    if (querySettings.sortColumns[0].order === "DESC") {
      return [];
    }
    return [];
  };

  const getSortClassName = (fieldName: string): string => {
    if (querySettings) {
      const sortCol = querySettings.sortColumns.find(
        (col) => col.fieldName === fieldName
      );
      if (sortCol) {
        return `sort sort-${sortCol.order.toLocaleLowerCase()}`;
      }
    }
    return "sort";
  };
  return (
    <div role="row" className="app-data-table-row header">
      {columns.map((col, idx) => {
        return (
          <div
            key={col.key}
            role="columnheader"
            onClick={
              col.isSortable
                ? () =>
                    onDataTableQuery({
                      ...querySettings,
                      currentPage: 1,
                      sortColumns: getSortColumns(col.fieldName),
                    })
                : undefined
            }
            className={`${
              col.isSortable ? getSortClassName(col.fieldName) : ""
            }${col.headerClassName ? ` ${col.headerClassName}` : ""}${
              fixFirstColumn && idx === 0 ? " fixed-first-col" : ""
            }`}
          >
            <span>{col.name}</span>
          </div>
        );
      })}
    </div>
  );
}

export default AppDataTableHeader;
