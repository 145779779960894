import { NxpSpin } from "@nexploretechnology/nxp-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";
import useAppContext from "../../../app/hooks/useAppContext";
import {
  VocEvent,
  VocGetSupportDocumentResponse,
  VocSiteEvent,
} from "../../../app/types";
import {
  getEventSupportDocuments,
  getSiteEventSupportDocuments,
} from "../../compensation-event-services";
import NewSupportingDocumentButton from "./new-document/NewSupportingDocumentButton";
import AcaPanel from "./supporting-panels/Aca/AcaPanel";
import CorrespondencePanel from "./supporting-panels/Correspondence/CorrespondencePanel";
import DrawingsPanel from "./supporting-panels/Drawings/Drawings";
import ContemporaryRecordsPanel from "./supporting-panels/General/ContemporaryRecordPanel.tsx/ContemporaryRecordPanel";
import DailyDiariesPanel from "./supporting-panels/General/DailyDiariesPanel/DailyDiariesPanel";
import LabourRecordPanel from "./supporting-panels/General/LabourRecordPanel/LabourRecordPanel";
import PlantRecordPanel from "./supporting-panels/General/PlantRecordPanel/PlantRecordPanel";
import StaffRecordPanel from "./supporting-panels/General/StaffRecordPanel/StaffRecordPanel";
import MinervaPanel from "./supporting-panels/Minerva/MinervaPanel";
import OtherDocumentPanel from "./supporting-panels/OtherDocument/OtherDocumentPanel";
import SciPanel from "./supporting-panels/Sci/SciPanel";

interface SupportingTabProps {
  eventDetail: VocEvent | VocSiteEvent;
}

const SupportingTab: React.FC<SupportingTabProps> = ({ eventDetail }) => {
  const { siteEventId, eventId } = useParams();

  const { serviceConfig, errorHandler } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [supportDocuments, setSupportDocuments] =
    useState<VocGetSupportDocumentResponse>();

  const fetchSupportingDocuments = useCallback(async () => {
    try {
      setIsLoading(true);
      if (siteEventId) {
        const response = await getSiteEventSupportDocuments(
          siteEventId,
          serviceConfig
        );
        setSupportDocuments(response);
      }
      if (eventId) {
        const response = await getEventSupportDocuments(eventId, serviceConfig);
        setSupportDocuments(response);
      }
    } catch (error) {
      errorHandler(error, "fetch supporting documents");
    } finally {
      setIsLoading(false);
    }
  }, [siteEventId, eventId, serviceConfig, errorHandler]);

  useEffect(() => {
    fetchSupportingDocuments();
  }, [fetchSupportingDocuments]);

  const refresh = () => {
    fetchSupportingDocuments();
  };

  return (
    <div className="supporting-tab-container">
      <AccessVisible objectCode="compensation_event" actionType="edit">
        <NewSupportingDocumentButton onRefresh={refresh} />
      </AccessVisible>
      <NxpSpin spinning={!supportDocuments}>
        {supportDocuments && (
          <CorrespondencePanel
            correspondences={supportDocuments.correspondence}
            onRefresh={refresh}
            onSetIsLoading={setIsLoading}
            isLoading={isLoading}
            ////////////Notification/////////////////
            eventDetail={eventDetail}
            notifications={supportDocuments.notifications}
            notificationsReceiver={supportDocuments.notificationReceiver}
            notificationsSender={supportDocuments.notificationSender}
          />
        )}
        {supportDocuments && (
          <LabourRecordPanel
            labourRecords={supportDocuments.labour}
            onRefresh={refresh}
            onSetIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        )}
        {supportDocuments && (
          <PlantRecordPanel
            plantRecords={supportDocuments.plant}
            onRefresh={refresh}
            onSetIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        )}
        {supportDocuments && (
          <StaffRecordPanel
            timesheets={supportDocuments.timesheet}
            onRefresh={refresh}
            onSetIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        )}
        {supportDocuments && (
          <DailyDiariesPanel
            dailyDiaries={supportDocuments.siteDiaries}
            onRefresh={refresh}
            onSetIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        )}
        {supportDocuments && (
          <ContemporaryRecordsPanel
            contemporaryRecords={supportDocuments.contemporary}
            onRefresh={refresh}
            onSetIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        )}
        {supportDocuments && (
          <DrawingsPanel
            drawings={supportDocuments.drawingDocs}
            onRefresh={refresh}
            onSetIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        )}
        {supportDocuments && (
          <AcaPanel
            acaDocs={supportDocuments.acaDocs}
            onRefresh={refresh}
            onSetIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        )}
        {supportDocuments && (
          <SciPanel
            scis={supportDocuments?.sciDocs}
            onRefresh={refresh}
            onSetIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        )}
        <MinervaPanel />
        {supportDocuments && (
          <OtherDocumentPanel
            eventReferenceFiles={supportDocuments.eventReferenceFiles}
            otherDocuments={supportDocuments.other}
            onRefresh={refresh}
            onSetIsLoading={setIsLoading}
            isLoading={isLoading}
            eventDetail={eventDetail}
          />
        )}
      </NxpSpin>
    </div>
  );
};

export default SupportingTab;
