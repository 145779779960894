import React from "react";

import { NxpSpin } from "@nexploretechnology/nxp-ui";

interface DemoLoadingSpinProps {}

const DemoLoadingSpin: React.FC<DemoLoadingSpinProps> = () => {
  return (
    <>
      <NxpSpin size="small" />
      <NxpSpin />
      <NxpSpin size="large" />
      <NxpSpin size="large" expand />
    </>
  );
};

export default DemoLoadingSpin;
