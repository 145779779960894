import { DateTime } from "luxon";

import { ColumnDataType } from "../../components/app-data-table";
import { FilterOption, FilterType } from "../../components/app-filter";
import { FORMAT_SHORT_DATE_LUXON } from "../const";

export const prepareFilterOptions = (filterOptions: FilterOption[]) => {
  if (!filterOptions) {
    return [];
  }

  const greaterThanFilterOptions: FilterOption[] = [];
  const lessThanFilterOptions: FilterOption[] = [];
  const betweenFilterOptions: { from: FilterOption; to: FilterOption }[] = [];

  filterOptions.forEach((filterOpt) => {
    switch (filterOpt.filterType) {
      case FilterType.GreaterThanOrEqual:
        {
          const greaterOpt = greaterThanFilterOptions.find(
            (greaterOpt) => greaterOpt.fieldName === filterOpt.fieldName
          );
          if (!greaterOpt) {
            greaterThanFilterOptions.push(filterOpt);
          } else {
            switch (greaterOpt.columnDataType) {
              case ColumnDataType.Date:
                {
                  const greaterOptDateValue = new Date(greaterOpt.filterValue);
                  const filterOptDateValue = new Date(filterOpt.filterValue);
                  if (filterOptDateValue > greaterOptDateValue) {
                    greaterOpt.filterValue = filterOpt.filterValue;
                  }
                }
                break;
              case ColumnDataType.Money:
              case ColumnDataType.Number:
              case ColumnDataType.Percent:
                {
                  const greaterOptNumValue = Number(greaterOpt.filterValue);
                  const filterOptNumValue = Number(filterOpt.filterValue);
                  if (filterOptNumValue > greaterOptNumValue) {
                    greaterOpt.filterValue = filterOpt.filterValue;
                  }
                }
                break;
            }
          }
        }
        break;
      case FilterType.LessThanOrEqual:
        {
          const lessOpt = lessThanFilterOptions.find(
            (lessOpt) => lessOpt.fieldName === filterOpt.fieldName
          );
          if (!lessOpt) {
            lessThanFilterOptions.push(filterOpt);
          } else {
            switch (lessOpt.columnDataType) {
              case ColumnDataType.Date:
                {
                  const lessOptDateValue = new Date(lessOpt.filterValue);
                  const filterOptDateValue = new Date(filterOpt.filterValue);
                  if (filterOptDateValue < lessOptDateValue) {
                    lessOpt.filterValue = filterOpt.filterValue;
                  }
                }
                break;
              case ColumnDataType.Money:
              case ColumnDataType.Number:
              case ColumnDataType.Percent:
                {
                  const lessOptNumValue = Number(lessOpt.filterValue);
                  const filterOptNumValue = Number(filterOpt.filterValue);
                  if (filterOptNumValue < lessOptNumValue) {
                    lessOpt.filterValue = filterOpt.filterValue;
                  }
                }
                break;
            }
          }
        }
        break;
    }
  });

  greaterThanFilterOptions.forEach((greaterOpt) => {
    const lessOpt = lessThanFilterOptions.find((lessOpt) => {
      if (lessOpt.fieldName === greaterOpt.fieldName) {
        switch (lessOpt.columnDataType) {
          case ColumnDataType.Date:
            {
              const lessOptDateValue = new Date(lessOpt.filterValue);
              const greaterOptDateValue = new Date(greaterOpt.filterValue);
              if (greaterOptDateValue < lessOptDateValue) {
                return true; // treat as between
              }
            }
            break;
          case ColumnDataType.Money:
          case ColumnDataType.Number:
          case ColumnDataType.Percent:
            {
              const lessOptNumValue = Number(lessOpt.filterValue);
              const greaterOptNumValue = Number(greaterOpt.filterValue);
              if (greaterOptNumValue < lessOptNumValue) {
                return true; // treat as between
              }
            }
            break;
        }
      }
      return false;
    });

    if (lessOpt) {
      betweenFilterOptions.push({
        from: greaterOpt,
        to: lessOpt,
      });
    }
  });

  const returnOptions = filterOptions.filter(
    // filter out options for between comparison
    (item) =>
      !(
        (item.filterType === FilterType.GreaterThanOrEqual ||
          item.filterType === FilterType.LessThanOrEqual) &&
        betweenFilterOptions.find(
          (betweenOpt) => betweenOpt.from.fieldName === item.fieldName
        )
      )
  );

  return returnOptions
    .map((option) => {
      let value: boolean | Date | number | string | Date[] | number[];
      let valueTypeHolder: typeof value; // typeof value for getting around typescript error
      switch (option.columnDataType) {
        case ColumnDataType.Boolean:
          value =
            option.filterType === FilterType.IsEmpty
              ? null
              : option.filterValue === "true";
          break;
        case ColumnDataType.Date:
          value = DateTime.fromFormat(
            option.filterValue,
            FORMAT_SHORT_DATE_LUXON
          ).toJSDate();
          break;
        case ColumnDataType.Number:
        case ColumnDataType.Money:
        case ColumnDataType.Percent:
          value = Number(option.filterValue);
          break;
        default:
          //case ColumnDataType.String:
          value = option.filterValue;
          break;
      }

      return {
        column: option.fieldName,
        type: (option.filterType === FilterType.IsEmpty
          ? FilterType.Equal
          : option.filterType
        )
          .replace(/ /g, "")
          .replace("GreaterThanOrEqual", "GreaterEqual")
          .replace("LessThanOrEqual", "LessEqual"),
        value: value as typeof valueTypeHolder,
      };
    })
    .concat(
      betweenFilterOptions.map((betweenFilter) => {
        let value: Date[] | number[];
        switch (betweenFilter.from.columnDataType) {
          case ColumnDataType.Date:
            value = [
              DateTime.fromFormat(
                betweenFilter.from.filterValue,
                FORMAT_SHORT_DATE_LUXON
              ).toJSDate(),
              DateTime.fromFormat(
                betweenFilter.to.filterValue,
                FORMAT_SHORT_DATE_LUXON
              ).toJSDate(),
            ];
            break;
          case ColumnDataType.Number:
          case ColumnDataType.Money:
          case ColumnDataType.Percent:
            value = [
              Number(betweenFilter.from.filterValue),
              Number(betweenFilter.to.filterValue),
            ];
            break;
          default:
            value = [];
            break;
        }

        return {
          column: betweenFilter.from.fieldName,
          type: FilterType.Between,
          value,
        };
      })
    );
};
