import React, { useState } from "react";

import AppButton from "../../../app/components/app-button";
import AddLibraryContainer from "./AddLibraryContainer";

import "./AddLibraryButton.scss";
import { useTranslation } from "react-i18next";

interface AddLibraryButtonProps {
  onAdd: Function;
}

const AddLibraryButton: React.FC<AddLibraryButtonProps> = ({ onAdd }) => {
  const { t } = useTranslation();
  const [showAddLibraryModal, setShowAddLibraryModal] = useState(false);
  const handleModalClose = () => setShowAddLibraryModal(false);

  return (
    <>
      <AppButton
        className="voc-admin-library-new"
        onClick={() => setShowAddLibraryModal(true)}
      >
        + {t("voc.common.add")}
      </AppButton>
      {showAddLibraryModal && (
        <AddLibraryContainer
          onAdd={onAdd}
          showAddLibraryModal={showAddLibraryModal}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default AddLibraryButton;
