import React, { useState } from "react";

import {
  notify,
  NxpButton,
  NxpFormGrid,
  NxpHolder,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { Col, Row } from "antd";
import * as yup from "yup";

import { DemoUserForm, demoUserFormItems } from "./demoUserForm";

const formSchema = yup.object({
  firstName: yup.string().nullable().required("First Name required."),
  lastName: yup.string(),
  birthday: yup.date().required("Birthday required."),
});

interface FormGridItemArrayProps {}

const FormGridItemArray: React.FC<FormGridItemArrayProps> = () => {
  const [userForm, setUserForm] = useState<DemoUserForm>({
    firstName: "",
    lastName: "Chan",
    age: 18,
    gender: "m",
    skills: [],
    isDead: false,
    kids: [],
    birthday: new Date(),
  });

  const handleSave = () => {
    saveWithValidate(undefined);
  };

  const handleSaveValidated = async () => {
    notify.actionCompleted();
  };

  const [validationError, , , saveWithValidate] = useYupValidate<
    Pick<DemoUserForm, "firstName" | "lastName" | "birthday">
  >(userForm, formSchema, handleSaveValidated);

  const handleFormGridStateChange = (
    fieldName: keyof DemoUserForm,
    value: unknown
  ) => {
    console.log("ccccccccccccccc", fieldName, value);
    if (
      fieldName === "age" &&
      Number.isFinite(value) &&
      (value as number) > 100
    ) {
      setUserForm((prevState) => ({
        ...prevState,
        age: 100,
      }));
      return;
    }

    if (fieldName === "firstName" && value === "peter") {
      setUserForm((prevState) => ({
        ...prevState,
        firstName: value,
        lastName: "Hung",
      }));
      return;
    }

    setUserForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <NxpHolder>
      <Row justify="end">
        <Col>
          <NxpButton onClick={handleSave}>Save</NxpButton>
        </Col>
      </Row>
      <NxpFormGrid
        validationError={validationError}
        formItems={demoUserFormItems}
        formState={userForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpHolder>
  );
};

export default FormGridItemArray;
