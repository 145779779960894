import "./FeedbackListLayout.scss";

import _ from "lodash";
import { Icon, IDropdownOption } from "office-ui-fabric-react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import AccessVisible from "../../app/components/app-access-visible/AppAccessVisible";
import AppCollapsible from "../../app/components/app-collapsible/AppCollapsible";
import {
  ColumnDataType,
  DataTableColumn,
  QuerySettings,
} from "../../app/components/app-data-table";
import AppDataTableHeader from "../../app/components/app-data-table/AppDataTableHeader";
import AppFilter, {
  FilterColumn,
  FilterOption,
} from "../../app/components/app-filter";
import AppHeader from "../../app/components/app-header";
import {
  VocEventListItem,
  VocFeedbackListItem,
  VocFeedbackType,
} from "../../app/types";
import { mapDateToString } from "../../app/utils/mappers";

interface FeedbackListLayoutProps {
  querySettings: QuerySettings;
  dataSource: VocFeedbackListItem[];
  filterOptions: FilterOption[];
  onFilterChange: (filterOptions: FilterOption[]) => void;
  onExportExcel: () => void;
  onDataTableQuery: (querySettings: QuerySettings) => void;
}

const FeedbackListLayout: React.FC<FeedbackListLayoutProps> = ({
  querySettings,
  dataSource,
  filterOptions,
  onFilterChange,
  onDataTableQuery,
}) => {
  const { t } = useTranslation();
  const feedbackTypeOptions: IDropdownOption[] = [
    {
      key: null,
      text: t("voc.feedback.feedbackCategory"),
    },
    {
      key: VocFeedbackType.Enhancement,
      text: t("voc.feedback.enhancement"),
    },
    {
      key: VocFeedbackType.MissingInformation,
      text: t("voc.feedback.missingInformation"),
    },
    {
      key: VocFeedbackType.Bug_And_Error,
      text: t("voc.feedback.bugError"),
    },
    {
      key: VocFeedbackType.Question,
      text: t("voc.feedback.question"),
    },
    {
      key: VocFeedbackType.Other,
      text: t("voc.feedback.others"),
    },
  ];
  const columns = useMemo<DataTableColumn<VocEventListItem>[]>(
    () => [
      {
        key: "date",
        name: t("voc.feedback.createdOn"),
        fieldName: "createdOn",
        isSortable: true,
        columnDataType: ColumnDataType.Date,
        headerClassName:
          filterOptions.find((item) => item.fieldName === "createdOn") &&
          "header-filtered",
      },
      {
        key: "entity",
        name: t("voc.feedback.entity"),
        fieldName: "entityCode",
        isSortable: true,
        columnDataType: ColumnDataType.String,
        headerClassName:
          filterOptions.find((item) => item.fieldName === "entityCode") &&
          "header-filtered",
      },
      {
        key: "raised",
        name: t("voc.feedback.raised"),
        fieldName: "author",
        isSortable: false,
        columnDataType: ColumnDataType.String,
        headerClassName:
          filterOptions.find((item) => item.fieldName === "author") &&
          "header-filtered",
      },
      {
        key: "feedbackCategory",
        name: t("voc.feedback.feedbackCategory"),
        fieldName: "inquiryType",
        isSortable: true,
        headerClassName:
          filterOptions.find((item) => item.fieldName === "inquiryType") &&
          "header-filtered",
        columnDataType: ColumnDataType.Select,
        columnDropdownOptions: [
          ...Object.entries(VocFeedbackType).map((entry) => ({
            key: entry[1] as string,
            text: entry[0].replace(/_/g, " "),
          })),
        ],
        onRender: (item: VocEventListItem) =>
          item.eventType && t("voc.common.eventType." + item.eventType),
      },
      {
        key: "viewFile",
        name: t("voc.feedback.viewFile"),
        fieldName: "viewFile",
        isSortable: false,
        columnDataType: ColumnDataType.String,
        headerClassName:
          filterOptions.find((item) => item.fieldName === "viewFile") &&
          "header-filtered",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSource]
  );
  return (
    <AccessVisible objectCode="feedbacks" actionType="reader">
      <AppHeader localeResourceId="voc.common.feedback">
        <AppFilter
          filterOptions={filterOptions}
          onFilterChange={onFilterChange}
          filterColumns={
            [
              ..._.filter(
                columns,
                (col: { isSortable: boolean }) => col.isSortable !== false
              ),
            ] as unknown as FilterColumn[]
          }
        />
        {/*
        the excel export feature will do next sprint
        <AppExportExcelButton
          localeContext={localeContext}
          onExportExcel={onExportExcel}
        />
        */}
      </AppHeader>
      <div className="voc-feedback-register">
        <AppDataTableHeader
          columns={columns}
          querySettings={querySettings}
          fixFirstColumn={false}
          onDataTableQuery={onDataTableQuery}
        />
        {dataSource?.length ? (
          dataSource.map((feedback) => (
            <AppCollapsible
              defaultCollapsed={true}
              key={feedback.feedbackId}
              hasFields={true}
              fields={[
                {
                  property: "Description",
                  className: "comment",
                  value: _.get(feedback, "feedbackDes"),
                  fieldtype: "string",
                },
              ]}
              headerChildren={
                <>
                  <div className="c-col first feedback-date">
                    <div className="date-info">
                      <Icon iconName="Calendar" />
                      {mapDateToString(feedback.recordDate)}
                    </div>
                  </div>
                  <div className="c-col size-m c-applied">
                    {_.get(feedback, "entityCode")}
                  </div>
                  <div className="c-col size-m c-applied">
                    {_.get(feedback, "raisedBy")}
                  </div>
                  <div className="c-col size-m c-target">
                    {_.get(
                      _.find(feedbackTypeOptions, {
                        key: _.get(feedback, "feedbackType.value"),
                      }),
                      "text"
                    )}
                  </div>
                  <div className="c-col size-m f-file">
                    {feedback.link ? (
                      <div className="file-info blueBold">
                        <NxpButton
                          type="link"
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(feedback.link, "_blank");
                          }}
                        >
                          <Icon iconName="PageSolid" />
                          {_.get(feedback, "fileName")}
                        </NxpButton>
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </div>
                </>
              }
              bodyChildren={null}
            />
          ))
        ) : (
          <div className="no-record">
            {t("voc.appDataListComponent.noRecords")}
          </div>
        )}
      </div>
    </AccessVisible>
  );
};

export default FeedbackListLayout;
