import React from "react";
import { useTranslation } from "react-i18next";

import {
  AppFormRow,
  AppFormTextField,
} from "../../../../app/components/app-form";
import { VocLibraryObject } from "../../../../app/types";

interface DescriptionProps {
  form: Partial<VocLibraryObject>;
  formErrors: any; // ValidationResult<VocLibraryObject>;
  onFormChange: (changeValues: Partial<VocLibraryObject>) => void;
}

const Description: React.FC<DescriptionProps> = ({
  form,
  formErrors,
  onFormChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <AppFormRow>
        <AppFormTextField
          required={true}
          label={t("voc.library.description")}
          name="label"
          value={form.label}
          errorMessage={formErrors && formErrors.label}
          onChange={(_, value) => onFormChange({ label: value })}
        />
      </AppFormRow>
    </>
  );
};

export default Description;
