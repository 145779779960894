import { DeleteOutlined, LockFilled, UploadOutlined } from "@ant-design/icons";
import {
  notify,
  NxpButton,
  NxpButtonDeleteConfirm,
  NxpPanel,
  NxpTable,
  NxpTableColumnProps,
  NxpTooltip,
  sorterForNumber,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Button, Input, InputNumber, Select, Upload } from "antd";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import { convertNumber } from "../../../../../app/components/app-numeric-input/utils/convert-value-helper";
import useAppContext from "../../../../../app/hooks/useAppContext";
import i18n from "../../../../../app/i18n/i18n";
import { upload } from "../../../../../app/services/file";
import {
  VocSupportDocument,
  VocSupportDocumentEnums,
} from "../../../../../app/types";
import { formatNumberByLocale } from "../../../../../app/utils/number/formatNumberByLocale";
import { parseNumberByLocale } from "../../../../../app/utils/number/parseNumberByLocale";
import {
  deleteSupportDocumentsById,
  editEventSupportDocuments,
  editSiteEventSupportDocuments,
} from "../document-form-services";
import {
  getSiteDocStatuses,
  isRowDisplayOnly,
  relatedEventDisplay,
} from "../helpers/supporting-helper";

interface AcaPanelProps {
  acaDocs: VocSupportDocument[];
  onRefresh: () => void;
  onSetIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

const useStyles = createUseStyles({
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
});

const AcaPanel: React.FC<AcaPanelProps> = ({
  acaDocs,
  onRefresh,
  onSetIsLoading,
  isLoading,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { errorHandler, serviceConfig } = useAppContext();

  const { eventId, siteEventId } = useParams();

  const [editing, setEditing] = useState(false);
  const [formState, setFormState] = useState<VocSupportDocument[]>([]);

  const handleClickEdit = () => {
    setFormState([...acaDocs]);
    setEditing(true);
  };

  const handleClickCancel = () => {
    setEditing(false);
    setFormState([]);
  };

  const deleteDocument = useCallback(
    async (record: VocSupportDocument) => {
      const idToUse = siteEventId || eventId;
      const eventType = siteEventId ? "site_events" : "events";
      await deleteSupportDocumentsById(
        idToUse,
        record.id,
        eventType,
        serviceConfig
      );
      setEditing(false);
      setFormState([]);
      notify.actionCompleted();
      onRefresh();
    },
    [eventId, onRefresh, serviceConfig, siteEventId]
  );

  const handleSave = async () => {
    if (!formState) return;

    onSetIsLoading(true);
    const updatedAcaDocs = formState.map((item) => ({
      comment: item.comment,
      details: { ...item.details },
      documentFiles: item.documentFiles.map((file) => ({
        assetId: file.assetId,
        fileName: file.fileName,
        mimeType: file.mimeType,
        url: file.url,
      })),
      documentType: item.documentType,
      id: item.id,
    }));

    const isSiteEvent = Boolean(siteEventId);
    try {
      if (isSiteEvent) {
        await editSiteEventSupportDocuments(siteEventId, serviceConfig, {
          documentType: VocSupportDocumentEnums.Subcontractor_ACA,
          documents: updatedAcaDocs,
        });
      } else {
        await editEventSupportDocuments(eventId, serviceConfig, {
          documentType: VocSupportDocumentEnums.Subcontractor_ACA,
          documents: updatedAcaDocs,
        });
      }
      setEditing(false);
      setFormState([]);
      notify.actionCompleted();
      onRefresh();
    } catch (error) {
      errorHandler(error, "save Aca documents");
    } finally {
      onSetIsLoading(false);
    }
  };

  const handleRemoveFile = useCallback(
    (record) => {
      if (!formState) return;

      const updatedFormState = formState.map((item) =>
        item.id === record.id ? { ...item, documentFiles: [] } : item
      );
      setFormState(updatedFormState);
    },
    [formState]
  );

  const handleFileChange = useCallback(
    async (file, record) => {
      try {
        const { assetId, url } = await upload(file, serviceConfig);
        const newFile = {
          assetId,
          url,
          fileName: file.name,
          mimeType: file.type,
        };

        if (!formState) return;

        const updatedFormState = formState.map((item) =>
          item.id === record.id
            ? {
                ...item,
                documentFiles: [
                  {
                    assetId: newFile.assetId,
                    fileName: file.name,
                    url: newFile.url,
                    mimeType: file.type,
                  },
                ],
              }
            : item
        );
        setFormState(updatedFormState);
      } catch (error) {
        errorHandler(error, "upload file");
      }
    },
    [serviceConfig, formState, errorHandler]
  );

  const handleInputChange = useCallback(
    (id: string | number, field: string, value: unknown) => {
      const newRecords = formState.map((item) => {
        if (item.id === id) {
          if (field in item.details) {
            return {
              ...item,
              details: {
                ...item.details,
                [field]: value,
              },
            };
          } else {
            return {
              ...item,
              [field]: value,
            };
          }
        }
        return item;
      });
      setFormState(newRecords);
    },
    [formState]
  );

  const getStatusRender = useCallback(
    (status: string) => {
      const statusOption = getSiteDocStatuses(t).find(
        (option) => option.enumValue === status
      );
      return statusOption ? statusOption.render : null;
    },
    [t]
  );

  const columns: NxpTableColumnProps<VocSupportDocument>[] = useMemo(
    () => [
      {
        title: t("voc.eventPage.supporting.acaRef"),
        dataIndex: ["details", "reference"],
        key: "reference",
        width: "10%",
        sorter: (a, b) =>
          sorterForString(a.details.reference, b.details.reference),
        render: (text, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <Input
                type="text"
                value={record.details.reference}
                onChange={(e) =>
                  handleInputChange(record.id, "reference", e.target.value)
                }
              />
            );
          } else {
            return text;
          }
        },
      },
      {
        title: t("voc.eventPage.supporting.subcontractor"),
        dataIndex: ["details", "subcontractor"],
        key: "subcontractor",
        width: "10%",
        sorter: (a, b) =>
          sorterForString(a.details.subcontractor, b.details.subcontractor),
        render: (text, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <Input
                type="text"
                value={record.details.subcontractor}
                onChange={(e) =>
                  handleInputChange(record.id, "subcontractor", e.target.value)
                }
              />
            );
          } else {
            return text;
          }
        },
      },
      {
        title: t("voc.eventPage.summary.subject"),
        dataIndex: ["details", "subject"],
        key: "subject",
        width: "10%",
        sorter: (a, b) => sorterForString(a.details.subject, b.details.subject),
        render: (text, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <Input
                type="text"
                value={record.details.subject}
                onChange={(e) =>
                  handleInputChange(record.id, "subject", e.target.value)
                }
              />
            );
          } else {
            return text;
          }
        },
      },
      {
        title: t("voc.eventPage.supporting.acaAmount"),
        dataIndex: ["details", "amount"],
        key: "amount",
        width: "10%",
        sorter: (a, b) =>
          sorterForNumber(Number(a.details.amount), Number(b.details.amount)),
        render: (amount, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <InputNumber
                style={{ width: "100%" }}
                value={amount}
                onChange={(value) =>
                  handleInputChange(record.id, "amount", value)
                }
                formatter={(value?: unknown) =>
                  formatNumberByLocale(Number(value ?? 0), i18n.language)
                }
                parser={(displayValue?: string) =>
                  parseNumberByLocale(displayValue, i18n.language)
                }
              />
            );
          } else {
            return convertNumber(amount);
          }
        },
      },
      {
        title: t("voc.common.status"),
        dataIndex: ["details", "docStatus"],
        key: "docStatus",
        width: "10%",
        render: (status, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <Select
                style={{ width: "100%" }}
                value={status}
                onChange={(value) =>
                  handleInputChange(record.id, "docStatus", value)
                }
                options={
                  getSiteDocStatuses(t).map((status) => ({
                    label: status.render,
                    value: status.enumValue,
                  })) || []
                }
              />
            );
          } else {
            return getStatusRender(status);
          }
        },
      },
      {
        title: t("voc.eventPage.supporting.relatedEvent"),
        dataIndex: ["details", "relatedEvent"],
        key: "relatedEvent",
        width: "10%",
        sorter: (a, b) =>
          sorterForString(
            relatedEventDisplay(
              a.compensationEventSerial,
              a.compensationEventTitle,
              a.siteEventSerial,
              a.siteEventTitle
            ),
            relatedEventDisplay(
              b.compensationEventSerial,
              b.compensationEventTitle,
              b.siteEventSerial,
              b.siteEventTitle
            )
          ),
        render: (text, record) => {
          return (
            <Button
              type="link"
              onClick={
                !record.siteEventId
                  ? () =>
                      window.open(`./../compensation-events/${record.eventId}`)
                  : () => window.open(`./../site-events/${record.siteEventId}`)
              }
            >
              {relatedEventDisplay(
                record.compensationEventSerial,
                record.compensationEventTitle,
                record.siteEventSerial,
                record.siteEventTitle
              )}
            </Button>
          );
        },
      },
      {
        title: t("voc.eventPage.summary.comment"),
        dataIndex: "comment",
        key: "comment",
        width: "10%",
        sorter: (a, b) => sorterForString(a.comment, b.comment),
        render: (text, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <Input.TextArea
                value={record.comment}
                onChange={(e) =>
                  handleInputChange(record.id, "comment", e.target.value)
                }
              />
            );
          } else {
            return text;
          }
        },
      },
      {
        title: t("voc.eventPage.supporting.file"),
        dataIndex: "file",
        key: "file",
        width: "5%",
        render: (file, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            switch (record.documentFiles?.length) {
              case 0:
                return (
                  <Upload
                    showUploadList={false}
                    beforeUpload={(file) => {
                      handleFileChange(file, record);
                      return false;
                    }}
                  >
                    <Button icon={<UploadOutlined />} />
                  </Upload>
                );
              case 1:
                return (
                  <Upload
                    fileList={record.documentFiles.map((file) => ({
                      uid: file.assetId,
                      name: file.fileName,
                      status: "done",
                      url: file.url,
                    }))}
                    onRemove={() => handleRemoveFile(record)}
                  >
                    {record?.documentFiles.length < 1 && (
                      <Button icon={<UploadOutlined />} />
                    )}
                  </Upload>
                );
              default:
                return null;
            }
          } else {
            return record.documentFiles && record.documentFiles.length > 0 ? (
              <a
                href={record.documentFiles[0].url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {record.documentFiles[0]?.fileName}
              </a>
            ) : null;
          }
        },
      },
    ],
    [
      t,
      editing,
      eventId,
      handleInputChange,
      getStatusRender,
      handleFileChange,
      handleRemoveFile,
    ]
  );

  if (editing) {
    columns.unshift({
      title: t("voc.appDataListComponent.delete"),
      key: "delete",
      width: "3%",
      render: (text, record) => {
        if (isRowDisplayOnly(record, eventId)) {
          return (
            <NxpTooltip
              title={`${
                t("voc.eventPage.supporting.siteDocStatus.editInSiteEvent") +
                record.siteEventSerial
              }`}
            >
              <LockFilled />
            </NxpTooltip>
          );
        }
        return (
          <NxpButtonDeleteConfirm
            type="link"
            danger
            icon={<DeleteOutlined />}
            onConfirm={() => deleteDocument(record)}
          />
        );
      },
    });
  }

  return (
    <NxpPanel
      titleContent={`${t("voc.eventPage.supporting.subcontractsACA")} (${
        acaDocs?.length
      })`}
      defaultCollapsed
    >
      <div className={classes.buttonsContainer}>
        {!editing && (
          <NxpButton onClick={handleClickEdit}>
            {t("voc.common.edit")}
          </NxpButton>
        )}
        {editing && (
          <>
            <NxpButton type="default" onClick={handleClickCancel}>
              {t("voc.common.cancel")}
            </NxpButton>
            <NxpButton onClick={handleSave}>{t("voc.common.save")}</NxpButton>
          </>
        )}
      </div>
      <NxpTable
        loading={isLoading}
        columns={columns}
        dataSource={editing ? formState : acaDocs}
        rowKey="id"
      />
    </NxpPanel>
  );
};

export default AcaPanel;
