import React, { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { CPCSUser } from "../../../services/app";
import AppAccountSettingsModal from "./AppAccountSettingsModal";

const useStyles = createUseStyles(() => ({
  appAccountSettingsButton: {},
}));

interface AppAccountSettingsModalButtonProps {
  user: CPCSUser;
  onPopoverVisibleChange: (visible: boolean) => void;
}

const AppAccountSettingsButton: React.FC<
  AppAccountSettingsModalButtonProps
> = ({ user, onPopoverVisibleChange }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();

  const handleClick: MouseEventHandler<HTMLElement> = (e) => {
    onPopoverVisibleChange(false);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const classes = useStyles();

  return (
    <>
      <NxpButton
        type="default"
        className={classes.appAccountSettingsButton}
        onClick={handleClick}
      >
        {t("voc.common.accountSettings")}
      </NxpButton>
      {modalVisible && (
        <AppAccountSettingsModal
          user={user}
          modalVisible={modalVisible}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default AppAccountSettingsButton;
