import React from "react";

import "./AppIconLink.scss";

interface AppIconLinkProps {
  href: string;
  targetBlank: boolean;
  rel?: string;
  className?: string;
  stopPropagation?: boolean; // needed for preventing row click event in listing
  children?: React.ReactNode;
}

const AppIconLink: React.FC<AppIconLinkProps> = ({
  href,
  targetBlank,
  className,
  stopPropagation,
  children,
}) => {
  const targetBlankProps = {
    target: targetBlank ? "_blank" : undefined,
    rel: targetBlank ? "noopener noreferrer" : undefined,
  };
  return href ? (
    <a
      onClick={stopPropagation ? (e) => e.stopPropagation() : undefined}
      href={href}
      className={`app-icon-link${className ? ` ${className}` : ""}`}
      {...targetBlankProps}
    >
      {children}
    </a>
  ) : null;
};

export default AppIconLink;
