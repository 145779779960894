import { useCallback } from "react";

import { notify } from "@nexploretechnology/nxp-ui";
import { useKeycloak } from "@react-keycloak/web";

import CustomApiError from "../utils/backend/custom-api-error";
import { useLogger } from "./useLogger";

export const useErrorHandler = () => {
  const { keycloak } = useKeycloak();

  const logger = useLogger();

  const handler = useCallback(
    (
      error: Error | unknown,
      taskDescription: string, // task in format such as 'getting locations', 'updating photo detail', 'deleting album'
      errorReference?: string | number | object, // information that could be useful for tracing error, not yet used at current stage
      hideNotification?: boolean
    ) => {
      const msg = `Error occurred${
        taskDescription ? ` when ${taskDescription}` : ""
      } - ${error instanceof Error ? error.message : error}`;

      logger.error(msg, errorReference, error as Error);

      if ((error as CustomApiError).status === 401) {
        keycloak.logout();
      }
      if (!hideNotification) {
        notify.error(msg);
      }
    },
    [keycloak, logger]
  );

  return handler;
};

export default useErrorHandler;
