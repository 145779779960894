import "./CreateEventModalLayout.scss";

import { IChoiceGroupOption, IDropdownOption } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";

import { ValidationResult } from "@nexploretechnology/nxp-ui";

import AppButton from "../../../../app/components/app-button";
import {
  AppFormDropdownField,
  AppFormField,
  AppFormRadioField,
  AppFormRow,
  AppFormTextField,
} from "../../../../app/components/app-form";
import AppModal from "../../../../app/components/app-modal";
import { AsyncResult } from "../../../../app/hooks/useAsync";
import {
  VocEvent,
  VocEventCreation,
  VocEventType,
} from "../../../../app/types";
import CustomApiError from "../../../../app/utils/backend/custom-api-error";

interface CreateEventModalLayoutProps {
  entityId: string;
  showCreateModal: boolean;
  onModalClose: () => void;
  form: VocEventCreation;
  onChange: (changeValues: Partial<VocEventCreation>) => void;
  onSave: (addAnother: boolean) => void;
  validationError: ValidationResult<VocEventCreation>;
  createEventAsyncResult: AsyncResult<VocEvent>;
  sourceOptions: IDropdownOption[];
  sourceTypeOptions: IDropdownOption[];
}

const CreateEventModalLayout: React.FC<CreateEventModalLayoutProps> = ({
  showCreateModal,
  onModalClose,
  form,
  onChange,
  onSave,
  validationError,
  createEventAsyncResult,
  sourceOptions,
  sourceTypeOptions,
}) => {
  const { t } = useTranslation();

  const eventTypeOptions: IDropdownOption[] = [
    {
      key: VocEventType.VO,
      text: t("voc.eventList.createEvent.vo"),
    },
    {
      key: VocEventType.Claim,
      text: t("voc.eventList.createEvent.claim"),
    },
    {
      key: VocEventType.VO_And_Claim,
      text: t("voc.eventList.createEvent.voAndClaim"),
    },
  ];

  const yesNoOptions: IChoiceGroupOption[] = [
    {
      key: "true",
      text: t("voc.common.yes"),
    },
    {
      key: "false",
      text: t("voc.common.no"),
    },
  ];

  const inputErrors = {
    ...validationError,
    ...(createEventAsyncResult.error as CustomApiError)?.serverResponseData,
  };

  return (
    <AppModal
      title={t("voc.createEvent.createNewEvent")}
      className="create-event-modal"
      isOpen={showCreateModal}
      loading={createEventAsyncResult.loading}
      onClose={onModalClose}
      footer={
        <AppButton
          disabled={createEventAsyncResult.loading}
          className="button"
          onClick={() => onSave(false)}
          text={t("voc.common.save")}
        />
      }
      errorContent={
        !(createEventAsyncResult.error as CustomApiError)?.serverResponseData &&
        createEventAsyncResult.error?.message
      }
    >
      <AppFormRow>
        <AppFormTextField
          required={true}
          label={t("voc.eventPage.summary.eventTitle")}
          name="eventTitle"
          value={form.eventTitle}
          errorMessage={inputErrors && inputErrors.eventTitle}
          onChange={(_, value) => onChange({ eventTitle: value })}
        />
      </AppFormRow>
      <AppFormRow>
        <AppFormDropdownField
          label={t("voc.eventPage.summary.eventType")}
          required={true}
          placeholder={t("voc.common.pleaseSelect")}
          options={eventTypeOptions}
          value={form.eventType}
          errorMessage={inputErrors && inputErrors.eventType}
          onChange={(_, option) =>
            onChange({
              eventType: option.key.toString(),
            })
          }
        />
        <AppFormRadioField
          errorMessage={inputErrors && inputErrors.awarded}
          options={yesNoOptions}
          selectedKey={form.awarded?.toString()}
          onChange={(_: React.FormEvent, option: IChoiceGroupOption) =>
            onChange({ awarded: option.key === "true" })
          }
          label={t("voc.common.awarded")}
        />
      </AppFormRow>
      <AppFormRow>
        <AppFormField className="category-dropdown">
          <AppFormDropdownField
            label={t("voc.common.source")}
            required={true}
            placeholder={t("voc.common.pleaseSelect")}
            options={sourceOptions}
            value={form.eventSource}
            errorMessage={inputErrors && inputErrors.eventSource}
            onChange={(_, option) =>
              onChange({
                eventSource: option.key.toString(),
              })
            }
          />
        </AppFormField>
        <AppFormField className="category-dropdown">
          <AppFormDropdownField
            label={t("voc.eventPage.summary.sourceType")}
            required={true}
            placeholder={t("voc.common.pleaseSelect")}
            options={sourceTypeOptions}
            value={form.eventSourceType}
            errorMessage={inputErrors && inputErrors.eventSourceType}
            onChange={(_, option) =>
              onChange({
                eventSourceType: option.key.toString(),
              })
            }
          />
        </AppFormField>
      </AppFormRow>
      <AppFormRow>
        <AppFormTextField
          label={t("voc.eventPage.summary.narrative")}
          name="narrative"
          value={form.narrative}
          errorMessage={inputErrors && inputErrors.narrative}
          onChange={(_, value) => onChange({ narrative: value })}
        />
      </AppFormRow>
      <AppFormRow>
        <AppFormRadioField
          options={yesNoOptions}
          selectedKey={String(form.contemporaryRecordRequired)}
          onChange={(_: React.FormEvent, option: IChoiceGroupOption) =>
            onChange({ contemporaryRecordRequired: option.key === "true" })
          }
          label={t("voc.eventPage.summary.contemporaryRecordRequired")}
        />
      </AppFormRow>
    </AppModal>
  );
};

export default CreateEventModalLayout;
