import { Route } from "react-router-dom";

import AppProtectedPage from "../app/components/app-router/AppProtectedPage";
import VarianceReportContainer from "./pages/variance-report/VarianceReportContainer";
import DashboardContainer from "./pages/dashboard/DashboardContainer";

export const reportsRoutes = [
  <Route
    key="variance-report"
    path="/entities/:entityId/variance/report/page"
    element={<AppProtectedPage component={<VarianceReportContainer />} />}
  />,
  <Route
    key="dashboard"
    path="/entities/:entityId/dashboard"
    element={
      <AppProtectedPage requireEntityReady component={<DashboardContainer />} />
    }
  />,
];
