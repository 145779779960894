import {
  NxpFormItem,
  NxpTable,
  NxpTableRowDropdown,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { ManageRolesTableItem } from "../../manage-role-services";
import useAppContext from "../../../app/hooks/useAppContext";
import { ColumnProps } from "antd/es/table";
import { useSelectUserOptions } from "../../../app/hooks/useSelectUserOptions";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { Tag, Tooltip } from "antd";
import { appTheme } from "../../../app/components/appTheme";
import DeleteRoleButton from "./actions/DeleteRoleButton";
import EditRoleButton from "./actions/EditRoleButton";

const useStyles = createUseStyles((theme) => ({
  userTag: {
    fontSize: theme.fontSize.main,
    border: "none",
  },
  manageRolesTable: {
    "& .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)":
      {
        "& .ant-select-selector": {
          backgroundColor: "transparent",
          cursor: "default",
        },
        "& .ant-select-arrow": {
          display: "none",
        },
      },
  },
}));

interface ManageRolesTableProps {
  rows: ManageRolesTableItem[];
  isLoading: boolean;
  onSetRows: Dispatch<SetStateAction<ManageRolesTableItem[]>>;
  onSetInitRows: React.Dispatch<React.SetStateAction<ManageRolesTableItem[]>>;
  onRefetch: () => Promise<void>;
}

const ManageRolesTable: React.FC<ManageRolesTableProps> = ({
  rows,
  isLoading,
  onSetRows,
  onSetInitRows,
  onRefetch,
}) => {
  const { t } = useTranslation();

  const { hasRight } = useAppContext();

  const classes = useStyles();

  const { getUserOptionsForTag } = useSelectUserOptions();

  const renderTag = useCallback(
    (item: ManageRolesTableItem) => (props: CustomTagProps) => {
      const option = getUserOptionsForTag(item.users).find(
        (o) => o.id === props.value
      );
      const tag = option ? option.tag : props.label?.toString().split(" <")[0];
      return (
        <Tooltip title={option?.name}>
          <Tag
            style={{ fontSize: appTheme.fontSize.main, border: "none" }}
            closable={props.closable}
            onClose={props.onClose}
          >
            {tag}
          </Tag>
        </Tooltip>
      );
    },
    [getUserOptionsForTag]
  );

  const renderUsers = useCallback(
    (_: unknown, row: ManageRolesTableItem) => (
      <NxpFormItem
        key={`user-${row.id}`}
        controlType="selectUserMultiple"
        controlProps={{
          options: getUserOptionsForTag(
            rows.find((role) => role.roleCode === row.roleCode)?.users || []
          ),
          value: row.entityRoleUserIds,
          tagRender: renderTag(row),
          onChange: (value) => {
            onSetRows((prevRowData) => {
              return prevRowData.map((prevRow) => {
                if (row.id === prevRow.id) {
                  if (typeof value === "string") {
                    return {
                      ...prevRow,
                      entityRoleUserIds: [value],
                    };
                  } else {
                    return {
                      ...prevRow,
                      entityRoleUserIds: value,
                    };
                  }
                } else {
                  return prevRow;
                }
              });
            });
          },
          disabled: !hasRight({ actionType: "assign", objectCode: "role" }),
        }}
      />
    ),
    [getUserOptionsForTag, hasRight, onSetRows, renderTag, rows]
  );

  const columns: ColumnProps<ManageRolesTableItem>[] = useMemo(
    () => [
      {
        title: t("voc.ManageRole.RoleName"),
        dataIndex: "name",
        width: 250,
        sorter: (a: ManageRolesTableItem, b: ManageRolesTableItem) =>
          sorterForString(a.name, b.name),
      },
      {
        title: t("voc.ManageRole.User"),
        dataIndex: "entityRoleUserIds",
        render: renderUsers,
      },
      {
        fixed: "right",
        align: "right",
        title: t("voc.common.action"),
        dataIndex: "action",
        width: 30,
        render: (_: unknown, row: ManageRolesTableItem) => {
          const hasEditRoleRight = hasRight({
            actionType: "edit",
            objectCode: "role",
          });

          const hasDeleteRoleRight = hasRight({
            actionType: "delete",
            objectCode: "role",
          });

          if (hasEditRoleRight) {
            return (
              <NxpTableRowDropdown
                menu={{
                  items: [
                    ...(hasEditRoleRight
                      ? [
                          {
                            label: (
                              <EditRoleButton
                                row={row}
                                onSetRows={onSetRows}
                                onSetInitRows={onSetInitRows}
                              />
                            ),
                            key: "edit",
                          },
                        ]
                      : []),
                    ...(hasDeleteRoleRight
                      ? [
                          {
                            label: (
                              <DeleteRoleButton
                                row={row}
                                onRefetch={onRefetch}
                              />
                            ),
                            key: "delete",
                          },
                        ]
                      : []),
                  ],
                }}
              />
            );
          } else {
            return <></>;
          }
        },
      },
    ],
    [t, renderUsers, hasRight, onSetRows, onSetInitRows, onRefetch]
  );

  return (
    <div className={classes.manageRolesTable}>
      <NxpTable
        loading={isLoading}
        rowKey="id"
        columns={columns}
        dataSource={rows}
      />
    </div>
  );
};

export default ManageRolesTable;
