import React from "react";
import { useTranslation } from "react-i18next";

import {
  ISpinnerStyleProps,
  ISpinnerStyles,
  IStyleFunctionOrObject,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";

import useAppContext from "../../hooks/useAppContext";
import uploadSVG from "../../images/upload.svg";
import { getMimeTypeByFileName, upload } from "../../services/file";
import { hasValue } from "../../utils/has-value";
import { ImagePreviewer, PlaceholderPreviewer } from "./Previewer";

import "./AppFileUpload.scss";

interface AppFileUploaderProps {
  label: string;
  onChange: (files: Partial<AttachmentParams>[], ref?: any) => void;
  spinnerStyles?: IStyleFunctionOrObject<ISpinnerStyleProps, ISpinnerStyles>;
  fileLimit?: number;
  fileRef?: any;
  customUploadPath?: string;
}

export interface AttachmentParams {
  id: number;
  createdAt: Date;
  remarkId: number;
  assetId: string;
  fileName: string;
  mimeType: string;
  url?: string;
}

export interface Attachment extends Partial<AttachmentParams> {
  url: string;
}

const AppFileUpload: React.FC<AppFileUploaderProps> = (props) => {
  const { t } = useTranslation();
  const {
    label,
    onChange,
    spinnerStyles,
    fileLimit,
    fileRef,
    customUploadPath,
  } = props;

  const [files, setFiles] = React.useState<Attachment[]>([]);
  const { serviceConfig } = useAppContext();
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const previewList = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    onChange(files, fileRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, fileRef]);

  const handleOnFileDelete = React.useCallback(
    (file: Attachment) => {
      setFiles((prevFiles) =>
        prevFiles.filter((item) => item.assetId !== file.assetId)
      );
    },
    [setFiles]
  );

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const renderPreviewer = React.useCallback(
    (file: Attachment) => {
      switch (file.mimeType) {
        case "image/png":
        case "image/jpg":
        case "image/jpeg":
        case "image/gif":
          return (
            <ImagePreviewer
              url={file.url}
              remove={() => handleOnFileDelete(file)}
            />
          );
        default:
          return (
            <PlaceholderPreviewer
              url={file.url}
              remove={() => handleOnFileDelete(file)}
            />
          );
      }
    },
    [handleOnFileDelete]
  );

  const startUploadFile = React.useCallback(() => {
    const fileInput = fileInputRef.current;
    if (fileInput && !isLoading) {
      fileInput.click();
    }
  }, [isLoading]);

  const uploadFile = React.useCallback(
    async (file: File) => {
      const uploadResult = await upload(file, serviceConfig, customUploadPath);
      const attachment: Attachment = {
        assetId: uploadResult.assetId,
        fileName: file.name,
        mimeType: hasValue(file.type)
          ? file.type
          : getMimeTypeByFileName(file.name),
        url: uploadResult.url,
      };
      setFiles((prevFiles) => [...prevFiles, attachment]);
    },
    [customUploadPath, serviceConfig]
  );

  const onFileInputChange = React.useCallback(async () => {
    const fileInput = fileInputRef.current;
    if (!fileInput || fileInput.files.length <= 0) return;
    setIsLoading(true);
    const newFileList = fileInputRef.current.files;
    const newFiles = [
      ...(Array(newFileList.length).keys() as unknown as number[]),
    ]
      .map((index) => newFileList.item(index))
      .filter((file): file is File => file !== null)
      .slice(0, 5 - files.length);
    try {
      await Promise.all(newFiles.map((file) => uploadFile(file)));
    } finally {
      setIsLoading(false);
      fileInput.value = "";
    }
  }, [files.length, uploadFile]);

  return (
    <div className="file-uploader-container">
      <label className="label">{label}</label>
      <div className="preview-list-wrapper">
        <div ref={previewList} className="preview-list">
          {(fileLimit === undefined || files.length < fileLimit) && (
            <div className="uploader" onClick={startUploadFile}>
              {isLoading ? (
                <Spinner
                  className="file-uploader-spinner"
                  size={SpinnerSize.small}
                  styles={spinnerStyles}
                />
              ) : (
                <img src={uploadSVG} alt="" />
              )}
              <span>{t("voc.common.upload")}</span>
            </div>
          )}
          {files.map((it) => (
            <div key={it.assetId} className="preview-list-item">
              {renderPreviewer(it)}
              <div className="preview-list-item__label">{it.fileName}</div>
            </div>
          ))}
        </div>
      </div>
      <input
        hidden={true}
        type="file"
        ref={fileInputRef}
        accept="image/*,audio/*,video/*,.csv,.doc,.xls,.ppt,.docx,.xlsx,.pptx,.pdf,.zip,.msg"
        multiple={fileLimit > 1 ? true : false}
        onChange={onFileInputChange}
      />
    </div>
  );
};

export default AppFileUpload;
