import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpFormGrid, NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";

import { VocCustomUiQuestionListCustomField } from "../../../../app/types";
import { getFormattedInputNumberControlProps } from "../../../../app/utils/number/getFormattedInputNumberControlProps";

interface ValuesTabCustomFieldsProps {
  customFields: VocCustomUiQuestionListCustomField[];
  customValues: any;
  onSetCustomValues: React.Dispatch<React.SetStateAction<any>>;
  isEditingValues: boolean;
}

const useStyles = createUseStyles((theme) => ({
  title: {
    color: theme.palette.primary,
  },
}));

const ValuesTabCustomFields: React.FC<ValuesTabCustomFieldsProps> = ({
  customFields,
  customValues,
  onSetCustomValues,
  isEditingValues,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const formItems: NxpFormGridItemProps<any>[] = customFields
    ?.filter((f) => f.display)
    ?.map((f) => {
      let controlType: NxpFormGridItemProps<any>["controlType"];
      let options: { label: string; value: any }[] = [];

      switch (f.dataType) {
        case "TEXT":
          controlType = "input";
          break;
        case "VALUE":
        case "PERCENTAGE":
          controlType = "inputNumber";
          break;
        case "DATE":
          controlType = "datePicker";
          break;
        case "DROPDOWN":
          controlType = "select";
          options = f.validations.limits.map((o) => ({ label: o, value: o }));
          break;
        case "YESNO":
          controlType = "radioGroup";
          options = [
            { label: "Yes", value: true },
            { label: "No", value: false },
          ];
          break;
        default:
          controlType = "inputNumber";
          break;
      }

      const isCalculationEditing =
        isEditingValues && f.dataType === "CALCULATION";

      const controlProps = {
        value: isCalculationEditing ? "" : customValues[f.key] ?? "",
        onChange: (event) => {
          const value = event && event.target ? event.target.value : event;
          setCustomFieldValues(f.key, value);
        },
        options,
        disabled: f.dataType === "CALCULATION",
        addonAfter: f.dataType === "PERCENTAGE" ? "%" : undefined,
        ...(f.dataType === "PERCENTAGE" || controlType === "inputNumber"
          ? getFormattedInputNumberControlProps()
          : {}),
      };

      return {
        controlType,
        label: f.name.toUpperCase(),
        itemFieldName: f.key,
        span: 3,
        controlProps,
      };
    });

  function setCustomFieldValues(fieldKey: string, newValue: any) {
    onSetCustomValues((prev) => ({
      ...prev,
      [fieldKey]: newValue,
    }));
  }

  return (
    <>
      <h4 className={classes.title}>{t("voc.common.customFields")}</h4>
      <NxpFormGrid
        editing={isEditingValues}
        formItems={formItems}
        onFormStateChange={(fieldName: string, value) =>
          setCustomFieldValues(fieldName, value)
        }
      />
    </>
  );
};

export default ValuesTabCustomFields;
