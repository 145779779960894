import React from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import {
  CheckCircleFilled,
  LayoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { NxpButtonWithDropdown, NxpHeader } from "@nexploretechnology/nxp-ui";
import { Menu, Typography } from "antd";

const useStyles = createUseStyles(() => ({
  demoHeader: {},
}));

interface DemoHeaderAdvancedProps {}

const DemoHeaderAdvanced: React.FC<DemoHeaderAdvancedProps> = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div className={classes.demoHeader}>
      <NxpHeader
        onBackClick={() => navigate(-1)}
        titleContent="Demo Header"
        subheadingContent={
          <>
            test subheading{" "}
            <Typography.Text type="success">
              <CheckCircleFilled />
            </Typography.Text>{" "}
          </>
        }
        actionContent={
          <NxpButtonWithDropdown
            menu={
              <Menu onClick={(item) => console.log(item.key)}>
                <Menu.Item
                  onClick={() => console.log("test 1")}
                  key="1"
                  icon={<LayoutOutlined />}
                >
                  Demo Splitter
                </Menu.Item>
                <Menu.Item
                  onClick={() => console.log("test 2")}
                  key="2"
                  icon={<LayoutOutlined />}
                >
                  Demo Left Drawer
                </Menu.Item>
                <Menu.Item
                  key="3"
                  onClick={() => console.log("test 3")}
                  icon={<UserOutlined />}
                >
                  2nd menu item
                </Menu.Item>
              </Menu>
            }
          >
            testing menu button
          </NxpButtonWithDropdown>
        }
      />
    </div>
  );
};

export default DemoHeaderAdvanced;
