import React, { useCallback, useEffect, useState } from "react";

import {
  notify,
  NxpFormTable,
  NxpFormTableColumnProps,
  sorterForNumber,
  sorterForString,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../app/hooks/useAppContext";
import {
  deleteDemo,
  DemoItem,
  DemoItemForm,
  getDemoFormList,
  updateDemo,
} from "../../demo-services";

const columns: NxpFormTableColumnProps<DemoItem>[] = [
  {
    title: "name",
    dataIndex: "name",
    width: 150,
    fixed: "left",
    sorter: (a, b) => sorterForString(a.name, b.name),
    formItemProps: {
      controlType: "input",
    },
  },
  {
    title: "age",
    dataIndex: "age",
    width: 150,
    sorter: (a, b) => sorterForNumber(a.age, b.age),
    formItemProps: {
      controlType: "inputNumber",
      controlProps: {
        decimalPlace: 0,
      },
    },
  },
  {
    title: "address",
    dataIndex: "address",
    width: 300,
  },
  {
    title: "address",
    dataIndex: "address",
    width: 300,
  },
  {
    title: "address",
    dataIndex: "address",
    width: 300,
  },
  {
    title: "address",
    dataIndex: "address",
    width: 300,
  },
];

const formSchema = yup.object().shape({
  name: yup.string().nullable().required("Name required."),
  age: yup.number(),
});

interface TableFormProps {}

const TableForm: React.FC<TableFormProps> = () => {
  const [editItem, setEditItem] = useState<DemoItem>();
  const appContext = useAppContext();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [demoItems, setDemoItems] = useState<DemoItem[]>([]);

  useEffect(() => {
    getDemoFormList().then((data) => setDemoItems(data));
  }, []);

  const handleSaveValidated = async () => {
    if (editItem) {
      setSaveInProgress(true);
      try {
        const rec = await updateDemo(editItem);
        setDemoItems((prevState) =>
          prevState.map((item) => (item.key === rec.key ? rec : item))
        );
        notify.actionCompleted();
        setTimeout(() => setSaveInProgress(false));
      } catch (ex) {
        appContext.errorHandler(ex as Error, "update demo item");
      } finally {
        setSaveInProgress(false);
        setEditItem(undefined);
      }
    }
  };

  const [validationError, , clearError, saveWithValidate] =
    useYupValidate<DemoItemForm>(editItem, formSchema, handleSaveValidated);

  const handleRowEdit = useCallback(
    (editItem: DemoItem) => setEditItem({ ...editItem }),
    []
  );

  const handleRowSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  const handleRowCancel = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      setEditItem(undefined);
      clearError();
    },
    [clearError]
  );

  const handleRowDelete = useCallback(
    async (deleteItem: DemoItem) => {
      try {
        setSaveInProgress(true);
        await deleteDemo(deleteItem.key);
        setDemoItems((prevState) =>
          prevState?.filter((item) => item.key !== deleteItem.key)
        );
      } catch (ex) {
        appContext.errorHandler(ex as Error, "delete demo");
      } finally {
        setSaveInProgress(false);
        setEditItem(undefined);
      }
    },
    [appContext]
  );

  const handleFormStateChange = useCallback(
    (fieldName: keyof DemoItem, value: unknown) => {
      console.log("ccccccccccccccc", fieldName, value);

      if (
        fieldName === "age" &&
        Number.isFinite(value) &&
        (value as number) > 100
      ) {
        setEditItem((prevState) => ({
          ...prevState!,
          age: 100,
        }));
        return;
      }

      setEditItem((prevState) => ({
        ...prevState!,
        [fieldName]: value,
      }));
    },
    []
  );

  return (
    <NxpFormTable
      formState={editItem}
      onFormStateChange={handleFormStateChange}
      validationError={validationError}
      hideDelete={false} // same as leaving this undefined
      rowDisableDelete={(record: DemoItem) => Number(record.key) % 2 === 0}
      rowDisableEdit={(record: DemoItem) => Number(record.key) % 4 === 0}
      useDefaultHeight
      saveInProgress={saveInProgress}
      itemCompareKey="key"
      onRowDelete={handleRowDelete}
      onRowEdit={handleRowEdit}
      onRowSave={handleRowSave}
      onRowCancel={handleRowCancel}
      columns={columns}
      dataSource={demoItems}
      pagination={{ pageSize: 30 }}
    />
  );
};

export default React.memo(TableForm);
