import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import { NxpButton, NxpSteps } from "@nexploretechnology/nxp-ui";

const useStyles = createUseStyles((theme) => ({
  DemoSteps: {},
}));

interface DemoStepsProps {}

const DemoSteps: React.FC<DemoStepsProps> = () => {
  const classes = useStyles();
  const demoSteps = ["Step A", "Step B", "Step C"] as const;
  type DemoStep = (typeof demoSteps)[number];
  const [activeDemoStep, setActiveDemoStep] = useState<DemoStep>(demoSteps[0]);

  const stepWithKeys = [
    { title: "Step 1", key: 100 },
    { title: "Step 22", key: 102 },
    { title: "Step 333", key: 103 },
  ];
  const [activeStepWithKey, setActiveStepWithKey] = useState(
    stepWithKeys[0].key
  );
  return (
    <div className={classes.DemoSteps}>
      <NxpSteps
        steps={demoSteps}
        activeStepKey={activeDemoStep}
        onStepKeyChange={(step) => setActiveDemoStep(step)}
      />
      <NxpButton
        type="default"
        onClick={() =>
          setActiveDemoStep((prev) => demoSteps[demoSteps.indexOf(prev) - 1])
        }
        disabled={demoSteps.indexOf(activeDemoStep) === 0}
      >
        Back
      </NxpButton>
      <NxpButton
        onClick={() =>
          setActiveDemoStep((prev) => demoSteps[demoSteps.indexOf(prev) + 1])
        }
        disabled={demoSteps.indexOf(activeDemoStep) === demoSteps.length - 1}
      >
        Next
      </NxpButton>
      <br />
      <br />
      <p>Steps with title and key</p>
      <NxpSteps
        steps={stepWithKeys}
        activeStepKey={activeStepWithKey}
        onStepKeyChange={(step) => setActiveStepWithKey(step)}
      />
      <NxpButton
        type="default"
        onClick={() =>
          setActiveStepWithKey(
            (prev) =>
              stepWithKeys[
                stepWithKeys.findIndex((step) => step.key === prev) - 1
              ].key
          )
        }
        disabled={
          stepWithKeys.findIndex((step) => step.key === activeStepWithKey) === 0
        }
      >
        Back
      </NxpButton>
      <NxpButton
        onClick={() =>
          setActiveStepWithKey(
            (prev) =>
              stepWithKeys[
                stepWithKeys.findIndex((step) => step.key === prev) + 1
              ].key
          )
        }
        disabled={
          stepWithKeys.findIndex((step) => step.key === activeStepWithKey) ===
          stepWithKeys.length - 1
        }
      >
        Next
      </NxpButton>
    </div>
  );
};

export default DemoSteps;
