import React from "react";

import { VocSupportDocument } from "../../../app/types";
import Calendar from "./calendar";
import RecordsLists from "./records-lists/RecordsLists";
import RecordsSummary from "./records-summary";
import { DateRecord } from "./RecordsTabContainer";

import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";
import NewSupportingRecordButton from "./records-lists/add-supporting-record/NewSupportingRecordButton";
import "./RecordsTabLayout.scss";

export interface BatchUpdateEvent {
  id: string;
  serial: string;
  title: string;
  eventType?: "compEvent" | "siteEvent";
}

interface RecordsTabLayoutProps {
  eventId: string;
  entityId: string;
  dateRecords: DateRecord[];
  dateOfAwareness?: Date;
  dateOfCessation?: Date;
  onStatusRecordsRefresh: () => void;
  eventSupportDocuments: VocSupportDocument[];
  siteEventId: string;
  events: BatchUpdateEvent[];
  contemporaryRecordRequired: boolean;
}

const RecordsTabLayout: React.FC<RecordsTabLayoutProps> = ({
  eventId,
  entityId,
  dateRecords,
  dateOfAwareness,
  dateOfCessation,
  onStatusRecordsRefresh,
  eventSupportDocuments,
  siteEventId,
  events,
  contemporaryRecordRequired,
}) => {
  return (
    <>
      <AccessVisible
        objectCode={eventId ? "compensation_event" : "event"}
        actionType="create"
      >
        <NewSupportingRecordButton onRefresh={onStatusRecordsRefresh} />
      </AccessVisible>
      <div className="voc-records-tab">
        <div>
          <Calendar
            dateOfAwareness={dateOfAwareness}
            dateOfCessation={dateOfCessation}
            dateRecords={dateRecords}
            onStatusRecordsRefresh={onStatusRecordsRefresh}
            eventSupportDocuments={eventSupportDocuments}
            contemporaryRecordRequired={contemporaryRecordRequired}
            events={events}
            siteEventId={siteEventId}
            // permissions={"compensation event:edit"}
          />
          <RecordsLists
            onRefresh={onStatusRecordsRefresh}
            labourRecords={eventSupportDocuments.filter(
              (doc) => doc.documentType === "VO-EV-SDOC-02"
            )}
            plantRecords={eventSupportDocuments.filter(
              (doc) => doc.documentType === "VO-EV-SDOC-03"
            )}
            timesheets={eventSupportDocuments.filter(
              (doc) => doc.documentType === "VO-EV-SDOC-04"
            )}
            dailyDiaries={eventSupportDocuments.filter(
              (doc) => doc.documentType === "VO-EV-SDOC-05"
            )}
            contemporaryRecords={eventSupportDocuments.filter(
              (doc) => doc.documentType === "VO-EV-SDOC-08"
            )}
          />
        </div>
        <RecordsSummary
          dateRecords={dateRecords}
          dateOfAwareness={dateOfAwareness}
          dateOfCessation={dateOfCessation}
          contemporaryRecordRequired={contemporaryRecordRequired}
          eventId={eventId}
          entityId={entityId}
          siteEventId={siteEventId}
        />
      </div>
    </>
  );
};

export default RecordsTabLayout;
