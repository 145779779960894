import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import {
  notify,
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModalFooter,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import { t } from "i18next";
import useAppContext from "../../../hooks/useAppContext";
import { updateMyPassword } from "../../../services/app";

const useStyles = createUseStyles((theme) => ({
  appAccountSettingsSecurity: {
    marginTop: theme.spacing(1),
  },
}));

interface UpdatePasswordForm {
  newPassword: string;
  confirmPassword: string;
}

interface AppAccountSettingsSecurityProps {
  onModalClose: () => void;
}

const AppAccountSettingsSecurity: React.FC<AppAccountSettingsSecurityProps> = ({
  onModalClose,
}) => {
  const appContext = useAppContext();

  const [editForm, setEditForm] = useState<UpdatePasswordForm>({
    newPassword: "",
    confirmPassword: "",
  });

  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    try {
      await updateMyPassword(editForm.newPassword, appContext.serviceConfig);
      setSaveInProgress(false);
      notify.actionCompleted();
    } catch (ex) {
      appContext.errorHandler(ex, "update password");
    } finally {
      setSaveInProgress(false);
    }
  };

  const formSchema = yup.object({
    newPassword: yup
      .string()
      .nullable()
      .required(t("voc.updatePassword.newPasswordRequired"))
      .test("pattern", t("voc.updatePassword.passwordRule"), function (val) {
        if (!val) {
          // pass to next validator
          return true;
        }
        return !!val?.match(
          /(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*+=~]).{8,}/
        );
      }),
    confirmPassword: yup
      .string()
      .nullable()
      .required(t("voc.updatePassword.confirmPasswordRequired"))
      .test("equal", t("voc.updatePassword.doesNotMatch"), function (val) {
        const ref = yup.ref("newPassword");
        return val === this.resolve(ref);
      }),
  });

  const [validationError, , , saveWithValidate] =
    useYupValidate<UpdatePasswordForm>(
      editForm,
      formSchema,
      handleSaveValidated
    );

  const handleFormGridStateChange = (
    fieldName: keyof UpdatePasswordForm,
    value: unknown
  ) => {
    // console.log('ccccccccccccccc', fieldName, value);

    setEditForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSave = () => {
    saveWithValidate(undefined);
  };

  const classes = useStyles();

  const formItems: NxpFormGridItemProps<UpdatePasswordForm>[] = [
    {
      controlType: "input",
      controlProps: {
        type: "password",
      },
      startOnNewRow: true,
      required: true,
      label: t("voc.common.newPassword"),
      itemFieldName: "newPassword",
      span: 8,
    },
    {
      controlType: "input",
      controlProps: {
        type: "password",
      },
      required: true,
      label: t("voc.common.confirmPassword"),
      itemFieldName: "confirmPassword",
      span: 8,
    },
  ];

  return (
    <div className={classes.appAccountSettingsSecurity}>
      <h2>{t("voc.common.updatePassword")}</h2>
      <NxpFormGrid
        formState={editForm}
        validationError={validationError}
        formItems={formItems}
        onFormStateChange={handleFormGridStateChange}
      />
      <NxpModalFooter>
        <>
          <NxpButton
            type="default"
            onClick={onModalClose}
            disabled={saveInProgress}
          >
            {t("voc.common.cancel")}
          </NxpButton>
          <NxpButton onClick={handleSave} loading={saveInProgress}>
            {t("voc.common.save")}
          </NxpButton>
        </>
      </NxpModalFooter>
    </div>
  );
};

export default AppAccountSettingsSecurity;
