import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { notify } from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../app/hooks/useAppContext";
import { useAsync } from "../../../../app/hooks/useAsync";
import { useValidate } from "../../../../app/hooks/useValidate";
import { VocEvent, VocEventCreation } from "../../../../app/types";
import { createEvent } from "../../../compensation-event-services";
import CreateEventModalLayout from "./CreateEventModalLayout";

interface CreateEventModalContainerProps {
  showCreateModal: boolean;
  onModalClose: () => void;
  entityId: string;
}

function getValidationSchema() {
  return yup.object().shape({
    eventTitle: yup.string().nullable().required("Event Title required."),
    eventType: yup.string().nullable().required("Event Type required"),
    awarded: yup.boolean().nullable(),
    reference: yup.string().nullable(),
    contemporaryRecordRequired: yup.boolean().nullable(),
    eventSource: yup.string().nullable().required("Event Source required"),
    eventSourceType: yup
      .string()
      .nullable()
      .required("Event Source Type required"),
    narrative: yup.string().nullable(),
  });
}

const CreateEventModalContainer: React.FC<CreateEventModalContainerProps> = ({
  showCreateModal,
  onModalClose,
  entityId,
}) => {
  const { libraries, serviceConfig, errorHandler } = useAppContext();
  const navigate = useNavigate();

  const [form, setForm] = React.useState<VocEventCreation>(() => ({
    eventType: null,
    awarded: false,
    reference: null,
    eventTitle: null,
    contemporaryRecordRequired: true,
    eventSource: null,
    eventSourceType: null,
    narrative: null,
  }));

  const options = useMemo(() => {
    const sourceOptions = libraries["VOC_SOURCES"].map((dict) => ({
      text: dict.label,
      key: dict.value,
    }));

    const sourceTypeOptions = libraries["VOC_SOURCE_TYPE"].map((dict) => ({
      text: dict.label,
      key: dict.value,
    }));

    return {
      sourceOptions,
      sourceTypeOptions,
    };
  }, [libraries]);

  const [createEventAsyncResult, setCreateEventRequest] = useAsync<VocEvent>(
    undefined,
    {
      onSuccess: (data) => {
        notify.actionCompleted();
        navigate(`./${data.id}`);
      },
      onError: (ex) => {
        errorHandler(ex, "compensation event creation");
      },
    }
  );

  const handleSaveValidated = React.useCallback(
    async (addAnother: boolean) => {
      setCreateEventRequest(() => {
        return createEvent(form, serviceConfig);
      });
    },
    [form, serviceConfig, setCreateEventRequest]
  );

  const [validationError, , , saveWithValidate] = useValidate<
    Partial<VocEventCreation>,
    boolean //context type, eg. addAnother
  >(form, getValidationSchema(), handleSaveValidated);

  const handleChange = React.useCallback(
    (changeValues: Partial<VocEventCreation>) => {
      setForm((prevState) => ({ ...prevState, ...changeValues }));
    },
    []
  );

  const handleSave = (addAnother: boolean) => {
    saveWithValidate(addAnother);
  };

  return (
    <CreateEventModalLayout
      entityId={entityId}
      showCreateModal={showCreateModal}
      onModalClose={onModalClose}
      form={form}
      onChange={handleChange}
      onSave={handleSave}
      validationError={validationError}
      createEventAsyncResult={createEventAsyncResult}
      sourceOptions={options.sourceOptions}
      sourceTypeOptions={options.sourceTypeOptions}
    />
  );
};

export default CreateEventModalContainer;
