import React, { useState } from "react";

import { notify, useYupValidate } from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { CPCSUser } from "../../../../services/app";
import { PresignResult, uploadAvatar } from "../../../../services/file";
import { updateMyProfile } from "../../../../services/user-preference-services";
import AppAccountSettingsGeneralLayout from "./AppAccountSettingsGeneralLayout";

interface AppAccountSettingsGeneralContainerProps {
  user: CPCSUser;
  onModalClose: () => void;
}

const AppAccountSettingsGeneralContainer: React.FC<
  AppAccountSettingsGeneralContainerProps
> = ({ user, onModalClose }) => {
  const appContext = useAppContext();

  const [editForm, setEditForm] = useState<CPCSUser>(user);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    try {
      // update is not returning user object at the moment
      await updateMyProfile(editForm, appContext.serviceConfig);
      appContext.onAppContextCacheItemUpdate("activeUser", {
        ...appContext.activeUser!,
        ...editForm,
      });
      setSaveInProgress(false);
      notify.actionCompleted();
      onModalClose();
    } catch (ex) {
      appContext.errorHandler(ex, "update personal profile");
    } finally {
      setSaveInProgress(false);
    }
  };

  const formSchema = yup.object({
    id: yup.string().nullable().required("ID required."),
    salutation: yup.string().nullable(),
    firstName: yup.string().nullable().required("First Name required."),
    lastName: yup.string().nullable().required("Last Name required."),
    displayName: yup.string().nullable().required("Display Name required."),
    primaryEmail: yup
      .string()
      .nullable()
      .required("Email required.")
      .email("Email not in correct format."),
    title: yup.string().nullable(),
    mobileNumber: yup.string().nullable(),
    officeNumber: yup.string().nullable(),
    comments: yup.string().nullable(),
    avatar: yup
      .object({
        assetId: yup.string().required(),
        filename: yup.string().required(),
        url: yup.string().required(),
      })
      .default(null)
      .nullable() as any,
  });

  const [validationError, , , saveWithValidate] = useYupValidate<CPCSUser>(
    editForm,
    formSchema as any,
    handleSaveValidated
  );

  const handleFormGridStateChange = (
    fieldName: keyof CPCSUser,
    value: unknown
  ) => {
    // console.log('ccccccccccccccc', fieldName, value);

    setEditForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSave = () => {
    saveWithValidate(undefined);
  };

  const handleImageUpload = (file: File) => {
    return uploadAvatar(file, appContext.serviceConfig);
  };

  const handleImageUploadDone = (file: PresignResult) => {
    setEditForm((prevState) => ({
      ...prevState,
      avatar: {
        assetId: file.assetId,
        url: file.url,
        filename: "avatar",
      },
    }));
  };

  const handleImageUploadClear = () => {
    setEditForm((prevState) => ({
      ...prevState,
      avatar: user.avatar,
    }));
  };

  return (
    <AppAccountSettingsGeneralLayout
      editForm={editForm}
      validationError={validationError}
      saveInProgress={saveInProgress}
      onFormGridStateChange={handleFormGridStateChange}
      onImageUpload={handleImageUpload}
      onImageUploadDone={handleImageUploadDone}
      onImageUploadClear={handleImageUploadClear}
      onSave={handleSave}
      onModalClose={onModalClose}
    />
  );
};

export default AppAccountSettingsGeneralContainer;
