import React, { useEffect, useState } from "react";

import { notify } from "@nexploretechnology/nxp-ui";
import { IDropdownOption } from "office-ui-fabric-react";

import useAppContext from "../../../app/hooks/useAppContext";
import { useAsync } from "../../../app/hooks/useAsync";
import {
  VocCustomUi,
  VocCustomUiLayoutItemType,
  VocEventValuationMethod,
  VocGetDictionary,
  VocVO,
} from "../../../app/types";
import {
  INSTRUCTED_BY_NONE,
  INSTRUCTED_BY_OTHER,
} from "../../../app/utils/const";
import { hasValue } from "../../../app/utils/has-value";
import { getEventVO, updateEventVO } from "../../compensation-event-services";
import VODetailsTabLayout from "./VODetailsTabLayout";

const getGroupFields = (groupKey: string, customUi: VocCustomUi): string[] => {
  const group = customUi.layout.find((grp) => grp.key === groupKey);
  if (group.type === VocCustomUiLayoutItemType.LayoutGroup) {
    return group.members
      .filter(
        (member) =>
          member.type !== VocCustomUiLayoutItemType.QuestionListCustomField
      )
      .map((member) => member.key);
  }
  return [];
};

interface VODetailsTabContainerProps {
  eventId: string;
  customUi: VocCustomUi;
  onEventRefresh: () => void;
}

const VODetailsTabContainer: React.FC<VODetailsTabContainerProps> = ({
  eventId,
  customUi,
  onEventRefresh,
}) => {
  const { libraries } = useAppContext();
  const [editingFieldGroups, setEditingFieldGroups] = useState<string[]>([]);
  const [formDropdownOptions, setFormDropdownOptions] = useState<{
    applicableClauses: IDropdownOption[];
    valuationClause: IDropdownOption[];
    valuationMethod: IDropdownOption[];
    instructionOwner: IDropdownOption[];
  }>({
    applicableClauses: [],
    valuationClause: [],
    valuationMethod: [],
    instructionOwner: [],
  });

  const [form, setForm] = React.useState<VocVO>();

  const { errorHandler, serviceConfig } = useAppContext();

  const refineVoData = (data: VocVO) => {
    return {
      ...data,
      applicableClauses: data.applicableClauses?.map((d) => ({
        label: "1",
        value: d.toString(),
      })),
      applicableValuation: data.applicableValuation?.map((d) => ({
        label: "2",
        value: d.toString(),
      })),
      instructionOwner: data.instructionOwner && {
        label: "3",
        value: data.instructionOwner.toString(),
      },
    };
  };

  const [getVOResult] = useAsync(() => getEventVO(eventId, serviceConfig), {
    onSuccess: (data) => {
      setForm(refineVoData(data));
    },
    onError: (ex) => errorHandler(ex, "getting event VO"),
  });

  useEffect(() => {
    setFormDropdownOptions((prevState) => ({
      ...prevState,
      applicableClauses: libraries["VOC_CLAUSE_INSTR"].map(
        (dict: VocGetDictionary) => ({
          text: dict.label,
          key: dict.value,
        })
      ),
    }));
    setFormDropdownOptions((prevState) => ({
      ...prevState,
      instructionOwner: libraries["VOC_VO_EMPOWER_REPS"].map(
        (dict: VocGetDictionary) => ({
          text: dict.label,
          key: dict.value,
        })
      ),
    }));

    setFormDropdownOptions((prevState) => ({
      ...prevState,
      valuationClause: libraries["VOC_CLAUSE_VALUATION"].map(
        (dict: VocGetDictionary) => ({
          text: dict.label,
          key: dict.value,
        })
      ),
    }));

    setFormDropdownOptions((prevState) => ({
      ...prevState,
      valuationMethod: Object.entries(VocEventValuationMethod).map((entry) => ({
        text: entry[0].replace(/_/g, " "),
        key: entry[1],
      })),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = (groupKey: string) => {
    const groupFields = getGroupFields(groupKey, customUi);
    const resetByGroup: any = {};
    groupFields.forEach((field, index) => {
      const val = (getVOResult.data as { [key: string]: any })[field];

      if (field === "instructionOwner") {
        resetByGroup["instructionOwner"] =
          val === undefined
            ? null
            : {
                label: index,
                value: val,
              };
      } else {
        resetByGroup[field] = val === undefined ? null : val;
      }
    });
    setForm((prevState) => ({
      ...prevState,
      ...resetByGroup,
      customization: getVOResult.data.customization,
    }));
    setEditingFieldGroups((prevState) =>
      prevState.filter((item) => item !== groupKey)
    );
  };

  const handleSave = async (groupKey: string) => {
    const groupFields = getGroupFields(groupKey, customUi);

    const saveByGroup: Record<string, any> = {};
    groupFields.forEach((field: keyof VocVO) => {
      const val = form[field];
      saveByGroup[field] = val === undefined ? null : val;
    });

    saveByGroup.applicableClauses = form.applicableClauses
      ? form.applicableClauses.map((dict) => dict.value)
      : [];
    saveByGroup.applicableValuation = form.applicableValuation
      ? form.applicableValuation.map((dict) => dict.value)
      : [];
    saveByGroup.valuationMethod = form.valuationMethod
      ? form.valuationMethod.map((dict) => dict)
      : [];
    saveByGroup.instructionOwner = form.instructionOwner?.value;
    saveByGroup.serial = undefined;

    const customFields = customUi.layout
      .find((l) => l.key === groupKey)
      .members.filter(
        (m) => m.type === VocCustomUiLayoutItemType.QuestionListCustomField
      );

    saveByGroup.customization = form.customization;

    Object.keys(saveByGroup.customization).forEach((key) => {
      if (!customFields.some((field) => field.key === key)) {
        saveByGroup.customization[key] = undefined;
      }
    });

    try {
      setForm(
        refineVoData(await updateEventVO(eventId, saveByGroup, serviceConfig))
      );
      notify.actionCompleted(); // 'voc.eventPage.VODetails.updateSuccess'
    } catch (ex) {
      errorHandler(ex, `vo update`);
    }

    setEditingFieldGroups((prevState) =>
      prevState.filter((item) => item !== groupKey)
    );

    if (groupKey === "voGeneral") {
      onEventRefresh();
    }
  };

  const handleEdit = (groupKey: string) => {
    setEditingFieldGroups((prevState) => [...prevState, groupKey]);
  };

  const handleFormChange = (changeValues: Partial<VocVO>) => {
    if (hasValue(changeValues.instructionOwner?.value)) {
      // set defaults for instructionEmpowered and instructionObligated
      changeValues.instructionEmpowered =
        changeValues.instructionOwner.value !== INSTRUCTED_BY_OTHER &&
        changeValues.instructionOwner.value !== INSTRUCTED_BY_NONE;
      changeValues.instructionObligated = changeValues.instructionEmpowered;
    }
    setForm((prevState) => ({ ...prevState, ...changeValues }));
  };

  return form && customUi ? (
    <VODetailsTabLayout
      editingFieldGroups={editingFieldGroups}
      voForm={form}
      applicableClausesOptions={formDropdownOptions.applicableClauses}
      valuationClauseOptions={formDropdownOptions.valuationClause}
      valuationMethodOptions={formDropdownOptions.valuationMethod}
      instructionOwnerOptions={formDropdownOptions.instructionOwner}
      customUi={customUi}
      formErrors={{}}
      onFormChange={handleFormChange}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
    />
  ) : null;
};

export default VODetailsTabContainer;
