import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataNode } from "antd/lib/tree";
import AccessRightPageLayout from "./AccessRightPageLayout";
import useAppContext from "../../../app/hooks/useAppContext";
import {
  ApplicationPermissionSetData,
  EntityRole,
  EntityRoleAccessRight,
  getAllAccessRight,
  getEntityRoles,
  getRoleAccessRight,
} from "../../access-right-services";
import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";

interface AccessRightPageContainerProps {}

const AccessRightPageContainer: React.FC<
  AccessRightPageContainerProps
> = () => {
  const appContext = useAppContext();
  const { t } = useTranslation();
  const { serviceConfig, errorHandler } = appContext;
  const [entityRoles, setEntityRoles] = useState<EntityRole[]>();
  const [selectedRole, setSelectedRole] = useState<EntityRole>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [userRights, setUserRights] = useState<EntityRoleAccessRight[]>([]);
  const [applicationPermissionSetData, setApplicationPermissionSetData] =
    useState<ApplicationPermissionSetData[]>([]);

  const fetch = useCallback(async () => {
    try {
      const results = await Promise.all([
        getEntityRoles(serviceConfig),
        getAllAccessRight(serviceConfig),
      ]);
      const entityRoles = results[0];
      setEntityRoles(entityRoles);
      const applicationPermissionSetData = results[1];
      setApplicationPermissionSetData(applicationPermissionSetData);
    } catch (err) {
      errorHandler(err, "fetch access right");
    }
  }, [serviceConfig, errorHandler]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const roleRights = useCallback(async () => {
    if (!entityRoles || !Array.isArray(entityRoles)) {
      return;
    }
    let allUserAccessRights = [];
    await Promise.all(
      entityRoles.map(async (role, idx) => {
        try {
          const userAccessRights = await getRoleAccessRight(
            serviceConfig,
            role.id
          );
          allUserAccessRights[idx] = {
            id: role.id,
            permissions: userAccessRights,
          };
        } catch (error) {
          errorHandler(error, `fetching access rights for role ${role.code}`);
        }
      })
    );
    setUserRights(allUserAccessRights);
  }, [entityRoles, errorHandler, serviceConfig]);

  useEffect(() => {
    roleRights();
  }, [roleRights]);

  useEffect(() => {
    if (refresh) {
      fetch();
      roleRights();
      setRefresh(false);
    }
  }, [fetch, refresh, roleRights]);

  useEffect(() => {
    if (selectedRole) {
      return;
    } else {
      const role = entityRoles ? entityRoles[0] : undefined;
      setSelectedRole(role);
    }
  }, [entityRoles, selectedRole]);

  const entityRolesTree: DataNode[] = useMemo(() => {
    const localRoles = entityRoles?.map((role) => {
      return {
        key: role.code,
        title: role.name,
        isLeaf: true,
      } as DataNode;
    });
    const inheritedRoles: DataNode[] = [];
    return [
      {
        key: "localRoles",
        title: t("AccessRight.LocalRoles"),
        isLeaf: false,
        selectable: false,
        children: localRoles,
      },
      {
        key: "inheritedRoles",
        title: t("AccessRight.InheritedRoles"),
        isLeaf: false,
        selectable: false,
        children: inheritedRoles,
      },
    ] as DataNode[];
  }, [entityRoles, t]);

  const handleRoleSelect = (key: string) => {
    if (!key) return;
    const selectedRole = entityRoles?.find((role) => role.code === key);
    setSelectedRole(selectedRole);
  };

  return (
    <AccessVisible
      objectCode="access"
      actionType="view"
      fallback={<i>{t("app.permission.accessDenied")}</i>}
    >
      <AccessRightPageLayout
        selectedRole={selectedRole}
        entityRolesTree={entityRolesTree}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        userRights={userRights}
        applicationPermissionSetData={applicationPermissionSetData}
        onRoleSelect={handleRoleSelect}
        setRefresh={setRefresh}
      />
    </AccessVisible>
  );
};

export default AccessRightPageContainer;
