import React from "react";

import AppFormField, { AppFormFieldProps } from "./AppFormField";

import "./AppFormReadOnlyField.scss";

type AppFormReadOnlyFieldProps = {
  label?: string;
  children?: React.ReactNode;
} & AppFormFieldProps;

const AppFormReadOnlyField: React.FC<AppFormReadOnlyFieldProps> = ({
  label,
  children,
  ...restProps
}) => {
  return (
    <AppFormField {...restProps}>
      <div className="app-form-read-only-field">
        <label>{label}</label>
        {children}
      </div>
    </AppFormField>
  );
};

export default AppFormReadOnlyField;
