export function convertToJsonBody(
  requestBody: any,
  timezoneHourAdjustment: number
) {
  if (timezoneHourAdjustment) {
    Object.keys(requestBody).forEach((key) => {
      if (
        Object.prototype.toString.call(requestBody[key]) === "[object Date]"
      ) {
        requestBody[key] = new Date(
          requestBody[key].getTime() +
            Math.round(-timezoneHourAdjustment * 3600000)
        );
      }
    });
  }

  return JSON.stringify(requestBody);
}
