import React from "react";

import { Icon } from "office-ui-fabric-react";

import { VocEventFile } from "../../types";
import AppFileUpload from "../app-file-upload/AppFileUpload";
import { AppFormField } from "./";
import { AppFormFieldProps } from "./AppFormField";

import "./AppFormSingleFileField.scss";

export type AppFormSingleFieldProps = {
  file: VocEventFile;
  label?: string;
  onUploaded: (file: VocEventFile) => void;
  onRemove: () => void;
  errorMessage?: string | string[];
} & AppFormFieldProps;

const AppFormSingleFileField: React.FC<AppFormSingleFieldProps> = ({
  file,
  label = "File",
  onUploaded,
  onRemove,
  errorMessage,
}) => {
  return (
    <AppFormField className="voc-form-file-field">
      {file ? (
        <>
          <label className="file-info-label">{label}</label>
          <p className="file-info">
            <a href={file.url} target="_blank" rel="noreferrer noopener">
              <Icon iconName="PageSolid" />
              {file.fileName}
            </a>
            <button className="remove" onClick={onRemove}>
              X
            </button>
          </p>
        </>
      ) : (
        <AppFileUpload
          label={label}
          onChange={(files: any) => {
            if (files.length) {
              const uploaded: VocEventFile = {
                url: (files[0] as any).url,
                fileName: files[0].fileName,
                assetId: files[0].assetId,
                mimeType: files[0].mimeType,
              };
              onUploaded(uploaded);
            }
          }}
        />
      )}
      {errorMessage && (
        <div role="alert" className="error">
          <p>
            {Array.isArray(errorMessage)
              ? errorMessage.join(" / ")
              : errorMessage}
          </p>
        </div>
      )}
    </AppFormField>
  );
};

export default AppFormSingleFileField;
