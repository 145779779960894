import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import {
  AddRole,
  editRole,
  ManageRolesTableItem,
} from "../../../manage-role-services";
import { useTranslation } from "react-i18next";
import useAppContext from "../../../../app/hooks/useAppContext";
import * as yup from "yup";
import {
  notify,
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";

interface EditRoleModalProps {
  row: ManageRolesTableItem;
  onClose: () => void;
  onSetRows: Dispatch<SetStateAction<ManageRolesTableItem[]>>;
  onSetInitRows: Dispatch<SetStateAction<ManageRolesTableItem[]>>;
}

const EditRoleModal: React.FC<EditRoleModalProps> = ({
  row,
  onClose,
  onSetRows,
  onSetInitRows,
}) => {
  const { t } = useTranslation();

  const { errorHandler, serviceConfig } = useAppContext();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [editForm, setEditForm] = useState<AddRole>({
    name: row.name,
    code: row.roleCode,
    description: row.description,
  });

  const formSchema = yup.object().shape({
    name: yup.string().required(t("voc.ManageRole.RoleNameIsRequired")),
    code: yup.string().required(t("voc.ManageRole.RoleCodeIsRequired")),
    description: yup.string(),
  });

  const formItems: NxpFormGridItemProps<AddRole>[] = [
    {
      controlType: "input",
      label: t("voc.ManageRole.RoleName"),
      itemFieldName: "name",
      span: 12,
      required: true,
    },
    {
      controlType: "input",
      label: t("voc.ManageRole.RoleCode"),
      itemFieldName: "code",
      span: 12,
      required: true,
    },
    {
      controlType: "textarea",
      label: t("voc.ManageRole.description"),
      itemFieldName: "description",
      span: 24,
    },
  ];

  const handleSaveValidated = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await editRole(serviceConfig, row.id, editForm);

      const updateRow = (prevRows: ManageRolesTableItem[]) => {
        return prevRows.map((prevRow) =>
          prevRow.id === row.id
            ? {
                ...prevRow,
                name: editForm.name,
                roleCode: editForm.code,
                description: editForm.description,
              }
            : prevRow
        );
      };

      onSetRows(updateRow);
      onSetInitRows(updateRow);

      notify.actionCompleted();
    } catch (err) {
      errorHandler(err as Error, "create role");
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  }, [
    serviceConfig,
    row.id,
    editForm,
    onSetRows,
    onSetInitRows,
    errorHandler,
    onClose,
  ]);
  const [validationError, , , saveWithValidate] = useYupValidate<AddRole>(
    editForm,
    formSchema,
    handleSaveValidated
  );

  const handleFormGridStateChange = (
    fieldName: keyof AddRole,
    value: unknown
  ) => {
    setEditForm((prevState) => {
      const newState = {
        ...prevState,
        [fieldName]: value,
      };
      return newState;
    });
  };

  const handleModalSave = () => {
    saveWithValidate(undefined);
  };

  return (
    <NxpModal
      title={t("voc.AccessRight.EditRole")}
      open={true}
      onCancel={onClose}
      footer={
        <>
          <NxpButton loading={isSubmitting} type="text" onClick={onClose}>
            {t("voc.common.cancel")}
          </NxpButton>
          <NxpButton loading={isSubmitting} onClick={handleModalSave}>
            {t("voc.common.save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        formItems={formItems}
        formState={editForm}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default EditRoleModal;
