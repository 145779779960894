import {
  notify,
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { AddRole, createRole } from "../../../manage-role-services";
import useAppContext from "../../../../app/hooks/useAppContext";

interface AddRoleModalProps {
  onClose: () => void;
  onRefetch: () => Promise<void>;
}

const initEditForm = {
  name: "",
  code: "",
  description: undefined,
};

const AddRoleModal: React.FC<AddRoleModalProps> = ({ onClose, onRefetch }) => {
  const { t } = useTranslation();

  const { errorHandler, serviceConfig } = useAppContext();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [editForm, setEditForm] = useState<AddRole>(initEditForm);

  const formSchema = yup.object().shape({
    name: yup.string().required(t("voc.ManageRole.RoleNameIsRequired")),
    code: yup.string().required(t("voc.ManageRole.RoleCodeIsRequired")),
    description: yup.string(),
  });

  const formItems: NxpFormGridItemProps<AddRole>[] = [
    {
      controlType: "input",
      label: t("voc.ManageRole.RoleName"),
      itemFieldName: "name",
      span: 12,
      required: true,
    },
    {
      controlType: "input",
      label: t("voc.ManageRole.RoleCode"),
      itemFieldName: "code",
      span: 12,
      required: true,
    },
    {
      controlType: "textarea",
      label: t("voc.ManageRole.description"),
      itemFieldName: "description",
      span: 24,
    },
  ];

  const handleSaveValidated = useCallback(async () => {
    try {
      setIsSubmitting(true);

      await createRole(serviceConfig, editForm);
      notify.actionCompleted();
    } catch (err) {
      errorHandler(err as Error, "create role");
    } finally {
      setIsSubmitting(false);
      onRefetch();
      onClose();
    }
  }, [editForm, serviceConfig, errorHandler, onRefetch, onClose]);

  const [validationError, , , saveWithValidate] = useYupValidate<AddRole>(
    editForm,
    formSchema,
    handleSaveValidated
  );

  const handleFormGridStateChange = (
    fieldName: keyof AddRole,
    value: unknown
  ) => {
    setEditForm((prevState) => {
      const newState = {
        ...prevState,
        [fieldName]: value,
      };
      return newState;
    });
  };

  const handleModalSave = () => {
    saveWithValidate(undefined);
  };

  return (
    <NxpModal
      title={t("voc.AccessRight.AddRole")}
      open={true}
      onCancel={onClose}
      footer={
        <>
          <NxpButton loading={isSubmitting} type="text" onClick={onClose}>
            {t("voc.common.cancel")}
          </NxpButton>
          <NxpButton loading={isSubmitting} onClick={handleModalSave}>
            {t("voc.common.save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        formItems={formItems}
        formState={editForm}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddRoleModal;
