import * as React from "react";

import cn from "classnames";
import isEqual from "lodash/isEqual";
import {
  Dialog as FabricDialog,
  DialogFooter as FabricDialogFooter,
  IDialogProps,
  Overlay,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import * as uuid from "uuid";

import { BackButton } from "./BackButton";

import "./Modal.scss";

export interface ModalProps {
  isOpen: boolean;
  loading?: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  renderFooter?: (
    props?: Pick<ModalProps, "onClose" | "onBack" | "onNext">
  ) => React.ReactNode;
  renderAction?: (
    props?: Pick<ModalProps, "onClose" | "onBack" | "onNext">
  ) => React.ReactNode;
  backLabel?: string;
  onClose?: () => void;
  onBack?: () => void;
  onNext?: () => void;
  children?: React.ReactNode;
  variant?: "inspection";
  themeColor?: string;
  className?: string;
  fillPage?: boolean;
  initialValue?: Object;
  currentValue?: Object;
}

const Dialog = FabricDialog as React.FC<
  IDialogProps & { children: React.ReactNode }
>;

const DialogFooter = FabricDialogFooter as React.FC<
  React.ComponentProps<typeof FabricDialogFooter> & {
    children: React.ReactNode;
  }
>;

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  loading,
  title,
  subTitle,
  backLabel,
  onBack,
  onClose,
  onNext,
  renderFooter,
  renderAction,
  children,
  variant,
  themeColor,
  className,
  fillPage,
  initialValue,
  currentValue,
}) => {
  const [uniqueModalClassName] = React.useState(() => {
    return `modal-${uuid.v4()}`;
  });

  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  const modalProps = React.useMemo(() => {
    const props: IDialogProps["modalProps"] = {
      isDarkOverlay: true,
    };

    if (fillPage) {
      Object.assign(props, {
        containerClassName: "fillpage-container",
        overlay: {
          className: "fillpage-overlay",
        },
      });
    }

    return props;
  }, [fillPage]);

  React.useEffect(() => {
    setIsDirty(isOpen && !isEqual(initialValue, currentValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, currentValue]);

  const handleDismiss = React.useCallback(() => {
    // TODO: Remove global guard once all the usage is removed
    if (
      //cpcs.guard.form.isDirty() || isDirty
      isDirty
    ) {
      const confirmToClose = window.confirm(
        // commonMessageById('modal.guard.unsavedForm.confirmMessage')
        "modal.guard.unsavedForm.confirmMessage"
      );
      if (!confirmToClose) {
        return;
      }
    }
    if (onClose) {
      onClose();
    }
  }, [onClose, isDirty]);

  return (
    <Dialog
      minWidth="500"
      hidden={!isOpen}
      onDismiss={handleDismiss}
      modalProps={modalProps}
      className={cn(
        `modal ${variant && `modal-${variant}`}`,
        uniqueModalClassName,
        className,
        fillPage && "fillpage-modal"
      )}
    >
      {themeColor && (
        <style>{`.${uniqueModalClassName} { --dialog-theme-color: ${themeColor} }`}</style>
      )}
      {title && (
        <div className={cn("modal-title", subTitle && "with-subtitle")}>
          {title}
        </div>
      )}
      {subTitle && <div className="modal-subtitle">{subTitle}</div>}
      <div className="modal-context">{children}</div>
      <DialogFooter>
        {typeof renderFooter === "function" ? (
          renderFooter({ onClose, onBack, onNext })
        ) : (
          <div className="modal-default-footer">
            {onBack ? (
              <BackButton onClick={onBack} backLabel={backLabel} />
            ) : (
              <span></span>
            )}
            <span className="modal__button-group">
              {renderAction && renderAction({ onClose, onBack, onNext })}
            </span>
          </div>
        )}
      </DialogFooter>
      {loading && (
        <Overlay className="modal-overlay">
          <Spinner
            size={SpinnerSize.medium}
            styles={
              themeColor
                ? {
                    circle: {
                      borderTopColor: themeColor,
                      border: "1.5px solid " + themeColor + "88", // 50% opacity
                    },
                  }
                : undefined
            }
          />
        </Overlay>
      )}
    </Dialog>
  );
};

export function useModal(defaultOpen: boolean = false) {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  const open = React.useCallback(() => setIsOpen(true), []);
  const close = React.useCallback(() => setIsOpen(false), []);

  return { modalProps: { isOpen, onClose: close }, open, close, isOpen };
}
