import i18next from "i18next";
import i18n from "../../i18n/i18n";
import { UserLanguagePreference } from "../../utils/localisation/locale-enum";
import { Option } from "../app-field-block/AppFieldBlock";
import { DataListItem, SortDirection, SortedColumn } from "./types";

// ===============================================================
//                     Translate
// ===============================================================

export const defaultTranslateLimit = (translates) => {
  return translates === 8
    ? " right-eight"
    : translates === 7
    ? " right-seven"
    : translates === 6
    ? " right-six"
    : translates === 5
    ? " right-five"
    : translates === 4
    ? " right-four"
    : translates === 3
    ? " right-three"
    : translates === 2
    ? " right-two"
    : " right-one";
};

export const translateRight = (translates, translate, setTranslate) => {
  if (translate === "" && translates) {
    setTranslate(" right-one");
  }
  if (translate === " right-one" && translates > 1) {
    setTranslate(" right-two");
  }
  if (translate === " right-two" && translates > 2) {
    setTranslate(" right-three");
  }
  if (translate === " right-three" && translates > 3) {
    setTranslate(" right-four");
  }
  if (translate === " right-four" && translates > 4) {
    setTranslate(" right-five");
  }
  if (translate === " right-five" && translates > 5) {
    setTranslate(" right-six");
  }
  if (translate === " right-six" && translates > 6) {
    setTranslate(" right-seven");
  }
  if (translate === " right-seven" && translates > 7) {
    setTranslate(" right-eight");
  }
};

export const translateLeft = (translates, translate, setTranslate) => {
  if (translate === " right-eight" && translates > 7) {
    setTranslate(" right-seven");
  }
  if (translate === " right-seven" && translates > 6) {
    setTranslate(" right-six");
  }
  if (translate === " right-six" && translates > 5) {
    setTranslate(" right-five");
  }
  if (translate === " right-five" && translates > 4) {
    setTranslate(" right-four");
  }
  if (translate === " right-four" && translates > 3) {
    setTranslate(" right-three");
  }
  if (translate === " right-three" && translates > 2) {
    setTranslate(" right-two");
  }
  if (translate === " right-two" && translates > 1) {
    setTranslate(" right-one");
  }
  if (translate === " right-one" && translates) {
    setTranslate("");
  }
};

// ===============================================================
//                             Calculations
// ===============================================================

export const formatForEnums = (value: any, columnOptions: Option[]) => {
  const enumEntry = columnOptions.filter(
    (option) => option.enumValue === value
  );
  if (enumEntry.length > 0) {
    return enumEntry[0] ? enumEntry[0].render : undefined;
  } else {
    return value;
  }
};

export const calculateOHP = (items: DataListItem[]) => {
  //sum profits
  const pnl = items.reduce((accumulator, item) => {
    return accumulator + Number(item.fieldDataArray[11].fieldData);
  }, 0);
  //sum G&A
  const gna = items.reduce((accumulator, item) => {
    return accumulator + Number(item.fieldDataArray[8].fieldData);
  }, 0);
  //sum total cost
  const totalCost = items.reduce((accumulator, item) => {
    return accumulator + Number(item.fieldDataArray[9].fieldData);
  }, 0);

  return (pnl + gna) / (totalCost - gna);
};

// ===============================================================
//                     Sortings
// ===============================================================

export const defaultSortedColumn = (
  isDisableSort?: boolean,
  defaultSortColumn?: number,
  defaultSortDirection?: SortDirection
): SortedColumn => {
  return {
    columnIndex: defaultSortColumn ? defaultSortColumn : 0,
    sortDirection:
      isDisableSort || defaultSortDirection === "" ? "" : "asc" ?? "desc",
  };
};

export const handleSortHelper = (items, columns, sortedColumn) => {
  return [...items].sort((a, b) => {
    let aValue;
    let bValue;
    let isString;
    if (
      columns[sortedColumn.columnIndex].columnDataType === "string" &&
      !columns[sortedColumn.columnIndex].stringConversionSymbol &&
      !columns[sortedColumn.columnIndex].stringConversionDecimals &&
      !columns[sortedColumn.columnIndex].sortNumerically
    ) {
      isString = true;
      aValue = a.fieldDataArray[sortedColumn.columnIndex].fieldData
        ? a.fieldDataArray[sortedColumn.columnIndex].fieldData
        : "";
      bValue = b.fieldDataArray[sortedColumn.columnIndex].fieldData
        ? b.fieldDataArray[sortedColumn.columnIndex].fieldData
        : "";
    }
    if (
      columns[sortedColumn.columnIndex].stringConversionSymbol ||
      columns[sortedColumn.columnIndex].sortNumerically
    ) {
      isString = false;
      aValue = Number(a.fieldDataArray[sortedColumn.columnIndex].fieldData);
      bValue = Number(b.fieldDataArray[sortedColumn.columnIndex].fieldData);
    }
    if (columns[sortedColumn.columnIndex].columnDataType === "date") {
      isString = false;
      aValue = a.fieldDataArray[sortedColumn.columnIndex].fieldData;
      bValue = b.fieldDataArray[sortedColumn.columnIndex].fieldData;
    }
    if (columns[sortedColumn.columnIndex].columnDataType === "basicdropdown") {
      isString = true;
      aValue = a.fieldDataArray[sortedColumn.columnIndex].fieldData.value
        ? formatForEnums(
            a.fieldDataArray[sortedColumn.columnIndex].fieldData.value,
            columns[sortedColumn.columnIndex].columnOptions
          )
        : "";
      bValue = b.fieldDataArray[sortedColumn.columnIndex].fieldData.value
        ? formatForEnums(
            b.fieldDataArray[sortedColumn.columnIndex].fieldData.value,
            columns[sortedColumn.columnIndex].columnOptions
          )
        : "";
    }
    if (columns[sortedColumn.columnIndex].columnDataType === "file") {
      isString = true;
      aValue = a.fieldDataArray[sortedColumn.columnIndex].fieldData[0]
        ? a.fieldDataArray[sortedColumn.columnIndex].fieldData[0].fileName
        : "";
      bValue = b.fieldDataArray[sortedColumn.columnIndex].fieldData[0]
        ? b.fieldDataArray[sortedColumn.columnIndex].fieldData[0].fileName
        : "";
    }
    if (!isString) {
      if (sortedColumn.sortDirection === "asc") {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      } else {
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      }
    } else {
      if (sortedColumn.sortDirection === "asc") {
        return aValue && bValue ? aValue.localeCompare(bValue) : 0;
      } else {
        return bValue && aValue ? bValue.localeCompare(aValue) : 0;
      }
    }
  });
};

// ===============================================================
//                       Formatting
// ===============================================================

const formatMonth = (month: number) => {
  const months = [
    i18next.t("voc.months.jan"),
    i18next.t("voc.months.feb"),
    i18next.t("voc.months.mar"),
    i18next.t("voc.months.apr"),
    i18next.t("voc.months.may"),
    i18next.t("voc.months.jun"),
    i18next.t("voc.months.jul"),
    i18next.t("voc.months.aug"),
    i18next.t("voc.months.sep"),
    i18next.t("voc.months.oct"),
    i18next.t("voc.months.nov"),
    i18next.t("voc.months.dec"),
  ];
  return months[month];
};

// Updated VC-300: need to cater for MMM output
//    adding optional argument to check if need to output as text
export const formatDate = (date: Date, displayMonthText: boolean = false) => {
  if (!date) {
    return "";
  }
  const d = new Date(date);

  // VC-300: either display the string or display the padded numeric value
  const m = d.getMonth();
  const month =
    displayMonthText === true
      ? formatMonth(m)
      : (m + 1).toString().length < 2
      ? (m + 1).toString().padStart(2, "0")
      : (m + 1).toString();
  // let month = '' + (d.getMonth() + 1);

  let day = "" + d.getDate();
  const year = d.getFullYear();

  // if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = "0" + day;

  // VC-300: Show the preferred date format.
  // Determine the format order based on the locale from i18n.language
  const formatOrder =
    i18n.language === UserLanguagePreference.US
      ? [month, day, year]
      : [day, month, year];

  // Join the date components with a hyphen to form the final date string
  const result = formatOrder.join("-");

  return result;

  // return [year, month, day].join('-');
};

// ===============================================================
//                         Field Change
// ===============================================================

export const handleFieldChangeHelper = (
  itemIndex: number,
  fieldIndex: number,
  newValue: any,
  newItems,
  isVarianceLibraryFlag,
  validateNumbersOnly,
  valuesSubrecordsCalculations,
  t
) => {
  let stopCalculations = false;
  let allowInput = false;

  if (validateNumbersOnly) {
    if (
      isNaN(newValue) &&
      fieldIndex !== 0 &&
      fieldIndex !== 1 &&
      fieldIndex !== 2
    ) {
      stopCalculations = true;
      newItems[itemIndex].validationError = t(
        "voc.appDataListComponent.numberValidation"
      );
    }
    if (
      !isNaN(newValue) &&
      fieldIndex !== 0 &&
      fieldIndex !== 1 &&
      fieldIndex !== 2
    ) {
      stopCalculations = false;
      newItems[itemIndex].validationError = null;
      allowInput = true;
    }
    if (fieldIndex === 0 || fieldIndex === 1 || fieldIndex === 2) {
      allowInput = true;
    }
  } else {
    allowInput = true;
  }
  if (allowInput) {
    newItems[itemIndex].fieldDataArray[fieldIndex].fieldData = newValue;
    newItems[itemIndex].edited = true;
  }

  //VSC - if need to recalculate total cost...
  if (
    valuesSubrecordsCalculations &&
    [3, 4, 5, 6, 7, 8].includes(fieldIndex) &&
    !stopCalculations
  ) {
    //...recalculate total cost
    newItems[itemIndex].fieldDataArray[9].fieldData = Number(
      Number(newItems[itemIndex].fieldDataArray[3].fieldData) +
        Number(newItems[itemIndex].fieldDataArray[4].fieldData) +
        Number(newItems[itemIndex].fieldDataArray[5].fieldData) +
        Number(newItems[itemIndex].fieldDataArray[6].fieldData) +
        Number(newItems[itemIndex].fieldDataArray[7].fieldData) +
        Number(newItems[itemIndex].fieldDataArray[8].fieldData)
    );
  }

  //VSC - if need to recalculate total cost [1 thru 6] OR revenue...
  if (
    valuesSubrecordsCalculations &&
    [3, 4, 5, 6, 7, 8, 10].includes(fieldIndex) &&
    !stopCalculations
  ) {
    //recalculate total cost / revenue dependent - profit and loss
    newItems[itemIndex].fieldDataArray[11].fieldData = Number(
      Number(newItems[itemIndex].fieldDataArray[10].fieldData) -
        Number(newItems[itemIndex].fieldDataArray[9].fieldData)
    );
    //recalculate total cost / revenue dependent - OH&P
    newItems[itemIndex].fieldDataArray[12].fieldData =
      newItems[itemIndex].fieldDataArray[3].fieldData ||
      newItems[itemIndex].fieldDataArray[4].fieldData ||
      newItems[itemIndex].fieldDataArray[5].fieldData ||
      newItems[itemIndex].fieldDataArray[6].fieldData
        ? Number(newItems[itemIndex].fieldDataArray[11].fieldData) +
          Number(newItems[itemIndex].fieldDataArray[8].fieldData) /
            Number(newItems[itemIndex].fieldDataArray[9].fieldData) -
          Number(newItems[itemIndex].fieldDataArray[8].fieldData)
        : null;
  }

  //variance library specific:
  if (isVarianceLibraryFlag && fieldIndex === 0) {
    if (newValue === "less than") {
      newItems[itemIndex].fieldDataArray[1].readonly = true;
      newItems[itemIndex].fieldDataArray[2].readonly = false;
      newItems[itemIndex].fieldDataArray[1].fieldData = null;
    } else if (newValue === "more than") {
      newItems[itemIndex].fieldDataArray[1].readonly = false;
      newItems[itemIndex].fieldDataArray[2].readonly = true;
      newItems[itemIndex].fieldDataArray[2].fieldData = null;
    } else if (newValue === "range") {
      newItems[itemIndex].fieldDataArray[1].readonly = false;
      newItems[itemIndex].fieldDataArray[2].readonly = false;
    }
  }
};
