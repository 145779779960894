import React, { ReactNode } from "react";

import "./AppCollapsible.scss";
import { useTranslation } from "react-i18next";
import AccessVisible from "../app-access-visible/AppAccessVisible";
import { Right } from "../../services/app";
import { NxpButton, NxpSpin } from "@nexploretechnology/nxp-ui";

interface Field {
  property: string;
  value: ReactNode;
  fieldtype: string;
  className?: string;
}

interface AppCollapsibleProps {
  hasFields: boolean;
  fields?: Field[];
  headerChildren: JSX.Element;
  bodyChildren: JSX.Element;
  defaultCollapsed?: boolean;
  editCallback?: Function;
  editingState?: boolean;
  hideEditButton?: boolean;
  saveCallback?: Function;
  cancelCallback?: Function;
  addCallback?: Function;
  firstDecollapseCallback?: Function;
  className?: string;
  isLoading?: boolean;
  isLoadOnEveryClick?: boolean;
  permissionCode?: Right["objectCode"];
}

const AppCollapsible: React.FC<AppCollapsibleProps> = (props) => {
  const {
    hasFields,
    fields,
    headerChildren,
    bodyChildren,
    defaultCollapsed = true,
    editCallback,
    editingState,
    hideEditButton,
    saveCallback,
    cancelCallback,
    addCallback,
    firstDecollapseCallback,
    className,
    isLoading,
    isLoadOnEveryClick,
    permissionCode,
  } = props;
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = React.useState<boolean>(defaultCollapsed);

  const [firstDecollapse, setFirstDecollapse] = React.useState<boolean>(false);

  const ButtonWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  return (
    <div
      className={
        "collapsible" +
        (collapsed ? " collapsed" : "") +
        (className ? ` ${className}` : "")
      }
    >
      <div
        className={
          "collapsible-header" + (editingState ? " block-pointer" : "")
        }
        onClick={() => {
          if (editingState) {
            return;
          }
          if (!firstDecollapseCallback) {
            setCollapsed(!collapsed);
          } else {
            if (collapsed && (isLoadOnEveryClick || !firstDecollapse)) {
              setCollapsed(false);
              setFirstDecollapse(true);
              firstDecollapseCallback();
            } else {
              setCollapsed(!collapsed);
            }
          }
        }}
      >
        {headerChildren}
        {editCallback && !collapsed && !editingState && !hideEditButton ? (
          <ButtonWrapper
            condition={Boolean(permissionCode)}
            wrapper={(children) => (
              <AccessVisible
                objectCode={permissionCode}
                actionType="edit"
                fallback={<></>}
              >
                {children}
              </AccessVisible>
            )}
            children={
              <div className="editing-controls">
                {isLoading && (
                  <NxpSpin
                    spinning={isLoading}
                    style={{
                      paddingRight: 10,
                      paddingTop: 3,
                    }}
                  ></NxpSpin>
                )}
                <NxpButton
                  onClick={(e) => {
                    e.stopPropagation();
                    editCallback();
                  }}
                  disabled={isLoading}
                >
                  {t("voc.common.edit").toLocaleUpperCase()}
                </NxpButton>
              </div>
            }
          ></ButtonWrapper>
        ) : null}

        {saveCallback && !collapsed && editingState ? (
          <div className="editing-controls">
            {isLoading && (
              <NxpSpin
                spinning={isLoading}
                style={{
                  paddingRight: 10,
                  paddingTop: 3,
                }}
              ></NxpSpin>
            )}
            <div className="collapse-lock reminder">
              {t("voc.appCollapsibleComponent.collapseLocked")}
            </div>
            {cancelCallback ? (
              <NxpButton
                onClick={(e) => {
                  e.stopPropagation();
                  cancelCallback();
                }}
                disabled={isLoading}
              >
                {t("voc.common.cancel").toLocaleUpperCase()}
              </NxpButton>
            ) : null}
            <NxpButton
              onClick={(e) => {
                e.stopPropagation();
                saveCallback();
              }}
              disabled={isLoading}
            >
              {t("voc.common.save").toLocaleUpperCase()}
            </NxpButton>
            {addCallback ? (
              <ButtonWrapper
                condition={Boolean(permissionCode)}
                wrapper={(children) => (
                  <AccessVisible
                    objectCode={permissionCode}
                    actionType="create"
                    fallback={<></>}
                  >
                    {children}
                  </AccessVisible>
                )}
                children={
                  <NxpButton
                    onClick={(e) => {
                      e.stopPropagation();
                      addCallback();
                    }}
                    disabled={isLoading}
                  >
                    {t("voc.common.add").toLocaleUpperCase()}
                  </NxpButton>
                }
              ></ButtonWrapper>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="collapsible-body">
        {!hasFields
          ? bodyChildren
          : fields.map((field, i) => (
              <div
                key={i}
                className={`field-wrapper${
                  field.className ? ` ${field.className}` : ""
                }`}
              >
                <div className={"field" + (i % 2 !== 0 ? " right" : "")}>
                  <div className="property">{field.property}</div>
                  <div className="value">{field.value}</div>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default AppCollapsible;
