import "./AppFilterFieldRow.scss";

import { DateTime } from "luxon";
import {
  BaseButton,
  DatePicker,
  Dropdown,
  IDropdownOption,
  TextField,
} from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";

import { FORMAT_SHORT_DATE_LUXON } from "../../utils/const";
import { mapDateToString } from "../../utils/mappers";
import { isNumericString } from "../../utils/string";
import { ColumnDataType } from "../app-data-table";
import { AppFormField } from "../app-form";
import AppNumericInput from "../app-numeric-input";
import { FilterOption, FilterType } from "./AppFilter";
import { getSettingsOptions } from "./get-settings-options.helper";

interface AppFilterFieldRowProps {
  isFormValid: boolean;
  formFilterOption: FilterOption;
  rowIndex: number;
  showClearButton: boolean;
  fieldDropdownOptions: IDropdownOption[];
  onFormFilterChange: (
    originalFormFilterOption: FilterOption,
    updatedFormFilterOption: FilterOption
  ) => void;
}

const AppFilterFieldRow: React.FC<AppFilterFieldRowProps> = ({
  isFormValid,
  formFilterOption,
  rowIndex,
  fieldDropdownOptions,
  showClearButton,
  onFormFilterChange,
}) => {
  const { t } = useTranslation();

  const emptyOption: IDropdownOption = {
    key: "",
    text: t("voc.filter.pleaseSelect"),
  };
  const getValueInput = (
    formFilterOption: FilterOption,
    showLabel: boolean = true,
    className?: string
  ) => {
    const label = showLabel ? t("voc.filter.filterValue") : undefined;
    switch (formFilterOption?.columnDataType) {
      case ColumnDataType.Select:
        if (formFilterOption.columnDropdownOptions) {
          return (
            <Dropdown
              className={className}
              label={label}
              options={[emptyOption, ...formFilterOption.columnDropdownOptions]}
              selectedKey={formFilterOption.filterValue || ""}
              onChange={(_, option: IDropdownOption) => {
                const updatedFormFilterOption = { ...formFilterOption };
                updatedFormFilterOption.filterValue = option.key.toString();
                onFormFilterChange(formFilterOption, updatedFormFilterOption);
              }}
            />
          );
        } else {
          return (
            <TextField
              className={className}
              value={formFilterOption.filterValue}
              label={label}
              name="value"
              onChange={(e, value) => {
                const updatedFormFilterOption = { ...formFilterOption };
                updatedFormFilterOption.filterValue = value;
                onFormFilterChange(formFilterOption, updatedFormFilterOption);
              }}
            />
          );
        }
      case ColumnDataType.Boolean:
        return (
          <Dropdown
            className={className}
            label={label}
            options={[
              emptyOption,
              { key: "true", text: "Yes" },
              { key: "false", text: "No" },
            ]}
            disabled={formFilterOption.filterType === FilterType.IsEmpty}
            selectedKey={formFilterOption.filterValue || ""}
            onChange={(_, option: IDropdownOption) => {
              const updatedFormFilterOption = { ...formFilterOption };
              updatedFormFilterOption.filterValue = option.key.toString();
              onFormFilterChange(formFilterOption, updatedFormFilterOption);
            }}
          />
        );
      case ColumnDataType.Date:
        return (
          <DatePicker
            className={className}
            label={label}
            key="expectedCompletionDate"
            value={
              formFilterOption.filterValue
                ? DateTime.fromFormat(
                    formFilterOption.filterValue,
                    FORMAT_SHORT_DATE_LUXON
                  ).toJSDate()
                : undefined
            }
            onSelectDate={(date) => {
              const updatedFormFilterOption = { ...formFilterOption };
              updatedFormFilterOption.filterValue = date
                ? mapDateToString(date)
                : "";
              onFormFilterChange(formFilterOption, updatedFormFilterOption);
            }}
          />
        );
      case ColumnDataType.Number:
      case ColumnDataType.Money:
        return (
          <TextField
            className={className}
            value={
              isNumericString(formFilterOption.filterValue)
                ? formFilterOption.filterValue
                : ""
            }
            label={label}
            name="value"
            onChange={(_, value) => {
              if (isNumericString(value)) {
                const updatedFormFilterOption = { ...formFilterOption };
                updatedFormFilterOption.filterValue = value;
                onFormFilterChange(formFilterOption, updatedFormFilterOption);
              }
            }}
          />
        );
      case ColumnDataType.Percent:
        return (
          <AppFormField
            className={
              className ? `${className} percent-field` : "percent-field"
            }
          >
            <label>{label}</label>
            <AppNumericInput
              inputType="percent"
              value={Number(formFilterOption.filterValue)}
              onValueChange={(value) => {
                const updatedFormFilterOption = { ...formFilterOption };
                updatedFormFilterOption.filterValue = String(value);
                onFormFilterChange(formFilterOption, updatedFormFilterOption);
              }}
            />
          </AppFormField>
        );
      default:
        //includes case ColumnDataType.String and other (if any)
        return (
          <TextField
            className={className}
            value={formFilterOption.filterValue}
            label={label}
            name="value"
            onChange={(_, value) => {
              const updatedFormFilterOption = { ...formFilterOption };
              updatedFormFilterOption.filterValue = value;
              onFormFilterChange(formFilterOption, updatedFormFilterOption);
            }}
          />
        );
    }
  };

  return (
    <div className="app-filter-field-row">
      <div className="field">
        <Dropdown
          className={
            !isFormValid && !formFilterOption.fieldName
              ? "error-input"
              : undefined
          }
          label={rowIndex === 0 ? t("voc.filter.filterField") : undefined}
          options={[emptyOption, ...fieldDropdownOptions]}
          selectedKey={formFilterOption.fieldName || ""}
          onChange={(_, option: IDropdownOption) => {
            const updatedFormFilterOption = { ...formFilterOption };
            updatedFormFilterOption.fieldName = option.key.toString();
            onFormFilterChange(formFilterOption, updatedFormFilterOption);
          }}
        />
      </div>
      <div className="field">
        <Dropdown
          className={
            !isFormValid && !formFilterOption.filterType
              ? "error-input"
              : undefined
          }
          label={rowIndex === 0 ? t("voc.common.filterSettings") : undefined}
          options={getSettingsOptions(
            formFilterOption.columnDataType,
            emptyOption,
            t
          )}
          selectedKey={formFilterOption.filterType || ""}
          onChange={(_, option: IDropdownOption) => {
            const updatedFormFilterOption = { ...formFilterOption };
            updatedFormFilterOption.filterType =
              option.key.toString() as FilterType;
            onFormFilterChange(formFilterOption, updatedFormFilterOption);
          }}
        />
      </div>
      <div className="field">
        {getValueInput(
          formFilterOption,
          rowIndex === 0,
          !isFormValid &&
            !formFilterOption.filterValue &&
            formFilterOption.filterType !== FilterType.IsEmpty
            ? "error-input"
            : undefined
        )}
      </div>
      <div className="field">
        {showClearButton ? (
          <BaseButton
            className="clear-one-button"
            onClick={() => {
              onFormFilterChange(formFilterOption, {});
            }}
          >
            {t("voc.feedback.clear")}
          </BaseButton>
        ) : (
          <BaseButton
            className="delete-button"
            onClick={() => {
              onFormFilterChange(formFilterOption, undefined);
            }}
          >
            {t("voc.feedback.delete")}
          </BaseButton>
        )}
      </div>
    </div>
  );
};

export default AppFilterFieldRow;
