import { useMemo } from "react";
import { useParams } from "react-router-dom";

export interface RouteParams {
  // exclude undefined value here to avoid extensive checking in client code
  entityId: string;
  // add more here to meet app requirement, eg, registerId, albumId...
}

const useRouteParams = (): RouteParams => {
  const { entityId } = useParams<"entityId">();

  const params = useMemo(
    () => ({
      entityId: entityId || "",
    }),
    [entityId]
  );

  return params;
};

export default useRouteParams;
