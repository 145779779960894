import React, { useEffect, useRef, useState } from "react";
import "./ValueRegisterInput.scss";
import keyFilter from "./keyFilter";
import { VocValueRegister } from "../../../../app/types";
import {
  formatNumberWithComma,
  formatPercentValue,
  getNumberFromFormatValue,
} from "../../../../app/utils/string";
import i18n from "../../../../app/i18n/i18n";
import { useTranslation } from "react-i18next";

interface ValueRegisterPercentInputProps {
  id: string;
  suffix?: string;
  value: number;
  fieldName: keyof VocValueRegister;
  rowIndex: number;
  disabled?: boolean;
  onChange: (
    value: number,
    prevValue: number,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => void;
  onKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => void;
  onFocus: (
    e: React.FocusEvent<HTMLInputElement>,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const ValueRegisterPercentInput: React.FC<ValueRegisterPercentInputProps> = ({
  id,
  suffix = "%", //one character only
  value,
  fieldName,
  rowIndex,
  disabled = false,
  onChange,
  onKeyDown, // for navigating table cell with keyboard
  onFocus,
  onBlur,
}) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>();

  const [inputValue, setInputValue] = useState(() => value);

  const decimalSeparator = ["es", "de"].includes(i18n.language) ? "," : ".";

  useEffect(() => {
    if (inputValue !== value) {
      inputRef.current.value = formatPercentValue(value, suffix);
      setInputValue(value);
    } else if (
      (inputValue && inputValue * 100) !==
      getNumberFromFormatValue(inputRef.current.value, i18n.language)
    ) {
      // handle a rare case when the input element's value is not in sync with inputValue state
      inputRef.current.value = formatPercentValue(inputValue, suffix);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, t]);

  const handleChange = (newValue: number) => {
    setInputValue((prevState) => {
      if (prevState !== newValue) {
        onChange(newValue, prevState, fieldName, rowIndex);
      }
      return newValue;
    });
  };

  // from https://codepen.io/559wade/pen/LRzEjj
  function formatPercent(input: HTMLInputElement, isBlur: boolean) {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    let input_val = input.value;

    // don't validate empty input
    if (input_val === "") {
      handleChange(getNumberFromFormatValue(input_val, i18n.language));
      return;
    }

    // original length
    const original_len = input_val.length;

    // initial caret position
    let caret_pos = input.selectionStart;

    // check for decimal
    if (input_val.indexOf(decimalSeparator) >= 0) {
      // get position of first decimal
      // this prevents multiple decimals from
      // being entered
      const decimal_pos = input_val.indexOf(decimalSeparator);

      // split number by decimal point
      let left_side = input_val.substring(0, decimal_pos);
      let right_side = input_val.substring(decimal_pos);

      // add commas to left side of number
      left_side = formatNumberWithComma(left_side, "");

      // validate right side
      right_side = formatNumberWithComma(right_side, "");

      // On blur make sure 2 numbers after decimal
      if (isBlur) {
        right_side += "00";
      }

      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);

      // join number by .
      input_val = left_side + decimalSeparator + right_side + suffix;
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = formatNumberWithComma(input_val, "");
      if (!isBlur) {
        input_val = input_val + suffix;
      }

      // final formatting
      if (isBlur) {
        if (input_val.length > 0) {
          input_val += `${decimalSeparator}00%`;
        }
        if (input_val === suffix) {
          input_val = "";
        }
      }
    }

    // send updated string to input
    input.value = input_val;

    // put caret back in the right position
    const updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;
    input.setSelectionRange(caret_pos, caret_pos);

    handleChange(getNumberFromFormatValue(input_val, i18n.language) / 100);
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (keyFilter(e)) {
      formatPercent(e.currentTarget, false);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // if (inputValue) {
    //   setInputValue(prevState => formatValue(prevState));
    // }
    formatPercent(e.currentTarget, true);
    onBlur(e);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    onFocus(e, fieldName, rowIndex);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown(e, fieldName, rowIndex);
  };

  return (
    <input
      ref={inputRef}
      id={id}
      className="voc-value-register-input"
      disabled={disabled}
      defaultValue={formatPercentValue(inputValue, suffix)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
    />
  );
};

export default ValueRegisterPercentInput;
