import React from "react";
import { useTranslation } from "react-i18next";

import {
  AppFormField,
  AppFormRow,
  AppFormSingleFileField,
  AppFormTextField,
} from "../../../../app/components/app-form";
import { VocLibraryObject } from "../../../../app/types";

interface DescriptionWithFileProps {
  form: Partial<VocLibraryObject>;
  formErrors: any; //ValidationResult<VocLibraryObject>;
  onFormChange: (changeValues: Partial<VocLibraryObject>) => void;
}

const DescriptionWithFile: React.FC<DescriptionWithFileProps> = ({
  form,
  formErrors,
  onFormChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <AppFormRow>
        <AppFormTextField
          required={true}
          label={t("voc.library.description")}
          name="label"
          value={form.label}
          errorMessage={formErrors && formErrors.label}
          onChange={(_, value) => onFormChange({ label: value })}
        />
      </AppFormRow>
      <AppFormRow>
        <AppFormSingleFileField
          file={
            form.files?.length
              ? {
                  ...form.files[0],
                }
              : undefined
          }
          onUploaded={(file) =>
            onFormChange({
              files: [
                {
                  ...file,
                },
              ],
            })
          }
          onRemove={() => onFormChange({ files: [] })}
          errorMessage={formErrors && formErrors.files}
        />
        <AppFormField />
      </AppFormRow>
    </>
  );
};

export default DescriptionWithFile;
