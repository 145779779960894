import React from "react";
import { useTranslation } from "react-i18next";

import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";
import { DataListItem } from "../../../app/components/app-data-list/types";
import useAppContext from "../../../app/hooks/useAppContext";
import { getUserDisplayName, Right } from "../../../app/services/app";
import { VocLog, VocUpdateNotificationsRequest } from "../../../app/types";
import { ServiceConfig } from "../../../app/utils/backend/api-request";
import LogsTabLayout from "./LogsTabLayout";

interface LogsTabContainerProps {
  permissionCode: Right["objectCode"];
  id: string;
  onFetch: (
    siteEventId: string,
    serviceConfig: ServiceConfig
  ) => Promise<VocLog[]>;
  onUndo: (
    eventId: string,
    logEntryId: string,
    serviceConfig: ServiceConfig
  ) => Promise<VocUpdateNotificationsRequest>;
  onEventRefresh: () => void;
}

const LogsTabContainer: React.FC<LogsTabContainerProps> = (props) => {
  const { serviceConfig, errorHandler } = useAppContext();
  const { permissionCode, id, onFetch, onUndo, onEventRefresh } = props;
  const { t } = useTranslation();

  const [logs, setLogs] = React.useState<DataListItem[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const convertLogs = React.useCallback((logs: VocLog[]) => {
    return logs
      ? logs.map((log) => {
          let dataChanged = (
            <>
              <span className="tab-title">{log.tab ?? "General"}</span>
              <div style={{ padding: 3 }}>
                {log.action === "DELETE" ? (
                  <strong>{log.sourceType}</strong>
                ) : (
                  <div>
                    {Object.keys(log.changedFields)?.map((field, i) => {
                      return (
                        <div>
                          <strong>{field} </strong>
                          <span> : </span>
                          <span>
                            {JSON.stringify(log.changedFields?.[field]?.old)}{" "}
                          </span>
                          <strong style={{ fontSize: 16 }}> → </strong>
                          <span>
                            {JSON.stringify(log.changedFields?.[field]?.new)}{" "}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          );

          return {
            id: log.id,
            action: log.action,
            fieldDataArray: [
              { fieldData: log.action },
              { fieldData: getUserDisplayName(log.editor) },
              { fieldData: log.createdOn },
              { fieldData: dataChanged },
            ],
          };
        })
      : [];
  }, []);

  const fetchLogs = React.useCallback(async () => {
    try {
      const response = await onFetch(id, serviceConfig);
      const conversion = convertLogs(response);
      setLogs(conversion);
      setLoaded(true);
    } catch (ex) {
      errorHandler(ex, "Fetch Logs");
    }
  }, [convertLogs, errorHandler, id, onFetch, serviceConfig]);

  React.useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  const undoLog = async (logEntryId: string) => {
    try {
      await onUndo(id, logEntryId, serviceConfig);
      fetchLogs();
      onEventRefresh();
    } catch (ex) {
      errorHandler(ex, "Undo Logs");
    }
  };

  return loaded ? (
    <AccessVisible
      objectCode={permissionCode}
      actionType="view"
      fallback={<i>{t("app.permission.accessDenied")}</i>}
    >
      <LogsTabLayout
        logs={logs}
        undoCallback={undoLog}
        permissionCode={permissionCode}
      />
    </AccessVisible>
  ) : null;
};

export default LogsTabContainer;
