import { convertToJsonBody } from "../../../../app/services/convert-to-json-body";
import { VocUpdateSubrecordsResponse } from "../../../../app/types";
import {
  ServiceConfig,
  apiRequest,
} from "../../../../app/utils/backend/api-request";
import { EditValuesSubrecord } from "./value-breakdown.type";

export const updateValuesSubRecords = (
  eventId: string,
  valuesSubrecordsUpdate: EditValuesSubrecord,
  serviceConfig: ServiceConfig
): Promise<VocUpdateSubrecordsResponse> => {
  return apiRequest<VocUpdateSubrecordsResponse>(
    `/events/${eventId}/valuation/records`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PUT",
      body: convertToJsonBody(
        { records: [valuesSubrecordsUpdate] },
        serviceConfig.timezoneHourAdjustment
      ),
    }
  );
};

export const deleteValuesSubRecords = (
  eventId: string,
  valuesSubrecordsUpdate: { id: string; isDeleted: boolean },
  serviceConfig: ServiceConfig
): Promise<VocUpdateSubrecordsResponse> => {
  return apiRequest<VocUpdateSubrecordsResponse>(
    `/events/${eventId}/valuation/records`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PUT",
      body: convertToJsonBody(
        { records: [valuesSubrecordsUpdate] },
        serviceConfig.timezoneHourAdjustment
      ),
    }
  );
};
