import { UploadOutlined } from "@ant-design/icons";
import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModalFooter,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { Button, Upload } from "antd";
import { isBefore } from "date-fns";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import useAppContext from "../../../../../app/hooks/useAppContext";
import { VocSupportDocumentEnums } from "../../../../../app/types";
import { getUserDisplayDateFormat } from "../../../../../app/utils/mappers";
import { createSupportDocument } from "../new-document-form-services";
import { LabourRecordForm } from "../new-document-form-type";
import { useFileHandler } from "./hooks/useFileHandler";

interface NewOtherDocumentFormProps {
  onModalClose: () => void;
  onRefresh: () => void;
  documentType: VocSupportDocumentEnums | "";
}

const NewOtherDocumentForm: React.FC<NewOtherDocumentFormProps> = ({
  onModalClose,
  onRefresh,
  documentType,
}) => {
  const { t } = useTranslation();

  const { eventId, siteEventId } = useParams();

  const { serviceConfig, errorHandler } = useAppContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formState, setFormState] = useState<LabourRecordForm>({
    fromDate: undefined,
    toDate: undefined,
    subject: "",
    comment: "",
    link: null,
    files: [],
    recordStatus: undefined,
  });

  const { handleFileChange, handleRemoveFile } =
    useFileHandler<LabourRecordForm>(setFormState);

  const formSchema = yup.object().shape({
    fromDate: yup.date().required(t("voc.eventPage.newDocument.fromRequired")),
    toDate: yup
      .date()
      .required(t("voc.eventPage.newDocument.toRequired"))
      .when(
        "fromDate",
        (fromDate, schema) =>
          fromDate &&
          schema.min(
            fromDate,
            t("ToDate must be greater than or equal to FromDate")
          )
      ),
    subject: yup
      .string()
      .required(t("voc.eventPage.newDocument.subjectRequired")),
    comment: yup.string(),
    link: yup.string().url("Enter a valid URL").notRequired(),
  });

  const formItems: NxpFormGridItemProps<LabourRecordForm>[] = [
    {
      controlType: "datePicker",
      span: 12,
      label: t("voc.common.from"),
      itemFieldName: "fromDate",
      required: true,
      controlProps: {
        format: getUserDisplayDateFormat(),
        value: formState?.fromDate,
        disabledDate: (current) =>
          current && isBefore(new Date(), current.toDate()),
      },
    },
    {
      controlType: "datePicker",
      span: 12,
      label: t("voc.common.to"),
      itemFieldName: "toDate",
      required: true,
      controlProps: {
        format: getUserDisplayDateFormat(),
        value: formState?.toDate,
        disabledDate: (current) =>
          current && isBefore(new Date(), current.toDate()),
      },
    },
    {
      controlType: "input",
      span: 24,
      label: t("voc.common.subject"),
      itemFieldName: "subject",
      required: true,
      controlProps: {
        value: formState?.subject,
      },
    },
    {
      controlType: "input",
      span: 24,
      label: t("voc.common.comment"),
      itemFieldName: "comment",
      controlProps: {
        value: formState?.comment,
      },
    },
    {
      controlType: "custom",
      span: 12,
      label: "File",
      itemFieldName: "files",
      customContent: (
        <Upload
          beforeUpload={(file) => {
            handleFileChange(file);
            return false; // Prevent automatic upload
          }}
          onRemove={handleRemoveFile}
          fileList={formState?.files.map((file) => ({
            uid: file.assetId,
            name: file.fileName,
            status: "done",
            url: file.url,
          }))}
        >
          {formState?.files.length < 1 && (
            <Button icon={<UploadOutlined />}>
              {t("voc.common.clickToUpload")}
            </Button>
          )}
        </Upload>
      ),
    },
    {
      controlType: "input",
      span: 12,
      label: t("voc.common.link"),
      itemFieldName: "link",
      controlProps: {
        value: formState?.link,
        placeholder: "https://",
      },
    },
  ];

  const handleSaveValidated = async () => {
    try {
      setIsSubmitting(true);
      const idToUse = siteEventId || eventId;
      const isSiteEvent = Boolean(siteEventId);

      await createSupportDocument(
        idToUse,
        {
          documentFiles: formState?.files,
          comment: formState?.comment,
          details: {
            documentType: documentType,
            fromDate: formState?.fromDate,
            toDate: formState?.toDate,
            subject: formState?.subject,
            link: formState?.link,
            recordStatus: formState?.recordStatus,
          },
          documentType: documentType,
        },
        serviceConfig,
        isSiteEvent
      );
      onModalClose();
      onRefresh();
    } catch (error) {
      errorHandler(error, "save document");
    } finally {
      setIsSubmitting(false);
    }
  };
  const [validationError, , , saveWithValidate] =
    useYupValidate<LabourRecordForm>(
      formState,
      formSchema,
      handleSaveValidated
    );

  const handleFormGridStateChange = (
    fieldName: keyof LabourRecordForm,
    value: unknown
  ) => {
    setFormState((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleValidateSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  return (
    <>
      <NxpFormGrid
        formItems={formItems}
        formState={formState}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
      <NxpModalFooter>
        <NxpButton
          disabled={isSubmitting}
          type="default"
          onClick={onModalClose}
        >
          {t("voc.common.cancel")}
        </NxpButton>
        <NxpButton
          disabled={isSubmitting}
          onClick={handleValidateSave}
          type="primary"
        >
          {t("voc.common.save")}
        </NxpButton>
      </NxpModalFooter>
    </>
  );
};

export default NewOtherDocumentForm;
