import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { NxpSpin } from "@nexploretechnology/nxp-ui";
import { get, has, isEmpty, isNull, omit } from "lodash";

import { DataListItem } from "../../../../app/components/app-data-list/types";
import { Option } from "../../../../app/components/app-field-block/AppFieldBlock";
import useAppContext from "../../../../app/hooks/useAppContext";
import {
  DeletedRecord,
  VocCreateCompEventData,
  VocCustomUi,
  VocEditingData,
  VocEventFile,
  VocEventFileWrapper,
  VocGetDictionary,
  VocScreenNameEnums,
  VocSiteEvent,
  VocSiteEventPatchRequest,
  VocSiteEventValidation,
} from "../../../../app/types";
import {
  EVENT_STATUS_CLOSED,
  INSTRUCTED_BY_NONE,
  INSTRUCTED_BY_OTHER,
} from "../../../../app/utils/const";
import {
  convertLocationOptions,
  LocationOption,
} from "../../../../app/utils/location";
import InfoTabLayout from "./InfoTabLayout";
import { useAsync } from "../../../../app/hooks/useAsync";
import {
  Recovery,
  updateSiteEventRecovery,
} from "../../../site-event-services";

interface InfoTabContainerProps {
  siteEventData: VocSiteEvent;
  onEventRefresh: () => void;
  setForm: React.Dispatch<React.SetStateAction<VocSiteEventPatchRequest>>;
  setUpdateSiteEventRecovery: (asyncRequest: () => Promise<Recovery>) => void;
}

const InfoTabContainer: React.FC<InfoTabContainerProps> = (props) => {
  const { siteEventData, setForm, setUpdateSiteEventRecovery, onEventRefresh } =
    props;
  const {
    libraries,
    getDictionary,
    getCustomizedScreen,
    errorHandler,
    serviceConfig,
  } = useAppContext();
  const { t } = useTranslation();
  const customUi = getCustomizedScreen(
    VocScreenNameEnums.SITE_EVENT_SUMMARY_SCREEN
  );

  const [screenDataAsyncResut] = useAsync<VocCustomUi>(
    () =>
      Promise.resolve({
        layout: customUi["layout"],
      } as VocCustomUi)
    // vocService.getCustomizedScreen(VocScreenIdEnums.SITE_EVENT_SUMMARY_SCREEN)
  );

  /////////////////////////////
  //EDITING STATE CONTROL HOOKS
  const [isEditingGeneral, setIsEditingGeneral] =
    React.useState<boolean>(false);
  const [isEditingRecovery, setIsEditingRecovery] =
    React.useState<boolean>(false);

  //RENDER STATE CONTROL HOOK
  const [recoverable, setRecoverable] = React.useState<boolean>(
    siteEventData.recoverable
  );
  const [reasonForNotRecoverable, setReasonForNotRecoverable] =
    React.useState<string>(siteEventData.reasonForNotRecoverable);

  const setformData = useCallback((): VocEditingData => {
    let customization: Record<string, string> = {};
    get(customUi, "layout[0]members", [])
      .filter((field) => field?.type === "QuestionListCustomField")
      .forEach((item) => {
        customization[item.key] = siteEventData["customization"][item.key];
      });
    return {
      ...siteEventData,
      customization,
      eventSource: siteEventData.eventSource as any, // ? object
      eventSourceType:
        (siteEventData.eventSourceType as string[])?.map((label) => label) ||
        [],
      eventStatus: siteEventData.eventStatus ? siteEventData.eventStatus : null,
      delayDisruptiveEffect: isNull(siteEventData.delayDisruptiveEffect)
        ? []
        : isEmpty(
            siteEventData.delayDisruptiveEffect.filter(
              (delay) => !isNull(delay)
            )
          )
        ? []
        : siteEventData.delayDisruptiveEffect?.map((obj) => obj),
      instructedBy: siteEventData.instructedBy as string,
      locations: (siteEventData.locations as string[]).map((label) => label),
      tags: siteEventData.tags
        ? siteEventData.tags.map((tag: string) => ({ name: tag, key: tag }))
        : null,
    };
  }, [siteEventData, customUi]);

  /////////////////
  //CORE DATA HOOKS
  const [editingData, setEditingData] = React.useState<VocEditingData>(
    setformData()
  );
  const [createCompEventData, setCreateCompEventData] =
    React.useState<VocCreateCompEventData>({
      eventType: undefined,
      commercialOwner: null,
      operationOwner: null,
      recordSupporter: null,
      contemporaryRecordRequired: true,
    });

  useEffect(() => {
    setEditingData(setformData());
    return () => {
      setEditingData({} as VocEditingData);
    };
  }, [setformData, siteEventData]);

  const [linkCompEvent, setLinkCompEvent] = React.useState<any>(null);

  ///////
  //FILES
  const [files, setFiles] = React.useState<DataListItem[]>(
    convertFiles(siteEventData.files)
  );
  const [filesUpdate, setFilesUpdate] = React.useState<VocEventFile[][]>([]);
  const [deletedRecords, setDeletedRecords] = React.useState<number[]>(null);

  function convertFiles(files: VocEventFileWrapper[]) {
    return files.map((item) => ({
      id: item.id,
      noRemove: true,
      fieldDataArray: [{ fieldData: [item.file] }],
    }));
  }

  function addFile() {
    setFiles([...files, { id: null, fieldDataArray: [{ fieldData: [] }] }]);
    const newFilesUpdate = [...filesUpdate];
    newFilesUpdate.push([]);
    setFilesUpdate(newFilesUpdate);
  }

  React.useEffect(() => {
    if (files) {
      const deletedRecords: number[] = [];
      siteEventData.files.forEach((memo: VocEventFileWrapper) => {
        let deleted = true;
        files.forEach((file) => {
          if (memo.id === file.id) {
            deleted = false;
          }
        });
        if (deleted) {
          deletedRecords.push(memo.id);
        }
      });
      setDeletedRecords(deletedRecords);

      setFilesUpdate(
        files.map((file) => {
          if (file.fieldDataArray[0].fieldData[0]) {
            const newFile = file.fieldDataArray[0].fieldData[0];
            newFile.id = file.id;
            return [newFile];
          } else {
            return [];
          }
        })
      );
    }
  }, [files, siteEventData]);

  //0 - not selected, 1 - new compensation event, 2 - link compensation event
  const [compensationEvent, setCompensationEvent] = React.useState<number>(0); //initial state sync with data received - later

  const [validationErrors, setValidationErrors] =
    React.useState<VocSiteEventValidation>({
      compensationEvent: null,
      eventType: null,
      linkCompEvent: null,
      commercialOwner: null,
      operationOwner: null,
      recordSupporter: null,
    });

  //////////////
  //GET SOURCES
  const [libSources] = React.useState<Option[]>(
    convertLibs(libraries["VOC_SOURCES"] || [])
  );

  function convertLibs(dicts: VocGetDictionary[]) {
    const libs: Option[] = [];
    dicts.forEach((dict) => {
      if (!dict.disabled) {
        libs.push({
          enumValue: dict.value,
          render: dict.label,
        });
      }
    });
    return libs;
  }

  //////////////
  //GET SOURCE TYPES
  const [libSourceTypes] = React.useState<Option[]>(
    convertLibs(libraries["VOC_SOURCE_TYPE"] || [])
  );
  /////////////////////////
  //GET DELAYING/DISRUPTIVE
  const [libDelayDisrupt] = React.useState<Option[]>(
    convertLibs(libraries["VOC_DELAY_EFFECTS"] || [])
  );
  ////////////////////////////
  //GET EMPOWERED CLIENT REPS
  const [libReps] = React.useState<Option[]>([
    ...convertLibs(libraries["VOC_VO_EMPOWER_REPS"] || []),
  ]);

  ///////////////
  //GET LOCATIONS
  const [libLocs, setLibLocs] = React.useState<Option[]>(null);
  const [,] = useAsync<LocationOption[]>(
    () =>
      new Promise((resolve) =>
        resolve(
          convertLocationOptions(getDictionary("LOCATION")?.members || [])
        )
      ),
    {
      onSuccess: (data) => {
        setLibLocs(data);
      },
      onError(ex) {
        errorHandler(ex, "getting location dictionary");
      },
    }
  );

  ////////////////////////////
  //GET EVENT STATUSES
  const [libStatuses] = React.useState<Option[]>(
    convertLibs(libraries["VOC_EVENT_STATUS"] || [])
  );

  //EDITING VALUES
  function setValues(...args: any[]) {
    const fieldLabel = arguments[1];
    const fildKey = arguments[0] as keyof VocSiteEvent;
    const newValue = arguments[2];

    switch (fildKey) {
      case "subject":
        setEditingData({ ...editingData, subject: newValue });
        break;
      case "remarks":
        setEditingData({ ...editingData, remarks: newValue });
        break;
      case "eventSource":
        setEditingData({ ...editingData, eventSource: newValue });
        break;
      case "reference":
        setEditingData({ ...editingData, reference: newValue });
        break;
      case "locations": {
        const locCopy = [...editingData.locations];
        if (newValue.selected) {
          locCopy.push(newValue.key);
          setEditingData({
            ...editingData,
            locations: locCopy,
          });
        } else {
          const children = libLocs
            .filter((lib) => lib.childOf === newValue.key)
            .map((lib) => lib.enumValue);
          const subtracted = locCopy.filter(
            (loc) => loc !== newValue.key && !children.includes(loc)
          );
          setEditingData({
            ...editingData,
            locations: subtracted,
          });
        }
        setLibLocs((prevState) => {
          const libLocCopy = prevState;
          prevState.forEach((loc) => {
            if (newValue.selected && loc.enumValue === newValue.key) {
              libLocCopy.forEach((copy) => {
                if (copy.childOf === loc.enumValue) {
                  copy.disabled = false;
                }
              });
            }
            if (!newValue.selected && loc.enumValue === newValue.key) {
              libLocCopy.forEach((copy) => {
                if (copy.childOf === loc.enumValue) {
                  copy.disabled = true;
                }
              });
            }
          });
          return libLocCopy;
        });
        break;
      }
      case "eventSourceType": {
        const estCopy = [...editingData.eventSourceType];
        if (newValue.selected) {
          estCopy.push(newValue.key);
          setEditingData({
            ...editingData,
            eventSourceType: estCopy,
          });
        } else {
          const subtracted = estCopy.filter((est) => est !== newValue.key);
          setEditingData({
            ...editingData,
            eventSourceType: subtracted,
          });
        }
        break;
      }
      case "delayDisruptiveEffect": {
        const ddCopy = [...editingData.delayDisruptiveEffect];
        if (newValue.selected) {
          ddCopy.push(newValue.key);
          setEditingData({
            ...editingData,
            delayDisruptiveEffect: ddCopy,
          });
        } else {
          const subtracted = ddCopy.filter((dd) => dd !== newValue.key);
          setEditingData({
            ...editingData,
            delayDisruptiveEffect: subtracted,
          });
        }
        break;
      }
      case "workType":
        setEditingData({ ...editingData, workType: newValue });
        break;
      case "instructedBy":
        setEditingData({
          ...editingData,
          instructedBy: newValue,
          instructionEmpowered:
            newValue === INSTRUCTED_BY_OTHER || newValue === INSTRUCTED_BY_NONE
              ? false
              : editingData.instructionEmpowered,
        });
        break;
      case "instructedByOther":
        setEditingData({ ...editingData, instructedByOther: newValue });
        break;
      case "dateOfAwareness":
        setEditingData({ ...editingData, dateOfAwareness: newValue });
        break;
      case "dateOfCessation":
        setEditingData({ ...editingData, dateOfCessation: newValue });
        break;
      case "instructionEmpowered":
        setEditingData({
          ...editingData,
          instructionEmpowered: newValue === "y" ? true : false,
        });
        break;
      case "tags":
        setEditingData({ ...editingData, tags: newValue });
        break;
      case "eventStatus":
        setEditingData({ ...editingData, eventStatus: newValue });
        break;
      case "contemporaryRecordRequired":
        setEditingData({
          ...editingData,
          contemporaryRecordRequired: newValue,
        });
        break;
      case "eventType":
        setCreateCompEventData({ ...createCompEventData, eventType: newValue });
        break;
      case "commercialOwner":
        setCreateCompEventData({
          ...createCompEventData,
          commercialOwner: newValue[0],
        });
        break;
      case "operationOwner":
        setCreateCompEventData({
          ...createCompEventData,
          operationOwner: newValue[0],
        });
        break;
      case "recordSupporter":
        setCreateCompEventData({
          ...createCompEventData,
          recordSupporter: newValue[0],
        });
        break;
      case "linkCompEvent":
        setLinkCompEvent(newValue[0]);
        break;
    }

    const customs = screenDataAsyncResut.data.layout[0].members.filter(
      (m: any) => m.type === "QuestionListCustomField"
    );

    const custom = customs.filter(
      (c: any) => c.name.toLocaleUpperCase() === fieldLabel
    );
    if (custom.length > 0) {
      //need to mutate this call for each different custom type!
      setEditingData({
        ...editingData,
        ...{
          customization: { ...editingData?.customization, [fildKey]: newValue },
        },
      });
    }
  }

  //SAVE
  const [closeInvalid, setCloseInvalid] = React.useState<boolean>(false);

  function saveGeneral() {
    const filesCopy = [...files];

    let isInvalid = false;

    filesUpdate.forEach((file, i) => {
      if (file.length < 1) {
        filesCopy[i].validationError = t(
          "voc.siteEventDetails.pleaseSubmitFile"
        );
        isInvalid = true;
      } else if (!file[0].id) {
        filesCopy[i].fieldDataArray[0].fieldData[0] = file[0];
      }
    });
    setFiles(filesCopy);

    if (
      editingData.eventStatus === EVENT_STATUS_CLOSED &&
      !editingData.dateOfCessation
    ) {
      setCloseInvalid(true);
      isInvalid = true;
    } else {
      //reset validation
      setCloseInvalid(false);
    }

    if (isInvalid) {
      return;
    }

    const dearrayed = filesUpdate.map((update) => update[0]);

    const updateObjects = dearrayed.map((update) => ({ file: update }));

    const deletedRecordObjects: DeletedRecord[] = deletedRecords.map((fid) => ({
      id: fid,
      isDeleted: true,
    }));
    const combinedRecords = [...updateObjects, ...deletedRecordObjects]
      .filter((postFile) => !has(postFile, "isDeleted"))
      .map((imgFile: { file: VocEventFile }) => ({
        file: omit(imgFile?.file, ["id"]),
      }));
    const customs = screenDataAsyncResut.data.layout[0].members.filter(
      (m: any) => m.type === "QuestionListCustomField"
    );

    const customFieldObject: any = {};

    customs.forEach((c: any) => {
      const fieldVal = editingData["customization"][c.key];
      customFieldObject[c.key] =
        c.dataType === "VALUE" || c.dataType === "PERCENTAGE"
          ? parseFloat(fieldVal)
          : fieldVal;
    });

    setForm({
      subject: editingData.subject,
      remarks: editingData.remarks,
      eventSource: editingData.eventSource,
      eventSourceType: editingData.eventSourceType,
      eventStatus: editingData.eventStatus,
      delayDisruptiveEffect: editingData.delayDisruptiveEffect,
      workType: editingData.workType,
      reference: editingData.reference,
      locations: editingData.locations,
      instructedBy: editingData.instructedBy,
      instructedByOther:
        editingData.instructedBy === INSTRUCTED_BY_OTHER
          ? editingData.instructedByOther
          : null,
      instructionEmpowered: editingData.instructionEmpowered,
      dateOfAwareness: editingData.dateOfAwareness,
      dateOfCessation: editingData.dateOfCessation,
      contemporaryRecordRequired: editingData.contemporaryRecordRequired,
      recoverable: recoverable,
      reasonForNotRecoverable: reasonForNotRecoverable,
      files: combinedRecords,
      tags: editingData.tags ? editingData.tags.map((tag) => tag.name) : null,
      ...{ customization: customFieldObject },
    });
    setIsEditingGeneral(false);
  }

  function saveRecovery() {
    if (recoverable) {
      if (compensationEvent === 0) {
        setValidationErrors({
          ...validationErrors,
          compensationEvent: t("voc.siteEventDetails.compEventValidation"),
        });
      }
      if (compensationEvent === 1) {
        let opOwnerValidation = null;
        if (createCompEventData.contemporaryRecordRequired) {
          if (!createCompEventData.operationOwner) {
            opOwnerValidation = t("voc.siteEventDetails.opOwnerValidation");
          }
        }
        setValidationErrors({
          ...validationErrors,
          compensationEvent: null,
          eventType: createCompEventData.eventType
            ? null
            : t("voc.siteEventDetails.eventTypeValidation"),
          commercialOwner: createCompEventData.commercialOwner
            ? null
            : t("voc.siteEventDetails.commOwnerValidation"),
          operationOwner: opOwnerValidation,
        });

        if (
          !opOwnerValidation &&
          createCompEventData.eventType &&
          createCompEventData.commercialOwner &&
          createCompEventData.operationOwner
        ) {
          const compensationEvent: any = {
            eventType: createCompEventData.eventType,
            commercialOwnerId: createCompEventData.commercialOwner.key,
            contemporaryRecordRequired:
              createCompEventData.contemporaryRecordRequired,
          };
          if (createCompEventData.contemporaryRecordRequired) {
            compensationEvent.operationOwnerId =
              createCompEventData.operationOwner.key;
          }
          if (createCompEventData.recordSupporter) {
            compensationEvent.recordSupporterId =
              createCompEventData.recordSupporter.key;
          }
          setUpdateSiteEventRecovery(() =>
            updateSiteEventRecovery(
              {
                compensationEvent,
                recoverable,
              },
              siteEventData?.id as string,
              serviceConfig
            ).finally(onEventRefresh)
          );
          setIsEditingRecovery(false);
        }
      }
      if (compensationEvent === 2) {
        setValidationErrors({
          ...validationErrors,
          compensationEvent: null,
          linkCompEvent: linkCompEvent
            ? null
            : t("voc.siteEventDetails.linkCompEventValidation"),
        });
        //SET FORM
        if (linkCompEvent) {
          setUpdateSiteEventRecovery(() =>
            updateSiteEventRecovery(
              {
                compensationEventId: linkCompEvent.key,
                recoverable: recoverable,
              },
              siteEventData?.id as string,
              serviceConfig
            ).finally(onEventRefresh)
          );
          setIsEditingRecovery(false);
        }
      }
    }
    //reset validation error
    if (!recoverable) {
      setValidationErrors({
        ...validationErrors,
        compensationEvent: null,
      });
      saveGeneral();
      setIsEditingRecovery(false);
    }
  }

  //CANCEL
  function cancelGeneral() {
    const customs = screenDataAsyncResut.data.layout[0].members.filter(
      (m: any) => m.type === "QuestionListCustomField"
    );

    const customFieldObject: any = {};
    customs.forEach((c: any) => {
      customFieldObject[c.key] = siteEventData["customization"][c.key] || "";
    });

    setEditingData({
      ...editingData,
      remarks: siteEventData.remarks,
      eventSource: siteEventData.eventSource as string,
      eventSourceType:
        (siteEventData.eventSourceType as string[])?.map((label) => label) ||
        [],
      eventStatus: siteEventData.eventStatus,
      delayDisruptiveEffect:
        siteEventData.delayDisruptiveEffect?.map((obj) => obj) || [],
      reference: siteEventData.reference,
      instructedBy: siteEventData.instructedBy as string,
      locations: (siteEventData.locations as string[]).map((label) => label),
      dateOfAwareness: siteEventData.dateOfAwareness,
      ...{ customization: customFieldObject },
    });
    setCloseInvalid(false);
    setFiles(convertFiles(siteEventData.files));
  }

  function cancelRecovery() {
    setCompensationEvent(0);
    setCreateCompEventData({
      eventType: undefined,
      commercialOwner: null,
      operationOwner: null,
      recordSupporter: null,
      contemporaryRecordRequired: false,
    });
    setLinkCompEvent(null);
    setRecoverable(siteEventData.recoverable);
    setReasonForNotRecoverable(siteEventData.reasonForNotRecoverable);
  }

  const layout = screenDataAsyncResut.data
    ? screenDataAsyncResut.data.layout[0].members
    : null;

  const layoutOrder = screenDataAsyncResut.data
    ? screenDataAsyncResut.data.layout[0].members.map((f: any) => f.key)
    : null;

  const otherIndex = layoutOrder
    ? layoutOrder.indexOf("instructedBy") + 1
    : null;

  return libSources &&
    libSourceTypes &&
    libReps &&
    libLocs &&
    libDelayDisrupt &&
    libStatuses &&
    layout ? (
    // otherIndex
    <InfoTabLayout
      layout={layout}
      otherIndex={otherIndex}
      siteEventData={siteEventData}
      editingData={editingData}
      createCompEventData={createCompEventData}
      setCreateCompEventData={setCreateCompEventData}
      linkCompEvent={linkCompEvent}
      isEditingGeneral={isEditingGeneral}
      setIsEditingGeneral={setIsEditingGeneral}
      isEditingRecovery={isEditingRecovery}
      setIsEditingRecovery={setIsEditingRecovery}
      setValues={setValues}
      saveGeneral={saveGeneral}
      cancelGeneral={cancelGeneral}
      saveRecovery={saveRecovery}
      cancelRecovery={cancelRecovery}
      files={files}
      setFiles={setFiles}
      setFilesUpdate={setFilesUpdate}
      addFile={addFile}
      recoverable={recoverable}
      setRecoverable={setRecoverable}
      reasonForNotRecoverable={reasonForNotRecoverable}
      setReasonForNotRecoverable={setReasonForNotRecoverable}
      compensationEvent={compensationEvent}
      setCompensationEvent={setCompensationEvent}
      validationErrors={validationErrors}
      libSources={libSources}
      libSourceTypes={libSourceTypes}
      libDelayDisrupt={libDelayDisrupt}
      libReps={libReps}
      libLocs={libLocs}
      libStatuses={libStatuses}
      closeInvalid={closeInvalid}
    />
  ) : (
    <NxpSpin
      spinning={true}
      style={{ display: "flex", justifyContent: "center", margin: 60 }}
    ></NxpSpin>
  );
};

export default InfoTabContainer;
