import React from "react";
import { useTranslation } from "react-i18next";

import AppCard from "../../../app/components/app-card/AppCard";
import AppDataList from "../../../app/components/app-data-list/AppDataList";
import { DataListItem } from "../../../app/components/app-data-list/types";
import AppFieldBlock from "../../../app/components/app-field-block/AppFieldBlock";
import { VocKeyDateOption, VocPlanningRequest } from "../../../app/types";

interface PlanningTabLayoutProps {
  entityId: string;
  planningData: VocPlanningRequest;
  setPlanning: Function;
  savePlanning: Function;
  keyDatesData: DataListItem[];
  reactiveKeyDatesData: DataListItem[];
  setKeyDatesData: React.Dispatch<React.SetStateAction<DataListItem[]>>;
  saveKeyDates: Function;
  validateKeyDates: Function;
  addCallback: Function;
  cancelCallback: Function;
  libKeyDates: VocKeyDateOption[];
  libConstrStatuses: any[];
}

const PlanningTabLayout: React.FC<PlanningTabLayoutProps> = (props) => {
  const {
    entityId,
    planningData,
    setPlanning,
    savePlanning,
    keyDatesData,
    reactiveKeyDatesData,
    setKeyDatesData,
    saveKeyDates,
    validateKeyDates,
    addCallback,
    cancelCallback,
    libKeyDates,
    libConstrStatuses,
  } = props;
  const { t } = useTranslation();
  const [isEditingGeneral, setIsEditingGeneral] =
    React.useState<boolean>(false);
  const [isEditingDeadlines, setIsEditingDeadlines] =
    React.useState<boolean>(false);

  return (
    <div className="planning-tab-container">
      <AppCard
        title={t("voc.eventPage.generalInformation")}
        canEdit={true}
        isEditing={isEditingGeneral}
        editCallback={() => setIsEditingGeneral(true)}
        cancelCallback={() => {
          cancelCallback(0);
          setIsEditingGeneral(false);
        }}
        saveCallback={() => {
          savePlanning();
          setIsEditingGeneral(false);
        }}
      >
        <AppFieldBlock
          entityId={entityId}
          editing={isEditingGeneral}
          fields={[
            {
              label: t("voc.eventPage.planning.planningOwner"),
              value: planningData.planningOwner,
              type: "owner",
              width: "fourth",
              onChangeCallback: setPlanning,
              editable: true,
              itemLimit: 1,
            },
            {
              label: t("voc.eventPage.planning.impacted"),
              value: planningData.impactedActivities,
              type: "string",
              width: "fourth",
              onChangeCallback: setPlanning,
              editable: true,
            },
            {
              label: t("voc.eventPage.planning.planningReference"),
              value: planningData.planningReference,
              type: "string",
              width: "fourth",
              onChangeCallback: setPlanning,
              editable: true,
            },
            {
              label: t("voc.eventPage.planning.constructionStatus"),
              value: planningData.constructionStatus,
              type: "basicdropdown",
              width: "fourth",
              onChangeCallback: setPlanning,
              editable: true,
              options: libConstrStatuses,
            },
          ]}
        />
      </AppCard>
      <AppCard
        title={t("voc.eventPage.planning.keyDatesHeader")}
        canEdit={true}
        isEditing={isEditingDeadlines}
        editCallback={() => setIsEditingDeadlines(true)}
        cancelCallback={() => {
          cancelCallback(1);
          setIsEditingDeadlines(false);
        }}
        saveCallback={() => {
          if (validateKeyDates()) {
            saveKeyDates();
            setIsEditingDeadlines(false);
          }
        }}
        addCallback={addCallback}
        addCallbackShortcut={keyDatesData.length === 0}
      >
        <AppDataList
          editing={isEditingDeadlines}
          canDeleteLastItem={true}
          columns={[
            {
              columnName: t("voc.common.reference").toLocaleUpperCase(),
              columnWidthClassName: "fourth",
              columnDataType: "basicdropdown", //change to basicdropdown
              columnOptions: libKeyDates,
              editable: true,
            },
            {
              columnName: t("voc.eventPage.planning.dateOfContract"),
              columnWidthClassName: "fourth",
              columnDataType: "date",
              editable: false,
            },
            {
              columnName: t("voc.eventPage.planning.dateOfForecast"),
              columnWidthClassName: "fourth",
              columnDataType: "date",
              editable: true,
            },
            {
              columnName: t("voc.eventPage.planning.timeImpact"),
              columnWidthClassName: "fourth",
              columnDataType: "string",
              editable: false,
            },
          ]}
          items={reactiveKeyDatesData ? reactiveKeyDatesData : keyDatesData}
          editSetStateHook={setKeyDatesData}
          setFiles={null}
        />
      </AppCard>
    </div>
  );
};

export default PlanningTabLayout;
