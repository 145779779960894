import { ChoiceGroup, IChoiceGroupProps } from "office-ui-fabric-react";
import React from "react";

import AppFormField, { AppFormFieldProps } from "./AppFormField";

import "./AppFormRadioField.scss";

export type AppFormRadioFieldProps = {
  errorMessage?: string | string[];
} & Pick<
  IChoiceGroupProps,
  "selectedKey" | "options" | "onChange" | "label" | "required" | "disabled"
> &
  AppFormFieldProps;

const AppFormRadioField: React.FC<AppFormRadioFieldProps> = ({
  selectedKey,
  options,
  onChange,
  label,
  required,
  errorMessage,
  className,
  disabled,
  ...restProps
}) => {
  return (
    <AppFormField
      {...restProps}
      className={`app-form-radio-field${className ? ` ${className}` : ""}`}
    >
      <ChoiceGroup
        disabled={disabled}
        required={required}
        selectedKey={selectedKey}
        options={options}
        onChange={onChange}
        label={label}
      />
      {errorMessage && (
        <div role="alert" className="error">
          <p>
            {Array.isArray(errorMessage)
              ? errorMessage.join(" / ")
              : errorMessage}
          </p>
        </div>
      )}
    </AppFormField>
  );
};

export default AppFormRadioField;
