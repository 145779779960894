import { VocGetValuesToClient } from "../../../../app/types";

export const computeInternalValue = (state: VocGetValuesToClient) => {
  const { internalEstimateSubstantiated, firstEstimate } = state;
  //C = A or B
  if (internalEstimateSubstantiated) {
    return internalEstimateSubstantiated;
  } else if (firstEstimate) {
    return firstEstimate;
  }
  return undefined;
};

export const computeInternalTarget = (state: VocGetValuesToClient) => {
  const { internalValue, recovery } = state;
  if (internalValue && recovery) {
    //E = C * D
    return parseFloat((internalValue * recovery).toFixed(2));
  }
  return undefined;
};

export const computeRecovery = (state: VocGetValuesToClient) => {
  const { internalValue, internalTarget } = state;
  if (internalValue && internalTarget) {
    //D = E / C
    return parseFloat((internalTarget / internalValue).toFixed(2));
  }
  return undefined;
};

export const computeInternalVariance = (state: VocGetValuesToClient) => {
  const { internalValue, clientAssessed } = state;
  if (internalValue || clientAssessed) {
    //G = C - F
    return parseFloat(
      ((internalValue ?? 0) - (clientAssessed ?? 0)).toFixed(2)
    );
  }
  return undefined;
};

export const computeVarianceTCA = (state: VocGetValuesToClient) => {
  const { internalTarget, clientAssessed } = state;
  if (internalTarget || clientAssessed) {
    //H = E - F
    return parseFloat(
      ((internalTarget ?? 0) - (clientAssessed ?? 0)).toFixed(2)
    );
  }
  return undefined;
};
