import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  DemoUpload: {},
}));

interface DemoUploadProps {}

const DemoUpload: React.FC<DemoUploadProps> = () => {
  const classes = useStyles();

  return <div className={classes.DemoUpload}></div>;
};

export default DemoUpload;
