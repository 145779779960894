import React from "react";

import useAppContext from "../../../app/hooks/useAppContext";
import { RemarkAttachment } from "../../../app/services/vocService";
import ImportExcelModal from "./ImportExcelModal";

interface ImportExcelContainerProps {
  entityId: string;
  showImportExcelModal: boolean;
  onModalClose: () => void;
  onImport: Function;
}

const ImportExcelContainer: React.FC<ImportExcelContainerProps> = (props) => {
  const { entityId, showImportExcelModal, onModalClose, onImport } = props;
  const { errorHandler } = useAppContext();

  const [file, setFile] = React.useState<Partial<RemarkAttachment>[]>();
  const [importExecuted, setImportExecuted] = React.useState<boolean>(false);
  const [importSuccess, setImportSuccess] = React.useState<boolean>(false);
  const [importFailure, setImportFailure] = React.useState<boolean>(false);

  const handleImportExcel = React.useCallback(async () => {
    try {
      setImportFailure(false);
      setImportSuccess(true);
      setImportExecuted(true);
      onImport();
    } catch (ex) {
      errorHandler(ex, "Import Excel");
      setImportSuccess(false);
      setImportFailure(true);
      setImportExecuted(true);
    }
  }, [errorHandler, onImport]);

  const importExcel = () => {
    handleImportExcel();
  };

  return (
    <ImportExcelModal
      entityId={entityId}
      showImportExcelModal={showImportExcelModal}
      onModalClose={onModalClose}
      file={file}
      setFile={setFile}
      importExcel={importExcel}
      importExecuted={importExecuted}
      setImportExecuted={setImportExecuted}
      importSuccess={importSuccess}
      importFailure={importFailure}
    ></ImportExcelModal>
  );
};

export default ImportExcelContainer;
