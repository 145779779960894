import { ColumnDataType, DataTableColumn } from "../components/app-data-table";
import { VocSiteEventListItem } from "../types";

export const siteEventDefaultColumns: DataTableColumn<VocSiteEventListItem>[] =
  [
    {
      key: "serial",
      name: "voc.siteEvents.eventNumber",
      fieldName: "serial",
      minWidth: 108,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "subject",
      name: "voc.siteEvents.subject",
      fieldName: "subject",
      minWidth: 138,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "eventStatus",
      name: "voc.siteEvents.eventStatus",
      fieldName: "eventStatus",
      minWidth: 138,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Select,
    },
    {
      key: "eventSource",
      name: "voc.siteEvents.eventSource",
      fieldName: "eventSource",
      minWidth: 138,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Select,
    },
    {
      key: "reference",
      name: "voc.siteEvents.reference",
      fieldName: "reference",
      minWidth: 138,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "locations",
      name: "voc.siteEvents.location",
      fieldName: "locations",
      minWidth: 138,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Select,
    },
    {
      key: "instructedBy",
      name: "voc.siteEvents.instructedBy",
      fieldName: "instructedBy",
      minWidth: 138,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "dateOfAwareness",
      name: "voc.common.dateOfAwareness",
      fieldName: "dateOfAwareness",
      minWidth: 120,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Date,
    },
    {
      key: "recoverable",
      name: "voc.siteEvents.recoverable",
      fieldName: "recoverable",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Boolean,
    },
    {
      key: "compensationEventSerial",
      name: "voc.common.ceReference",
      fieldName: "compensationEventSerial",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "reportedBy",
      name: "voc.siteEvents.reportedBy",
      fieldName: "reportedBy",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "createdOn",
      name: "voc.siteEvents.createdOn",
      fieldName: "createdOn",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Date,
    },
    {
      key: "lastUpdatedBy",
      name: "voc.siteEvents.lastUpdatedBy",
      fieldName: "lastUpdatedBy",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "updatedOn",
      name: "voc.common.updatedOn",
      fieldName: "updatedOn",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Date,
    },
    {
      key: "numNoActionRecs",
      name: "voc.siteEvents.numNoActionRecs",
      fieldName: "numNoActionRecs",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Number,
    },
    {
      key: "numOutstandingRecs",
      name: "voc.siteEvents.numOutstandingRecs",
      fieldName: "numOutstandingRecs",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Number,
    },
    {
      key: "numPreparedRecs",
      name: "voc.siteEvents.numPreparedRecs",
      fieldName: "numPreparedRecs",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Number,
    },
    {
      key: "numSubmittedRecs",
      name: "voc.siteEvents.numSubmittedRecs",
      fieldName: "numSubmittedRecs",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Number,
    },
    {
      key: "numberOfDays",
      name: "voc.siteEvents.numberOfDays",
      fieldName: "numberOfDays",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Number,
    },
  ];
