import React, { useCallback, useEffect, useState } from "react";
import useAppContext from "../../../app/hooks/useAppContext";
import {
  addUserToRole,
  getEntityRoles,
  getEntityRoleUsers,
  ManageRolesTableItem,
  removeUserFromRole,
} from "../../manage-role-services";
import { useTranslation } from "react-i18next";
import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";
import ManageRolesTable from "./ManageRolesTable";
import { notify, NxpButton, NxpHeader } from "@nexploretechnology/nxp-ui";
import { createUseStyles } from "react-jss";
import AddRoleButton from "./actions/AddRoleButton";

const useStyles = createUseStyles((theme) => ({
  action: {
    display: "flex",
  },
  buttonDiv: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
  },
  role: {
    flex: 1,
    marginRight: "8px",
  },
  user: {
    flex: 1,
  },
  userInput: {
    margin: "8px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "16px 0",
  },
}));

interface ManageRolePageProps {}

const ManageRolePage: React.FC<ManageRolePageProps> = () => {
  const appContext = useAppContext();

  const classes = useStyles();

  const { serviceConfig, errorHandler } = appContext;

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [initRows, setInitRows] = useState<ManageRolesTableItem[]>([]);

  const [rows, setRows] = useState<ManageRolesTableItem[]>([]);

  const fetchEntityRolesWithUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const newRowData: ManageRolesTableItem[] = [];
      const entityRoles = await getEntityRoles(serviceConfig);

      for (const entityRole of entityRoles) {
        const entityRoleUsers = await getEntityRoleUsers(
          serviceConfig,
          entityRole.id
        );
        const entityRoleUserIds =
          entityRoleUsers.map((entityRoleUser) => entityRoleUser.id) ?? [];
        const row: ManageRolesTableItem = {
          id: entityRole.id,
          roleCode: entityRole.code,
          name: entityRole.name,
          description: entityRole.description,
          entityRoleUserIds: entityRoleUserIds,
          users: entityRoleUsers,
        };
        newRowData.push(row);
      }

      const sortedNewRowData = newRowData.sort((a, b) =>
        a.roleCode.localeCompare(b.roleCode)
      );

      setInitRows(sortedNewRowData);
      setRows(sortedNewRowData);
    } catch (ex) {
      setRows([]);
      errorHandler(
        ex as Error,
        t("security.manageRoles.error.fetchEntityRolesOrUsers") ?? ""
      );
    } finally {
      setIsLoading(false);
    }
  }, [errorHandler, serviceConfig, t]);

  useEffect(() => {
    fetchEntityRolesWithUsers();
  }, [fetchEntityRolesWithUsers]);

  const handleSave = async () => {
    const addedRolesUsers = [];
    const deletedRolesUsers = [];

    for (const row of rows) {
      const added = row.entityRoleUserIds
        .filter(
          (userId) =>
            !initRows
              .find((initRow) => initRow.id === row.id)
              .entityRoleUserIds.includes(userId)
        )
        .map((userId) => ({
          entityRoleId: row.id,
          userId: userId,
        }));
      addedRolesUsers.push(...added);

      const deleted = initRows
        .find((initRow) => initRow.id === row.id)
        .entityRoleUserIds.filter(
          (userId) => !row.entityRoleUserIds.includes(userId)
        )
        .map((userId) => ({
          entityRoleId: row.id,
          userId: userId,
        }));
      deletedRolesUsers.push(...deleted);
    }

    const updatedRolesUsersCount =
      addedRolesUsers.length + deletedRolesUsers.length;

    if (updatedRolesUsersCount <= 0) {
      notify.warning(t("security.manageRoles.error.noUpdatedEntityRolesUsers"));
      return;
    }

    setIsLoading(true);
    const errorMsgs = [];

    for (const addedRolesUser of addedRolesUsers) {
      try {
        await addUserToRole(
          serviceConfig,
          addedRolesUser.entityRoleId,
          addedRolesUser.userId
        );
      } catch (ex) {
        const errorMsg = {
          action: "Add",
          roleUser: addedRolesUser,
          error: ex,
        };
        errorMsgs.push(errorMsg);
      }
    }

    for (const deletedRolesUser of deletedRolesUsers) {
      try {
        await removeUserFromRole(
          serviceConfig,
          deletedRolesUser.entityRoleId,
          deletedRolesUser.userId
        );
      } catch (ex) {
        const errorMsg = {
          action: "Delete",
          roleUser: deletedRolesUser,
          error: ex,
        };
        errorMsgs.push(errorMsg);
      }
    }

    if (errorMsgs.length > 0) {
      if (errorMsgs.length === updatedRolesUsersCount) {
        errorHandler(
          "",
          t("security.manageRoles.error.saveEntityRolesOrUsersFailed")
        );
      } else {
        errorHandler(
          errorMsgs.map((errorMsg) => errorMsg.error).join(", "),
          errorMsgs
            .map(
              (errorMsg) =>
                `${t(
                  "security.manageRoles.error.saveEntityRolesOrUsers"
                )} (action: ${errorMsg.action}, entityRoleId: ${
                  errorMsg.roleUser.entityRoleId
                }, userId: ${errorMsg.roleUser.userId})`
            )
            .join(", ")
        );
        notify.success(
          t("security.manageRoles.error.partialSaveEntityRolesOrUsers")
        );
      }
    } else {
      notify.actionCompleted();
    }

    setIsLoading(false);
    await fetchEntityRolesWithUsers();
  };

  return (
    <AccessVisible
      objectCode="role"
      actionType="view"
      fallback={<i>{t("voc.AccessRight.permission.accessDenied")}</i>}
    >
      <NxpHeader
        titleContent={t("voc.ManageRole.User")}
        actionContent={
          <div className={classes.action}>
            <AccessVisible
              objectCode="role"
              actionType="create"
              fallback={<i>{t("voc.AccessRight.permission.addDenied")}</i>}
            >
              <AddRoleButton onRefetch={fetchEntityRolesWithUsers} />
            </AccessVisible>
          </div>
        }
      />
      <ManageRolesTable
        rows={rows}
        isLoading={isLoading}
        onSetRows={setRows}
        onSetInitRows={setInitRows}
        onRefetch={fetchEntityRolesWithUsers}
      />
      <div className={classes.buttonContainer}>
        <NxpButton type="default" onClick={fetchEntityRolesWithUsers}>
          {t("voc.common.reset")}
        </NxpButton>
        <NxpButton onClick={handleSave}> {t("voc.common.save")} </NxpButton>
      </div>
    </AccessVisible>
  );
};

export default ManageRolePage;
