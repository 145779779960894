import React from "react";
import { useTranslation } from "react-i18next";

import AppCard from "../../../app/components/app-card/AppCard";
import AppDataList from "../../../app/components/app-data-list/AppDataList";
import { DataListItem } from "../../../app/components/app-data-list/types";
import { VocCustomUi, VocEvent } from "../../../app/types";

import UpperCards from "./upper-cards";
import "./SummaryTab.scss";
import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";
import NotifyButton from "./notify/NotifyButton";
import TasksContainer from "./tasks/TasksContainer";

interface SummaryTabLayoutProps {
  event: VocEvent;
  customUi: VocCustomUi;
  refFileData: DataListItem[];
  setRefFileData: any;
  setRefFiles: any;
  saveRefFiles: Function;
  cancelRefFile: Function;
  addRefFile: Function;
  onEventRefresh: () => void;
  isEditingReference: boolean;
  setIsEditingReference: React.Dispatch<React.SetStateAction<boolean>>;
}

const SummaryTabLayout: React.FC<SummaryTabLayoutProps> = (props) => {
  const {
    event,
    customUi,
    refFileData,
    setRefFileData,
    setRefFiles,
    saveRefFiles,
    cancelRefFile,
    addRefFile,
    onEventRefresh,
    isEditingReference,
    setIsEditingReference,
  } = props;
  const { t } = useTranslation();
  return (
    <div className="summary-container">
      <AccessVisible objectCode="compensation_event" actionType="edit">
        <NotifyButton />
      </AccessVisible>
      <UpperCards
        onEventRefresh={onEventRefresh}
        vocEvent={event}
        customUi={customUi}
      />
      <AppCard
        title={t("voc.eventPage.summary.refFileUploaded")}
        canEdit={true}
        isEditing={isEditingReference}
        editCallback={() => setIsEditingReference(!isEditingReference)}
        cancelCallback={() => {
          cancelRefFile();
          setIsEditingReference(false);
        }}
        saveCallback={() => {
          saveRefFiles();
        }}
        addCallback={() => addRefFile()}
        addCallbackShortcut={true}
        // permissions={'compensation event:edit'}
      >
        <AppDataList
          editing={isEditingReference}
          canDeleteLastItem={true}
          columns={[
            {
              columnName: t("voc.eventPage.summary.subject"),
              columnWidthClassName: "fifth",
              columnDataType: "string",
              editable: true,
            },
            {
              columnName: t("voc.eventPage.summary.documentDate"),
              columnWidthClassName: "fifth",
              columnDataType: "date",
              editable: true,
            },
            {
              columnName: t("voc.eventPage.summary.viewFile"),
              columnWidthClassName: "fifth",
              columnDataType: "file",
              editable: true,
            },
            {
              columnName: t("voc.eventPage.summary.externalLink"),
              columnWidthClassName: "fifth",
              columnDataType: "link",
              editable: true,
            },
            {
              columnName: t("voc.eventPage.summary.comment"),
              columnWidthClassName: "fifth",
              columnDataType: "string",
              editable: true,
            },
          ]}
          items={refFileData}
          editSetStateHook={setRefFileData}
          setFiles={setRefFiles}
          labelIfEmpty={t("voc.common.noRecordsAvailable")}
        ></AppDataList>
      </AppCard>
      <TasksContainer />
    </div>
  );
};

export default SummaryTabLayout;
