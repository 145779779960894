import React from "react";

import classNames from "classnames";
import { TextField } from "office-ui-fabric-react";

import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";
import { VocCustomUiLayoutItemType } from "../../../app/types";

import "./QuestionGroupCaption.scss";

interface QuestionGroupCaptionProps {
  caption: string;
  isRequired: boolean;
  display: boolean;
  locked: boolean;
  isRenaming: boolean;
  allowHide: boolean;
  layoutItemType: VocCustomUiLayoutItemType;
  onRenameChange: (value: string) => void;
  onDisplayChange: () => void;
  onLockChange: () => void;
}

const QuestionGroupCaption: React.FC<QuestionGroupCaptionProps> = ({
  caption,
  isRequired,
  display,
  locked,
  isRenaming,
  allowHide,
  layoutItemType,
  onRenameChange,
  onDisplayChange,
  onLockChange,
}) => {
  const getDisplayLockButton = (accessAllowed: boolean) => {
    if (accessAllowed) {
      return (
        <>
          <button
            onClick={onDisplayChange}
            className={classNames("display", !display && "hide")}
          />
          <button
            onClick={onLockChange}
            className={classNames("lock", locked && "locked")}
          />
        </>
      );
    }

    return (
      <>
        <button
          onClick={onDisplayChange}
          disabled={locked}
          className={classNames("display", !display && "hide")}
        />
        {locked && <i className="icon-locked" />}
      </>
    );
  };

  return (
    <div className="voc-customization-question-groups-caption">
      {isRenaming &&
      layoutItemType === VocCustomUiLayoutItemType.QuestionListPresetField ? (
        <AccessVisible
          objectCode="customizations"
          actionType="admin"
          fallback={<TextField value={caption} disabled={true} />}
        >
          <TextField
            value={caption}
            onChange={(e, value) => onRenameChange(value)}
          />
        </AccessVisible>
      ) : (
        caption
      )}
      {isRequired && !isRenaming && <span className="required">*</span>}
      {isRenaming || !allowHide || (
        <AccessVisible
          objectCode="customizations"
          actionType="admin"
          fallback={getDisplayLockButton(false)}
        >
          {getDisplayLockButton(true)}
        </AccessVisible>
      )}
    </div>
  );
};

export default QuestionGroupCaption;
