import {
  DeleteOutlined,
  LinkOutlined,
  LockFilled,
  UploadOutlined,
} from "@ant-design/icons";
import {
  notify,
  NxpButton,
  NxpButtonDeleteConfirm,
  NxpPanel,
  NxpTable,
  NxpTableColumnProps,
  NxpTooltip,
  sorterForDate,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Button, Input, TreeSelect, Upload } from "antd";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import useAppContext from "../../../../../app/hooks/useAppContext";
import { upload } from "../../../../../app/services/file";
import {
  VocSupportDocument,
  VocSupportDocumentEnums,
} from "../../../../../app/types";
import { convertToTreeSelectOptions } from "../../../../../app/utils/location";
import { getUserDisplayDateFormat } from "../../../../../app/utils/mappers";
import {
  deleteSupportDocumentsById,
  editEventSupportDocuments,
  editSiteEventSupportDocuments,
} from "../document-form-services";
import {
  isRowDisplayOnly,
  relatedEventDisplay,
} from "../helpers/supporting-helper";

interface DrawingsPanelProps {
  drawings: VocSupportDocument[];
  onRefresh: () => void;
  onSetIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

const useStyles = createUseStyles({
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
});

const DrawingsPanel: React.FC<DrawingsPanelProps> = ({
  drawings,
  onRefresh,
  onSetIsLoading,
  isLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { eventId, siteEventId } = useParams();

  const { serviceConfig, errorHandler, getDictionary } = useAppContext();

  const [editing, setEditing] = useState(false);
  const [formState, setFormState] = useState<VocSupportDocument[]>([]);

  const handleClickEdit = () => {
    setFormState([...drawings]);
    setEditing(true);
  };

  const handleClickCancel = () => {
    setEditing(false);
    setFormState([]);
  };

  const deleteDocument = useCallback(
    async (record: VocSupportDocument) => {
      const idToUse = siteEventId || eventId;
      const eventType = siteEventId ? "site_events" : "events";
      try {
        await deleteSupportDocumentsById(
          idToUse,
          record.id,
          eventType,
          serviceConfig
        );
        setEditing(false);
        setFormState([]);
        notify.actionCompleted();
        onRefresh();
      } catch (error) {
        errorHandler(error, "delete support document");
      }
    },
    [errorHandler, eventId, onRefresh, serviceConfig, siteEventId]
  );

  const handleSave = async () => {
    if (!formState) return;

    onSetIsLoading(true);
    const updatedDrawings = formState.map((item) => ({
      details: { ...item.details },
      documentFiles: item.documentFiles.map((file) => ({
        assetId: file.assetId,
        fileName: file.fileName,
        mimeType: file.mimeType,
        url: file.url,
      })),
      documentType: item.documentType,
      id: item.id,
    }));

    const isSiteEvent = Boolean(siteEventId);
    try {
      if (isSiteEvent) {
        await editSiteEventSupportDocuments(siteEventId, serviceConfig, {
          documentType: VocSupportDocumentEnums.Drawings,
          documents: updatedDrawings,
        });
      } else {
        await editEventSupportDocuments(eventId, serviceConfig, {
          documentType: VocSupportDocumentEnums.Drawings,
          documents: updatedDrawings,
        });
      }
      setEditing(false);
      setFormState([]);
      notify.actionCompleted();
      onRefresh();
    } catch (error) {
      errorHandler(error, "save Drawings");
    } finally {
      onSetIsLoading(false);
    }
  };

  const handleRemoveFile = useCallback(
    (record) => {
      if (!formState) return;

      const updatedFormState = formState.map((item) =>
        item.id === record.id ? { ...item, documentFiles: [] } : item
      );
      setFormState(updatedFormState);
    },
    [formState]
  );

  const handleFileChange = useCallback(
    async (file, record) => {
      try {
        const { assetId, url } = await upload(file, serviceConfig);
        const newFile = {
          assetId,
          url,
          fileName: file.name,
          mimeType: file.type,
        };

        if (!formState) return;

        const updatedFormState = formState.map((item) =>
          item.id === record.id
            ? {
                ...item,
                documentFiles: [
                  {
                    assetId: newFile.assetId,
                    fileName: file.name,
                    url: newFile.url,
                    mimeType: file.type,
                  },
                ],
              }
            : item
        );
        setFormState(updatedFormState);
      } catch (error) {
        errorHandler(error, "upload file");
      }
    },
    [serviceConfig, formState, errorHandler]
  );

  const handleInputChange = useCallback(
    (id: string | number, field: string, value: unknown) => {
      const newRecords = formState.map((item) => {
        if (item.id === id) {
          if (field in item.details) {
            return {
              ...item,
              details: {
                ...item.details,
                [field]: value,
              },
            };
          } else {
            return {
              ...item,
              [field]: value,
            };
          }
        }
        return item;
      });
      setFormState(newRecords);
    },
    [formState]
  );

  const columns: NxpTableColumnProps<VocSupportDocument>[] = useMemo(
    () => [
      {
        title: "DRAWING NUMBER",
        dataIndex: ["details", "drawingNumber"],
        key: "subject",
        width: "8%",
        sorter: (a, b) =>
          sorterForString(a.details.drawingNumber, b.details.drawingNumber),
        render: (text, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <Input
                type="text"
                value={record.details.drawingNumber}
                onChange={(e) =>
                  handleInputChange(record.id, "drawingNumber", e.target.value)
                }
              />
            );
          } else {
            return text;
          }
        },
      },
      {
        title: "REVISION",
        dataIndex: ["details", "revision"],
        key: "revision",
        width: "8%",
        sorter: (a, b) =>
          sorterForString(a.details.revision, b.details.revision),
        render: (text, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <Input
                type="text"
                value={record.details.revision}
                onChange={(e) =>
                  handleInputChange(record.id, "revision", e.target.value)
                }
              />
            );
          } else {
            return text;
          }
        },
      },
      {
        title: "title",
        dataIndex: ["details", "title"],
        key: "title",
        width: "8%",
        sorter: (a, b) => sorterForString(a.details.title, b.details.title),
        render: (text, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <Input
                type="text"
                value={record.details.title}
                onChange={(e) =>
                  handleInputChange(record.id, "title", e.target.value)
                }
              />
            );
          } else {
            return text;
          }
        },
      },
      {
        title: t("voc.feedback.date"),
        dataIndex: ["details", "documentDate"],
        key: "documentDate",
        width: "8%",
        sorter: (a, b) =>
          sorterForDate(a.details.documentDate, b.details.documentDate),
        extraFormatOptions: {
          type: "date",
          format: getUserDisplayDateFormat(),
        },
      },
      {
        title: t("voc.eventPage.supporting.relatedEvent"),
        dataIndex: ["details", "relatedEvent"],
        key: "relatedEvent",
        width: "8%",
        sorter: (a, b) =>
          sorterForString(
            relatedEventDisplay(
              a.compensationEventSerial,
              a.compensationEventTitle,
              a.siteEventSerial,
              a.siteEventTitle
            ),
            relatedEventDisplay(
              b.compensationEventSerial,
              b.compensationEventTitle,
              b.siteEventSerial,
              b.siteEventTitle
            )
          ),
        render: (text, record) => {
          return (
            <Button
              type="link"
              onClick={
                !record.siteEventId
                  ? () =>
                      window.open(`./../compensation-events/${record.eventId}`)
                  : () => window.open(`./../site-events/${record.siteEventId}`)
              }
            >
              {relatedEventDisplay(
                record.compensationEventSerial,
                record.compensationEventTitle,
                record.siteEventSerial,
                record.siteEventTitle
              )}
            </Button>
          );
        },
      },
      {
        title: "ORIGINATOR",
        dataIndex: ["details", "originator"],
        key: "ORIGINATOR",
        width: "8%",
        render: (text, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <Input
                type="text"
                value={record.details.originator}
                onChange={(e) =>
                  handleInputChange(record.id, "originator", e.target.value)
                }
              />
            );
          } else {
            return text;
          }
        },
      },
      {
        title: t("voc.siteEventDetails.location"),
        dataIndex: "locations",
        key: "locations",
        width: "8%",
        render: (locations, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <TreeSelect
                allowClear
                showSearch
                multiple
                style={{ width: "100%" }}
                treeData={convertToTreeSelectOptions(
                  getDictionary("LOCATION")?.members
                )}
                value={record.details.location}
                onChange={(value) => {
                  handleInputChange(record.id, "location", value);
                }}
              ></TreeSelect>
            );
          } else {
            return record.details.location
              ?.map((location) => location)
              .join(", ");
          }
        },
      },
      {
        title: t("voc.eventPage.supporting.file"),
        dataIndex: "file",
        key: "file",
        width: "8%",
        render: (file, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            switch (record.documentFiles?.length) {
              case 0:
                return (
                  <Upload
                    showUploadList={false}
                    beforeUpload={(file) => {
                      handleFileChange(file, record);
                      return false;
                    }}
                  >
                    <Button icon={<UploadOutlined />} />
                  </Upload>
                );
              case 1:
                return (
                  <Upload
                    fileList={record.documentFiles.map((file) => ({
                      uid: file.assetId,
                      name: file.fileName,
                      status: "done",
                      url: file.url,
                    }))}
                    onRemove={() => handleRemoveFile(record)}
                  >
                    {record?.documentFiles.length < 1 && (
                      <Button icon={<UploadOutlined />} />
                    )}
                  </Upload>
                );
              default:
                return null;
            }
          } else {
            return record.documentFiles && record.documentFiles.length > 0 ? (
              <a
                href={record.documentFiles[0].url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {record.documentFiles[0]?.fileName}
              </a>
            ) : null;
          }
        },
      },
      {
        title: t("voc.eventPage.supporting.link"),
        dataIndex: ["details", "link"],
        key: "link",
        width: "8%",
        render: (link, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            return (
              <Input
                type="text"
                value={record.details.link}
                onChange={(e) =>
                  handleInputChange(record.id, "link", e.target.value)
                }
              />
            );
          } else {
            const getClickableLink = () => {
              return record.details.link.startsWith("http://") ||
                record.details.link.startsWith("https://")
                ? record.details.link
                : `https://${link}`;
            };
            return record.details.link ? (
              <NxpTooltip title={record.details.link}>
                <a
                  href={getClickableLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkOutlined />
                </a>
              </NxpTooltip>
            ) : null;
          }
        },
      },
    ],
    [
      editing,
      eventId,
      getDictionary,
      handleFileChange,
      handleInputChange,
      handleRemoveFile,
      t,
    ]
  );

  if (editing) {
    columns.unshift({
      title: t("voc.appDataListComponent.delete"),
      key: "delete",
      width: "3%",
      render: (text, record) => {
        if (isRowDisplayOnly(record, eventId)) {
          return (
            <NxpTooltip
              title={`${
                t("voc.eventPage.supporting.siteDocStatus.editInSiteEvent") +
                record.siteEventSerial
              }`}
            >
              <LockFilled />
            </NxpTooltip>
          );
        }
        return (
          <NxpButtonDeleteConfirm
            type="link"
            danger
            icon={<DeleteOutlined />}
            onConfirm={() => deleteDocument(record)}
          />
        );
      },
    });
  }

  return (
    <NxpPanel
      titleContent={`${t("voc.eventPage.supporting.siteRecords.drawings")} (${
        drawings?.length
      })`}
      defaultCollapsed
    >
      <div className={classes.buttonsContainer}>
        {!editing && (
          <NxpButton loading={isLoading} onClick={handleClickEdit}>
            {t("voc.common.edit")}
          </NxpButton>
        )}
        {editing && (
          <>
            <NxpButton
              loading={isLoading}
              type="default"
              onClick={handleClickCancel}
            >
              {t("voc.common.cancel")}
            </NxpButton>
            <NxpButton loading={isLoading} onClick={handleSave}>
              {t("voc.common.save")}
            </NxpButton>
          </>
        )}
      </div>
      <NxpTable
        loading={isLoading}
        columns={columns}
        dataSource={editing && formState ? formState : drawings}
      />
    </NxpPanel>
  );
};

export default DrawingsPanel;
