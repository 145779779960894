import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router";

import {
  AppstoreAddOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  NxpHeader,
  NxpLeftDrawerWrapper,
  NxpMenu,
} from "@nexploretechnology/nxp-ui";
import { Button, Drawer, Menu } from "antd";

import TableListing from "../../components/TableListing";

const useStyles = createUseStyles((theme) => ({
  demoLeftDrawer: {},
}));

interface DemoLeftDrawerProps {}

const DemoLeftDrawer: React.FC<DemoLeftDrawerProps> = () => {
  const classes = useStyles();

  const [visible, setVisible] = useState(true);
  const [childrenDrawer, setChildrenDrawer] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const navigate = useNavigate();

  return (
    <div className={classes.demoLeftDrawer}>
      <NxpLeftDrawerWrapper
        defaultDrawerWidth={200}
        drawerContent={
          <>
            <b>Menu (menuNodes array)</b>
            <NxpMenu
              defaultSelectedKeys={["1.3.1"]}
              mode="inline"
              menuNodes={[
                {
                  nodeType: "submenu",
                  nodeTitle: (
                    <span>
                      <MailOutlined />
                      <span>Navigation Sub 1</span>
                    </span>
                  ),
                  nodeKey: "1",
                  childNodes: [
                    {
                      nodeType: "menu-item",
                      nodeTitle: (
                        <span>
                          <MailOutlined />
                          <span>Navigation Item 1.1</span>
                        </span>
                      ),
                      nodeKey: "1.1",
                      onClick: (menuInfo) =>
                        console.log("MMMMMMMMMMMMMMM", menuInfo),
                    },
                    {
                      nodeType: "menu-item",
                      nodeTitle: (
                        <span>
                          <MailOutlined />
                          <span>Navigation Item 1.2</span>
                        </span>
                      ),
                      nodeKey: "1.2",
                      onClick: (menuInfo) =>
                        console.log("MMMMMMMMMMMMMMM", menuInfo),
                    },
                    {
                      nodeType: "submenu",
                      nodeTitle: (
                        <span>
                          <MailOutlined />
                          <span>Navigation Sub 1.3</span>
                        </span>
                      ),
                      nodeKey: "1.3",
                      childNodes: [
                        {
                          nodeType: "menu-item",
                          nodeTitle: "Navigation Item 1.3.1",
                          nodeKey: "1.3.1",
                          onClick: (menuInfo) =>
                            console.log("MMMMMMMMMMMMMMM", menuInfo),
                        },
                        {
                          nodeType: "menu-item",
                          nodeTitle: "Navigation Item 1.3.2",
                          nodeKey: "1.3.2",
                          onClick: (menuInfo) =>
                            console.log("MMMMMMMMMMMMMMM", menuInfo),
                        },
                      ],
                    },
                  ],
                },
                {
                  nodeType: "submenu",
                  nodeTitle: (
                    <span>
                      <MailOutlined />
                      <span>Navigation Sub 2 (with group)</span>
                    </span>
                  ),
                  nodeKey: "2",
                  childNodes: [
                    {
                      nodeType: "menu-group",
                      nodeTitle: (
                        <span>
                          <MailOutlined />
                          <span>Navigation Group 2.1</span>
                        </span>
                      ),
                      nodeKey: "2.1",
                      childNodes: [
                        {
                          nodeType: "menu-item",
                          nodeTitle: (
                            <span>
                              <MailOutlined />
                              <span>Navigation Item 2.1.1</span>
                            </span>
                          ),
                          nodeKey: "2.1.1",
                          onClick: (menuInfo) =>
                            console.log("MMMMMMMMMMMMMMM", menuInfo),
                        },
                        {
                          nodeType: "menu-item",
                          nodeTitle: (
                            <span>
                              <MailOutlined />
                              <span>Navigation Item 2.1.2</span>
                            </span>
                          ),
                          nodeKey: "2.1.2",
                          onClick: (menuInfo) =>
                            console.log("MMMMMMMMMMMMMMM", menuInfo),
                        },
                      ],
                    },
                    {
                      nodeType: "menu-group",
                      nodeTitle: (
                        <span>
                          <MailOutlined />
                          <span>Navigation Group 2.2</span>
                        </span>
                      ),
                      nodeKey: "2.2",
                      childNodes: [
                        {
                          nodeType: "menu-item",
                          nodeTitle: "Navigation Item 2.2.1",
                          nodeKey: "2.2.1",
                          onClick: (menuInfo) =>
                            console.log("MMMMMMMMMMMMMMM", menuInfo),
                        },
                        {
                          nodeType: "menu-item",
                          nodeTitle: "Navigation Item 2.2.2",
                          nodeKey: "2.2.2",
                          onClick: (menuInfo) =>
                            console.log("MMMMMMMMMMMMMMM", menuInfo),
                        },
                      ],
                    },
                  ],
                },
              ]}
            />

            <b>Menu (jsx nodes)</b>
            <NxpMenu
              defaultSelectedKeys={["8"]}
              defaultOpenKeys={["sub2", "sub3", "8"]}
              mode="inline"
            >
              <Menu.SubMenu
                key="sub1"
                title={
                  <span>
                    <MailOutlined />
                    <span>Navigation One</span>
                  </span>
                }
              >
                <Menu.ItemGroup
                  key="g1"
                  title={
                    <span>
                      <MailOutlined />
                      <span>Navigation One.1</span>
                    </span>
                  }
                >
                  <Menu.Item key="1">
                    <span>
                      <MailOutlined />
                      <span>Navigation One.1.1</span>
                    </span>
                  </Menu.Item>
                  <Menu.Item key="2">Option 2</Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup key="g2" title="Item 22">
                  <Menu.Item key="3">Option 3</Menu.Item>
                  <Menu.Item key="4">Option 4</Menu.Item>
                </Menu.ItemGroup>
              </Menu.SubMenu>
              <Menu.SubMenu
                key="sub2"
                title={
                  <span>
                    <AppstoreAddOutlined type="appstore" />
                    <span>Navigation Two</span>
                  </span>
                }
              >
                <Menu.Item key="5">Option 5</Menu.Item>
                <Menu.Item key="6">Option 6</Menu.Item>
                <Menu.SubMenu key="sub3" title="Submenu">
                  <Menu.Item key="7">Option 7</Menu.Item>
                  <Menu.Item key="8">Option 8</Menu.Item>
                </Menu.SubMenu>
              </Menu.SubMenu>
              <Menu.SubMenu
                key="sub4"
                title={
                  <span>
                    <SettingOutlined type="setting" />
                    <span>Navigation Three</span>
                  </span>
                }
              >
                <Menu.Item key="9">Option 9</Menu.Item>
                <Menu.Item key="10">Option 10</Menu.Item>
                <Menu.Item key="11">Option 11</Menu.Item>
                <Menu.Item key="12">Option 12</Menu.Item>
              </Menu.SubMenu>
            </NxpMenu>
          </>
        }
      >
        <NxpHeader
          titleContent="Demo Splitter"
          onBackClick={() => navigate(-1)}
        />
        123demo Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
        minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
        ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
        <br />
        <TableListing />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br /> <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br /> <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        demo
        <br />
        <Drawer
          title="Multi-level drawer"
          width={520}
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <Button type="primary" onClick={showChildrenDrawer}>
            Two-level drawer
          </Button>
          <Drawer
            title="Two-level Drawer"
            width={320}
            closable={false}
            onClose={onChildrenDrawerClose}
            visible={childrenDrawer}
          >
            This is two-level drawer
          </Drawer>
        </Drawer>
      </NxpLeftDrawerWrapper>
    </div>
  );
};

export default DemoLeftDrawer;
