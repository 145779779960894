import React, { useRef, useState } from "react";

import { NxpFormItem } from "@nexploretechnology/nxp-ui";
import { Col, Form, InputRef, Row } from "antd";

import { DemoUserForm } from "./demoUserForm";

interface FormDeclarativeProps {}

const FormDeclarative: React.FC<FormDeclarativeProps> = () => {
  const inputRef = useRef<InputRef>(null);

  const [userForm, setUserForm] = useState<DemoUserForm>({
    firstName: "",
    lastName: "Chan",
    age: 18,
    gender: "m",
    skills: [],
    isDead: false,
    kids: [],
    birthday: new Date(),
  });

  return (
    <Form layout="vertical">
      <Row gutter={24}>
        <Col span={12}>
          <NxpFormItem
            controlType="input"
            label="First Name"
            help="first name too short"
            validateStatus="error"
            controlRef={inputRef}
            controlProps={{
              value: userForm.firstName,
              onChange: (e) =>
                setUserForm((prevState) => ({
                  ...prevState,
                  firstName: e.target.value,
                })),
            }}
            required
          />
        </Col>
        <Col span={12}>
          <NxpFormItem
            controlType="input"
            label="Last Name"
            controlProps={{
              value: userForm.lastName,
              onChange: (e) =>
                setUserForm((prevState) => ({
                  ...prevState,
                  lastName: e.target.value,
                })),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default FormDeclarative;
