import React, { useState } from "react";

import AppButton from "../../../../app/components/app-button";
import { VocValueRegister } from "../../../../app/types";
// import { VocValueRegister } from '../../../types';
import BatchPaymentReferenceContainer from "./BatchPaymentReferenceContainer";
import { useTranslation } from "react-i18next";

// import AppButton from '../../../components/app-button';

interface BatchPaymentReferenceButtonProps {
  valueRegisterList: VocValueRegister[];
  onRefresh: () => void;
}

const BatchPaymentReferenceButton: React.FC<
  BatchPaymentReferenceButtonProps
> = ({ valueRegisterList, onRefresh }) => {
  const [showModal, setShowModal] = useState(false);
  const handleClick = () => {
    setShowModal(true);
  };
  const { t } = useTranslation();
  const handleModalClose = () => {
    setShowModal(false);
  };
  return valueRegisterList ? (
    <>
      <AppButton
        disabled={valueRegisterList.length === 0}
        onClick={handleClick}
      >
        {t("voc.value.register.updatePaymentReference")}
      </AppButton>
      <BatchPaymentReferenceContainer
        showModal={showModal}
        valueRegiserList={valueRegisterList}
        onRefresh={onRefresh}
        onModalClose={handleModalClose}
      />
    </>
  ) : null;
};

export default BatchPaymentReferenceButton;
