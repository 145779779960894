import React from "react";
import { useTranslation } from "react-i18next";

import { IChoiceGroupOption, Icon } from "office-ui-fabric-react";

import AppButton from "../../../../app/components/app-button";
import AppFileUpload from "../../../../app/components/app-file-upload/AppFileUpload";
import {
  AppFormField,
  AppFormNumericField,
  AppFormRadioField,
  AppFormRow,
  AppFormTextField,
} from "../../../../app/components/app-form";
import AppModal from "../../../../app/components/app-modal";
import { AsyncResult } from "../../../../app/hooks/useAsync";
import { VocClaim } from "../../../../app/types";
import CustomApiError from "../../../../app/utils/backend/custom-api-error";

import "./AddClaimModal.scss";

interface AddClaimModalProps {
  entityId: string;
  showAddClaimModal: boolean;
  form: Partial<VocClaim>;
  createClaimAsyncResult: AsyncResult<VocClaim>;
  validationError: any;
  onFormChange: (changeValues: Partial<VocClaim>) => void;
  onSave: () => void;
  onModalClose: () => void;
}

const yesNoOptions: IChoiceGroupOption[] = [
  {
    key: "true",
    text: "yes", //no easy way to inject localeContext here...
  },
  {
    key: "false",
    text: "no", //no easy way to inject localeContext here...
  },
];

const AddClaimModal: React.FC<AddClaimModalProps> = ({
  showAddClaimModal,
  form,
  createClaimAsyncResult,
  validationError,
  onFormChange,
  onSave,
  onModalClose,
}) => {
  const { t } = useTranslation();
  const inputErrors = {
    ...validationError,
    ...(createClaimAsyncResult.error as CustomApiError)?.serverResponseData,
  };
  return (
    <AppModal
      className="voc-event-claim-modal"
      isOpen={showAddClaimModal}
      title={t("voc.common.addClaim")}
      onClose={onModalClose}
      loading={createClaimAsyncResult.loading}
      footer={
        <AppButton
          className="button"
          onClick={onSave}
          text={t("voc.common.save")}
        />
      }
      errorContent={
        !(createClaimAsyncResult.error as CustomApiError)?.serverResponseData &&
        createClaimAsyncResult.error?.message
      }
    >
      {/* <AppFormRow>
        <AppFormDropdownField
          label="Document Type"
          required={true}
          placeholder="Please select"
          options={Object.entries(VocEventSupportDocumentType).map(entry => ({
            text: entry[0].replace(/_/g, ' '),
            key: entry[1],
          }))}
          value={
            form.documentType?.value === undefined
              ? null
              : form.documentType.value
          }
          errorMessage={inputErrors && inputErrors.documentType}
          onChange={(_, option) => {
            if (firstLoadRef.current) {
              // for fixing strange fluent-ui bug on Document Type dropdown
              firstLoadRef.current = false;
              return;
            }
            onFormChange({
              documentType: {
                label: undefined,
                value: option.key as VocEventSupportDocumentType | null,
              },
            });
          }}
        />
      </AppFormRow> */}
      <AppFormRow>
        <AppFormTextField
          label={t("voc.eventPage.claims.claimTitle")}
          name="title"
          value={form.title}
          errorMessage={inputErrors && inputErrors.title}
          onChange={(_, value) => onFormChange({ title: value })}
        />
      </AppFormRow>
      <AppFormRow>
        <AppFormNumericField
          // required={true} // VC-303: title field is optional
          label={t("voc.common.appliedDays")}
          name="appliedDays"
          decimalPlaces={0}
          value={form.appliedDays}
          errorMessage={inputErrors && inputErrors.appliedDays}
          onValueChange={(value) => {
            onFormChange({ appliedDays: value });
          }}
        />
        <AppFormNumericField
          // required={true} // VC-303: title field is optional
          label={t("voc.common.targetDays")}
          name="targetDays"
          decimalPlaces={0}
          value={form.targetDays}
          errorMessage={inputErrors && inputErrors.targetDays}
          onValueChange={(value) => {
            onFormChange({ targetDays: value });
          }}
        />
      </AppFormRow>
      <AppFormRow>
        <AppFormNumericField
          // required={true} // VC-303: title field is optional
          label={t("voc.common.accessedDays")}
          name="awardedDays"
          decimalPlaces={0}
          value={form.awardedDays}
          errorMessage={inputErrors && inputErrors.awardedDays}
          onValueChange={(value) => {
            onFormChange({ awardedDays: value });
          }}
        />
        <AppFormRadioField
          options={yesNoOptions}
          selectedKey={String(form.agreeQuantumQty)}
          onChange={(_: React.FormEvent, option: IChoiceGroupOption) =>
            onFormChange({ agreeQuantumQty: option.key === "true" })
          }
          label={t("voc.eventPage.claims.agreementToQuantumQuantities")}
        />
      </AppFormRow>
      <AppFormRow>
        <AppFormRadioField
          options={yesNoOptions}
          selectedKey={String(form.agreeQuantumRates)}
          onChange={(_: React.FormEvent, option: IChoiceGroupOption) =>
            onFormChange({ agreeQuantumRates: option.key === "true" })
          }
          label={t("voc.eventPage.claims.agreementToQuantumPrices")}
        />
        <AppFormRadioField
          options={yesNoOptions}
          selectedKey={String(form.agreeQuantumOhnp)}
          onChange={(_: React.FormEvent, option: IChoiceGroupOption) =>
            onFormChange({ agreeQuantumOhnp: option.key === "true" })
          }
          label={t("voc.eventPage.claims.agreementToQuantumOhp")}
        />
      </AppFormRow>
      <AppFormRow>
        <AppFormField className="file-upload">
          {form.claimSupportDocument ? (
            <>
              <label className="file-info-label">File</label>
              <p className="file-info">
                <a
                  href={form.claimSupportDocument.url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Icon iconName="PageSolid" />
                  {form.claimSupportDocument.fileName}
                </a>
                <button
                  className="remove"
                  onClick={() =>
                    onFormChange({
                      claimSupportDocument: undefined,
                    })
                  }
                >
                  X
                </button>
              </p>
            </>
          ) : (
            <AppFileUpload
              fileLimit={1}
              label="File"
              onChange={(files) => {
                if (files.length) {
                  onFormChange({
                    claimSupportDocument: {
                      url: (files[0] as any).url,
                      fileName: files[0].fileName,
                      assetId: files[0].assetId,
                      mimeType: files[0].mimeType,
                    },
                  });
                }
              }}
            />
          )}
        </AppFormField>
        <AppFormTextField
          label={t("voc.common.comment")}
          name="claimSupportDocumentComment"
          value={form.claimSupportDocumentComment}
          errorMessage={inputErrors && inputErrors.claimSupportDocumentComment}
          onChange={(_, value) =>
            onFormChange({ claimSupportDocumentComment: value })
          }
        />
      </AppFormRow>
    </AppModal>
  );
};

export default AddClaimModal;
