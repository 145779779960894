import React from "react";

import { LibrarySource } from "../../../libraries/libraries-services";
import useAppContext from "../../hooks/useAppContext";
import { AppFormDropdownField } from "./";
import { AppFormDropdownFieldProps } from "./AppFormDropdownField";

interface AppFormLibraryDropdownFieldProps
  extends Omit<AppFormDropdownFieldProps, "options"> {
  entityId: string;
  libraryKey: LibrarySource;
}

const AppFormLibraryDropdownField: React.FC<
  AppFormLibraryDropdownFieldProps
> = ({ entityId, libraryKey, ...restProps }) => {
  const { libraries } = useAppContext();

  return (
    <AppFormDropdownField
      {...restProps}
      options={
        libraries[libraryKey]?.length === undefined
          ? []
          : libraries[libraryKey].map((item) => {
              if (
                libraryKey === "LOCATION" ||
                libraryKey === "VOC_CLIENT_WITH_STATUS"
              ) {
                const locationItem = item as any;
                return {
                  key: locationItem.code,
                  text: locationItem.name,
                };
              }
              return {
                key: item.value,
                text: item.label,
              };
            })
      }
    />
  );
};

export default AppFormLibraryDropdownField;
