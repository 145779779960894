import "./QuestionGroupFieldSchemaModal.scss";

import { Checkbox } from "office-ui-fabric-react";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Mention, MentionsInput } from "react-mentions";

import { ValidationResult } from "@nexploretechnology/nxp-ui";

import AppButton, { AppButtonType } from "../../../app/components/app-button";
import {
  AppFormDropdownField,
  AppFormField,
  AppFormRow,
  AppFormTextField,
} from "../../../app/components/app-form";
import AppModal from "../../../app/components/app-modal";
import { AsyncResult } from "../../../app/hooks/useAsync";
import {
  VocCustomUiFieldDataType,
  VocCustomUiFieldSchema,
  VocCustomUiFormulaOutput,
  VocCustomUiLayoutItem,
} from "../../../app/types";
import CustomApiError from "../../../app/utils/backend/custom-api-error";

interface QuestionGroupFieldSchemaModalProps {
  showFieldSchemaModal: boolean;
  isCreating: boolean;
  form: Partial<VocCustomUiFieldSchema>;
  fieldSchemaAsyncResult: AsyncResult<VocCustomUiFieldSchema>;
  validationError: ValidationResult<VocCustomUiFieldSchema>;
  valuesFormulaFields?: VocCustomUiLayoutItem[];
  onFormChange: (changeValues: Partial<VocCustomUiFieldSchema>) => void;
  onModalClose: () => void;
  onFormSave: () => void;
}

const QuestionGroupFieldSchemaModal: React.FC<
  QuestionGroupFieldSchemaModalProps
> = ({
  showFieldSchemaModal,
  isCreating,
  form,
  validationError,
  fieldSchemaAsyncResult,
  valuesFormulaFields,
  onFormChange,
  onModalClose,
  onFormSave,
}) => {
  const { t } = useTranslation();

  const formErrors = {
    ...validationError,
    ...(fieldSchemaAsyncResult.error as CustomApiError)?.serverResponseData,
  };

  const handleDropdownDescriptionChange = (
    value: string,
    itemIndex: number
  ) => {
    onFormChange({
      validations: {
        limits: [
          ...form.validations.limits.map((item, idx) =>
            itemIndex === idx ? value : item
          ),
        ],
      },
    });
  };

  const handleAddDropdownOptionClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onFormChange({
      validations: { limits: [...form.validations.limits, ""] },
    });

    // focus new input in next tick
    const row = e.currentTarget.parentNode.parentNode;
    const isFirst = e.currentTarget.className.includes("is-first");
    setTimeout(() => {
      if (isFirst) {
        (row.childNodes[0] as Element).querySelector("input").focus();
      } else {
        (row.childNodes[1] as Element).querySelector("input").focus();
      }
    });
  };

  const handleDropdownOptionDrop = (
    e: React.DragEvent<HTMLDivElement>,
    idx?: number
  ) => {
    const options = [...form.validations.limits];
    const fromIndex = Number(e.dataTransfer.getData("fromIndex"));
    // eslint-disable-next-line eqeqeq
    if (idx != fromIndex) {
      const moved = options.splice(fromIndex, 1)[0];
      if (Number.isFinite(idx)) {
        options.splice(idx, 0, moved);
      } else {
        options.push(moved);
      }

      onFormChange({
        validations: { limits: options },
      });
      e.currentTarget.className = e.currentTarget.className.replace(
        / drag-over/g,
        ""
      );
    }
  };

  const handleDropdownOptionDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    idx: number
  ) => {
    e.dataTransfer.setData("fromIndex", idx.toString());
    if (!e.currentTarget.className.includes(" dragging")) {
      e.currentTarget.className += " dragging";
    }
  };

  const handleDropdownOptionDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.className = e.currentTarget.className.replace(
      / dragging/g,
      ""
    );
  };

  const handleDropdownOptionDragLeave = (
    e: React.DragEvent<HTMLDivElement>
  ) => {
    e.currentTarget.className = e.currentTarget.className.replace(
      / drag-over/g,
      ""
    );
  };

  const handleDropdownOptionDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!e.currentTarget.className.includes(" drag-over")) {
      e.currentTarget.className += " drag-over";
    }
  };

  const getAddDropdownOptionButton = () => (
    <AppFormField
      onDrop={(e: React.DragEvent<HTMLDivElement>) =>
        handleDropdownOptionDrop(e, undefined)
      }
      onDragOver={handleDropdownOptionDragOver}
      onDragLeave={handleDropdownOptionDragLeave}
    >
      <AppButton
        className="add-description"
        appButtonType={AppButtonType.Basic}
        onClick={handleAddDropdownOptionClick}
      >
        + Add Description
      </AppButton>
    </AppFormField>
  );

  const getDropdownOptionInput = (idx: number) => {
    return (
      <AppFormTextField
        draggable={true}
        onDrop={(e: React.DragEvent<HTMLDivElement>) =>
          handleDropdownOptionDrop(e, idx)
        }
        onDragStart={(e: React.DragEvent<HTMLDivElement>) =>
          handleDropdownOptionDragStart(e, idx)
        }
        onDragEnd={handleDropdownOptionDragEnd}
        onDragLeave={handleDropdownOptionDragLeave}
        onDragOver={handleDropdownOptionDragOver}
        required={!idx}
        errorMessage={!idx && formErrors && formErrors.validations}
        label={`Option ${idx + 1}`}
        value={form.validations.limits[idx]}
        onChange={(_, value) => handleDropdownDescriptionChange(value, idx)}
      />
    );
  };

  const getDropdownFields = () => {
    let count = 0;
    const rows: ReactNode[] = [];

    while (form.validations.limits.length >= count) {
      if (form.validations.limits.length > count + 1) {
        const idx = count;
        rows.push(
          <AppFormRow key={`${idx}`}>
            {getDropdownOptionInput(count)}
            {getDropdownOptionInput(count + 1)}
          </AppFormRow>
        );
      } else if (form.validations.limits.length === count + 1) {
        const idx = count;
        rows.push(
          <AppFormRow key={`${idx}`}>
            {getDropdownOptionInput(count)}
            {getAddDropdownOptionButton()}
          </AppFormRow>
        );
      } else if (form.validations.limits.length === count) {
        rows.push(
          <AppFormRow key={`${count}`}>
            {getAddDropdownOptionButton()}
            <AppFormField />
          </AppFormRow>
        );
      }
      count += 2;
    }
    return <>{rows}</>;
  };

  const handleFormulaChange = (value: string) => {
    onFormChange({
      validations: { ...form.validations, formula: value },
    });
  };

  const handleFormulaOutputFormatChange = (value: string) => {
    onFormChange({
      validations: {
        ...form.validations,
        output: value as VocCustomUiFormulaOutput,
      },
    });
  };

  const getFormulaFields = (formula: string, errorMsg: string) => {
    return (
      <>
        {" "}
        <AppFormRow
          className={`question-group-formula${errorMsg ? " error" : ""}`}
        >
          <AppFormField>
            <label>
              Formula <span className="required">*</span> (type space then @ to
              populate field selection)
            </label>
            <MentionsInput
              className="formula-mention-input"
              value={formula}
              onChange={(e) => handleFormulaChange(e.target.value)}
              allowSuggestionsAboveCursor={true}
            >
              <Mention
                className="mention"
                trigger="@"
                data={valuesFormulaFields.map((field) => ({
                  id: field.key,
                  display: (field.customName || field.name)
                    .replace(/\[/g, "(")
                    .replace(/\]/g, ")"),
                }))}
                displayTransform={(id: any, display: string) => `${display}`}
                markup={"{{__id__::__display__}}"}
              />
            </MentionsInput>
            {errorMsg && (
              <div role="alert" className="error-message">
                <p>{errorMsg}</p>
              </div>
            )}
          </AppFormField>
        </AppFormRow>
        <AppFormRow className="question-group-formula-output">
          <AppFormDropdownField
            onChange={(e, option) =>
              handleFormulaOutputFormatChange(option.key.toString())
            }
            value={form.validations.output}
            required
            label="Output Format"
            options={[
              {
                key: VocCustomUiFormulaOutput.Value,
                text: "Value - Format: #,##0.00",
              },
              {
                key: VocCustomUiFormulaOutput.Currency,
                text: "Currency - $#,##0.00",
              },
              {
                key: VocCustomUiFormulaOutput.Percentage,
                text: "Percentage - 0.00%",
              },
            ]}
          />{" "}
          <AppFormField />
        </AppFormRow>
      </>
    );
  };

  return (
    <AppModal
      title={`${
        isCreating ? t("voc.common.addField") : t("voc.common.editField")
      }`}
      className="voc-customization-question-group-modal"
      isOpen={showFieldSchemaModal}
      loading={fieldSchemaAsyncResult.loading}
      onClose={onModalClose}
      footer={
        <AppButton
          disabled={fieldSchemaAsyncResult.loading}
          className="button"
          onClick={onFormSave}
          text={t("voc.common.save")}
        />
      }
      errorContent={
        !(fieldSchemaAsyncResult.error as CustomApiError)?.serverResponseData &&
        fieldSchemaAsyncResult.error?.message
      }
    >
      <AppFormRow>
        <AppFormTextField
          required={true}
          label={t("voc.common.fieldName")}
          name="name"
          value={form.name}
          disabled={!isCreating}
          errorMessage={formErrors && formErrors.name}
          onChange={(_, value) => onFormChange({ name: value })}
        />
        <AppFormDropdownField
          disabled={!isCreating}
          required={true}
          label={t("voc.common.fieldType")}
          value={form.dataType}
          errorMessage={
            formErrors &&
            form.dataType !== VocCustomUiFieldDataType.Formula &&
            formErrors.dataType
          }
          onChange={(_, opt) =>
            onFormChange({ dataType: opt.key as VocCustomUiFieldDataType })
          }
          options={Object.entries(VocCustomUiFieldDataType)
            .filter(
              (entry) =>
                !(
                  entry[1] === VocCustomUiFieldDataType.FileUpload ||
                  entry[1] === VocCustomUiFieldDataType.ReadOnly ||
                  entry[1] === VocCustomUiFieldDataType.MultiSelect ||
                  (!valuesFormulaFields &&
                    entry[1] === VocCustomUiFieldDataType.Formula)
                )
            )
            .map((entry) => ({
              key: entry[1],
              text: entry[0].replace("YesNo", "Yes / No"),
            }))}
        />
      </AppFormRow>
      {(form.dataType === VocCustomUiFieldDataType.Dropdown &&
        getDropdownFields()) ||
        (form.dataType === VocCustomUiFieldDataType.Formula &&
          getFormulaFields(form.validations.formula, formErrors.dataType))}
      {valuesFormulaFields === undefined && (
        <AppFormRow>
          <AppFormField className="synchronise-input">
            <Checkbox
              label={t(
                "voc.admin.customization.questionGroup.synchroniseInput"
              )}
              disabled={!isCreating}
              onChange={(_, checked) => {
                onFormChange({
                  isSyncField: checked,
                });
              }}
              checked={form.isSyncField}
            />
          </AppFormField>
        </AppFormRow>
      )}
    </AppModal>
  );
};
export default QuestionGroupFieldSchemaModal;
