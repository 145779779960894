import useAppContext from "../../../../../../app/hooks/useAppContext";
import { upload } from "../../../../../../app/services/file";
import { VocEventFile } from "../../../../../../app/types";

interface FormWithFiles {
  files: VocEventFile[];
}

export function useFileHandler<T extends FormWithFiles>(
  onSetFormState: React.Dispatch<React.SetStateAction<T>>
) {
  const { serviceConfig, errorHandler } = useAppContext();

  const handleFileChange = async (file: File) => {
    try {
      const { assetId, url } = await upload(file, serviceConfig);
      const newFile = {
        assetId,
        url,
        fileName: file.name,
        mimeType: file.type,
      };
      onSetFormState((prevState) => ({
        ...prevState,
        files: [...prevState.files, newFile],
      }));
    } catch (error) {
      errorHandler(error, "upload file");
    }
  };

  const handleRemoveFile = () => {
    onSetFormState((prevState) => ({
      ...prevState,
      files: [],
    }));
  };

  return { handleFileChange, handleRemoveFile };
}
