import React from "react";
import { createUseStyles } from "react-jss";

import { NxpHolder } from "@nexploretechnology/nxp-ui";

const useStyles = createUseStyles((theme) => ({
  DemoHolder: {},
}));

interface DemoHolderProps {}

const DemoHolder: React.FC<DemoHolderProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.DemoHolder}>
      <NxpHolder>
        <p>Content inside ...</p>
        <p>Content inside ...</p>
        <p>Content inside ...</p>
      </NxpHolder>
    </div>
  );
};

export default DemoHolder;
