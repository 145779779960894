import { useEffect, useRef } from "react";

export const useDataTableWrapperResized = (pageTopOffset: number) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const setWrapperAndGridDimension = () => {
    wrapperRef.current.style.height = `${window.innerHeight - pageTopOffset}px`;
    // for "No record found" row width to fix data table
    (
      wrapperRef.current.childNodes[
        wrapperRef.current.childNodes.length - 1
      ] as HTMLElement
    ).style.width = `${wrapperRef.current.scrollWidth}px`;
  };

  useEffect(() => {
    setWrapperAndGridDimension();
    window.addEventListener("resize", setWrapperAndGridDimension);
    // window.addEventListener('scroll', setWrapperHeight);

    return () => {
      window.removeEventListener("resize", setWrapperAndGridDimension);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return wrapperRef;
};
