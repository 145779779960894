import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { notify } from "@nexploretechnology/nxp-ui";
import { head } from "lodash";
import * as yup from "yup";

import useAppContext from "../../../../app/hooks/useAppContext";
import {
  VocRecordBatchUpdateData,
  VocRecordStatus,
} from "../../../../app/types";
import { batchUpdateSiteEventStatusRecords } from "../../../../site-events/site-event-services";
import { BatchUpdateEvent } from "../RecordsTabLayout";
import BatchUpdateModal from "./BatchUpdateModal";
import { useAsync } from "../../../../app/hooks/useAsync";
import { useValidate } from "../../../../app/hooks/useValidate";
import i18next from "i18next";
import { mapDateToString } from "../../../../app/utils/mappers";

export function getBatchUpdateSchema() {
  return yup.object().shape({
    fromDate: yup
      .date()
      .nullable()
      .required(i18next.t("voc.eventPage.records.startDateRequired")),
    toDate: yup
      .date()
      .nullable()
      .required(i18next.t("voc.eventPage.records.endDateRequired"))
      .test(
        "CompareFromDate",
        i18next.t("voc.eventPage.records.cannotBeEarlierThanFromDate"),
        function (val) {
          const ref = yup.ref("fromDate");
          return val >= this.resolve(ref);
        }
      ),
    status: yup
      .mixed<VocRecordStatus>()
      .oneOf(Object.values(VocRecordStatus))
      .nullable()
      .required(i18next.t("voc.eventPage.records.statusRequired")),
    serials: yup
      .array()
      .of(yup.string())
      .required(
        i18next.t("voc.eventPage.records.pleaseSelectEventBatchUpdate")
      ),
  });
}

interface BatchUpdateContainerProps {
  showBatchUpdateModal: boolean;
  onModalClose: () => void;
  onStatusRecordsRefresh: () => void;
  events: BatchUpdateEvent[];
}

const BatchUpdateContainer: React.FC<BatchUpdateContainerProps> = ({
  showBatchUpdateModal,
  onModalClose,
  onStatusRecordsRefresh,
  events,
}) => {
  const { t: translation } = useTranslation();
  const { serviceConfig, errorHandler } = useAppContext();

  const [form, setForm] = useState<VocRecordBatchUpdateData>(() => ({
    fromDate: new Date(),
    toDate: new Date(),
    status: undefined,
    serials: [],
  }));

  const [batchUpdateResult, setBatchUpdateRequest] = useAsync(undefined, {
    onSuccess: () => {
      notify.success(translation("voc.eventPage.records.batchUpdateCompleted"));
      onStatusRecordsRefresh();
      onModalClose();
    },
    onError(ex) {
      errorHandler(
        ex,
        translation("voc.eventPage.records.batchUpdateCompleted")
      );
    },
  });

  const saveBatchUpdate = () => {
    form.serials.forEach((s) => {
      setBatchUpdateRequest(() => {
        const target = events.filter((e) => e.serial === s);
        return batchUpdateSiteEventStatusRecords(
          target[0].eventType,
          mapDateToString(form.fromDate),
          mapDateToString(form.toDate),
          form.status,
          undefined,
          head(target).id as string,
          serviceConfig
        );
      });
    });
  };

  const [validationError, , , saveWithValidate] = useValidate<
    Partial<VocRecordBatchUpdateData>
  >(form, getBatchUpdateSchema(), saveBatchUpdate);

  const handleFormChange = React.useCallback(
    (changeValues: Partial<VocRecordBatchUpdateData>) => {
      setForm((prevState) => ({ ...prevState, ...changeValues }));
    },
    [setForm]
  );

  const handleBatchUpdate = () => {
    saveWithValidate(undefined);
  };

  return (
    <BatchUpdateModal
      showBatchUpdateModal={showBatchUpdateModal}
      form={form}
      batchUpdateResult={batchUpdateResult}
      validationError={validationError}
      onFormChange={handleFormChange}
      onBatchUpdate={handleBatchUpdate}
      onModalClose={onModalClose}
      events={events}
    />
  );
};

export default BatchUpdateContainer;
