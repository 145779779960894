import React from "react";

import { BaseButton } from "office-ui-fabric-react";

import { mapDateToMonthString } from "../../../../app/utils/mappers";

import "./MonthSelect.scss";
import { useTranslation } from "react-i18next";

const getMenuProps = (
  startMonth: Date,
  endMonth: Date,
  onMonthChange: (date?: Date) => void
) => {
  const items = [
    {
      key: "whole",
      text: "Record Period",
      onClick: () => onMonthChange(undefined),
    },
  ];

  let tmpMonth = startMonth;
  while (tmpMonth <= endMonth) {
    const text = mapDateToMonthString(tmpMonth);
    const tmpMonthClone = new Date(tmpMonth.getTime());
    items.push({
      key: text,
      text,
      onClick: () => {
        onMonthChange(tmpMonthClone);
      },
    });
    tmpMonth = new Date(tmpMonth.getFullYear(), tmpMonth.getMonth() + 1, 1);
  }

  return {
    shouldFocusOnMount: true,
    items,
  };
};

interface MonthSelectProps {
  selectedMonth?: Date;
  recordStartMonth: Date;
  recordEndMonth: Date;
  onMonthChange: (date: Date) => void;
  fixedCalendarStart?: Date;
  fixedCalendarEnd?: Date;
  now: Date;
}

const MonthSelect: React.FC<MonthSelectProps> = ({
  selectedMonth,
  recordStartMonth,
  recordEndMonth,
  onMonthChange,
  fixedCalendarStart,
  fixedCalendarEnd,
  now,
}) => {
  const { t } = useTranslation();
  const menuProps =
    (!fixedCalendarEnd &&
      recordStartMonth.getTime() === recordEndMonth.getTime()) || // no cessation and record start and end in same month
    (recordStartMonth.getTime() === recordEndMonth.getTime() &&
      recordEndMonth.getTime() ===
        new Date(now.getFullYear(), now.getMonth(), 1).getTime()) // or record start and end month is both current month
      ? undefined
      : getMenuProps(
          recordStartMonth,
          fixedCalendarEnd
            ? recordEndMonth
            : new Date(now.getFullYear(), now.getMonth(), 1),
          onMonthChange
        );
  return (
    <div className="voc-records-tab-month-select">
      <div>
        <BaseButton
          disabled={fixedCalendarStart && selectedMonth <= fixedCalendarStart}
          className="arrow-button"
          onClick={() => {
            if (selectedMonth) {
              onMonthChange(
                new Date(
                  selectedMonth.getFullYear(),
                  selectedMonth.getMonth() - 1,
                  1
                )
              );
            } else {
              onMonthChange(recordStartMonth);
            }
          }}
        >
          ◀
        </BaseButton>
        <BaseButton
          className="menu-button"
          disabled={!menuProps}
          menuProps={menuProps}
        >
          {selectedMonth
            ? mapDateToMonthString(selectedMonth)
            : t("voc.eventPage.records.recordPeriod")}
        </BaseButton>
        <BaseButton
          disabled={
            fixedCalendarEnd &&
            selectedMonth &&
            fixedCalendarEnd.getFullYear() === selectedMonth.getFullYear() &&
            fixedCalendarEnd.getMonth() === selectedMonth.getMonth()
          }
          className="arrow-button"
          onClick={() => {
            if (selectedMonth) {
              onMonthChange(
                new Date(
                  selectedMonth.getFullYear(),
                  selectedMonth.getMonth() + 1,
                  1
                )
              );
            } else {
              onMonthChange(recordEndMonth);
            }
          }}
        >
          ▶
        </BaseButton>
      </div>
    </div>
  );
};

export default MonthSelect;
