import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ISpinnerStyleProps,
  ISpinnerStyles,
  IStyleFunctionOrObject,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import useAppContext from "../../hooks/useAppContext";
import uploadSVG from "../../images/upload.svg";
import "./AppFileUpload.scss";
import { decodeValueRegisterExcelImport } from "../../../compensation-events/compensation-event-services";
import { lettersToNumber } from "../../../compensation-events/pages/valueRegister/ImportExcel/ImportExcelContainer";
import { VocMapping, VocValueRegister } from "../../types";
import { cloneDeep } from "lodash";

interface AppFileUploaderProps {
  label: string;
  onChange: (files: Partial<AttachmentParams>[], ref?: any) => void;
  spinnerStyles?: IStyleFunctionOrObject<ISpinnerStyleProps, ISpinnerStyles>;
  fileLimit?: number;
  fileRef?: any;
  customUploadPath?: string;
  stage: number;
  refColumn?: string;
  fieldsToMap?: VocMapping[];
  setFile?: React.Dispatch<React.SetStateAction<File>>;
  setDecodedValues: React.Dispatch<
    React.SetStateAction<Partial<VocValueRegister>[]>
  >;
  setAggregateValues: React.Dispatch<
    React.SetStateAction<Partial<VocValueRegister>>
  >;
}

export interface AttachmentParams {
  id: number;
  createdAt: Date;
  remarkId: number;
  assetId: string;
  fileName: string;
  mimeType: string;
  url?: string;
}

export interface Attachment extends Partial<AttachmentParams> {
  url: string;
}

const ValueExcelUpload: React.FC<AppFileUploaderProps> = (props) => {
  const { t } = useTranslation();
  const {
    label,
    spinnerStyles,
    stage,
    refColumn,
    fieldsToMap,
    setFile,
    setDecodedValues,
    setAggregateValues,
  } = props;
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const previewList = React.useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { serviceConfig } = useAppContext();
  const [excelFile, setExcelFile] = useState<File>();

  const calculateValues = (requests: any[]) => {
    const requestCopy = cloneDeep(requests);
    requestCopy.forEach((r) => {
      if (r.internalTarget && r.submittedValue) {
        r.recovery = r.internalTarget / r.submittedValue;
      }
      if (r.internalTarget && r.clientAssessed) {
        r.pendingApproval = r.internalTarget - r.clientAssessed;
      }
      if (r.cumulativeAppliedNominal && r.internalTarget) {
        r.cumulativeApplied = r.cumulativeAppliedNominal / r.internalTarget;
      }
      if (r.internalTarget && r.eisA2A6 && r.includedInReports === true) {
        r.eisA8A14 = r.internalTarget - r.eisA2A6;
      }
      if (r.directCost || r.indirectCost || r.costGnA || r.costCloseout) {
        r.totalCost =
          (r.directCost ? r.directCost : 0) +
          (r.indirectCost ? r.indirectCost : 0) +
          (r.costGnA ? r.costGnA : 0) +
          (r.costCloseout ? r.costCloseout : 0);
      }
      if (r.internalTarget && r.totalCost) {
        r.profitLoss = r.internalTarget - r.totalCost;
      }
      if (
        r.costGnA &&
        r.profitLoss &&
        r.totalCost &&
        r.totalCost !== r.costGnA
      ) {
        r.ohnp = (r.costGnA + r.profitLoss) / (r.totalCost - r.costGnA);
      }
    });
    return requestCopy;
  };

  const startUploadFile = React.useCallback(() => {
    const fileInput = fileInputRef.current;
    if (fileInput && !isLoading) {
      fileInput.click();
    }
  }, [isLoading]);

  useEffect(() => {
    if (stage === 2) {
      const mappings: any = {};
      fieldsToMap.forEach((f) => {
        mappings[f.field] = lettersToNumber(f.column);
      });
      decodeValueRegisterExcelImport(
        serviceConfig,
        {
          compensationEventSerial: lettersToNumber(refColumn),
          ...mappings,
        },
        excelFile
      ).then((data) => {
        setDecodedValues(calculateValues(data.requests));
        setAggregateValues(data.aggregatedValues);
      });
    }
  }, [
    excelFile,
    fieldsToMap,
    refColumn,
    serviceConfig,
    setAggregateValues,
    setDecodedValues,
    stage,
  ]);

  const onFileInputChange = React.useCallback(
    async (e) => {
      const fileInput = fileInputRef.current;
      if (!fileInput || fileInput.files.length <= 0) return;
      setIsLoading(true);
      try {
        setExcelFile(e.target.files[0]);
        setFile(e.target.files[0]);
      } finally {
        setIsLoading(false);
        fileInput.value = "";
      }
    },
    [setFile]
  );

  return (
    <div className="file-uploader-container">
      <label className="label">{label}</label>
      <div className="preview-list-wrapper">
        <div ref={previewList} className="preview-list">
          <div className="uploader" onClick={startUploadFile}>
            {isLoading ? (
              <Spinner
                className="file-uploader-spinner"
                size={SpinnerSize.small}
                styles={spinnerStyles}
              />
            ) : (
              <img src={uploadSVG} alt="" />
            )}
            <span>{t("voc.common.upload")}</span>
          </div>
        </div>
      </div>
      <input
        hidden={true}
        type="file"
        ref={fileInputRef}
        accept=".csv,"
        multiple={false}
        onChange={onFileInputChange}
      />
    </div>
  );
};

export default ValueExcelUpload;
