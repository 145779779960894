import React from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

import useAppContext from "../../../app/hooks/useAppContext";
import { useAsync } from "../../../app/hooks/useAsync";
import { EntityUser, getUserDisplayName } from "../../../app/services/app";
import useVocServiceAsync from "../../../app/services/useVocServiceAsync";
import { VocService } from "../../../app/services/vocService";
import {
  VocChatChannel,
  VocChatMention,
  VocChatMessage,
  VocGetChats,
  VocPostChatRequest,
  VocPostChatResponse,
} from "../../../app/types";
import ChatsTabLayout from "./ChatsTabLayout";

interface ChatsTabContainerProps {}

const ChatsTabContainer: React.FC<ChatsTabContainerProps> = (props) => {
  const { serviceConfig, entityUsers } = useAppContext();
  const [chatData, setChatData] = React.useState<VocGetChats>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<VocPostChatRequest>(null);
  const [messages, setMessages] = React.useState<VocChatMessage[]>(null);
  const [posters, setPosters] = React.useState<string[]>(null);
  const [users, setUsers] = React.useState<VocChatMention[]>(null);
  const [mentions, setMentions] = React.useState<VocChatMention[]>([]);
  const [channels, setChannels] = React.useState<VocChatChannel[]>([]);
  const [selectedChannel, setSelectedChannel] = React.useState<string>(null);
  const [messagePosting, setMessagePosting] = React.useState<boolean>(false);
  let { eventId, siteEventId } = useParams();

  const [, setGetChats] = useVocServiceAsync<VocGetChats>(
    serviceConfig.entityId,
    (vocService) => {
      if (!siteEventId) {
        return vocService.getChat(serviceConfig, eventId);
      } else {
        return vocService.getSiteEventChat(siteEventId, serviceConfig);
      }
    },
    {
      onSuccess: (data) => {
        if (!chatData || messagePosting) {
          setChatData(data);
          setMessagePosting(false);
        }
        if (!selectedChannel) {
          if (!siteEventId) {
            setChannels([
              {
                eventType: "comp",
                id: data.compensationEvent.compensationEventId,
                serial: data.compensationEvent.compensationEventSerial,
                title: data.compensationEvent.eventTitle,
              },
              {
                eventType: "divider",
              },
              ...data.siteEvents.map((e) => ({
                eventType: "site",
                id: e.siteEventId,
                serial: e.siteEventSerial,
                title: e.eventTitle,
              })),
            ]);
            setSelectedChannel("comp");
          } else {
            if (data.compensationEvent) {
              setChannels([
                {
                  eventType: "site",
                  id: data.siteEvent.siteEventId,
                  serial: data.siteEvent.siteEventSerial,
                  title: data.siteEvent.eventTitle,
                },
                {
                  eventType: "divider",
                },
                {
                  eventType: "comp",
                  id: data.compensationEvent.compensationEventId,
                  serial: data.compensationEvent.compensationEventSerial,
                  title: data.compensationEvent.eventTitle,
                },
              ]);
            } else {
              setChannels([
                {
                  eventType: "site",
                  id: data.siteEvent.siteEventId,
                  serial: data.siteEvent.siteEventSerial,
                  title: data.siteEvent.eventTitle,
                },
              ]);
            }
            setSelectedChannel(String(data.siteEvent.siteEventId));
          }

          const entryChat =
            (!siteEventId
              ? data.compensationEvent.chats
              : data.siteEvent.chats) || [];

          const reversedData = [...entryChat].reverse();
          const reducePosters = reversedData.reduce((acc, curr) => {
            if (!acc.includes(curr.author.id)) {
              acc.push(curr.author.id);
            }
            return acc;
          }, []);
          setPosters(reducePosters);
          setMessages(entryChat.reverse());
        } else {
          let selectedChat = null;
          if (selectedChannel === "comp") {
            selectedChat = data.compensationEvent.chats;
          } else {
            if (siteEventId) {
              selectedChat = data.siteEvent.chats;
            } else {
              selectedChat = data.siteEvents.filter(
                (e) => e.siteEventId === selectedChannel
              )[0].chats;
            }
          }
          const reversedData = [...selectedChat].reverse();
          const reducePosters = reversedData.reduce((acc, curr) => {
            if (!acc.includes(curr.author.id)) {
              acc.push(curr.author.id);
            }
            return acc;
          }, []);
          setPosters(reducePosters);
          setMessages(selectedChat.reverse());
          setSelectedChannel(
            selectedChannel === "comp" ? "comp" : String(selectedChannel)
          );
        }

        setLoaded(true);
      },
    }
  );

  function changeChannel(eventType: string, id: string) {
    if (eventType === selectedChannel || String(id) === selectedChannel) {
      return;
    }
    let selectedChat = null;
    if (eventType === "comp") {
      selectedChat = chatData.compensationEvent.chats;
    }
    if (eventType === "site") {
      if (siteEventId) {
        selectedChat = chatData.siteEvent.chats;
      } else {
        selectedChat = chatData.siteEvents.filter(
          (e) => e.siteEventId === id
        )[0].chats;
      }
    }
    const reversedData = [...selectedChat].reverse();
    const reducePosters = reversedData.reduce((acc, curr) => {
      if (!acc.includes(curr.author.id)) {
        acc.push(curr.author.id);
      }
      return acc;
    }, []);
    setPosters(reducePosters);
    setMessages(selectedChat.reverse());
    setSelectedChannel(eventType === "comp" ? "comp" : String(id));
  }

  const [, setGetPostResponse] = useVocServiceAsync<VocPostChatResponse>(
    serviceConfig.entityId,
    null,
    {
      onSuccess: () => {
        if (!siteEventId) {
          setGetChats((vocService: VocService) => {
            return vocService.getChat(serviceConfig, eventId);
          });
        } else {
          setGetChats((vocService: VocService) => {
            return vocService.getSiteEventChat(siteEventId, serviceConfig);
          });
        }
      },
    }
  );

  const [,] = useAsync<EntityUser[]>(() => Promise.resolve(entityUsers), {
    onSuccess: (data) => {
      setUsers(
        data.map((user) => ({
          id: user.id as string,
          display: getUserDisplayName(user),
        }))
      );
    },
  });

  const handlePostChat = React.useCallback(() => {
    setGetPostResponse((vocService: VocService) => {
      if (selectedChannel === "comp") {
        return vocService.postChat(
          serviceConfig,
          chatData.compensationEvent.compensationEventId,
          form
        );
      } else {
        return vocService.postSiteEventChat(
          selectedChannel,
          serviceConfig,
          form
        );
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  React.useEffect(() => {
    if (loaded && form) {
      handlePostChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, form]);

  return loaded ? (
    <ChatsTabLayout
      messagePosting={messagePosting}
      channels={channels}
      selectedChannel={selectedChannel}
      messages={messages?.sort(
        (a, b) => dayjs(b.createdOn).unix() - dayjs(a.createdOn).unix()
      )}
      posters={posters}
      users={users}
      mentions={mentions}
      changeChannel={changeChannel}
      setMentions={setMentions}
      setForm={setForm}
      setMessagePosted={setMessagePosting}
    />
  ) : null;
};

export default ChatsTabContainer;
