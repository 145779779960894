import { VocCustomUiQuestionListCustomField } from "../../../../app/types";

export const prepareCustomData = (
  customFields: VocCustomUiQuestionListCustomField[],
  customValues: Record<string, any>
): Record<string, any> => {
  const customsToSend = customFields.filter(
    (c) => c.dataType !== "CALCULATION"
  );
  const customFullSend: Record<string, any> = {};
  customsToSend.forEach((c) => {
    customFullSend[c.key] = customValues?.[c.key];
  });
  return customFullSend;
};
