import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router";

import { LayoutOutlined, UserOutlined } from "@ant-design/icons";
import {
  notify,
  NxpButton,
  NxpButtonDeleteConfirm,
  NxpButtonWithConfirm,
  NxpButtonWithDropdown,
  NxpHeader,
  NxpTabs,
  TabPane,
} from "@nexploretechnology/nxp-ui";
import { Menu } from "antd";

import useAppContext from "../../../app/hooks/useAppContext";
import TableListing from "../../components/TableListing";
import FormDeclarative from "./DemoFormAndPanel/FormDeclarative";
import FormGridItemArray from "./DemoFormAndPanel/FormGridItemArray";
import FormGridModal from "./DemoFormAndPanel/FormGridModal";
import FormGridPanel from "./DemoFormAndPanel/FormGridPanel";
import FormSurveyPanel from "./DemoFormAndPanel/FormSurveyPanel";
import DemoHeader from "./DemoHeaderAndTabs/DemoHeader";
import DemoHeaderAdvanced from "./DemoHeaderAndTabs/DemoHeaderAdvanced";
import DemoHeaderBreadcrumb from "./DemoHeaderAndTabs/DemoHeaderBreadcrumb";
import DemoHeaderEmbeded from "./DemoHeaderAndTabs/DemoHeaderEmbeded";
import DemoHeaderSearchbar from "./DemoHeaderAndTabs/DemoHeaderSearchbar";
import DemoHeaderTabs from "./DemoHeaderAndTabs/DemoHeaderTabs";
import DemoTabs from "./DemoHeaderAndTabs/DemoTabs";
import DemoAlerts from "./DemoMiscDisplay/DemoAlerts";
import DemoCardList from "./DemoMiscDisplay/DemoCardList";
import DemoDescriptionList from "./DemoMiscDisplay/DemoDescriptionList";
import DemoHolder from "./DemoMiscDisplay/DemoHolder";
import DemoLoadingSpin from "./DemoMiscDisplay/DemoLoadingSpin";
import DemoModalWithSteps from "./DemoMiscDisplay/DemoModalWithSteps";
import DemoModalWithSubheading from "./DemoMiscDisplay/DemoModalWithSubheading";
import DemoSteps from "./DemoMiscDisplay/DemoSteps";
import DemoToggleGroup from "./DemoMiscDisplay/DemoToggleGroup";
import DemoDatePicker from "./DemoMiscInput/DemoDatePicker";
import DemoNumberInput from "./DemoMiscInput/DemoNumberInput";
import DemoSignature from "./DemoMiscInput/DemoSignature";
import DemoTimePicker from "./DemoMiscInput/DemoTimePicker";
import DemoUpload from "./DemoMiscInput/DemoUpload";
import TableEditing from "./DemoTableEditing/TableEditable";
import TableFullForm from "./DemoTableEditing/TableFullForm";
import TableLazy from "./DemoTableListing/TableLazy";
import TableForm from "./TableForm";

const useStyles = createUseStyles((theme) => ({
  demo: {
    color: theme.palette.textLabel,
    "& h2": {
      marginTop: theme.spacing(4),
    },
  },
}));

export interface DemoProps {}

const Demo: React.FC<DemoProps> = () => {
  const navigate = useNavigate();
  const { activeUser } = useAppContext();
  const [modalVisible, setModalVisible] = useState(false);

  const handleTabChange = (key: string) => {
    console.log("tab changed tab changed tab changed", key);
  };

  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <div className={classes.demo}>
      <NxpHeader
        titleContent={t("demo.DemoPage")}
        actionContent={
          <>
            Demo Buttons:{" "}
            <NxpButton type="default" onClick={() => navigate("./splitter")}>
              Layout - Splitter
            </NxpButton>
            <NxpButton type="text" onClick={() => navigate("./left-drawer")}>
              Layout - Left Drawer Wrapper
            </NxpButton>
            <NxpButtonWithConfirm
              danger
              modalContent="Proceed to use nxp-ui?"
              onConfirm={() => notify.info("Thank you!")}
            >
              Confirm Button
            </NxpButtonWithConfirm>
            <NxpButtonDeleteConfirm
              onConfirm={() => notify.actionCompleted()}
            />
            <NxpButtonWithDropdown
              menu={
                <Menu onClick={(item) => console.log(item.key)}>
                  <Menu.Item
                    onClick={() => console.log("test 1")}
                    key="1"
                    icon={<LayoutOutlined />}
                  >
                    1st menu item
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    onClick={() => console.log("test 2")}
                    icon={<UserOutlined />}
                  >
                    2nd menu item
                  </Menu.Item>
                </Menu>
              }
            >
              Menu Button
            </NxpButtonWithDropdown>
          </>
        }
      />
      <p>
        i18n Demos -{" "}
        <span>
          {t("demo.HelloUser", {
            user: activeUser ? `${activeUser.displayName}` : "",
          })}
        </span>
        , <span>{t("demo.Greeting", { count: 1 })}</span>,
        <span>{t("demo.Greeting", { count: 5 })}</span>,
        <span>{t("demo.Greeting", { count: 100 })}</span>
      </p>

      <NxpTabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab="Form and Panel" key="1">
          <h2>Form - Declarative</h2>
          <FormDeclarative />
          <h2>Form Grid - Items Array</h2>
          <FormGridItemArray />
          <h2>
            Form Grid in Modal{" "}
            <NxpButton onClick={() => setModalVisible(true)}>Open</NxpButton>
          </h2>
          <FormGridModal
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
          />
          <h2>Form Grid - Panel</h2>
          <FormGridPanel />
          <h2>Form Survey - Panel</h2>
          <FormSurveyPanel />
        </TabPane>
        <TabPane tab="Table - Listing" key="2">
          <>
            <h2>Table - Listing</h2>
            <TableListing />
            <h2>Table - Lazyloading</h2>
            <TableLazy />
            <h2>Table - Filtering</h2>
            <h2>Table - Column Customization</h2>
          </>
        </TabPane>
        <TabPane tab="Table - Editing" key="3">
          <>
            <h2>Table - Form</h2>
            <TableForm />
            <h2>Table - Full Form</h2>
            <TableFullForm />
            <h2>Table - Editing</h2>
            <TableEditing />
          </>
        </TabPane>
        <TabPane tab="Tabs and Header" key="4">
          <>
            <h2>Tabs</h2>
            <DemoTabs />
            <h2>Header with Tabs</h2>
            <DemoHeaderTabs />
            <h2>Header Basic</h2>
            <DemoHeader />
            <h2>Header Advanced</h2>
            <DemoHeaderAdvanced />
            <h2>Header with Breadcrumb</h2>
            <DemoHeaderBreadcrumb />
            <h2>
              Header Embeded (inside content, with level prop for setting h2,
              h3, h4...)
            </h2>
            <DemoHeaderEmbeded />
            <h2>Header in Search Bar</h2>
            <DemoHeaderSearchbar />
          </>
        </TabPane>
        <TabPane tab="Misc - Display" key="5">
          <>
            <h2>Loading Spin</h2>
            <DemoLoadingSpin />
            <h2>Alerts</h2>
            <DemoAlerts />
            <h2>Holder</h2>
            <DemoHolder />
            <h2>Steps</h2>
            <DemoSteps />
            <h2>Modal with steps</h2>
            <DemoModalWithSteps />
            <h2>Modal with subheading</h2>
            <DemoModalWithSubheading />
            <h2>Toggle Group</h2>
            <DemoToggleGroup />
            <h2>Description List</h2>
            <DemoDescriptionList />
            <h2>Card List</h2>
            <DemoCardList />
          </>
        </TabPane>
        <TabPane tab="Misc - Input" key="6">
          <>
            <h2>Number Input</h2>
            <DemoNumberInput />
            <h2>Date Picker</h2>
            <DemoDatePicker />
            <h2>Time Picker</h2>
            <DemoTimePicker />
            <h2>Signature</h2>
            <DemoSignature />
            <h2>Upload</h2>
            <DemoUpload />
          </>
        </TabPane>
      </NxpTabs>
    </div>
  );
};

export default Demo;
