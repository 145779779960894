import "../../Customization.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import AppHeader from "../../../app/components/app-header";

interface CustomizationLayoutProps {
  onActiveSectionChange: (screenName: string) => void;
}

const CustomizationLayout: React.FC<CustomizationLayoutProps> = ({
  onActiveSectionChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <AppHeader localeResourceId={"voc.customization.customization"} />
      <div className="voc-customization">
        <button
          key="compensation-event"
          className="section-button"
          onClick={() => {
            onActiveSectionChange("compensation-event");
          }}
        >
          {t("voc.customization.compensationEvent")}
          <span className="button-edit-icon">{t("voc.common.edit")}</span>
        </button>

        <button
          key={"site-event"}
          className="section-button"
          onClick={() => {
            onActiveSectionChange("site-event");
          }}
        >
          {t("voc.customization.siteEvent")}
          <span className="button-edit-icon">{t("voc.common.edit")}</span>
        </button>
      </div>
    </>
  );
};

export default CustomizationLayout;
