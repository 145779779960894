import { FilterOption, FilterType } from "../app/components/app-filter";
import { convertToJsonBody } from "../app/services/convert-to-json-body";
import { PageRequest } from "../app/services/vocService";
import {
  ResponseWithPagination,
  VocEventStatusRecord,
  VocEventType,
  VocGetDictionary,
  VocGetSupportDocumentResponse,
  VocRecordStatus,
  VocSiteEvent,
  VocSiteEventListItem,
  VocSiteEventPatchRequest,
  VocSiteEventPostRequest,
  VocSupportDocument,
  VocSupportDocumentEnums,
} from "../app/types";
import { apiRequest, ServiceConfig } from "../app/utils/backend/api-request";
import { prepareFilterOptions } from "../app/utils/backend/prepare-filter-options";
import { REGISTER_LAZY_LOAD_SIZE } from "../app/utils/const";

interface Option {
  render: string;
  enumValue: string;
  disabled?: boolean;
  childOf?: string;
}
export interface Recovery {
  compensationEventId?: string;
  recoverable: boolean;
  compensationEvent?: {
    eventType: VocEventType;
    commercialOwnerId: string;
    recordSupporterId: string;
    contemporaryRecordRequired: true;
    operationOwnerId: string;
  };
}

export function convertLibs(dicts: VocGetDictionary[]) {
  const libs: Option[] = [];
  dicts.forEach((dict) => {
    if (!dict.disabled) {
      libs.push({
        enumValue: dict.value,
        render: dict.label,
      });
    }
  });
  return libs;
}

export const getSiteEvent = (id: string, serviceConfig: ServiceConfig) => {
  return apiRequest<VocSiteEvent>(`/site_events/${id}`, serviceConfig);
};

export const createSiteEvent = (
  siteEvent: Partial<VocSiteEventPostRequest>,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocSiteEvent>(`/site_events`, serviceConfig, {
    method: "POST",
    body: JSON.stringify({
      ...siteEvent,
      files: siteEvent.files ? siteEvent.files.map((f) => ({ file: f })) : [],
    }),
  });
};

export const updateSiteEvent = (
  id: string,
  form: VocSiteEventPatchRequest,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocSiteEvent>(`/site_events/${id}`, serviceConfig, {
    credentials: "same-origin",
    method: "PATCH",
    body: JSON.stringify(form),
  });
};

export const searchSiteEvents = (
  serviceConfig: ServiceConfig,
  pageRequest: PageRequest,
  filterOptions?: FilterOption[]
) => {
  const filters = prepareFilterOptions(filterOptions || []);

  if (pageRequest.search) {
    // handle table search box value
    filters.push({
      column: "serial",
      type: FilterType.Contains,
      value: pageRequest.search,
    });
  }

  // pageRequest.perPage === 0 for lazy loading
  const perPage =
    pageRequest.perPage === 0 ? REGISTER_LAZY_LOAD_SIZE : pageRequest.perPage;

  return apiRequest<ResponseWithPagination<VocSiteEventListItem[]>>(
    `/search/site_events`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        limit: perPage,
        offset: parseInt(pageRequest.page)
          ? (parseInt(pageRequest.page) - 1) * perPage
          : 0,
        sortColumn: pageRequest.sort,
        sortOrder: pageRequest.order,
        filters,
      }),
    }
  );
};
export const supportDocFormat = (form: Partial<VocSupportDocument>) => {
  let postData: any = {
    documentType: form?.documentType,
    documentFiles: form?.documentFiles,
    comment: form?.comment,
    details: {
      documentType: form?.documentType,
      link: form?.link,
    },
  };
  if (form?.id) {
    postData = { ...postData, id: form?.id };
  }
  switch (form?.documentType as VocSupportDocumentEnums) {
    case "VO-EV-SDOC-01":
      postData = {
        ...postData,
        details: {
          ...postData.details,
          subject: form?.subject,
          sender: form?.sender,
          recipient: form?.recipient,
          documentDate: form?.documentDate,
        },
      };
      break;
    case "VO-EV-SDOC-06":
      postData = {
        ...postData,
        details: {
          ...postData.details,
          subject: form?.subject,
          reference: form?.reference,
          amount: form?.amount,
          docStatus: form?.docStatus,
          subcontractor: form?.subcontractor,
        },
      };
      break;
    case "VO-EV-SDOC-07":
      postData = {
        ...postData,
        details: {
          ...postData.details,
          subject: form?.subject,
          reference: form?.reference,
          amount: form?.amount,
          docStatus: form?.docStatus,
          fromDate: form?.fromDate,
          toDate: form?.toDate,
          subcontractor: form?.subcontractor,
        },
      };
      break;
    case "VO-EV-SDOC-11":
      postData = {
        ...postData,
        details: {
          ...postData.details,
          drawingNumber: form?.drawingNumber,
          revision: form?.revision,
          title: form?.title,
          originator: form?.originator,
          documentDate: form?.documentDate,
          location: form?.location,
        },
      };
      break;
    case "VO-EV-SDOC-09":
      postData = {
        ...postData,
        details: {
          ...postData.details,
          subject: form?.subject,
          fromDate: form?.fromDate,
          toDate: form?.toDate,
        },
      };
      break;
    case "VO-EV-SDOC-10":
      postData = {
        ...postData,
        details: {
          ...postData.details,
          subject: form?.subject,
          fromDate: form?.fromDate,
          toDate: form?.toDate,
          reference: form?.reference,
          amount: form?.amount,
          details: form?.details,
          valueToDate: form?.valueToDate,
          certifiedToDate: form?.certifiedToDate,
          subcontractorAssessment: form?.subcontractorAssessment,
        },
      };
      break;
    default:
      postData = {
        ...postData,
        details: {
          ...postData.details,
          subject: form?.subject,
          fromDate: form?.fromDate,
          toDate: form?.toDate,
          recordStatus: form?.recordStatus,
        },
      };
      break;
  }
  return postData;
};
export const createSiteEventSupportDocument = (
  siteEventId: string,
  supportDocument: Partial<VocSupportDocument>,
  serviceConfig: ServiceConfig
) => {
  const supportDocument_: { [key: string]: any } = {
    ...supportDocFormat(supportDocument),
  };
  Object.keys(supportDocument_).forEach((key) => {
    if (supportDocument_[key] === null) {
      // remove null field for other document type to meet api requirement
      supportDocument_[key] = undefined;
    }
  });
  if (supportDocument_.subcontractor) {
    supportDocument_.subcontractorId = supportDocument_.subcontractor.id;
    supportDocument_.subcontractor = undefined;
  }
  return apiRequest<VocSupportDocument>(
    `/site_events/${siteEventId}/support_document`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "POST",
      body: JSON.stringify(supportDocument_),
    }
  );
};

export const getSiteEventSupportDocuments = (
  siteEventId: string,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<VocGetSupportDocumentResponse>(
    `${serviceConfig.apiBaseUrl}/support-documents/entities/${serviceConfig.entityId}/site_events/${siteEventId}/support_document`,
    serviceConfig
  );
};

export const batchUpdateSiteEventStatusRecords = (
  type: "compEvent" | "siteEvent",
  start: string,
  finish: string,
  status: VocRecordStatus,
  remarks: string,
  siteEventId: string,
  serviceConfig: ServiceConfig
): Promise<VocEventStatusRecord[]> => {
  return apiRequest<VocEventStatusRecord[]>(
    `/${
      type === "compEvent" ? "events" : "site_events"
    }/${siteEventId}/records`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PATCH",
      body: JSON.stringify({
        start,
        finish,
        status,
        remarks,
      }),
    }
  );
};
export const createSiteEventStatusRecord = (
  start: string,
  status: VocRecordStatus,
  remarks: string,
  siteEventId: string,
  serviceConfig: ServiceConfig
): Promise<VocEventStatusRecord[]> => {
  return apiRequest<VocEventStatusRecord[]>(
    `/site_events/${siteEventId}/records`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "POST",
      body: JSON.stringify({
        start,
        status,
        remarks,
      }),
    }
  );
};
export const updateSiteEventRecovery = (
  recovery: Recovery,
  siteEventId: string,
  serviceConfig: ServiceConfig
): Promise<Recovery> => {
  return apiRequest<Recovery>(
    `/site_events/${siteEventId}/recovery`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PATCH",
      body: convertToJsonBody(recovery, serviceConfig.timezoneHourAdjustment),
    }
  );
};
// export const getSiteEvent = (id: string, serviceConfig: ServiceConfig) => {
//   return apiRequest<VocSiteEvent>(
//     `${serviceConfig.apiBaseUrl}/site-events/entity/${serviceConfig.entityId}/site-events/${id}`,
//     serviceConfig
//   );
// };

// mock temporarily
// return Promise.resolve<{
//   listItems: VocSiteEventListItem[];
//   totalRecordCount: number;
// }>({
//   listItems: [
//     {
//       id: 1,
//       serial: "serial1",
//       compensationEventId: 123,
//       compensationEventSerial: "serial123",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject1",
//       remarks: "remarks1",
//       eventStatus: { label: "eventStatus1", value: "eventStatus1" },
//       eventSource: { label: "eventSource1", value: "eventSource1" },
//       reference: "reference1",
//       locations: [],
//       instructedBy: { label: "instructedBy1", value: "instructedBy1" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy1", id: 1 },
//       lastUpdatedBy: { name: "lastUpdatedBy1", id: 1 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 2,
//       serial: "serial2",
//       compensationEventId: 333,
//       compensationEventSerial: "serial333",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject2",
//       remarks: "remarks2",
//       eventStatus: { label: "eventStatus2", value: "eventStatus2" },
//       eventSource: { label: "eventSource2", value: "eventSource2" },
//       reference: "reference2",
//       locations: [],
//       instructedBy: { label: "instructedBy2", value: "instructedBy2" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy2", id: 2 },
//       lastUpdatedBy: { name: "lastUpdatedBy2", id: 2 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 1,
//       serial: "serial1",
//       compensationEventId: 123,
//       compensationEventSerial: "serial123",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject1",
//       remarks: "remarks1",
//       eventStatus: { label: "eventStatus1", value: "eventStatus1" },
//       eventSource: { label: "eventSource1", value: "eventSource1" },
//       reference: "reference1",
//       locations: [],
//       instructedBy: { label: "instructedBy1", value: "instructedBy1" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy1", id: 1 },
//       lastUpdatedBy: { name: "lastUpdatedBy1", id: 1 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 2,
//       serial: "serial2",
//       compensationEventId: 333,
//       compensationEventSerial: "serial333",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject2",
//       remarks: "remarks2",
//       eventStatus: { label: "eventStatus2", value: "eventStatus2" },
//       eventSource: { label: "eventSource2", value: "eventSource2" },
//       reference: "reference2",
//       locations: [],
//       instructedBy: { label: "instructedBy2", value: "instructedBy2" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy2", id: 2 },
//       lastUpdatedBy: { name: "lastUpdatedBy2", id: 2 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 1,
//       serial: "serial1",
//       compensationEventId: 123,
//       compensationEventSerial: "serial123",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject1",
//       remarks: "remarks1",
//       eventStatus: { label: "eventStatus1", value: "eventStatus1" },
//       eventSource: { label: "eventSource1", value: "eventSource1" },
//       reference: "reference1",
//       locations: [],
//       instructedBy: { label: "instructedBy1", value: "instructedBy1" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy1", id: 1 },
//       lastUpdatedBy: { name: "lastUpdatedBy1", id: 1 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 2,
//       serial: "serial2",
//       compensationEventId: 333,
//       compensationEventSerial: "serial333",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject2",
//       remarks: "remarks2",
//       eventStatus: { label: "eventStatus2", value: "eventStatus2" },
//       eventSource: { label: "eventSource2", value: "eventSource2" },
//       reference: "reference2",
//       locations: [],
//       instructedBy: { label: "instructedBy2", value: "instructedBy2" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy2", id: 2 },
//       lastUpdatedBy: { name: "lastUpdatedBy2", id: 2 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 1,
//       serial: "serial1",
//       compensationEventId: 123,
//       compensationEventSerial: "serial123",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject1",
//       remarks: "remarks1",
//       eventStatus: { label: "eventStatus1", value: "eventStatus1" },
//       eventSource: { label: "eventSource1", value: "eventSource1" },
//       reference: "reference1",
//       locations: [],
//       instructedBy: { label: "instructedBy1", value: "instructedBy1" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy1", id: 1 },
//       lastUpdatedBy: { name: "lastUpdatedBy1", id: 1 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 2,
//       serial: "serial2",
//       compensationEventId: 333,
//       compensationEventSerial: "serial333",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject2",
//       remarks: "remarks2",
//       eventStatus: { label: "eventStatus2", value: "eventStatus2" },
//       eventSource: { label: "eventSource2", value: "eventSource2" },
//       reference: "reference2",
//       locations: [],
//       instructedBy: { label: "instructedBy2", value: "instructedBy2" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy2", id: 2 },
//       lastUpdatedBy: { name: "lastUpdatedBy2", id: 2 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 1,
//       serial: "serial1",
//       compensationEventId: 123,
//       compensationEventSerial: "serial123",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject1",
//       remarks: "remarks1",
//       eventStatus: { label: "eventStatus1", value: "eventStatus1" },
//       eventSource: { label: "eventSource1", value: "eventSource1" },
//       reference: "reference1",
//       locations: [],
//       instructedBy: { label: "instructedBy1", value: "instructedBy1" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy1", id: 1 },
//       lastUpdatedBy: { name: "lastUpdatedBy1", id: 1 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 2,
//       serial: "serial2",
//       compensationEventId: 333,
//       compensationEventSerial: "serial333",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject2",
//       remarks: "remarks2",
//       eventStatus: { label: "eventStatus2", value: "eventStatus2" },
//       eventSource: { label: "eventSource2", value: "eventSource2" },
//       reference: "reference2",
//       locations: [],
//       instructedBy: { label: "instructedBy2", value: "instructedBy2" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy2", id: 2 },
//       lastUpdatedBy: { name: "lastUpdatedBy2", id: 2 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 1,
//       serial: "serial1",
//       compensationEventId: 123,
//       compensationEventSerial: "serial123",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject1",
//       remarks: "remarks1",
//       eventStatus: { label: "eventStatus1", value: "eventStatus1" },
//       eventSource: { label: "eventSource1", value: "eventSource1" },
//       reference: "reference1",
//       locations: [],
//       instructedBy: { label: "instructedBy1", value: "instructedBy1" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy1", id: 1 },
//       lastUpdatedBy: { name: "lastUpdatedBy1", id: 1 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 2,
//       serial: "serial2",
//       compensationEventId: 333,
//       compensationEventSerial: "serial333",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject2",
//       remarks: "remarks2",
//       eventStatus: { label: "eventStatus2", value: "eventStatus2" },
//       eventSource: { label: "eventSource2", value: "eventSource2" },
//       reference: "reference2",
//       locations: [],
//       instructedBy: { label: "instructedBy2", value: "instructedBy2" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy2", id: 2 },
//       lastUpdatedBy: { name: "lastUpdatedBy2", id: 2 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 1,
//       serial: "serial1",
//       compensationEventId: 123,
//       compensationEventSerial: "serial123",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject1",
//       remarks: "remarks1",
//       eventStatus: { label: "eventStatus1", value: "eventStatus1" },
//       eventSource: { label: "eventSource1", value: "eventSource1" },
//       reference: "reference1",
//       locations: [],
//       instructedBy: { label: "instructedBy1", value: "instructedBy1" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy1", id: 1 },
//       lastUpdatedBy: { name: "lastUpdatedBy1", id: 1 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 2,
//       serial: "serial2",
//       compensationEventId: 333,
//       compensationEventSerial: "serial333",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject2",
//       remarks: "remarks2",
//       eventStatus: { label: "eventStatus2", value: "eventStatus2" },
//       eventSource: { label: "eventSource2", value: "eventSource2" },
//       reference: "reference2",
//       locations: [],
//       instructedBy: { label: "instructedBy2", value: "instructedBy2" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy2", id: 2 },
//       lastUpdatedBy: { name: "lastUpdatedBy2", id: 2 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 1,
//       serial: "serial1",
//       compensationEventId: 123,
//       compensationEventSerial: "serial123",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject1",
//       remarks: "remarks1",
//       eventStatus: { label: "eventStatus1", value: "eventStatus1" },
//       eventSource: { label: "eventSource1", value: "eventSource1" },
//       reference: "reference1",
//       locations: [],
//       instructedBy: { label: "instructedBy1", value: "instructedBy1" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy1", id: 1 },
//       lastUpdatedBy: { name: "lastUpdatedBy1", id: 1 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 2,
//       serial: "serial2",
//       compensationEventId: 333,
//       compensationEventSerial: "serial333",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject2",
//       remarks: "remarks2",
//       eventStatus: { label: "eventStatus2", value: "eventStatus2" },
//       eventSource: { label: "eventSource2", value: "eventSource2" },
//       reference: "reference2",
//       locations: [],
//       instructedBy: { label: "instructedBy2", value: "instructedBy2" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy2", id: 2 },
//       lastUpdatedBy: { name: "lastUpdatedBy2", id: 2 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 1,
//       serial: "serial1",
//       compensationEventId: 123,
//       compensationEventSerial: "serial123",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject1",
//       remarks: "remarks1",
//       eventStatus: { label: "eventStatus1", value: "eventStatus1" },
//       eventSource: { label: "eventSource1", value: "eventSource1" },
//       reference: "reference1",
//       locations: [],
//       instructedBy: { label: "instructedBy1", value: "instructedBy1" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy1", id: 1 },
//       lastUpdatedBy: { name: "lastUpdatedBy1", id: 1 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 2,
//       serial: "serial2",
//       compensationEventId: 333,
//       compensationEventSerial: "serial333",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject2",
//       remarks: "remarks2",
//       eventStatus: { label: "eventStatus2", value: "eventStatus2" },
//       eventSource: { label: "eventSource2", value: "eventSource2" },
//       reference: "reference2",
//       locations: [],
//       instructedBy: { label: "instructedBy2", value: "instructedBy2" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy2", id: 2 },
//       lastUpdatedBy: { name: "lastUpdatedBy2", id: 2 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 1,
//       serial: "serial1",
//       compensationEventId: 123,
//       compensationEventSerial: "serial123",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject1",
//       remarks: "remarks1",
//       eventStatus: { label: "eventStatus1", value: "eventStatus1" },
//       eventSource: { label: "eventSource1", value: "eventSource1" },
//       reference: "reference1",
//       locations: [],
//       instructedBy: { label: "instructedBy1", value: "instructedBy1" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy1", id: 1 },
//       lastUpdatedBy: { name: "lastUpdatedBy1", id: 1 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 2,
//       serial: "serial2",
//       compensationEventId: 333,
//       compensationEventSerial: "serial333",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject2",
//       remarks: "remarks2",
//       eventStatus: { label: "eventStatus2", value: "eventStatus2" },
//       eventSource: { label: "eventSource2", value: "eventSource2" },
//       reference: "reference2",
//       locations: [],
//       instructedBy: { label: "instructedBy2", value: "instructedBy2" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy2", id: 2 },
//       lastUpdatedBy: { name: "lastUpdatedBy2", id: 2 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 1,
//       serial: "serial1",
//       compensationEventId: 123,
//       compensationEventSerial: "serial123",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject1",
//       remarks: "remarks1",
//       eventStatus: { label: "eventStatus1", value: "eventStatus1" },
//       eventSource: { label: "eventSource1", value: "eventSource1" },
//       reference: "reference1",
//       locations: [],
//       instructedBy: { label: "instructedBy1", value: "instructedBy1" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy1", id: 1 },
//       lastUpdatedBy: { name: "lastUpdatedBy1", id: 1 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 2,
//       serial: "serial2",
//       compensationEventId: 333,
//       compensationEventSerial: "serial333",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject2",
//       remarks: "remarks2",
//       eventStatus: { label: "eventStatus2", value: "eventStatus2" },
//       eventSource: { label: "eventSource2", value: "eventSource2" },
//       reference: "reference2",
//       locations: [],
//       instructedBy: { label: "instructedBy2", value: "instructedBy2" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy2", id: 2 },
//       lastUpdatedBy: { name: "lastUpdatedBy2", id: 2 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 1,
//       serial: "serial1",
//       compensationEventId: 123,
//       compensationEventSerial: "serial123",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject1",
//       remarks: "remarks1",
//       eventStatus: { label: "eventStatus1", value: "eventStatus1" },
//       eventSource: { label: "eventSource1", value: "eventSource1" },
//       reference: "reference1",
//       locations: [],
//       instructedBy: { label: "instructedBy1", value: "instructedBy1" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy1", id: 1 },
//       lastUpdatedBy: { name: "lastUpdatedBy1", id: 1 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 2,
//       serial: "serial2",
//       compensationEventId: 333,
//       compensationEventSerial: "serial333",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject2",
//       remarks: "remarks2",
//       eventStatus: { label: "eventStatus2", value: "eventStatus2" },
//       eventSource: { label: "eventSource2", value: "eventSource2" },
//       reference: "reference2",
//       locations: [],
//       instructedBy: { label: "instructedBy2", value: "instructedBy2" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy2", id: 2 },
//       lastUpdatedBy: { name: "lastUpdatedBy2", id: 2 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 1,
//       serial: "serial1",
//       compensationEventId: 123,
//       compensationEventSerial: "serial123",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject1",
//       remarks: "remarks1",
//       eventStatus: { label: "eventStatus1", value: "eventStatus1" },
//       eventSource: { label: "eventSource1", value: "eventSource1" },
//       reference: "reference1",
//       locations: [],
//       instructedBy: { label: "instructedBy1", value: "instructedBy1" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy1", id: 1 },
//       lastUpdatedBy: { name: "lastUpdatedBy1", id: 1 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 2,
//       serial: "serial2",
//       compensationEventId: 333,
//       compensationEventSerial: "serial333",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject2",
//       remarks: "remarks2",
//       eventStatus: { label: "eventStatus2", value: "eventStatus2" },
//       eventSource: { label: "eventSource2", value: "eventSource2" },
//       reference: "reference2",
//       locations: [],
//       instructedBy: { label: "instructedBy2", value: "instructedBy2" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy2", id: 2 },
//       lastUpdatedBy: { name: "lastUpdatedBy2", id: 2 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 1,
//       serial: "serial1",
//       compensationEventId: 123,
//       compensationEventSerial: "serial123",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject1",
//       remarks: "remarks1",
//       eventStatus: { label: "eventStatus1", value: "eventStatus1" },
//       eventSource: { label: "eventSource1", value: "eventSource1" },
//       reference: "reference1",
//       locations: [],
//       instructedBy: { label: "instructedBy1", value: "instructedBy1" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy1", id: 1 },
//       lastUpdatedBy: { name: "lastUpdatedBy1", id: 1 },
//       recoverable: true,
//       files: [],
//     },
//     {
//       id: 2,
//       serial: "serial2",
//       compensationEventId: 333,
//       compensationEventSerial: "serial333",
//       createdOn: new Date(),
//       updatedOn: new Date(),
//       subject: "subject2",
//       remarks: "remarks2",
//       eventStatus: { label: "eventStatus2", value: "eventStatus2" },
//       eventSource: { label: "eventSource2", value: "eventSource2" },
//       reference: "reference2",
//       locations: [],
//       instructedBy: { label: "instructedBy2", value: "instructedBy2" },
//       dateOfAwareness: new Date(),
//       reportedBy: { name: "reportedBy2", id: 2 },
//       lastUpdatedBy: { name: "lastUpdatedBy2", id: 2 },
//       recoverable: true,
//       files: [],
//     },
//   ],
//   totalRecordCount: 2,
// });
// };
