import { Route } from "react-router-dom";

import AppProtectedPage from "../app/components/app-router/AppProtectedPage";
import FeedbackListContainer from "./pages/FeedbackListContainer";

export const feedbackRoutes = [
  <Route
    key="feedback"
    path="/entities/:entityId/admin/feedback"
    element={
      <AppProtectedPage
        requireEntityReady
        component={<FeedbackListContainer />}
      />
    }
  />,
];
