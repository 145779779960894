import "./SiteEventListLayout.scss";

import { Toggle } from "office-ui-fabric-react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "antd";
import moment from "moment";
import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";
import AppDataTable, {
  ColumnDataType,
  DataTableColumn,
  QuerySettings,
  useDataTableWrapperResized,
} from "../../../app/components/app-data-table";
import { getDataTableDefaultCellContent } from "../../../app/components/app-data-table/AppDataTableRow";
import AppExportExcelButton from "../../../app/components/app-export-excel-button";
import AppFilter, {
  FilterOption,
  FilterType,
} from "../../../app/components/app-filter";
import AppHeader from "../../../app/components/app-header";
import AppIconLink from "../../../app/components/app-icon-link";
import useAppContext from "../../../app/hooks/useAppContext";
import { getDictionaryItemsAsIndentedSelectOptions } from "../../../app/services/dictionary";
import {
  VocCustomUi,
  VocCustomUiLayoutItemType,
  VocSiteEventListItem,
} from "../../../app/types";
import { EVENT_STATUS_CLOSED } from "../../../app/utils/const";
import { hasValue } from "../../../app/utils/has-value";
import { mapDateToString } from "../../../app/utils/mappers";
import { siteEventDefaultColumns } from "../../../app/utils/site-event-default-columns";
import { convertUrl } from "../../../app/utils/string";
import CreateSiteEventButton from "./create-site-event/CreateSiteEventButton";

interface SiteEventListLayoutProps {
  entityId: string;
  querySettings: QuerySettings;
  totalRecordCount: number;
  loading: boolean;
  dataSource: VocSiteEventListItem[];
  filterOptions: FilterOption[];
  customUi: VocCustomUi;
  onDataTableQuery: (querySettings: QuerySettings) => void;
  onDataTableRowClick: (
    e: React.MouseEvent<HTMLDivElement>,
    dataItem: VocSiteEventListItem
  ) => void;

  onFilterChange: (filterOptions: FilterOption[]) => void;
  onDataTableFooterAggregate: (
    rowDataItem: VocSiteEventListItem,
    footerDataItem: VocSiteEventListItem,
    isLastRow: boolean
  ) => void;
  onLazyLoad: () => void;

  onExportExcel: () => void;
}

const SiteEventListLayout: React.FC<SiteEventListLayoutProps> = ({
  entityId,
  querySettings,
  totalRecordCount,
  loading,
  dataSource,
  filterOptions,
  customUi,
  onDataTableQuery,
  onDataTableRowClick,
  onFilterChange,
  onDataTableFooterAggregate,
  onLazyLoad,
  onExportExcel,
}) => {
  const { t } = useTranslation();
  const {
    getLibraryItemByKeyValue,
    getDictionaryItemByKey,
    libraries,
    dictionaries,
  } = useAppContext();

  const navigate = useNavigate();

  const columns = useMemo(() => {
    const getheaderClassName = (
      filterOptions: FilterOption[],
      fieldName: string
    ) => {
      return (
        filterOptions.find((item) => item.fieldName === fieldName) &&
        "header-filtered"
      );
    };

    const onRenderMap = {
      eventStatus: (item: VocSiteEventListItem) => {
        return (
          getLibraryItemByKeyValue("VOC_EVENT_STATUS", item.eventStatus)
            ?.label ?? item.eventSource
        );
      },
      eventSource: (item: VocSiteEventListItem) => {
        return (
          getLibraryItemByKeyValue("VOC_SOURCES", item.eventSource)?.label ??
          item.eventSource
        );
      },
      reference: (item: VocSiteEventListItem) => {
        const url = convertUrl(item.reference);
        if (url) {
          return (
            <AppIconLink stopPropagation={true} href={url} targetBlank={true} />
          );
        }
        return item.reference?.toString() || null;
      },
      locations: (item: VocSiteEventListItem) => {
        return (
          item.locations &&
          item.locations
            .map((loc) => getDictionaryItemByKey("LOCATION", loc)?.label ?? loc)
            .join(", ")
        );
      },
      instructedBy: (item: VocSiteEventListItem) => {
        return (
          item.instructedBy &&
          libraries["VOC_VO_EMPOWER_REPS"].find(
            (dict) => dict.value === item.instructedBy
          )?.label
        );
      },
      dateOfAwareness: (item: VocSiteEventListItem) =>
        item.dateOfAwareness
          ? mapDateToString(moment(item.dateOfAwareness).toDate())
          : null,
      compensationEventSerial: (item: VocSiteEventListItem) => {
        return (
          <Button
            type="link"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`./../compensation-events/${item.compensationEventId}`);
            }}
          >
            {item.compensationEventSerial}
          </Button>
        );
      },
    };

    const columnDropdownOptionsMap = {
      eventSource: libraries["VOC_SOURCES"]?.map((item) => ({
        text: item.label,
        key: item.value,
      })),
      locations: getDictionaryItemsAsIndentedSelectOptions(
        dictionaries.find((d) => d.name === "LOCATION")?.members || []
      ),
    };

    return siteEventDefaultColumns.map((col) => {
      col.headerClassName = getheaderClassName(filterOptions, col.fieldName);
      col.onRender = onRenderMap[col.fieldName];
      col.columnDropdownOptions = columnDropdownOptionsMap[col.fieldName];
      col.name = t(col.name);
      return col;
    });
  }, [
    filterOptions,
    dictionaries,
    libraries,
    getLibraryItemByKeyValue,
    getDictionaryItemByKey,
    navigate,
    t,
  ]);

  const wrapperRef = useDataTableWrapperResized(168);

  const columnCustomized: DataTableColumn<VocSiteEventListItem>[] = useMemo(
    () =>
      customUi.layout
        .filter(
          (customCol) =>
            customCol.type === VocCustomUiLayoutItemType.DataTableColumn &&
            customCol.display &&
            (customCol.isCustomField ||
              columns.find((col) => col.key === customCol.key))
        )
        .map((customCol) => {
          if (
            customCol.type === VocCustomUiLayoutItemType.DataTableColumn &&
            customCol.isCustomField
          ) {
            return {
              key: customCol.key,
              name: customCol.name,
              fieldName: customCol.key,
              minWidth: 100,
              isResizable: true,
              isSortable: true,
              columnDataType: customCol.columnDataType || ColumnDataType.String,
              headerClassName:
                filterOptions.find(
                  (item) => item.fieldName === customCol.key
                ) && "header-filtered",
              onRender: (item: VocSiteEventListItem) =>
                hasValue(item.customization) &&
                getDataTableDefaultCellContent(
                  customCol.columnDataType,
                  item.customization[customCol.key]
                ),
            };
          }
          const column = columns.find((col) => col.key === customCol.key);
          return column;
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns]
  );

  const filterColumns: DataTableColumn<VocSiteEventListItem>[] = useMemo(
    () => [
      {
        // column added solely for support event status filtering / toggle
        key: "eventStatus",
        fieldName: "eventStatus",
        name: "Event Status",
        columnDataType: ColumnDataType.Select,
        isSortable: false,
        columnDropdownOptions: [
          {
            key: EVENT_STATUS_CLOSED,
            text: "Closed",
          },
        ],
      },
      ...columnCustomized,
    ],
    [columnCustomized]
  );

  return (
    <>
      <AppHeader localeResourceId="voc.siteEvents.eventRegister">
        <Toggle
          className="hide-closed-toggle"
          onText={t("voc.SiteEventList.hideClosedEvents")}
          offText={t("voc.SiteEventList.hideClosedEvents")}
          checked={
            !!filterOptions.find(
              (option) =>
                option.fieldName === "eventStatus" &&
                option.filterType === FilterType.NotEqual &&
                option.filterValue === EVENT_STATUS_CLOSED
            )
          }
          onChange={(_, checked) => {
            if (checked) {
              onFilterChange([
                ...filterOptions,
                {
                  fieldName: "eventStatus",
                  filterType: FilterType.NotEqual,
                  filterValue: EVENT_STATUS_CLOSED,
                  columnDataType: ColumnDataType.Select,
                  columnDropdownOptions: [
                    {
                      key: EVENT_STATUS_CLOSED,
                      text: "Closed",
                    },
                  ],
                },
              ]);
            } else {
              onFilterChange(
                filterOptions.filter(
                  (option) =>
                    !(
                      option.fieldName === "eventStatus" &&
                      option.filterType === FilterType.NotEqual &&
                      option.filterValue === EVENT_STATUS_CLOSED
                    )
                )
              );
            }
          }}
        />
        <AppFilter
          filterOptions={filterOptions}
          onFilterChange={onFilterChange}
          filterColumns={filterColumns}
        />
        <AppExportExcelButton onExportExcel={onExportExcel} />
        <AccessVisible objectCode="event" actionType="create">
          <CreateSiteEventButton entityId={entityId} />
        </AccessVisible>
      </AppHeader>
      <div className="voc-site-event-register" ref={wrapperRef}>
        <AppDataTable
          columns={columnCustomized}
          keyColumnName="id"
          dataSource={dataSource}
          querySettings={querySettings}
          loading={loading}
          totalRecordCount={totalRecordCount}
          enableSearch={true}
          enablePagination={true}
          fixFirstColumn={true}
          onDataTableQuery={onDataTableQuery}
          onRowClick={onDataTableRowClick}
          onFooterAggregate={onDataTableFooterAggregate}
          onLazyLoad={onLazyLoad}
        />
      </div>
    </>
  );
};

export default SiteEventListLayout;
