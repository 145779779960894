import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import {
  notify,
  NxpButton,
  NxpModal,
  NxpModalFooter,
  NxpSteps,
} from "@nexploretechnology/nxp-ui";

const useStyles = createUseStyles((theme) => ({
  DemoModalWithSteps: {},
}));

interface DemoModalWithStepsProps {}

const DemoModalWithSteps: React.FC<DemoModalWithStepsProps> = () => {
  const classes = useStyles();
  const demoSteps = ["Step A", "Step B", "Step C"] as const;
  type DemoStep = (typeof demoSteps)[number];
  const [activeDemoStep, setActiveDemoStep] = useState<DemoStep>(demoSteps[0]);

  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div className={classes.DemoModalWithSteps}>
      <NxpButton onClick={() => setModalVisible(true)}>Show Modal</NxpButton>
      <NxpModal
        visible={modalVisible}
        title="Modal with steps"
        onCancel={() => setModalVisible(false)}
      >
        <NxpSteps
          steps={demoSteps}
          activeStepKey={activeDemoStep}
          onStepKeyChange={(step) => setActiveDemoStep(step)}
        />
        {activeDemoStep === "Step A" && (
          <>
            <p>
              A context...
              <br />
              A context...
              <br />
              A context...
              <br />
            </p>
            <NxpModalFooter>
              <NxpButton
                onClick={() => {
                  setActiveDemoStep("Step B");
                }}
              >
                Next
              </NxpButton>
            </NxpModalFooter>
          </>
        )}
        {activeDemoStep === "Step B" && (
          <>
            <p>
              B B context...
              <br />
              B B context...
              <br />
              B B context...
              <br />
              B B context...
              <br />
            </p>
            <NxpModalFooter
              leftContent={
                <NxpButton
                  type="default"
                  onClick={() => {
                    setActiveDemoStep("Step A");
                  }}
                >
                  Back
                </NxpButton>
              }
            >
              <NxpButton
                onClick={() => {
                  setActiveDemoStep("Step C");
                }}
              >
                Next
              </NxpButton>
            </NxpModalFooter>
          </>
        )}
        {activeDemoStep === "Step C" && (
          <>
            <p>
              C C C context...
              <br />
              C C C context...
              <br />
              C C C context...
              <br />
              C C C context...
              <br />
              C C C context...
              <br />
            </p>
            <NxpModalFooter
              leftContent={
                <NxpButton
                  type="default"
                  onClick={() => {
                    setActiveDemoStep("Step B");
                  }}
                >
                  Back
                </NxpButton>
              }
            >
              <NxpButton onClick={() => notify.actionCompleted()}>
                Save
              </NxpButton>
            </NxpModalFooter>
          </>
        )}
      </NxpModal>
    </div>
  );
};

export default DemoModalWithSteps;
