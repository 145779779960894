import { LinkOutlined } from "@ant-design/icons";
import { DateFormatPattern, formatDate } from "@nexploretechnology/nxp-ui";
import { Button, Dropdown, MenuProps, Space } from "antd";
import i18next from "i18next";
import moment from "moment";
import { Icon } from "office-ui-fabric-react";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AccessVisible from "../../../../app/components/app-access-visible/AppAccessVisible";
import { VocRecordStatus, VocSupportDocument } from "../../../../app/types";
import BatchUpdateButton from "../batch-update/BatchUpdateButton";
import { DateRecord } from "../RecordsTabContainer";
import { BatchUpdateEvent } from "../RecordsTabLayout";
import "./Calendar.scss";
import DayCell from "./DayCellContainer";
import MonthlySelect from "./MonthSelect";

const getCalendarRows = (
  dateRecords: DateRecord[],
  now: Date,
  onStatusRecordsRefresh: () => void,
  eventSupportDocuments: VocSupportDocument[],
  contemporaryRecordRequired: boolean,
  siteEventId: string,
  events: BatchUpdateEvent[],
  selectedMonth?: Date,
  dateOfAwareness?: Date,
  dateOfCessation?: Date

  // permissions?: Permission | Permission[]
) => {
  console.log("eventSupportDocuments: ", eventSupportDocuments);

  const rows: ReactNode[] = [];

  let startDate: Date;

  if (selectedMonth) {
    startDate = new Date(selectedMonth.getTime());
  } else if (
    dateOfAwareness &&
    dateRecords.length &&
    dateRecords[0].date < dateOfAwareness
  ) {
    startDate = new Date(
      dateRecords[0].date.getFullYear(),
      dateRecords[0].date.getMonth(),
      1
    );
  } else if (dateOfAwareness) {
    startDate = new Date(
      dateOfAwareness.getFullYear(),
      dateOfAwareness.getMonth(),
      1
    );
  } else if (dateRecords.length) {
    startDate = new Date(
      dateRecords[0].date.getFullYear(),
      dateRecords[0].date.getMonth(),
      1
    );
  } else if (dateOfCessation) {
    startDate = new Date(
      dateOfCessation.getFullYear(),
      dateOfCessation.getMonth(),
      1
    );
  } else {
    startDate = new Date(now.getFullYear(), now.getMonth(), 1);
  }

  let endDate: Date;
  if (selectedMonth) {
    endDate = new Date(
      selectedMonth.getFullYear(),
      selectedMonth.getMonth() + 1,
      0
    );
  } else if (
    dateOfCessation &&
    dateRecords.length &&
    dateRecords[dateRecords.length - 1].date > dateOfCessation
  ) {
    endDate = new Date(
      dateRecords[dateRecords.length - 1].date.getFullYear(),
      dateRecords[dateRecords.length - 1].date.getMonth() + 1,
      0
    );
  } else if (dateOfCessation) {
    endDate = new Date(
      dateOfCessation.getFullYear(),
      dateOfCessation.getMonth() + 1,
      0
    );
    // } else if (dateRecords.length) {
    //   endDate = new Date(
    //     dateRecords[dateRecords.length - 1].date.getFullYear(),
    //     dateRecords[dateRecords.length - 1].date.getMonth() + 1,
    //     0
    //   );
  } else {
    endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  }

  const curDate = new Date(startDate.getTime());
  curDate.setDate(curDate.getDate() - curDate.getDay()); // set to begining of the week

  while (curDate <= endDate || curDate.getDay() === 6) {
    const dayCells: ReactNode[] = [];
    for (let i = 0; i < 7; i++) {
      const curDateClone = new Date(curDate.getTime());
      const supportDocuments = eventSupportDocuments.filter((doc) => {
        let compareDate = moment(
          formatDate(curDateClone),
          DateFormatPattern.date
        );
        let startDate = moment(
          formatDate(doc.details.fromDate),
          DateFormatPattern.date
        );
        let endDate = moment(
          formatDate(doc.details.toDate),
          DateFormatPattern.date
        );

        return compareDate.isBetween(startDate, endDate, null, "[]");
      });

      if (curDateClone <= startDate || curDateClone > endDate) {
        dayCells.push(
          <DayCell
            key={curDateClone.getTime()}
            onStatusRecordsRefresh={onStatusRecordsRefresh}
            supportDocuments={supportDocuments}
            contemporaryRecordRequired={contemporaryRecordRequired}
            siteEventId={
              events[0].eventType === "siteEvent" ? events[0].id : undefined
            }
            eventId={
              events[0].eventType === "compEvent" ? events[0].id : undefined
            }
            // permissions={permissions}
          />
        );
      } else {
        const remarks: ReactNode[] = [];
        if (
          dateOfAwareness &&
          dateOfAwareness.getFullYear() === curDateClone.getFullYear() &&
          dateOfAwareness.getMonth() === curDateClone.getMonth() &&
          dateOfAwareness.getDate() === curDateClone.getDate()
        ) {
          remarks.push(
            <span key="Awareness">
              <Icon iconName="Calendar" />
              {i18next.t("voc.eventPage.records.awareness")}
            </span>
          );
        }
        if (
          dateOfCessation &&
          dateOfCessation.getFullYear() === curDateClone.getFullYear() &&
          dateOfCessation.getMonth() === curDateClone.getMonth() &&
          dateOfCessation.getDate() === curDateClone.getDate()
        ) {
          remarks.push(
            <span key="Cessation">
              <Icon iconName="Calendar" />
              {i18next.t("voc.eventPage.records.cessation")}
            </span>
          );
        }
        const relatedRemarks: ReactNode[] = [];
        const relatedSE = dateRecords.filter(
          (item) =>
            item.date.getFullYear() === curDateClone.getFullYear() &&
            item.date.getMonth() === curDateClone.getMonth() &&
            item.date.getDate() === curDateClone.getDate() &&
            item.statusType === VocRecordStatus.Related_Site_Event
        );
        if (relatedSE.length) {
          const items: MenuProps["items"] = relatedSE.map((item) => ({
            label: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`../site-events/${item.siteEventId}`}
              >
                {item?.siteEventSerial}
              </a>
            ),
            key: item?.siteEventId,
            icon: <LinkOutlined />,
          }));
          relatedRemarks.push(
            <Dropdown menu={{ items }}>
              <Button
                type="link"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <Space>
                  <LinkOutlined />
                </Space>
              </Button>
            </Dropdown>
          );
        }
        dayCells.push(
          <DayCell
            dateRecords={dateRecords.filter(
              (item) =>
                item.date.getFullYear() === curDateClone.getFullYear() &&
                item.date.getMonth() === curDateClone.getMonth() &&
                item.date.getDate() === curDateClone.getDate()
            )}
            dateOfAwareness={dateOfAwareness}
            dateOfCessation={dateOfCessation}
            remarks={remarks}
            relatedRemarks={relatedRemarks}
            key={curDateClone.getTime()}
            date={curDateClone}
            showMonth={!selectedMonth}
            onStatusRecordsRefresh={onStatusRecordsRefresh}
            supportDocuments={supportDocuments}
            contemporaryRecordRequired={contemporaryRecordRequired}
            siteEventId={
              events[0].eventType === "siteEvent" ? events[0].id : undefined
            }
            eventId={
              events[0].eventType === "compEvent" ? events[0].id : undefined
            }
            // permissions={permissions}
          />
        );
      }

      curDate.setDate(curDate.getDate() + 1);
    }
    rows.push(<tr key={curDate.getTime()}>{dayCells}</tr>);
  }

  return <>{rows}</>;
};

interface CalendarProps {
  dateRecords: DateRecord[];
  dateOfAwareness?: Date;
  dateOfCessation?: Date;
  onStatusRecordsRefresh: () => void;
  eventSupportDocuments: VocSupportDocument[];
  contemporaryRecordRequired: boolean;
  events: BatchUpdateEvent[];
  siteEventId: string;
  // permissions?: Permission | Permission[];
}

const Calendar: React.FC<CalendarProps> = ({
  dateRecords,
  dateOfAwareness,
  dateOfCessation,
  onStatusRecordsRefresh,
  eventSupportDocuments,
  contemporaryRecordRequired,
  events,
}) => {
  const { eventId } = useParams();
  const now = new Date();
  const { t } = useTranslation();
  let tmpDate = dateRecords.length ? dateRecords[0].date : now;

  if (dateOfAwareness && dateOfAwareness < tmpDate) {
    tmpDate = dateOfAwareness;
  } else if (!dateOfAwareness && dateRecords.length === 0 && dateOfCessation) {
    tmpDate = dateOfCessation;
  }

  const recordStartMonth = new Date(
    tmpDate.getFullYear(),
    tmpDate.getMonth(),
    1
  );

  tmpDate = dateOfCessation;
  if (!tmpDate && dateRecords.length) {
    tmpDate = dateRecords[dateRecords.length - 1].date;
  } else if (
    tmpDate &&
    dateRecords.length &&
    dateRecords[dateRecords.length - 1].date > tmpDate
  ) {
    tmpDate = dateRecords[dateRecords.length - 1].date;
  }
  if (!tmpDate) {
    tmpDate = now;
  }
  const recordEndMonth = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), 1);

  const [selectedMonth, setSelectedMonth] = useState<Date>(() => {
    if (recordStartMonth.getTime() === recordEndMonth.getTime()) {
      return recordStartMonth;
    }
  });

  let fixedCalendarStart = dateOfAwareness;
  if (
    fixedCalendarStart &&
    dateRecords.length &&
    dateRecords[0].date < dateOfAwareness
  ) {
    fixedCalendarStart = dateRecords[0].date;
  }

  let fixedCalendarEnd = dateOfCessation;
  if (
    fixedCalendarEnd &&
    dateRecords.length &&
    dateRecords[dateRecords.length - 1].date > dateOfCessation
  ) {
    fixedCalendarEnd = dateRecords[dateRecords.length - 1].date;
  }

  return (
    <div className="voc-records-tab-calendar">
      <AccessVisible
        objectCode={eventId ? "compensation_event" : "event"}
        actionType="create"
      >
        <BatchUpdateButton
          onStatusRecordsRefresh={onStatusRecordsRefresh}
          events={events}
        />
      </AccessVisible>
      <MonthlySelect
        fixedCalendarStart={fixedCalendarStart}
        fixedCalendarEnd={fixedCalendarEnd}
        recordStartMonth={recordStartMonth}
        recordEndMonth={recordEndMonth}
        selectedMonth={selectedMonth}
        onMonthChange={setSelectedMonth}
        now={now}
      />
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>{t("voc.days.sun")}</th>
              <th>{t("voc.days.mon")}</th>
              <th>{t("voc.days.tue")}</th>
              <th>{t("voc.days.wed")}</th>
              <th>{t("voc.days.thu")}</th>
              <th>{t("voc.days.fri")}</th>
              <th>{t("voc.days.sat")}</th>
            </tr>
          </thead>
          <tbody>
            {getCalendarRows(
              dateRecords,
              now,
              onStatusRecordsRefresh,
              eventSupportDocuments,
              contemporaryRecordRequired,
              events[0].id,
              events,
              selectedMonth,
              dateOfAwareness,
              dateOfCessation
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Calendar;
