import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { DateFormatPattern, notify } from "@nexploretechnology/nxp-ui";
import { cloneDeep } from "lodash";

import { DataListItem } from "../../../../app/components/app-data-list/types";
import useAppContext from "../../../../app/hooks/useAppContext";
import { useAsync } from "../../../../app/hooks/useAsync";
import { DictionaryItem } from "../../../../app/services/dictionary";
import {
  VocSendTaskReminderResponse,
  VocTask,
  VocUpdateTasks,
} from "../../../../app/types";
import {
  getEventTasks,
  updateCompEventTasks,
  updateSiteEventTasks,
} from "../../../compensation-event-services";
import TasksLayout from "./TasksLayout";
import moment from "moment";
import { getUserDisplayName } from "../../../../app/utils/helper/get-user-display-name";

export interface TasksContainerProps {}

const TasksContainer: React.FC<TasksContainerProps> = (props) => {
  const { t: translate } = useTranslation();
  const { serviceConfig, getDictionary, errorHandler, activeUser } =
    useAppContext();
  const { siteEventId, eventId, entityId } = useParams();
  //////
  //CORE
  const [isEditingTasks, setIsEditingTasks] = React.useState<boolean>(false);
  const [tasksMemo, setTasksMemo] = React.useState<VocTask[]>(null);
  const [tasks, setTasks] = React.useState<DataListItem[]>(null);
  const [todoTasks, setTodoTasks] = React.useState<DataListItem[]>(null);
  const [form, setForm] = React.useState<VocUpdateTasks>(null);

  const [, setGetTasks] = useAsync<VocTask[]>(
    () =>
      getEventTasks(
        siteEventId && !eventId ? siteEventId : eventId,
        siteEventId && !eventId ? "siteEvent" : "compEvent",
        serviceConfig
      ),
    {
      onSuccess: (data: React.SetStateAction<VocTask[]>) => {
        setTasksMemo(data);
        setTasks(convertTasks(data as any) as any);
      },
      onError(ex) {
        errorHandler(ex, "Fetch Event Task Failure");
      },
    }
  );
  const [taskReminder, setSendTaskReminder] =
    useAsync<VocSendTaskReminderResponse>(null, {
      onSuccess: () => {
        notify.success(
          translate("voc.eventPage.summary.tasks.reminderSuccess")
        );
      },
    });
  React.useEffect(() => {
    if (tasks) {
      const tasksTodo = cloneDeep(tasks);
      tasksTodo.forEach((t: any) => {
        if (t.fieldDataArray[5].fieldData === "VOC_EVENT_TASK_STATUS_03") {
          t.hidden = true;
        }
      });
      setTodoTasks(tasksTodo);
    }
  }, [tasks]);

  const [, setUpdateTasks] = useAsync<VocTask[]>(null, {
    onSuccess: () => {
      setIsEditingTasks(false);
      setGetTasks(() => {
        return getEventTasks(
          siteEventId && !eventId ? siteEventId : eventId,
          siteEventId && !eventId ? "siteEvent" : "compEvent",
          serviceConfig
        );
      });
    },
    onError(ex) {
      errorHandler(ex, "Fetch Event Task Failure");
    },
  });

  const handleUpdateTasks = React.useCallback(() => {
    setUpdateTasks(() => {
      if (siteEventId && !eventId) {
        return updateSiteEventTasks(siteEventId, form, serviceConfig);
      } else if (!siteEventId && eventId) {
        return updateCompEventTasks(eventId, form, serviceConfig);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  React.useEffect(() => {
    if (form) {
      handleUpdateTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  function convertTasks(tasks: VocTask[]) {
    const converted = tasks.map((t) => ({
      id: t.id as string,
      edited: false,
      hidden: false,
      undeletable: t.assignedBy.id.toString() === activeUser?.id ? false : true,
      readonlyNotice:
        t.assignedBy.id.toString() === activeUser?.id
          ? null
          : translate("voc.eventPage.summary.originatorDeleteTask"),
      fieldDataArray: [
        { fieldData: t.taskDetails },
        { fieldData: t.assignedTo },
        { fieldData: t.deadline },
        { fieldData: getUserDisplayName(t.assignedBy, false, true) },
        { fieldData: t.createdOn },
        { fieldData: t.taskStatus },
        { fieldData: t.completionDate },
      ],
    }));
    return converted;
  }

  function saveTasks() {
    const deconverted: Partial<VocTask>[] = [];

    const deletedRecords: any[] = [];
    tasksMemo.forEach((memo) => {
      let deleted = true;
      tasks.forEach((t) => {
        if (memo.id === t.id) {
          deleted = false;
        }
      });
      if (deleted) {
        deletedRecords.push({
          id: memo.id,
          taskDetails: memo.taskDetails,
          assignedToId: memo.assignedTo?.id,
          deleted: true,
        });
      }
    });

    const newHookState = [...tasks];

    let invalid = false;

    tasks.forEach((t, i) => {
      let valid = true;
      let missingTaskDetails = false;
      let missingAssignee = false;

      if (!t.fieldDataArray[0].fieldData) {
        valid = false;
        invalid = true;
        missingTaskDetails = true;
      }
      if (!t.fieldDataArray[1].fieldData) {
        valid = false;
        invalid = true;
        missingAssignee = true;
      }

      if (valid) {
        const deconversion: any = {
          taskDetails: t.fieldDataArray[0].fieldData,
          assignedToId: t.fieldDataArray[1].fieldData.key
            ? t.fieldDataArray[1].fieldData.key
            : t.fieldDataArray[1].fieldData.id,
          deadline: moment(t.fieldDataArray[2].fieldData).format(
            DateFormatPattern.date
          ),
          taskStatus: t.fieldDataArray[5].fieldData,
        };
        if (t.id) {
          deconversion.id = t.id;
        }
        if (t.edited) {
          deconverted.push(deconversion);
        }
      } else {
        const invalidated = t;
        if (missingTaskDetails && !missingAssignee) {
          t.validationError = translate(
            "voc.eventPage.validationError.enterTaskDetails"
          );
        } else if (!missingTaskDetails && missingAssignee) {
          t.validationError = translate(
            "voc.eventPage.validationError.assignTask"
          );
        } else if (missingTaskDetails && missingAssignee) {
          t.validationError = translate(
            "voc.eventPage.validationError.enterTaskDetailsAndAssignTask"
          );
        }
        newHookState[i] = invalidated;
        setTasks(newHookState);
      }
    });

    if (!invalid) {
      const combinedRecords = [...deconverted, ...deletedRecords];
      setForm({
        tasks: combinedRecords,
      });
    }
    //set form after hooking in the send tasks API
  }

  function addTask() {
    const tasksCopy = [
      ...tasks,
      ...[
        {
          id: null,
          edited: true,
          fieldDataArray: [
            { fieldData: undefined },
            { fieldData: undefined },
            { fieldData: undefined },
            { fieldData: undefined },
            { fieldData: undefined },
            { fieldData: "VOC_EVENT_TASK_STATUS_01" },
            { fieldData: undefined },
          ],
        },
      ],
    ];

    setTasks(tasksCopy);
  }

  function cancel() {
    setTasks(convertTasks(tasksMemo));
    setIsEditingTasks(false);
  }

  //////////////
  //GET STATUSES
  const [libTaskStatuses] = React.useState<any[]>(
    convertOurskyLibsNoChildren(
      getDictionary("VOC_EVENT_TASK_STATUS")?.members || []
    )
  );

  function convertOurskyLibsNoChildren(dicts: DictionaryItem[]) {
    return dicts.map((dict) => ({
      enumValue: dict.key,
      render: dict.label,
    }));
  }

  return tasks && libTaskStatuses ? (
    <TasksLayout
      entityId={entityId}
      isEditingTasks={isEditingTasks}
      setIsEditingTasks={setIsEditingTasks}
      tasks={tasks}
      todoTasks={todoTasks}
      setTasks={setTasks}
      addTask={addTask}
      cancel={cancel}
      saveTasks={saveTasks}
      libTaskStatuses={libTaskStatuses}
      taskReminder={taskReminder}
      setSendTaskReminder={setSendTaskReminder}
    />
  ) : null;
};

export default TasksContainer;
