import React from "react";
import { createUseStyles } from "react-jss";

import { CheckCircleFilled } from "@ant-design/icons";
import { NxpButton, NxpHeader } from "@nexploretechnology/nxp-ui";
import { Typography } from "antd";

const useStyles = createUseStyles((theme) => ({
  demoHeader: {},
}));

interface DemoHeaderEmbededProps {}

const DemoHeaderEmbeded: React.FC<DemoHeaderEmbededProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.demoHeader}>
      <NxpHeader
        level={2}
        titleContent="Demo Header"
        subheadingContent={
          <>
            test subheading{" "}
            <Typography.Text type="success">
              <CheckCircleFilled />
            </Typography.Text>{" "}
          </>
        }
        actionContent={<NxpButton>Extra</NxpButton>}
      />
    </div>
  );
};

export default DemoHeaderEmbeded;
