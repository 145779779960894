import React, { useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { NxpToggleGroup } from "@nexploretechnology/nxp-ui";

import { AppTheme } from "../../../../app/components/appTheme";

const useStyles = createUseStyles((theme) => ({
  DemoToggleGroup: {},
}));

interface DemoToggleGroupProps {}

const DemoToggleGroup: React.FC<DemoToggleGroupProps> = () => {
  const classes = useStyles();

  const [singlarSelectedValue, setSinglarSelectedValue] = useState<string>();

  const handleSinglarToggleGroupChange = (group?: string) => {
    setSinglarSelectedValue(group);
  };

  const theme = useTheme<AppTheme>();

  const [multipleSelectedValue, setMultipleSelectedValue] = useState<string[]>(
    []
  );

  const handleMultipleToggleGroupChange = (groups?: string[]) => {
    setMultipleSelectedValue(groups || []);
  };

  return (
    <div className={classes.DemoToggleGroup}>
      <NxpToggleGroup
        multiple={false}
        selectedValue={singlarSelectedValue}
        onChange={handleSinglarToggleGroupChange}
        items={[
          {
            label: "Pending",
            value: "pending",
            color: theme.palette.dimDark,
          },
          {
            label: "Processing",
            value: "processing",
            color: theme.palette.warning,
          },
          {
            label: "Done",
            value: "Done",
            color: theme.palette.success,
          },
        ]}
      />
      <br />
      <p>multiple</p>
      <NxpToggleGroup
        multiple={true}
        selectedValues={multipleSelectedValue}
        onChange={handleMultipleToggleGroupChange}
        allButtonLabel="No Filter"
        items={[
          {
            label: "Pending",
            value: "pending",
            color: theme.palette.dimDark,
          },
          {
            label: "Processing",
            value: "processing",
            color: theme.palette.warning,
          },
          {
            label: "Done",
            value: "Done",
            color: theme.palette.success,
          },
        ]}
      />
    </div>
  );
};

export default DemoToggleGroup;
