import { NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";

export interface DemoUserForm {
  firstName: string;
  lastName?: string;
  age: number;
  gender?: "m" | "f" | "o";
  skills: string[];
  birthday: Date;
  isDead: boolean;
  kids: string[];
  nextKid?: string;
  balance?: number;
  growthRate?: number;
  supervisor?: number;
  teammates?: number[];
  workingLocation?: string;
}

export const demoUserFormItems: NxpFormGridItemProps<DemoUserForm>[] = [
  {
    controlType: "input",
    label: "First Name",
    itemFieldName: "firstName",
    required: true,
    span: 12,
  },
  {
    controlType: "input",
    controlProps: {
      disabled: true,
    },
    label: "Last Name",
    itemFieldName: "lastName",
    span: 12,
    error: "some custom error",
  },
  {
    controlType: "inputNumber",
    label: "Age",
    itemFieldName: "age",
    span: 8,
    startOnNewRow: true,
    controlProps: {
      decimalPlace: 0,
    },
  },
  {
    controlType: "select",
    controlProps: {
      allowClear: true,
      options: [
        { label: "Male", value: "m" },
        { label: "Female", value: "f" },
        { label: "Others", value: "o" },
      ],
    },
    label: "Gender",
    itemFieldName: "gender",
    span: 8,
  },
  {
    controlType: "selectMultiple",
    controlProps: {
      options: [
        { label: "Cooking", value: "cooking" },
        { label: "Painting", value: "painting" },
        { label: "Talking", value: "talking" },
      ],
    },
    startOnNewRow: true,
    label: "Skills",
    itemFieldName: "skills",
    span: 8,
  },
  {
    controlType: "datePicker",
    label: "Birthday",
    itemFieldName: "birthday",
    span: 12,
    startOnNewRow: true,
  },
  {
    controlType: "checkbox",
    label: "Is Dead",
    itemFieldName: "isDead",
    span: 6,
  },
  {
    controlType: "checkbox",
    label: "Is Dead with Yes",
    itemFieldName: "isDead",
    span: 6,
    controlProps: {
      children: "Yes",
    },
  },
  {
    controlType: "checkboxGroup",
    label: "Kids",
    itemFieldName: "kids",
    controlProps: {
      options: [
        { label: "Boy", value: "b" },
        { label: "Girl", value: "g" },
        { label: "Puppy", value: "p" },
        { label: "Kitten", value: "k" },
      ],
    },
    span: 12,
  },
  {
    controlType: "radioGroup",
    label: "Next Kid",
    itemFieldName: "nextKid",
    controlProps: {
      options: [
        { label: "Boy", value: "b" },
        { label: "Girl", value: "g" },
        { label: "Puppy", value: "p" },
        { label: "Kitten", value: "k" },
      ],
    },
    span: 12,
  },
  {
    controlType: "inputNumber",
    label: "Balance",
    itemFieldName: "balance",
    span: 6,
    controlProps: {
      prefix: "$",
      decimalPlace: 3,
    },
  },
  {
    controlType: "inputPercent",
    label: "Growth Rate",
    itemFieldName: "growthRate",
    span: 6,
  },
  {
    controlType: "selectUser",
    controlProps: {
      options: [
        { id: "123", name: "John" },
        { id: "234", name: "Mary" },
      ],
    },
    label: "Supervisor",
    itemFieldName: "supervisor",
    span: 6,
  },
  {
    controlType: "selectUserMultiple",
    controlProps: {
      options: [
        { id: "123", name: "John" },
        { id: "234", name: "Mary" },
      ],
    },
    label: "teammates",
    itemFieldName: "teammates",
    span: 6,
  },
  {
    controlType: "treeSelect",
    controlProps: {
      options: [
        {
          title: "Node1",
          value: "0-0",
          children: [
            {
              title: "Child Node1",
              value: "0-0-1",
            },
            {
              title: "Child Node2",
              value: "0-0-2",
            },
          ],
        },
        {
          title: "Node2",
          value: "0-1",
        },
      ],
    },
    label: "Location",
    itemFieldName: "workingLocation",
    span: 6,
  },
];
