// import { VocValueRegister } from '../../../types/valueRegister';
import React from "react";

import { VocValueRegister } from "../../../../app/types";

interface ValueRegisterCheckboxInputProps {
  id: string;
  checked: boolean;
  fieldName: keyof VocValueRegister;
  rowIndex: number;
  disabled?: boolean;
  onChange: (
    value: boolean,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => void;
  onKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => void;
  onFocus: (
    e: React.FocusEvent<HTMLInputElement>,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const ValueRegisterCheckboxInput: React.FC<ValueRegisterCheckboxInputProps> = ({
  id,
  checked,
  fieldName,
  rowIndex,
  disabled = false,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.focus();
    onChange(e.currentTarget.checked, fieldName, rowIndex);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    onFocus(e, fieldName, rowIndex);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown(e, fieldName, rowIndex);
  };

  return (
    <input
      type="checkbox"
      id={id}
      disabled={disabled}
      onFocus={handleFocus}
      onChange={handleChange}
      checked={checked}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
    />
  );
};

export default ValueRegisterCheckboxInput;
