import { ColumnDataType, DataTableColumn } from "../components/app-data-table";
import { VocEventListItem } from "../types";

export const compensationEventDefaultColumns: DataTableColumn<VocEventListItem>[] =
  [
    {
      key: "eventTypeSerial",
      name: "voc.events.eventTypeSerial",
      fieldName: "eventTypeSerial",
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "serial",
      name: "voc.events.serial",
      fieldName: "serial",
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "eventReference",
      name: "voc.events.eventReference",
      fieldName: "eventReference",
      isSortable: false,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "eventTitle",
      name: "voc.events.eventTitle",
      fieldName: "eventTitle",
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "eventType",
      name: "voc.events.eventType",
      fieldName: "eventType",
      isSortable: true,
      columnDataType: ColumnDataType.Select,
    },
    {
      key: "eventSource",
      name: "voc.events.eventSource",
      fieldName: "eventSource",
      minWidth: 138,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Select,
    },
    {
      key: "eventSourceType",
      name: "voc.events.eventSourceType",
      fieldName: "eventSourceType",
      isSortable: true,
      columnDataType: ColumnDataType.Select,
    },
    {
      key: "statusWithClient",
      name: "voc.events.statusWithClient",
      fieldName: "statusWithClient",
      isSortable: true,
      columnDataType: ColumnDataType.Select,
    },
    {
      key: "internalValue",
      name: "voc.events.internalValue",
      fieldName: "internalValue",
      isSortable: true,
      columnDataType: ColumnDataType.Money,
    },
    {
      key: "clientAssessed",
      name: "voc.events.clientAssessed",
      fieldName: "clientAssessed",
      isSortable: true,
      columnDataType: ColumnDataType.Money,
    },
    {
      key: "pendingApproval",
      name: "voc.events.pendingApproval",
      fieldName: "pendingApproval",
      isSortable: true,
      columnDataType: ColumnDataType.Money,
    },
    {
      key: "physicalProgress",
      name: "voc.events.physicalProgress",
      fieldName: "physicalProgress",
      isSortable: true,
      columnDataType: ColumnDataType.Percent,
    },
    {
      key: "internalProgressValue",
      name: "voc.events.internalProgressValue",
      fieldName: "internalProgressValue",
      isSortable: true,
      columnDataType: ColumnDataType.Money,
    },
    {
      key: "cumulativeValueCertified",
      name: "voc.events.cumulativeValueCertified",
      fieldName: "cumulativeValueCertified",
      isSortable: true,
      columnDataType: ColumnDataType.Money,
    },
    {
      key: "recovery",
      name: "voc.events.recovery",
      fieldName: "recovery",
      isSortable: true,
      columnDataType: ColumnDataType.Percent,
    },
    {
      key: "internalTarget",
      name: "voc.events.internalTarget",
      fieldName: "internalTarget",
      isSortable: true,
      columnDataType: ColumnDataType.Money,
    },
    {
      key: "targetDays",
      name: "voc.events.targetDays",
      fieldName: "targetDays",
      isSortable: true,
      columnDataType: ColumnDataType.Number,
    },
    {
      key: "agreeQuantumQty",
      name: "voc.events.agreeQuantumQty",
      fieldName: "agreeQuantumQty",
      isSortable: true,
      columnDataType: ColumnDataType.Boolean,
    },
    {
      key: "includedInReports",
      name: "voc.events.includedInReports",
      fieldName: "includedInReports",
      isSortable: true,
      columnDataType: ColumnDataType.Boolean,
    },
    {
      key: "awarded",
      name: "voc.common.awarded",
      fieldName: "awarded",
      isSortable: true,
      columnDataType: ColumnDataType.Boolean,
    },
    {
      key: "contemporaryRecordRequired",
      name: "voc.common.contemporaryRecordRequired",
      fieldName: "contemporaryRecordRequired",
      isSortable: true,
      columnDataType: ColumnDataType.Boolean,
    },
    {
      key: "commercialOwner",
      name: "voc.common.commercialOwner",
      fieldName: "commercialOwner",
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "recordOwner",
      name: "voc.common.recordOwner",
      fieldName: "recordOwner",
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "recordSupporter",
      name: "voc.common.recordSupporter",
      fieldName: "recordSupporter",
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "dateOfAwareness",
      name: "voc.common.dateOfAwareness",
      fieldName: "dateOfAwareness",
      isSortable: true,
      columnDataType: ColumnDataType.Date,
    },
    {
      key: "dateOfCessation",
      name: "voc.common.dateOfCessation",
      fieldName: "dateOfCessation",
      isSortable: true,
      columnDataType: ColumnDataType.Date,
    },
    {
      key: "createdOn",
      name: "voc.common.createdOn",
      fieldName: "createdOn",
      isSortable: true,
      columnDataType: ColumnDataType.Date,
    },
    {
      key: "updatedOn",
      name: "voc.common.updatedOn",
      fieldName: "updatedOn",
      isSortable: true,
      columnDataType: ColumnDataType.Date,
    },
    {
      key: "advanceInstruction",
      name: "voc.events.advanceInstruction",
      fieldName: "advanceInstruction",
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "clientVoRef",
      name: "voc.events.clientVoRef",
      fieldName: "clientVoRef",
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "claimTitle",
      name: "voc.events.claimTitle",
      fieldName: "claimTitle",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "claimSerial",
      name: "voc.events.claimSerial",
      fieldName: "claimSerial",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "appliedDays",
      name: "voc.events.appliedDays",
      fieldName: "appliedDays",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Number,
    },
    {
      key: "awardedDays",
      name: "voc.events.awardedDays",
      fieldName: "awardedDays",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Number,
    },
    {
      key: "agreeQuantumRates",
      name: "voc.events.agreeQuantumRates",
      fieldName: "agreeQuantumRates",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Boolean,
    },
    {
      key: "agreeQuantumOhnp",
      name: "voc.events.agreeQuantumOhnp",
      fieldName: "agreeQuantumOhnp",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Boolean,
    },
    {
      key: "claimSupportDocumentComment",
      name: "voc.events.claimSupportDocumentComment",
      fieldName: "claimSupportDocumentComment",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.String,
    },
    {
      key: "firstNotificationRequiredDate",
      name: "voc.events.firstNotificationRequiredDate",
      fieldName: "firstNotificationRequiredDate",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Date,
    },
    {
      key: "followUpNotificationRequiredDate",
      name: "voc.events.followUpNotificationRequiredDate",
      fieldName: "followUpNotificationRequiredDate",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Date,
    },
    {
      key: "finalNotificationRequiredDate",
      name: "voc.events.finalNotificationRequiredDate",
      fieldName: "finalNotificationRequiredDate",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Date,
    },
    {
      key: "numOfRelatedSiteEvents",
      name: "voc.events.numOfRelatedSiteEvents",
      fieldName: "numOfRelatedSiteEvents",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Number,
    },
    {
      key: "numNoActionRecs",
      name: "voc.events.numNoActionRecs",
      fieldName: "numNoActionRecs",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Number,
    },
    {
      key: "numOutstandingRecs",
      name: "voc.events.numOutstandingRecs",
      fieldName: "numOutstandingRecs",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Number,
    },
    {
      key: "numPreparedRecs",
      name: "voc.events.numPreparedRecs",
      fieldName: "numPreparedRecs",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Number,
    },
    {
      key: "numSubmittedRecs",
      name: "voc.events.numSubmittedRecs",
      fieldName: "numSubmittedRecs",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Number,
    },
    {
      key: "numberOfDays",
      name: "voc.events.numberOfDays",
      fieldName: "numberOfDays",
      minWidth: 100,
      isResizable: true,
      isSortable: true,
      columnDataType: ColumnDataType.Number,
    },
  ];
