import React from "react";

interface VocContextType {
  entityId: string;
  eventId: string;
  entityTimezone: string;
}

const VocContext = React.createContext<VocContextType>({
  entityId: undefined,
  eventId: undefined,
  entityTimezone: undefined,
});

export default VocContext;

export function withVocContext<P, T>(
  entityId: string,
  eventId: string,
  entityTimezone: string,
  WrappedComponent: React.ComponentType<P>
) {
  return React.forwardRef<T, P>((props, ref) => (
    <VocContext.Provider value={{ entityId, eventId, entityTimezone }}>
      <WrappedComponent {...props} ref={ref} />
    </VocContext.Provider>
  ));
}

export const useVocContext = () => {
  const vocCtx = React.useContext(VocContext);
  return {
    entityId: vocCtx.entityId,
    eventId: vocCtx.eventId,
    entityTimezone: vocCtx.entityTimezone,
  };
};
