import React from "react";
import { useTranslation } from "react-i18next";

import { ValidationResult } from "@nexploretechnology/nxp-ui";
import { DatePicker } from "office-ui-fabric-react";

import AppButton from "../../../../app/components/app-button";
import {
  AppFormDropdownField,
  AppFormField,
  AppFormRow,
} from "../../../../app/components/app-form";
import AppModal from "../../../../app/components/app-modal";
import { AsyncResult } from "../../../../app/hooks/useAsync";

import {
  VocRecordBatchUpdateData,
  VocRecordStatus,
} from "../../../../app/types";
import { BatchUpdateEvent } from "../RecordsTabLayout";

import CustomApiError from "../../../../app/utils/backend/custom-api-error";
import { mapDateWithWeekdaysToString } from "../../../../app/utils/mappers";
import "./BatchUpdateModal.scss";

interface BatchUpdateModalProps {
  showBatchUpdateModal: boolean;
  form: VocRecordBatchUpdateData;
  batchUpdateResult: AsyncResult;
  validationError: ValidationResult<VocRecordBatchUpdateData>;
  onBatchUpdate: () => void;
  onFormChange: (changeValues: Partial<VocRecordBatchUpdateData>) => void;
  onModalClose: () => void;
  events: BatchUpdateEvent[];
}

const BatchUpdateModal: React.FC<BatchUpdateModalProps> = ({
  showBatchUpdateModal,
  form,
  batchUpdateResult,
  validationError,
  onBatchUpdate,
  onFormChange,
  onModalClose,
  events,
}) => {
  const inputErrors = {
    ...validationError,
    ...(batchUpdateResult.error as CustomApiError)?.serverResponseData,
  };
  const { t } = useTranslation();

  return (
    <AppModal
      className="voc-batch-update-modal"
      title={t("voc.eventPage.records.batchUpdate")}
      isOpen={showBatchUpdateModal}
      footer={
        <AppButton onClick={onBatchUpdate}>{t("voc.common.save")}</AppButton>
      }
      loading={batchUpdateResult.loading}
      onClose={onModalClose}
      errorContent={
        !(batchUpdateResult.error as CustomApiError)?.serverResponseData &&
        batchUpdateResult.error?.message
      }
    >
      <AppFormRow>
        <AppFormField>
          <div className="voc-event-records-date-range">
            <DatePicker
              className={inputErrors.fromDate ? "error" : undefined}
              label={t("voc.eventPage.records.dateRange")}
              placeholder={t("voc.common.pleaseSelect")}
              isRequired={true}
              value={form.fromDate}
              maxDate={new Date()}
              onSelectDate={(date) => {
                onFormChange({ fromDate: date });
              }}
              disableAutoFocus={true}
              onClick={(e) => {
                // for avoiding fluent-ui default focus
                (e.currentTarget as HTMLElement).querySelector("i").click();
              }}
              formatDate={(date) => {
                return mapDateWithWeekdaysToString(date);
              }}
            />
            <span className="to">{t("voc.eventPage.records.to")}</span>
            <DatePicker
              className={inputErrors.toDate ? "error" : undefined}
              isRequired={true}
              placeholder={t("voc.common.pleaseSelect")}
              value={form.toDate}
              maxDate={new Date()}
              onSelectDate={(date) => {
                onFormChange({ toDate: date });
              }}
              formatDate={(date) => {
                return mapDateWithWeekdaysToString(date);
              }}
            />
          </div>
          {inputErrors && inputErrors.fromDate && (
            <p className="error">{inputErrors.fromDate}</p>
          )}
          {inputErrors && inputErrors.toDate && (
            <p className="error to-date">{inputErrors.toDate}</p>
          )}
        </AppFormField>
      </AppFormRow>
      <AppFormRow>
        <AppFormDropdownField
          label={t("voc.eventPage.records.status")}
          required={true}
          value={form.status}
          options={Object.entries(VocRecordStatus).map((entry) => ({
            key: entry[1],
            text: entry[0].replace(/_/g, " "),
          }))}
          errorMessage={inputErrors && inputErrors.status}
          onChange={(_, option) =>
            onFormChange({ status: option.key as VocRecordStatus })
          }
        />
      </AppFormRow>
      <AppFormRow>
        <AppFormDropdownField
          className="event-multiselect"
          label={t("voc.common.event_one")}
          required={true}
          selectedKeys={form.serials}
          multiSelect
          options={events.map((se) => ({
            key: se.serial,
            text: se.serial + ": " + se.title,
          }))}
          errorMessage={inputErrors && inputErrors.serials}
          onChange={(_, option) => {
            if (form.serials.includes(String(option.key))) {
              onFormChange({
                serials: [
                  ...form.serials.filter((s) => s !== String(option.key)),
                ],
              });
            } else {
              onFormChange({ serials: [...form.serials, String(option.key)] });
            }
          }}
        />
      </AppFormRow>
    </AppModal>
  );
};

export default BatchUpdateModal;
