import "./CreateSiteEventModal.scss";

import { IDropdownOption, TextField } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";

import { ValidationResult } from "@nexploretechnology/nxp-ui";

import AppButton from "../../../../app/components/app-button";
import {
  AppFormCalendarField,
  AppFormDropdownField,
  AppFormField,
  AppFormLibraryDropdownField,
  AppFormMultiFileField,
  AppFormRow,
  AppFormTextField,
} from "../../../../app/components/app-form";
import AppModal from "../../../../app/components/app-modal";
import { VocSiteEventPostRequest } from "../../../../app/types";
import { INSTRUCTED_BY_OTHER } from "../../../../app/utils/const";
import { LocationOption } from "../../../../app/utils/location";
import {
  mapDateToString,
  stringToDateFormat,
} from "../../../../app/utils/mappers";

interface CreateSiteEventModalProps {
  entityId: string;
  showCreateModal: boolean;
  form: Partial<VocSiteEventPostRequest>;
  locationOptions: LocationOption[];
  empowerRepsDropdownOptions: IDropdownOption[];
  onFormChange: (changeValues: Partial<VocSiteEventPostRequest>) => void;
  onFormSave: (addAnother: boolean) => void;
  onModalClose: () => void;
  validationError: ValidationResult<VocSiteEventPostRequest>;
  saveInProgress: boolean;
}

const CreateSiteEventModal: React.FC<CreateSiteEventModalProps> = ({
  entityId,
  showCreateModal,
  form,
  locationOptions,
  empowerRepsDropdownOptions,
  onFormChange,
  onFormSave,
  onModalClose,
  validationError,
  saveInProgress,
}) => {
  const { t } = useTranslation();

  return (
    <AppModal
      title={t("voc.createEvent.createNewEvent")}
      className="voc-create-site-event-modal"
      isOpen={showCreateModal}
      loading={saveInProgress}
      onClose={onModalClose}
      footer={
        <AppButton
          disabled={saveInProgress}
          className="button"
          onClick={() => onFormSave(false)}
          text={t("voc.common.save")}
        />
      }
    >
      <AppFormRow>
        <AppFormTextField
          required={true}
          label={t("voc.eventPage.summary.subject")}
          name="subject"
          value={form.subject}
          errorMessage={validationError?.subject}
          onChange={(_, value) => onFormChange({ subject: value })}
        />
      </AppFormRow>
      <AppFormRow>
        <AppFormTextField
          required={true}
          label={t("voc.siteEventDetails.remark")}
          name="remarks"
          value={form.remarks}
          multiline={true}
          errorMessage={validationError?.remarks}
          onChange={(_, value) => onFormChange({ remarks: value })}
        />
      </AppFormRow>
      <AppFormRow>
        <AppFormLibraryDropdownField
          label={t("voc.siteEventDetails.source")}
          required={true}
          placeholder={t("voc.common.pleaseSelect")}
          entityId={entityId}
          libraryKey="VOC_SOURCES"
          value={form.eventSource}
          errorMessage={validationError?.eventSource}
          onChange={(_, option) =>
            onFormChange({
              eventSource: option.key.toString(),
            })
          }
        />
        <AppFormTextField
          label={t("voc.siteEventDetails.reference")}
          name="reference"
          value={form.reference}
          errorMessage={validationError?.reference}
          onChange={(_, value) => onFormChange({ reference: value })}
        />
      </AppFormRow>
      <AppFormRow>
        <AppFormField className="location-picker">
          <AppFormDropdownField
            calloutProps={{ className: "voc-location-select" }}
            label={t("voc.siteEventDetails.location")}
            placeholder={t("voc.common.pleaseSelect")}
            options={locationOptions.map((option) => ({
              key: option.enumValue,
              text: option.render,
              disabled: option.disabled ? option.disabled : false,
            }))}
            selectedKeys={form.locations}
            multiSelect={true}
            errorMessage={validationError?.locations}
            onChange={(_, option) => {
              const key = option.key.toString();
              const prevArr = form.locations ? [...form.locations] : [];
              onFormChange({
                locations: option.selected
                  ? [...prevArr, option.key.toString()]
                  : form.locations?.filter(
                      (item) =>
                        !(
                          item === key ||
                          locationOptions.find(
                            (locationOption) =>
                              locationOption.enumValue === item &&
                              locationOption.childOf === key
                          )
                        )
                    ),
              });
            }}
          />
        </AppFormField>
        <AppFormField>
          <AppFormDropdownField
            label={t("voc.eventPage.VODetails.instructionIssuedBy")}
            placeholder={t("voc.common.pleaseSelect")}
            options={empowerRepsDropdownOptions}
            errorMessage={validationError?.instructedBy}
            required
            onChange={(_, option) => {
              onFormChange({
                instructedBy: option.key.toString(),
              });
            }}
          />
          {form.instructedBy === INSTRUCTED_BY_OTHER && (
            <span className="specify-other">
              {t("voc.eventPage.VODetails.specify")}
              <TextField
                value={form.instructedByOther}
                onChange={(_, value) => {
                  onFormChange({
                    instructedByOther: value,
                  });
                }}
              />
            </span>
          )}
        </AppFormField>
      </AppFormRow>
      <AppFormRow>
        <AppFormCalendarField
          label={t("voc.eventPage.notifications.dateOfAwareness")}
          isRequired={true}
          value={stringToDateFormat(form.dateOfAwareness)}
          onChange={(date) =>
            onFormChange({ dateOfAwareness: mapDateToString(date) })
          }
          errorMessage={validationError?.dateOfAwareness}
        />
        <AppFormMultiFileField
          files={form.files?.length ? [...form.files] : []}
          onFileChange={(files) =>
            onFormChange({
              files,
            })
          }
          label={t("voc.common.files")}
          onRemove={() => onFormChange({ files: [] })}
          errorMessage={validationError?.files}
        />
      </AppFormRow>
    </AppModal>
  );
};

export default CreateSiteEventModal;
