import React from "react";

import { NxpDescriptionList } from "@nexploretechnology/nxp-ui";

interface DemoDescriptionListProps {}

const DemoDescriptionList: React.FC<DemoDescriptionListProps> = () => {
  return (
    <NxpDescriptionList headerWidth={100}>
      <dt>Test term</dt>
      <dd>Test Definition</dd>
      <dt>Test term Test term Test term Test term Test term</dt>
      <dd>Test Definition</dd>
      <dt>Test term</dt>
      <dd>
        Test Definition Test Definition Test Definition Test Definition Test
        Definition
      </dd>
      <dt>Test term</dt>
      <dd>Test Definition</dd>
    </NxpDescriptionList>
  );
};

export default DemoDescriptionList;
