import { UserInfo } from "../../../app/types";

export function getUserKey(info: UserInfo) {
  if (info) {
    return info.key;
  } else if (info === null) {
    return null;
  } else {
    return undefined;
  }
}
