import { NxpLabel, NxpModal } from "@nexploretechnology/nxp-ui";
import { Form, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { VocSupportDocumentEnums } from "../../../../app/types";
import { vocSupportDocumentOptions } from "./new-document-form-type";
import NewContemporaryRecordForm from "./new-document-forms/general/NewContemporaryRecordForm";
import NewDailyDiariesForm from "./new-document-forms/general/NewDailyDiariesForm";
import NewLabourRecordForm from "./new-document-forms/general/NewLabourRecordForm";
import NewPlantRecordForm from "./new-document-forms/general/NewPlantRecordForm";
import NewStaffRecordTimeSheetForm from "./new-document-forms/general/NewStaffRecordTimeSheetForm";
import NewAcaForm from "./new-document-forms/NewAcaForm";
import NewCorrespondenceForm from "./new-document-forms/NewCorrespondenceForm";
import NewDrawingForm from "./new-document-forms/NewDrawingForm";
import NewMinervaForm from "./new-document-forms/NewMinervaForm";
import NewOtherDocumentForm from "./new-document-forms/NewOtherDocumentForm";
import NewSciForm from "./new-document-forms/NewSciForm";

interface NewDocumentModalProps {
  onModalClose: () => void;
  onRefresh: () => void;
}

const NewSupportingDocumentModal: React.FC<NewDocumentModalProps> = ({
  onModalClose,
  onRefresh,
}) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    documentType: "",
  });

  const handleChange = (value) => {
    setFormState({ documentType: value });
  };

  return (
    <NxpModal
      open={true}
      title={t("voc.common.newDocument")}
      onCancel={onModalClose}
    >
      <Form layout="vertical">
        <Form.Item
          label={<NxpLabel>{t("voc.common.documentType")}</NxpLabel>}
          required
        >
          <Select
            value={formState.documentType}
            showSearch
            allowClear
            onChange={handleChange}
            options={vocSupportDocumentOptions.map((type) => ({
              label: type.label,
              value: type.value,
            }))}
            filterOption={(input, option) =>
              option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>
      </Form>
      {formState.documentType === VocSupportDocumentEnums.Correspondence && (
        <NewCorrespondenceForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}
      {formState.documentType === VocSupportDocumentEnums.Labour_Records && (
        <NewLabourRecordForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}
      {formState.documentType === VocSupportDocumentEnums.Plant_Records && (
        <NewPlantRecordForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}
      {formState.documentType ===
        VocSupportDocumentEnums.Staff_Records_Timesheets && (
        <NewStaffRecordTimeSheetForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}
      {formState.documentType === VocSupportDocumentEnums.Daily_Diaries && (
        <NewDailyDiariesForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}
      {formState.documentType === VocSupportDocumentEnums.Subcontractor_ACA && (
        <NewAcaForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}
      {formState.documentType === VocSupportDocumentEnums.Subcontractor_SCI && (
        <NewSciForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}
      {formState.documentType ===
        VocSupportDocumentEnums.Contemporary_Records && (
        <NewContemporaryRecordForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}
      {formState.documentType ===
        VocSupportDocumentEnums.Subcontractor_Minerva && (
        <NewMinervaForm onModalClose={onModalClose} />
      )}
      {formState.documentType === VocSupportDocumentEnums.Other_Documents && (
        <NewOtherDocumentForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}

      {formState.documentType === VocSupportDocumentEnums.Drawings && (
        <NewDrawingForm
          documentType={formState.documentType}
          onRefresh={onRefresh}
          onModalClose={onModalClose}
        />
      )}
    </NxpModal>
  );
};

export default NewSupportingDocumentModal;
