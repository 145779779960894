import { Route } from "react-router-dom";

import AppProtectedPage from "../app/components/app-router/AppProtectedPage";
import Libraries from "./pages/Libraries";

export const librariesRoutes = [
  <Route
    key="libraries"
    path="/entities/:entityId/libraries"
    element={<AppProtectedPage requireEntityReady component={<Libraries />} />}
  />,
];
