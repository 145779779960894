import React from "react";
import { createUseStyles } from "react-jss";

import { NxpHeader, NxpTabs, TabPane } from "@nexploretechnology/nxp-ui";

const useStyles = createUseStyles(() => ({
  demoHeader: {},
}));

interface DemoHeaderTabsProps {}

const DemoHeaderTabs: React.FC<DemoHeaderTabsProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.demoHeader}>
      <NxpHeader
        titleContent="Demo Header"
        actionContent={
          <>
            <NxpTabs>
              <TabPane tab="test" key="123"></TabPane>
              <TabPane tab="test" key="1236"></TabPane>
              <TabPane tab="test" key="1235"></TabPane>
              <TabPane tab="test" key="1234"></TabPane>
            </NxpTabs>
          </>
        }
      />
    </div>
  );
};

export default DemoHeaderTabs;
