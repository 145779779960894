import httpRequest from "./http-request";

export interface ServiceConfig {
  entityId?: string;
  token?: string;
  apiBaseUrl: string;
  userDirectoryServiceApiBaseUrl: string;
  entityServiceApiBaseUrl: string;
  fileServiceApiBaseUrl: string;
  qcAppBaseUrl: string;
  timezoneHourAdjustment?: number;
}

export async function apiRequest<R = any>(
  url: string,
  serviceConfig: ServiceConfig,
  options?: RequestInit,
  retryLimit = 0,
  lastRetryDelay = 2000
) {
  const requestSettings: RequestInit = {
    ...options,
    headers: {
      Authorization: `Bearer ${serviceConfig.token}` || "",
      ...options?.headers,
    },
  };

  return httpRequest<R>(
    url.startsWith("http://") || url.startsWith("https://")
      ? url
      : `${serviceConfig.apiBaseUrl}/entities/${serviceConfig.entityId}${url}`,
    requestSettings,
    retryLimit,
    lastRetryDelay
  );
}
