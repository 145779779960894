import React from "react";

import translations from "../../i18n/en/translations.json";

interface TranslationCheckProps {}

const TranslationCheck: React.FC<TranslationCheckProps> = () => {
  const valueCountData: {
    [key: string]: { count: number; jsonKeys: string[] };
  } = {};

  Object.keys(translations).forEach((key) => {
    const valueCountKey = (translations as { [key: string]: string })[key]
      .toLowerCase()
      .trim();
    if (valueCountData[valueCountKey]) {
      valueCountData[valueCountKey] = {
        count: valueCountData[valueCountKey].count + 1,
        jsonKeys: [...valueCountData[valueCountKey].jsonKeys, key],
      };
    } else {
      valueCountData[valueCountKey] = {
        count: 1,
        jsonKeys: [key],
      };
    }
  });

  const valueCountKeys = Object.keys(valueCountData);

  return (
    <div>
      <h1>
        Checked{" "}
        {`${Object.keys(translations).length} translations with ${
          valueCountKeys.length
        } unique values`}{" "}
      </h1>
      {valueCountKeys.map((valueKey) =>
        valueCountData[valueKey].count > 1 ? (
          <p
            style={{ color: "red", whiteSpace: "pre" }}
          >{`${valueKey} -- found ${
            valueCountData[valueKey].count
          } times -- in \n${valueCountData[valueKey].jsonKeys.join(
            `, \n`
          )}`}</p>
        ) : (
          <p>{`${valueKey} -- ok`}</p>
        )
      )}
    </div>
  );
};

export default TranslationCheck;
