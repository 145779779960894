import { FilterOption } from "../components/app-filter";
import {
  UserInfo,
  VocFeedback,
  VocFeedbackCreation,
  VocFeedbackListItem,
} from "../types";
import { apiRequest, ServiceConfig } from "../utils/backend/api-request";
import { prepareFilterOptions } from "../utils/backend/prepare-filter-options";
import { REGISTER_LAZY_LOAD_SIZE } from "../utils/const";
import { hasValue } from "../utils/has-value";
import { convertToJsonBody } from "./convert-to-json-body";
import exportRequest from "./export-request";
import { PageRequest } from "./vocService";

export interface Entity {
  id: string;
  name: string;
  entityCode: string;
}

export interface RegisterType {
  value: string;
  label: string;
}

export interface CPCSUser {
  id: string;
  salutation?: string | null;
  firstName: string;
  lastName: string;
  displayName: string;
  title?: string | null;
  primaryEmail: string;
  mobileNumber?: string | null;
  officeNumber?: string | null;
  comments?: string | null;
  isSuspended: boolean;
  avatar?: {
    filename: string;
    assetId: string;
    url: string;
  } | null;
}
export type EntityUser = {
  id: string;
  keycloakId: string;
  displayName?: string;
  name: string;
  email: string;
  isSuspended: boolean;
  createdOn?: Date;
  updatedOn?: Date;
};

type RightObjectCode =
  | "event"
  | "customizations"
  | "access"
  | "role"
  | "compensation_event"
  | "libraries"
  | "values"
  | "feedbacks"
  | "dashboard";
type actionType =
  | "create"
  | "edit"
  | "view"
  | "assign"
  | "delete"
  | "admin"
  | "reader";

// write type
export type Right = {
  objectCode: RightObjectCode;
  actionType: actionType;
};

export type RoleList = {
  id: string;
  objectCode: string;
  action: string;
};

export const userSalutationValues = ["Ms.", "Mrs.", "Mr."] as const;
export type UserSalutation = (typeof userSalutationValues)[number];

export interface UserRightList {
  // eg, "subcontractor-add": UserRightListItem[];
  // or,  "user-create": boolean;
  "demo-view": boolean;
}

export const getUserDisplayName = (
  user?: CPCSUser | EntityUser | UserInfo | Record<string, any>
) => {
  if (!hasValue(user)) {
    return "";
  }
  if (typeof user === "string") {
    return user;
  }

  if (hasValue(user.displayName)) {
    return user.displayName;
  }

  if ("name" in user && hasValue(user.name)) {
    return user.name;
  }

  if ("firstName" in user && hasValue(user.firstName)) {
    return `${user.firstName} ${user.lastName}`;
  }

  return "";
};

export const getEntityInfo = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<Entity>(
    `${serviceConfig.entityServiceApiBaseUrl}/entity/${serviceConfig.entityId}`,
    serviceConfig
  );
};

export const getCPCSUsers = async (serviceConfig: ServiceConfig) => {
  return apiRequest<CPCSUser[]>(
    `${serviceConfig.userDirectoryServiceApiBaseUrl}/user/${serviceConfig.entityId}?showIsSuspended=false`,
    serviceConfig
  );
};

export const getEntityUsers = async (serviceConfig: ServiceConfig) => {
  const entityUsers = await apiRequest<EntityUser[]>(`/users`, serviceConfig);

  return entityUsers.filter((user) => !user.isSuspended);
};

// export const getUserRightList = async (serviceConfig: ServiceConfig) => {
//   // return await apiRequest<UserRightList>(
//   //   `/entities/${serviceConfig.entityId}/user-right-list`,
//   //   serviceConfig
//   // );
//   return Promise.resolve({ "demo-view": true });
// };

export const getMe = async (serviceConfig: ServiceConfig) => {
  return apiRequest<CPCSUser>(
    `${serviceConfig.userDirectoryServiceApiBaseUrl}/user/myself`,
    serviceConfig
  );
};

export const updateMyPassword = async (
  newPassword: string,
  serviceConfig: ServiceConfig
) => {
  return apiRequest(
    `${serviceConfig.userDirectoryServiceApiBaseUrl}/user/password/me`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        secret: newPassword,
      }),
    }
  );
};

export const getMyRights = async (serviceConfig: ServiceConfig) => {
  return apiRequest(`/rights/me`, serviceConfig);
};

export const getFeedback = (
  serviceConfig: ServiceConfig,
  pageRequest: Partial<PageRequest>,
  filterOptions?: FilterOption[]
): Promise<VocFeedbackListItem[]> => {
  const filters = !filterOptions ? [] : prepareFilterOptions(filterOptions);
  const perPage =
    pageRequest.perPage === 0 ? REGISTER_LAZY_LOAD_SIZE : pageRequest.perPage;
  const query = {
    limit: perPage,
    offset: parseInt(pageRequest.page)
      ? (parseInt(pageRequest.page) - 1) * perPage
      : 0,
    sortColumn: pageRequest.sort,
    sortOrder: pageRequest.order,
    filters: filters.length > 0 ? filters : [],
  };

  return apiRequest<VocFeedbackListItem[]>(`/search/feedbacks`, serviceConfig, {
    method: "POST",
    body: JSON.stringify(query),
  });
};

export const createFeedback = (
  event: Partial<VocFeedbackCreation>,
  serviceConfig: ServiceConfig
): Promise<VocFeedback> => {
  return apiRequest<VocFeedback>(`/feedbacks`, serviceConfig, {
    credentials: "same-origin",
    method: "POST",
    body: convertToJsonBody(event, serviceConfig.timezoneHourAdjustment),
  });
};

export const getExcel = async (
  screenId: number,
  serviceConfig: ServiceConfig
) => {
  return exportRequest(
    `${serviceConfig.apiBaseUrl}/entities/${serviceConfig.entityId}/export/${screenId}`,
    {
      credentials: "same-origin",
      method: "GET",
      headers: {
        Authorization: `Bearer ${serviceConfig.token}` || "",
      },
    }
  );
};
