import React from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router";

import { NxpHeader, NxpMainSplitter } from "@nexploretechnology/nxp-ui";

const useStyles = createUseStyles((theme) => ({
  demoSplitter: {},
  demoSplitterDetails: {
    position: "relative",
    margin: theme.spacing(2.5, 0, 0, 5),
  },
}));

interface DemoSplitterProps {}

const DemoSplitter: React.FC<DemoSplitterProps> = () => {
  const navigate = useNavigate();

  const classes = useStyles();

  return (
    <div className={classes.demoSplitter}>
      <NxpMainSplitter>
        <div>left content here</div>
        <div className={classes.demoSplitterDetails}>
          <NxpHeader
            titleContent="Demo Splitter"
            onBackClick={() => navigate(-1)}
          />
          123demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
          demo
          <br />
        </div>
      </NxpMainSplitter>
    </div>
  );
};

export default DemoSplitter;
