import React, { useState } from "react";

import {
  notify,
  NxpButton,
  NxpFormGrid,
  NxpPanelEditable,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import { DemoUserForm, demoUserFormItems } from "./demoUserForm";

const formSchema = yup.object({
  firstName: yup.string().nullable().required("First Name required."),
  lastName: yup.string(),
  birthday: yup.date().required("Birthday required."),
});

interface FormGridPanelProps {}

const FormGridPanel: React.FC<FormGridPanelProps> = () => {
  const [userForm, setUserForm] = useState<DemoUserForm>({
    firstName: "",
    lastName: "Chan",
    age: 18,
    gender: "m",
    skills: [],
    isDead: false,
    kids: [],
    birthday: new Date(),
  });

  const handleSaveValidated = async () => {
    notify.actionCompleted();
  };

  const [validationError, , , saveWithValidate] = useYupValidate<
    Pick<DemoUserForm, "firstName" | "lastName" | "birthday">
  >(userForm, formSchema, handleSaveValidated);

  const handleSave = () => {
    return (
      Object.values(saveWithValidate(undefined)).filter(
        (val) => val !== undefined
      ).length === 0
    );
  };

  const handleFormGridStateChange = (
    fieldName: keyof DemoUserForm,
    value: unknown
  ) => {
    console.log("ccccccccccccccc", fieldName, value);
    if (
      fieldName === "age" &&
      Number.isFinite(value) &&
      (value as number) > 100
    ) {
      setUserForm((prevState) => ({
        ...prevState,
        age: 100,
      }));
      return;
    }

    if (fieldName === "firstName" && value === "peter") {
      setUserForm((prevState) => ({
        ...prevState,
        firstName: value,
        lastName: "Hung",
      }));
      return;
    }

    setUserForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handlePanelSave = (setEditing: (editing: boolean) => void) => {
    if (handleSave()) {
      setEditing(false);
    }
  };

  return (
    <NxpPanelEditable
      titleContent="User Form in Panel"
      defaultCollapsed={true}
      onPanelSave={handlePanelSave}
    >
      {(editing, updateEditing) => (
        <NxpFormGrid
          editing={editing}
          validationError={validationError}
          formItems={[
            ...demoUserFormItems,
            {
              controlType: "custom",
              itemFieldName: "custom",
              span: 6,
              customContent: (
                <NxpButton
                  onClick={() => updateEditing(!editing)}
                  className="no-label-form-item"
                  disabled={editing}
                >
                  edit
                </NxpButton>
              ),
            },
          ]}
          formState={userForm}
          onFormStateChange={handleFormGridStateChange}
        />
      )}
    </NxpPanelEditable>
  );
};

export default FormGridPanel;
