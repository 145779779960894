import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import {
  ChoiceGroup,
  DatePicker,
  Dropdown,
  IBasePickerSuggestionsProps,
  ITag,
  TagPicker,
  TooltipHost,
} from "office-ui-fabric-react";

import useAppContext from "../../../app/hooks/useAppContext";
import { VocEventShort } from "../../types";
import { mapDateWithWeekdaysToString } from "../../utils/mappers";
import { convertUrl } from "../../utils/string";
import AppFileUpload from "../app-file-upload/AppFileUpload";

import "./AppFieldBlock.scss";
import { getEventListShort } from "../../../compensation-events/compensation-event-services";
import { formatDate } from "../app-data-list/helper";
import { getUserDisplayName } from "../../utils/helper/get-user-display-name";

export interface Option {
  render: string;
  enumValue: string;
  disabled?: boolean;
  childOf?: string;
}

export interface Field {
  label: string;
  identifier?: string;
  key?: string;
  value: any; //actual use case for any
  type: string;
  width: string;
  editable: boolean;
  onChangeCallback?: Function;
  stringConversionDecimals?: number;
  stringConversionSymbol?: string;
  dropdownCategory?: string;
  options?: Option[]; //only needed for basic dropdown fields
  validationError?: boolean;
  validationErrorMessage?: string;
  itemLimit?: number; //only needed for owners, but can expand for others including files
  stringHyperlink?: string;
  referenceAsLink?: string | ReactNode;
  tooltip?: string;
  highlight?: boolean;
  varianceFlip?: boolean;
  sideBorder?: boolean;
  bottomBorder?: boolean;
  required?: boolean;
  hideWhenEdit?: boolean;
}

//when you get to decomposing Summary, drop in a new prop "isConfiguring" to control field order
interface AppFieldBlockProps {
  fields: Field[];
  editing: boolean;
  entityId?: string;
}

interface ITagSerial extends ITag {
  serial: string;
}

interface ITagPlus extends ITag {
  isNewItem: boolean;
}

const AppFieldBlock: React.FC<AppFieldBlockProps> = (props) => {
  const { fields, editing } = props;
  const { serviceConfig, entityUsers } = useAppContext();
  const { t } = useTranslation();
  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: t("voc.appFieldBlockComponent.suggestedChoices"),
    noResultsFoundText: t("voc.appFieldBlockComponent.noResultsFound"),
  };

  const [usersDropdownOptions, setUsersDropdownOptions] = React.useState<
    ITag[] | null
  >(null);
  const [compEventsDropdownOptions, setCompEventsDropdownOptions] =
    React.useState<ITagSerial[] | null>(null);

  React.useEffect(() => {
    if (fields.filter((field) => field.type === "owner").length > 0) {
      setUsersDropdownOptions(
        entityUsers.map((user) => ({
          name: getUserDisplayName(user, false, true),
          key: user.id,
        }))
      );
    }
    if (fields.filter((field) => field.type === "compevent").length > 0) {
      getEventListShort(serviceConfig).then((data) => {
        setCompEventsDropdownOptions(
          data.map((event: VocEventShort) => ({
            name: `${event.eventTitle} (${event.serial})`,
            key: event.id,
            serial: event.serial,
          }))
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterEntityUserTags = (
    filterText: string,
    tagList: ITag[] | undefined
  ): ITag[] => {
    if (!usersDropdownOptions) {
      return []; //null;
    }
    return filterText && tagList
      ? usersDropdownOptions.filter((tag: ITag) => {
          return (
            !tagList.find((selected) => selected.key === tag.key) &&
            tag.name &&
            tag.name.toLowerCase().includes(filterText.toLowerCase())
          );
        })
      : [];
  };

  const filterCompEventTags = (
    filterText: string,
    tagList: ITag[] | undefined
  ): ITag[] => {
    if (!compEventsDropdownOptions) {
      return []; // null;
    }
    return filterText && tagList
      ? compEventsDropdownOptions.filter(
          (tag: ITagSerial) =>
            !tagList.find((selected) => selected.key === tag.key) &&
            (tag.name.toLowerCase().includes(filterText.toLowerCase()) ||
              tag.serial.toLowerCase().includes(filterText.toLowerCase()))
        )
      : [];
  };

  const getTextFromItem = (item: ITag) => item.name;

  // function formatDate(date: Date) {
  //   const d = new Date(date);
  //   let month = "" + (d.getMonth() + 1);
  //   let day = "" + d.getDate();
  //   const year = d.getFullYear();

  //   if (month.length < 2) month = "0" + month;
  //   if (day.length < 2) day = "0" + day;

  //   return [year, month, day].join("-");
  // }

  function formatForEnums(value: string, options: Option[]) {
    const enumEntry = options.filter((option) => option.enumValue === value);

    if (enumEntry.length > 0) {
      return enumEntry[0] ? enumEntry[0].render : undefined;
    } else {
      return value;
    }
  }

  function handleChangeCallback(
    onChangeCallback: Function | undefined,
    label: string,
    key: string,
    identifier: string | undefined,
    value: any,
    valueType: string
  ) {
    if (onChangeCallback) {
      onChangeCallback(
        key,
        label + (identifier ? identifier : ""),
        valueType === "string" ? (value.length === 0 ? "" : value) : value
      );
    }
  }

  return (
    <div className="field-block">
      {fields.map((field, i) => (
        <div
          key={i}
          className={
            "field " +
            (field.highlight ? "highlight " : "") +
            (field.varianceFlip ? "variance-flip " : "") +
            (field.bottomBorder ? "bottom-set-border " : "") +
            (field.sideBorder ? "right-set-border " : "") +
            field.width
          }
        >
          <div className="field-label">
            <div
              className={`field-label-text ${
                field?.required ? "required" : ""
              }`}
            >
              {field.label}
            </div>
            {field.tooltip ? (
              <TooltipHost content={field.tooltip}>
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.5 14.5625C9.24048 14.5625 10.9097 13.8711 12.1404 12.6404C13.3711 11.4097 14.0625 9.74048 14.0625 8C14.0625 6.25952 13.3711 4.59032 12.1404 3.35961C10.9097 2.1289 9.24048 1.4375 7.5 1.4375C5.75952 1.4375 4.09032 2.1289 2.85961 3.35961C1.6289 4.59032 0.9375 6.25952 0.9375 8C0.9375 9.74048 1.6289 11.4097 2.85961 12.6404C4.09032 13.8711 5.75952 14.5625 7.5 14.5625ZM7.5 15.5C9.48912 15.5 11.3968 14.7098 12.8033 13.3033C14.2098 11.8968 15 9.98912 15 8C15 6.01088 14.2098 4.10322 12.8033 2.6967C11.3968 1.29018 9.48912 0.5 7.5 0.5C5.51088 0.5 3.60322 1.29018 2.1967 2.6967C0.790176 4.10322 0 6.01088 0 8C0 9.98912 0.790176 11.8968 2.1967 13.3033C3.60322 14.7098 5.51088 15.5 7.5 15.5Z"
                      fill="#8A9999"
                    />
                    <path
                      d="M4.92188 6.15594H6.15938C6.15938 5.42375 6.58875 4.85844 7.43438 4.85844C8.07656 4.85844 8.66531 5.18 8.66531 5.95344C8.66531 6.54875 8.31469 6.8225 7.76063 7.23875C7.12969 7.69719 6.63 8.2325 6.66563 9.10156L6.67219 9.53562H7.8975V9.20281C7.8975 8.52969 8.15344 8.33375 8.84438 7.80969C9.41531 7.37562 10.0106 6.89375 10.0106 5.88219C10.0106 4.46563 8.81438 3.78125 7.50469 3.78125C6.26156 3.78125 4.89281 4.38781 4.92281 6.15594H4.92188ZM6.38625 11.3272C6.38625 11.8269 6.78469 12.1962 7.33313 12.1962C7.90406 12.1962 8.29688 11.8269 8.29688 11.3272C8.29688 10.8097 7.90313 10.4459 7.33219 10.4459C6.78469 10.4459 6.38625 10.8097 6.38625 11.3272Z"
                      fill="#8A9999"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect
                        width="15"
                        height="15"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </TooltipHost>
            ) : null}
          </div>
          {field.validationError ? (
            <div className="validation-error">
              {field.validationErrorMessage}
            </div>
          ) : null}
          {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          {field.type === "string" || field.type === "number" ? (
            editing && field.editable ? (
              <div className="field-input">
                {field.stringConversionSymbol &&
                field.stringConversionSymbol !== "%" ? (
                  <div className="symbol">{field.stringConversionSymbol}</div>
                ) : null}
                <input
                  className="input-element"
                  value={field.value ? field.value : ""}
                  onChange={(e) => {
                    const newNumber = e.target.value.replace(/[^\d.-]/g, "");
                    handleChangeCallback(
                      field.onChangeCallback,
                      field.label,
                      field?.key,
                      field.identifier,
                      field.type === "number" ? newNumber : e.target.value,
                      "string"
                    );
                  }}
                ></input>
                {field.stringConversionSymbol &&
                field.stringConversionSymbol === "%" ? (
                  <div className="symbol">{field.stringConversionSymbol}</div>
                ) : null}
              </div>
            ) : (
              <div
                className={
                  "field-value" +
                  (field.value === 0 && field.stringConversionDecimals
                    ? " zero"
                    : "") +
                  (field.value < 0 && field.stringConversionDecimals
                    ? " negative"
                    : "")
                }
              >
                {field.stringConversionSymbol &&
                field.stringConversionSymbol !== "%" &&
                field.value !== null
                  ? field.stringConversionSymbol
                  : null}
                {field.stringConversionDecimals && field.value !== null
                  ? editing && field?.hideWhenEdit
                    ? ""
                    : Number(field.value)
                        .toFixed(field.stringConversionDecimals)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : field.value &&
                    !field.stringHyperlink &&
                    !field.referenceAsLink
                  ? field.value
                  : null}
                {field.referenceAsLink ? field?.referenceAsLink : null}
                {field.stringHyperlink ? (
                  <a href={field.stringHyperlink}>{field.value}</a>
                ) : null}
                {field.stringConversionSymbol &&
                field.stringConversionSymbol === "%" &&
                field.value !== null
                  ? field.stringConversionSymbol
                  : null}
              </div>
            )
          ) : null}
          {/* ////////////////////////////////////////////////////////////////////////////////// */}
          {field.type === "textarea" ? (
            editing && field.editable ? (
              <div className="field-input">
                <textarea
                  rows={4}
                  style={{ fontFamily: "inherit" }}
                  value={field.value ? field.value : ""}
                  onChange={(e) => {
                    handleChangeCallback(
                      field.onChangeCallback,
                      field.label,
                      field?.key,
                      field.identifier,
                      e.target.value,
                      "string"
                    );
                  }}
                />
              </div>
            ) : (
              <div className="field-value">
                {field.value
                  ? field.value.split("\n").reduce(
                      (arr, line, index) =>
                        arr.concat(
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ),
                      []
                    )
                  : null}
              </div>
            )
          ) : null}

          {field.type === "link" ? (
            editing && field.editable ? (
              <input
                className="link-input"
                value={field.value ? field.value : ""}
                onChange={(e) => {
                  handleChangeCallback(
                    field.onChangeCallback,
                    field.label,
                    field?.key,
                    field.identifier,
                    e.target.value,
                    "string"
                  );
                }}
              ></input>
            ) : (
              <div className="link">
                {convertUrl(field.value) ? (
                  <>
                    <a
                      href={convertUrl(field.value)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {field.value}
                    </a>
                  </>
                ) : (
                  field.value || null
                )}
              </div>
            )
          ) : null}
          {field.type === "date" ? (
            editing && field.editable ? (
              <div className="field-input">
                <DatePicker
                  value={field.value ? new Date(field.value) : undefined}
                  formatDate={(date) => {
                    return date === undefined
                      ? ""
                      : mapDateWithWeekdaysToString(date);
                  }}
                  onSelectDate={(date) => {
                    handleChangeCallback(
                      field.onChangeCallback,
                      field.label,
                      field?.key,
                      field.identifier,
                      date,
                      "date"
                    );
                  }}
                ></DatePicker>
              </div>
            ) : (
              <div className="field-value">
                {field.value ? formatDate(field.value, true) : null}
              </div>
            )
          ) : null}
          {field.type === "file" ? (
            editing && field.editable ? (
              field.value?.length > 0 ? (
                <div className="file-input">
                  <div className="existingdoc">
                    <a href={field.value[0].url}>{field.value[0].fileName}</a>
                    <div
                      className="remove"
                      onClick={() => {
                        handleChangeCallback(
                          field.onChangeCallback,
                          field.label,
                          field?.key,
                          field.identifier,
                          [],
                          "removefile"
                        );
                      }}
                    >
                      X
                    </div>
                  </div>
                </div>
              ) : (
                <div className="file-input">
                  <AppFileUpload
                    label={null}
                    fileRef={i}
                    onChange={(file) => {
                      handleChangeCallback(
                        field.onChangeCallback,
                        field.label,
                        field?.key,
                        field.identifier,
                        file,
                        "file"
                      );
                    }}
                    fileLimit={1}
                    //need to add uploading state (while file is still uploading) handler
                  />
                </div>
              )
            ) : (
              <div className="file-value">
                {field.value?.[0] ? (
                  <a href={field.value[0].url}>{field.value[0].fileName}</a>
                ) : null}
              </div>
            )
          ) : null}
          {field.type === "categorydropdown" ? (
            editing && field.editable ? null : ( // ></CategoryDropdown> //   }} //     ); //       'selectionArray' //       selectedItems[0], //       field.identifier, //       field.label, //       field.onChangeCallback, //     handleChangeCallback( //   onChange={selectedItems => { //   entityId={String(entityId)} //   categoryCode={field.dropdownCategory} //   itemLimit={3} //   items={[field.value.value]} // <CategoryDropdown
              <div className="field-value">
                {field.value.value ? field.value.value : null}
              </div>
            )
          ) : null}
          {field.type === "basicdropdown" ? (
            editing && field.editable ? (
              <Dropdown
                key={field.value}
                onChange={(e, selected) => {
                  handleChangeCallback(
                    field.onChangeCallback,
                    field.label,
                    field?.key,
                    field.identifier,
                    selected?.key,
                    "selectedKey"
                  );
                }}
                options={
                  field.options?.map((option) => ({
                    key: option.enumValue,
                    text: option.render,
                  })) || []
                }
                defaultSelectedKey={field.value}
              />
            ) : (
              <div className="field-value">
                {formatForEnums(field.value, field.options || [])}
              </div>
            )
          ) : null}
          {field.type === "multiselectdropdown" ? (
            editing && field.editable ? (
              <Dropdown
                calloutProps={{ className: "voc-location-select" }}
                multiSelect
                selectedKeys={field.value}
                onChange={(e, selected) => {
                  handleChangeCallback(
                    field.onChangeCallback,
                    field.label,
                    field?.key,
                    field.identifier,
                    selected,
                    "selectedArray"
                  );
                }}
                options={
                  field.options?.map((option) => ({
                    key: option.enumValue,
                    text: option.render,
                    disabled: false,
                  })) || []
                }
                defaultSelectedKeys={field.value}
              />
            ) : (
              <div className="field-value">
                {field.value.map((loc: any, li: number) => (
                  <span key={li}>
                    {formatForEnums(loc, field.options || []) +
                      (li + 1 === field.value.length ? "" : ", ")}
                  </span>
                ))}
              </div>
            )
          ) : null}
          {field.type === "radio" ? (
            editing && field.editable ? (
              <ChoiceGroup
                selectedKey={
                  field.value === true
                    ? "y"
                    : field.value === false
                    ? "n"
                    : undefined
                }
                onChange={(e, o) => {
                  if (
                    o !== undefined &&
                    o.key !== undefined &&
                    field.onChangeCallback !== undefined
                  ) {
                    o.key === "y"
                      ? field.onChangeCallback(
                          field?.key,
                          field.label +
                            (field.identifier ? field.identifier : ""),
                          true
                        )
                      : field.onChangeCallback(
                          field?.key,
                          field.label +
                            (field.identifier ? field.identifier : ""),
                          false
                        );
                  }
                }}
                options={[
                  {
                    text: t("voc.common.yes"),
                    key: "y",
                  },
                  {
                    text: t("voc.common.no"),
                    key: "n",
                  },
                ]}
              />
            ) : (
              <div className="field-value">
                {field.value === true
                  ? t("voc.common.yes")
                  : field.value === false
                  ? t("voc.common.no")
                  : null}
              </div>
            )
          ) : null}
          {field.type === "owner" ? (
            editing && field.editable ? (
              <div className="owner">
                <TagPicker
                  className={field.value ? "selected" : undefined}
                  removeButtonAriaLabel={"voc.appFieldBlockComponent.remove"} //t( "voc.appFieldBlockComponent.remove")}
                  onResolveSuggestions={filterEntityUserTags}
                  getTextFromItem={getTextFromItem}
                  pickerSuggestionsProps={pickerSuggestionsProps}
                  itemLimit={field.itemLimit}
                  defaultSelectedItems={
                    field.value
                      ? [
                          {
                            name: getUserDisplayName(field.value, false, true),
                            key: field.value.id,
                          },
                        ]
                      : undefined
                  }
                  onChange={(items) => {
                    handleChangeCallback(
                      field.onChangeCallback,
                      field.label,
                      field?.key,
                      field.identifier,
                      items,
                      "selectedArray"
                    );
                  }}
                  onInputChange={(e) => {
                    return e;
                  }}
                />
              </div>
            ) : (
              <div className="field-value">
                {field.value
                  ? getUserDisplayName(field.value, false, true)
                  : ""}
              </div>
            )
          ) : null}
          {field.type === "tags" ? (
            editing && field.editable ? (
              <div className="tags">
                <TagPicker
                  inputProps={{ spellCheck: true }}
                  className={field.value ? "selected" : undefined}
                  removeButtonAriaLabel={"voc.appFieldBlockComponent.remove"} //t("voc.appFieldBlockComponent.remove")}
                  onRenderSuggestionsItem={(
                    props: any
                    // itemProps: any
                  ): JSX.Element => {
                    return (
                      <div
                        // className={props.isNewItem ? newItem : existingItem}
                        key={props.key}
                      >
                        {props.name}
                      </div>
                    );
                  }}
                  onResolveSuggestions={(filterText: string): ITag[] => {
                    // const noTags: ITagPlus[] = [];
                    // replace noTags with a GET populated list of tags

                    // const existingMatches: ITagPlus[] = filterText
                    //   ? noTags.filter(
                    //       tag =>
                    //         tag.name
                    //           .toLowerCase()
                    //           .indexOf(filterText.toLowerCase()) === 0
                    //     )
                    //   : [];
                    const noMatch: ITagPlus[] = [
                      {
                        key: filterText,
                        name: filterText,
                        isNewItem: true,
                      },
                    ];
                    return noMatch;
                    // return existingMatches.some(a => a.key === filterText)
                    //   ? existingMatches
                    //   : noMatch.concat(existingMatches);
                  }}
                  onItemSelected={(
                    item: ITag | undefined | null
                  ): ITag | null => {
                    // if (item && item.isNewItem) {
                    if (item) {
                      //CALL API TO SAVE TAGS TO BACKEND HERE!!
                    }
                    return item || null;
                  }}
                  defaultSelectedItems={field.value}
                  onChange={(items) => {
                    handleChangeCallback(
                      field.onChangeCallback,
                      field.label,
                      field?.key,
                      field.identifier,
                      items,
                      "selectedArray"
                    );
                  }}
                  onInputChange={(e) => {
                    return e;
                  }}
                />
              </div>
            ) : (
              <div className="field-value">
                {field.value
                  ? field.value.map((item: any, i: number) => (
                      <span key={i} className="tag">
                        {item.name}
                      </span>
                    ))
                  : null}
              </div>
            )
          ) : null}
          {field.type === "compevent" ? (
            editing && field.editable ? (
              <div className="compevent">
                <TagPicker
                  className={field.value ? "selected" : undefined}
                  removeButtonAriaLabel={t("voc.appFieldBlockComponent.remove")}
                  onResolveSuggestions={filterCompEventTags}
                  getTextFromItem={getTextFromItem}
                  pickerSuggestionsProps={pickerSuggestionsProps}
                  itemLimit={field.itemLimit}
                  defaultSelectedItems={
                    field.value
                      ? [
                          {
                            name: field.value.name,
                            key: field.value.key,
                          },
                        ]
                      : undefined
                  }
                  onChange={(items) => {
                    handleChangeCallback(
                      field.onChangeCallback,
                      field.label,
                      field?.key,
                      field.identifier,
                      items,
                      "selectedArray"
                    );
                  }}
                  onInputChange={(e) => {
                    return e;
                  }}
                />
              </div>
            ) : (
              <div className="field-value">
                {field.value ? field.value.name : ""}
              </div>
            )
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default AppFieldBlock;
