import { IChoiceGroupOption } from "office-ui-fabric-react";

import {
  AppQuestionListField,
  AppQuestionListFieldTypes,
} from "../components/app-question-list/AppQuestionList";
import {
  VocCustomUi,
  VocCustomUiFieldDataType,
  VocCustomUiLayoutItemType,
} from "../types";
import { mapDateToString } from "./mappers";
import { formatPercentValue } from "./string";
import { isNull, isUndefined } from "lodash";

const yesNoOptions: IChoiceGroupOption[] = [
  {
    key: "true",
    text: "Yes",
  },
  {
    key: "false",
    text: "No",
  },
];

const getYesNoLabel = (yesNo?: boolean) => {
  if (yesNo === undefined || yesNo === null) {
    return null;
  }
  return yesNoOptions.find((option) => option.key === yesNo?.toString())?.text;
};

export const getQuestionGroupFields = (
  groupKey: string,
  customUi: VocCustomUi,
  presetFields: AppQuestionListField[],
  customForm: { [key: string]: any },
  onFormChange: (form: { [key: string]: any }) => void
): AppQuestionListField[] => {
  // customization

  const group = customUi.layout.find((grp) => grp.key === groupKey);
  const fields: AppQuestionListField[] = [];

  // type guard
  if (group.type === VocCustomUiLayoutItemType.LayoutGroup) {
    group.members
      .filter((member) => member.display)
      .forEach((member) => {
        if (member.type === VocCustomUiLayoutItemType.QuestionListPresetField) {
          const field = presetFields.find(
            (field) => field.fieldName === member.key
          );
          if (field) {
            // apply custom name from ui customization
            if (member.customName) {
              field.caption = member.customName;
            }
            // push only if field exist
            fields.push(field);
          }
        } else if (
          member.type === VocCustomUiLayoutItemType.QuestionListCustomField
        ) {
          switch (member.dataType) {
            case VocCustomUiFieldDataType.YesNo:
              fields.push({
                caption: member.name,
                fieldName: member.key,
                fieldType: AppQuestionListFieldTypes.Radio,
                options: yesNoOptions,
                selectedKey: !!customForm["customization"]
                  ? !isUndefined(customForm["customization"][member.key]) &&
                    !isNull(customForm["customization"][member.key])
                    ? customForm["customization"][member.key].toString()
                    : undefined
                  : customForm[member.key].toString(),
                contentForRead: getYesNoLabel(
                  customForm["customization"]
                    ? customForm["customization"][member.key]
                    : customForm[member.key]
                ),
                onChange: (_, opt) =>
                  onFormChange({
                    customization: {
                      ...customForm["customization"],
                      [member.key]: opt.key === "true",
                    },
                  }),
              });
              break;
            case VocCustomUiFieldDataType.Dropdown:
              fields.push({
                caption: member.name,
                fieldName: member.key,
                fieldType: AppQuestionListFieldTypes.Dropdown,
                options: member.validations.limits.map((opt) => ({
                  key: opt,
                  text: opt,
                })),
                value: customForm["customization"]
                  ? customForm["customization"][member.key]
                  : customForm[member.key],
                contentForRead: customForm["customization"]
                  ? customForm["customization"][member.key]
                  : customForm[member.key],
                onChange: (_, opt) =>
                  onFormChange({
                    customization: {
                      ...customForm["customization"],
                      [member.key]: opt.key,
                    },
                  }),
              });
              break;
            case VocCustomUiFieldDataType.Date:
              fields.push({
                caption: member.name,
                fieldName: member.key,
                fieldType: AppQuestionListFieldTypes.Calender,
                value: customForm["customization"]
                  ? customForm["customization"][member.key]
                  : customForm[member.key],
                contentForRead: mapDateToString(
                  customForm["customization"]
                    ? customForm["customization"][member.key]
                    : customForm[member.key]
                ),
                onChange: (value) =>
                  onFormChange({
                    customization: {
                      ...customForm["customization"],
                      [member.key]: value,
                    },
                  }),
              });
              break;
            case VocCustomUiFieldDataType.Percentage:
              fields.push({
                caption: member.name,
                fieldName: member.key,
                fieldType: AppQuestionListFieldTypes.NumericInput,
                value: customForm["customization"][member.key],
                contentForRead: formatPercentValue(
                  customForm["customization"]
                    ? customForm["customization"][member.key]
                    : customForm[member.key]
                ),
                inputType: "percent",
                onValueChange: (value) =>
                  onFormChange({
                    customization: {
                      ...customForm["customization"],
                      [member.key]: value,
                    },
                  }),
              });
              break;
            case VocCustomUiFieldDataType.Text:
              fields.push({
                caption: member.name,
                fieldName: member.key,
                fieldType: AppQuestionListFieldTypes.TextInput,
                value: customForm["customization"]
                  ? customForm["customization"][member.key]
                  : customForm[member.key],
                contentForRead: customForm["customization"]
                  ? customForm["customization"][member.key]
                  : customForm[member.key],
                onChange: (_, value) =>
                  onFormChange({
                    customization: {
                      ...customForm["customization"],
                      [member.key]: value,
                    },
                  }),
              });
              break;
            case VocCustomUiFieldDataType.Value:
              fields.push({
                caption: member.name,
                fieldName: member.key,
                fieldType: AppQuestionListFieldTypes.NumericInput,
                value: customForm["customization"]
                  ? customForm["customization"][member.key]
                  : customForm[member.key],
                contentForRead: customForm["customization"]
                  ? customForm["customization"][member.key]
                  : customForm[member.key],
                onValueChange: (value) =>
                  onFormChange({
                    customization: {
                      ...customForm["customization"],
                      [member.key]: value,
                    },
                  }),
              });
              break;
          }
        }
      });
  }
  return fields;
};
