import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import useAppContext from "../../../app/hooks/useAppContext";
import { useAsync } from "../../../app/hooks/useAsync";
import { VocEvent } from "../../../app/types";
import { BACK_HREF_BY_JS } from "../../../app/utils/const";
import { stringToDateFormat } from "../../../app/utils/mappers";
import { getEventById } from "../../compensation-event-services";
import EventDetailsLayout from "./EventDetailsLayout";

interface EventDetailsContainerProps {}

const EventDetailsContainer: React.FC<EventDetailsContainerProps> = (props) => {
  const { entityId, eventId } = useParams();
  const [nextEvent, setNextEvent] = React.useState<string>(null);
  const [prevEvent, setPrevEvent] = React.useState<string>(null);
  const { serviceConfig } = useAppContext();
  const eventStringToDateFormat = useCallback(
    (recentEvent: VocEvent) => ({
      ...recentEvent,
      dateOfAwareness:
        recentEvent.dateOfAwareness &&
        stringToDateFormat(recentEvent.dateOfAwareness),
      dateOfCessation:
        recentEvent.dateOfCessation &&
        stringToDateFormat(recentEvent.dateOfCessation),
    }),
    []
  );

  const [eventAsyncResult, setEventRequest] = useAsync<VocEvent>(
    () => getEventById(eventId, serviceConfig).then(eventStringToDateFormat),
    {
      onSuccess: (data) => {
        if (nextEvent !== data.next) {
          setNextEvent(data.next);
        }
        if (prevEvent !== data.last) {
          setPrevEvent(data.last);
        }
      },
    }
  );
  const handleEventRefresh = () =>
    setEventRequest(
      (): Promise<VocEvent> =>
        getEventById(eventId, serviceConfig).then(eventStringToDateFormat)
    );
  const preAndNextEventHandler = async (eventId: string) =>
    setEventRequest(() =>
      getEventById(eventId, serviceConfig).then(eventStringToDateFormat)
    );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <EventDetailsLayout
      key={eventId}
      entityId={entityId}
      eventId={eventId}
      backHref={
        document.referrer === `./compensation-events`
          ? BACK_HREF_BY_JS
          : `./../../compensation-events`
      }
      event={eventAsyncResult.data}
      loading={eventAsyncResult.loading}
      error={eventAsyncResult.error}
      onEventRefresh={handleEventRefresh}
      nextEvent={nextEvent}
      prevEvent={prevEvent}
      preAndNextEventHandler={preAndNextEventHandler}
    />
  );
};
export default EventDetailsContainer;
