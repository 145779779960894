import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useNavigate } from "react-router-dom";

import {
  CheckCircleFilled,
  LayoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  NxpButtonWithDropdown,
  NxpHeader,
  NxpSearchBar,
} from "@nexploretechnology/nxp-ui";
import { Menu, Typography } from "antd";

import { AppTheme } from "../../../../app/components/appTheme";

const useStyles = createUseStyles((theme) => ({
  demoHeader: {
    padding: theme.spacing(2),
  },
}));

interface DemoHeaderSearchbarProps {}

const DemoHeaderSearchbar: React.FC<DemoHeaderSearchbarProps> = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const theme = useTheme<AppTheme>();

  return (
    <div className={classes.demoHeader}>
      <NxpSearchBar
        backgroundColor={theme.palette.backgroundPrimaryLight}
        leftContent={
          <NxpHeader
            onBackClick={() => navigate(-1)}
            titleContent="Demo Header"
            subheadingContent={
              <>
                test subheading{" "}
                <Typography.Text type="success">
                  <CheckCircleFilled />
                </Typography.Text>{" "}
              </>
            }
          />
        }
        rightContent={
          <NxpButtonWithDropdown
            menu={
              <Menu onClick={(item) => console.log(item.key)}>
                <Menu.Item
                  onClick={() => navigate("./demo/splitter")}
                  key="1"
                  icon={<LayoutOutlined />}
                >
                  Demo Splitter
                </Menu.Item>
                <Menu.Item
                  onClick={() => navigate("./demo/left-drawer")}
                  key="1"
                  icon={<LayoutOutlined />}
                >
                  Demo Left Drawer
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={() => console.log("test 2")}
                  icon={<UserOutlined />}
                >
                  2nd menu item
                </Menu.Item>
              </Menu>
            }
          >
            testing menu button
          </NxpButtonWithDropdown>
        }
      />
    </div>
  );
};

export default DemoHeaderSearchbar;
