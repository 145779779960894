import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { notify } from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../app/hooks/useAppContext";
import { AsyncResult } from "../../../../app/hooks/useAsync";
import { useValidate } from "../../../../app/hooks/useValidate";
import { getUserDisplayName } from "../../../../app/services/app";
import { VocEventSummaryNotification } from "../../../../app/types";
import { createNotificationReminder } from "../../../compensation-event-services";
import ReminderModalLayout from "./ReminderModalLayout";

function getValidationSchema(notificationAddedMsg: string) {
  return yup.object().shape({
    sendToAll: yup.boolean(),
    sendToCoOnly: yup.boolean(),
    sendToRoAndRsOnly: yup.boolean(),
    sendToOthers: yup
      .array(yup.string())
      .nullable()
      .test("checked", notificationAddedMsg, function (val) {
        const refSendToAll = yup.ref("sendToAll");
        const refSendToCoOnly = yup.ref("sendToCoOnly");
        const refSendToRoAndRsOnly = yup.ref("sendToRoAndRsOnly");
        return (
          !!this.resolve(refSendToAll) ||
          !!this.resolve(refSendToCoOnly) ||
          !!this.resolve(refSendToRoAndRsOnly) ||
          !!(val && val.length > 0)
        );
      }),
  }) as unknown as yup.Schema<Partial<VocEventSummaryNotification>>;
}

interface ReminderModalContainerProps {
  showReminderModal: boolean;
  onModalClose: () => void;
  injectedDefaultForm?: VocEventSummaryNotification;
}

const ReminderModalContainer: React.FC<ReminderModalContainerProps> = ({
  showReminderModal,
  onModalClose,
  injectedDefaultForm,
}) => {
  const { eventId } = useParams();
  const { serviceConfig, errorHandler, entityUsers } = useAppContext();
  const defaultForm: VocEventSummaryNotification = injectedDefaultForm
    ? injectedDefaultForm
    : {
        sendToAll: false,
        sendToCoOnly: false,
        sendToRoAndRsOnly: false,
        sendToOthers: undefined,
        message: "",
      };

  const [isSending, setIsSending] = useState<boolean>(false);

  const [form, setForm] = React.useState<VocEventSummaryNotification>(() => ({
    ...defaultForm,
  }));

  const [entityUserOptions, setEntityUserOptions] = useState([]);

  useEffect(() => {
    if (showReminderModal) {
      setForm({ ...defaultForm });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReminderModal]);

  useEffect(() => {
    setEntityUserOptions(
      entityUsers.map((user) => ({
        name: getUserDisplayName(user),
        key: user.id,
      }))
    );
  }, [entityUsers]);

  const [reminderResultError, setReminderResultError] = useState<AsyncResult>();

  const handleSaveValidated = async () => {
    try {
      setIsSending(true);
      const reqBody = {
        ...form,
        // remarks: in form, unchecked = undefined, checked = [] or [...],
        //           so we only convert HERE when sending to api
        sendToOthers: form.sendToOthers ?? [],
      };
      await createNotificationReminder(eventId, reqBody, serviceConfig);
      notify.actionCompleted();
      onModalClose();
    } catch (ex) {
      setReminderResultError({ error: ex, loading: false, data: {} });
      errorHandler(ex, "Save Reminder");
    } finally {
      setIsSending(false);
    }
  };

  const { t } = useTranslation();

  const [validationError, , , saveWithValidate] = useValidate<
    Partial<VocEventSummaryNotification>
  >(
    form,
    getValidationSchema(t("voc.eventPage.summary.notificationAdded")),
    handleSaveValidated
  );

  const handleValidation = () => {
    saveWithValidate(undefined);
  };

  const handleFormChange = (
    changeValues: Partial<VocEventSummaryNotification>
  ) => {
    setForm((prevState) => ({ ...prevState, ...changeValues }));
  };

  return (
    showReminderModal && (
      <ReminderModalLayout
        entityUserOptions={entityUserOptions}
        showReminderModal={showReminderModal}
        form={form}
        notificationReminderAsyncResult={reminderResultError}
        validationError={validationError}
        onFormChange={handleFormChange}
        onModalClose={onModalClose}
        onSave={handleValidation}
        isSending={isSending}
      />
    )
  );
};

export default ReminderModalContainer;
