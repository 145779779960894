import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { notify, useYupValidate } from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../app/hooks/useAppContext";
import { useAsync } from "../../../../app/hooks/useAsync";
import { getUserDisplayName } from "../../../../app/services/app";
import { VocEventSummaryNotification } from "../../../../app/types";
import { createEventSummaryNotification } from "../../../compensation-event-services";
import NotifyModalLayout from "./NotifyModalLayout";

function getValidationSchema(notificationAddedMsg: string) {
  return yup.object().shape({
    sendToAll: yup.boolean(),
    sendToCoOnly: yup.boolean(),
    sendToRoAndRsOnly: yup.boolean(),
    sendToOthers: yup
      .array(yup.string())
      .nullable()
      .test("checked", notificationAddedMsg, function (val) {
        const refSendToAll = yup.ref("sendToAll");
        const refSendToCoOnly = yup.ref("sendToCoOnly");
        const refSendToRoAndRsOnly = yup.ref("sendToRoAndRsOnly");
        return (
          !!this.resolve(refSendToAll) ||
          !!this.resolve(refSendToCoOnly) ||
          !!this.resolve(refSendToRoAndRsOnly) ||
          !!(val && val.length > 0)
        );
      }),
  }) as unknown as yup.Schema<Partial<VocEventSummaryNotification>>;
}
interface NotifyModalContainerProps {
  showNotifyModal: boolean;
  onModalClose: () => void;
}

const NotifyModalContainer: React.FC<NotifyModalContainerProps> = ({
  showNotifyModal,
  onModalClose,
}) => {
  const { t } = useTranslation();
  const { eventId } = useParams();

  const { serviceConfig, errorHandler, entityUsers } = useAppContext();
  const defaultForm: VocEventSummaryNotification = useMemo(
    () => ({
      sendToAll: false,
      sendToCoOnly: false,
      sendToRoAndRsOnly: false,
      sendToOthers: undefined,
      message: "",
    }),
    []
  );
  const [form, setForm] = React.useState<VocEventSummaryNotification>(() => ({
    ...defaultForm,
  }));

  const [
    createSummaryNotificationAsyncResult,
    setCreateSummaryNotificationRequest,
  ] = useAsync<VocEventSummaryNotification>(undefined, {
    onSuccess: () => {
      notify.success(t("voc.eventPage.summary.notificationAdded"));
      onModalClose();
    },
    onError: (ex) => errorHandler(ex, "creating summary notification"),
  });

  const [entityUserOptions, setEntityUserOptions] = useState([]);

  useEffect(() => {
    if (showNotifyModal) {
      setForm({ ...defaultForm });
    }
  }, [defaultForm, showNotifyModal]);

  useEffect(() => {
    setEntityUserOptions(
      entityUsers.map((user) => {
        return {
          name: getUserDisplayName(user),
          key: user?.id,
        };
      })
    );
  }, [entityUsers]);

  const handleSaveValidated = () => {
    setCreateSummaryNotificationRequest(() =>
      createEventSummaryNotification(
        eventId,
        { ...form, sendToOthers: form?.sendToOthers ?? [] },
        serviceConfig
      )
    );
  };

  const [validationError, , , saveWithValidate] = useYupValidate<
    Partial<VocEventSummaryNotification>
  >(
    form,
    getValidationSchema(t("voc.eventPage.summary.notificationAdded")),
    handleSaveValidated
  );

  const handleValidation = () => {
    saveWithValidate(undefined);
  };

  const handleFormChange = (
    changeValues: Partial<VocEventSummaryNotification>
  ) => {
    setForm((prevState) => ({ ...prevState, ...changeValues }));
  };

  return (
    showNotifyModal && (
      <NotifyModalLayout
        entityUserOptions={entityUserOptions}
        showNotifyModal={showNotifyModal}
        form={form}
        createSummaryNotificationAsyncResult={
          createSummaryNotificationAsyncResult
        }
        validationError={validationError}
        onFormChange={handleFormChange}
        onModalClose={onModalClose}
        onSave={handleValidation}
      />
    )
  );
};

export default NotifyModalContainer;
