import React, { useState } from "react";

import { VocValueRegister } from "../../../../app/types";

interface ValueRegisterTextInputProps {
  id: string;
  value: string;
  fieldName: keyof VocValueRegister;
  rowIndex: number;
  disabled?: boolean;
  onChange: (
    value: string,
    prevValue: string,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => void;
  onKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => void;
  onFocus: (
    e: React.FocusEvent<HTMLInputElement>,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const ValueRegisterTextInput: React.FC<ValueRegisterTextInputProps> = ({
  id,
  value,
  fieldName,
  rowIndex,
  disabled = false,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
}) => {
  const [inputValue, setInputValue] = useState(() => value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setInputValue((prevState) => {
      onChange(newValue, prevState, fieldName, rowIndex);
      return newValue;
    });
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    onFocus(e, fieldName, rowIndex);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown(e, fieldName, rowIndex);
  };

  return (
    <input
      id={id}
      disabled={disabled}
      onFocus={handleFocus}
      onChange={handleChange}
      value={inputValue || ""}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
    />
  );
};

export default ValueRegisterTextInput;
