import {
  DeleteOutlined,
  LinkOutlined,
  LockFilled,
  UploadOutlined,
} from "@ant-design/icons";
import {
  DateFormatPattern,
  formatDate,
  notify,
  NxpButton,
  NxpButtonDeleteConfirm,
  NxpDatePicker,
  NxpPanel,
  NxpTable,
  NxpTableColumnProps,
  NxpTooltip,
  sorterForDate,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Button, Input, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import { isAfter, isBefore } from "date-fns";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import useAppContext from "../../../../../app/hooks/useAppContext";
import { upload } from "../../../../../app/services/file";
import {
  VocEvent,
  VocSiteEvent,
  VocSupportDocument,
  VocSupportDocumentEnums,
} from "../../../../../app/types";
import { getUserDisplayDateFormat } from "../../../../../app/utils/mappers";
import {
  deleteSupportDocumentsById,
  editEventSupportDocuments,
  editSiteEventSupportDocuments,
} from "../document-form-services";
import {
  convertEventReferenceFilesToOtherDocument,
  getTooltipMessage,
  isRowDisplayOnly,
  relatedEventDisplay,
} from "../helpers/supporting-helper";

interface OtherDocumentsPanelPanelProps {
  otherDocuments: VocSupportDocument[];
  eventReferenceFiles: VocSupportDocument[];
  onRefresh: () => void;
  onSetIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  eventDetail: VocEvent | VocSiteEvent;
}

const useStyles = createUseStyles({
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px",
  },
});

const OtherDocumentPanel: React.FC<OtherDocumentsPanelPanelProps> = ({
  otherDocuments,
  onRefresh,
  onSetIsLoading,
  isLoading,
  eventReferenceFiles,
  eventDetail,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { serviceConfig, errorHandler } = useAppContext();
  const { eventId, siteEventId } = useParams();

  const combinedOtherDocuments = useMemo(() => {
    const convertedEventReferenceFiles =
      convertEventReferenceFilesToOtherDocument(
        eventReferenceFiles,
        eventDetail,
        Boolean(siteEventId)
      );
    return [...otherDocuments, ...convertedEventReferenceFiles];
  }, [eventDetail, eventReferenceFiles, otherDocuments, siteEventId]);

  const [editing, setEditing] = useState(false);
  const [formState, setFormState] = useState<VocSupportDocument[]>([]);

  const handleClickEdit = () => {
    //as VocSupportDocument because
    //in site event, documentFile will be return object, but in compensation event, documentFiles will be return array
    //it cause the type error
    setFormState(combinedOtherDocuments as VocSupportDocument[]);
    setEditing(true);
  };

  const handleClickCancel = () => {
    setEditing(false);
    setFormState([]);
  };

  const deleteDocument = useCallback(
    async (record: VocSupportDocument) => {
      const idToUse = siteEventId || eventId;
      const eventType = siteEventId ? "site_events" : "events";
      await deleteSupportDocumentsById(
        idToUse,
        record.id,
        eventType,
        serviceConfig
      );
      setEditing(false);
      setFormState([]);
      notify.actionCompleted();
      onRefresh();
    },
    [eventId, onRefresh, serviceConfig, siteEventId]
  );

  const handleSave = async () => {
    if (!formState) return;

    onSetIsLoading(true);
    const updatedOtherDocuments = formState
      .filter((item) => !item.isEventReferenceFiles)
      .map((item) => ({
        comment: item.comment,
        details: { ...item.details },
        documentFiles: item.documentFiles.map((file) => ({
          assetId: file.assetId,
          fileName: file.fileName,
          mimeType: file.mimeType,
          url: file.url,
        })),
        documentType: item.documentType,
        id: item.id,
      }));

    const isSiteEvent = Boolean(siteEventId);
    try {
      if (isSiteEvent) {
        await editSiteEventSupportDocuments(siteEventId, serviceConfig, {
          documentType: VocSupportDocumentEnums.Other_Documents,
          documents: updatedOtherDocuments,
        });
      } else {
        await editEventSupportDocuments(eventId, serviceConfig, {
          documentType: VocSupportDocumentEnums.Other_Documents,
          documents: updatedOtherDocuments,
        });
      }
      setEditing(false);
      setFormState([]);
      notify.actionCompleted();
      onRefresh();
    } catch (error) {
      errorHandler(error, "save Other Documents");
    } finally {
      onSetIsLoading(false);
    }
  };

  const handleRemoveFile = useCallback(
    (record) => {
      if (!formState) return;

      const updatedFormState = formState.map((item) =>
        item.id === record.id ? { ...item, documentFiles: [] } : item
      );
      setFormState(updatedFormState);
    },
    [formState]
  );

  const handleFileChange = useCallback(
    async (file, record) => {
      try {
        const { assetId, url } = await upload(file, serviceConfig);
        const newFile = {
          assetId,
          url,
          fileName: file.name,
          mimeType: file.type,
        };

        if (!formState) return;

        const updatedFormState = formState.map((item) =>
          item.id === record.id
            ? {
                ...item,
                documentFiles: [
                  {
                    assetId: newFile.assetId,
                    fileName: file.name,
                    url: newFile.url,
                    mimeType: file.type,
                  },
                ],
              }
            : item
        );
        setFormState(updatedFormState);
      } catch (error) {
        errorHandler(error, "upload file");
      }
    },
    [serviceConfig, formState, errorHandler]
  );

  const handleInputChange = useCallback(
    (id: string | number, field: string, value: unknown) => {
      if (!formState) return;

      const newOtherDocument = formState.map((item) => {
        if (item.id === id) {
          if (field in item.details) {
            return {
              ...item,
              details: {
                ...item.details,
                [field]: value,
              },
            };
          } else {
            return {
              ...item,
              [field]: value,
            };
          }
        }
        return item;
      });
      setFormState(newOtherDocument);
    },
    [formState]
  );

  const columns: NxpTableColumnProps<VocSupportDocument>[] = useMemo(
    () => [
      {
        title: t("voc.eventPage.summary.subject"),
        dataIndex: ["details", "subject"],
        key: "subject",
        width: "8%",
        sorter: (a, b) => sorterForString(a.details.subject, b.details.subject),
        render: (text, record) => {
          if (
            editing &&
            !isRowDisplayOnly(record, eventId) &&
            !record.isEventReferenceFiles
          ) {
            return (
              <Input
                type="text"
                value={record.details.subject}
                onChange={(e) =>
                  handleInputChange(record.id, "subject", e.target.value)
                }
              />
            );
          } else {
            return text;
          }
        },
      },
      {
        title: t("voc.eventPage.supporting.dateFrom"),
        dataIndex: ["details", "fromDate"],
        key: "fromDate",
        width: "8%",
        sorter: (a, b) => sorterForDate(a.details.fromDate, b.details.fromDate),
        render: (date, record) =>
          editing &&
          !isRowDisplayOnly(record, eventId) &&
          !record.isEventReferenceFiles ? (
            <NxpDatePicker
              format={getUserDisplayDateFormat()}
              value={record.details.fromDate}
              disabledDate={(current) =>
                (current &&
                  (isBefore(new Date(), current.toDate()) ||
                    isAfter(current.toDate(), new Date()))) ||
                isAfter(current.toDate(), record.details.toDate)
              }
              onChange={(value) => {
                handleInputChange(record.id, "fromDate", value);
              }}
            />
          ) : (
            formatDate(new Date(date), getUserDisplayDateFormat())
          ),
      },
      {
        title: t("voc.eventPage.supporting.dateTo"),
        dataIndex: ["details", "toDate"],
        key: "toDate",
        width: "8%",
        sorter: (a, b) => sorterForDate(a.details.toDate, b.details.toDate),
        render: (date, record) =>
          editing &&
          !isRowDisplayOnly(record, eventId) &&
          !record.isEventReferenceFiles ? (
            <NxpDatePicker
              format={getUserDisplayDateFormat()}
              value={record.details.toDate}
              disabledDate={(current) =>
                current &&
                (isBefore(current.toDate(), record.details.fromDate) ||
                  isAfter(current.toDate(), new Date()))
              }
              onChange={(value) =>
                handleInputChange(record.id, "toDate", value)
              }
            />
          ) : (
            formatDate(new Date(date), getUserDisplayDateFormat())
          ),
      },
      {
        title: t("voc.eventPage.supporting.relatedEvent"),
        dataIndex: ["details", "relatedEvent"],
        key: "relatedEvent",
        width: "8%",
        sorter: (a, b) =>
          sorterForString(
            relatedEventDisplay(
              a.compensationEventSerial,
              a.compensationEventTitle,
              a.siteEventSerial,
              a.siteEventTitle
            ),
            relatedEventDisplay(
              b.compensationEventSerial,
              b.compensationEventTitle,
              b.siteEventSerial,
              b.siteEventTitle
            )
          ),
        render: (text, record) => {
          return (
            <Button
              type="link"
              onClick={
                !record.siteEventId
                  ? () =>
                      window.open(`./../compensation-events/${record.eventId}`)
                  : () => window.open(`./../site-events/${record.siteEventId}`)
              }
            >
              {relatedEventDisplay(
                record.compensationEventSerial,
                record.compensationEventTitle,
                record.siteEventSerial,
                record.siteEventTitle || record.compensationEventTitle
              )}
            </Button>
          );
        },
      },
      {
        title: t("voc.eventPage.summary.comment"),
        dataIndex: "comment",
        key: "comment",
        width: "8%",
        sorter: (a, b) => sorterForString(a.comment, b.comment),
        render: (text, record) => {
          if (
            editing &&
            !isRowDisplayOnly(record, eventId) &&
            !record.isEventReferenceFiles
          ) {
            return (
              <TextArea
                value={record.comment}
                onChange={(e) =>
                  handleInputChange(record.id, "comment", e.target.value)
                }
              />
            );
          } else {
            return text;
          }
        },
      },
      {
        title: t("voc.eventPage.supporting.file"),
        dataIndex: "file",
        key: "file",
        width: "5%",
        render: (file, record) => {
          if (editing && !isRowDisplayOnly(record, eventId)) {
            switch (record.documentFiles?.length) {
              case 0:
                return (
                  <Upload
                    showUploadList={false}
                    beforeUpload={(file) => {
                      handleFileChange(file, record);
                      return false;
                    }}
                  >
                    <Button icon={<UploadOutlined />} />
                  </Upload>
                );
              case 1:
                return (
                  <Upload
                    fileList={record.documentFiles.map((file) => ({
                      uid: file.assetId,
                      name: file.fileName,
                      status: "done",
                      url: file.url,
                    }))}
                    onRemove={() => handleRemoveFile(record)}
                  >
                    {record?.documentFiles.length < 1 && (
                      <Button icon={<UploadOutlined />} />
                    )}
                  </Upload>
                );
              default:
                return null;
            }
          } else {
            return record.documentFiles && record.documentFiles.length > 0 ? (
              <a
                href={record.documentFiles[0].url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {record.documentFiles[0]?.fileName}
              </a>
            ) : null;
          }
        },
      },
      {
        title: t("voc.eventPage.supporting.link"),
        dataIndex: ["details", "link"],
        key: "link",
        width: "8%",
        render: (link, record) => {
          if (
            editing &&
            !isRowDisplayOnly(record, eventId) &&
            !record.isEventReferenceFiles
          ) {
            return (
              <Input
                type="text"
                value={record.details.link}
                onChange={(e) =>
                  handleInputChange(record.id, "link", e.target.value)
                }
              />
            );
          } else {
            const getClickableLink = () => {
              return record.details.link.startsWith("http://") ||
                record.details.link.startsWith("https://")
                ? record.details.link
                : `https://${link}`;
            };
            return record.details.link ? (
              <NxpTooltip title={record.details.link}>
                <a
                  href={getClickableLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkOutlined />
                </a>
              </NxpTooltip>
            ) : null;
          }
        },
      },
      {
        title: t("voc.common.updatedOn"),
        dataIndex: "updatedOn",
        key: "updatedOn",
        width: "10%",
        sorter: (a, b) => sorterForDate(a.updatedOn, b.updatedOn),
        extraFormatOptions: {
          type: "date",
          format: DateFormatPattern.date,
        },
        render: (date) => formatDate(new Date(date), DateFormatPattern.date),
      },
    ],
    [t, editing, eventId, handleInputChange, handleFileChange, handleRemoveFile]
  );

  if (editing) {
    columns.unshift({
      title: t("voc.appDataListComponent.delete"),
      key: "delete",
      width: "3%",
      render: (text, record) => {
        if (
          isRowDisplayOnly(record, eventId) ||
          record.isEventReferenceFiles ||
          record.isEventReferenceFiles
        ) {
          return (
            <NxpTooltip title={getTooltipMessage(record, eventId, t)}>
              <LockFilled />
            </NxpTooltip>
          );
        }
        return (
          <NxpButtonDeleteConfirm
            type="link"
            danger
            icon={<DeleteOutlined />}
            onConfirm={() => deleteDocument(record)}
          />
        );
      },
    });
  }

  return (
    <NxpPanel
      titleContent={`${t("voc.eventPage.supporting.otherDocuments")} (${
        combinedOtherDocuments?.length
      })`}
      defaultCollapsed
    >
      <div className={classes.buttonsContainer}>
        {!editing && (
          <NxpButton loading={isLoading} onClick={handleClickEdit}>
            {t("voc.common.edit")}
          </NxpButton>
        )}
        {editing && (
          <>
            <NxpButton
              loading={isLoading}
              type="default"
              onClick={handleClickCancel}
            >
              {t("voc.common.cancel")}
            </NxpButton>
            <NxpButton loading={isLoading} onClick={handleSave}>
              {t("voc.common.save")}
            </NxpButton>
          </>
        )}
      </div>
      <NxpTable
        loading={isLoading}
        columns={columns}
        dataSource={editing && formState ? formState : combinedOtherDocuments}
        rowKey="id"
      />
    </NxpPanel>
  );
};

export default OtherDocumentPanel;
