import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModalFooter,
} from "@nexploretechnology/nxp-ui";
import { MinervaForm, recordStatus } from "../new-document-form-type";

interface NewMinervaFormProps {
  onModalClose: () => void;
}

const NewMinervaForm: React.FC<NewMinervaFormProps> = ({ onModalClose }) => {
  const { t } = useTranslation();

  const [formState, setFormState] = useState<MinervaForm>({
    fromDate: null,
    toDate: null,
    subject: "",
    subcontractor: "",
    totalAmount: "",
    valueToDate: "",
    certifiedToDate: "",
    assessment: "",
    reference: "",
    recordStatus: undefined,
    subcontractorAssessment: "",
    details: "",
  });

  const formItems: NxpFormGridItemProps<MinervaForm>[] = [
    {
      controlType: "input",
      span: 12,
      label: "reference",
      itemFieldName: "reference",
      required: true,
      controlProps: {
        disabled: true,
        value: formState?.subject,
        onChange: (e) => handleFormGridStateChange("reference", e.target.value),
      },
    },
    {
      controlType: "input",
      span: 12,
      label: t("voc.eventPage.summary.subject"),
      itemFieldName: "subject",
      required: true,
      controlProps: {
        disabled: true,
        value: formState?.subject,
        onChange: (e) => handleFormGridStateChange("subject", e.target.value),
      },
    },
    {
      controlType: "datePicker",
      span: 12,
      label: t("voc.eventPage.supporting.dateFrom"),
      itemFieldName: "fromDate",
      required: true,
      controlProps: {
        value: formState?.fromDate,
        disabled: true,
        onChange: (date) => handleFormGridStateChange("fromDate", date),
      },
    },
    {
      controlType: "datePicker",
      span: 12,
      label: t("voc.eventPage.supporting.dateTo"),
      itemFieldName: "toDate",
      required: true,
      controlProps: {
        disabled: true,
        value: formState?.toDate,
        onChange: (date) => handleFormGridStateChange("toDate", date),
      },
    },

    {
      controlType: "input",
      span: 12,
      label: t("voc.eventPage.supporting.subcontractor"),
      itemFieldName: "subcontractor",
      required: true,
      controlProps: {
        disabled: true,
        value: formState?.subcontractor,
        onChange: (e) =>
          handleFormGridStateChange("subcontractor", e.target.value),
      },
    },
    {
      controlType: "input",
      span: 12,
      label: "Total Amount",
      itemFieldName: "totalAmount",
      required: true,
      controlProps: {
        disabled: true,
        value: formState?.totalAmount,
        addonBefore: "$",
        onChange: (e) =>
          handleFormGridStateChange("totalAmount", e.target.value),
      },
    },
    {
      controlType: "input",
      span: 12,
      label: t("voc.eventPage.supporting.valueToDate"),
      itemFieldName: "valueToDate",
      required: true,
      controlProps: {
        disabled: true,
        value: formState?.valueToDate,
        onChange: (e) =>
          handleFormGridStateChange("valueToDate", e.target.value),
      },
    },
    {
      controlType: "input",
      span: 12,
      label: t("voc.eventPage.supporting.certifiedToDate"),
      itemFieldName: "certifiedToDate",
      required: true,
      controlProps: {
        disabled: true,
        value: formState?.certifiedToDate,
        onChange: (e) =>
          handleFormGridStateChange("certifiedToDate", e.target.value),
      },
    },
    {
      controlType: "input",
      span: 12,
      label: "Subcontractor Assessment",
      itemFieldName: "subcontractorAssessment",
      required: true,
      controlProps: {
        disabled: true,
        value: formState?.totalAmount,
        addonBefore: "$",
        onChange: (e) =>
          handleFormGridStateChange("totalAmount", e.target.value),
      },
    },
    {
      controlType: "select",
      span: 12,
      label: "Status",
      itemFieldName: "totalAmount",
      required: true,
      controlProps: {
        disabled: true,
        options: recordStatus.map((item) => ({
          label: item.label,
          value: item.value,
        })),
      },
    },
    {
      controlType: "input",
      span: 24,
      label: "Details",
      itemFieldName: "details",
      required: true,
      controlProps: {
        disabled: true,
        value: formState?.recordStatus,
        onChange: (value) => handleFormGridStateChange("recordStatus", value),
      },
    },
  ];

  const handleFormGridStateChange = (
    fieldName: keyof MinervaForm,
    value: any
  ) => {
    setFormState((prev) => ({ ...prev, [fieldName]: value }));
  };

  return (
    <>
      <NxpFormGrid
        formItems={formItems}
        formState={formState}
        onFormStateChange={handleFormGridStateChange}
      />
      <NxpModalFooter>
        <NxpButton type="default" onClick={onModalClose}>
          {t("voc.common.cancel")}
        </NxpButton>
        <NxpButton disabled type="primary">
          {t("voc.common.save")}
        </NxpButton>
      </NxpModalFooter>
    </>
  );
};

export default NewMinervaForm;
