import * as React from "react";
import "./Previewer.scss";
import { Icon } from "office-ui-fabric-react";

interface ImagePreviewerProps {
  url: string;
  remove?: () => void;
}

const ImagePreviewerImpl: React.FC<ImagePreviewerProps> = ({ url, remove }) => {
  const handleThumbnailClick = React.useCallback(() => {
    window.open(url, "_blank");
  }, [url]);

  return (
    <div className="previewer-container">
      <div
        onClick={handleThumbnailClick}
        className="image-container"
        style={{ backgroundImage: `url('${url}')` }}
      />
      {remove && (
        <div className="previewer__close-button" onClick={remove}>
          <Icon className="previewer__close-button-icon" iconName="clear" />
        </div>
      )}
    </div>
  );
};

export const ImagePreviewer = ImagePreviewerImpl;
