import {
  VocGetValuesBuildUp,
  VocGetValuesEisReporting,
  VocGetValuesToClient,
  VocGetValuesToDate,
  VocUpdateValuesRequest,
} from "../../../../app/types";

export const prepareValuesForm = (
  customFullSend: Record<string, any>,
  toClientFormState: VocGetValuesToClient,
  buildUpFormState: VocGetValuesBuildUp,
  paymentWithClientFormState: VocGetValuesToDate,
  eisReportingFormState: Pick<
    VocGetValuesEisReporting,
    | "eisA2A6"
    | "eisA8A14"
    | "in16"
    | "eisSecuredProject"
    | "eisStrategyIncludedInPlanning"
  >,
  isIncludedInReports: Pick<VocGetValuesEisReporting, "includedInReports">,
  actualValues: {
    physicalProgress: number;
    internalProgressValue: number;
  },
  costCodes: {
    costCodes: string[];
  }
): VocUpdateValuesRequest => ({
  firstEstimate: toClientFormState?.firstEstimate || 0,
  internalEstimateSubstantiated:
    toClientFormState?.internalEstimateSubstantiated || 0,
  internalValue: toClientFormState?.internalValue || 0,
  internalTarget: toClientFormState?.internalTarget || 0,
  clientAssessed: toClientFormState?.clientAssessed || 0,
  internalVariance: toClientFormState?.internalVariance || 0,
  targetVariance: toClientFormState?.targetVariance || 0,
  recovery: toClientFormState?.recovery || 0,
  directCost: buildUpFormState?.directCost || 0,
  indirectCost: buildUpFormState?.indirectCost || 0,
  costCloseOut: buildUpFormState?.costCloseOut || 0,
  costGna: buildUpFormState?.costGna || 0,
  totalCost: buildUpFormState?.totalCost || 0,
  profitLoss: buildUpFormState?.profitLoss || 0,
  overheadProfit: buildUpFormState?.overheadProfit || 0,
  eisA2A6: eisReportingFormState?.eisA2A6 || 0,
  eisA8A14: eisReportingFormState?.eisA8A14 || 0,
  eisSecuredProject: eisReportingFormState.eisSecuredProject,
  eisStrategyIncludedInPlanning:
    eisReportingFormState.eisStrategyIncludedInPlanning, //E - eisSecuredProject
  in16: eisReportingFormState.in16 as string,
  cumulativeApplied: paymentWithClientFormState?.cumulativeApplied || 0,
  cumulativeAppliedNominal:
    paymentWithClientFormState?.cumulativeAppliedNominal || 0,
  cumulativeValueCertified:
    paymentWithClientFormState?.cumulativeValueCertified || 0,
  cumulativeValueVariance:
    paymentWithClientFormState?.cumulativeValueVariance || 0,
  paymentReference: paymentWithClientFormState?.paymentReference || "",
  includedInReports: isIncludedInReports.includedInReports,
  physicalProgress: actualValues?.physicalProgress || 0,
  internalProgressValue: actualValues?.internalProgressValue || 0,
  costCodes: costCodes.costCodes,
  ...{ customization: customFullSend },
});
