// import { VocValueRegister } from '../../../types';
import React from "react";

import { VocValueRegister } from "../../../../app/types";

interface ValueRegisterLinkProps {
  id: string;
  href: string;
  fieldName: keyof VocValueRegister;
  rowIndex: number;
  children: React.ReactNode;
  onKeyDown: (
    e: React.KeyboardEvent<HTMLAnchorElement>,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => void;
  onFocus: (
    e: React.FocusEvent<HTMLAnchorElement>,
    fieldName: keyof VocValueRegister,
    rowIndex: number
  ) => void;
  onBlur: (e: React.FocusEvent<HTMLAnchorElement>) => void;
}
const ValueRegisterLink: React.FC<ValueRegisterLinkProps> = ({
  id,
  href,
  fieldName,
  rowIndex,
  children,
  onFocus,
  onKeyDown,
  onBlur,
}) => {
  const handleFocus = (e: React.FocusEvent<HTMLAnchorElement>) => {
    onFocus(e, fieldName, rowIndex);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    onKeyDown(e, fieldName, rowIndex);
  };

  return (
    <a
      id={id}
      href={href}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
    >
      {children}
    </a>
  );
};

export default ValueRegisterLink;
