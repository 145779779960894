import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { notify } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../../app/hooks/useAppContext";
import {
  VocEvent,
  VocRecordStatus,
  VocSupportDocument,
} from "../../../../app/types";
import { createSiteEventStatusRecord } from "../../../../site-events/site-event-services";
import { createStatusRecord } from "../../../compensation-event-services";
import { DateRecord } from "../RecordsTabContainer";

import { useAsync } from "../../../../app/hooks/useAsync";
import DayCellLayout from "./DayCellLayout";
import { omit } from "office-ui-fabric-react";
import { mapDateToString } from "../../../../app/utils/mappers";

interface DayCellContainerProps {
  date?: Date;
  dateRecords?: DateRecord[];
  dateOfAwareness?: Date;
  dateOfCessation?: Date;
  showMonth?: boolean;
  remarks?: ReactNode[];
  relatedRemarks?: ReactNode[];
  onStatusRecordsRefresh: () => void;
  supportDocuments: VocSupportDocument[];
  contemporaryRecordRequired: boolean;
  siteEventId?: string;
  eventId?: VocEvent["id"];
}

const DayCellContainer: React.FC<DayCellContainerProps> = ({
  date,
  dateRecords,
  dateOfAwareness,
  dateOfCessation,
  showMonth,
  remarks,
  onStatusRecordsRefresh,
  supportDocuments,
  contemporaryRecordRequired,
  siteEventId,
  eventId,
  relatedRemarks,
}) => {
  const { t } = useTranslation();
  const { serviceConfig, errorHandler } = useAppContext();

  const [, setBatchUpdateRequest] = useAsync(undefined, {
    onSuccess: () => {
      notify.success(t("voc.eventPage.records.statusUpdated"));
      onStatusRecordsRefresh();
    },
    onError(ex) {
      errorHandler(ex, t("voc.eventPage.records.statusUpdated"));
    },
  });

  const handleStatusChange = (
    isAll: boolean,
    isCompEvent: boolean,
    siteEventId: string,
    status?: VocRecordStatus
  ) => {
    if (isAll) {
      dateRecords.forEach((dr) => {
        setBatchUpdateRequest(() => {
          if (!dr.siteEventId) {
            return createStatusRecord(
              mapDateToString(date),
              status,
              undefined,
              dr.compensationEventId,
              serviceConfig
            );
          } else {
            return createSiteEventStatusRecord(
              mapDateToString(date),
              status,
              undefined,
              dr.siteEventId,
              serviceConfig
            );
          }
        });
      });
    } else {
      setBatchUpdateRequest(() => {
        // if (status === VocRecordStatus.Outstanding) {
        //   return vocService.deleteStatusRecords(Number(vocCtx.eventId), date);
        // }
        if (isCompEvent) {
          return createStatusRecord(
            mapDateToString(date),
            status,
            undefined,
            eventId,
            serviceConfig
          );
        } else {
          return createSiteEventStatusRecord(
            mapDateToString(date),
            status,
            undefined,
            siteEventId,
            serviceConfig
          );
        }
      });
    }
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  let dateStatus: VocRecordStatus;
  if (dateRecords && dateRecords.length > 0) {
    dateStatus = dateRecords.reduce(
      (acc: VocRecordStatus, next: DateRecord) => {
        const nextPriority = next.statusType
          ? Number(next.statusType.slice(-2))
          : 99;
        const prevPriority = acc ? Number(acc.slice(-2)) : 99;
        if (!acc) {
          return next.statusType;
        } else {
          if (prevPriority < nextPriority) {
            return acc;
          } else {
            return next.statusType;
          }
        }
      },
      null
    );
  }
  if (
    contemporaryRecordRequired &&
    dateOfAwareness &&
    dateOfAwareness <= date &&
    (dateOfCessation ? date < dateOfCessation : date <= today) &&
    dateRecords.every(
      (dateRec) => dateRec.statusType === VocRecordStatus.Related_Site_Event
    )
  ) {
    dateStatus = VocRecordStatus.Outstanding;
  }

  const subSubmenu = (
    isAll: boolean,
    isCompEvent: boolean,
    siteEventId: string
  ) => {
    if (isAll) {
      return {
        className: "voc-records-tab-cell-date-subsubmenu",
        items: [
          ...Object.entries(omit(VocRecordStatus, ["Related_Site_Event"])),
        ].map((entry) => ({
          key: entry[0],
          text: `● ${entry[0].replace(/_/g, " ")}`,
          onClick: () => {
            handleStatusChange(true, null, null, entry[1]);
          },
        })),
      };
    } else {
      return {
        className: "voc-records-tab-cell-date-subsubmenu",
        items: [
          ...Object.entries(omit(VocRecordStatus, ["Related_Site_Event"])),
        ].map((entry) => ({
          key: entry[0],
          text: `● ${entry[0].replace(/_/g, " ")}`,
          onClick: () => {
            handleStatusChange(false, isCompEvent, siteEventId, entry[1]);
          },
        })),
      };
    }
  };

  const mapRefactor = (dateRecords: DateRecord[]) => {
    return dateRecords.map((dr) => ({
      key: dr.siteEventId
        ? String(dr.siteEventId)
        : String(dr.compensationEventId),
      text: dr.siteEventId
        ? dr.siteEventSerial + ": " + dr.siteEventTitle
        : dr.compensationEventSerial + ": " + dr.compensationEventTitle,
      subMenuProps: subSubmenu(
        false,
        dr.siteEventId ? false : true,
        dr.siteEventId ? dr.siteEventId : dr.compensationEventId
      ),
    }));
  };

  const submenu = (status: string) => {
    if (status === "all") {
      return {
        className: "voc-records-tab-cell-date-submenu",
        items: [
          {
            key: "all",
            text: t("voc.eventPage.records.batchUpdateAll"),
            subMenuProps: subSubmenu(true, null, null),
          },
          ...mapRefactor(dateRecords),
        ],
      };
    }
    return {
      className: "voc-records-tab-cell-date-submenu",
      items: mapRefactor(
        dateRecords.filter((dr) =>
          status !== "all" ? dr.statusType === status : dr
        )
      ),
    };
  };

  const compEventMenuItems = [
    dateRecords && dateRecords.length > 0
      ? {
          key: "all",
          text:
            "● " +
            t("voc.eventPage.records.allLinkedEvents") +
            " (" +
            dateRecords.length +
            ")",
          subMenuProps: submenu("all"),
          disabled: false,
        }
      : {
          key: "all",
          text: "● " + t("voc.eventPage.records.allLinkedEvents") + " (0)",
          disabled: true,
        },
  ].concat([
    ...Object.entries(VocRecordStatus)
      // .filter(
      //   entry => dateRecord || entry[1] !== VocRecordStatus.Outstanding
      // )
      // eslint-disable-next-line array-callback-return
      .map((entry) => {
        if (dateRecords)
          if (
            dateRecords.filter((dr) => dr.statusType === entry[1]).length > 0
          ) {
            return {
              key: entry[0],
              text: `● ${entry[0].replace(/_/g, " ")} (${
                dateRecords.filter((dr) => dr.statusType === entry[1]).length
              })`,
              subMenuProps: submenu(entry[1]),
              disabled: false,
            };
          } else {
            return {
              className: "empty",
              key: entry[0],
              text: `● ${entry[0].replace(/_/g, " ")} (0)`,
              disabled: true,
            };
          }
      }),
  ]);

  const compEventMenu = {
    className: "voc-records-tab-cell-date-menu",
    shouldFocusOnMount: true,
    items: compEventMenuItems,
  };

  const siteEventMenu = siteEventId
    ? subSubmenu(false, false, siteEventId)
    : null;

  return (
    <DayCellLayout
      dateStatus={dateStatus}
      remarks={remarks}
      relatedRemarks={relatedRemarks}
      date={date}
      showMonth={showMonth}
      menu={siteEventId ? siteEventMenu : compEventMenu}
      supportDocuments={supportDocuments}
      eventCount={dateRecords ? dateRecords.length : null}
    />
  );
};

export default DayCellContainer;
