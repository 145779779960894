import { NxpButton, NxpModal } from "@nexploretechnology/nxp-ui";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useKeycloak } from "@react-keycloak/web";
import { Divider } from "antd";
import warningIconUrl from "../../images/warningOutlined.svg";

const useStyles = createUseStyles((theme) => ({
  warningIcon: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "20px",
    width: "15%",
  },
  warningText: {
    textAlign: "center",
    fontSize: theme.fontSize.h2,
    fontWeight: theme.fontWeight.bold,
  },
  exitButton: {
    width: "112px",
    height: "40px",
    "& > span": {
      fontSize: "16px",
    },
  },
  lineTenderReceived: {
    width: "calc(100% + 50px) !important",
    marginLeft: "-25px !important",
    marginRight: "-25px !important",
  },
}));

const SuspendedUserModal = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { keycloak } = useKeycloak();

  return (
    <NxpModal
      open={true}
      closable={false}
      footer={
        <>
          <Divider className={classes.lineTenderReceived} />
          <NxpButton
            className={classes.exitButton}
            size="middle"
            onClick={() => keycloak.logout()}
          >
            {t("app.common.exit")}
          </NxpButton>
        </>
      }
    >
      <img
        className={classes.warningIcon}
        src={warningIconUrl}
        alt={t("app.common.warning") ?? "Warning"}
      />
      <h2 className={classes.warningText}>
        {t("app.common.SuspendedUserWarning")}
      </h2>
    </NxpModal>
  );
};

export default SuspendedUserModal;
