import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "office-ui-fabric-react";

import AppButton from "../../../../app/components/app-button";
import AppCard from "../../../../app/components/app-card/AppCard";
import {
  AppFormReadOnlyField,
  AppFormRow,
} from "../../../../app/components/app-form";
import AppMessageBar, {
  AppMessageBarType,
} from "../../../../app/components/app-message-bar";
import useAppContext from "../../../../app/hooks/useAppContext";
import {
  VocEventSummaryNotification,
  VocGetRecentMessagesResponse,
  VocRecordStatus,
} from "../../../../app/types";
import { mapDateToString } from "../../../../app/utils/mappers";
import { getRecentMessages } from "../../../compensation-event-services";
import ReminderModalContainer from "../../notifications-tab/reminder/ReminderModalContainer";
import { DateRecord } from "../RecordsTabContainer";

import "./RecordsSummary.scss";
import { useAsync } from "../../../../app/hooks/useAsync";

interface RecordsSummaryProps {
  dateRecords: DateRecord[];
  dateOfAwareness?: Date;
  dateOfCessation?: Date;
  contemporaryRecordRequired: boolean;
  eventId: string;
  entityId: string;
  siteEventId: string;
}

const RecordsSummary: React.FC<RecordsSummaryProps> = ({
  dateRecords,
  dateOfAwareness,
  dateOfCessation,
  contemporaryRecordRequired,
  eventId,
  siteEventId,
}) => {
  const { t } = useTranslation();
  //notifications sidebar
  const { serviceConfig } = useAppContext();
  const [ownerNotifyDate, setOwnerNotifyDate] = React.useState<Date>(null);

  const [showReminderModal, setShowReminderModal] = React.useState(false);
  const handleModalClose = () => setShowReminderModal(false);
  const [injectedDefaultForm, setInjectedDefaultForm] =
    React.useState<VocEventSummaryNotification>(null);

  const [,] = useAsync<VocGetRecentMessagesResponse>(
    () => getRecentMessages(siteEventId ?? eventId, serviceConfig),
    {
      onSuccess: (data) => {
        setOwnerNotifyDate(data.eventOwnersMessage.dispatchedOn);
      },
    }
  );
  //end notifications sidebar

  let totalOutStandingCount = 0;
  let defaultOutStandingCount = 0; // dates without status but counted as outstanding (>= dateOfAwareness AND <= endDate AND no status)

  if (dateOfAwareness) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDate = dateOfCessation || today;
    if (dateOfAwareness < endDate) {
      // count outstanding between dateOfAwareness and end date
      const nonOutstandingCount = dateRecords.filter(
        (record) =>
          record?.statusType !== VocRecordStatus.Related_Site_Event &&
          record.date >= dateOfAwareness &&
          record.date < endDate
      ).length;

      if (contemporaryRecordRequired) {
        defaultOutStandingCount =
          Math.ceil(
            (endDate.getTime() - dateOfAwareness.getTime()) /
              (1000 * 60 * 60 * 24)
          ) - nonOutstandingCount;
      }
      defaultOutStandingCount =
        defaultOutStandingCount > 0 ? defaultOutStandingCount : 0;

      const count =
        defaultOutStandingCount +
        dateRecords.filter(
          (record) => record.statusType === VocRecordStatus.Outstanding
        ).length;
      totalOutStandingCount = count > 0 ? count : 0;
    }
  }

  return (
    <div className="voc-records-tab-summary">
      {!dateOfAwareness && (
        <AppMessageBar messageBarType={AppMessageBarType.Warning}>
          {t("voc.eventPage.records.pleaseInputDateOfAwareness")}
        </AppMessageBar>
      )}
      <AppCard title={t("voc.eventPage.records.schedule")} canEdit={false}>
        <AppFormRow>
          <AppFormReadOnlyField
            label={t("voc.eventPage.records.dateOfAwareness")}
          >
            <p className="date">
              <Icon iconName="Calendar" />{" "}
              {dateOfAwareness ? mapDateToString(dateOfAwareness) : "--"}
            </p>
          </AppFormReadOnlyField>
          <AppFormReadOnlyField
            label={t("voc.eventPage.records.dateOfCessation")}
          >
            <p className="date">
              <Icon iconName="Calendar" />{" "}
              {dateOfCessation ? mapDateToString(dateOfCessation) : "--"}
            </p>
          </AppFormReadOnlyField>
        </AppFormRow>
      </AppCard>
      <ul>
        {Object.entries(VocRecordStatus)
          .filter((status) => status[1] !== VocRecordStatus.Related_Site_Event)
          .map((entry) => {
            return (
              <li key={entry[0]}>
                <span
                  className={`color-dot voc-records-tab-status-${entry[0]
                    .toLowerCase()
                    .replace(/_/g, "-")}`}
                />
                {entry[0].replace(/_/g, " ")}
                <span className="count">
                  {entry[1] === VocRecordStatus.Outstanding
                    ? totalOutStandingCount
                    : dateRecords.filter((item) => item.statusType === entry[1])
                        .length}
                </span>
              </li>
            );
          })}
        <li>
          <span className="color-dot" />
          {t("voc.common.total")}
          <span className="count">
            {dateRecords.filter(
              (item) =>
                item.statusType &&
                item.statusType !== VocRecordStatus.Related_Site_Event
            ).length + defaultOutStandingCount}
          </span>
        </li>
      </ul>
      {!siteEventId ? (
        <div className="notifications-wrapper">
          <AppCard
            title={t("voc.eventPage.summary.notifications")}
            canEdit={false}
          >
            <div className="notification">
              <div className="notification-usertype title">
                {t("voc.eventPage.records.userType")}
              </div>
              <div className="notification-dispatched title">
                {t("voc.eventPage.records.lastNotifiedDate")}
              </div>
            </div>
            <div className="notification">
              <div className="notification-usertype">
                {t("voc.eventPage.records.owners")}
              </div>
              <div className="notification-dispatched">
                <div className="date">
                  {ownerNotifyDate ? mapDateToString(ownerNotifyDate) : "N/A"}
                </div>
                <AppButton
                  onClick={() => {
                    setInjectedDefaultForm({
                      sendToAll: false,
                      sendToCoOnly: true,
                      sendToRoAndRsOnly: true,
                      sendToOthers: undefined,
                      message: "",
                    });
                    setShowReminderModal(true);
                  }}
                >
                  {t("voc.eventPage.records.notify")}
                </AppButton>
              </div>
            </div>
          </AppCard>
        </div>
      ) : null}
      <ReminderModalContainer
        showReminderModal={showReminderModal}
        onModalClose={handleModalClose}
        injectedDefaultForm={injectedDefaultForm}
      />
    </div>
  );
};

export default RecordsSummary;
