import { Icon } from "office-ui-fabric-react";

export { Icon } from "office-ui-fabric-react";

export const SidebarIcon = {
  formsItp: "Preview",
  constructionLots: "FormLibraryMirrored",
  admin: "MapPin",
  process: "Org",
  quit: "ChromeBack",
};
export const ModalIcon = {
  close: "clear",
  back: "RevToggleKey",
};

export const ListIcon = {
  more: "more",
};

export default Icon;
