import { NxpFormGrid, NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import i18n from "../../../../app/i18n/i18n";
import {
  VocGetValuesBuildUp,
  VocGetValuesEisReporting,
  VocGetValuesToClient,
  VocGetValuesToDate,
} from "../../../../app/types";
import { getFormattedInputNumberControlProps } from "../../../../app/utils/number/getFormattedInputNumberControlProps";
import { computeInternalProgressValue } from "../helpers/actualProgressCalculation";
import {
  calculateOverheadProfit,
  calculateProfitLoss,
} from "../helpers/buildUpCalculations";
import {
  computeEisA8A14,
  computeEisStrategyIncludedInPlanning,
} from "../helpers/eisReportCalculations";
import { computeCumulativeAppliedNominal } from "../helpers/paymentWithClient";
import {
  computeInternalTarget,
  computeInternalValue,
  computeInternalVariance,
  computeRecovery,
  computeVarianceTCA,
} from "../helpers/toClientCalculation";

interface ToClientPanelProps {
  fieldNames: Record<string, string>;
  editing: boolean;
  toClientFormState: VocGetValuesToClient;
  onSetToClientFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesToClient>
  >;
  onSetBuildUpFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesBuildUp>
  >;
  onSetEisReportingFormState: React.Dispatch<
    React.SetStateAction<
      Pick<
        VocGetValuesEisReporting,
        | "eisA2A6"
        | "in16"
        | "eisA8A14"
        | "eisSecuredProject"
        | "eisStrategyIncludedInPlanning"
      >
    >
  >;
  onSetActualValues: React.Dispatch<
    React.SetStateAction<{
      physicalProgress: number;
      internalProgressValue: number;
    }>
  >;

  onSetPaymentWithClientFormState: React.Dispatch<
    React.SetStateAction<VocGetValuesToDate>
  >;
}

const useStyles = createUseStyles((theme) => ({
  title: {
    color: theme.palette.primary,
  },
}));

const ToClientPanel: React.FC<ToClientPanelProps> = ({
  fieldNames,
  editing,
  toClientFormState,
  onSetToClientFormState,
  onSetBuildUpFormState,
  onSetActualValues,
  onSetEisReportingFormState,
  onSetPaymentWithClientFormState,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const toClientFormItems: NxpFormGridItemProps<VocGetValuesToClient>[] = [
    {
      // A
      controlType: "inputNumber",
      label: fieldNames.firstEstimate,
      itemFieldName: "firstEstimate",
      span: 3,
      controlProps: {
        value: toClientFormState?.firstEstimate,
        addonBefore: editing ? "$" : undefined,
        prefix: editing ? undefined : "$",
        precision: 2,
        decimalPlace: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      // B
      controlType: "inputNumber",
      label: fieldNames.internalSubstantiated,
      itemFieldName: "internalEstimateSubstantiated",
      span: 3,
      controlProps: {
        value: toClientFormState?.internalEstimateSubstantiated,
        addonBefore: editing ? "$" : undefined,
        prefix: editing ? undefined : "$",
        precision: 2,
        decimalPlace: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      controlType: "inputNumber",
      label: fieldNames.internalValue,
      itemFieldName: "internalValue",
      span: 3,
      editing: false,
      controlProps: {
        value: toClientFormState?.internalValue,
        prefix: "$",
        precision: 2,
        readOnly: true,
        decimalPlace: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      // D
      controlType: "inputPercent",
      label: fieldNames.recovery,
      itemFieldName: "recovery",
      span: 3,
      controlProps: {
        addonAfter: editing ? "%" : undefined,
        suffix: editing ? undefined : "%",
        value: toClientFormState?.recovery,
        precision: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      // E
      controlType: "inputNumber",
      label: fieldNames.internalTarget,
      itemFieldName: "internalTarget",
      span: 3,
      controlProps: {
        value: toClientFormState?.internalTarget,
        addonBefore: editing ? "$" : undefined,
        prefix: editing ? undefined : "$",
        precision: 2,
        decimalPlace: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      //F
      controlType: "inputNumber",
      label: fieldNames.clientAssessed,
      itemFieldName: "clientAssessed",
      span: 3,
      controlProps: {
        value: toClientFormState?.clientAssessed,
        addonBefore: editing ? "$" : undefined,
        prefix: editing ? undefined : "$",
        precision: 2,
        decimalPlace: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      //G = C - F
      controlType: "inputNumber",
      label: fieldNames.varianceIVCA,
      itemFieldName: "internalVariance",
      span: 3,
      editing: false,
      controlProps: {
        value: toClientFormState?.internalVariance,
        prefix: "$",
        decimalPlace: 2,
        precision: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
    {
      //H = E - F
      controlType: "inputNumber",
      label: fieldNames.varianceTCA,
      itemFieldName: "targetVariance",
      span: 3,
      editing: false,
      controlProps: {
        value: toClientFormState?.targetVariance,
        prefix: "$",
        precision: 2,
        decimalPlace: 2,
        locale: i18n.language,
        ...getFormattedInputNumberControlProps(),
      },
    },
  ];

  const handleFormGridStateChange = (
    fieldName: keyof VocGetValuesToClient,
    value: number
  ) => {
    onSetToClientFormState((prev) => {
      const updatedState = {
        ...prev,
        [fieldName]: value,
      };

      //A or B
      if (
        fieldName === "internalEstimateSubstantiated" ||
        fieldName === "firstEstimate"
      ) {
        updatedState.internalValue = computeInternalValue(updatedState);
        updatedState.internalTarget = computeInternalTarget(updatedState);
        updatedState.internalVariance = computeInternalVariance(updatedState);
        updatedState.targetVariance = computeVarianceTCA(updatedState);

        //N = E-M
        onSetBuildUpFormState((prevState) => {
          return {
            ...prevState,
            profitLoss: calculateProfitLoss(
              prevState,
              computeInternalTarget(updatedState)
            ),
          };
        });

        //O = (N + L) / (I + J + K)
        onSetBuildUpFormState((prevState) => {
          prevState.profitLoss = calculateProfitLoss(
            prevState,
            computeInternalTarget(updatedState)
          );
          return {
            ...prevState,
            overheadProfit: calculateOverheadProfit(prevState),
          };
        });

        //EisA8A14 = E - eisA2A6
        onSetEisReportingFormState((prevState) => {
          return {
            ...prevState,
            eisA8A14: computeEisA8A14(
              prevState,
              computeInternalTarget(updatedState)
            ),
          };
        });

        //eisStrategyIncludedInPlanning = E - eisStrategyIncludedInPlanning
        onSetEisReportingFormState((prevState) => {
          return {
            ...prevState,
            computeEisStrategyIncludedInPlanning:
              computeEisStrategyIncludedInPlanning(
                prevState,
                computeInternalTarget(updatedState)
              ),
          };
        });

        //U = T * E
        onSetActualValues((prevState) => {
          return {
            ...prevState,
            internalProgressValue: computeInternalProgressValue(
              prevState,
              computeInternalTarget(updatedState)
            ),
          };
        });

        //W = E * V
        onSetPaymentWithClientFormState((prevState) => {
          return {
            ...prevState,
            cumulativeAppliedNominal: computeCumulativeAppliedNominal(
              prevState,
              computeInternalTarget(updatedState)
            ),
          };
        });
      }

      //D
      if (fieldName === "recovery") {
        updatedState.internalTarget = computeInternalTarget(updatedState);
        updatedState.targetVariance = computeVarianceTCA(updatedState);
        //N = E-M
        onSetBuildUpFormState((prevState) => {
          return {
            ...prevState,
            profitLoss: calculateProfitLoss(
              prevState,
              computeInternalTarget(updatedState)
            ),
          };
        });

        //O = (N + L) / (I + J + K)
        onSetBuildUpFormState((prevState) => {
          prevState.profitLoss = calculateProfitLoss(
            prevState,
            computeInternalTarget(updatedState)
          );
          return {
            ...prevState,
            overheadProfit: calculateOverheadProfit(prevState),
          };
        });

        //U = T * E
        onSetActualValues((prevState) => {
          return {
            ...prevState,
            internalProgressValue: computeInternalProgressValue(
              prevState,
              computeInternalTarget(updatedState)
            ),
          };
        });

        //EisA8A14 = E - eisA2A6
        onSetEisReportingFormState((prevState) => {
          return {
            ...prevState,
            eisA8A14: computeEisA8A14(
              prevState,
              computeInternalTarget(updatedState)
            ),
          };
        });

        //computeEisStrategyIncludedInPlanning = E - computeEisStrategyIncludedInPlanning
        onSetEisReportingFormState((prevState) => {
          return {
            ...prevState,
            computeEisStrategyIncludedInPlanning:
              computeEisStrategyIncludedInPlanning(
                prevState,
                computeInternalTarget(updatedState)
              ),
          };
        });

        //W = E * V
        onSetPaymentWithClientFormState((prevState) => {
          return {
            ...prevState,
            cumulativeAppliedNominal: computeCumulativeAppliedNominal(
              prevState,
              computeInternalTarget(updatedState)
            ),
          };
        });
      }

      //E
      if (fieldName === "internalTarget") {
        updatedState.recovery = computeRecovery(updatedState);
        updatedState.targetVariance = computeVarianceTCA(updatedState);

        //N = E-M
        onSetBuildUpFormState((prevState) => {
          const { internalTarget } = updatedState;
          return {
            ...prevState,
            profitLoss: calculateProfitLoss(prevState, internalTarget),
          };
        });

        //O = (N + L) / (I + J + K)
        onSetBuildUpFormState((prevState) => {
          const { internalTarget } = updatedState;
          prevState.profitLoss = calculateProfitLoss(prevState, internalTarget);
          return {
            ...prevState,
            overheadProfit: calculateOverheadProfit(prevState),
          };
        });

        //U = T * E
        onSetActualValues((prevState) => {
          return {
            ...prevState,
            internalProgressValue: computeInternalProgressValue(
              prevState,
              computeInternalTarget(updatedState)
            ),
          };
        });

        //EisA8A14 = E - eisA2A6
        onSetEisReportingFormState((prevState) => {
          return {
            ...prevState,
            eisA8A14: computeEisA8A14(
              prevState,
              computeInternalTarget(updatedState)
            ),
          };
        });

        //eisStrategyIncludedInPlanning
        onSetEisReportingFormState((prevState) => {
          return {
            ...prevState,
            eisStrategyIncludedInPlanning: computeEisStrategyIncludedInPlanning(
              prevState,
              computeInternalTarget(updatedState)
            ),
          };
        });

        //W = E * V
        onSetPaymentWithClientFormState((prevState) => {
          return {
            ...prevState,
            cumulativeAppliedNominal: computeCumulativeAppliedNominal(
              prevState,
              computeInternalTarget(updatedState)
            ),
          };
        });
      }

      //F
      if (fieldName === "clientAssessed") {
        updatedState.internalVariance = computeInternalVariance(updatedState);
        updatedState.targetVariance = computeVarianceTCA(updatedState);
      }
      return updatedState;
    });
  };

  return (
    <>
      <h4 className={classes.title}>
        {t("voc.eventPage.values.toClientHeader")}
      </h4>
      <NxpFormGrid
        editing={editing}
        formItems={toClientFormItems}
        formState={toClientFormState}
        onFormStateChange={handleFormGridStateChange}
      />
    </>
  );
};

export default ToClientPanel;
