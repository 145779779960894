import React from "react";
import { useTranslation } from "react-i18next";

import { cloneDeep } from "lodash";

import AppCollapsible from "../../../app/components/app-collapsible/AppCollapsible";
import { DeletedRecord, VocLibraryObject } from "../../../app/types";
import { useLibraryHandler } from "../../hooks/useLibraryHandler";
import AppDataListV2 from "../../../app/components/app-data-list/AppDataListV2";
import { DataListItem } from "../../../app/components/app-data-list/types";

interface ClausesProps {
  libraryType: "VOC_CLAUSE_INSTR" | "VOC_CLAUSE_VALUATION";
  refetchCount: number;
}

export const Clauses: React.FC<ClausesProps> = ({
  libraryType,
  refetchCount,
}) => {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = React.useState(false);

  const convertClauses = (items: VocLibraryObject[]) => {
    const converted = items.map((item) => ({
      id: item.id,
      value: item.value,
      edited: false,
      sortOrder: item.sortOrder,
      fieldDataArray: [{ fieldData: item.label }, { fieldData: item.files }],
    }));
    return converted;
  };

  const {
    // Variables
    fetchLibraryAndUsage,
    isLibraryAndUsageLoaded,
    libraryItem,
    displayLibraryItem,
    setDisplayLibraryItem,
    // Library Custom Sort
    isCustomSort,
    saveCustomOrder,
    resetCustomOrder,
    // Update Handler
    handleUpdateLibraries,
  } = useLibraryHandler(libraryType, convertClauses, refetchCount);

  // ================================================
  //                 setFiles
  // ================================================

  const handleCustomSetFiles = React.useCallback(
    (file: any, fileRef: any) => {
      setDisplayLibraryItem((prevDisplayLibraryItem) => {
        const newDisplayLibraryItem = cloneDeep(prevDisplayLibraryItem);
        if (newDisplayLibraryItem?.[fileRef]?.fieldDataArray?.[1]) {
          newDisplayLibraryItem[fileRef].fieldDataArray[1].fieldData = file;
          newDisplayLibraryItem[fileRef].edited = true;
        }
        return newDisplayLibraryItem;
      });
    },
    [setDisplayLibraryItem]
  );

  // ================================================
  //                 panel & colDef
  // ================================================

  const panelTitle = React.useMemo(
    () =>
      libraryType === "VOC_CLAUSE_INSTR"
        ? t("voc.library.titles.clausesInstruction")
        : libraryType === "VOC_CLAUSE_VALUATION"
        ? t("voc.library.titles.clausesValuation")
        : "",
    [libraryType, t]
  );

  const column = React.useMemo(
    () => [
      {
        columnName: t("voc.eventPage.values.description"),
        columnWidthClassName: "remainder",
        columnDataType: "string",
        editable: true,
      },
      {
        columnName: t("voc.eventPage.summary.viewFile"),
        columnWidthClassName: "fourth",
        columnDataType: "file",
        editable: true,
      },
    ],
    [t]
  );

  // ================================================
  //                Action Handler
  // ================================================

  const handleSave = () => {
    const cMemo = libraryItem;
    setIsEditing(false);
    const deconvertedClauses: Partial<VocLibraryObject>[] = [];
    const deconversionTarget: DataListItem[] = displayLibraryItem;
    deconversionTarget.forEach((clause: DataListItem, i: number) => {
      if (clause.edited) {
        deconvertedClauses.push({
          id: clause.id,
          value: clause.value,
          label: clause.fieldDataArray[0].fieldData,
          files:
            clause.fieldDataArray[1].fieldData?.length > 0
              ? [
                  {
                    assetId: clause.fieldDataArray[1].fieldData[0].assetId,
                    fileName: clause.fieldDataArray[1].fieldData[0].fileName,
                    mimeType: clause.fieldDataArray[1].fieldData[0].mimeType,
                    url: clause.fieldDataArray[1].fieldData[0].url,
                  },
                ]
              : [],
        });
      }
    });
    const deletedRecords: DeletedRecord[] = [];
    cMemo.forEach((memo: any) => {
      let deleted = true;
      deconversionTarget.forEach((clause) => {
        if (memo.id === clause.id) {
          deleted = false;
        }
      });
      if (deleted) {
        deletedRecords.push({
          id: memo.id,
          label: memo.label,
          value: memo.value,
        });
      }
    });
    //BACKEND REQUIRES CERTAIN FIELDS, EVEN WHEN DELETING, WHEN IT SHOULD NOT BE NECESSARY
    const deletedRecordObjects: DeletedRecord[] = deletedRecords.map((rec) => ({
      id: rec.id,
      value: rec.value,
      label: rec.label,
      isDeleted: true,
    }));
    const combinedRecords = [...deconvertedClauses, ...deletedRecordObjects];
    handleUpdateLibraries({
      items: combinedRecords,
    });
    setIsEditing(false);
  };

  const handleCancel = () => {
    // setDisplayLibraryItem(libraryItem);
    fetchLibraryAndUsage();
  };

  return (
    <AppCollapsible
      permissionCode="libraries"
      hasFields={false}
      editCallback={() => {
        setIsEditing(true);
      }}
      editingState={isEditing}
      saveCallback={handleSave}
      cancelCallback={() => {
        handleCancel();
        setIsEditing(false);
      }}
      firstDecollapseCallback={() => {
        fetchLibraryAndUsage();
      }}
      isLoading={!isLibraryAndUsageLoaded}
      isLoadOnEveryClick={true}
      headerChildren={<div className="doctype-title">{panelTitle}</div>}
      bodyChildren={
        isLibraryAndUsageLoaded ? (
          // <AppDataList
          <AppDataListV2
            permissionCode="libraries"
            editing={isEditing}
            canDeleteLastItem={true}
            columns={column}
            oriItems={libraryItem}
            items={displayLibraryItem}
            editSetStateHook={setDisplayLibraryItem}
            handleCustomSetFiles={handleCustomSetFiles}
            // setFiles={setFiles} // we use handleCustomSetFiles
            defaultSortDirection="asc"
            saveCustomOrderCallback={saveCustomOrder}
            resetCustomOrderCallback={resetCustomOrder}
            saveCustomOrderCode={libraryType}
            initCustomSortState={isCustomSort}
          />
        ) : null
      }
    ></AppCollapsible>
  );
};

export default Clauses;
