import "./CreateFeedbackModalLayout.scss";

import { Checkbox, Icon } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";

import { ValidationResult } from "@nexploretechnology/nxp-ui";

import AppButton from "../../app/components/app-button";
import AppFileUpload from "../../app/components/app-file-upload/AppFileUpload";
import {
  AppFormDropdownField,
  AppFormField,
  AppFormRow,
  AppFormTextField,
} from "../../app/components/app-form";
import AppModal from "../../app/components/app-modal";
import { AsyncResult } from "../../app/hooks/useAsync";
import {
  VocFeedback,
  VocFeedbackCreation,
  VocFeedbackType,
} from "../../app/types";
import CustomApiError from "../../app/utils/backend/custom-api-error";
import feedback from "../images/feedback.svg";
import { getFeedbackTypeOptions } from "./feedback-options.const";

interface CreateFeedbackModalLayoutProps {
  entityId: string;
  showCreateModal: boolean;
  onModalClose: () => void;
  form: VocFeedbackCreation;
  formSubmit: boolean;
  onChange: (changeValues: Partial<VocFeedbackCreation>) => void;
  onSave: (addAnother: boolean) => void;
  validationError: ValidationResult<VocFeedbackCreation>;
  createFeedbackAsyncResult: AsyncResult<VocFeedback>;
}

const CreateFeedbackModalLayout: React.FC<CreateFeedbackModalLayoutProps> = ({
  showCreateModal,
  onModalClose,
  form,
  onChange,
  onSave,
  validationError,
  createFeedbackAsyncResult,
  formSubmit,
}) => {
  const { t } = useTranslation();
  const feedbackTypeOptions = getFeedbackTypeOptions(t);
  const inputErrors = {
    ...validationError,
    ...(createFeedbackAsyncResult.error as CustomApiError)?.serverResponseData,
  };
  return (
    <AppModal
      title={t("voc.feedback.shareYourFeedback")}
      className="create-Feedback-modal"
      isOpen={showCreateModal}
      loading={createFeedbackAsyncResult.loading}
      onClose={onModalClose}
      header={!formSubmit}
      footer={
        !formSubmit ? (
          <AppButton
            disabled={createFeedbackAsyncResult.loading}
            className="button"
            onClick={() => onSave(false)}
            text={t("voc.common.save")}
          />
        ) : (
          <AppButton
            disabled={createFeedbackAsyncResult.loading}
            className="button"
            onClick={onModalClose}
            text={t("voc.library.importExcel.done")}
          />
        )
      }
      errorContent={
        !(createFeedbackAsyncResult.error as CustomApiError)
          ?.serverResponseData && createFeedbackAsyncResult.error?.message
      }
    >
      {formSubmit ? (
        <div className="thanks">
          <h1>{t("voc.feedback.thankyou")}</h1>
          <AppFormRow className="thanks">
            <img src={feedback} className="feedback-logo" alt="feedback" />
          </AppFormRow>
          <div>
            {t("voc.feedback.thankyouContentLine1")}
            {t("voc.feedback.thankyouContentLine2")}
          </div>
        </div>
      ) : (
        <>
          <AppFormRow className="half">
            <AppFormField className="category-dropdown">
              <AppFormDropdownField
                label={t("voc.feedback.feedbackCategory")}
                required={true}
                placeholder={t("voc.common.pleaseSelect")}
                options={feedbackTypeOptions}
                value={form.feedbackType?.value}
                errorMessage={inputErrors?.feedbackType}
                onChange={(_, option) =>
                  onChange({
                    feedbackType: {
                      label: undefined,
                      value: option.key as VocFeedbackType,
                    },
                  })
                }
              />
            </AppFormField>
          </AppFormRow>
          <AppFormRow>
            <AppFormField>
              <AppFormTextField
                required={true}
                label={t("voc.feedback.description")}
                multiline
                name="feedback-description"
                value={form.feedbackDes}
                errorMessage={inputErrors?.feedbackDes}
                onChange={(_, value) => onChange({ feedbackDes: value })}
              />
            </AppFormField>
          </AppFormRow>
          <AppFormRow className="half">
            <AppFormField className="file-upload ">
              {form.documentFiles?.length > 0 ? (
                <>
                  <label className="file-info-label">File</label>
                  <p className="file-info">
                    <a
                      href={form.documentFiles[0].url}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Icon iconName="PageSolid" />
                      {form.documentFiles[0].fileName}
                    </a>
                    <button
                      className="remove"
                      onClick={() =>
                        onChange({
                          documentFiles: [],
                        })
                      }
                    >
                      X
                    </button>
                  </p>
                </>
              ) : (
                <AppFileUpload
                  label={t("voc.feedback.attachFile")}
                  onChange={(files) => {
                    if (files.length) {
                      onChange({
                        documentFiles: [
                          {
                            url: (files[0] as any).url,
                            fileName: files[0].fileName,
                            assetId: files[0].assetId,
                            mimeType: files[0].mimeType,
                          },
                        ],
                      });
                    }
                  }}
                />
              )}
            </AppFormField>
          </AppFormRow>
          <AppFormRow className="half">
            <AppFormField className="synchronise-input">
              <Checkbox
                label={t("voc.feedback.isAnonymous")}
                onChange={(_, checked) => {
                  onChange({
                    isAnonymous: checked,
                  });
                }}
                checked={form.isAnonymous}
              />
            </AppFormField>
          </AppFormRow>
        </>
      )}
    </AppModal>
  );
};

export default CreateFeedbackModalLayout;
