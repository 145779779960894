import i18n from "../i18n/i18n";

export function convertIntoAmountFormat(
  value: number,
  stringConversionSymbol: string,
  stringConversionDecimals: number
) {
  if (value === undefined || value === null) {
    return "";
  }

  const convertedValue = stringConversionSymbol === "%" ? value * 100 : value;

  // parse value to .00 format
  const parsedValue = parseFloat(convertedValue.toString()).toFixed(
    Math.max(stringConversionDecimals ? stringConversionDecimals : 0)
  );

  const parts = parsedValue.split(".");

  // parse value to comma format
  if (["de", "es"].includes(i18n.language)) {
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  } else {
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
}
