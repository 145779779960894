import React from "react";
import { useTranslation } from "react-i18next";

import AppButton from "../../../app/components/app-button";
import AppFileUpload from "../../../app/components/app-file-upload/AppFileUpload";
import AppModal from "../../../app/components/app-modal";
import { RemarkAttachment } from "../../../app/services/vocService";

interface ImportExcelModalProps {
  entityId: string;
  showImportExcelModal: boolean;
  onModalClose: () => void;
  file: Partial<RemarkAttachment>[];
  setFile: React.Dispatch<React.SetStateAction<Partial<RemarkAttachment>[]>>;
  importExcel: () => void;
  importExecuted: boolean;
  setImportExecuted: React.Dispatch<React.SetStateAction<boolean>>;
  importSuccess: boolean;
  importFailure: boolean;
}

const ImportExcelModal: React.FC<ImportExcelModalProps> = (props) => {
  const {
    showImportExcelModal,
    onModalClose,
    file,
    setFile,
    importExcel,
    importExecuted,
    setImportExecuted,
    importSuccess,
    importFailure,
  } = props;

  const { t } = useTranslation();

  const handleDownloadTemplate = () => {
    fetch(`${window.location.origin}/templates/libraries-template.xlsx`).then(
      (response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "libraries-template.xlsx";
          alink.click();
        });
      }
    );
  };

  React.useEffect(() => {
    if (file && file.length > 0) {
      importExcel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <AppModal
      className="import-excel-modal--library"
      title={t("voc.library.importExcel.importFromExcel")}
      isOpen={showImportExcelModal}
      onClose={onModalClose}
      mandatoryHintReplacement={<div></div>}
      footer={
        <>
          {importExecuted && (
            <AppButton
              onClick={() => {
                setImportExecuted(false);
              }}
            >
              {t("voc.library.importExcel.uploadAgain")}
            </AppButton>
          )}
          <AppButton
            onClick={() => {
              onModalClose();
            }}
          >
            {t("voc.library.importExcel.done")}
          </AppButton>
        </>
      }
    >
      {importExecuted && importSuccess && (
        <div className="import-notice import-succeeded">
          {t("voc.library.importExcel.success")}
        </div>
      )}
      {importExecuted && importFailure && (
        <div className="import-notice import-failed">
          {t("voc.library.importExcel.error")}
        </div>
      )}
      <div className="import-excel-flexbox">
        <div className="download-template-wrapper">
          <div className="title">
            {t("voc.library.importExcel.excelTemplate")}
          </div>
          <div className="download-template">
            <AppButton onClick={handleDownloadTemplate}>
              {t("voc.library.importExcel.download")}
            </AppButton>
          </div>
        </div>
        <div className="upload-excel-wrapper">
          {!importExecuted && (
            <div className="title">
              {t("voc.library.titles.importExcelFile")}
            </div>
          )}
          {importExecuted && (
            <div className="title">
              {t("voc.library.importExcel.lastUploadedExcelFile")}
            </div>
          )}
          {!importExecuted && (
            <AppFileUpload
              label={null}
              onChange={(f) => {
                setFile(f);
              }}
              fileLimit={1}
              customUploadPath="/library/import"
              //need to add uploading state (while file is still uploading) handler
            ></AppFileUpload>
          )}
          {importExecuted && <a href={file[0].url}>{file[0].fileName}</a>}
          <div className="reminder">
            #{t("voc.library.reminder.saveBeforeImport")}
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default ImportExcelModal;
