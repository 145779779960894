import { EntityUser } from "../app/services/app";
import { apiRequest, ServiceConfig } from "../app/utils/backend/api-request";
export interface ManageRolesTableItem {
  id: string;
  roleCode: string;
  name: string;
  description?: string;
  entityRoleUserIds: string[];
  users: EntityUser[];
}

export interface EntityRole {
  code: string;
  description: string;
  entityId: string;
  id: string;
  name: string;
}

export type AddRole = {
  name: string;
  code: string;
  description?: string;
};

export const getEntityRoles = async (serviceConfig: ServiceConfig) => {
  return apiRequest<EntityRole[]>(`/entity-roles`, serviceConfig);
};

export const getEntityRoleUsers = async (
  serviceConfig: ServiceConfig,
  entityRoleId: string
) => {
  return await apiRequest<EntityUser[]>(
    `/entity-roles/${entityRoleId}/users`,
    serviceConfig
  );
};

export const deleteRole = async (
  serviceConfig: ServiceConfig,
  entityRoleId: string
) => {
  return await apiRequest<EntityUser[]>(
    `/entity-roles/${entityRoleId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export const createRole = async (
  serviceConfig: ServiceConfig,
  role: AddRole
) => {
  return await apiRequest<AddRole>(`/entity-roles`, serviceConfig, {
    method: "POST",
    body: JSON.stringify({
      name: role.name,
      code: role.code,
      description: role.description,
    }),
  });
};

export const editRole = async (
  serviceConfig: ServiceConfig,
  entityRoleId: string,
  role: AddRole
) => {
  return await apiRequest<AddRole>(
    `/entity-roles/${entityRoleId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        name: role.name,
        code: role.code,
        description: role.description,
      }),
    }
  );
};

export const removeUserFromRole = async (
  serviceConfig: ServiceConfig,
  entityRoleId: string,
  userId: string
) => {
  return await apiRequest<EntityUser>(
    `/entity-roles/${entityRoleId}/users/${userId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export const addUserToRole = async (
  serviceConfig: ServiceConfig,
  entityRoleId: string,
  userId: string
) => {
  return await apiRequest<EntityUser>(
    `/entity-roles/${entityRoleId}/users/${userId}`,
    serviceConfig,
    {
      method: "POST",
    }
  );
};
