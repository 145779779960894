import { TFunction } from "i18next";
import { IDropdownOption } from "office-ui-fabric-react";

import { VocFeedbackType } from "../../app/types";

export const getFeedbackTypeOptions = (
  t: TFunction<"translation", undefined, "translation">
) => {
  const feedbackTypeOptions: IDropdownOption[] = [
    {
      key: null,
      text: t("voc.common.pleaseSelect"),
    },
    {
      key: VocFeedbackType.Enhancement,
      text: t("voc.feedback.enhancement"),
    },
    {
      key: VocFeedbackType.MissingInformation,
      text: t("voc.feedback.missingInformation"),
    },
    {
      key: VocFeedbackType.Bug_And_Error,
      text: t("voc.feedback.bugError"),
    },
    {
      key: VocFeedbackType.Question,
      text: t("voc.feedback.question"),
    },
    {
      key: VocFeedbackType.Other,
      text: t("voc.feedback.others"),
    },
  ];

  return feedbackTypeOptions;
};
