import {
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpLabel,
} from "@nexploretechnology/nxp-ui";
import React from "react";
import useAppContext from "../../../../app/hooks/useAppContext";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { VocGetValuesResponse } from "../../../../app/types";

interface CostCodesProps {
  editing: boolean;
  fieldNames: Record<string, string>;
  costCodes: { costCodes: string[] };
  incomingDataPackage: VocGetValuesResponse;
  onSetCostCodes: React.Dispatch<
    React.SetStateAction<{
      costCodes: string[];
    }>
  >;
}

const useStyles = createUseStyles((theme) => ({
  title: {
    color: theme.palette.primary,
  },
}));

const CostCodesPanel: React.FC<CostCodesProps> = ({
  fieldNames,
  editing,
  costCodes,
  incomingDataPackage,
  onSetCostCodes,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { getDictionary } = useAppContext();

  const formItems: NxpFormGridItemProps<{ costCodes: string[] }>[] = [
    {
      controlType: "selectMultiple",
      label: fieldNames.costCode,
      itemFieldName: "costCodes",
      span: 24,
      editing: editing && incomingDataPackage?.records.length === 0,
      controlProps: {
        value: costCodes?.costCodes,
        options: [
          ...(getDictionary("COST_CODES")?.members.map((otp) => ({
            value: otp?.key,
            label: `${otp?.value}`,
          })) || []),
        ],
      },
    },
  ];

  const handleFormGridStateChange = (
    fieldName: keyof { costCodes: string[] },
    value: string[]
  ) => {
    onSetCostCodes((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <>
      <h4 className={classes.title}>{t("voc.eventPage.values.costCodes")}</h4>
      <NxpFormGrid
        editing={incomingDataPackage.records.length === 0 && editing}
        formItems={formItems}
        formState={costCodes}
        onFormStateChange={handleFormGridStateChange}
      />
      <NxpLabel>
        If using individual records, please add cost codes to each individual
        item
      </NxpLabel>
    </>
  );
};

export default CostCodesPanel;
