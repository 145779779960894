import React, { useState } from "react";

import { BaseButton } from "office-ui-fabric-react";

import { ColumnDataType } from "../app-data-table";
import AppFilterModal from "./AppFilterModal";

import "./AppFilter.scss";

export enum FilterType {
  Equal = "Equal",
  NotEqual = "Not Equal",
  IsEmpty = "Is Empty",
  Contains = "Contains",
  GreaterThanOrEqual = "Greater Than Or Equal",
  LessThanOrEqual = "Less Than Or Equal",
  Between = "Between",
}

export interface FilterOption {
  fieldName?: string;
  filterType?: FilterType;
  columnDataType?: ColumnDataType;
  filterValue?: string;
  columnDropdownOptions?: { key: string; text: string }[];
}

export interface FilterColumn {
  fieldName: string;
  name: string; // display nam of the field defined in the table column settings
  columnDataType: ColumnDataType;
  columnDropdownOptions?: { key: string; text: string }[];
}

interface AppFilterProps {
  filterOptions: FilterOption[];
  filterColumns: FilterColumn[];
  buttonClassName?: string;
  onFilterChange: (filterOptions: FilterOption[]) => void;
}

const AppFilter: React.FC<AppFilterProps> = ({
  filterOptions,
  filterColumns,
  buttonClassName,
  onFilterChange,
}) => {
  const [showFilterModal, setShowFilterModal] = useState(false);

  const handleFilterButtonClick = () => {
    setShowFilterModal(true);
  };

  const handleModalClose = () => {
    setShowFilterModal(false);
  };

  return (
    <>
      <BaseButton
        className={`app-filter-button${filterOptions.length ? " active" : ""}${
          buttonClassName ? ` ${buttonClassName}` : ""
        }`}
        iconProps={{ iconName: "FilterSolid" }}
        onClick={handleFilterButtonClick}
      >
        {filterOptions.length > 0 && (
          <span className="count">
            {new Set(filterOptions.map((item) => item.fieldName)).size}
          </span>
        )}
      </BaseButton>
      {showFilterModal && (
        <AppFilterModal
          showFilterModal={showFilterModal}
          onFilterChange={onFilterChange}
          filterOptions={filterOptions}
          filterColumns={filterColumns}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

export default AppFilter;
