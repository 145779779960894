import { hasValue } from "../has-value";

interface DisplayUser {
  displayName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  primaryEmail?: string;
  isSuspended?: boolean;
  isDisabled?: boolean;
}

export const getUserDisplayName = (
  user?: DisplayUser,
  includeEmail = false,
  includeStatus = false
) => {
  if (!hasValue(user)) {
    return "";
  }

  let emailAppend = "";
  if (includeEmail) {
    if (hasValue(user.email) || hasValue(user.primaryEmail)) {
      emailAppend = ` <${user.email || user.primaryEmail}>`;
    }
  }

  let statusAppend = "";
  if (includeStatus) {
    statusAppend += user.isSuspended ? " (Disabled)" : "";
    // statusAppend += user.isDisabled ? " (Removed)" : "";
  }

  let userDisplayName = "";
  if (hasValue(user.displayName)) {
    userDisplayName = user.displayName;
  } else if (hasValue(user.firstName) || hasValue(user.lastName)) {
    userDisplayName = `${user.firstName} ${user.lastName}`;
  } else {
    userDisplayName = "(Anonymous User)";
  }

  return `${userDisplayName}${emailAppend}${statusAppend}`;
};
