import {
  VocCustomUiQuestionListCustomField,
  VocCustomUiQuestionListPresetField,
} from "../../../../app/types";

export const processFields = (data, t) => {
  const fieldLookup: Record<string, string> = {};
  const customs: VocCustomUiQuestionListCustomField[] = [];

  data.layout[0].members.forEach(
    (
      f: VocCustomUiQuestionListCustomField | VocCustomUiQuestionListPresetField
    ) => {
      if (f.type === "QuestionListPresetField") {
        fieldLookup[f.key] = f.customName
          ? f.customName
          : t(`voc.eventPage.values.${f.key}`) || f.name;
      } else if (f.type === "QuestionListCustomField") {
        customs.push(f);
      }
    }
  );

  return { fieldLookup, customs };
};
