import React from "react";
import { createUseStyles } from "react-jss";

import { NxpButton, NxpCardList } from "@nexploretechnology/nxp-ui";

import cardCover from "../../../../app/images/card-cover.png";

const useStyles = createUseStyles((theme) => ({
  demoCardList: {},
}));

const registers = [
  {
    id: 1,
    name: "GZ-SZ-HK Link",
    description:
      "Hong Kong section of the Guangzhou-Shenzhen-Hong Kong Express Rail Link",
    owner: "John A.",
  },
  {
    id: 2,
    name: "HZM Bridge",
    description: "The Hong Kong-Zhuhai-Macao Bridge (HZMB) Hong Kong Section",
    owner: "Sam B.",
  },
  {
    id: 3,
    name: "KT Stage 1",
    description: "Kai Tak development – stage 1 advance infrastructure works",
    owner: "Brian K.",
  },
];

interface DemoCardListProps {}

const DemoCardList: React.FC<DemoCardListProps> = () => {
  const classes = useStyles();

  const handleCardClick = (id: number, e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    alert(id);
  };

  const handleCreateClick = () => {
    alert("create");
  };

  return (
    <div className={classes.demoCardList}>
      <NxpCardList
        isLoading={!registers}
        onCreateClick={handleCreateClick}
        createText="New Register"
        cards={registers.map((reg) => ({
          id: reg.id.toString(),
          onClick: () => handleCardClick(reg.id),
          cover: <img src={cardCover} alt={`Register - ${reg.name}`} />,
          children: (
            <>
              <h3>{reg.name}</h3>
              <p>{reg.description}</p>
              <p>By {reg.owner}</p>
            </>
          ),
          actions: [
            <NxpButton type="text" onClick={(e) => handleCardClick(reg.id, e)}>
              Select
            </NxpButton>,
          ],
        }))}
      />
    </div>
  );
};

export default DemoCardList;
