export default function keyFilter(e: React.KeyboardEvent<HTMLInputElement>) {
  if ((e.ctrlKey || e.metaKey) && e.keyCode === 65) {
    // filter ctrl-a / cmd-a
    return false;
  }
  if (e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 93) {
    // filter ctrl / cmd
    return false;
  }
  if (e.shiftKey && (e.keyCode === 37 || e.keyCode === 39)) {
    // filter shift-left / shift-right
    return false;
  }
  if (e.keyCode === 16) {
    // filter shift
    return false;
  }
  return true;
}
