import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  BaseButton,
  IPivotItemProps,
  Pivot,
  PivotItem,
  PivotLinkFormat,
} from "office-ui-fabric-react";

import AppHeader from "../../../app/components/app-header";
import { VocSiteEvent, VocSiteEventPatchRequest } from "../../../app/types";
import {
  getSiteEventLogs,
  undoSiteEventLogs,
} from "../../../compensation-events/compensation-event-services";
import ChatsTabContainer from "../../../compensation-events/components/chatsTab/ChatsTabContainer";
import LogsTabContainer from "../../../compensation-events/components/logs-tab/LogsTabContainer";
import RecordsTabContainer from "../../../compensation-events/components/records-tab";
import { Recovery } from "../../site-event-services";
import InfoTabContainer from "./info-tab/InfoTabContainer";

import "./SiteEventDetails.scss";
import SupportingTab from "../../../compensation-events/components/supporting-tab/SupportingTab";

interface SiteEventDetailsLayoutProps {
  entityId: string;
  siteEventId: string;
  siteEventData: VocSiteEvent;
  backHref?: string;
  setForm: React.Dispatch<React.SetStateAction<VocSiteEventPatchRequest>>;
  handleEventRefresh: () => void;
  nextSiteEvent: string;
  prevSiteEvent: string;
  preAndNextEventHandler: (eventId: string) => Promise<void>;
  setUpdateSiteEventRecovery: (asyncRequest: () => Promise<Recovery>) => void;
}

const SiteEventDetailsLayout: React.FC<SiteEventDetailsLayoutProps> = (
  props
) => {
  const {
    entityId,
    siteEventId,
    siteEventData,
    backHref,
    setForm,
    handleEventRefresh,
    nextSiteEvent,
    prevSiteEvent,
    preAndNextEventHandler,
    setUpdateSiteEventRecovery,
  } = props;

  const [lastHeader, setLastHeader] = React.useState<
    { props: IPivotItemProps } | undefined
  >(undefined);
  const [quickNavIndent, setQuickNavIndent] = React.useState<boolean>(false);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (lastHeader) {
      if (
        [
          t("voc.eventPage.tabRecords").toLocaleUpperCase(),
          t("voc.eventPage.tabSupporting").toLocaleUpperCase(),
        ].includes(lastHeader.props.headerText)
      ) {
        setQuickNavIndent(true);
      } else if (
        [
          t("voc.siteEventDetails.info"),
          t("voc.eventPage.tabChats"),
          t("voc.eventPage.tabLogs"),
        ].includes(lastHeader.props.headerText)
      ) {
        setQuickNavIndent(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastHeader]);

  const navigate = useNavigate();

  return siteEventData ? (
    <div className="voc-event-page">
      <AppHeader
        text={siteEventData.serial + ": " + siteEventData.subject}
        backHref={backHref}
      >
        <div
          className={"quick-nav-flexbox" + (quickNavIndent ? " indent" : "")}
        >
          {prevSiteEvent ? (
            <BaseButton
              className="header-back-button"
              onClick={() =>
                preAndNextEventHandler(prevSiteEvent).finally(() =>
                  navigate(`./../${prevSiteEvent}`)
                )
              }
            >
              <span className="arrow">◀</span>{" "}
              {t("voc.siteEventDetails.prevSiteEvent")}
            </BaseButton>
          ) : null}
          {nextSiteEvent ? (
            <BaseButton
              className="header-back-button"
              onClick={() =>
                preAndNextEventHandler(nextSiteEvent).finally(() =>
                  navigate(`./../${nextSiteEvent}`)
                )
              }
            >
              {t("voc.siteEventDetails.nextSiteEvent")}{" "}
              <span className="arrow">▶</span>
            </BaseButton>
          ) : null}
        </div>
      </AppHeader>
      <Pivot
        linkFormat={PivotLinkFormat.tabs}
        className={
          lastHeader?.props?.headerText === "Chats"
            ? "chats-tabs-control"
            : "tabs-control"
        }
        onLinkClick={setLastHeader}
      >
        <PivotItem headerText={t("voc.siteEventDetails.info")}>
          <InfoTabContainer
            siteEventData={siteEventData}
            setForm={setForm}
            onEventRefresh={handleEventRefresh}
            setUpdateSiteEventRecovery={setUpdateSiteEventRecovery}
          />
        </PivotItem>
        <PivotItem
          headerText={t("voc.eventPage.tabRecords").toLocaleUpperCase()}
        >
          <RecordsTabContainer
            event={siteEventData}
            entityId={entityId}
            eventId={null}
            siteEventId={siteEventId}
            onEventRefresh={handleEventRefresh}
            contemporaryRecordRequired={
              siteEventData.contemporaryRecordRequired
            }
          />
        </PivotItem>
        <PivotItem
          headerText={t("voc.eventPage.tabSupporting").toLocaleUpperCase()}
        >
          <SupportingTab eventDetail={siteEventData} />
        </PivotItem>
        <PivotItem headerText={t("voc.eventPage.tabChats")}>
          <ChatsTabContainer />
        </PivotItem>
        <PivotItem headerText={t("voc.eventPage.tabLogs")}>
          <LogsTabContainer
            permissionCode="event"
            id={siteEventId}
            onFetch={getSiteEventLogs}
            onUndo={undoSiteEventLogs}
            onEventRefresh={handleEventRefresh}
          />
        </PivotItem>
      </Pivot>
    </div>
  ) : null;
};

export default SiteEventDetailsLayout;
