import { VocGetValuesBuildUp } from "../../../../app/types";

export const calculateTotalCost = (state: VocGetValuesBuildUp) => {
  const { directCost, indirectCost, costCloseOut, costGna } = state;
  if (directCost || indirectCost || costCloseOut || costGna) {
    //M = I + J + K + L
    return (
      (directCost ?? 0) +
      (indirectCost ?? 0) +
      (costCloseOut ?? 0) +
      (costGna ?? 0)
    );
  }
  return undefined;
};

export const calculateProfitLoss = (
  state: VocGetValuesBuildUp,
  internalTarget: number
) => {
  const { totalCost } = state;
  if (totalCost || internalTarget) {
    return (internalTarget ?? 0) - (totalCost ?? 0);
  }
  return undefined;
};

export const calculateOverheadProfit = (state: VocGetValuesBuildUp) => {
  const { profitLoss, costGna, directCost, indirectCost, costCloseOut } = state;
  const numerator = profitLoss + costGna;
  const denominator = directCost + indirectCost + costCloseOut;

  return denominator !== 0 ? numerator / denominator : 0;
};
