export const computeInternalProgressValue = (
  state: {
    physicalProgress: number;
    internalProgressValue: number;
  },
  internalTarget: number
) => {
  const { physicalProgress } = state;
  // U = T * E
  if (physicalProgress) {
    return parseFloat(
      ((physicalProgress ?? 0) * (internalTarget ?? 0)).toFixed(2)
    );
  }
  return undefined;
};

export const computePhysicalProgress = (
  state: {
    physicalProgress: number;
    internalProgressValue: number;
  },
  internalTarget: number
) => {
  const { internalProgressValue, physicalProgress } = state;

  // T = U / E
  if (physicalProgress || internalProgressValue) {
    return parseFloat(
      ((internalProgressValue ?? 0) / (internalTarget ?? 0)).toFixed(2)
    );
  }
};
