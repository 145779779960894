// p.s. now - copy from nxp-ui '' for ease of parsing -- may find another method to replace parseNumberByLocale here

import { DEFAULT_DISPLAY_FORMAT } from "@nexploretechnology/nxp-ui";

export const parseNumberByLocale = (
  displayValue: string | undefined,
  locale = DEFAULT_DISPLAY_FORMAT,
  prefix?: string,
  suffix = ""
) => {
  if (!displayValue) {
    return;
  }
  //TODO: 'de-DE'.includes("de", "es")
  if (locale === "de" || locale === "es") {
    const parsedValue = (displayValue || "")
      .replace(prefix ? `${prefix} ` : "", "") // has space
      .replace(suffix ? `${suffix}` : "", "") // no space
      .replaceAll(".", "")
      .replace(",", ".");
    return parsedValue;
  } else {
    const parsedValue = (displayValue || "")
      .replace(prefix ? `${prefix} ` : "", "") // has space
      .replace(suffix ? `${suffix}` : "", "") // no space
      .replaceAll(",", "");
    return parsedValue;
  }
};
