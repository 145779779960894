import React from "react";
import { useTranslation } from "react-i18next";

import { notify } from "@nexploretechnology/nxp-ui";
import { cloneDeep } from "lodash";

import useAppContext from "../../app/hooks/useAppContext";
import {
  DeletedRecord,
  Usage,
  VocGetLibraryUsage,
  VocLibraryObject,
  VocOrder,
  VocOrders,
} from "../../app/types";
import {
  getLibrary,
  getLibraryUsage,
  LibrarySource,
  LibraryTypes,
  updateLibraryItem,
  updateLibrarySort,
} from "../libraries-services";
import { DataListItem } from "../../app/components/app-data-list/types";

export const useLibraryHandler = (
  libraryType: LibraryTypes,
  convertHandler: Function,
  refetchCount: number
) => {
  const { errorHandler, serviceConfig } = useAppContext();
  const { t } = useTranslation();

  const [libraryItem, setLibraryItem] = React.useState<any[]>([]);
  const [displayLibraryItem, setDisplayLibraryItem] = React.useState<
    DataListItem[]
  >([]);
  const [isLibraryAndUsageLoaded, setIsLibraryAndUsageLoaded] =
    React.useState<boolean>(false);
  const [isCustomSort, setIsCustomSort] = React.useState<boolean>(false);
  const [customSortOrder, setCustomSortOrder] = React.useState<VocOrders>(null);

  const checkIsCustom = (items: any[]) => {
    let isCustom = false;
    items.forEach((item) => {
      if (item.sortOrder !== 0 && item.sortOrder !== 99) {
        isCustom = true;
      }
    });
    return isCustom;
  };

  // =================================================================
  //                          Library Items
  // =================================================================

  const fetchLibraryAndUsage = React.useCallback(async () => {
    // setIsLibraryAndUsageLoaded(false);
    try {
      const libraryResponse = await getLibrary(libraryType, serviceConfig);
      const libraryUsageResponse = await getLibraryUsage(
        serviceConfig,
        libraryType as LibrarySource
      );
      const newLibraryItem = convertHandler(
        libraryResponse.dictionaries as unknown as VocLibraryObject[]
      );
      const newDisplayLibraryItem = cloneDeep(newLibraryItem);
      newDisplayLibraryItem.forEach((lib: any) => {
        libraryUsageResponse.forEach((used: VocGetLibraryUsage) => {
          if (lib.value === used.value) {
            // lock only if there is any usage
            if (used.usage.length > 0) {
              const serials = used.usage.map((u: Usage) =>
                u.siteEventSerial
                  ? u.siteEventSerial
                  : u.compensationEventSerial
              );
              lib.undeletable = true;
              lib.readonlyNotice =
                t("voc.library.currentlyUsed") +
                " " +
                serials.join(" ") +
                " " +
                t("voc.library.cannotBeDeleted");
            }
          }
        });
      });
      // ori data
      setLibraryItem(newLibraryItem);
      // display data (form with updated field and usage)
      setDisplayLibraryItem(newDisplayLibraryItem);
      setIsCustomSort(checkIsCustom(libraryResponse.dictionaries));
      setIsLibraryAndUsageLoaded(true);
    } catch (ex) {
      errorHandler(ex, "Fetch Library");
    }
  }, [convertHandler, errorHandler, libraryType, serviceConfig, t]);

  // =========================== Updated ==============================

  const handleUpdateLibraries = React.useCallback(
    async (
      reqBody: {
        items: (Partial<VocLibraryObject> | DeletedRecord)[];
      },
      callback?: Function
    ) => {
      try {
        if (reqBody) {
          await updateLibraryItem(
            serviceConfig,
            libraryType as LibrarySource,
            reqBody
          );
          notify.actionCompleted();
          fetchLibraryAndUsage();
          if (callback) {
            callback();
          }
        } else {
          console.log("Update Libraries ERROR: No Request Body");
        }
      } catch (ex) {
        errorHandler(ex, "Update Libraries");
      }
    },
    [errorHandler, fetchLibraryAndUsage, libraryType, serviceConfig]
  );

  // ======== Refetch Count Updated (i.e. new item added) ===========

  // temp fix: use useRef to prevent infinite call
  // >> not sure why refetchCount is unchange but keep calling useEffect when add
  const ref = React.useRef(0);
  React.useEffect(() => {
    if (refetchCount > 0) {
      if (ref.current !== refetchCount) {
        fetchLibraryAndUsage();
        ref.current = refetchCount;
      }
    }
  }, [fetchLibraryAndUsage, refetchCount]);

  // =================================================================
  //                       Library Custom Sort
  // =================================================================

  const handleUpdateLibrarySort = React.useCallback(async () => {
    try {
      const orders =
        Object.keys(customSortOrder.orders)?.map((id) => {
          return {
            id: id,
            sortOrder: customSortOrder.orders[id],
          };
        }) ?? [];
      const reqBody = {
        orders: orders,
      };
      await updateLibrarySort(serviceConfig, libraryType, reqBody);
      setDisplayLibraryItem((prevDisplayLibraryItem) => {
        const newDisplayLibraryItem = prevDisplayLibraryItem.map((item) => {
          const newItem = {
            ...item,
            sortOrder: customSortOrder.orders[item.id],
          };
          return newItem;
        });
        newDisplayLibraryItem.sort(
          (a, b) => parseInt(a.sortOrder) - parseInt(b.sortOrder)
        );
        return newDisplayLibraryItem;
      });
      notify.actionCompleted();
    } catch (ex) {
      errorHandler(ex, "Update Library Sort");
    }
  }, [customSortOrder, errorHandler, libraryType, serviceConfig]);

  React.useEffect(() => {
    if (customSortOrder) {
      handleUpdateLibrarySort();
    }
  }, [customSortOrder, handleUpdateLibrarySort]);

  const saveCustomOrder = (items: DataListItem[]) => {
    let isChanged = false;
    items.forEach((item, i: number) => {
      if (item.sortOrder !== i + 1) isChanged = true;
    });
    // if changed, call setCustomSortOrder
    if (isChanged) {
      const orders: VocOrder = {};
      items.forEach((item, i: number) => {
        orders[item.id] = i + 1;
      });
      setCustomSortOrder({ orders: orders });
    } else {
      console.log("--order not changed");
    }
  };

  const resetCustomOrder = () => {
    const orders: VocOrder = {};
    displayLibraryItem.forEach((item) => {
      orders[item.id] = 0;
    });
    setCustomSortOrder({ orders: orders });
  };

  // =================================================================
  //                             Output
  // =================================================================

  return {
    fetchLibraryAndUsage,
    isLibraryAndUsageLoaded,
    // Original Library Items
    libraryItem,
    // Display Library Items
    displayLibraryItem,
    setDisplayLibraryItem,
    // Library Custom Sort
    isCustomSort,
    saveCustomOrder,
    resetCustomOrder,
    // Update Library Handler
    handleUpdateLibraries,
  };
};
