import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { notify, useYupValidate } from "@nexploretechnology/nxp-ui";
import { omit } from "lodash";
import { IDropdownOption, ITag } from "office-ui-fabric-react";
import * as yup from "yup";

import useAppContext from "../../../../app/hooks/useAppContext";
import { useAsync } from "../../../../app/hooks/useAsync";
import { getUserDisplayName } from "../../../../app/services/app";
import {
  VocCustomUi,
  VocCustomUiLayoutItemType,
  VocEvent,
  VocEventType,
} from "../../../../app/types";
import {
  convertLocationOptions,
  LocationOption,
} from "../../../../app/utils/location";
import { convertLibs } from "../../../../site-events/site-event-services";
import {
  getClaimSelectList,
  getEventVO,
  getSiteEventsQuery,
  getVOSelectList,
  updateEvent,
} from "../../../compensation-event-services";
import UpperCardsLayout from "./UpperCardsLayout";

export interface SummaryTabDropdownOptions {
  entityUser: ITag[];
  instructionOwner: IDropdownOption[];
  vo: ITag[];
  claim: ITag[];
  clientWithStatus: IDropdownOption[];
  source: IDropdownOption[];
  sourceType: IDropdownOption[];
  delayEffect: IDropdownOption[];
  eventType: IDropdownOption[];
}

const getGroupFields = (
  groupKey: string,
  customUi: VocCustomUi
): { key: string; type: VocCustomUiLayoutItemType }[] => {
  const group = customUi.layout.find((grp) => grp.key === groupKey);
  if (group.type === VocCustomUiLayoutItemType.LayoutGroup) {
    return group.members.map((member) => ({
      key: member.key,
      type: member?.type,
    }));
  }
  return [];
};

interface UpperCardsContainerProps {
  vocEvent: VocEvent;
  customUi: VocCustomUi;
  onEventRefresh: () => void;
}

const UpperCardsContainer: React.FC<UpperCardsContainerProps> = ({
  vocEvent,
  customUi,
  onEventRefresh,
}) => {
  const { t } = useTranslation();
  const { serviceConfig, errorHandler, libraries, getDictionary, entityUsers } =
    useAppContext();
  const validationSchema = yup.object({
    eventTitle: yup
      .string()
      .nullable()
      .required(t("voc.eventList.eventTitleRequired")),
  });
  const [editingFieldGroups, setEditingFieldGroups] = useState<string[]>([]);
  const [form, setForm] = React.useState<VocEvent>();
  useEffect(() => {
    setForm({
      ...vocEvent,
      otherClaimReferences: vocEvent.relatedClaimsDigest
        ? vocEvent.relatedClaimsDigest
            .filter((item) => item?.subClaim)
            .map((claim) => claim?.eventId)
        : [],
      otherVoReferences: vocEvent.relatedVoDigest
        ? vocEvent.relatedVoDigest.map((vo) => vo.id)
        : [],
    });
  }, [vocEvent]);

  const [getEventVoResult] = useAsync(
    () =>
      vocEvent?.eventType !== VocEventType.Claim
        ? getEventVO(vocEvent?.id, serviceConfig)
        : undefined,
    {
      onError: (ex) => errorHandler(ex, "getting event VO"),
    }
  );

  const [, setUpdateVoEventRequest] = useAsync<VocEvent>(undefined, {
    onSuccess: () => {
      onEventRefresh();
      notify.success(t("voc.eventList.updateEventSuccess"));
    },
    onError(ex) {
      errorHandler(ex, t("voc.eventList.updateEventFailed"));
    },
  });

  const [formDropdownOptions, setFormDropdownOptions] =
    useState<SummaryTabDropdownOptions>({
      entityUser: [],
      instructionOwner: [],
      vo: [],
      claim: [],
      clientWithStatus: [],
      source: [],
      sourceType: [],
      // location: [],
      delayEffect: [],
      eventType: [],
    });

  useEffect(() => {
    setFormDropdownOptions((prevState) => ({
      ...prevState,
      entityUser: entityUsers.map((user) => ({
        name: `${getUserDisplayName(user)}`,
        key: user?.id,
      })),
    }));

    getVOSelectList(serviceConfig).then((data) => {
      setFormDropdownOptions((prevState) => ({
        ...prevState,
        vo: data.map((vo) => {
          return {
            name: `${vo.title} (${vo.serial})`,
            key: vo.id,
          };
        }),
      }));
    });

    getClaimSelectList(serviceConfig).then((data) => {
      setFormDropdownOptions((prevState) => ({
        ...prevState,
        claim: data.map((claim) => ({
          name: `${claim.title} (${claim.serial})`,
          key: claim.id,
        })),
      }));
    });

    const instructionOwners: IDropdownOption[] = [];
    [...convertLibs(libraries["VOC_VO_EMPOWER_REPS"] || [])].forEach((dict) => {
      if (!dict.disabled) {
        instructionOwners.push({
          text: dict.render,
          key: dict.enumValue,
        });
      }
    });
    setFormDropdownOptions((prevState) => ({
      ...prevState,
      instructionOwner: instructionOwners,
    }));

    setFormDropdownOptions((prevState) => ({
      ...prevState,
      clientWithStatus: convertLibs(
        libraries["VOC_CLIENT_WITH_STATUS"] || []
      ).map((dict) => ({
        text: dict.render,
        key: dict.enumValue,
      })),
    }));

    setFormDropdownOptions((prevState) => ({
      ...prevState,
      source: convertLibs(libraries["VOC_SOURCES"] || []).map((dict) => ({
        text: dict.render,
        key: dict.enumValue,
      })),
    }));

    setFormDropdownOptions((prevState) => ({
      ...prevState,
      sourceType: convertLibs(libraries["VOC_SOURCE_TYPE"] || []).map(
        (dict) => ({
          text: dict.render,
          key: dict.enumValue,
        })
      ),
    }));

    setFormDropdownOptions((prevState) => ({
      ...prevState,
      delayEffect: convertLibs(libraries["VOC_DELAY_EFFECTS"] || []).map(
        (dict) => ({
          text: dict.render,
          key: dict.enumValue,
        })
      ),
    }));

    setFormDropdownOptions(
      (prevState) =>
        ({
          ...prevState,
          eventType: Object.entries(VocEventType).map((entry) => ({
            text: entry[0].replace(/_/g, " "),
            key: entry[1],
          })),
        } as any)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraries, serviceConfig]);

  const handleGetSiteEvents = (filterText: string) => {
    return getSiteEventsQuery(filterText, true, serviceConfig);
  };

  const handleCancel = (groupKey: string) => {
    const groupFields = getGroupFields(groupKey, customUi);
    const resetByGroup: any = {};
    groupFields.forEach((field) => {
      const val = (vocEvent as { [key: string]: any })[field.key];
      resetByGroup[field.key] = val === undefined ? null : val;
    });
    setForm((prevState) => ({ ...prevState, ...resetByGroup }));
    setEditingFieldGroups((prevState) =>
      prevState.filter((item) => item !== groupKey)
    );
  };

  const handleSaveValidated = (groupKey: string) => {
    const groupFields = getGroupFields(groupKey, customUi);
    const saveByGroup: Record<string, unknown> = {};
    groupFields.forEach((field) => {
      const val = (form as { [key: string]: any })[field.key];

      if (field.type === "QuestionListPresetField") {
        saveByGroup[field.key] = val;
      }

      if (
        field.key === "instructionOwner" &&
        // only add instructionOwnerOptional and instructionObligated if instructionOwner is changed
        form["instructionOwner"] !== vocEvent.instructionOwner
      ) {
        saveByGroup["instructionOwnerOptional"] =
          form["instructionOwnerOptional"];
        saveByGroup["instructionObligated"] = form["instructionObligated"];
      }
    });

    if (form["customization"]) {
      saveByGroup["customization"] = form["customization"];
    }

    setUpdateVoEventRequest(() =>
      updateEvent(
        vocEvent.id,
        omit(saveByGroup, ["claim", "vo"]),
        serviceConfig
      )
    );

    setEditingFieldGroups((prevState) =>
      prevState.filter((item) => item !== groupKey)
    );
  };

  const handleEdit = (groupKey: string) => {
    setEditingFieldGroups((prevState) => [...prevState, groupKey]);
  };

  const handleFormChange = (changeValues: Partial<VocEvent>) => {
    setForm((prevState) => ({ ...prevState, ...changeValues }));
  };

  const [validationError, , , saveWithValidate] = useYupValidate<
    Partial<VocEvent>,
    string
  >(form, validationSchema as any, handleSaveValidated);

  const handleValidation = (groupKey: string) => {
    if (groupKey === "generalInformation") {
      saveWithValidate(groupKey);
    } else {
      handleSaveValidated(groupKey);
    }
  };

  ///////////////
  //COPIED FROM SITE EVENT

  const [libLocations, setLibLocations] = React.useState<LocationOption[]>([]);

  const [,] = useAsync<LocationOption[]>(
    () => {
      return Promise.resolve(
        convertLocationOptions(getDictionary("LOCATION")?.members || [])
      );
    },
    {
      onSuccess: (data) => {
        setLibLocations(data);
      },
      onError(ex) {
        errorHandler(ex, "getting location dictionary");
      },
    }
  );

  const updateLibLocationState = (locations: string[]) => {
    setLibLocations((prevState) => {
      const libLocCopy = [...prevState];
      prevState.forEach((libLoc) => {
        libLocCopy.forEach((copy) => {
          if (!copy.childOf) {
            copy.disabled = false;
          }
        });
        if (locations.includes(libLoc.enumValue)) {
          libLocCopy.forEach((copy) => {
            if (
              copy.enumValue === libLoc.enumValue ||
              copy.childOf === libLoc.enumValue ||
              !copy.childOf
            ) {
              copy.disabled = false;
            }
          });
        } else {
          libLocCopy.forEach((copy) => {
            if (copy.childOf === libLoc.enumValue) {
              copy.disabled = true;
            }
          });
        }
      });
      return libLocCopy;
    });
  };

  useEffect(() => {
    if (form?.locations && !libLocations.find((item) => !item.disabled)) {
      updateLibLocationState(form ? form.locations.map((item) => item) : []);
    }
  }, [form, libLocations]);

  return customUi && libLocations ? (
    <UpperCardsLayout
      formDropdownOptions={formDropdownOptions}
      libLocations={libLocations}
      form={form}
      eventType={vocEvent?.eventType}
      eventVoSerial={getEventVoResult.data?.serial}
      editingFieldGroups={editingFieldGroups}
      customUi={customUi}
      formErrors={validationError}
      onEdit={handleEdit}
      onFormChange={handleFormChange}
      onGetSiteEvents={handleGetSiteEvents}
      onSave={handleValidation}
      onCancel={handleCancel}
    />
  ) : null;
};

export default UpperCardsContainer;
